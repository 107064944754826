import React, {useEffect, useState} from "react"
import {Button, Grid} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {lowerFirst} from "lodash"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import MuseumInformationInputFields from "./MuseumInformationInputFields"
import SaveIcon from "@mui/icons-material/Save"
import {Cancel} from "@mui/icons-material"

const MuseumInformationSettings = ({umbrellaSettings, museumSettings, getMuseumSettings}) => {
    const t = useBaseTranslation()
    const {selectedMuseum, umbrellaId, umbrellaName} = useMuseumSelectorState()
    const [museumsInformation, setMuseumsInformation] = useState({
        screenName: museumSettings?.screenName,
        orgNr: museumSettings?.orgNr,
        street: museumSettings?.street,
        zipCode: museumSettings?.zipCode,
        city: museumSettings?.city,
        homepageUrl: museumSettings?.homepageUrl,
    })
    const [umbrellaInformation, setUmbrellaInformation] = useState({
        screenName: umbrellaSettings?.screenName,
        orgNr: umbrellaSettings?.orgNr,
        street: umbrellaSettings?.street,
        zipCode: umbrellaSettings?.zipCode,
        city: umbrellaSettings?.city,
        homepageUrl: umbrellaSettings?.homepageUrl,
    })
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const snackbar = useCustomSnackbar()

    useEffect(() => {
        if (museumSettings) {
            setMuseumsInformation({
                screenName: museumSettings?.screenName,
                orgNr: museumSettings?.orgNr,
                street: museumSettings?.street,
                zipCode: museumSettings?.zipCode,
                city: museumSettings?.city,
                homepageUrl: museumSettings?.homepageUrl,
            })
        } else {
            setMuseumsInformation({
                screenName: null,
                orgNr: null,
                street: null,
                zipCode: null,
                city: null,
                homepageUrl: null,
            })
        }
        if (umbrellaSettings) {
            setUmbrellaInformation({
                screenName: umbrellaSettings?.screenName,
                orgNr: umbrellaSettings?.orgNr,
                street: umbrellaSettings?.street,
                zipCode: umbrellaSettings?.zipCode,
                city: umbrellaSettings?.city,
                homepageUrl: umbrellaSettings?.homepageUrl,
            })
        }
    }, [museumSettings, umbrellaSettings])

    const [
        {
            data: umbrellaPutData,
            error: umbrellaPutError,
        }, putUmbrellaMuseumSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    const [
        {
            data: museumPutData,
            loading: museumPutLoading,
            error: museumPutError,
        }, putMuseumSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    useEffect(() => {
        if (umbrellaPutData) {
            snackbar.showSuccess(`${t("museumInformation")} for ${umbrellaName} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
        if (museumPutData) {
            snackbar.showSuccess(`${t("museumInformation")} for ${selectedMuseum?.name} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
    }, [selectedMuseum, museumPutData, t, umbrellaName, snackbar, getMuseumSettings, umbrellaPutData])

    useEffect(() => {
        if (museumPutError) {
            snackbar.showError(t(museumPutError.message))
        }
        if (umbrellaPutError) {
            snackbar.showError(t(umbrellaPutError.message))
        }
    }, [museumPutError, t, snackbar, umbrellaPutError])

    const saveMuseumInformation = () => {
        const umbrellaMuseumId = umbrellaSettings?.museumId ? umbrellaSettings.museumId : umbrellaId
        const museumsId = museumSettings?.museumId ? museumSettings.museumId : selectedMuseum?.id

        putUmbrellaMuseumSettings({
            url: `museum_settings/${umbrellaMuseumId}`,
            data: {
                ...umbrellaSettings,
                orgNr: umbrellaInformation.orgNr,
                street: umbrellaInformation.street,
                zipCode: umbrellaInformation.zipCode,
                city: umbrellaInformation.city,
                postalCodeEnabled: umbrellaInformation.postalCodeEnabled,
                screenName: umbrellaInformation.screenName,
                homepageUrl: umbrellaInformation.homepageUrl,
            },
        })

        if (museumsId !== umbrellaMuseumId) {
            putMuseumSettings({
                url: `museum_settings/${museumsId}`,
                data: {
                    ...museumSettings,
                    orgNr: museumsInformation.orgNr,
                    street: museumsInformation.street,
                    zipCode: museumsInformation.zipCode,
                    city: museumsInformation.city,
                    screenName: museumsInformation.screenName,
                    homepageUrl: museumsInformation.homepageUrl,
                },
            })
        }
    }

    const resetChanges = () => {
        setMuseumsInformation({
            screenName: museumSettings?.screenName,
            orgNr: museumSettings?.orgNr,
            street: museumSettings?.street,
            zipCode: museumSettings?.zipCode,
            city: museumSettings?.city,
            homepageUrl: museumSettings?.homepageUrl,
        })

        setUmbrellaInformation({
            screenName: umbrellaSettings?.screenName,
            orgNr: umbrellaSettings?.orgNr,
            street: umbrellaSettings?.street,
            zipCode: umbrellaSettings?.zipCode,
            city: umbrellaSettings?.city,
            homepageUrl: umbrellaSettings?.homepageUrl,
            postalCodeEnabled: umbrellaSettings?.postalCodeEnabled,
        })
    }

    const onMuseumInformationUpdated = (isUmbrellaFields, propertyName, propertyValue) => {
        isUmbrellaFields ?
        setUmbrellaInformation(prev => ({...prev, [propertyName]: propertyValue})) :
        setMuseumsInformation(prev => ({...prev, [propertyName]: propertyValue}))
        setUnsavedChanges(true)
    }

    return (
        <>
            <Grid container
                  spacing={3}
                  direction="column">
                {museumPutLoading
                 ? <ProgressIndicator/>
                 : <>
                     <Grid container
                           item
                           spacing={2}
                           xs={12}>
                         <Grid item xs={6}>
                             <MuseumInformationInputFields onMuseumInformationUpdated={onMuseumInformationUpdated}
                                                           museumInformation={umbrellaInformation}
                                                           museumName={umbrellaName}
                                                           isUmbrellaFields={true}
                                                           readonly={false}/>
                         </Grid>
                         {selectedMuseum?.type === "museum" && <Grid item xs={6}>
                             <MuseumInformationInputFields onMuseumInformationUpdated={onMuseumInformationUpdated}
                                                           museumInformation={museumsInformation}
                                                           isUmbrellaFields={false}
                                                           museumName={selectedMuseum?.name}
                                                           readonly={false}/>
                         </Grid>}
                     </Grid>
                     <Grid container
                           item
                           justifyContent={"flex-end"}
                           spacing={2}
                           xs={12}>
                         <Grid item>
                             <Button variant="contained"
                                     disabled={!unsavedChanges}
                                     onClick={resetChanges}
                                     color={"primary"}
                                     startIcon={<Cancel/>}>
                                 {t("cancel")}
                             </Button>
                         </Grid>
                         <Grid item>
                             <Button variant="contained"
                                     disabled={!unsavedChanges}
                                     color={"primary"}
                                     onClick={saveMuseumInformation}
                                     startIcon={<SaveIcon/>}>
                                 {t("save")}
                             </Button>
                         </Grid>
                     </Grid>
                 </>
                }
            </Grid>
        </>
    )
}

export default MuseumInformationSettings
