import React, {useEffect, useState} from "react"
import {Grid, Tooltip, Typography} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import {Update} from "@mui/icons-material"
import {dateFormat, parseAndFormatTimeString} from "../../helper-functions/dateFormat"


const ScheduleEventExceptionsGridRowRescheduled = ({
                                                       startDate,
                                                       originalStartDate,
                                                       endTime,
                                                       originalEndTime,
                                                       originalStartTime,
                                                       startTime
                                                   }) => {
    const theme = useTheme()

    const [color, setColor] = useState("lightgray")
    const [isRescheduled, setIsRescheduled] = useState(false)
    const [startDateChanged, setStartDateChanged] = useState(false)
    const [startTimeChanged, setStartTimeChanged] = useState(false)
    const [endTimeChanged, setEndTimeChanged] = useState(false)
    const [changeCount, setChangeCount] = useState(0)

    useEffect(() => setStartDateChanged(startDate !== originalStartDate), [startDate, originalStartDate])
    useEffect(() => setStartTimeChanged(startTime !== originalStartTime), [startTime, originalStartTime])
    useEffect(() => setEndTimeChanged(endTime !== originalEndTime), [endTime, originalEndTime])
    useEffect(() => setIsRescheduled(startDateChanged || startTimeChanged || endTimeChanged), [startDateChanged, startTimeChanged, endTimeChanged])
    useEffect(() => setColor(isRescheduled ? "darkred" : "lightgray"), [isRescheduled])

    useEffect(() => {
        let count = 0
        if (isRescheduled) count += 1
        if (startTimeChanged) count += 1
        if (startDateChanged) count += 1
        if (endTimeChanged) count += 1
        setChangeCount(count)
    }, [isRescheduled, startTimeChanged, startDateChanged, endTimeChanged])

    const StartDateTooltip = () => (
        <Typography variant="subtitle2">
            <small>
                {"Dato endret fra "}
                <strong>{dateFormat(new Date(originalStartDate), "PP")}</strong>
                {" til "}
                <strong>{dateFormat(new Date(startDate), "PP")}</strong>
                {"."}
            </small>
        </Typography>
    )

    const StartTimeTooltip = () => (
        <Typography variant="subtitle2">
            <small>
                {"Starttidspunkt endret fra "}
                <strong>{originalStartTime ? parseAndFormatTimeString(originalStartTime) : "ingen"}</strong>
                {" til "}
                <strong>{startTime ? parseAndFormatTimeString(startTime) : "ingen"}</strong>
                {"."}
            </small>
        </Typography>
    )

    const EndTimeTooltip = () => (
        <Typography variant="subtitle2">
            <small>
                {"Sluttidspunkt endret fra "}
                <strong>{originalEndTime ? parseAndFormatTimeString(originalEndTime) : "ingen"}</strong>
                {" til "}
                <strong>{endTime ? parseAndFormatTimeString(endTime) : "ingen"}</strong>
                {"."}
            </small>
        </Typography>
    )

    const TooltipTitle = () => (
        <>
            <Typography variant="subtitle2">
                Denne kalenderhendelsen er <strong>{isRescheduled ? "" : "ikke"}</strong> flyttet<br/>
            </Typography>
            {changeCount > 0 && (
                changeCount === 1
                    ? <>
                        {startDateChanged && <StartDateTooltip/>}
                        {startTimeChanged && <StartTimeTooltip/>}
                        {endTimeChanged && <EndTimeTooltip/>}
                    </>
                    : <ul style={{marginLeft: -16}}>
                        {startDateChanged && <li><StartDateTooltip/></li>}
                        {startTimeChanged && <li><StartTimeTooltip/></li>}
                        {endTimeChanged && <li><EndTimeTooltip/></li>}
                    </ul>
            )}
        </>
    )

    return (
        <Tooltip title={<TooltipTitle/>}>
            <Grid container item direction="column" alignItems="center">
                <Grid item><Update style={{color: color}}/></Grid>
                <Grid item>
                    <Typography style={{color: color, marginTop: -8, fontSize: theme.typography.pxToRem(11)}}>
                        Flyttet
                    </Typography>
                </Grid>
            </Grid>
        </Tooltip>
    )
}

export default ScheduleEventExceptionsGridRowRescheduled