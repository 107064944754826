import {Button, Grid, Hidden, IconButton, InputAdornment, Tooltip, Typography} from "@mui/material"
import TextField from "@mui/material/TextField"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import {useAxiosBpn} from "../../../app/axios"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import _ from "lodash"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

/**
 * @typedef DiscountCodeType
 * @property {number} id
 * @property {string} code
 * @property {number} discount
 * @property {number} timesUsed
 * @property {number} useLimit
 */

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    switch: {
        marginLeft: theme.spacing(1),
        verticalAlign: "middle",
    },
    switchLabel: {
        fontSize: "0.9em",
    },
    warning: {
        textAlign: "right",
        color: "lightgray",
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(-1),
    },
    timesUsedLabel: {
        textAlign: "right",
        color: "gray",
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(-1),
    },
}))

/**
 *
 * @param index
 * @param data {DiscountCodeType | undefined}
 * @param umbrellaId
 * @param removeFromProductGroups
 * @param submitChange {function(index: number, data: DiscountCodeType): void}
 * @return {JSX.Element}
 * @constructor
 */
const DiscountCode = ({index, data, umbrellaId, removeFromProductGroups, submitChange}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    const [discountCode, setDiscountCode] = useState(
        /** @type {DiscountCodeType | undefined} */
        undefined
    )
    const [inUse] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const snackbar = useCustomSnackbar()

    const [{loading: postLoading, error: postError}, postDiscountCode] = useAxiosBpn({
        url: "discount_codes",
        method: "POST",
    }, {manual: true})

    const [{loading: putLoading, error: putError}, putDiscountCode] = useAxiosBpn({
        url: `discount_codes/${discountCode?.id}`,
        method: "PUT",
    }, {manual: true})

    const [{loading: deleteLoading, error: deleteError}, deleteDiscountCode] = useAxiosBpn({
        url: `discount_codes/${discountCode?.id}`,
        method: "DELETE",
    }, {manual: true})

    useEffect(() => {
        setDiscountCode(data)
    }, [data])

    useEffect(() => {
        if (discountCode?.new) setUnsavedChanges(true)
    }, [discountCode])

    useEffect(() => {
        if (postError) snackbar.showError(t(postError.message))
        if (putError) snackbar.showError(t(putError.message))
        if (deleteError) snackbar.showError(t(deleteError.message))
    }, [deleteError, postError, putError, snackbar, t])

    const handleChange = e => {
        e.persist()
        setDiscountCode(prev => ({...prev, [e.target.name]: e.target.value?.toUpperCase()}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleUseLimitChange = e => {
        e.persist()
        const useLimitValue = parseInt(e.target.value)
        setDiscountCode(prev => ({...prev, [e.target.name]: useLimitValue}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleSave = () => {
        if (discountCode?.new) {
            postDiscountCode({data: {...trimCode(discountCode), museumId: umbrellaId}})
                .then(({data: {discountCode: _discountCode}}) => {
                    snackbar.showSuccess(`${t("discountCode")} ${t("added").toLowerCase()}`)
                    submitChange(index, _discountCode)
                    setDiscountCode(_discountCode)
                    setUnsavedChanges(false)
                })
        } else {
            putDiscountCode({data: {...trimCode(discountCode), museumId: umbrellaId}})
                .then(({data: {discountCode: _discountCode}}) => {
                    snackbar.showSuccess(`${t("discountCode")} ${t("updated").toLowerCase()}`)
                    submitChange(index, _discountCode)
                    setUnsavedChanges(false)
                })
        }
    }

    const handleDelete = () => {
        if (discountCode?.new) {
            removeFromProductGroups(index)
        } else {
            deleteDiscountCode()
                .then(() => {
                    snackbar.showSuccess(`${t("discountCode")} ${t("deleted").toLowerCase()}`)
                    removeFromProductGroups(index)
                })
        }
    }

    /**
     *
     * @param dc {DiscountCodeType | undefined}
     * @return {DiscountCodeType | undefined}
     */
    function trimCode(dc) {
        if (!dc) {
            return dc
        }
        return {
            ...dc,
            code: dc.code?.toUpperCase().trim(),
        }
    }

    const handleSubmit = () => {
        const trimmedCode = trimCode(discountCode)
        if (!_.isEqual(trimmedCode, data)) {
            setUnsavedChanges(true)
            submitChange(index, trimmedCode)
        }
    }

    const isMissingCode = () => {
        return putError || discountCode?.code === "" || discountCode?.code === undefined
    }

    const isInvalidDiscount = (discount) => {
        const discountAsNumber = parseInt(discount)
        return !(discountAsNumber && discountAsNumber >= 0 && discountAsNumber <= 100)
    }

    const isInvalidUseLimit = (useLimit) => {
        return useLimit ? !(useLimit && useLimit >= 0 && useLimit <= 99999999) : false
    }

    const tooltipTitle = () => inUse ? `${t("deleteProductGroupTooltipHelper")}` : ""

    return <>
        {postLoading || putLoading || deleteLoading ? <ProgressIndicator/> : (
            <form noValidate
                  className={classes.root}
                  autoComplete="off"
                  onBlur={handleSubmit}>
                <Grid container
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="center">
                    <Grid item
                          xs={2}
                          sm={1}>
                        <TextField label={t("id")}
                                   name="id"
                                   variant="outlined"
                                   size="small"
                                   disabled
                                   fullWidth
                                   value={discountCode?.id || ""}/>
                    </Grid>
                    <Grid item
                          xs
                          lg={5}
                          xl={6}>
                        <TextField label={t("code")}
                                   name="code"
                                   variant="outlined"
                                   size="small"
                                   error={isMissingCode()}
                                   fullWidth
                                   required
                                   onChange={handleChange}
                                   value={discountCode?.code || ""}/>
                    </Grid>
                    <Grid item
                          xs={3}
                          sm={3}
                          md={2}
                          lg>
                        <TextField label={t("discount")}
                                   name="discount"
                                   variant="outlined"
                                   fullWidth
                                   size="small"
                                   type="number"
                                   error={isInvalidDiscount(discountCode?.discount)}
                                   onChange={handleChange}
                                   InputProps={{
                                       inputProps: {
                                           max: 100, min: 0,
                                       },
                                       endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                   }}
                                   value={discountCode?.discount || ""}/>
                    </Grid>
                    <Grid item
                          xs={2}>
                        <TextField label={t("discountCodeUseLimit")}
                                   name="useLimit"
                                   variant="outlined"
                                   fullWidth
                                   size="small"
                                   error={isInvalidUseLimit(discountCode?.useLimit)}
                                   type="number"
                                   onChange={handleUseLimitChange}
                                   InputProps={{
                                       inputProps: {
                                           max: 9999999, min: 0,
                                       },
                                   }}
                                   value={discountCode?.useLimit || ""}/>
                    </Grid>
                    <Hidden smDown
                            xlUp>
                        <Grid item>
                            <Tooltip title={tooltipTitle()}>
                                <span>
                                    <IconButton disabled={inUse} onClick={handleDelete} size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <IconButton
                                disabled={!unsavedChanges || isInvalidDiscount(discountCode?.discount) || isMissingCode() || isInvalidUseLimit(
                                    discountCode?.useLimit)}
                                onClick={handleSave}
                                size="large">
                                <SaveIcon/>
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Hidden only={["sm", "md", "lg"]}>
                        <Grid item
                              container
                              justifyContent="flex-end"
                              wrap={"nowrap"}
                              xs={12}
                              sm>
                            <Tooltip title={tooltipTitle()}>
                                <span>
                                    <Button variant="contained"
                                            disabled={inUse}
                                            onClick={handleDelete}
                                            className={classes.button}
                                            startIcon={<DeleteIcon/>}>
                                        {t("delete")}
                                    </Button>
                                </span>
                            </Tooltip>
                            <Button variant="contained"
                                    className={classes.button}
                                    disabled={!unsavedChanges || isInvalidDiscount(discountCode?.discount) || isInvalidUseLimit(
                                        discountCode?.useLimit) || isMissingCode()}
                                    onClick={handleSave}
                                    startIcon={<SaveIcon/>}>
                                {t("save")}
                            </Button>
                        </Grid>
                    </Hidden>
                </Grid>

                {(!!discountCode?.timesUsed || unsavedChanges) &&
                  <Grid item container justifyContent={"flex-end"}>
                      {(!!discountCode?.timesUsed && discountCode?.timesUsed !== 0) &&
                        <Typography variant="subtitle2" className={classes.timesUsedLabel}>
                            {`${discountCode.timesUsed} ${t("discountCodeTimesUsed")}` }
                        </Typography>}
                      {unsavedChanges &&
                        <Typography variant="subtitle2"
                                    className={classes.warning}>
                            {t("unsavedChanges") + "!"}
                        </Typography>}
                  </Grid>
                }
            </form>
        )}
    </>;
}

export default DiscountCode
