import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { MenuItem, Select } from "@mui/material";
import ReactQuill from "react-quill";
import { translationRichTextFormats, translationRichTextModules } from "./TranslatableRichTextField";

const useStyles = makeStyles(theme => ({
  translationField: {
    width: "100%",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  languageField: {
    width: "50%",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  translationFieldWrapper: {
    display: "flex",
    width: "100%",
    paddingLeft: theme.spacing(5),
    alignItems: "flex-start",
    justifyContent: "space-between",
  }
}))

export const availableTranslationLanguages = [
  // {locale: "dk", language: "Dansk"},
  {locale: "en", language: "Engelsk"},
  // {locale: "ru", language: "Russisk"},
  // {locale: "sv", language: "Svensk"},
   {locale: "es", language: "Spansk"},
   {locale: "de", language: "Tysk"},
   {locale: "fr", language: "Fransk"},
   {locale: "nn", language: "Nynorsk"}
]

const TranslationRichTextField = ({onTranslationChanged,  onTranslationRemoved, translation, variant, translations}) => {
  const classes = useStyles()

  return (
    <div className={classes.translationFieldWrapper}>
      <Select
        value={translation.locale}
        label="Språk"
        required={true}
        error={!translation.locale || translation.locale === ""}
        variant={variant}
        className={classes.languageField}
        onChange={(event) => {
          onTranslationChanged(event.target.value, translation.id, "locale", true)
        }}>
        {availableTranslationLanguages.map(language => <MenuItem disabled={translations.some(t => t.locale === language.locale)} key={language.locale} value={language.locale}>{language.language}</MenuItem> )}
      </Select>

      <ReactQuill value={translation.translation || ""}
                  onChange={(generatedHTML) => onTranslationChanged(generatedHTML, translation.id, "translation", false)}
                  theme={"snow"}
                  onBlur={() => onTranslationChanged(translation.translation, translation.id, "translation", true)}
                  style={{height: "100px", marginBottom: "80px"}}
                  modules={translationRichTextModules}
                  formats={translationRichTextFormats}
                  bounds={".quill-wrapper"}/>
      <IconButton
        onClick={() => onTranslationRemoved(translation.id)}
        aria-label="delete"
        size="large">
        <DeleteIcon/>
      </IconButton>
    </div>
  );
}

export default React.memo(TranslationRichTextField)
