import React, {useEffect, useRef, useState} from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import nbLocale from "@fullcalendar/core/locales/nb"
import {dateFormat, getStartAndEndOfMonth} from "../helper-functions/dateFormat"
import {addMonths, parse, subMonths} from "date-fns"
import {toSentenceCase} from "../helper-functions/stringUtility"
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {blueGrey} from "@mui/material/colors"
import {useBaseTranslation} from "../baseTranslationContext"
import tippy from "tippy.js"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import {Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography} from "@mui/material"
import EventIcon from "@mui/icons-material/Event"
import MuseumIcon from "@mui/icons-material/Museum"
import EditIcon from "@mui/icons-material/Edit"
import Popper from "@mui/material/Popper"
import {PRODUCT_TYPE_ENTRANCE_TICKET, PRODUCT_TYPE_EVENT} from "../baseContext"
import {ENTRANCE_BASE_URL, EVENTS_BASE_URL} from "../Routes"
import {useHistory} from "react-router-dom"
import CalendarToolbar from "./CalendarToolbar"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: "100%",
        width: "100%",
    },
    card: {
        maxWidth: 345,
    },
    avatar: {
        backgroundColor: blueGrey[500],
    },
    paper: {
        padding: theme.spacing(2),
    },
    popper: {
        zIndex: 50,
    },
    grid: {
        paddingTop: theme.spacing(2),
    },
}))

const initialPopperContent = {
    title: "",
    subheader: "",
    description: "",
    date: "",
    museumName: "",
    productId: "",
    productType: "",
    scheduleEventId: "",
}

const Calendar = ({
                      scheduleEvents,
                      timeInterval,
                      setTimeInterval,
                      editable = false,
                  }) => {
    const classes = useStyles()
    const {palette} = useTheme()
    const t = useBaseTranslation()
    const anchorRef = useRef(null)
    const calendarRef = React.createRef()
    const history = useHistory()

    const [popperOpen, setPopperOpen] = useState(false)
    const [popperContent, setPopperContent] = useState(initialPopperContent)
    const [anchorEl, setAnchorEl] = useState(null)
    const [calendarApi, setCalendarApi] = useState()

    useEffect(() => {
        setCalendarApi(calendarRef?.current?.getApi())
    }, [calendarRef])

    const scheduleEventTransform = ({
                                        id,
                                        name,
                                        description,
                                        productId,
                                        productName,
                                        productType,
                                        museumName,
                                        startDate,
                                        startTime,
                                        endTime,
                                        isFullDay,
                                        recurNumber,
                                        ticketsSold,
                                        testTicketsSold,
                                    }) => {
        let event = {
            title: `${productName ? productName : t("noName")} ${name ? " - " + name : ""}`,
            allDay: isFullDay,
            extendedProps: {
                startTime: dateFormat(parse(startTime, "HH:mm:ss", new Date()), "HH:mm"),
                endTime: dateFormat(parse(endTime, "HH:mm:ss", new Date()), "HH:mm"),
                description: description,
                scheduleEventId: id,
                productId: productId,
                productName: productName,
                productType: productType,
                eventName: name,
                museumName: museumName,
                recurNumber: recurNumber,
                ticketsSold: !!ticketsSold || !!testTicketsSold,
                startDate: toSentenceCase(dateFormat(parse(startDate, "yyyy-MM-dd", new Date()), "PPPP")),
            },
        }
        event.start = `${startDate}${startTime ? "T" + startTime : ""}`
        event.end = `${startDate}${endTime ? "T" + endTime : ""}`

        return event
    }

    const handleEventMouseEnter = ({el, event}) => {
        tippy(el, {
            content:
                `<strong>${event.title}</strong>` +
                `<p>${getDateString(event)}</p>`,
            allowHTML: true,
            touch: false,
            placement: "top-start",
            delay: [100, 200],
        })
    }

    const getDateString = (event) => {
        let string = `${event.extendedProps.startDate}, `

        if (event.allDay) {
            string += t("isAllDay").toLowerCase()
        } else {
            string += `${t("from").toLowerCase()} ${event.extendedProps.startTime} `
            if (event.extendedProps.endTime) string += `${t("to").toLowerCase()} ${event.extendedProps.endTime}`
        }
        return string
    }

    const handleEventClick = ({el, event}) => {
        setAnchorEl(el)

        setPopperContent({
            title: event.extendedProps.productName,
            subheader: event.extendedProps.eventName,
            description: event.extendedProps.description,
            museumName: event.extendedProps.museumName,
            scheduleEventId: event.extendedProps.scheduleEventId,
            productId: event.extendedProps.productId,
            productType: event.extendedProps.productType,
            recurNumber: event.extendedProps.recurNumber,
            date: getDateString(event),
            ticketsSold: event.extendedProps.ticketsSold,
            startDate: event.extendedProps.startDate,
            startTime: event.extendedProps.startTime,
            endTime: event.extendedProps.endTime,
        })
        setPopperOpen(true)
    }

    const handleEdit = () => {
        if (popperContent) {
            switch (popperContent.productType) {
                case PRODUCT_TYPE_EVENT:
                    history.push(`${EVENTS_BASE_URL}/${popperContent.productId}/edit`)
                    break
                case PRODUCT_TYPE_ENTRANCE_TICKET:
                    history.push(`${ENTRANCE_BASE_URL}/${popperContent.productId}/edit`)
                    break
                default:
                    break
            }
        }
    }

    const handlePopperClose = event => {
        // noinspection JSUnresolvedFunction
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setPopperOpen(false)
    }

    const handleNextMonthClick = () => {
        // noinspection JSUnresolvedFunction
        calendarApi.next()
        setTimeInterval(getStartAndEndOfMonth(addMonths(timeInterval.start, 1)))
    }

    const handlePreviousMonthClick = () => {
        // noinspection JSUnresolvedFunction
        calendarApi.prev()
        setTimeInterval(getStartAndEndOfMonth(subMonths(timeInterval.start, 1)))
    }

    const handleTodayClick = () => {
        // noinspection JSUnresolvedFunction
        calendarApi.today()
        setTimeInterval(getStartAndEndOfMonth())
    }

    return (
        <>
            <CalendarToolbar handlePreviousMonthClick={handlePreviousMonthClick}
                             handleNextMonthClick={handleNextMonthClick}
                             handleTodayClick={handleTodayClick}
                             timeInterval={timeInterval}/>

            <FullCalendar plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                          locale={nbLocale}
                          events={scheduleEvents}
                          eventDataTransform={scheduleEventTransform}
                          editable={true}
                          weekNumbers={true}
                          eventTimeFormat={{
                              hour: "2-digit",
                              minute: "2-digit",
                              omitZeroMinute: false,
                          }}
                          height="94%"
                          ref={calendarRef}
                          initialDate={timeInterval.start}
                          eventColor={palette.primary.dark}
                          eventMouseEnter={handleEventMouseEnter}
                          eventClick={handleEventClick}
                          showNonCurrentDates={false}
                          fixedWeekCount={false}
                          titleFormat={{year: "numeric", month: "long"}}
                          headerToolbar={false}
                          initialView="dayGridMonth"/>

            <Popper open={popperOpen}
                    anchorEl={anchorEl}
                    className={classes.popper}
                    placement={"bottom-start"}>
                <ClickAwayListener onClickAway={handlePopperClose}>
                    <Card className={classes.card}>
                        <CardHeader title={popperContent.title}
                                    subheader={popperContent.subheader}
                                    avatar={<Avatar className={classes.avatar}>A</Avatar>}/>
                        <CardContent>
                            <Grid container
                                  spacing={1}
                                  alignItems="center">
                                <Grid container
                                      spacing={1}
                                      alignItems="center">
                                    <Grid item><EventIcon color="disabled"/></Grid>
                                    <Grid item>
                                        <Typography variant="body2"
                                                    color="textSecondary"
                                                    component="p">
                                            {popperContent.date}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container
                                      spacing={1}
                                      alignItems="center">
                                    <Grid item><MuseumIcon color="disabled"/></Grid>
                                    <Grid item>
                                        <Typography variant="body2"
                                                    color="textSecondary"
                                                    component="p">
                                            {popperContent.museumName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1"
                                                component="p">
                                        {popperContent.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            {!editable && <Button startIcon={<EditIcon/>} onClick={handleEdit}>{t("edit")}</Button>}
                        </CardActions>
                    </Card>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default Calendar