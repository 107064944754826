import React from "react"
import {Grid, List} from "@mui/material"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles";
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MuiListItem from "@mui/material/ListItem"
import {FiberManualRecord} from "@mui/icons-material"
import {useHistory} from "react-router-dom"

const Accordion = withStyles({
    root: {
        border: 0,
        boxShadow: "none",
        marginBottom: -5,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
            marginBottom: -5,
        },
        "&$disabled": {
            backgroundColor: "inherit",
            marginTop: 5,
        },
    },
    disabled: {},
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: 0,
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
        backgroundColor: theme.palette.background.default,
    },
}))(MuiAccordionDetails)

const ListItem = withStyles((theme) => ({
    root: {
        width: theme.drawerWidth,
        paddingLeft: theme.drawerWidth / 4,
        "&:hover": {
            backgroundColor: "inherit",
        },
    },
}))(MuiListItem)

const MenuItem = ({
                      itemKey,
                      icon,
                      label,
                      url,
                      subItems,
                      expanded,
                      disabled = false,
                      handleExpandedChange,
                      onMenuClick,
                  }) => {
    const theme = useTheme()
    const {location: {pathname}} = useHistory()

    const handleSubItemClick = (index, url) => {
        onMenuClick(url)
    }

    const handleChange = (itemKey, url) => (event, newExpanded) => {
        handleExpandedChange(itemKey, url)(event, newExpanded)
    }

    return (
        <Accordion square
                   selected
                   disabled={disabled}
                   expanded={expanded === itemKey}
                   onChange={handleChange(itemKey, url)}>
            <AccordionSummary expandIcon={subItems && <ExpandMoreIcon/>}>
                <Grid container
                      alignItems="center"
                      wrap="nowrap"
                      spacing={1}>
                    <Grid item
                          style={{marginTop: "5px"}}>
                        {icon}
                    </Grid>
                    <Grid item>
                        <Typography variant="overline">{label}</Typography>
                    </Grid>
                    {expanded === itemKey && (
                        <Grid item
                              style={{marginTop: "2px"}}>
                            <FiberManualRecord style={{fontSize: "0.8em", color: theme.palette.primary.light}}/>
                        </Grid>
                    )}
                </Grid>
            </AccordionSummary>
            {subItems && (
                <AccordionDetails>
                    <List dense>
                        {subItems.map(({label, url, disabled}, index) => (
                            <ListItem key={index}
                                      dense
                                      button
                                      disabled={disabled}
                                      selected={pathname === url}
                                      disableGutters
                                      onClick={() => handleSubItemClick(index, url)}>
                                <Typography variant="overline"
                                            style={{fontSize: "0.8em"}}>{label}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            )}
        </Accordion>
    )
}

export default MenuItem