import React from "react"
import {Chip, Tooltip} from "@mui/material"
import {getSubstring} from "../helper-functions/stringUtility"

const ChipWithTooltip = ({label, size, color, maxLength = 16}) => {
    return (
        <Tooltip title={label?.length > maxLength ? label : ""}>
            <Chip label={getSubstring(label, maxLength)} size={size} color={color}/>
        </Tooltip>
    )
}

export default ChipWithTooltip