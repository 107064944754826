import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useAuthsState} from "../app/auths/authsContext";
import ProductsSettings from "./products-settings/ProductsSettings";
import {DASHBOARD_BASE_URL, SETTINGS_BASE_URL} from "../app/Routes";
import PaymentSettings from "./payment-settings/PaymentSettings";
import {useMuseumSelectorState} from "../app/museum-selector/museumSelectorContext";
import {Typography} from "@mui/material";
import OtherSettings from "./other-settings/OtherSettings";
import {useBaseTranslation} from "../app/baseTranslationContext";
import VouchersSettings from "./voucher-settings/VouchersSettings";
import ApiSettings from "./api-settings/ApiSettings";
import UserPortalSettings from "./user-portal-settings/UserPortalSettings";
import {ROLES_VALUES} from "../app/menu/MuseumSelector"

export const SettingsRoutes = () => {
    const {userIsAuthenticated} = useAuthsState()
    const {umbrellaAndChildrenIds, selectedMuseum} = useMuseumSelectorState()
    const t = useBaseTranslation()

    const getRoutes = () => {
        if (userIsAuthenticated) {
            return (
                <>
                    <Route exact
                           path={SETTINGS_BASE_URL}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <Redirect to={`${SETTINGS_BASE_URL}/products`}/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                    <Route path={`${SETTINGS_BASE_URL}/products`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <ProductsSettings/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                    <Route path={`${SETTINGS_BASE_URL}/payment`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <PaymentSettings/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                    <Route path={`${SETTINGS_BASE_URL}/vouchers`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <VouchersSettings/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                    <Route path={`${SETTINGS_BASE_URL}/api`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <ApiSettings/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                    <Route path={`${SETTINGS_BASE_URL}/other`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <OtherSettings/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                    <Route path={`${SETTINGS_BASE_URL}/user-portal`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <UserPortalSettings/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                </>
            )
        } else {
            return null
        }
    }

    if (umbrellaAndChildrenIds?.length) {
        return (
            <Switch>
                {getRoutes()}
            </Switch>
        )
    } else {
        return (
            <Typography>{t("youNeedToSelectAMuseumToChangeSettings")}.</Typography>
        )
    }
}
