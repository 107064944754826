import {useBaseTranslation} from "../baseTranslationContext"
import {PRODUCT_TYPE_ENTRANCE_TICKET, PRODUCT_TYPE_EVENT, PRODUCT_TYPE_SEASONAL_PASS} from "../baseContext"
import {Grid, MenuItem, TextField} from "@mui/material"
import Typography from "@mui/material/Typography"
import React from "react"

const ProductTypeSelector = ({label, selected, handleChange, gridContainerProps}) => {
    const t = useBaseTranslation()

    const selectable = [
        {label: t("events"), value: PRODUCT_TYPE_EVENT},
        {label: t("entrance"), value: PRODUCT_TYPE_ENTRANCE_TICKET},
        {label: t("seasonalPasses"), value: PRODUCT_TYPE_SEASONAL_PASS},
        {label: t("all"), value: "all"},
    ]

    return (
        <Grid container
              alignItems="center"
              {...gridContainerProps}>
            {label && <Grid item> <Typography variant="caption" color="textSecondary"> {label}: </Typography> </Grid>}

            <Grid item>
                <TextField select
                           size="small"
                           variant="standard"
                           style={{width: 160, marginLeft: 8}}
                           value={selected || ""}>
                    {selectable.map(({value, label: _label}, index) => (
                        <MenuItem key={index}
                                  value={value}
                                  onClick={() => handleChange(value)}>
                            {_label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    )
}

export default ProductTypeSelector