import React, {useEffect, useState} from "react"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import DashboardCard from "../components/DashboardCard"
import {Avatar, Card, CardHeader, Chip, Grid, Link, Tooltip, Typography} from "@mui/material"
import {useHistory} from "react-router-dom"
import {getBaseRouteBasedOnProductType} from "../helper-functions/getBaseUrlBasedOnProductType"
import ImageCropper from "./ImageCropper"
import {useTheme} from "@mui/material/styles"
import MuseumIcon from "@mui/icons-material/Museum"
import IconButton from "@mui/material/IconButton"
import {ShoppingCart} from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
import {toLowerCase} from "../helper-functions/stringUtility"
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext"
import {ROLES_VALUES} from "../menu/MuseumSelector"

const SmallProductListCard = ({
                                  data,
                                  loading,
                                  title,
                                  subheader,
                                  avatar,
                                  testOrders,
                              }) => {
    const t = useBaseTranslation()
    const history = useHistory()
    const {palette: {primary: {dark: dark_color}}, spacing} = useTheme()
    const {selectedMuseum} = useMuseumSelectorState()

    const [orderLines, setOrderLines] = useState([])

    useEffect(() => {
        if (data?.orderLines) setOrderLines(data.orderLines)
    }, [data])

    const getTitle = (productName, productType) => (
        <Grid container
              spacing={1}
              alignItems="center">
            <Grid item>
                <Tooltip title={t("linkToProductDetailsTooltip")}>
                    <Typography variant="h6"
                                style={{cursor: "pointer"}}>
                        {productName}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography variant="subtitle2"
                            style={{marginTop: 4}}
                            color="textSecondary">
                    {`(${toLowerCase(t(productType))})`}
                </Typography>
            </Grid>
        </Grid>
    )

    const getAvatar = imageUrl => (
        <Avatar variant="square"
                style={{
                    backgroundColor: dark_color,
                    width: spacing(8),
                    height: spacing(8),
                }}>
            <ImageCropper height="100%"
                          src={imageUrl || "https://images.unsplash.com/photo-1453806839674-d1a9087ca1ed?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"}/>
        </Avatar>
    )

    const getSubheader = museumName => <Chip label={museumName}
                                             icon={<MuseumIcon/>}
                                             size="small"/>

    const getAction = (status, productId, productType, museumId) => (
        <>
            {status === "published" && (
                <Tooltip title={t("linkToProductPortalTooltip")}>
                    <Link href={`${window._env_.REACT_APP_BPN_PORTAL}museum/${museumId}/produkt/${productId}`}
                          target="portal">
                        <IconButton size="large">
                            <ShoppingCart/>
                        </IconButton>
                    </Link>
                </Tooltip>
            )}
            {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        history.push(`${getBaseRouteBasedOnProductType(productType)}/${productId}/edit`)
                    }}
                    size="large">
                    <EditIcon/>
                </IconButton>
            )}
        </>
    )

    return (
        <>
            <DashboardCard maxHeight={650}
                           title={title}
                           subheader={subheader}
                           testOrders={testOrders}
                           avatar={avatar}
                           cardContentStyle={{
                               overflowY: "auto",
                               overflowX: "hidden",
                               maxHeight: 650 - 64,
                           }}>
                {loading
                    ? <ProgressIndicator/>
                    : orderLines?.length
                        ? orderLines.map(({
                                              productName,
                                              museumName,
                                              productId,
                                              museumId,
                                              productType,
                                              product: {imageUrl, status}
                                          },
                                          index) => (
                            <Card square
                                  variant="outlined"
                                  key={index}
                                  style={{
                                      marginTop: "8px",
                                      cursor: "pointer",
                                  }}
                                  onClick={() => history.push(`${getBaseRouteBasedOnProductType(productType)}/${productId}/details`)}>
                                <CardHeader title={getTitle(productName, productType)}
                                            avatar={getAvatar(imageUrl)}
                                            subheader={getSubheader(museumName)}
                                            action={getAction(status, productId, productType, museumId)}/>
                            </Card>
                        ))
                        : <Typography style={{margin: "16px"}}>{t("noDataToShow")}!</Typography>
                }
            </DashboardCard>
        </>
    )
}

export default SmallProductListCard


