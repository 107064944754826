import React from "react"
import {Typography} from "@mui/material"

const ProductPriceCategoryHelperText = () => {
    return (
        <>
            <Typography variant="subtitle2">
                Alle priser må være knyttet til en <em>priskategori</em>, som igjen er knyttet til
                en <em>varegruppe</em>.
            </Typography>
            <Typography variant="body2">
                Både priskategorier og varegrupper må opprettes under innstillinger før de kan tas i bruk i
                produkteditor.
            </Typography>
            <br/>
            <Typography variant="h6">Priskategorier</Typography>
            <Typography variant="body2">
                Grunnen til at faste priskategorier må opprettes i forkant, i stedet for å bare ha et
                fritekstfelt, er at det gjør det enklere å kategorisere besøksstatistikk.
                <br/><br/>
                <em>
                    Vær oppmerksom på at det er fullt mulig å overstyre navnet på priskategoriene, slik
                    at de prisene sluttbruker ser i kjøpsportalen og på billettene, kan skreddersys for
                    hvert enkelt produkt.
                </em>
            </Typography>
            <br/>
            <Typography variant="h6">Varegrupper</Typography>
            <Typography variant="body2">
                Merverdigavgift er knyttet til varegruppe, som igjen er knyttet til en eller flere
                priskategorier. I produkteditoren er det kun mulig å velge forhåndsdefinerte
                priskategorier (og dermed varegruppe og MVA indirekte). Endringer på MVA og varegruppe
                må gjøres under innstillinger.
            </Typography>
        </>
    )
}

export default ProductPriceCategoryHelperText