import React, {useEffect} from "react"
import {useAxiosAuthz} from "../axios"
import {ProgressIndicator} from "../ProgressIndicator"

export const USER_LOGGED_IN = "authsContext/loggedIn"
export const USER_LOGGED_OUT = "authsContext/loggedOut"
export const SET_USER_DATA = "authsContext/setUserData"
export const SET_USER_ACCESS_MUSEUM_IDS = "authsContext/setUserAccessMuseumIds"
export const SET_USER_HAS_ACCESS = "authsContext/setUserHasAccess"

const AuthsStateContext = React.createContext(undefined)
const AuthsDispatchContext = React.createContext(undefined)

const initialState = {
    userIsAuthenticated: false,
    userHasAccess: false,
    userData: {},
    userName: "",
    userAccessMuseumIds: [],
}

const authsReducer = (state, action) => {
    switch (action.type) {
        case USER_LOGGED_IN:
            return {
                ...state,
                userIsAuthenticated: true,
            }
        case USER_LOGGED_OUT:
            return {
                ...state,
                userIsAuthenticated: false,
            }
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.payload,
                userName: action.payload.email,
            }
        case SET_USER_ACCESS_MUSEUM_IDS:
            return {
                ...state,
                userAccessMuseumIds: action.payload,
            }
        case SET_USER_HAS_ACCESS:
            return {
                ...state,
                userHasAccess: action.payload,
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const AuthsProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(authsReducer, {...initialState}, undefined)
    const [{data, loading, error}, getUserData] = useAxiosAuthz({url: "authorize/userData"})

    useEffect(() => {
        if (data) {
            let userAccessMuseumIds = data?.appAccess?.museums?.map(({id}) => id)
            dispatch({type: SET_USER_ACCESS_MUSEUM_IDS, payload: generateMuseumIdAndRoleMap(data?.appAccess?.museums)})
            dispatch({type: SET_USER_DATA, payload: data})
            dispatch({type: SET_USER_HAS_ACCESS, payload: userAccessMuseumIds?.length > 0})
        }
    }, [data])

    useEffect(() => {
        if (error && state.userIsAuthenticated) {
            getUserData()
        }
    }, [error, getUserData, state.userIsAuthenticated])

    return (
        <AuthsStateContext.Provider value={state}>
            <AuthsDispatchContext.Provider value={dispatch}>
                {loading ? <ProgressIndicator/> : children}
            </AuthsDispatchContext.Provider>
        </AuthsStateContext.Provider>
    )
}

export const useAuthsState = () => {
    const context = React.useContext(AuthsStateContext)
    if (undefined === context) {
        throw new Error("useAuthsState must be used within a AuthsProvider")
    }
    return context
}

export const useAuthsDispatch = () => {
    const context = React.useContext(AuthsDispatchContext)
    if (undefined === context) {
        throw new Error("useAuthsDispatch must be used within a AuthsProvider")
    }
    return context
}

const generateMuseumIdAndRoleMap = (accessibleMuseums) => {
    const museumIdAndRoleMap = new Map()
    accessibleMuseums.forEach(museum => {
        const bpnRole = museum.applications.find(application => (application.id === window._env_.REACT_APP_ID))?.role?.name
        if (bpnRole) {
            museumIdAndRoleMap.set(museum.id, bpnRole)
        }
    })

    return museumIdAndRoleMap
}

