import axios from "axios"
import {makeUseAxios} from "axios-hooks"
import LRU from "lru-cache"
// noinspection NpmUsedModulesInstalled
import {v4 as uuid} from "uuid"

const LOCAL_STORAGE_ACCESS_TOKEN = "kit.access_token"
const LOCAL_STORAGE_ID_TOKEN = "kit.id_token"

const addTokenToHeader = () => {
    return async (config) => {
        const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
        const idToken = localStorage.getItem(LOCAL_STORAGE_ID_TOKEN)

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
                "Correlation-Id": uuid(),
            }
            config.crossDomain = true
        }

        if (idToken) {
            config.headers = {...config.headers, "Id-Token": idToken}
        }
        return config
    }
}

export const bpnAPI = axios.create({baseURL: `${window._env_.REACT_APP_BPN_ADMIN_API}`})
bpnAPI.interceptors.request.use(addTokenToHeader(), (error) => Promise.reject(error))
export const useAxiosBpn = makeUseAxios({axios: bpnAPI, cache: new LRU({max: 500})})

export const authzAPI = axios.create({baseURL: `${window._env_.REACT_APP_AUTHZ_API}`})
authzAPI.interceptors.request.use(addTokenToHeader(), (error) => Promise.reject(error))
export const useAxiosAuthz = makeUseAxios({axios: authzAPI, cache: new LRU({max: 500})})

export const museumsAPI = axios.create({baseURL: `${window._env_.REACT_APP_MUSEUMS_API}`})
export const useAxiosMuseums = makeUseAxios({axios: museumsAPI, cache: new LRU({max: 500})})
