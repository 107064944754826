import React, {useEffect, useState} from "react"
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import _ from "lodash"
import {useAxiosBpn} from "../axios"
import useCustomSnackbar from "../components/useCustomSnackbar"
import {Cancel} from "@mui/icons-material"
import {GridLabel, GridValue} from "../order-details/gridComponents"
import {getErrorMessage} from "../helper-functions/getErrorMessage"
import {ButtonWithProgress} from "../components/ButtonWithProgress"
import ConfirmationDialog from "../components/ConfirmationDialog"

const textFieldProps = {
    margin: "dense",
    variant: "outlined",
    autoComplete: "new-password",
}

const CustomerDetailsDialogGeneral = ({loading, customer, handleClose}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()

    const [_customer, setCustomer] = useState()
    const [editMode, setEditMode] = useState(false)
    const [gridRows, setGridRows] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [deleteCustomerConfirmationDialogOpen, setDeleteCustomerConfirmationDialogOpen] = useState(false)

    const [{data: deleteData, loading: deleteLoading, error: deleteError}, deleteCustomer] = useAxiosBpn(
        {
            url: `customers/${customer.id}`,
            method: "DELETE",
        }, {manual: true})

    const [{data: putData, loading: putLoading, error: putError}, putCustomer] = useAxiosBpn({
        url: `customers/${customer.id}`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => setCustomer(customer), [customer])

    useEffect(() => {
        if (_customer) {
            setGridRows([
                {name: "email", label: t("email"), value: _customer?.email},
                {name: "firstName", label: t("firstName"), value: _customer?.firstName},
                {name: "lastName", label: t("lastName"), value: _customer?.lastName},
                {name: "phone", label: t("phone"), value: _customer?.phone},
            ])
        }
    }, [_customer, t])

    useEffect(() => {
        if (putData) {
            snackbar.showSuccess(`${t("customer")} ${t("updated").toLowerCase()}`)
            setCustomer(putData.customer)
            setUnsavedChanges(false)
            setEditMode(false)
        }
    }, [putData, snackbar, t])

    useEffect(() => {
        if (deleteError) {
            snackbar.showError(getErrorMessage(deleteError.response, deleteError.message, t))
        }
    }, [deleteError, snackbar, t])

    useEffect(() => {
        if (putError) {
            snackbar.showError(t(putError.message))
        }
    }, [putError, snackbar, t])

    useEffect(() => {
        if (deleteData) {
            snackbar.showSuccess(t("customerDeletedSuccessfully"))
            handleClose()
        }
    }, [deleteData, snackbar, t, handleClose])

    const handleSave = () => putCustomer({data: _customer})

    const handleDelete = () => {
        deleteCustomer().catch(() => ({}))
    }

    const handleCustomerChange = (e) => {
        e.persist()
        setCustomer(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleCancel = () => {
        setCustomer(customer)
        setUnsavedChanges(false)
        setEditMode(false)
    }

    const handleSubmit = () => {
        if (!_.isEqual(_customer, customer)) {
            setUnsavedChanges(true)
        }
    }

    const getTitle = () => (
        <Grid container
              spacing={1}>
            <Grid item>
                {`${t("customer")} ${_customer?.id}`}
            </Grid>
            <Grid item>
                <Chip label={t(_customer?.status)}
                      color="primary"
                      size="small"/>
            </Grid>
        </Grid>)

    const getDeleteCustomerConfirmationDialogText = () => (
        <>
            Denne kunden vil bli slettet <u>permanent</u> (dette kan ikke angres). Kundeinformasjon vil bli fjernet fra
            alle tilknyttede ordre. <br/>
            <br/><br/> <strong>Vil du fortsette?</strong>
        </>
    )

    return loading || putLoading
           ? <ProgressIndicator/>
           : _customer
             ? <Card square
                     variant="outlined">
                 <CardHeader title={getTitle()}
                             titleTypographyProps={{color: "primary"}}
                             action={
                                 <>
                                     <div style={{display: "flex", alignItems: "center"}}>
                                         <IconButton onClick={() => setEditMode(!editMode)} size="large">
                                             <EditIcon/>
                                         </IconButton>
                                         <ButtonWithProgress variant="contained"
                                                             loading={deleteLoading}
                                                             label={t("delete")}
                                                             color="primary"
                                                             onClick={() => setDeleteCustomerConfirmationDialogOpen(true)}/>
                                     </div>
                                 </>}/>
                 <CardContent>
                     {editMode
                      ? <form noValidate
                              autoComplete="off"
                              onBlur={handleSubmit}>
                          {gridRows?.map(({name, label, value}, index) => (
                              <TextField {...textFieldProps}
                                         key={index}
                                         fullWidth
                                         label={label}
                                         name={name}
                                         value={value || ""}
                                         onChange={handleCustomerChange}/>
                          ))}
                      </form>
                      : <>
                          <Grid container
                                direction="column">
                              {gridRows?.map(({label, value}, index) =>
                                  value &&
                                  <Grid container
                                        key={index}
                                        alignItems="center">
                                      <GridLabel>{label}:</GridLabel>
                                      <GridValue>{value}</GridValue>
                                  </Grid>,
                              )}
                          </Grid>
                      </>}
                 </CardContent>
                 <CardActions style={{justifyContent: "flex-end"}}>
                     {unsavedChanges &&
                     <>
                         <Typography variant="subtitle2"
                                     color="textSecondary">
                             {t("unsavedChanges") + "!"}
                         </Typography>
                         <Button variant="text"
                                 onClick={handleCancel}
                                 color="primary"
                                 startIcon={<Cancel/>}>
                             {t("regret")}
                         </Button>
                     </>
                     }
                     {unsavedChanges || editMode
                      ? <Button variant="text"
                                color="primary"
                                disabled={!unsavedChanges}
                                onClick={handleSave}
                                startIcon={<SaveIcon/>}>
                          {t("save")}
                      </Button>
                      : null
                     }
                 </CardActions>
                 <ConfirmationDialog open={deleteCustomerConfirmationDialogOpen}
                                     handleAccept={handleDelete}
                                     handleClose={() => setDeleteCustomerConfirmationDialogOpen(false)}>
                     {getDeleteCustomerConfirmationDialogText()}
                 </ConfirmationDialog>
             </Card>
             : null;
}

export default CustomerDetailsDialogGeneral
