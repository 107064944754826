import React from "react"
import {Table, TableBody, TableContainer} from "@mui/material"
import GenericTableToolbar from "./GenericTableToolbar"
import GenericTableHead from "./GenericTableHead"
import GenericTableRow from "./GenericTableRow"

export const getGenericTableCellVariant = (size, totalsRow = false, totalsColumn = false) => {
    if (totalsRow || totalsColumn) return "subtitle2"
    return size === "medium" ? "body2" : "caption"
}

export const getGenericTableCellAlign = (align, type) => {
    if (align) return align

    switch (type) {
        case "date":
            return "left"
        case "number":
            return "right"
        default:
            return "center"
    }
}

const GenericTable = ({
                          columns,
                          rows,
                          disableOverflowScroll = false,
                          hideEmptyRows = true,
                          size = "medium",
                          tableHeight = "100%",
                          collapsible = false,
                          selectable = false,
                          emptyFirstCell = false,
                          selected = [],
                          setSelected = null,
                          selectedButtons = null,
                          stickyHeader = true,
                          hideHeader = false,
                          tableTitle = null,
                          totalsRow = false,
                          totalsColumn = false,
                      }) => {
    const isSelected = (id) => selected.indexOf(id) !== -1

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleRowClick = (event, id) => {
        if (id && selectable) {
            const selectedIndex = selected.indexOf(id)
            let newSelected = []

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id)
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1))
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1))
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                )
            }

            setSelected(newSelected)
        }
    }

    return (
        <div style={{overflow: disableOverflowScroll ? "hidden" : "auto"}}>
            <GenericTableToolbar selectable={selectable}
                                 title={tableTitle}
                                 numSelected={selected.length}
                                 selectedButtons={selectedButtons}/>
            <TableContainer style={{height: tableHeight}}>
                <Table size={size}
                       stickyHeader={stickyHeader}>
                    {!hideHeader && <GenericTableHead columns={columns}
                                                      collapsible={collapsible}
                                                      selectable={selectable}
                                                      emptyFirstCell={emptyFirstCell}
                                                      numSelected={selected.length}
                                                      numTotal={rows.length}
                                                      handleSelectAllClick={handleSelectAllClick}
                                                      size={size}/>}
                    <TableBody>
                        {rows?.map((row, index) => {
                            const isItemSelected = isSelected(row.id)

                            return (
                                <GenericTableRow key={index}
                                                 row={row}
                                                 columns={columns}
                                                 hideEmptyRows={hideEmptyRows}
                                                 handleRowClick={handleRowClick}
                                                 size={size}
                                                 emptyFirstCell={emptyFirstCell}
                                                 totalsRow={totalsRow && index === rows.length - 1}
                                                 totalsColumn={totalsColumn}
                                                 isSelected={isItemSelected}
                                                 selectable={selectable}
                                                 collapsible={collapsible}/>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default GenericTable

