import React from "react"
import {Typography} from "@mui/material"

const UniqueSellingPointsHelperText = () => {
    return (
        <>
            <Typography variant="body2">
                Regler for familierabatt defineres her, men må aktiveres på hvert produkt det skal gjelde for
                (<em>det kan gjøres i produkteditoren</em>).
                <br/>Hvis en kunde velger et antall billetter som oppfyller kravene til en familierabatt, blir rabatten
                beregnet automatisk og vises i handlekurven (med navn og oppnådd rabatt).
                <br/><br/>
            </Typography>
            <Typography variant="subtitle2">
                Eksempel 1
            </Typography>
            <Typography variant="body2">
                En familie defineres som <em><u>to</u> voksne og minst <u>ett</u> barn</em>. Det vil si at hver
                barnebillett som blir lagt i handlekurven (etter den første), vil være gratis, så lenge det allerede
                er to voksenbilletter.
                <br/><br/>
            </Typography>
            <Typography variant="subtitle2">
                Eksempel 2
            </Typography>
            <Typography variant="body2">
                En familie defineres som <em><u>én</u> voksen og minst <u>to</u> barn</em>.
                Det vil si at hver barnebillett som blir lagt i handlekurven (etter de to første), vil være gratis,
                så lenge det allerede er én voksenbillett.
                <br/><br/>
            </Typography>
            <Typography variant="body2">
                Hvis det er definert flere familierabatter og et kjøp oppfyller kravene til flere av dem, vil rabatten
                øverst i listen være gjeldende.
                <br/><br/>
            </Typography>
        </>
    )
}

export default UniqueSellingPointsHelperText
