import {
    Button,
    ButtonGroup,
    CircularProgress,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@mui/material"
import React, {useRef, useState} from "react"
import {useProductEditorTranslation} from "./productEditorTranslationContext"
import makeStyles from '@mui/styles/makeStyles';
import SaveIcon from "@mui/icons-material/Save"
import {ArrowDropDown} from "@mui/icons-material"
import StatusIcon from "../components/StatusIcon"

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
    },
}))

const ProductEditorButtons = ({updatedAt, status, unsavedChanges, saveProduct, loading}) => {
    const classes = useStyles()
    const t = useProductEditorTranslation()
    const anchorRef = useRef(null)

    const [open, setOpen] = useState(false)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleMenuItemClick = (_status) => {
        saveProduct(_status)
        setOpen(false)
    }

    return (
        <Grid container
              alignItems={"center"}
              spacing={2}>
            {updatedAt && (
                <Grid item>
                    <Typography variant={"overline"}
                                color={"textSecondary"}>
                        {t("lastUpdated")}: {new Date(updatedAt).toLocaleString("nb-NO")}
                    </Typography>
                </Grid>)
            }
            <Grid item>
                <StatusIcon status={status}/>
            </Grid>
            <Grid item>
                <div className={classes.wrapper}>
                    <ButtonGroup variant="contained"
                                 ref={anchorRef}
                                 color="primary">
                        <Button disabled={!unsavedChanges || loading}
                                startIcon={<SaveIcon/>}
                                onClick={() => saveProduct()}>
                            {t("save")}
                        </Button>
                        <Button size="small"
                                onClick={() => setOpen(!open)}>
                            <ArrowDropDown/>
                        </Button>
                    </ButtonGroup>
                    {loading && <CircularProgress size={24}
                                                  className={classes.buttonProgress}/>}
                </div>
                <Popper open={open}
                        style={{zIndex: 1500}}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow {...TransitionProps}
                              style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList>
                                        <MenuItem onClick={() => handleMenuItemClick(status === "published"
                                                                                     ? "draft"
                                                                                     : "published")}>
                                            {status === "published" ? t("saveAndDepublish") : t("saveAndPublish")}
                                        </MenuItem>
                                    </MenuList>

                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    )
}

export default ProductEditorButtons