import React, {createContext, useContext} from "react"
import {useTranslation} from "react-i18next"
import {ProgressIndicator} from "../ProgressIndicator"

const MenuTranslationContext = createContext(undefined)

export const MenuTranslationProvider = ({children}) => {
    const {t, ready} = useTranslation("bpn.menu", {useSuspense: false})

    if (ready) {
        return (
            <MenuTranslationContext.Provider value={t}>
                {children}
            </MenuTranslationContext.Provider>
        )
    } else {
        return <ProgressIndicator/>
    }
}

export const useMenuTranslation = () => {
    return useContext(MenuTranslationContext)
}