import React, { useEffect, useState } from "react";
import { IconButton, MenuItem, Select, Snackbar, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Alert, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import FormControl from "@mui/material/FormControl";
import { availableTranslationLanguages } from "../TranslationField";
import { useProductEditorTranslation } from "../productEditorTranslationContext";
import Link from "@mui/material/Link";
import { FileCopy } from "@mui/icons-material";


const useStyles = makeStyles({
    iframePreview:{
        position: "relative",
        height: ({isMobilePreview}) => {
            if (isMobilePreview) return "800px"
            return "100%"
        },
        width: ({isMobilePreview}) => {
            if (isMobilePreview) return "400px"
            return "100%"
        },
    },
    previewWrapper:{
        position: "relative",
        height: "90%",
    },
    toggleButtonGroup:{
        position: "absolute",
        right: 0,
        zIndex:1,
        background: "white",
        display: "flex",
        borderRadius: "5px",
        flexDirection: "column"
    },
    portalUrlContainer:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
    }

})


const ProductEditorPreview = ({product, unsavedChanges}) => {
    const [isMobilePreview, setIsMobilePreview] = useState(false)
    const [previewLanguage, setPreviewLanguage] = useState('no')
    const classes = useStyles({isMobilePreview})
    const [unsavedAlertOpen, setUnsavedAlertOpen] = useState(false)
    const t = useProductEditorTranslation()
    useEffect(() => {
        setUnsavedAlertOpen(unsavedChanges)
    }, [unsavedChanges])

    const handleToggle = (event, value) => {
        setIsMobilePreview(value);
    };

    const getPortalUrl = () => {
        return product.status === 'published' ?
          `${window._env_.REACT_APP_BPN_PORTAL}${previewLanguage}/museum/${product.museumId}/produkt/${product.id}` :
          `${window._env_.REACT_APP_BPN_PORTAL}${previewLanguage}/museum/${product.museumId}/produkt/${product.id}?draft=true`
    }

    const handleCopyToClipboard = async () => {
        await navigator.clipboard.writeText(getPortalUrl())
    }


    if (product?.id) {
        return (
            <div className={classes.previewWrapper}>
                <Snackbar anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                          open={unsavedAlertOpen}>
                    <Alert severity="info"
                           onClose={() => setUnsavedAlertOpen(false)}>
                        {t('unsavedChangesWarning')}
                    </Alert>
                </Snackbar>
                {product.status === "published" &&
                <div className={classes.portalUrlContainer}>
                    <Typography variant={"h6"}>{t("previewUrl")}<Link href={getPortalUrl()} target={"portal"}>{getPortalUrl()}</Link></Typography>
                    <Tooltip title={t("copyUrl")}>
                        <IconButton onClick={handleCopyToClipboard} size="large">
                            <FileCopy fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </div>}
                <div className={classes.toggleButtonGroup}>
                    <FormControl>
                        <Select
                          value={previewLanguage}
                          color={"primary"}
                          variant={"outlined"}
                          onChange={(e) => setPreviewLanguage(e.target.value)}>
                            <MenuItem  value="no">Norsk</MenuItem >
                            {availableTranslationLanguages.map(translation =>
                              <MenuItem key={translation.locale}  value={translation.locale}>{translation.language}</MenuItem >
                            )}
                        </Select>
                    </FormControl>
                    <ToggleButtonGroup orientation="vertical" value={isMobilePreview} exclusive onChange={handleToggle}>
                        <ToggleButton value={true} aria-label="mobile">
                            <PhoneAndroidIcon />
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="desktop">
                            <DesktopWindowsIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <iframe className={classes.iframePreview}
                        src={getPortalUrl()}
                        title="Portal"/>
            </div>
        );
    } else {
        return <Typography variant="body1"
                           style={{padding: 32}}>{t('unsavedProductWarning')}</Typography>
    }
}

export default ProductEditorPreview
