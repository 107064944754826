import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Button, Chip, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";



const useStyles = makeStyles(theme => ({
  flexDirectionColumnContainer: {
    display: "flex",
    flexDirection: "column",
    border: `1px solid grey`,
    borderRadius: "5%",
    overflow: "hidden",
    width:"375px",
    marginTop: "1rem"
  },
  cardInformationContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "1rem 0.9375rem",
    justifyContent: "space-between"
  },
  linkCardImage: {
    width: "100%",
    height: "190px",
    objectFit: "cover",
    borderRadiusTop: `5%`,
    objectPosition: "center center"
  },
}))


export const LinkCardPreview = ({imageUrl,title, description, balloonText}) => {
  const classes = useStyles()

  return(
    <div className={classes.flexDirectionColumnContainer}>
      {imageUrl && <div style={{position: "relative"}}>
        <div style={{position: imageUrl ? "absolute" : "sticky"}}>
          {balloonText && <Chip label={balloonText} sx={{
            backgroundColor: "white",
            margin: "0.5rem",
            color: "black",
            ".MuiChip-label": {fontWeight: "bold"}
          }}/>}
        </div>
        {imageUrl && <img className={classes.linkCardImage} src={imageUrl} alt={"product illustration"}/>}
      </div>}
      <div className={classes.cardInformationContainer}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <Typography variant={"body1"} fontWeight={"bold"}>{title}</Typography>
          <Typography variant={"caption"}>{description}</Typography>
        </div>
        <Button color={"primary"}><ArrowForwardIos/></Button>
      </div>
      {!imageUrl && <div style={{position: "relative"}}>
        <div style={{position: "sticky"}}>
          {balloonText && <Chip label={balloonText} sx={{
            backgroundColor: "white",
            margin: "0.5rem",
            color: "black",
            ".MuiChip-label": {fontWeight: "bold"}
          }}/>}
        </div>
      </div>}
    </div>
  )
}



