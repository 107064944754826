import React from "react"
import {Typography} from "@mui/material"

const NewsletterSettingsHelperText = () => {
    return (
        <>
            <Typography variant="subtitle2">
                Fyll ut feltene for Mailchimp eller Make for å koble en nyhetsbrev-liste til museet.
            </Typography>
            <br/>
            <Typography variant="body2">
                Mailchimp instillingene audience id og api key finner du inne på mailchimp kontoen tilknyttet ditt
                museum/selskap.
            </Typography>
            <br/>
            <Typography variant="body2">
                Instillingene for Make finner du inne på din konto hos Make.
            </Typography>
        </>
    )
}

export default NewsletterSettingsHelperText
