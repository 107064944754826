import AddIcon from "@mui/icons-material/Add"
import React from "react"
import Button from "@mui/material/Button"
import {CardActions} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const useStyles = makeStyles(() => ({
    button: {
        marginLeft: "auto",
    },
}))

const ProductGroups = ({children, handleNewProductGroup}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    return (
        <>
            {children}
            <CardActions>
                <Button variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={handleNewProductGroup}
                        endIcon={<AddIcon/>}>
                    {t("add")}
                </Button>
            </CardActions>
        </>
    )
}

export default ProductGroups