export const uniqueSellingPointsIcons = [
    "Pregnant_Woman",
    "Add",
    "Abc",
    "Announcement",
    "Article",
    "Audiotrack",
    "Blind",
    "Brush",
    "Camera",
    "Elderly",
    "Fastfood",
    "commute",
    "percent",
    "accessible",
    "shopping_cart",
    "verified",
    "room",
    "euro_symbol",
    "event_seat",
    "free_cancellation",
    "local_parking",
    "park",
    "help",
    "Wifi",
    "done",
    "Pets"]
