import React from "react"
import makeStyles from '@mui/styles/makeStyles';
import {lighten, Toolbar, Typography} from "@mui/material"
import clsx from "clsx"
import {lowerFirst} from "lodash"
import {useBaseTranslation} from "../baseTranslationContext"

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === "light"
        ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
        : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}))

const GenericTableToolbar = ({selectable, title, numSelected, selectedButtons}) => {
    const classes = useStyles()
    const t = useBaseTranslation()

    return selectable || title
           ? <Toolbar className={clsx(classes.root, {[classes.highlight]: numSelected > 0})}>
               {numSelected > 0 ? (
                   <Typography className={classes.title}
                               color="inherit"
                               variant="subtitle1"
                               component="div">
                       {`${numSelected} ${lowerFirst(t("selected"))}`}
                   </Typography>
               ) : (
                    <Typography className={classes.title}
                                variant="h6"
                                component="div">
                        {title}
                    </Typography>
                )}

               {numSelected > 0 && selectedButtons && selectedButtons}
           </Toolbar>
           : null
}

export default GenericTableToolbar