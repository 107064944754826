import React, {useEffect, useState} from "react"
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    ClickAwayListener,
    FormLabel,
    Grid,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
    Typography,
} from "@mui/material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import SaveButtonWithProgressAndLabel from "../../../app/components/SaveButtonWithProgressAndLabel"
import {ArrowDropDown} from "@mui/icons-material"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import _ from "lodash"
import VismaVoucherPaymentSubTypesSettingsHelperText from "./VismaVoucherPaymentSubTypesSettingsHelperText"
import AlertBox from "../../../app/components/AlertBox"

const gridSizes = {xs: 12, sm: 5, md: 4, lg: 3, xl: 2}

const GridMainLabel = ({label}) => (
    <Grid item {...gridSizes}>
        <Grid container
              justifyContent="flex-end"
              alignItems="center">
            <Grid item>
                <FormLabel>{label}</FormLabel>
            </Grid>
        </Grid>
    </Grid>
)

const VismaVoucherPaymentSubTypesSettings = () => {
    const t = useBaseTranslation()
    const {umbrellaId} = useMuseumSelectorState()
    const snackbar = useCustomSnackbar()

    const [anchorEl, setAnchorEl] = useState(null)
    const [availableSubTypes, setAvailableSubTypes] = useState([])
    const [paymentSubTypes, setPaymentSubTypes] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [
        {
            data: subTypesData,
            loading: subTypesLoading,
        }, getPaymentSubTypes,
    ] = useAxiosBpn({url: `voucher_settings/museums/${umbrellaId}/payment_sub_types/debit_accounts`})

    const [
        {
            data: subTypesPutData,
            loading: subTypesPutLoading,
            error: subTypesPutError,
        }, putSubTypes,
    ] = useAxiosBpn({
        url: `voucher_settings/museums/${umbrellaId}/payment_sub_types/debit_accounts`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        getPaymentSubTypes().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getPaymentSubTypes().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getPaymentSubTypes, umbrellaId])

    useEffect(() => {
        if (subTypesData) {
            const {paymentSubTypesDebitAccounts, unusedPaymentSubTypes} = subTypesData
            setAvailableSubTypes(unusedPaymentSubTypes)
            setPaymentSubTypes(paymentSubTypesDebitAccounts)
        }
    }, [subTypesData, umbrellaId])

    useEffect(() => {
        if (subTypesPutData) {
            const {paymentSubTypesDebitAccounts, unusedPaymentSubTypes} = subTypesPutData
            snackbar.showSuccess(t("paymentCardsUpdatedSuccessfully"))
            setAvailableSubTypes(unusedPaymentSubTypes)
            setPaymentSubTypes(paymentSubTypesDebitAccounts)
            setUnsavedChanges(false)
        }
    }, [subTypesPutData, snackbar, t])

    useEffect(() => {
        if (subTypesPutError) snackbar.showError(t(subTypesPutError.message))
    }, [subTypesPutError, snackbar, t])

    const handleSave = () => {
        putSubTypes({data: paymentSubTypes})
    }

    const handleChange = (e) => {
        e.persist()
        let newArray = [...paymentSubTypes]
        let idx = newArray.findIndex(x => x.name === e.target.name)
        newArray[idx] = {...newArray[idx], debitAccount: e.target.value}
        setPaymentSubTypes(newArray)

        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleSubTypeSelect = (subType) => {
        setPaymentSubTypes(prevState => ([...prevState, {name: subType, debitAccount: null}]))
        _.remove(availableSubTypes, (c) => c === subType)
        setAnchorEl(null)
    }

    return (
        <Card square
              variant="outlined">
            <CardHeader title={t("paymentCard")}
                        subheader={t("debitAccountOnPaymentCards")}
                        titleTypographyProps={{color: "primary", variant: "h6"}}
                        subheaderTypographyProps={{variant: "subtitle2"}}
                        action={<VismaVoucherPaymentSubTypesSettingsHelperText/>}/>
            <CardContent>
                {subTypesLoading || subTypesPutLoading
                 ? <ProgressIndicator/>
                 : <Grid container
                         direction="column"
                         alignItems="center"
                         spacing={1}>
                     <Grid container
                           justifyContent="flex-end">
                         <Button variant="outlined"
                                 color="primary"
                                 onClick={(e) => setAnchorEl(e.currentTarget)}
                                 endIcon={<ArrowDropDown/>}>
                             {t("addPaymentCard")}
                         </Button>
                         <Popper open={Boolean(anchorEl)}
                                 anchorEl={anchorEl}
                                 style={{zIndex: 1500}}>
                             <Paper elevation={8}>
                                 <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                     {availableSubTypes?.length
                                      ? <MenuList> {availableSubTypes.map((subType, index) => (
                                             <MenuItem key={index}
                                                       onClick={() => handleSubTypeSelect(subType)}>
                                                 {t(subType)}
                                             </MenuItem>))}
                                      </MenuList>
                                      :
                                      <Typography style={{padding: "16px"}}>{t("noMoreAvailablePaymentCards")}!</Typography>}
                                 </ClickAwayListener>
                             </Paper>
                         </Popper>
                     </Grid>
                     {paymentSubTypes.length
                      ? paymentSubTypes?.map(({name, debitAccount}, index) => (
                             <Grid container
                                   spacing={2}
                                   key={index}
                                   alignItems="center"
                                   item
                                   style={{maxWidth: 1200}}>
                                 <GridMainLabel label={name}/>
                                 <Grid item
                                       xl={12 - (gridSizes.xl * 2)}
                                       lg={12 - (gridSizes.lg * 2)}
                                       md={12 - (gridSizes.md * 2)}
                                       sm={12 - (gridSizes.sm * 2)}
                                       xs={12}>
                                     <Grid container
                                           alignItems="center"
                                           spacing={1}>
                                         <Grid item
                                               xs>
                                             <TextField name={name}
                                                        label={t("debitAccount")}
                                                        size="small"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={debitAccount || ""}
                                                        onChange={handleChange}/>
                                         </Grid>
                                     </Grid>
                                     <Grid item  {...gridSizes}/>
                                 </Grid>
                             </Grid>
                         ))
                      : <AlertBox title={t('noPaymentCardsAdded')} text={t('noPaymentCardsAddedText')}/>}
                 </Grid>}
            </CardContent>
            <CardActions>
                <SaveButtonWithProgressAndLabel unsavedChanges={unsavedChanges}
                                                handleSave={handleSave}
                                                loading={subTypesPutLoading}/>
            </CardActions>
        </Card>
    )
}

export default VismaVoucherPaymentSubTypesSettings