import React from "react";
import { useProductEditorTranslation } from "../productEditorTranslationContext";
import TranslatableTextField from "../TranslatableTextField";
import { PRODUCT_TYPE_EVENT, PRODUCT_TYPE_SEASONAL_PASS } from "../../baseContext";
import { LinkCardPreview } from "./LinkCardPreview";

const ProductLinkCard = ({product, unsavedChanges, setUnsavedChanges, updateProductState, productType}) => {
  const t = useProductEditorTranslation()

  const getLinkCardHeading = () => {
    if (!!!product?.linkCard?.heading?.value) {
      return (productType === PRODUCT_TYPE_EVENT || productType === PRODUCT_TYPE_SEASONAL_PASS) ?
        {value: product.name?.value, translations: []} :  {value: product.museumName, translations: []}
    }

    return product.linkCard.heading
  }

  const getLinkCardSubHeading = () => {
    if (!!!product?.linkCard?.subHeading?.value) {
      return (productType === PRODUCT_TYPE_EVENT || productType === PRODUCT_TYPE_SEASONAL_PASS) ?
        {value: product?.museumName, translations: []} : {value: product?.name?.value, translations: []}
    }

    return product.linkCard.subHeading
  }

  const handleLinkCardChanged = (propertyName, value) => {
    updateProductState({
      ...product,
      linkCard: {...product.linkCard, [propertyName]: value}
    })

    if (!unsavedChanges) setUnsavedChanges(true)
  }

  return(
    <form noValidate
          autoComplete="off">
      {product &&
        <>
          <TranslatableTextField label={t("heading")}
                                 name="heading"
                                 value={getLinkCardHeading()}
                                 size="small"
                                 key={"heading"}
                                 fullWidth
                                 onChange={(updatedValue) => handleLinkCardChanged("heading", updatedValue)} />
          <TranslatableTextField label={t("linkCardSubHeading")}
                                 key={"subHeading"}
                                 name="subHeading"
                                 value={getLinkCardSubHeading()}
                                 size="small"
                                 fullWidth
                                 onChange={(updatedValue) => handleLinkCardChanged("subHeading", updatedValue)}
          />
          <LinkCardPreview imageUrl={product?.image?.thumbnail|| product?.imageUrl}
                           description={getLinkCardSubHeading().value}
                           title={getLinkCardHeading().value}/>
        </>}
    </form>)
}

export default React.memo(ProductLinkCard)
