import {ProductDetailsHeader} from "./ProductDetailsHeader"
import {TabContext, TabList, TabPanel} from "@mui/lab"
import {Grid, Tab, useTheme} from "@mui/material"
import ProductGeneralDetailsCard from "./ProductGeneralDetailsCard"
import ProductGeneralStatsCard from "./ProductGeneralStatsCard"
import ProductVisitorStatsCard from "./ProductVisitorStatsCard"
import TicketStatusCard from "../ticket-status/TicketStatusCard"
import SmallRevenueCard from "../revenue/SmallRevenueCard"
import {ChartCardAvatar} from "../components/avatars"
import SmallVisitorStatsCard from "../visitor-stats/SmallVisitorStatsCard"
import {ProductOrderTable} from "./ProductOrderTable"
import CreateOrderDialog from "../create-order-dialog/CreateOrderDialog"
import OrderTable from "../order-table/OrderTable"
import OrderDetailsDialog from "../order-details/OrderDetailsDialog"
import React, {useCallback, useEffect, useState} from "react"
import {useAxiosBpn} from "../axios"
import {DAILY} from "../helper-functions/dateFormat"
import {GROUP_BY_PRICE_CATEGORY_NAME, GROUP_BY_PRODUCT_NAME} from "../components/OrderSumsGroupBySelectMenu"
import {useBaseTranslation} from "../baseTranslationContext"
import {AllProductComments} from "./AllProductComments"
import {AllProductFormQuestionAnswers} from "./AllProductFormQuestionAnswers"
import {EmailCustomersCard} from "./EmailCustomersCard"
import {useQuery} from "../hooks/useQuery"
import {STATS_TAB} from "../../sales/visitors/Visitors"

const LOCAL_STORAGE_KEY = "bpn.testOrders"

const SALES_TAB = "sales"
const OVERVIEW_TAB = "overview"
const MESSAGING_TAB = "messaging"
const COMMENTS_TAB = "comments"
const QUESTION_ANSWERS_TAB = "questionAnswers"

const ALLOWED_TABS = [SALES_TAB, OVERVIEW_TAB, MESSAGING_TAB, COMMENTS_TAB, QUESTION_ANSWERS_TAB]

export const DetailsDashboard = ({product, testOrders, setTestOrders, productLoading}) => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()
    const query = useQuery()

    const [createOrderDialogOpen, setCreateOrderDialogOpen] = useState(false)
    const [refetchOrders, setRefetchOrders] = useState(false)
    const [selectedOrderId, setSelectedOrderId] = useState()
    const [orderDialogOpen, setOrderDialogOpen] = useState(false)
    const [frequency] = useState(DAILY)
    const [groupBy] = useState(GROUP_BY_PRICE_CATEGORY_NAME)
    const [selectedTab, setSelectedTab] = useState(SALES_TAB)
    const [eventFilterButtonHidden, setEventFilterButtonHidden] = useState(true)

    const [{data: orderSumsByDateData, loading: loadingOrderSumsByDate}, getOrderSumsByDate] = useAxiosBpn({
        url: "orders/sums",
        params: {
            groupByDateFrequency: frequency,
            test: testOrders,
            productId: product.id,
        },
    }, {manual: true})

    const [{data: visitorStatsByDateData, loading: visitorStatsByDateLoading}, getVisitorStatsByDate] = useAxiosBpn({
        url: "orders/visitors",
        params: {
            groupByDateFrequency: frequency,
            test: testOrders,
            groupBy: groupBy,
            visitorStats: true,
            productId: product.id,
        },
    }, {manual: true})

    const [{data: visitorStatsPivotData, loading: visitorStatsPivotLoading}, getVisitorStatsPivot] = useAxiosBpn({
        url: "orders/visitors",
        params: {
            test: testOrders,
            visitorStats: true,
            pivot: true,
            index: "priceCategoryName",
            productId: product.id,
        },
    }, {manual: true})

    const [{data: productScheduleEventsData}, getProductScheduleEventsData] = useAxiosBpn({
        url: "schedule_events/grouped",
        params: {productId: product?.id},
    }, {manual: true})

    useEffect(() => {
        if (productScheduleEventsData) {
            const {groupedScheduleEvents} = productScheduleEventsData
            if (Object.entries(groupedScheduleEvents).length > 1) {
                setEventFilterButtonHidden(false)
            } else {
                // eslint-disable-next-line array-callback-return
                Object.entries(groupedScheduleEvents).map(([year, months]) => {
                    if (Object.entries(months).length > 1) {
                        setEventFilterButtonHidden(false)
                    } else {
                        // eslint-disable-next-line array-callback-return
                        Object.entries(months).map(([month, days]) => {
                            if (Object.entries(days).length > 1) {
                                setEventFilterButtonHidden(false)
                            } else {
                                // eslint-disable-next-line array-callback-return
                                Object.entries(days).map(([day, events]) => {
                                    if (Object.entries(events).length > 1) {
                                        setEventFilterButtonHidden(false)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
    }, [productScheduleEventsData])


    useEffect(() => {
        if (product?.id) getProductScheduleEventsData().catch(() => {
        })
    }, [getProductScheduleEventsData, product])

    useEffect(() => {
        if (selectedOrderId) setOrderDialogOpen(true)
    }, [selectedOrderId])

    useEffect(() => {
        if (selectedTab === OVERVIEW_TAB) {
            getOrderSumsByDate().catch(() => {
                // Just to avoid annoying console error
            })
            getVisitorStatsByDate().catch(() => {
                // Just to avoid annoying console error
            })
            getVisitorStatsPivot().catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [getOrderSumsByDate, getVisitorStatsByDate, getVisitorStatsPivot, selectedTab])

    useEffect(() => {
        if (query && ALLOWED_TABS.includes(query.get('tab'))) setSelectedTab(query.get('tab'))
    }, [query])

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    const handleCreateOrderDialogOpen = () => setCreateOrderDialogOpen(true)

    const handleOrderIdClick = useCallback((_id) => {
        setSelectedOrderId(_id)
    }, [])

    const handleCreateOrderDialogClose = () => {
        setCreateOrderDialogOpen(false)
        setRefetchOrders(!refetchOrders)
    }

    const handleOrderDialogClose = () => {
        setOrderDialogOpen(false)
        setSelectedOrderId(null)
        setRefetchOrders(!refetchOrders)
    }

    const handleTabSelect = (event, newTab) => {
        setSelectedTab(newTab)
    }

    return product
        ? <>
            <ProductDetailsHeader product={product} testOrders={testOrders} setTestOrders={setTestOrders}/>
            <div style={{marginTop: "16px"}}>
                <TabContext value={selectedTab}>
                    <TabList onChange={handleTabSelect} textColor="primary" indicatorColor="primary">
                        <Tab value={OVERVIEW_TAB} label="Oversikt"/>
                        <Tab value={SALES_TAB} label="Salg"/>
                        <Tab value={MESSAGING_TAB} label={"Kundekommunikasjon"}/>
                        <Tab value={COMMENTS_TAB} label={"Kommentarer"}/>
                        <Tab value={QUESTION_ANSWERS_TAB} label={"Spørsmål/Svar"}/>
                    </TabList>

                    <TabPanel value={OVERVIEW_TAB}>
                        <Grid container spacing={1}>
                            <Grid container item xs={12} lg={5} xl={4} direction="column" spacing={1}>
                                <Grid item>
                                    <ProductGeneralDetailsCard loading={productLoading}
                                                               product={product}
                                                               testOrders={testOrders}/>
                                </Grid>
                                <Grid item>
                                    <ProductGeneralStatsCard loading={productLoading}
                                                             product={product}
                                                             testOrders={testOrders}/>
                                </Grid>
                                <Grid item>
                                    <ProductVisitorStatsCard loading={visitorStatsPivotLoading}
                                                             data={visitorStatsPivotData}
                                                             productName={product?.name}
                                                             testOrders={testOrders}/>
                                </Grid>
                                <Grid item>
                                    <TicketStatusCard testOrders={testOrders}
                                                      productId={product.id}/>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} lg={7} xl={8} spacing={1}>
                                <Grid item style={{width: "100%"}}>
                                    <SmallRevenueCard data={orderSumsByDateData}
                                                      loading={loadingOrderSumsByDate}
                                                      frequency={frequency}
                                                      testOrders={testOrders}
                                                      shortcutTo={{
                                                          label: "Gå til hovedside for omsetning (data gruppert på produktnavn)",
                                                          url: `/sales/revenue?groupBy=${GROUP_BY_PRODUCT_NAME}`
                                                      }}
                                                      title={t("revenue")}
                                                      subheader={product?.name}
                                                      avatar={<ChartCardAvatar backgroundColor={testOrders
                                                          ? testOrdersPalette?.dark
                                                          : null}/>}/>
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <SmallVisitorStatsCard data={visitorStatsByDateData}
                                                           testOrders={testOrders}
                                                           loading={visitorStatsByDateLoading}
                                                           frequency={frequency}
                                                           shortcutTo={{
                                                               label: "Gå til hovedside for besøkstall (gruppert på produktnavn)",
                                                               url: `/sales/visitors?tab=${STATS_TAB}&groupBy=${GROUP_BY_PRODUCT_NAME}`
                                                           }}
                                                           groupBy={groupBy}
                                                           title={t("visitorNumbers")}
                                                           avatar={<ChartCardAvatar backgroundColor={testOrders
                                                               ? testOrdersPalette?.dark
                                                               : null}/>}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={SALES_TAB}>
                        <ProductOrderTable testOrders={testOrders}
                                           handleCreateOrderDialogOpen={handleCreateOrderDialogOpen}>
                            <CreateOrderDialog productId={product.id}
                                               open={createOrderDialogOpen}
                                               testOrder={testOrders}
                                               handleClose={handleCreateOrderDialogClose}/>
                            <OrderTable productId={product.id}
                                        refetchOrders={refetchOrders}
                                        subtractHeight={250}
                                        registrationFormQuestion={product.registrationFormQuestion}
                                        eventFilterButtonHidden={eventFilterButtonHidden}
                                        groupedScheduleEvents={productScheduleEventsData?.groupedScheduleEvents}
                                        handleOrderIdClick={handleOrderIdClick}
                                        testOrders={testOrders}/>
                        </ProductOrderTable>
                    </TabPanel>
                    <TabPanel value={MESSAGING_TAB}>
                        <EmailCustomersCard productId={product.id}
                                            eventFilterButtonHidden={eventFilterButtonHidden}
                                            groupedScheduleEvents={productScheduleEventsData?.groupedScheduleEvents}/>
                    </TabPanel>
                    <TabPanel value={COMMENTS_TAB}>
                        <AllProductComments productId={product.id}
                                            testOrders={testOrders}
                                            freeTextFieldLabel={product.registrationFormFreetextField}
                                            productName={product.name}/>
                    </TabPanel>
                    <TabPanel value={QUESTION_ANSWERS_TAB}>
                        <AllProductFormQuestionAnswers productId={product.id}
                                                       testOrders={testOrders}
                                                       question={product.registrationFormQuestion}
                                                       productName={product.name}/>
                    </TabPanel>
                </TabContext>
            </div>
            <OrderDetailsDialog orderId={selectedOrderId}
                                open={orderDialogOpen}
                                handleClose={handleOrderDialogClose}/>
        </>
        : null
}
