import React from "react"
import {Typography} from "@mui/material"

const TermsOfSaleUrlSettingsHelperText = () => {
    return (
        <>
            <Typography variant="subtitle2">
                Kjøpsbetingelser administreres foreløpig ikke i denne løsningen.
            </Typography>
            <br/>
            <Typography variant="body2">
                Det må derfor legges inn adresse til en ekstern side med betingelser. Denne vises som en lenke
                i kjøpsportalen.
                <br/><br/>
                Adressen kan legges inn på selskap/konsolidering, og vil da gjelde for alle tilhørende museer
                så lenge noe annet ikke er angitt. For å velge egen URL på et enkeltmuseum, må dette museet
                først velges i museumsvelgeren i sidemenyen.
            </Typography>
        </>
    )
}

export default TermsOfSaleUrlSettingsHelperText