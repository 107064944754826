import React from "react"
import { Alert } from '@mui/material';

const FamilyDiscountAlerts = ({nameMissing, categoryMissing, noFreeTicketsPossible}) => {

    return (
        <>
            {nameMissing && <Alert severity={"error"} style={{marginBottom: 8}}> Navn mangler! </Alert>}
            {categoryMissing && <Alert severity={"error"} style={{marginBottom: 8}}> Priskategori mangler! </Alert>}
            {noFreeTicketsPossible && (
                <Alert severity={"error"} style={{marginBottom: 8}}>
                    Ikke mulig å oppnå gratisbilletter med valgt oppsett!
                </Alert>
            )}
        </>
    )
}

export default FamilyDiscountAlerts