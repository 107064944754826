import {Button, Card, CardContent, CardHeader, Tooltip, useTheme} from "@mui/material"

import React from "react"
import AddIcon from "@mui/icons-material/Add"
import {toLowerCase} from "../helper-functions/stringUtility"
import {useBaseTranslation} from "../baseTranslationContext"
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext"
import {ROLES_VALUES} from "../menu/MuseumSelector"

export const ProductOrderTable = ({children, handleCreateOrderDialogOpen, testOrders}) => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()
    const {selectedMuseum} = useMuseumSelectorState()

    return (
        <Card style={{marginRight: 16, backgroundColor: testOrders ? testOrdersPalette?.light : null}}>
            <CardHeader title={`${t("ticketSales")}${testOrders ? ` (${toLowerCase(t("testOrders"))})` : ""}`}
                        action={selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <span>
                                <Tooltip title={testOrders ? t('createTestOrder') : t('createOrderHelperText')}>
                                    <Button onClick={handleCreateOrderDialogOpen}
                                            startIcon={<AddIcon/>}>
                                        {testOrders ? t("testOrder") : t("order")}
                                    </Button>
                                </Tooltip>
                            </span>
                            : null
                        }/>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}
