import {ROLES, ROLES_VALUES} from "./MuseumSelector";

export const ADMIN_APP_ID = "0f99554d-4d53-4ce6-ada5-6189f3abdf52";

export const getRoleByMuseumId = (museumId, allMuseumIdsAndRoleMap, isAppAdmin, isGlobalEkulturAdmin) => {
    if (!allMuseumIdsAndRoleMap) {
        return {name: "", value: 0}
    }

    let roleName = allMuseumIdsAndRoleMap.get(museumId)
    let roleValue = getRoleValeByName(roleName)
    if (isAppAdmin) {
        roleValue = ROLES_VALUES.APP_ADMIN
        roleName = ROLES.APP_ADMIN
    }

    if (isGlobalEkulturAdmin) {
        roleValue = ROLES_VALUES.GLOBAL_ADMIN
        roleName = ROLES.GLOBAL_ADMIN
    }
    return {name: roleName, value: roleValue}
}

const getRoleValeByName = (roleName) => {
    switch (roleName) {
        case ROLES.GLOBAL_ADMIN:
            return ROLES_VALUES.GLOBAL_ADMIN
        case ROLES.APP_ADMIN:
            return ROLES_VALUES.APP_ADMIN
        case ROLES.ADMIN:
            return ROLES_VALUES.ADMIN
        case ROLES.EDITOR:
            return ROLES_VALUES.EDITOR
        case ROLES.USER:
            return ROLES_VALUES.USER
        default:
            return 0
    }
}
