import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import {
    Avatar,
    Card,
    CardHeader,
    ClickAwayListener,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@mui/material"
import NetaxeptDialog from "./NetaxeptDialog"
import EditIcon from "@mui/icons-material/Edit"
import {ArrowDropDown} from "@mui/icons-material"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import {useAxiosBpn} from "../../../app/axios"
import HelpButton from "../../../app/components/HelpButton"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    avatar: {
        backgroundColor: theme.palette.primary.dark,
    },
}))

const PaymentTypes = () => {
    const classes = useStyles()
    const t = useBaseTranslation()
    const {umbrellaId} = useMuseumSelectorState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedPaymentType, setSelectedPaymentType] = useState({})
    const [netaxeptDialogOpen, setNetaxeptDialogOpen] = useState(false)
    const [availablePaymentTypes, setAvailablePaymentTypes] = useState([])
    const [paymentTypes, setPaymentTypes] = useState([])

    const [{data: paymentTypesData, loading: paymentTypesLoading}, getPaymentTypes] = useAxiosBpn({
        url: "payment_types",
        params: {
            museumId: umbrellaId,
        },
    })

    const [{data: availablePaymentTypesData}, getAvailablePaymentTypes] = useAxiosBpn({
        url: `payment_types/${umbrellaId}/available`,
    })

    useEffect(() => {
        getPaymentTypes().catch(() => {
            // Just to avoid annoying console error
        })
        getAvailablePaymentTypes().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (paymentTypesData) {
            setPaymentTypes(paymentTypesData.paymentTypes)
        }
    }, [paymentTypesData])

    useEffect(() => {
        if (availablePaymentTypesData) {
            const {availablePaymentTypes} = availablePaymentTypesData
            setAvailablePaymentTypes(availablePaymentTypes)
        }
    }, [availablePaymentTypesData])

    function getHelpButtonText(name) {
        switch (name) {
            case "netaxept":
                return t("paymentTypeNetaxeptHelperText")
            default:
                return null
        }
    }

    const handleAddPaymentTypeClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleAddPaymentTypeClose = () => {
        setAnchorEl(null)
    }

    const handleAddPaymentTypeSelect = (paymentType) => {
        setSelectedPaymentType(paymentType)
        setAnchorEl(null)
        switch (paymentType.name) {
            case "netaxept":
                return setNetaxeptDialogOpen(true)
            default:
                return null
        }
    }

    const handleNetaxeptDialogClose = () => {
        setNetaxeptDialogOpen(false)
        setSelectedPaymentType({})
        getPaymentTypes()
        getAvailablePaymentTypes()
    }

    return (
        <div>
            <Grid container
                  justifyContent="flex-end">
                <Button variant="outlined"
                        color="primary"
                        onClick={handleAddPaymentTypeClick}
                        endIcon={<ArrowDropDown/>}>
                    {t("addPaymentType")}
                </Button>
                <Popper open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        style={{zIndex: 1500}}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleAddPaymentTypeClose}>
                            {availablePaymentTypes?.length
                                ? <MenuList> {availablePaymentTypes.map((availablePaymentType, index) => (
                                    <MenuItem key={index}
                                              onClick={() => handleAddPaymentTypeSelect(availablePaymentType)}>
                                        {`${t(availablePaymentType.name)} (${availablePaymentType.test
                                            ? t("testEnvironment")
                                            : t("productionEnvironment")})`}
                                    </MenuItem>))}
                                </MenuList>
                                :
                                <Typography style={{padding: "16px"}}>{t("noMoreAvailablePaymentTypes")}!</Typography>}
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Grid>
            <NetaxeptDialog open={netaxeptDialogOpen}
                            paymentType={selectedPaymentType}
                            handleClose={handleNetaxeptDialogClose}/>
            {paymentTypes && paymentTypes.length
                ? paymentTypesLoading
                    ? <ProgressIndicator/>
                    : paymentTypes.map((paymentType, index) =>
                        <Card key={index}
                              square
                              variant="outlined"
                              style={{marginTop: "8px"}}>
                            <CardHeader title={<Typography variant="button">{t(paymentType.name)}</Typography>}
                                        avatar={
                                            <Avatar variant="square"
                                                    className={classes.avatar}>
                                                {t(paymentType.name).substring(0, 2)}
                                            </Avatar>}
                                        action={
                                            <>
                                                <HelpButton>{getHelpButtonText(paymentType.name)}</HelpButton>
                                                <IconButton onClick={() => handleAddPaymentTypeSelect(paymentType)} size="large">
                                                    <EditIcon/>
                                                </IconButton>
                                            </>
                                        }
                                        subheader={paymentType.test
                                            ? t("testEnvironment")
                                            : t("productionEnvironment")}/>
                        </Card>)
                : <Typography style={{margin: "16px"}}>{t("noPaymentTypesAdded")}!</Typography>}
        </div>
    );
}

export default PaymentTypes