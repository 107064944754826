import React, {useEffect, useState} from "react"
import {useBaseContextState} from "../../app/baseContext"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import EditorCardModule from "../../app/components/EditorCardModule"
import {useAxiosBpn} from "../../app/axios"
import {useTheme} from "@mui/material/styles"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import {ProgressIndicator} from "../../app/ProgressIndicator"
import ApiClients from "./ApiClients"
import ApiClient from "./ApiClient"

const ApiSettings = ({
                         showSectionHeader = true,
                     }) => {
    const {spacing} = useTheme()
    const t = useBaseTranslation()
    const {currentBaseUrl} = useBaseContextState()
    const {umbrellaName, umbrellaId} = useMuseumSelectorState()
    const [activeBreadcrumb, setActiveBreadcrumb] = useState(t("api"))
    const [apiClients, setApiClients] = useState([])

    const [{data, loading}, getApiClients] = useAxiosBpn({url: "api_clients", params: {museumId: umbrellaId}})

    useEffect(() => {
        getApiClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (data) setApiClients(data?.apiClients)
    }, [data])

    useEffect(() => {
        setActiveBreadcrumb(`${t("products")} (${umbrellaName})`)
    }, [t, umbrellaName])

    const breadcrumbs = [
        {label: t("settings"), url: currentBaseUrl},
        {label: activeBreadcrumb},
    ]

    const handleNewApiClient = () => {
        setApiClients(prevState => [...prevState, {new: true}])
    }

    const handleApiClientChange = (index, apiClient) => {
        let newApiClients = [...apiClients]
        newApiClients[index] = apiClient
        setApiClients(newApiClients)
    }

    const removeFromApiClients = index => {
        let newApiClients = apiClients
        newApiClients.splice(index, 1)
        setApiClients([...newApiClients])
    }

    return (
        <>
            {showSectionHeader && <SectionHeader breadcrumbs={breadcrumbs}/>}
            <ScrollableSectionView>
                <div style={{paddingRight: spacing(1)}}>
                    <EditorCardModule expanded={true}
                                      title="apiClients">
                        <ApiClients handleNewApiClient={handleNewApiClient}>
                            {loading
                             ? <ProgressIndicator/>
                             : apiClients?.map((apiClient, index) => (
                                    <ApiClient data={apiClient}
                                               key={index}
                                               index={index}
                                               submitChange={handleApiClientChange}
                                               removeFromApiClients={removeFromApiClients}/>
                                ))}
                        </ApiClients>
                    </EditorCardModule>
                </div>
            </ScrollableSectionView>
        </>
    )
}

export default ApiSettings