// noinspection NpmUsedModulesInstalled
import {codeToToken} from "@ekultur/authentication"
import {useEffect} from "react"
import {useHistory, useLocation} from "react-router-dom"
import {useAuthsDispatch, USER_LOGGED_IN} from "./authsContext"

export const Callback = () => {
    const location = useLocation()
    const dispatch = useAuthsDispatch()
    const history = useHistory()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        codeToToken({
            code: searchParams.get("code"),
            state: searchParams.get("state"),
            apiGateway: window._env_.REACT_APP_API_GATEWAY,
            onSuccess: data => {
                dispatch({type: USER_LOGGED_IN})
                history.push(data.previousPath)
            },
            sameSite: process.env.REACT_APP_ENVIRONMENT === 'beta'
        })
    }, [dispatch, history, location])

    return null
}
