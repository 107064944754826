import React from "react";
import { nokPercent, nokPrice } from "../helper-functions/numberFormat";
import {
  convertValidFromToDatetime,
  convertValidToToDatetime,
  dateFormat,
  datetimeToExcelDate
} from "../helper-functions/dateFormat";
import { parse } from "date-fns";
import { Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import { getSubstring } from "../helper-functions/stringUtility";

export const createOrderLinesTableHeaders = (_orderLines,
                                             t,
                                             handleOrderIdClick,
                                             smDown = false,
                                             xsDown = false,
                                             kdr = false) => [
  {field: "orderId", headerName: t("orderId"), onClick: handleOrderIdClick},
  {field: "orderExternalId", headerName: t("externalId"), hide: xsDown},
  {field: "orderDate", headerName: t("orderDate"), type: "datetime", flex: 2},
  {field: "orderStatus", headerName: t("status")},
  {field: "museumName", headerName: t("museum")},
  {field: "productName", headerName: t("product"), flex: 3, align: "left"},
  {field: "scheduleEventName", headerName: t("scheduleEvent"), flex: 2, align: "left"},
  {field: "validFrom", headerName: t("validFrom"), type: "datetime", flex: 2},
  {field: "validTo", headerName: t("validTo"), type: "datetime", flex: 2},
  {field: "productType", headerName: t("type")},
  {field: "productPriceCategoryName", headerName: t("priceCategory"), flex: 2},
  {field: "quantity", headerName: t("quantity")},
  {field: "price", headerName: t("price"), ...nokPrice, align: "left", hide: smDown},
  {field: "originalPriceIfDiscount", headerName: t("discountPercentage"), ...nokPercent, align: "left", hide: smDown},
  {field: "discountCode", headerName: t("discountCode"), hide: smDown},
  {field: "jointDiscount", headerName: t("jointDiscount"), hide: smDown},
  {field: "familyDiscount", headerName: t("familyDiscount"), hide: smDown},
  {field: "vat", headerName: t("vat"), ...nokPercent, align: "center", hide: smDown},
  {field: "vatAmount", headerName: t("vatAmount"), ...nokPrice, align: "left", hide: smDown},
  {field: "sum", headerName: t("sum"), ...nokPrice, align: "left", hide: smDown},
  {field: "paymentTypeName", headerName: t("paymentType"), hide: xsDown},
  {field: "transactionId", headerName: t("transactionId"), align: "left", hide: smDown},
  {field: "paymentSubtype", headerName: t("cardType"), hide: smDown},
  {field: "walletIssuer", headerName: t("paymentSolution"), hide: smDown},
  {field: "pointOfSaleSystemName", headerName: t("pointOfSaleSystem"), hide: !kdr},
  {field: "kdrStatus", headerName: t("kdrStatus"), hide: !kdr},
  {field: "kdrExportedAt", headerName: t("kdrExported"), hide: !kdr},
  {field: "customerId", headerName: t("customerId"), type: "number", align: "center", hide: xsDown},
  {field: "customerName", headerName: t("name"), align: "left", flex: 2},
  {field: "customerPhone", headerName: t("phone"), align: "left", flex: 2, hide: smDown},
  {field: "customerEmail", headerName: t("email"), align: "left", flex: 3, hide: smDown},
  {field: "internalComment", headerName: t("internalComment"), align: "left", flex: 5},
  {field: "externalComment", headerName: t("externalComment"), align: "left", flex: 5},
  {field: "question", headerName: t("question"), align: "left", flex: 3, hide: smDown},
  {field: "answer", headerName: t("answer"), align: "left", flex: 3, hide: smDown},
  {field: "referrer", headerName: t("referrer"), align: "left", flex: 3, hide: smDown},
]

export const createOrderTableHeaders = (t, handleOrderIdClick, smDown, xsDown, kdr, mini, productId = null) => [
  {field: "orderId", headerName: t("orderId"), onClick: handleOrderIdClick ? handleOrderIdClick : null},
  {field: "orderExternalId", headerName: t("externalId"), hide: xsDown},
  {field: "orderDate", headerName: t("orderDate"), type: "datetime", flex: 2},
  {field: "orderStatus", headerName: t("status")},
  {field: "paymentTypeName", headerName: t("paymentType"), hide: mini || xsDown},
  {field: "museums", headerName: t("museums"), hide: mini || smDown},
  {field: "products", headerName: t("products"), hide: smDown},
  {field: "sum", headerName: t("sum"), ...nokPrice, align: "left", hide: smDown},
  {field: "kdrStatus", headerName: t("kdrStatus"), hide: !kdr},
  {field: "kdrExportedAt", headerName: t("kdrExported"), hide: !kdr},
  {field: "emailReceiptStatus", headerName: t("emailStatus"), hide: mini || xsDown},
  {field: "emailReceiptSentAt", headerName: t("emailSentAt"), hide: mini || xsDown},
  {field: "customerId", headerName: t("customerId"), type: "number", align: "center", hide: mini || xsDown},
  {field: "customerName", headerName: t("name"), align: "left", flex: 2, hide: mini},
  {field: "customerEmail", headerName: t("email"), align: "left", flex: 3, hide: mini || smDown},
  {field: "customerPhone", headerName: t("phone"), align: "left", flex: 3, hide: mini || smDown},
  {field: "customerPostalCode", headerName: t("postalCode"), align: "left", flex: 3, hide: mini || smDown},
  {field: "giftedToName", headerName: t("giftedTo"), align: "left", flex: 3, hide: mini || smDown},
  {field: "internalComment", headerName: t("internalComment"), align: "left", flex: 3, hide: mini || smDown},
  {
    field: "externalComment",
    headerName: t("externalComment"),
    align: "left",
    flex: 3,
    hide: mini || smDown || !!!productId
  },
  {field: "question", headerName: t("question"), align: "left", flex: 3, hide: mini || smDown},
  {field: "answer", headerName: t("answer"), align: "left", flex: 3, hide: mini || smDown},
  {field: "referrer", headerName: t("referrer"), align: "left", flex: 3, hide: mini || smDown},
]

const createCompactOrderLinesTableHeaders = (t, smDown, mini) => [
  {field: "museumName", headerName: t("museum")},
  {field: "productName", headerName: t("product"), flex: 2, align: "left"},
  {field: "scheduleEventName", headerName: t("scheduleEvent"), flex: 1, align: "left"},
  {field: "validFrom", headerName: t("validFrom"), type: "datetime", flex: 2},
  {field: "validTo", headerName: t("validTo"), type: "datetime", flex: 2},
  {field: "productType", headerName: t("type"), hide: mini},
  {field: "productPriceCategoryName", headerName: t("priceCategory"), flex: 2},
  {field: "quantity", headerName: t("quantity")},
  {field: "price", headerName: t("price"), ...nokPrice, align: "left", hide: smDown},
  {field: "originalPriceIfDiscount", headerName: t("discount"), ...nokPercent, align: "left", hide: mini || smDown},
  {field: "vat", headerName: t("vat"), ...nokPercent, align: "center", hide: mini || smDown},
  {field: "vatAmount", headerName: t("vatAmount"), ...nokPrice, align: "left", hide: mini || smDown},
  {field: "sum", headerName: t("sum"), ...nokPrice, align: "left", hide: smDown},
]

const createCompactOrderLinesTableRows = (_orderLines, t) => {
  return _orderLines?.map(({
                             museumName,
                             productName,
                             productType,
                             productPriceCategoryName,
                             scheduleEventName,
                             startDate,
                             startTime,
                             endDate,
                             endTime,
                             quantity,
                             price,
                             vat,
                             originalPriceIfDiscount,
                           }) => ({
    museumName: museumName,
    productName: productName,
    scheduleEventName: scheduleEventName,
    validFrom: dateFormat(convertValidFromToDatetime(startDate, startTime), "Pp"),
    validTo: dateFormat(convertValidToToDatetime(startDate, endDate, endTime), "Pp"),
    productType: t(productType),
    productPriceCategoryName: productPriceCategoryName,
    quantity: quantity,
    price: price,
    originalPriceIfDiscount: calculateTotalDiscount(price, originalPriceIfDiscount),
    vat: vat,
    vatAmount: calculateVat(quantity * price, vat),
    sum: (quantity * price),
  }))
}

export const createOrderTableRows = (_orders, t, smDown, mini, productId = null, registrationFormQuestion = '') => {
  return _orders?.map(({
                         id,
                         externalId,
                         createdAt,
                         status,
                         paymentTypeName,
                         kdrStatus,
                         kdrExportedAt,
                         emailReceiptStatus,
                         emailReceiptSentAt,
                         customer,
                         giftedToFirstName,
                         giftedToLastName,
                         orderLines,
                         comment,
                         comments,
                         questionAnswers,
                         formQuestionAnswers,
                         referrer,
                       }) => {
    const tooltipIfMultipleOrLong = (items, label) => {
      if (items?.length > 1) {
        return (
          <Tooltip title={<>{items.map((m, i) => (<div key={i}>{m}</div>))}</>}>
            <Typography noWrap variant="caption">{`${items.length} ${label}...`}</Typography>
          </Tooltip>
        )
      }
      if (items[0].length > 12) {
        return (
          <Tooltip title={items[0]}>
            <Typography noWrap variant="caption">{getSubstring(items[0], 15)}</Typography>
          </Tooltip>
        )
      }
      return items
    }

    const questionAnswersParsed = JSON.parse(questionAnswers)
    const products = [...new Set(orderLines.map(line => line.productName))]
    const museums = [...new Set(orderLines.map(line => line.museumName))]
    const sum = orderLines.map(line => line.quantity * line.price).reduce((prev, curr) => prev + curr, 0)

    return {
      orderId: id,
      orderExternalId: externalId,
      orderDate: dateFormat(new Date(createdAt), "Pp"),
      orderStatus: t(status),
      paymentTypeName: t(paymentTypeName),
      museums: tooltipIfMultipleOrLong(museums, _.lowerFirst(t("museums"))),
      products: tooltipIfMultipleOrLong(products, _.lowerFirst(t("products"))),
      sum: sum,
      kdrStatus: t(kdrStatus),
      kdrExportedAt: kdrExportedAt ? dateFormat(new Date(kdrExportedAt), "Pp") : "",
      emailReceiptStatus: t(emailReceiptStatus),
      emailReceiptSentAt: emailReceiptSentAt ? dateFormat(new Date(emailReceiptSentAt), "Pp") : "",
      customerId: customer ? customer.id : "",
      customerName: customer ? `${customer.firstName} ${customer.lastName}` : "",
      customerEmail: customer ? customer.email : "",
      customerPhone: customer ? customer.phone : "",
      customerPostalCode: customer ? customer.address?.postalCode : "",
      giftedToName: `${giftedToFirstName ? giftedToFirstName : ""} ${giftedToLastName ? giftedToLastName : ""}`,
      question: formQuestionAnswers?.length ? registrationFormQuestion : "",
      answer: formQuestionAnswers?.length ? formQuestionAnswers.map(o => o.answer).join(", ") : "",
      referrer: referrer,
      internalComment: comment?.replace(/.{60}/g, "$&\n") || "",
      externalComment: comments.find(comment => comment.productId === productId)?.comment || "",
      collapsibles: [
        {
          title: t("orderLines"),
          columns: createCompactOrderLinesTableHeaders(t, smDown, mini),
          rows: createCompactOrderLinesTableRows(orderLines, t),
        },
      ],
    };
  });
}

export const createOrderLinesTableRows = (_orderLines, t, discountCodes, orders = [], registrationFormQuestion = '') => {
  return _orderLines?.map(({
                             comment,
                             orderId,
                             orderExternalId,
                             orderCreatedAt,
                             orderStatus,
                             museumName,
                             productName,
                             productId,
                             scheduleEventName,
                             productType,
                             productPriceCategoryName,
                             paymentTypeName,
                             paymentSubtype,
                             startDate,
                             startTime,
                             endDate,
                             originalPriceIfDiscount,
                             endTime,
                             walletIssuer,
                             transactionId,
                             pointOfSaleSystemName,
                             kdrStatus,
                             kdrExportedAt,
                             quantity,
                             price,
                             vat,
                             customer,
                             appliedDiscountCodeId,
                             jointProductsDiscountName,
                             familyDiscountName
                           }) => {
      const order = orders.find(order => order.id === orderId);
      return {
          orderId: orderId,
          orderExternalId: orderExternalId,
          orderDate: dateFormat(new Date(orderCreatedAt), "Pp"),
          orderStatus: t(orderStatus),
          museumName: museumName,
          productName: productName,
          scheduleEventName: scheduleEventName,
          validFrom: dateFormat(convertValidFromToDatetime(startDate, startTime), "Pp"),
          validTo: dateFormat(convertValidToToDatetime(startDate, endDate, endTime), "Pp"),
          productType: t(productType),
          productPriceCategoryName: productPriceCategoryName,
          quantity: quantity,
          price: price,
          originalPriceIfDiscount: calculateTotalDiscount(price, originalPriceIfDiscount),
          discountCode: !!discountCodes ? discountCodes.find(discountCode => discountCode.id === appliedDiscountCodeId)?.code || "" : "",
          jointProductsDiscountName: jointProductsDiscountName,
          familyDiscount: familyDiscountName,
          vat: vat,
          vatAmount: calculateVat(quantity * price, vat),
          sum: (quantity * price),
          paymentTypeName: t(paymentTypeName),
          transactionId: transactionId,
          paymentSubtype: paymentSubtype,
          walletIssuer: walletIssuer,
          pointOfSaleSystemName: t(pointOfSaleSystemName),
          kdrStatus: t(kdrStatus),
          kdrExportedAt: kdrExportedAt ? dateFormat(new Date(kdrExportedAt), "Pp") : "",
          customerId: customer ? customer.id : "",
          customerName: customer ? `${customer.firstName} ${customer.lastName}` : "",
          customerPhone: customer ? `${customer.phone}` : "",
          customerEmail: customer ? customer.email : "",
          internalComment: comment,
          externalComment: order?.comments.find(comment => comment.productId === productId)?.comment || "",
          question: order?.formQuestionAnswers?.length ? registrationFormQuestion : "",
          answer: order?.formQuestionAnswers?.length ? order.formQuestionAnswers.map(o => o.answer).join(", ") : "",
          referrer: order?.referrer,
      };
  })
}

export const createExcelDatasetFromOrderData = (headers, rows) => {
  const columns = headers.map(({headerName, flex}) => ({title: headerName, width: {wpx: flex * 80 || 80}}))
  let data = []

  if (rows) {
    rows.map((row) => {
      let newRow = []

      for (const [key, value] of Object.entries(row)) {
        const _header = headers.find(h => h.field === key)

        if (_header?.type) {
          if (_header.type === "datetime") {
            if (value) {
              const _dt = parse(value, "dd.MM.yyyy HH:mm", new Date())
              const _xlsxDate = datetimeToExcelDate(_dt)
              newRow.push({value: _xlsxDate, style: {numFmt: "dd.mm.yyyy hh:mm"}})
            } else {
              newRow.push({value: ""})
            }

          } else if (_header.type === "date") {
            if (value) {
              const _dt = parse(value, "dd.MM.yyyy", new Date())
              const _xlsxDate = datetimeToExcelDate(_dt)
              newRow.push({value: _xlsxDate, style: {numFmt: "dd.mm.yyyy"}})
            } else {
              newRow.push({value: ""})
            }
          } else {
            newRow.push({value: value || ""})
          }
        } else {
          newRow.push({value: value || ""})
        }
      }

      return data.push(newRow)
    })
  }
  return [{columns: columns, data: data}]
}

const calculateTotalDiscount = (price, originalPrice) => {
  if ((!!!price && price !== 0) || !!!originalPrice) {
    return null
  }

  return (1 - (price / originalPrice)) * 100
}

const calculateVat = (basis, vatPercentage) => {
  return basis - (basis / (1 + (vatPercentage) / 100))
}
