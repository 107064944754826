import React, { useEffect, useMemo } from "react";
import { useAxiosBpn } from "../axios";
import { ProgressIndicator } from "../ProgressIndicator";
import { isConfirmed } from "../helper-functions/orderStatuses";
import OrderDetailsDialogMainTicketCard from "./OrderDetailsDialogMainTicketCard";


const OrderDetailsDialogTicketDetails = ({order}) => {
    const [
        {
            data: fullTicketInfo,
            loading,
        }, getFullTicketInfo,
    ] = useAxiosBpn({
        url: `orders/tickets/${order.externalId}`,
        method: "GET",
    }, {manual: true})

    useEffect(() => {
        if (isConfirmed(order?.status)) getFullTicketInfo()
    }, [getFullTicketInfo, order])

    const mainTickets = useMemo(() => {
        const uniqueMainTickets = []
        const getSubTicketsForMainTicket = (mainTicket) => {
            return fullTicketInfo.order.tickets.filter(ticket => ticket.parentId === mainTicket.id)
        }

        if (fullTicketInfo && fullTicketInfo.order.tickets) {
            fullTicketInfo.order.tickets.forEach(ticket => {
                if (!uniqueMainTickets.find((mainTicket => mainTicket?.code === ticket.mainTicket?.code))) {
                    uniqueMainTickets.push({...ticket.mainTicket, subTickets: getSubTicketsForMainTicket(ticket.mainTicket)})
                }
            })
        }

        return uniqueMainTickets
    }, [fullTicketInfo])

    const getMainTickets = () => (
      mainTickets.map(mainTicket => {
          return  <OrderDetailsDialogMainTicketCard  key={mainTicket.code} mainTicket={mainTicket} order={order}/>
      })
    )

    return loading ? <ProgressIndicator/> : getMainTickets()
}

export default OrderDetailsDialogTicketDetails
