import {CardActions, Typography} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import React from "react"
import Button from "@mui/material/Button"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const FamilyDiscounts = ({children, handleNewFamilyDiscount, addDisabled}) => {
    const t = useBaseTranslation()

    return (
        <>
            {children}
            {addDisabled && (<Typography color="error">{t("canNotAddFamilyDiscountWithoutPriceCategory")}!</Typography>)}
            <CardActions>
                <Button variant="outlined"
                        color="primary"
                        disabled={addDisabled}
                        style={{marginLeft: "auto"}}
                        onClick={handleNewFamilyDiscount}
                        endIcon={<AddIcon/>}>
                    {t("add")}
                </Button>
            </CardActions>
        </>
    )
}

export default FamilyDiscounts