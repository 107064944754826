import React, {useEffect, useState} from "react"
import {getStartAndEndOfMonth} from "../../helper-functions/dateFormat"
import {useAxiosBpn} from "../../axios"
import {getUnixTime} from "date-fns"
import {ProgressIndicator} from "../../ProgressIndicator"
import Calendar from "../../components/Calendar"
import {Paper, Snackbar, Typography} from "@mui/material"
import { Alert } from '@mui/material';

const ProductEditorCalendar = ({product, refetchProduct, unsavedChanges}) => {
    const [timeInterval, setTimeInterval] = useState(getStartAndEndOfMonth())
    const [scheduleEvents, setScheduleEvents] = useState([])
    const [unsavedAlertOpen, setUnsavedAlertOpen] = useState(false)

    const [{data, loading}, getScheduleEvents] = useAxiosBpn({
        url: "schedule_events",
        params: {
            productId: product?.id,
            after: getUnixTime(timeInterval?.start) * 1000,
            before: getUnixTime(timeInterval?.end) * 1000,
            expandRecurring: true,
            sortRecurring: true,
            includeStats: true,
            includeTestTickets: true,
        },
    })

    useEffect(() => {
        setUnsavedAlertOpen(unsavedChanges)
    }, [unsavedChanges])

    useEffect(() => {
        if (data) {
            setScheduleEvents(data.scheduleEvents)
        }
    }, [data])

    useEffect(() => {
        getScheduleEvents().catch(() => {})
    }, [getScheduleEvents])

    if (product?.id) {
        return loading
               ? <ProgressIndicator/>
               : <Paper style={{margin: 8, padding: 8, height: "95%"}}
                        square>
                   <Snackbar anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                             open={unsavedAlertOpen}>
                       <Alert severity="info"
                              onClose={() => setUnsavedAlertOpen(false)}>
                           Det finnes ulagrede kalenderhendelser!
                           Du må trykke lagre før endringene vises i kalenderen.
                       </Alert>
                   </Snackbar>
                   <Calendar scheduleEvents={scheduleEvents}
                             editable={true}
                             timeInterval={timeInterval}
                             setTimeInterval={setTimeInterval}
                             refetch={getScheduleEvents}
                             refetchProduct={refetchProduct}
                   />
               </Paper>
    } else {
        return <Typography variant="caption"
                           style={{padding: 32}}>Produktet må lagres før kalender kan vises</Typography>
    }
}

export default ProductEditorCalendar