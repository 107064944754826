import {Icon, IconButton, TableCell} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useEffect} from "react";
import {useAxiosBpn} from "../../../app/axios";
import {ProgressIndicator} from "../../../app/ProgressIndicator";
import useCustomSnackbar from "../../../app/components/useCustomSnackbar";
import {useBaseTranslation} from "../../../app/baseTranslationContext";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import TableContainer from "@mui/material/TableContainer";
import {getSubstring} from "../../../app/helper-functions/stringUtility";

const UniqueSellingPointsTable = ({uniqueSellingPoints, onEditUniqueSellingPoint, removeFromUniqueSellingPoints}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()

    const [{loading: deleteLoading, error: deleteError}, deleteSellingPoint] = useAxiosBpn({}, {manual: true})

    useEffect(() => {
        if (deleteError) snackbar.showError(t(deleteError.message))
    }, [deleteError, snackbar, t])


    const handleDelete = (index, uniqueSellingPointId) => {
        deleteSellingPoint({url: `unique_selling_points/delete/${uniqueSellingPointId}`, method: "PATCH"}).then(() => {
            snackbar.showSuccess(`${t("uniqueSellingPoint")} ${t("deleted").toLowerCase()}`)
            removeFromUniqueSellingPoints(index)
        })
    }

    const removeHTMLTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    return <>
        {deleteLoading ? <ProgressIndicator/> : (
            <TableContainer>
                <Table sx={{minWidth: 650}}
                       aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t("icon")}</TableCell>
                            <TableCell align="center">{t("title")}</TableCell>
                            <TableCell align="left">{t("description")}</TableCell>
                            <TableCell align="center">{t("hasTranslation")}</TableCell>
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {uniqueSellingPoints.map((uniqueSellingPoint, index) => (
                            <TableRow key={uniqueSellingPoint.id}
                                      sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                <TableCell align="center"
                                           component="th"
                                           scope="row">
                                    <Icon fontSize={"small"}>{uniqueSellingPoint.icon.toLowerCase()}</Icon>
                                </TableCell>
                                <TableCell align="center">{getSubstring(uniqueSellingPoint.title, 90)}</TableCell>
                                <TableCell align="left">
                                    {uniqueSellingPoint.description.length > 1 &&
                                        <div dangerouslySetInnerHTML={{__html: removeHTMLTags(getSubstring(uniqueSellingPoint.description, 90))}}/>}
                                </TableCell>
                                <TableCell align="center">
                                    {uniqueSellingPoint?.translations?.length > 0 ? "✓" : ""}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => onEditUniqueSellingPoint(uniqueSellingPoint)}
                                        starticon={<EditIcon/>}
                                        size="large">
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(index, uniqueSellingPoint.id)} size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )}
    </>;
}

export default UniqueSellingPointsTable
