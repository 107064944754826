import {Grid} from "@mui/material"
import React, {useEffect, useState} from "react"
import {useBaseContextState} from "../../app/baseContext"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import PaymentTypes from "./payment-types/PaymentTypes"
import PointOfSaleSystems from "./point-of-sale-systems/PointOfSaleSystems"
import EditorCardModule from "../../app/components/EditorCardModule"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import ProductPaymentTypeHelperText from "./payment-types/ProductPaymentTypeHelperText"
import {useBaseTranslation} from "../../app/baseTranslationContext"

const PaymentSettings = ({showSectionHeader = true}) => {
    const t = useBaseTranslation()
    const {currentBaseUrl} = useBaseContextState()
    const {umbrellaName} = useMuseumSelectorState()
    const [activeBreadcrumb, setActiveBreadcrumb] = useState(t("payment"))

    useEffect(() => {
        setActiveBreadcrumb(`${t("payment")} (${umbrellaName})`)
    }, [t, umbrellaName])

    const breadcrumbs = [
        {label: t("settings"), url: currentBaseUrl},
        {label: activeBreadcrumb},
    ]

    return (
        <>
            {showSectionHeader && <SectionHeader breadcrumbs={breadcrumbs}/>}
            <ScrollableSectionView>
                <Grid container
                      direction="column"
                      spacing={1}>
                    <Grid item>
                        <EditorCardModule expanded={true}
                                          subheader={t("paymentTypeModuleHelperText")}
                                          helperTextComponent={<ProductPaymentTypeHelperText/>}
                                          title="paymentTypes">
                            <PaymentTypes/>
                        </EditorCardModule>
                    </Grid>
                    <Grid item>
                        <EditorCardModule title="pointOfSaleSystems"
                                          subheader={t("pointOfSaleSystemsModuleHelperText")}>
                            <PointOfSaleSystems/>
                        </EditorCardModule>
                    </Grid>
                </Grid>
            </ScrollableSectionView>
        </>
    )
}

export default PaymentSettings