import {AccordionSummary, Grid, Tooltip, Typography} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import {parse} from "date-fns"
import React from "react"
import {dateFormat} from "../../helper-functions/dateFormat"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import {toSentenceCase} from "../../helper-functions/stringUtility"
import {extractPattern, getRecurringPatternDescription} from "../../helper-functions/scheduleEventHelper"
import {ConfirmationNumber, ExpandMore, Museum, Repeat} from "@mui/icons-material"

const useStyles = makeStyles(theme => ({
    month: {
        fontSize: theme.typography.pxToRem(16),
        textTransform: "uppercase",
        marginBottom: theme.spacing(-1),
    },
    dayOfMonth: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: "bold",
    },
    alert: {
        color: "#d32f2f",
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeader: {
        fontSize: theme.typography.pxToRem(13),
        color: theme.palette.text.secondary,
    },
}))

const ScheduleEventSummary = ({event}) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()

    function getSecondaryHeader() {
        if (event.isRecurring) {
            return getRecurringPatternDescription(t, extractPattern(event), event?.startDate, event?.endDate)
        } else {
            return `${toSentenceCase(dateFormat(new Date(event.startDate), "ccc"))} - 
            ${event.isFullDay
                ? t("isFullDay")
                : dateFormat(parse(event.startTime, "HH:mm:ss", new Date()), "HH:mm")}`
        }
    }

    function getDayOfMonth() {
        return dateFormat(new Date(event.startDate), "dd")
    }

    function getMonth() {
        try {
            return dateFormat(new Date(event.startDate), "MMM").substring(0, 3)
        } catch (e) {
            return ''
        }
    }

    function getTooltipTitle() {
        const pattern = extractPattern(event)
        return getRecurringPatternDescription(t, pattern, event?.startDate, event?.endDate)
    }

    function getTicketTooltip() {
        return (
            <>
                {event?.ticketsSold
                    ? <Typography variant={"h6"}>
                        {`${event?.ticketsSold?.toLocaleString()} billett${event?.ticketsSold > 1 ? 'er' : ''} solgt`}
                    </Typography>
                    : null}
                {!event?.ticketsSold && event?.testTicketsSold
                    ? <Typography variant={"body2"}>
                        {`${event?.testTicketsSold?.toLocaleString()} testbillett${event?.testTicketsSold > 1 ? 'er' : ''} "solgt"`}
                    </Typography>
                    : null}
                {!event?.ticketsSold && !event?.testTicketsSold && (
                    <Typography>Ingen billetter solgt enda</Typography>
                )}
            </>
        )
    }

    return <>
        {event && (
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Grid container
                      wrap="nowrap"
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-evenly">

                    <Grid container
                          item
                          direction="column"
                          alignItems="center"
                          xs={2}>
                        {event?.isRecurring
                            ? <Grid item>
                                <Tooltip title={getTooltipTitle()}>
                                    <Repeat fontSize="large"/>
                                </Tooltip>
                            </Grid>
                            : <>
                                <Grid item>
                                    <Typography className={classes.month}>
                                        {getMonth()}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.dayOfMonth}>
                                        {getDayOfMonth()}
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </Grid>

                    <Grid container
                          direction="column"
                          item
                          xs>
                        <Grid item>
                            <Typography className={classes.secondaryHeader}>
                                {getSecondaryHeader()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <b>{event?.name?.translations ? event?.name?.value : event.name}</b>
                            </Typography>
                        </Grid>
                        <Grid container
                              item
                              spacing={1}>
                            <Grid item>
                                <Museum color="disabled"
                                        fontSize="small"/>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.secondaryHeader}>
                                    {event.museumName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tooltip title={getTicketTooltip()}>
                            <ConfirmationNumber
                                color={event?.ticketsSold || event?.testTicketsSold ? "error" : "disabled"}/>
                        </Tooltip>
                    </Grid>
                </Grid>
            </AccordionSummary>
        )}
    </>
}

export default ScheduleEventSummary