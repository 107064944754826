import {DialogTitle, Grid} from "@mui/material"
import {ProgressIndicator} from "../../ProgressIndicator"
import Button from "@mui/material/Button"
import EventIcon from "@mui/icons-material/Event"
import React from "react"
import {useBaseTranslation} from "../../baseTranslationContext"

const ScheduleEventExceptionsDialogTitle = ({groupedScheduleEvents, loading, handleEventDialogOpen, eventLabel}) => {
    const t = useBaseTranslation()

    return (
        <DialogTitle>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>{t("addException")}</Grid>
                <Grid item>
                    {loading
                        ? <ProgressIndicator linear={false}/>
                        : groupedScheduleEvents && (
                        <Button onClick={handleEventDialogOpen}
                                variant="text"
                                color="primary"
                                endIcon={<EventIcon/>}>
                            {eventLabel}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </DialogTitle>
    )
}

export default ScheduleEventExceptionsDialogTitle