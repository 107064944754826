import React from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material"

const ConfirmationDialog = ({open, handleClose, handleAccept, children}) => {
    const t = useBaseTranslation()

    return (
        <Dialog open={open}
                onClose={handleClose}>
            <DialogContent>
                <DialogContentText>
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}
                        color="primary">{t("cancel")}</Button>
                <Button onClick={handleAccept}
                        color="primary">{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog