import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, MenuItem, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useBaseTranslation} from "../../../app/baseTranslationContext";
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext";
import useCustomSnackbar from "../../../app/components/useCustomSnackbar";
import {useAxiosBpn} from "../../../app/axios";
import FamilyDiscountAlerts from "../family-discounts/FamilyDiscountAlerts";
import {uniqueSellingPointsIcons} from "./UniqueSellingPointsIcons";
import TranslatableRichTextField from "../../../app/product-editor/TranslatableRichTextField";
import TranslatableTextField from "../../../app/product-editor/TranslatableTextField";
import {
    getTranslatableFieldValueFromObject,
    getTranslationsPayloadFromObject
} from "../../../app/helper-functions/translationHelpers";

const populateTranslateableUniqueSellingPointsField = (uniqueSellingPoint) => {
    if (uniqueSellingPoint) {
        const tempSellingPoint = {...uniqueSellingPoint}
        tempSellingPoint.title = getTranslatableFieldValueFromObject(tempSellingPoint, "title")
        tempSellingPoint.description = getTranslatableFieldValueFromObject(tempSellingPoint, "description")
        return tempSellingPoint
    }

    return null
}


const EditOrCreateUniqueSellingPointDialog = ({
                                                  open,
                                                  handleClose,
                                                  uniqueSellingPointToEdit,
                                                  onUniqueSellingPoingSaved,
                                              }) => {
    const t = useBaseTranslation()
    const {umbrellaId} = useMuseumSelectorState()
    const snackbar = useCustomSnackbar()
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [errors] = useState({test: false})
    const [uniqueSellingPoint, setUniqueSellingPoint] = useState(populateTranslateableUniqueSellingPointsField(uniqueSellingPointToEdit) || {
        new: true,
        status: "draft",
        title: {value: "", translations: []},
        description: {value: "", translations: []},
        icon: "",
        museumId: umbrellaId,
    })

    const [{error: postError}, postUniqueSellingPoint] = useAxiosBpn({
        url: "unique_selling_points",
        method: "POST",
    }, {manual: true})

    const [{error: putError}, putUniqueSellingPoint] = useAxiosBpn({
        url: `unique_selling_points/${uniqueSellingPoint?.id}`,
        method: "PUT",
    }, {manual: true})


    useEffect(() => {
        const error = postError || putError || null
        if (error) {
            snackbar.showError(t(error.message))
        }
    }, [postError, putError, snackbar, t])

    const handleSave = () => {
        if (uniqueSellingPoint) {
            uniqueSellingPoint.translations = getTranslationsPayloadFromObject(uniqueSellingPoint)
            if (uniqueSellingPoint?.new) {
                postUniqueSellingPoint({data: uniqueSellingPoint})
                    .then(() => {
                        snackbar.showSuccess(`${t("uniqueSellingPoint")} ${t("added").toLowerCase()}`)
                        onUniqueSellingPoingSaved()
                        setUnsavedChanges(false)
                    })
            } else {
                putUniqueSellingPoint({data: uniqueSellingPoint})
                    .then(() => {
                        snackbar.showSuccess(`${t("uniqueSellingPoint")} ${t("updated").toLowerCase()}`)
                        onUniqueSellingPoingSaved()
                        setUnsavedChanges(false)
                    })
            }
        }
    }

    const handleChange = event => {
        setUniqueSellingPoint(prev => ({...prev, [event.target.name]: event.target.value}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const onTranslatableFieldChanged = (propertyKey, updatedValue) => {
        setUniqueSellingPoint(prev => ({...prev, [propertyKey]: updatedValue}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }


    const handleEmptyAndClose = () => {
        handleClose()
    }

    return (
        <Dialog open={open}
                onClose={handleEmptyAndClose}
                fullWidth
                maxWidth="md">
            <DialogTitle>
                {`${uniqueSellingPoint && uniqueSellingPoint?.id
                    ? t("edit")
                    : t("add")} ${t("uniqueSellingPoint").toLowerCase()}`}
            </DialogTitle>
            <DialogContent>
                {unsavedChanges && <FamilyDiscountAlerts {...errors} />}
                <Grid container
                      spacing={1}
                      direction="column">
                    <Grid item>
                        <TextField
                            select={true}
                            label={t("icon")}
                            value={uniqueSellingPoint?.icon || ""}
                            name={"icon"}
                            color={"primary"}
                            size="small"
                            variant={"outlined"}
                            onChange={handleChange}>
                            {uniqueSellingPointsIcons.map(icon =>
                                <MenuItem style={{display: "inline-flex", width: "10%"}} key={icon} value={icon}><Icon
                                    fontSize={"small"}>{icon.toLowerCase()}</Icon></MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TranslatableTextField name="title"
                                               label={t("title")}
                                               fullWidth
                                               variant={"outlined"}
                                               helperText={"Vises på produktside"}
                                               alwaysShowTranslationFields={true}
                                               error={unsavedChanges && !uniqueSellingPoint?.title}
                                               type="text"
                                               required
                                               value={uniqueSellingPoint?.title || ""}
                                               onChange={(updatedValue) => onTranslatableFieldChanged("title", updatedValue)}/>
                    </Grid>
                    <Grid item>
                        <TranslatableRichTextField name="description"
                                                   label={t("description")}
                                                   fullWidth
                                                   multiline={true}
                                                   showTranslationFields={true}
                                                   minRows={5}
                                                   variant={"outlined"}
                                                   helperText={`Vises i "se mer" popup`}
                                                   type="text"
                                                   value={uniqueSellingPoint?.description || ""}
                                                   onChange={(updatedValue) => onTranslatableFieldChanged("description", updatedValue)}/>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleEmptyAndClose}
                        color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={handleSave}
                        color="primary"
                        disabled={uniqueSellingPointToEdit === uniqueSellingPoint}>
                    {t("save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditOrCreateUniqueSellingPointDialog
