import React from "react"
import {Grid, TextField, Typography} from "@mui/material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const MuseumInformationInputFields = ({
                                          museumInformation,
                                          museumName,
                                          onMuseumInformationUpdated,
                                          isUmbrellaFields,
                                          readonly,
                                      }) => {
    const t = useBaseTranslation()
    return (
        <Grid container
              spacing={3}
              direction="column">
            <Grid item
                  xs>
                <Typography variant={"h6"}>{`${isUmbrellaFields
                                               ? "Selskapsnivå"
                                               : "Museumsnivå"} (${museumName})`}:</Typography>
            </Grid>
            <Grid item
                  xs>
                <TextField variant="outlined"
                           size="small"
                           fullWidth
                           name={"screenName"}
                           value={museumInformation?.screenName || ""}
                           disabled={readonly}
                           onChange={e => onMuseumInformationUpdated(isUmbrellaFields, e.target.name, e.target.value)}
                           label={t("screenName")}/>
            </Grid>
            <Grid item
                  xs>
                <TextField variant="outlined"
                           size="small"
                           fullWidth
                           name={"homepageUrl"}
                           value={museumInformation?.homepageUrl || ""}
                           disabled={readonly}
                           onChange={e => onMuseumInformationUpdated(isUmbrellaFields, e.target.name, e.target.value)}
                           label={t("homepageUrl")}/>
            </Grid>
            <Grid item
                  xs>
                <TextField variant="outlined"
                           size="small"
                           fullWidth
                           name={"orgNr"}
                           value={museumInformation?.orgNr || ""}
                           disabled={readonly}
                           onChange={e => onMuseumInformationUpdated(isUmbrellaFields, e.target.name, e.target.value)}
                           label={t("orgNr")}/>
            </Grid>
            <Grid item
                  xs>
                <TextField variant="outlined"
                           size="small"
                           fullWidth
                           name={"street"}
                           value={museumInformation?.street || ""}
                           disabled={readonly}
                           onChange={e => onMuseumInformationUpdated(isUmbrellaFields, e.target.name, e.target.value)}
                           label={t("street")}/>
            </Grid>
            <Grid item>
                <Grid container
                      direction="row"
                      spacing={2}>
                    <Grid item xs={4}>
                        <TextField variant="outlined"
                                   size="small"
                                   fullWidth
                                   name={"zipCode"}
                                   value={museumInformation?.zipCode || ""}
                                   disabled={readonly}
                                   onChange={e => onMuseumInformationUpdated(isUmbrellaFields,
                                       e.target.name,
                                       e.target.value === "" ? null : e.target.value)}
                                   label={t("zipCode")}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField variant="outlined"
                                   size="small"
                                   fullWidth
                                   name={"city"}
                                   value={museumInformation?.city || ""}
                                   disabled={readonly}
                                   onChange={e => onMuseumInformationUpdated(isUmbrellaFields,
                                       e.target.name,
                                       e.target.value)}
                                   label={t("city")}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MuseumInformationInputFields
