import React from "react"
import {Typography} from "@mui/material"

const ProductPaymentTypeHelperText = () => {
    return (
        <>
            <Typography variant="h6">Betalingsmåter</Typography>
            <Typography variant="body2">
                Betalingsmåter administreres under <strong>innstillinger</strong>, og defineres for hvert
                selskap (konsolidering). <em>Med andre ord vil alle museer under et selskap ha tilgang til samme
                innstillinger</em>, slik at man slipper å sette disse manuelt for hvert museum.
                <br/><br/>
                <u>Det er ikke lenger nødvendig å aktivere betalingsmåte for hvert produkt!</u> Betalingsmåter definert her vil
                komme som et alternativ på alle produkter under samme selskap/stiftelse.
            </Typography>
        </>
    )
}

export default ProductPaymentTypeHelperText