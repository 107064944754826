import {
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions, DialogContent,
    FormControlLabel,
    IconButton,
    Switch,
    Tooltip,
    Typography
} from "@mui/material"
import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import EditIcon from "@mui/icons-material/Edit"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import arrayMove from "array-move"
import {InfoOutlined} from "@mui/icons-material"

export const getFamilyDiscountLimitsString = (limits) => {
    let string = ""

    for (const limit of limits) {
        const {min, max, priceCategoryName} = limit
        string += `${string ? ", " : ""}${min}${max !== min ? "-" + max : ""} ${priceCategoryName}`
    }

    return string
}

const FamilyDiscountsTable = ({familyDiscounts, setFamilyDiscounts, onEditfamilyDiscount, getFamilyDiscounts}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()

    const [selected, setSelected] = useState({id: null, index: null})
    const [warningDialogOpen, setWarningDialogOpen] = useState(false)

    const [{error: updateActiveStatusError}, updateActiveStatus] = useAxiosBpn({method: "PATCH"}, {manual: true})
    const [{error: updatePriorityError}, updatePriority] = useAxiosBpn({method: "PATCH"}, {manual: true})

    useEffect(() => {
        const error = updateActiveStatusError || updatePriorityError || null
        if (error) snackbar.showError(t(error?.message))
    }, [updateActiveStatusError, snackbar, t, updatePriorityError])

    const handleActiveChange = (id, index, warningAccepted = false) => {
        if (warningAccepted || !familyDiscounts[index]?.products.length || !familyDiscounts[index].active) {
            // Set temporarily to avoid flicker in GUI
            setFamilyDiscounts(prevState => ([
                ...prevState.slice(0, index),
                {...prevState[index], active: !prevState[index].active},
                ...prevState.slice(index + 1),
            ]))
            updateActiveStatus({url: `family_discounts/${id}/active`}).then(() => getFamilyDiscounts().catch(() => {
            }))
        } else {
            setSelected({id: id, index: index})
            setWarningDialogOpen(true)
        }
    }

    const handleWarningAccept = () => {
        handleActiveChange(selected.id, selected.index, true)
        setSelected({id: null, index: null})
        setWarningDialogOpen(false)
    }

    const onDragEnd = ({source, destination}) => {
        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return
        }

        const newFamilyDiscounts = arrayMove(familyDiscounts, source.index, destination.index)
        const newPriority = familyDiscounts.length - destination.index

        // Set temporarily to avoid flicker in GUI
        setFamilyDiscounts(newFamilyDiscounts)

        updatePriority({url: `family_discounts/${familyDiscounts[source.index].id}/priority/${newPriority}`})
            .then(() => {
                snackbar.showSuccess(t("priorityOrderChanged"))
                return getFamilyDiscounts().catch(() => {
                })
            })
    }

    const ButtonGroup = ({familyDiscount, index}) => {
        const productsCount = familyDiscount?.products?.length || 0
        const products = familyDiscount?.products
        const maxProducts = 15

        return (
            <div style={{display: "flex", alignItems: "center"}}>
                {productsCount
                    ? <Tooltip title={
                        <>
                            <Typography variant="subtitle2">
                                {productsCount > maxProducts ? `Flere enn ${maxProducts} ` : `${productsCount} `}
                                produkt{productsCount !== 1 ? "er" : ""} har denne familierabatten
                                aktivert{productsCount > maxProducts ? `. Bl.a` : ""}:
                            </Typography>
                            <div style={{display: "flex", flexDirection: "column", marginLeft: 16, marginTop: 8}}>
                                {products?.map(({id, name}) => (
                                    <Typography key={id} variant="caption">{name}</Typography>
                                ))}
                            </div>
                        </>
                    }>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <InfoOutlined color="disabled"/>
                            <Typography variant="caption" color="textSecondary" style={{marginLeft: 4, marginRight: 8}}>
                                {productsCount} produkt{productsCount !== 1 ? "er" : ""}
                            </Typography>
                        </div>
                    </Tooltip>
                    : null}
                <FormControlLabel label={familyDiscount?.active
                    ? t("active")
                    : t("notActive")}
                                  control={
                                      <Switch checked={familyDiscount?.active}
                                              onChange={() => handleActiveChange(
                                                  familyDiscount.id,
                                                  index)}/>}/>
                <IconButton
                    onClick={() => onEditfamilyDiscount(familyDiscount)}
                    starticon={<EditIcon/>}
                    size="large">
                    <EditIcon/>
                </IconButton>
            </div>
        );
    }

    return <>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableDiscounts">
                {(provided) => (
                    <div ref={provided.innerRef}
                         {...provided.droppableProps}>
                        {familyDiscounts?.map((familyDiscount, index) => (
                            <Draggable draggableId={`${familyDiscount.id}`}
                                       key={familyDiscount.id}
                                       index={index}>
                                {provided => (
                                    <div ref={provided.innerRef}
                                         {...provided.draggableProps}>
                                        <Card variant="outlined"
                                              square
                                              style={{marginTop: 4, padding: 2}}>
                                            <CardHeader title={familyDiscount.name}
                                                        subheader={getFamilyDiscountLimitsString(familyDiscount?.limits)}
                                                        action={<ButtonGroup familyDiscount={familyDiscount}
                                                                             index={index}/>}
                                                        avatar={<IconButton {...provided.dragHandleProps} draggable disableRipple size="large">
                                                            <DragHandleIcon/>
                                                        </IconButton>}/>
                                        </Card>
                                    </div>
                                )}
                            </Draggable>))
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        <Dialog open={warningDialogOpen} onClose={() => setWarningDialogOpen(false)}>
            <DialogContent>
                Dette vil også deaktivere denne familierabatten på alle tilknyttede produkter. Er du sikker på at du vil fortsette?
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setWarningDialogOpen(false)}>Avbryt</Button>
                <Button onClick={handleWarningAccept}
                        color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    </>;
}

export default FamilyDiscountsTable