import React from "react"

const ImageCropper = ({src, height = "40%", filter = null}) => {
    return (
        <div style={{
            height: height,
            width: "100%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"

        }}>
            <img alt="Source"
                 style={{width: "100%", height: "100%", objectFit: "cover", filter: filter}}
                 src={src}/>
        </div>
    )
}

export default ImageCropper