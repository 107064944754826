import React, {useEffect, useState} from "react"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import {useBaseTranslation} from "../../app/baseTranslationContext"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import useCustomSnackbar from "../../app/components/useCustomSnackbar"
import {useAxiosBpn} from "../../app/axios"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import GroupTicketConfigurer from "./price-categories/GroupTicketConfigurer"

const useStyles = makeStyles(theme => ({
    switch: {
        marginTop: -6,
        marginBottom: -6,
        marginLeft: theme.spacing(1),
        verticalAlign: "middle",
    },
    switchLabel: {
        fontSize: "0.9em",
    },
}))

// const parseExistingPriceCategory = (priceCategoryToEdit) => {
//     if (priceCategoryToEdit) {
//         const parsedPriceCategory = {...priceCategoryToEdit}
//         if (priceCategoryToEdit?.groupTicket) {
//             parsedPriceCategory.groupTicket = JSON.parse(priceCategoryToEdit.groupTicket)
//             return parsedPriceCategory
//         }
//
//         return parsedPriceCategory
//     }
//
//     return null
// }

const EditOrCreatePriceCategoryDialog = ({
                                             open,
                                             handleClose,
                                             priceCategoryToEdit,
                                             availablePriceCategories,
                                             onPriceCategorySaved,
                                         }) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const {umbrellaId} = useMuseumSelectorState()
    const [priceCategory, setPriceCategory] = useState(priceCategoryToEdit || {
        new: true,
        includedInVisitorStats: false,
        excludedFromCapacityCalculation: false,
        groupTicket: null,
        productGroupId: null,
        name: "",
    })
    const [productGroups, setProductGroups] = useState([])
    const [isGroupTicket, setIsGroupTicket] = useState(!!priceCategoryToEdit?.groupTicket || false)
    const snackbar = useCustomSnackbar()

    const [{data: productGroupsData}, getProductGroups] = useAxiosBpn({
        url: "product_groups",
        params: {
            museumId: umbrellaId,
        },
    })

    const [{error: postError}, postPriceCategory] = useAxiosBpn({
        url: "price_categories",
        method: "POST",
    }, {manual: true})

    const [{error: putError}, putPriceCategory] = useAxiosBpn({
        url: `price_categories/${priceCategory?.id}`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        if (productGroupsData) setProductGroups(productGroupsData.productGroups)
    }, [productGroupsData])

    useEffect(() => {
        getProductGroups()
    }, [getProductGroups])

    const convertObjectToSnakeCase = (obj) => {
        const convertedObject = {}
        for (const key in obj) {
            convertedObject[camelToUnderscore(key)] = obj[key]
        }

        return convertedObject
    }

    const camelToUnderscore = (key) => {
        return key.replace(/([A-Z])/g, "_$1").toLowerCase();
    }

    const handleSave = () => {
        const newPriceCategory = {...priceCategory}
        if (newPriceCategory.groupTicket?.length > 0) {
            const snakeCaseGroupTicket = newPriceCategory.groupTicket.map(categoryAndCount => convertObjectToSnakeCase(
                categoryAndCount))
            newPriceCategory.groupTicket = JSON.stringify(snakeCaseGroupTicket)
        }

        if (newPriceCategory?.groupTicket?.length < 1) {
            newPriceCategory.groupTicket = null
        }

        if (priceCategory?.new) {
            postPriceCategory({
                data: {
                    ...newPriceCategory, museumId: umbrellaId,
                },
            }).then(({data: {priceCategory: _priceCategory}}) => {
                snackbar.showSuccess(`${t("priceCategory")} ${t("added").toLowerCase()}`)
                onPriceCategorySaved()
            })
        } else {
            putPriceCategory({data: newPriceCategory})
                .then(({data: {priceCategory: _priceCategory}}) => {
                    snackbar.showSuccess(`${t("priceCategory")} ${t("updated").toLowerCase()}`)
                    onPriceCategorySaved()
                })
        }
    }

    useEffect(() => {
        const error = postError || putError || null
        if (error) {
            snackbar.showError(t(error.message))
        }
    }, [postError, putError, snackbar, t])

    const handleChange = event => {
        event.persist()
        setPriceCategory(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    const handlePriceCategoryChange = (key, value) => {
        setPriceCategory(prev => ({...prev, [key]: value}))
    }

    const handleSwitcherChange = event => {
        event.persist()
        setPriceCategory(prev => ({...prev, [event.target.name]: event.target.checked}))
    }

    const handleProductGroupSelect = id => {
        const newProductGroups = productGroups.map(group => {
            // if this productgroup is the same as the newly selected
            if (group.id === id) {
                // add current pricecategory to list of pricecategories for product group
                let priceCategories = group?.priceCategories || []
                priceCategories.push(priceCategory)
                return {...group, priceCategories: priceCategories}
            }

            // remove current pricecategory from other product groups
            return {
                ...group,
                priceCategories: group?.priceCategories?.filter(category => category.id !== priceCategory.id),
            }
        })
        setProductGroups(newProductGroups)
        setPriceCategory(prev => ({...prev, productGroupId: id}))
    }

    const isPriceCategoryValid = () => {
        let isValid = true
        if (!priceCategory?.name || !priceCategory?.productGroupId) {
            isValid = false
        }

        if (priceCategory?.groupTicket?.length > 0) {
            priceCategory.groupTicket.forEach(ticketCategory => {
                if (!ticketCategory.ticketCount || ticketCategory.ticketCount <= 0 || !ticketCategory.priceCategory) {
                    isValid = false
                }
            })

        }

        return isValid
    }

    const handleEmptyAndClose = () => {
        setPriceCategory(null)
        handleClose()
    }

    return (
        <Dialog open={open}
                onClose={handleEmptyAndClose}
                fullWidth
                maxWidth="sm">
            <DialogTitle>
                {`${priceCategory && priceCategory?.id
                    ? t("edit")
                    : t("add")} ${t("priceCategory").toLowerCase()}`}
            </DialogTitle>
            <DialogContent>
                <Grid container
                      spacing={2}
                      direction="column">
                    <Grid item>
                        <TextField name="name"
                                   label={t("name")}
                                   fullWidth
                                   variant={"outlined"}
                                   type="text"
                                   error={!priceCategory?.name}
                                   required
                                   value={priceCategory?.name || ""}
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item>
                        {productGroups?.length && (
                            <TextField label={t("productGroup")}
                                       required
                                       name="productGroup"
                                       variant="outlined"
                                       select
                                       error={!priceCategory?.productGroupId}
                                       helperText={!priceCategory?.productGroupId && t("mustHaveProductGroup")}
                                       size="small"
                                       fullWidth
                                       value={priceCategory?.productGroupId || ""}>
                                {productGroups.map(({id, name: productGroupName}, _index) => (
                                    <MenuItem key={`${id}-${productGroupName}`}
                                              onClick={() => handleProductGroupSelect(id)}
                                              value={id}>
                                        {productGroupName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    </Grid>
                    <Grid item>
                        <FormControlLabel label={t("excludeFromCapacityCalculation")}
                                          classes={{root: classes.switch}}
                                          name={"excludedFromCapacityCalculation"}
                                          labelPlacement="end"
                                          control={
                                              <Switch checked={priceCategory?.excludedFromCapacityCalculation}
                                                      color={"primary"}
                                                      onChange={handleSwitcherChange}/>
                                          }/>
                    </Grid>
                    <Grid item>
                        <FormControlLabel label={t("includeInVisitorStatistics")}
                                          classes={{root: classes.switch}}
                                          name={"includedInVisitorStats"}
                                          labelPlacement="end"
                                          control={
                                              <Switch checked={priceCategory?.includedInVisitorStats}
                                                      color={"primary"}
                                                      onChange={handleSwitcherChange}/>
                                          }/>
                    </Grid>
                    {priceCategory?.includedInVisitorStats &&
                    <Grid item>
                        <FormControlLabel label={t("groupTicket")}
                                          name={"groupTicket"}
                                          classes={{root: classes.switch}}
                                          labelPlacement="end"
                                          control={
                                              <Switch color={"primary"}
                                                      checked={isGroupTicket}
                                                      onChange={() => {
                                                          if (isGroupTicket) {
                                                              setPriceCategory(prev => ({...prev, 'groupTicket': null}))
                                                          }

                                                          setIsGroupTicket(!isGroupTicket)
                                                      }}/>
                                          }/>
                    </Grid>
                    }
                    {isGroupTicket &&
                    <Grid item>
                        <GroupTicketConfigurer availablePriceCategories={availablePriceCategories}
                                               onPriceCategoryChanged={(key, value) => handlePriceCategoryChange(key,
                                                   value)}
                                               priceCategory={priceCategory}/>
                    </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleEmptyAndClose}
                        color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={handleSave}
                        color="primary"
                        disabled={!isPriceCategoryValid() || priceCategoryToEdit === priceCategory}>
                    {t("save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditOrCreatePriceCategoryDialog