import React from "react"
import {Card, CardContent, CardHeader, ClickAwayListener, Popper} from "@mui/material"

const GenericPopper = (({anchorEl, open, handleClose, title, maxHeight = 650, children}) => {

    return (
        <Popper open={open}
                anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={() => handleClose()}>
                <Card raised
                      style={{maxHeight: maxHeight}}>
                    {title && <CardHeader title={title}/>}
                    <CardContent style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: title ? maxHeight - 64 : maxHeight,
                    }}>
                        {children}
                    </CardContent>
                </Card>
            </ClickAwayListener>
        </Popper>
    )
})

export default GenericPopper