import React, {useEffect, useState} from "react"
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
} from "@mui/material"
import Button from "@mui/material/Button"
import {useBaseTranslation} from "../../baseTranslationContext"
import ProductTypeSelector from "../../components/ProductTypeSelector"
import {ProgressIndicator} from "../../ProgressIndicator"
import Typography from "@mui/material/Typography"

const RelatedProductsSelectorDialog = ({
                                           available,
                                           selected,
                                           handleChange,
                                           handleClose,
                                           open,
                                           loading,
                                       }) => {
    const t = useBaseTranslation()

    const [_available, setAvailable] = useState([])
    const [_selected, setSelected] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [selectedProductType, setSelectedProductType] = useState("all")
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        let products = available?.filter(product => !!!_selected.find(selectedProduct => selectedProduct.id === product.id))
        if (searchValue) products = products?.filter(({name}) => name.toUpperCase().includes(searchValue.toUpperCase()))
        if (selectedProductType !== "all") products = products?.filter(({type}) => type === selectedProductType)

        setAvailable(products)
    }, [available, _selected, selectedProductType, searchValue])

    useEffect(() => setSelected(selected), [selected])

    const _handleChange = ({target: {checked}}, product) => {
        if (checked) {
            setSelected([..._selected, product])
        } else {
            setSelected(_selected.filter(({id}) => id !== product.id))
        }
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleUpdate = () => {
        handleChange(_selected)
        setUnsavedChanges(false)
        handleClose()
    }

    const ProductWithCheckbox = ({product}) => {
        const {museumName, id, name} = product

        return (
            <FormControlLabel label={`${name} (${museumName})`}
                              onChange={e => _handleChange(e, product)}
                              value={product}
                              control={
                                  <Checkbox color={"primary"}
                                            checked={_selected?.filter(
                                                selectedProduct => selectedProduct.id === id).length > 0}/>
                              }/>
        )
    }

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md">
            <DialogContent style={{minHeight: 600}}>
                <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">
                                    {t("productSearch")}:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField size="small"
                                           variant="standard"
                                           style={{width: 160, marginLeft: 8}}
                                           value={searchValue || ""}
                                           onChange={({target: {value}}) => setSearchValue(value)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ProductTypeSelector label={t("type")}
                                             selected={selectedProductType}
                                             handleChange={setSelectedProductType}/>
                    </Grid>
                </Grid>
                {loading
                 ? <ProgressIndicator/>
                 : <FormControl>
                     {_selected?.map((product) => <ProductWithCheckbox key={product.id} product={product}/>)}
                     {_available?.map((product) => <ProductWithCheckbox key={product.id} product={product}/>)}
                 </FormControl>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button disabled={!unsavedChanges} onClick={handleUpdate} color="primary">{t("update")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default RelatedProductsSelectorDialog
