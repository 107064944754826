import React, {useEffect, useState} from "react"
import {Card, CardContent, Chip, FormControlLabel, Grid, Switch, TextField} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {dateFormat} from "../helper-functions/dateFormat"
import {useAxiosBpn} from "../axios"
import useCustomSnackbar from "../components/useCustomSnackbar"
import _ from "lodash"
import {useAuthsState} from "../auths/authsContext"
import {toLowerCase} from "../helper-functions/stringUtility"
import CardHeader from "@mui/material/CardHeader"
import {isConfirmed} from "../helper-functions/orderStatuses"
import HelpButton from "../components/HelpButton"
import {ButtonWithProgress} from "../components/ButtonWithProgress"
import OrderDetailsDialogAlerts from "./OrderDetailsDialogAlerts"
import {GridLabel, GridValue} from "./gridComponents"
import Typography from "@mui/material/Typography"
import SaveIcon from "@mui/icons-material/Save"
import {ProgressIndicator} from "../ProgressIndicator"
import OrderDetailsDialogCreditConfirmationDialog from "./OrderDetailsDialogCreditConfirmationDialog"
import {ROLES_VALUES} from "../menu/MuseumSelector"
import OrderDetailsDialogOverrideOrderDialog from "./OrderDetailsDialogOverrideOrderDialog"

const textFieldProps = {
    margin: "dense",
    variant: "outlined",
    autoComplete: "new-password",
}

const OrderDetailsDialogGeneralDetails = ({
                                              order,
                                              roleValue,
                                              refetchOrder,
                                              loading,
                                              processOrder,
                                              setOrderId,
                                          }) => {
    const {
        id,
        externalId,
        createdAt,
        updatedAt,
        status,
        creditNoteId,
        creditNoteForOrderId,
        giftedToFirstName,
        giftedToLastName,
        lockedByGlobalAdmin,
    } = order

    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()
    const {userName} = useAuthsState()

    const [gridRows, setGridRows] = useState([])
    const [creditConfirmationDialogOpen, setCreditConfirmationDialogOpen] = useState(false)
    const [overrideOrderDialogOpen, setOverrideOrderDialogOpen] = useState(false)
    const [_order, setOrder] = useState()
    const [productsInOrder, setProductsInOrder] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [boughtAsGift, setBoughtAsGift] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [missingComment, setMissingComment] = useState(false)

    const [{data: postData, loading: postLoading, error: postError}, createCreditNote] = useAxiosBpn(
        {
            url: `orders/${externalId}/credit`,
            method: "POST",
            params: {productIds: selectedProducts?.length ? selectedProducts.map(({id}) => id)?.join() : null},
        }, {manual: true})

    const [{data: putData, loading: putLoading, error: putError}, putOrder] = useAxiosBpn({
        url: `orders/${id}/minimal`,
        method: "PUT",
    }, {manual: true})

    const [{loading: overrideOrderLoading}, overrideOrder] = useAxiosBpn({
        url: `orders/${id}/override`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        const _productsInOrder = order.orderLines.reduce((unique, {productId, productName, museumName}) => {
            if (!unique[productId]) {
                unique[productId] = {
                    id: productId,
                    name: productName,
                    museumName: museumName,
                };
            }
            return unique;
        }, {});
        setProductsInOrder(Object.values(_productsInOrder))
        setOrder(order)
    }, [order])

    useEffect(() => {
        if (giftedToFirstName || giftedToLastName) setBoughtAsGift(true)
    }, [giftedToFirstName, giftedToLastName])

    useEffect(() => {
        setGridRows([
            {label: t("created"), value: dateFormat(createdAt, "PPPp")},
            {label: t("lastUpdated"), value: dateFormat(updatedAt, "PPPp")},
            {label: t("internalId"), value: id},
            {label: t("externalId"), value: externalId},
        ])
    }, [createdAt, updatedAt, id, externalId, t])

    useEffect(() => {
        if (postData) {
            snackbar.showInfo(t("creditNoteCreated"))
            refetchOrder()
        }
    }, [postData, refetchOrder, snackbar, t])

    useEffect(() => {
        if (putData) {
            snackbar.showSuccess(`${t("order")} ${t("updated").toLowerCase()}`)
            refetchOrder()
        }
    }, [putData, refetchOrder, snackbar, t])

    useEffect(() => {
        if (postError) {
            snackbar.showError(postError.message)
        }
    }, [postError, snackbar])

    useEffect(() => {
        if (putError) {
            snackbar.showError(t(putError.message))
        }
    }, [putError, snackbar, t])

    const handleCreditButtonClick = () => {
        if (_order?.comment) {
            setMissingComment(false)
            setCreditConfirmationDialogOpen(true)
        } else {
            setMissingComment(true)
        }
    }

    const handleCreditAccept = () => {
        setCreditConfirmationDialogOpen(false)
        createCreditNote()
    }

    const handleCommentChange = (e) => {
        e.persist()
        setOrder(prev => ({...prev, comment: e.target.value}))
    }

    const handleBoughtAsGiftChange = () => {
        if (boughtAsGift) {
            setOrder(prev => ({...prev, giftedToFirstName: null, giftedToLastName: null}))
        }
        setBoughtAsGift(!boughtAsGift)
        setUnsavedChanges(true)
    }

    const handleGiftReceiverChange = (e) => {
        e.persist()
        setOrder(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleSelectedProductsChange = ({target: {checked}}, product) => {
        if (checked) {
            setSelectedProducts([...selectedProducts, product])
        } else {
            setSelectedProducts(selectedProducts.filter(({id}) => id !== product.id))
        }
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleSubmit = () => {
        if (!_.isEqual(_order, order)) {
            setUnsavedChanges(true)
        }
    }

    const handleSave = () => {
        putOrder({data: {..._order, commentBy: userName, updatedBy: userName}})
    }

    const getTitle = () => (
        <Grid container
              spacing={1}>
            <Grid item>
                <span>{`${t("order")} ${id}`}<strong>{order?.test
                    ? ` (${toLowerCase(t("testOrder"))})`
                    : ""}</strong></span>
            </Grid>
            <Grid item>
                <Chip label={t(status)}
                      color="primary"
                      size="small"/>
            </Grid>
            {order.lockedByGlobalAdmin && <Grid item>
                <span>{t("lockedByGlobalAdmin")}</span>
            </Grid>}
        </Grid>)

    const getCardHeaderButtonText = () => {
        if (status === "confirmed_paid") return t("creditOrder")
        // noinspection JSBitwiseOperatorUsage
        if (status === "confirmed" | "confirmed_pay_later") return t("cancelOrder")
    }

    const getHelpButtonDialogText = () => isConfirmed(status)
        ? <>
            Ved kansellering eller kreditering av en ordre, vil det bli opprettet en
            kreditnota (uavhengig av om
            det har blitt trukket penger eller ikke). Kreditnotaen er i praksis bare
            en kopi av denne ordren, med
            negativt fortegn foran antall produkter på hver ordrelinje. <br/>
            Dette vil løse opp reserverte plasser, både med tanke på eventuell
            plassbegrensning og for
            statistikk. <br/><br/>
            Den nye ordren (kreditnotaen) vil også være med i rapporter/bilag hentet
            fra systemet, og siden den
            nullstiller den opprinnelige ordren, vil det sannsynligvis ikke være behov
            for å endre på tidligere
            eksporterte data. <br/><br/>
            I de tilfellene ordren er betalt via Netaxept, vil pengene bli refundert
            kunden. <br/><br/>
            Det er foreløpig ikke mulig å kun kreditere deler av en ordre.
        </>
        : null

    const getHelperText = () => missingComment ? t("creditOrderCommentRequiredText") : null

    return loading
        ? <ProgressIndicator/>
        : <Card square
                variant="outlined">
            <CardHeader title={getTitle()}
                        titleTypographyProps={{color: "primary"}}
                        action={
                            <Grid container
                                  alignItems="center">
                                {roleValue >= ROLES_VALUES.GLOBAL_ADMIN &&
                                    <>
                                        {!!lockedByGlobalAdmin && <Grid item>
                                            <div style={{display: "flex", alignItems: "center", marginRight: 2}}>
                                                <ButtonWithProgress variant="contained"
                                                                    loading={overrideOrderLoading}
                                                                    label={t("unlockOrder")}
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        overrideOrder({data: {lockedByGlobalAdmin: false}})
                                                                            .then(() => {
                                                                                refetchOrder()
                                                                            })
                                                                    }}/>
                                            </div>
                                        </Grid>}
                                        <Grid item>
                                            <div style={{display: "flex", alignItems: "center", marginRight: 2}}>
                                                <ButtonWithProgress variant="contained"
                                                                    loading={postLoading}
                                                                    label={t("overrideOrderStatus")}
                                                                    color="primary"
                                                                    onClick={() => setOverrideOrderDialogOpen(true)}/>
                                            </div>
                                        </Grid>
                                    </>
                                }
                                {isConfirmed(status) && roleValue >= ROLES_VALUES.EDITOR && (
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <Grid item>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <ButtonWithProgress variant="contained"
                                                                    disabled={missingComment || order.lockedByGlobalAdmin}
                                                                    loading={postLoading}
                                                                    label={getCardHeaderButtonText()}
                                                                    color="primary"
                                                                    onClick={handleCreditButtonClick}/>
                                            </div>
                                        </Grid>
                                        <Grid item><HelpButton>{getHelpButtonDialogText()}</HelpButton></Grid>
                                    </div>
                                )}
                            </Grid>
                        }/>
            <CardContent>
                <Grid container
                      direction="column">
                    <OrderDetailsDialogAlerts creditNoteId={creditNoteId}
                                              status={_order?.status}
                                              processOrder={processOrder}
                                              creditNoteForOrderId={creditNoteForOrderId}
                                              setOrderId={setOrderId}/>
                    {gridRows?.map(({label, value}, index) =>
                        value &&
                        <Grid container
                              key={index}
                              alignItems="center">
                            <GridLabel>{label}:</GridLabel>
                            <GridValue>{value}</GridValue>
                        </Grid>,
                    )}
                    <Grid item>
                        <FormControlLabel label={t("boughtAsGift")}
                                          labelPlacement="end"
                                          style={{marginTop: 8}}
                                          control={
                                              <Switch checked={boughtAsGift}
                                                      onChange={handleBoughtAsGiftChange}/>
                                          }/>
                    </Grid>
                    {boughtAsGift && (
                        <form noValidate
                              autoComplete="off"
                              onBlur={handleSubmit}>
                            <Grid container
                                  spacing={2}
                                  direction="row">
                                <Grid item
                                      xs={6}>
                                    <TextField {...textFieldProps}
                                               fullWidth
                                               label={t("firstNameGiftReceiver")}
                                               name="giftedToFirstName"
                                               value={_order?.giftedToFirstName || ""}
                                               onChange={handleGiftReceiverChange}/>
                                </Grid>
                                <Grid item
                                      xs={6}>
                                    <TextField {...textFieldProps}
                                               fullWidth
                                               label={t("lastNameGiftReceiver")}
                                               name="giftedToLastName"
                                               style={{marginBottom: 16}}
                                               value={_order?.giftedToLastName || ""}
                                               onChange={handleGiftReceiverChange}/>
                                </Grid>
                            </Grid>

                        </form>
                    )}
                    <Grid item>
                        <form noValidate
                              autoComplete="off"
                              onBlur={handleSubmit}>
                            <TextField label={t("comment")}
                                       name="comment"
                                       variant="outlined"
                                       style={{marginTop: 16}}
                                       required={missingComment}
                                       error={missingComment}
                                       helperText={getHelperText()}
                                       fullWidth
                                       multiline
                                       minRows={2}
                                       value={_order?.comment || ""}
                                       onChange={handleCommentChange}
                            />
                        </form>
                    </Grid>
                    <Grid container
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={1}>
                        {_order?.commentBy && (
                            <Grid item>
                                <Typography variant="caption"
                                            color="textSecondary">
                                    {t("lastCommentBy")}: {_order?.commentBy}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <ButtonWithProgress variant="text"
                                                    disabled={!unsavedChanges}
                                                    loading={putLoading}
                                                    label={t("save")}
                                                    color="primary"
                                                    startIcon={<SaveIcon/>}
                                                    onClick={handleSave}/>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <OrderDetailsDialogCreditConfirmationDialog open={creditConfirmationDialogOpen}
                                                            onClose={() => setCreditConfirmationDialogOpen(false)}
                                                            handleCreditAccept={handleCreditAccept}
                                                            handleSelectedProductsChange={handleSelectedProductsChange}
                                                            productsInOrder={productsInOrder}
                                                            selectedProducts={selectedProducts}
                                                            order={order}/>

                <OrderDetailsDialogOverrideOrderDialog open={overrideOrderDialogOpen}
                                                       onClose={() => setOverrideOrderDialogOpen(false)}
                                                       order={order}
                                                       onOrderUpdated={() => refetchOrder()}/>
            </CardContent>
        </Card>
}

export default OrderDetailsDialogGeneralDetails
