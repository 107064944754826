import React, {useEffect, useState} from "react"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import {useAxiosBpn} from "../../axios"
import {Link, MenuItem, OutlinedInput, Select} from "@mui/material"
import Typography from "@mui/material/Typography"
import {SETTINGS_BASE_URL} from "../../Routes"
import {lowerFirst} from "lodash"
import {useHistory} from "react-router-dom"

const VismaSettings = ({product, unsavedChanges, setUnsavedChanges, updateProductState}) => {
    const t = useProductEditorTranslation()
    const history = useHistory()

    const [_product, setProduct] = useState({})
    const [departments, setDepartments] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState({id: 0, name: t("none")})

    const [{data: departmentsData}, getDepartments] = useAxiosBpn(
        {url: "voucher_departments", params: {museumId: product?.museumId}}, {manual: true})

    useEffect(() => {
        getDepartments().catch(() => {
            // Just to avoid annoying console error
        })
    },[getDepartments])

    useEffect(() => {
        if (product) {
            setProduct(product)

            const {departmentId, departmentName} = product

            if (departmentId && departmentName) {
                setSelectedDepartment({id: departmentId, name: departmentName})
            } else setSelectedDepartment({id: 0, name: t("none")})
        }
    }, [getDepartments, product, t])

    useEffect(() => {
        if (departmentsData) {
            const {voucherDepartments} = departmentsData
            setDepartments(voucherDepartments)
        }
    }, [departmentsData])

    const handleDepartmentSelect = (department) => {
        updateProductState({
            ..._product,
            departmentId: department.id,
            departmentName: department.name,
        })

        if (!unsavedChanges) setUnsavedChanges(true)
    }

    return (
        <>
            <Typography><strong>{t("department")}</strong></Typography>
            {departments?.length > 0
             ? <>
                 <Typography variant="body2"
                             color="textSecondary"> {t("vismaSettingsDepartmentHelperText")} </Typography>
                 <Select value={selectedDepartment.name || ""}
                         fullWidth
                         input={<OutlinedInput/>}
                         variant="outlined">
                     <MenuItem value={t("none")}
                               onClick={() => handleDepartmentSelect({id: null, name: null})}>
                         {t("none")}
                     </MenuItem>
                     {departments?.map((department) => (
                         <MenuItem key={department.id}
                                   value={department.name}
                                   onClick={() => handleDepartmentSelect(department)}>
                             {department.name}
                         </MenuItem>
                     ))}
                 </Select>
             </>
             : <Typography variant="caption">
                 {`${t("noDepartmentsDefined")}! ${t("goTo")} `}
                 <Link component="button"
                       onClick={() => history.push(`${SETTINGS_BASE_URL}/vouchers`)}>
                     {t("voucherSettings").toLowerCase()}
                 </Link> {lowerFirst(t("toAddDepartments"))}.
             </Typography>}
        </>
    )
}

export default VismaSettings
