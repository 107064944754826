import {nokPrice} from "../helper-functions/numberFormat"
import _ from "lodash"
import {dateFormat, getFormatStringBasedOnFequency} from "../helper-functions/dateFormat"
import {GROUP_BY_PRODUCT_TYPE} from "../components/OrderSumsGroupBySelectMenu"

export const createRevenueTableHeaders = (_columns, t, smDown = false, xsDown = false, hiddenIndexes = []) => [
    {
        field: "id",
        headerName: t("id"),
        flex: 1,
        hide: true
    },
    {
        field: _columns[0],
        headerName: t(_columns[0]),
        type: "datetime",
        flex: 1,
        hide: hiddenIndexes.includes(0)
    },
    {
        field: _columns[1],
        headerName: t(_columns[1]),
        headerAlign: "left",
        align: "left",
        flex: 1.75,
        hide: hiddenIndexes.includes(1)
    },
    {
        field: _columns[2],
        headerName: t(_columns[2]),
        type: "number",
        flex: 1,
        valueFormatter: ({value}) => value ? value.toLocaleString() : value,
        hide: xsDown || hiddenIndexes.includes(2),
    },
    {
        field: _columns[3],
        headerName: t(_columns[3]),
        flex: 1.5,
        ...nokPrice,
        hide: smDown || hiddenIndexes.includes(3),
    },
    {
        field: _columns[4],
        headerName: t(_columns[4]),
        flex: 1,
        ...nokPrice,
        hide: smDown || hiddenIndexes.includes(4),
    },
    {
        field: _columns[5],
        headerName: t(_columns[5]),
        flex: 1.75,
        ...nokPrice,
        hide: hiddenIndexes.includes(5)
    },
]

export function createRevenueTableRows(_data, _columns, frequency, t, groupBy) {
    return _data.map((r, idx) => ({
            id: idx + 1,
            [_columns[0]]: _.upperFirst(dateFormat(new Date(r[0]), getFormatStringBasedOnFequency(frequency))),
            [_columns[1]]: groupBy === GROUP_BY_PRODUCT_TYPE ? t(r[1]) : r[1],
            [_columns[2]]: r[2],
            [_columns[3]]: r[3],
            [_columns[4]]: r[4],
            [_columns[5]]: r[5],
        }),
    )
}

export const createExcelDatasetFromDataGrid = (headers, rows) => {
    let columns = headers.map(({headerName, flex}) => ({title: headerName, width: {wpx: flex * 100 || 100}}))

    let data = rows.map((row) => {
        return Object.values(row).map(v => ({value: v || ""}))
    })
    return [{columns: columns, data: data}]
}


const extractChartDataset = (dates, groups) => dates.map(d => ({
    xAxisKey: d,
    ...groups.reduce((current, item) => {
        current[item] = 0;
        return current;
    }, {}),
}))


export const createRevenueChartData = (rows, dates, groups, groupBy, dateGroupBy, fallbackGroupBy="Omsetning") => {
    let dataset = extractChartDataset(dates, groups)

    for (const r of rows) {
        const index = dataset.findIndex(d => d.xAxisKey === r[dateGroupBy])
        dataset[index][groupBy ? r[groupBy] : fallbackGroupBy] = r.revenueExcludingVat
    }

    return dataset
}