import React, {useEffect, useState} from "react"
import {useBaseContextState} from "../../app/baseContext"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import { SELECTED_TYPE_UMBRELLA, useMuseumSelectorState } from "../../app/museum-selector/museumSelectorContext";
import EditorCardModule from "../../app/components/EditorCardModule"
import {useAxiosBpn} from "../../app/axios"
import {useTheme} from "@mui/material/styles"
import TermsOfSaleUrl from "./terms-of-sale-url/TermsOfSaleUrl"
import {ProgressIndicator} from "../../app/ProgressIndicator"
import TermsOfSaleUrlSettingsHelperText from "./terms-of-sale-url/TermsOfSaleUrlSettingsHelperText"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import NewsletterSettings from "./newsletter-settings/NewsletterSettings"
import NewsletterSettingsHelperText from "./newsletter-settings/NewsletterSettingsHelperText"
import RubicSettings from "./rubic-settings/RubicSettings"
import MuseumInformationSettings from "./museum_information/MuseumInformationSettings"
import GoogleTagManagerSettings from "./google-tag-manager/GoogleTagManagerSettings";

const OtherSettings = () => {
    const {spacing} = useTheme()
    const t = useBaseTranslation()
    const {currentBaseUrl} = useBaseContextState()
    const {selectedMuseum} = useMuseumSelectorState()
    const [activeBreadcrumb, setActiveBreadcrumb] = useState(t("products"))
    const [museumSettings, setMuseumSettings] = useState()
    const [umbrellaSettings, setUmbrellaSettings] = useState()

    const [{data: museumSettingsData, loading: museumSettingsLoading}, getMuseumSettings] = useAxiosBpn({
        url: `museum_settings/${selectedMuseum?.id}`,
    })

    useEffect(() => {
        getMuseumSettings().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getMuseumSettings().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getMuseumSettings, selectedMuseum])

    useEffect(() => {
        if (museumSettingsData) {
            const {museum, umbrella} = museumSettingsData
            setMuseumSettings(museum)
            setUmbrellaSettings(umbrella)
        }
    }, [museumSettingsData])

    useEffect(() => {
        setActiveBreadcrumb(`${t("other")} (${selectedMuseum?.name})`)
    }, [t, selectedMuseum])

    const breadcrumbs = [
        {label: t("settings"), url: currentBaseUrl},
        {label: activeBreadcrumb},
    ]

    return museumSettingsLoading ? <ProgressIndicator/> : (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}/>
            <ScrollableSectionView>
                <div style={{paddingRight: spacing(1)}}>
                    <EditorCardModule expanded={true}
                                      title="termsOfSaleUrl"
                                      helperTextComponent={<TermsOfSaleUrlSettingsHelperText/>}
                                      subheader={t("termsOfSaleUrlHelperText")}>
                        <TermsOfSaleUrl umbrellaSettings={umbrellaSettings}
                                        museumSettings={museumSettings}
                                        getMuseumSettings={getMuseumSettings}/>
                    </EditorCardModule>
                </div>
                <div style={{paddingRight: spacing(1)}}>
                    <EditorCardModule expanded={true}
                                      title={"newsletterSettings"}
                                      helperTextComponent={<NewsletterSettingsHelperText />}>
                        <NewsletterSettings umbrellaSettings={umbrellaSettings}
                                            museumSettings={museumSettings}
                                            getMuseumSettings={getMuseumSettings}/>
                    </EditorCardModule>
                </div>
                <div style={{paddingRight: spacing(1)}}>
                    <EditorCardModule expanded={true}
                                      title={"rubicSettings"}>
                        <RubicSettings umbrellaSettings={umbrellaSettings}
                                       museumSettings={museumSettings}
                                       getMuseumSettings={getMuseumSettings}/>
                    </EditorCardModule>
                </div>
                {(selectedMuseum?.type === SELECTED_TYPE_UMBRELLA) && <div style={{paddingRight: spacing(1)}}>
                    <EditorCardModule expanded={true}
                                      title={"googleTagManager"}>
                        <GoogleTagManagerSettings umbrellaSettings={umbrellaSettings}
                                       getMuseumSettings={getMuseumSettings}/>
                    </EditorCardModule>
                </div>}
                <div style={{paddingRight: spacing(1)}}>
                    <EditorCardModule expanded={true}
                                      title={"museumInformation"}
                                      subheader={t("museumSettingsHelperText")}>
                        <MuseumInformationSettings umbrellaSettings={umbrellaSettings}
                                                   museumSettings={museumSettings}
                                                   getMuseumSettings={getMuseumSettings}/>
                    </EditorCardModule>
                </div>
            </ScrollableSectionView>
        </>
    )
}

export default OtherSettings
