import React, {useEffect, useState} from "react"
import {Dialog, DialogActions, DialogContent, useTheme} from "@mui/material"
import Button from "@mui/material/Button"
import {useAxiosBpn} from "../axios"
import {useBaseTranslation} from "../baseTranslationContext"
import VoucherGeneralDetails from "./VoucherGeneralDetails"
import VoucherOrderLinesDetails from "./VoucherOrderLinesDetails"
import VoucherVismaDetails from "./VoucherVismaDetails"

const VoucherDetailsDialog = ({voucherId, open, handleClose}) => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()

    const [voucher, setVoucher] = useState({})
    const [_voucherId, setVoucherId] = useState(voucherId)
    const [{data, loading}, getVoucher] = useAxiosBpn({url: `vouchers/${_voucherId}`}, {manual: true})

    useEffect(() => {
        setVoucherId(voucherId)
    }, [voucherId])

    useEffect(() => {
        if (_voucherId) {
            getVoucher().catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [getVoucher, _voucherId])

    useEffect(() => {
        if (data) setVoucher(data?.voucher)
    }, [data])

    return !loading
        && <Dialog open={open}
                   onClose={handleClose}
                   fullWidth
                   maxWidth="md">
            <DialogContent style={{
                minHeight: 600,
                backgroundColor: voucher?.test ? testOrdersPalette?.light : null,
            }}>
                <div style={{marginBottom: 8}}>
                    <VoucherGeneralDetails voucher={voucher}
                                           loading={loading}/>
                </div>
                {voucher?.type === "visma" && (
                    <div style={{marginBottom: 8}}>
                        <VoucherVismaDetails voucher={voucher}
                                             loading={loading}/>
                    </div>
                )}
                <div style={{marginBottom: 8}}>
                    <VoucherOrderLinesDetails voucher={voucher}
                                              orderLines={voucher?.orderLines}
                                              loading={loading}/>
                </div>
            </DialogContent>
            <DialogActions style={{backgroundColor: voucher?.test ? testOrdersPalette?.light : null}}>
                <Button onClick={handleClose}
                        color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
}

export default VoucherDetailsDialog