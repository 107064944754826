import React, {useEffect, useState} from "react"
import {Grid, IconButton, Tooltip} from "@mui/material"
import {ProgressIndicator} from "../../app/ProgressIndicator"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import ReactExport from "react-data-export"
import {createVouchersTableHeaders, createVouchersTableRows} from "./vouchersTableHelpers"
import GenericTable from "../../app/generic-table/GenericTable"
import {ButtonWithProgress} from "../../app/components/ButtonWithProgress"
import {createExcelDatasetFromOrderData} from "../../app/order-table/orderTableHelpers"
import {lowerFirst} from "lodash"
import DeleteIcon from "@mui/icons-material/Delete"
import {useAxiosBpn} from "../../app/axios"
import {VOUCHER_FORMAT_VISMA, VOUCHER_STATUS_DELETED} from "../../app/helper-functions/voucherStatuses"
import useCustomSnackbar from "../../app/components/useCustomSnackbar"
import DeleteConfirmationDialog from "../../app/components/DeleteConfirmationDialog"
import {Refresh} from "@mui/icons-material"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {toSentenceCase} from "../../app/helper-functions/stringUtility"
import {dateFormat} from "../../app/helper-functions/dateFormat"
import {useAuthsState} from "../../app/auths/authsContext"
import DateFilterMonthSwitcherButtons from "../../app/components/DateFilterMonthSwitcherButtons"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const getDefaultMonthLabel = () => `${toSentenceCase(dateFormat(new Date(), "MMMM"))} ${dateFormat(new Date(), "y")}`

const VouchersTable = ({
                           data,
                           loading,
                           refetch,
                           handleVoucherIdClick,
                           testOrders,
                           before,
                           setBefore,
                           after,
                           setAfter,
                       }) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()
    const {umbrellaAndChildrenIds, umbrellaId} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [excelDataSet, setExcelDataSet] = useState()
    const [filename, setFilename] = useState("excel")
    const [selected, setSelected] = useState([])
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [monthLabel, setMonthLabel] = useState(getDefaultMonthLabel())
    const [voucherType, setVoucherType] = useState()
    const [vismaExportDisabled, setVismaExportDisabled] = useState(true)

    const [{data: deleteData, error: deleteError}, deleteVouchers] = useAxiosBpn({
        url: "vouchers/update_status/bulk",
        method: "PUT",
        params: {
            voucherIds: selected.join(","),
            status: VOUCHER_STATUS_DELETED,
        },
    }, {manual: true})

    const [{data: generateData, loading: generateLoading, error: generateError}, generateVouchers] = useAxiosBpn({
        url: `vouchers/museums/${umbrellaId}/generate`,
        method: "POST",
        params: {
            test: testOrders,
            before: before,
            after: after,
            museumIds: umbrellaAndChildrenIds?.length
                       ? umbrellaAndChildrenIds.join()
                       : Array.from(userAccessMuseumIds.keys()).join(),
        },
    }, {manual: true})

    const [{data: exportData, loading: exportLoading, error: exportError}, exportVouchers] = useAxiosBpn({
        url: `vouchers/museums/${umbrellaId}/visma/export`,
        method: "GET",
        responseType: "text",
        params: {
            test: testOrders,
            before: before,
            after: after,
            museumIds: umbrellaAndChildrenIds?.length
                       ? umbrellaAndChildrenIds.join()
                       : Array.from(userAccessMuseumIds.keys()).join(),
        },
    }, {manual: true})

    const [{data: voucherSettingsData}] = useAxiosBpn({url: `voucher_settings/museums/${umbrellaId}`})

    useEffect(() => {
        if (data) {
            const {vouchers} = data
            const _columns = createVouchersTableHeaders(t, _handleVoucherIdClick)
            setColumns(_columns)
            const _rows = createVouchersTableRows(vouchers, t)
            setRows(_rows)
            const dataSet = createExcelDatasetFromOrderData(_columns, _rows)
            setExcelDataSet(dataSet)
        }
        // eslint-disable-next-line
    }, [data, t])

    useEffect(() => {
        if (deleteData) {
            const addToMessage = (existing, stringToAdd) => `${existing}${existing ? ", " : ""}${stringToAdd}`
            const {changeNotNeeded, error, notFound, updated} = deleteData

            let message = ""
            if (changeNotNeeded.length) message = addToMessage(message,
                `${changeNotNeeded.length} bilag ikke endret (allerede riktig status)`)
            if (notFound.length) message = addToMessage(message, `${notFound.length} bilag ikke funnet (feil ID?)`)
            if (error.length) message = addToMessage(message, `${error.length} bilag ikke endret grunnet ukjent feil`)
            if (updated.length) message = addToMessage(message, `${updated.length} bilag slettet`)

            if (error.length) snackbar.showError(message)
            else if (notFound.length) snackbar.showWarning(message)
            else if (changeNotNeeded.length) snackbar.showInfo(message)
            else if (updated.length) snackbar.showSuccess(message)
            setSelected([])
            setDeleteDialogOpen(false)
            refetch()
        }
    }, [deleteData, refetch, snackbar, t])

    useEffect(() => {
        if (generateData) {
            const addToMessage = (existing, stringToAdd) => `${existing}${existing ? ", " : ""}${stringToAdd}`
            const {savedVouchers, skippedOrderLines} = generateData

            const count = skippedOrderLines?.count
            const missingDebitAccount = skippedOrderLines?.missingDebitAccount
            const missingSubtype = skippedOrderLines?.missingSubtype

            let message = ""

            if (savedVouchers.length) {
                message = addToMessage(message, `${savedVouchers.length} bilag opprettet`)
            }

            if (count) {
                message = addToMessage(message, `${count} ordrelinjer utelatt`)

                if (missingDebitAccount?.length || missingSubtype?.length) {
                    message += " ("
                    let missingMessage = ""

                    if (missingDebitAccount?.length) {
                        missingMessage = addToMessage(missingMessage,
                            `${missingDebitAccount?.length} mangler debetkonto`)
                    }

                    if (missingSubtype?.length) {
                        missingMessage = addToMessage(missingMessage,
                            `${missingSubtype?.length} mangler betalingsmetode`)
                    }
                    message = `${message}${missingMessage})`
                }
                snackbar.showWarning(message)
            } else {
                if (!savedVouchers.length) {
                    message = addToMessage(message, "Ingen ordrelinjer uten tilknyttede bilag funnet")
                }
                snackbar.showSuccess(message)
            }
            setSelected([])
            refetch()
        }
    }, [generateData, refetch, snackbar, t])

    useEffect(() => {
        if (exportData) {
            const {buildString, filename: _filename} = exportData
            const url = window.URL.createObjectURL(new Blob([buildString]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", `${_filename}.txt`)
            document.body.appendChild(link)
            link.click()
            refetch()
        }
    }, [exportData])

    useEffect(() => {
        if (voucherSettingsData && voucherSettingsData) {
            const {voucherSettings} = voucherSettingsData

            if (voucherSettings) {
                const {format} = voucherSettings
                setVoucherType(format)
            }
        }
    }, [voucherSettingsData])

    useEffect(() => {
        if (voucherType && voucherType === VOUCHER_FORMAT_VISMA) {
            setVismaExportDisabled(false)
        }
    }, [voucherType])

    useEffect(() => {
        setFilename(
            `${t("vouchers")} - ${monthLabel
                                  ? monthLabel
                                  : t("allDates")} - ${testOrders
                                                       ? "Test"
                                                       : "Prod"}`)
    }, [testOrders, monthLabel, t])

    useEffect(() => {
        if (deleteError) snackbar.showError(t(deleteError.message))
        if (generateError) snackbar.showError(t(generateError.message))
        if (exportError) snackbar.showError(t(exportError.message))
    }, [deleteError, generateError, exportError, snackbar, t])

    const handleMonthChange = (after = null, before = null, label = null) => {
        setMonthLabel(label || t("allDates"))
        setAfter(after)
        setBefore(before)
    }

    const _handleVoucherIdClick = (e) => {
        handleVoucherIdClick(e?.target?.textContent)
    }

    const DownloadXLSXButton = () => (
        <div style={{display: "flex", alignItems: "center"}}>
            <ButtonWithProgress label={t("downloadXLSX")}
                                variant="text"
                                color="primary"
                                loading={loading}/>
        </div>
    )

    const ExportButton = () => (
        <div style={{display: "flex", alignItems: "center"}}>
            <ButtonWithProgress label={t("export")}
                                variant="text"
                                color="primary"
                                disabled={vismaExportDisabled}
                                tooltip={t(vismaExportDisabled
                                           ? "generateAndExportVouchersTooltipDisabled"
                                           : "generateAndExportVouchersTooltip")}
                                onClick={() => exportVouchers()}
                                loading={exportLoading}/>
        </div>
    )

    const DeleteSelectedButton = () => (
        <Tooltip title={`${t("delete")} ${lowerFirst(t("voucher"))}`}>
            <IconButton onClick={() => setDeleteDialogOpen(true)} size="large">
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
    )

    return (
        <>
            <Grid container
                  spacing={3}
                  alignItems="center"
                  justifyContent="flex-end">
                <Grid item>
                    <ExportButton/>
                </Grid>
                <Grid item>
                    <ExcelFile filename={filename}
                               element={<DownloadXLSXButton/>}>
                        <ExcelSheet dataSet={excelDataSet}
                                    name={t("orderReport")}/>
                    </ExcelFile>
                </Grid>
                <Grid item>
                    <DateFilterMonthSwitcherButtons handleMonthChange={handleMonthChange}
                                                    currentMonth={after}/>
                </Grid>
                <Grid item>
                    <ButtonWithProgress label={t("generate")}
                                        variant="text"
                                        color="primary"
                                        startIcon={<Refresh/>}
                                        onClick={generateVouchers}
                                        tooltip={t("generateMissingVouchers")}
                                        loading={generateLoading}/>
                </Grid>
            </Grid>
            {loading
             ? <ProgressIndicator/>
             : <Grid container
                     direction="column"
                     spacing={1}>
                 <Grid item
                       style={{backgroundColor: testOrders ? "white" : null, width: "100%"}}>
                     <GenericTable columns={columns}
                                   rows={rows}
                                   tableTitle={t("vouchers")}
                                   selectable={true}
                                   selected={selected}
                                   setSelected={setSelected}
                                   selectedButtons={<DeleteSelectedButton/>}
                                   size="small"/>
                 </Grid>
                 <Grid container
                       item
                       alignItems="center"
                       justifyContent="flex-end"
                       spacing={2}>
                 </Grid>
             </Grid>
            }
            <DeleteConfirmationDialog open={deleteDialogOpen}
                                      content={`${t("deleteVouchersDialogText")}?`}
                                      handleClose={() => setDeleteDialogOpen(false)}
                                      handleDelete={() => deleteVouchers()}/>
        </>
    )
}

export default VouchersTable
