import Grid from "@mui/material/Grid"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import SectionHeader from "../../../app/components/SectionHeader"
import ScrollableSectionView from "../../../app/ScrollableSectionView"
import {ENTRANCE_BASE_URL} from "../../../app/Routes"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAuthsState} from "../../../app/auths/authsContext"
import SmallRevenueCard from "../../../app/revenue/SmallRevenueCard"
import {useAxiosBpn} from "../../../app/axios"
import {DAILY} from "../../../app/helper-functions/dateFormat"
import {GROUP_BY_PRODUCT_NAME, GROUP_BY_PRODUCT_TYPE} from "../../../app/components/OrderSumsGroupBySelectMenu"
import {subDays} from "date-fns"
import {PRODUCT_TYPE_ENTRANCE_TICKET} from "../../../app/baseContext"
import {useEntranceTranslation} from "../entranceTranslationContext"
import SmallVisitorStatsCard from "../../../app/visitor-stats/SmallVisitorStatsCard"
import TestOrdersSwitch from "../../../app/components/TestOrdersSwitch"
import {useTheme} from "@mui/material"
import {ChartCardAvatar} from "../../../app/components/avatars"
import {STATS_TAB} from "../../../sales/visitors/Visitors"

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    grid: {
        paddingTop: theme.spacing(2),
    },
}))

const LOCAL_STORAGE_KEY = "bpn.testOrders"
const NO_OF_DAYS = 10

export const EntrancesOverview = () => {
    const classes = useStyles()
    const t = useEntranceTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()

    const {umbrellaAndChildrenIds} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const [frequency] = useState(DAILY)
    const [groupBy] = useState(GROUP_BY_PRODUCT_NAME)
    const [createdAfter] = useState(subDays(new Date(), NO_OF_DAYS).getTime())

    const [{data: orderSumsData, loading: loadingOrderSums}] = useAxiosBpn({
        url: "orders/sums",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                       ? umbrellaAndChildrenIds.join()
                       : Array.from(userAccessMuseumIds.keys()).join(),
            groupBy: groupBy,
            groupByDateFrequency: frequency,
            test: testOrders,
            createdAfter: createdAfter,
            productType: PRODUCT_TYPE_ENTRANCE_TICKET,
        },
    })

    const [{data: visitorStatsData, loading: visitorStatsLoading}] = useAxiosBpn({
        url: "orders/visitors",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                       ? umbrellaAndChildrenIds.join()
                       : Array.from(userAccessMuseumIds.keys()).join(),
            groupBy: groupBy,
            groupByDateFrequency: frequency,
            test: testOrders,
            visitorStats: true,
            after: createdAfter,
            productType: PRODUCT_TYPE_ENTRANCE_TICKET,
        },
    })

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    const breadcrumbs = [
        {label: t("entrance"), url: ENTRANCE_BASE_URL},
        {label: t("overview")},
    ]

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <ScrollableSectionView>
                <Grid container
                      className={classes.grid}
                      justifyContent="space-between"
                      alignItems="stretch"
                      spacing={2}>
                    <Grid item
                          xs={12}
                          xl={6}>
                        <SmallRevenueCard data={orderSumsData}
                                          loading={loadingOrderSums}
                                          frequency={frequency}
                                          groupBy={groupBy}
                                          shortcutTo={{
                                              label: "Gå til hovedside for omsetning (data gruppert på produkttype)",
                                              url: `/sales/revenue?groupBy=${GROUP_BY_PRODUCT_TYPE}`
                                          }}
                                          testOrders={testOrders}
                                          title={t("revenue")}
                                          subheader="Pr. Inngangsbillett siste ti dager"
                                          avatar={<ChartCardAvatar backgroundColor={testOrders
                                                                                    ? testOrdersPalette?.dark
                                                                                    : null}/>}/>
                    </Grid>
                    <Grid item
                          xs={12}
                          xl={6}>
                        <SmallVisitorStatsCard data={visitorStatsData}
                                               loading={visitorStatsLoading}
                                               frequency={frequency}
                                               testOrders={testOrders}
                                               groupBy={groupBy}
                                               shortcutTo={{
                                                   label: "Gå til hovedside for besøkstall (data gruppert på produkttype)",
                                                   url: `/sales/visitors?tab=${STATS_TAB}&groupBy=${GROUP_BY_PRODUCT_TYPE}`
                                               }}
                                               title={t("visitorNumbers")}
                                               subheader="Pr. Inngangsbillett siste ti dager"
                                               avatar={<ChartCardAvatar backgroundColor={testOrders
                                                                                         ? testOrdersPalette?.dark
                                                                                         : null}/>}/>
                    </Grid>
                </Grid>
            </ScrollableSectionView>
        </>
    )
}