import React from "react"
import {Button, CircularProgress, Tooltip} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        position: "relative",
    },
}))

export const ButtonWithProgress = ({
                                       label,
                                       onClick,
                                       size = "medium",
                                       startIcon = null,
                                       endIcon = null,
                                       loading = false,
                                       disabled = false,
                                       color = "default",
                                       variant = "contained",
                                       tooltip = "",
                                   }) => {
    const classes = useStyles()

    return label ? (
        <div className={classes.wrapper}>
            <Tooltip title={tooltip}>
                <span>
                    <Button variant={variant}
                            color={color}
                            disabled={disabled || loading}
                            startIcon={startIcon}
                            endIcon={endIcon}
                            size={size}
                            onClick={onClick}>
                        {label}
                    </Button>
                </span>
            </Tooltip>
            {loading && <CircularProgress size={24}
                                          className={classes.buttonProgress}/>}
        </div>
    ) : null
}