import React from 'react'
import {useBaseTranslation} from "../../baseTranslationContext"
import AccordionActions from "@mui/material/AccordionActions"
import Button from "@mui/material/Button"
import DeleteIcon from "@mui/icons-material/Delete"
import makeStyles from '@mui/styles/makeStyles';
import MuiAccordion from "@mui/material/Accordion"
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles(theme => ({
    deleteButton: {
        background: "#d32f2f",
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: "white",
        "&:hover": {
            background: "#9a0007",
        },
    },
}))

const Accordion = withStyles({
    root: {
        paddingTop: "8px",
        paddingBottom: "8px",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$disabled': {
            background: "inherit",
        },
    },
    disabled: {},
})(MuiAccordion);

const ScheduleEventAccordion = ({
                                    expanded,
                                    handleExpandedChange,
                                    handleEventsDelete,
                                    children,
                                }) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    return (
        <Accordion expanded={expanded}
                   disabled={!handleExpandedChange}
                   onChange={handleExpandedChange}>
            {children}
            <AccordionActions>
                {handleEventsDelete && (
                    <Button variant="contained"
                            size="small"
                            className={classes.deleteButton}
                            startIcon={<DeleteIcon/>}
                            onClick={handleEventsDelete}>
                        {t("delete")}
                    </Button>
                )}
            </AccordionActions>
        </Accordion>
    )
}

export default ScheduleEventAccordion