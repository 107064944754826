import React, {useEffect, useState} from "react"
import {Card, CardContent, CardHeader, Grid, IconButton, Typography} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {Close} from "@mui/icons-material"

const OrderDetailsDialogQueryDetails = ({queryResponse, handleClose, system='netaxept'}) => {
    const t = useBaseTranslation()
    const [responseItems, setResponseItems] = useState([])

    useEffect(() => {
        function getItems(object) {
            return Object.entries(object).map(([key, value]) => {
                if (value) {
                    if (typeof value === "string" || typeof value === "number") {
                        return {label: t(key), value: value, type: "value"}
                    } else if (typeof value === "object") {
                        return {label: t(key), value: getItems(value), type: "items"}
                    }
                }
                return null
            }).filter(x => x)
        }

        if (queryResponse) {
            const items = getItems(queryResponse)
            setResponseItems(items)
        }
    }, [queryResponse, t])

    const GridItem = ({padding = 0, marginTop = 0, fontSize = "0.95em", strong = false, children}) => (
        <Grid item
              xs={12}
              sm={4}>
            <Typography variant="subtitle1"
                        style={{fontSize: fontSize, paddingLeft: padding, marginTop: marginTop}}>
                {strong
                 ? <strong>
                     {children}
                 </strong>
                 : children
                }
            </Typography>
        </Grid>
    )

    const getGridItem = (label, value, type, padding = 0, fontSize = "0.95em") => {
        if (type === "value") {
            return (
                <>
                    <GridItem strong={true}
                              fontSize={fontSize}
                              padding={padding}>{label}:</GridItem>
                    <GridItem size="small"
                              fontSize={fontSize}>{value}</GridItem>
                </>
            )
        } else if (type === "items") {
            return (
                <>
                    <GridItem strong={true}
                              marginTop={8}
                              fontSize={fontSize}
                              padding={16}>
                        {label}:
                    </GridItem>
                    {value.map(({label: _label, value: _value, type: _type}, index) => (
                        <Grid container
                              key={index}
                              alignItems="center">
                            {getGridItem(_label, _value, _type, 32, "0.91em")}
                        </Grid>
                    ))}
                </>
            )
        }
    }

    return (
        <Card square
              variant="outlined"
              style={{marginTop: 8}}>
            <CardHeader title={`${t("response")} (${t(system)})`}
                        action={
                            <IconButton onClick={handleClose} size="large">
                                <Close/>
                            </IconButton>}
                        titleTypographyProps={{variant: "h6", color: "secondary"}}/>
            <CardContent>
                {system === "netaxept" && (
                    <Typography variant="caption" color="textSecondary">
                        Vær oppmerksom på at beløp i spørringer til Netaxept står oppført i øre, ikke kroner.
                        I praksis har de to nuller for mye.
                    </Typography>
                )}
                {responseItems.map(({label, value, type}, index) => (
                    <Grid container
                          key={index}
                          alignItems="center">
                        {getGridItem(label, value, type)}
                    </Grid>
                ))}
            </CardContent>
        </Card>
    );
}

export default OrderDetailsDialogQueryDetails