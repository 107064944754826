import React from "react";
import { useBaseTranslation } from "../baseTranslationContext";
import { ProgressIndicator } from "../ProgressIndicator";
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";

const OrderDetailsDialogComments = ({loading, order}) => {
  const t = useBaseTranslation()

  const getProductNameByProductId = (productId) => {
    return order.orderLines.find(orderLine => orderLine.productId === productId)?.productName || ""
  }

  return loading
    ? <ProgressIndicator/>
    : <Card square
            variant="outlined">
      <CardHeader title={order.comments.length > 1 ? t("comments") : t("comment")}
                  titleTypographyProps={{color: "primary"}}
      />
      <CardContent>
        {order.comments?.map(comment => {
          return <div key={`${comment.productId}${comment.comment}`}>
            <Typography variant={"h6"}>{getProductNameByProductId(comment.productId)}:</Typography>
            <Typography variant={"caption"}>{t('customerInput')}:</Typography>
            <Typography>{comment.comment}</Typography>
            {order.comments.length > 1 && <Divider style={{marginTop: "1rem", marginBottom: "1rem"}}/>}
          </div>})
        }

      </CardContent>
    </Card>

}

export default OrderDetailsDialogComments
