import React, {useEffect, useState} from "react"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import {Grid} from "@mui/material"
import {
    createCollapsibleVisitorsPivotTableHeaders,
    createCollapsibleVisitorsTableRows,
    createVisitorsPivotTableHeaders,
    createVisitorsTableRows,
    createVisitorStatsExcelDataset,
} from "./visitorsHelperFunctions"
import RevenueCardActions from "../revenue/RevenueCardActions"
import GenericTable from "../generic-table/GenericTable"
import DashboardCard from "../components/DashboardCard"

const VisitorStatsPivotTable = ({
                                    data,
                                    loading,
                                    testOrders,
                                    handleDateFilterDialogOpen,
                                    dateFilterLabel,
                                    frequency,
                                    groupBy,
                                }) => {
    const t = useBaseTranslation()

    const [rows, setRows] = useState([])
    const [collapsibleRows, setCollapsibleRows] = useState([])
    const [columns, setColumns] = useState([])
    const [collapsibleColumns, setCollapsibleColumns] = useState([])
    const [excelDataSet, setExcelDataSet] = useState()

    useEffect(() => {
        if (data) {
            const {pivotTable} = data

            if (pivotTable) {
                const {columns: _columns, data: _data} = pivotTable

                if (_data?.length) {
                    const headers = createVisitorsPivotTableHeaders(_columns, t)
                    setColumns(headers)

                    const _rows = createVisitorsTableRows(_data, headers)
                    setRows(_rows)

                    const _collapsibleHeaders = createCollapsibleVisitorsPivotTableHeaders(_columns, t)
                    setCollapsibleColumns(_collapsibleHeaders)

                    const _collapsibleRows = createCollapsibleVisitorsTableRows(_data, _columns, t)
                    setCollapsibleRows(_collapsibleRows)
                } else {
                    setColumns([])
                    setCollapsibleColumns([])
                    setCollapsibleRows([])
                    setRows([])
                }
            } else {
                setColumns([])
                setCollapsibleColumns([])
                setCollapsibleRows([])
                setRows([])
            }
        } else {
            setColumns([])
            setCollapsibleColumns([])
            setCollapsibleRows([])
            setRows([])
        }
    }, [data, t, frequency, groupBy, loading])

    useEffect(() => {
        if (columns) {
            const dataSet = createVisitorStatsExcelDataset(columns, rows)
            setExcelDataSet(dataSet)
        }
    }, [rows, columns])

    return (
        <DashboardCard title={t("visitorNumbers")}
                       testOrders={testOrders}
                       action={
                           <RevenueCardActions excelDataSet={excelDataSet}
                                               handleDateFilterDialogOpen={handleDateFilterDialogOpen}
                                               dateFilterLabel={dateFilterLabel}
                                               excelFileName="Besøkstall Museumsbillett"/>
                       }>
            {loading
             ? <ProgressIndicator/>
             : <Grid container
                     direction="column"
                     spacing={1}>
                 <Grid container>
                     <Grid item
                           xs={12}
                           style={{width: "100%"}}>
                         <GenericTable columns={collapsibleColumns}
                                       rows={collapsibleRows}
                                       collapsible={true}
                                       totalsRow
                                       totalsColumn/>
                     </Grid>
                 </Grid>
             </Grid>
            }
        </DashboardCard>
    )
}

export default VisitorStatsPivotTable