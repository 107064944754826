import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import {v4 as uuid} from "uuid";
import ReactQuill, {Quill} from "react-quill";
import TranslationRichTextField from "./TranslationRichTextField";
import {useDebounceCallback} from "../hooks/useDebounceCallback";

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addTranslationButton: {
        float: "right",
    },
}))

const Link = Quill.import("formats/link")

class MyLink extends Link {
    static create(value) {
        let node = super.create(value)
        value = this.sanitize(value)
        if (!value.startsWith("http")) {
            value = "http://" + value
        }
        node.setAttribute("href", value)
        node.setAttribute("style", "color: blue;text-decoration: underline;")
        return node
    }
}

Quill.register(MyLink, true)

const block = Quill.import("blots/block");
block.tagName = 'DIV';
Quill.register(block, true);

export const translationRichTextModules = {
    toolbar: [
        [{header: "1"}, {header: "2"}],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
        ["bold", "italic", "underline", "strike"],
        [{list: "ordered"}, {list: "bullet"}],
        ["link"],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
}

export const translationRichTextFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "ordered",
    "link",
    "align",
]

const TranslatableRichTextField = ({
                                       value,
                                       variant = "filled",
                                       onChange,
                                       showTranslationFields = false
                                   }) => {
    const classes = useStyles()
    const [originalText, setOriginalText] = useState()
    const [translations, setTranslations] = useState([])
    const delayCallback = useDebounceCallback(500);

    useEffect(() => {
        setOriginalText(value?.value || "")
        setTranslations(value?.translations || [])
    }, [value])

    const addTranslation = () => {
        translations.find(translation => translation.locale === "en") ?
            setTranslations(prevState => [...prevState, {id: uuid(), locale: "", translation: ""}]) :
            setTranslations(prevState => [...prevState, {id: uuid(), locale: "en", translation: ""}])
    }

    const updateTranslation = (newValue, translationId, propertyToUpdate, emitTranslationValue) => {
        const updatedTranslations = [...translations]
        const translationIndex = updatedTranslations.findIndex(t => t.id === translationId)
        updatedTranslations[translationIndex][propertyToUpdate] = newValue
        setTranslations(updatedTranslations)
        if (emitTranslationValue) {
            onChange({value: originalText, translations: updatedTranslations})
        }
    }

    const removeTranslation = (id) => {
        onChange({value: originalText, translations: translations.filter(translation => translation.id !== id)})
        setTranslations(prevState => prevState.filter(translation => translation.id !== id))
    }

    return (
        <div>
            <ReactQuill value={originalText}
                        onChange={(generatedHTML) => {
                            delayCallback(() => {
                                if (!originalText) return;
                                if (generatedHTML && originalText && (generatedHTML === originalText)) return
                                onChange({value: generatedHTML, translations: translations})
                            })
                            setOriginalText(generatedHTML)
                        }}
                        theme={"snow"}
                        style={{height: "150px", marginBottom: "70px"}}
                        modules={translationRichTextModules}
                        formats={translationRichTextFormats}
                        bounds={".quill-wrapper"}/>
            {showTranslationFields && <Button className={classes.addTranslationButton} onClick={() => addTranslation()}>+
                                                                                                                        oversettelse</Button>}
            {showTranslationFields && translations && translations.map(translation =>
                <TranslationRichTextField
                    key={translation.id}
                    onTranslationChanged={(newValue,
                                           translationId,
                                           property,
                                           emitTranslationValue) => updateTranslation(newValue,
                        translationId,
                        property,
                        emitTranslationValue)}
                    translation={translation}
                    variant={variant}
                    translations={translations}
                    onTranslationRemoved={removeTranslation}/>)}
        </div>
    )
}

export default React.memo(TranslatableRichTextField)
