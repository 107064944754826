import React from "react"
import {Grid, Typography} from "@mui/material"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import DashboardCard from "../components/DashboardCard"
import {formatNOK} from "../helper-functions/numberFormat"

const ProductGeneralStatsCard = ({product, loading, testOrders}) => {
    const {
        newestSaleAt,
        revenueExcludingVat,
        revenueIncludingVat,
        unitsSold,
    } = product
    const t = useBaseTranslation()

    const items = [
        {
            label: t("newestSale"),
            text: new Date(newestSaleAt).toLocaleString("nb-NO"),
        },
        {
            label: t("unitsSold"),
            text: unitsSold,
        },
        {
            label: t("revenueExcludingVat"),
            text: formatNOK(revenueExcludingVat),
        },
        {
            label: t("revenueIncludingVat"),
            text: formatNOK(revenueIncludingVat),
        },
    ]

    return (
        <DashboardCard testOrders={testOrders}>
            {loading
             ? <ProgressIndicator/>
             : items.map(({label, text, subText}, index) => (
                    <Grid key={index}
                          container
                          direction="row"
                          wrap="nowrap"
                          spacing={2}
                          alignItems="baseline"
                          justifyContent="space-between">
                        <Grid item>
                            <Typography variant="subtitle2"
                                        noWrap>{label}:</Typography>
                        </Grid>
                        <Grid container
                              justifyContent="flex-end"
                              spacing={1}>
                            <Grid item>
                                <Typography variant="body2">{text}</Typography>
                            </Grid>
                            {subText && (
                                <Grid item>
                                    <Typography variant="body2"
                                                color="textSecondary">{subText}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ))}
        </DashboardCard>
    )
}

export default ProductGeneralStatsCard