import React from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {FormControlLabel, Switch, useTheme} from "@mui/material"

const TestOrdersSwitch = ({testOrders, setTestOrders}) => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()

    return (
        <FormControlLabel control={<Switch size="small"
                                           checked={testOrders}
                                           onChange={() => setTestOrders(!testOrders)}/>}
                          style={testOrders ? {backgroundColor: testOrdersPalette?.main} : null}
                          label={t("testOrders")}/>
    )
}

export default TestOrdersSwitch