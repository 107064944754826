import {Grid, IconButton, Typography} from "@mui/material"
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material"
import React from "react"

const MaximizeTableButton = ({rows, tableMaximized, setTableMaximized}) => {
    return rows?.length > 5
           ? <Grid container
                   spacing={1}
                   style={{margin: -16}}
                   alignItems="center"
                   justifyContent="flex-end">
               <Grid item>
                   <Typography variant="caption">
                       {`Viser ${tableMaximized ? rows.length : 5} av ${rows.length} linjer`}
                   </Typography>
               </Grid>
               <Grid item>
                   <IconButton onClick={() => setTableMaximized(!tableMaximized)} size="large">
                       {tableMaximized ? <KeyboardArrowUp fontSize="large"/> : <KeyboardArrowDown fontSize="large"/>}
                   </IconButton>
               </Grid>
           </Grid>
           : null;
}

export default MaximizeTableButton