import React from "react"
import {calculateHeight} from "./helper-functions/calculateHeight"


const ScrollableSectionView = ({children, header = true, breadcrumbs = true, toolbar = true}) => {
    return (
        <div style={{overflowY: "auto", overflowX: "hidden", height: calculateHeight(header, breadcrumbs, toolbar)}}>
            {children}
        </div>
    )
}

export default ScrollableSectionView