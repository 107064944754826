import {endOfMonth, format, formatDistanceToNow, parse, startOfMonth} from "date-fns"
import {nb} from "date-fns/locale"
import _ from "lodash"

export const getMonthString = (monthNumber) => {
    return _.upperFirst(dateFormat(new Date().setMonth(monthNumber - 1), "MMMM"))
}

export const dateFormat = (date, formatStr = "PP") => {
    if (date) {
        try {
            return format(date, formatStr, {
                locale: nb, // or global.__localeId__
            })
        } catch {
            return null
        }
    }
    return null
}

export const getEventLabel = (t, event) => {
    const {startDate, startTime, endTime, isFullDay, name} = event
    let label = `${dateFormat(parse(startDate, "yyyy-MM-dd", new Date()), "do MMM")} `

    if (isFullDay) {
        label += t("wholeDay")
    } else {
        label += dateFormat(parse(startTime, "HH:mm:ss", new Date()), "HH:mm")

        if (endTime) {
            label += `-${dateFormat(parse(endTime, "HH:mm:ss", new Date()), "HH:mm")}`
        }
    }

    if (name) label += ` ${name}`

    return label
}

export const parseAndFormatTimeString = time => {
    const parsedTime = parse(time, "HH:mm:ss", new Date())
    return dateFormat(parsedTime, "HH:mm")
}

export const parseAndFormatDateString = date => {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date())
    return dateFormat(parsedDate)
}

export const distanceToNow = date => {
    if (date) {
        return formatDistanceToNow(date, {
            addSuffix: true,
            locale: nb, // or global.__localeId__
        })
    }
    return null
}

export const datetimeToExcelDate = datetime => {
    let temp = new Date(datetime)
    const days = Math.round((temp.setHours(0, 0, 0, 0) - new Date(1899, 11, 30)) / 8.64e7)
    const partDay = (Math.abs((datetime - temp) % 8.64e7) / 8.64e7).toFixed(10)
    return parseFloat(days + partDay.substr(1))
}

export const getValidityString = ({startDate, startTime, endDate, endTime}) => {
    const _startDate = dateFormat(parse(startDate, "yyyy-MM-dd", new Date()), "PP")
    const _endDate = dateFormat(parse(endDate, "yyyy-MM-dd", new Date()), "PP")
    const _startTime = dateFormat(parse(startTime, "HH:mm:ss", new Date()), "HH:mm")
    const _endTime = dateFormat(parse(endTime, "HH:mm:ss", new Date()), "HH:mm")

    let valid = _startDate
    if (_endDate && _endDate !== _startDate) valid = `${valid} - ${_endDate}`
    if (_startTime) valid = `${valid}, ${_startTime}`
    if (_endTime && _endTime !== _startTime) valid = `${valid}-${_endTime}`

    return valid
}

export const convertValidFromToDatetime = (startDate, startTime) => {
    if (startTime) {
        return parse(startDate + startTime, "yyyy-MM-ddHH:mm:ss", new Date())
    } else return parse(startDate, "yyyy-MM-dd", new Date().setHours(0, 0, 0))
}

export const convertValidToToDatetime = (startDate, endDate, endTime) => {
    const _endDate = endDate ? endDate : startDate

    if (endTime) {
        return parse(_endDate + endTime, "yyyy-MM-ddHH:mm:ss", new Date())
    } else {
        return parse(_endDate, "yyyy-MM-dd", new Date()).setHours(23, 59, 59)
    }
}

export const getStartAndEndOfMonth = (date = new Date()) => ({start: startOfMonth(date), end: endOfMonth(date)})

export const MONTHLY = "monthly"
export const YEARLY = "yearly"
export const WEEKLY = "weekly"
export const DAILY = "daily"

export const DATE_FREQUENCIES = [DAILY, WEEKLY, MONTHLY, YEARLY]

export const getFormatStringBasedOnFequency = (frequency) => {
    switch (frequency) {
        case YEARLY:
            return "y"
        case WEEKLY:
            return "w"
        case DAILY:
            return "d. MMMM"
        default:
            return "MMMM"
    }
}

export const getDateLabelBasedOnFequency = (frequency) => {
    switch (frequency) {
        case YEARLY:
            return "year"
        case WEEKLY:
            return "week"
        case DAILY:
            return "day"
        default:
            return "month"
    }
}

export const simpleMonthNumberToStringConverter = (number) => {
    const noMonths = ["Januar", "Februar" , "Mars", "April", "Mai", "Juni", "Juli" , "August", "September", "Oktober", "November", "Desember"]
    return noMonths[number - 1]
}
