import React from "react";
import { Chip, Icon, Tooltip, Typography } from "@mui/material";
import { getSubstring } from "../../helper-functions/stringUtility";

const SelectedUniqueSellingPointChip = ({uniqueSellingPoint}) => {
  const {icon, title} = uniqueSellingPoint

  return (
    <Chip label={
      <Tooltip title={
        <>
          <Icon fontSize={"small"} style={{marginRight: "8px", color:"white"}}>{icon.toLowerCase()}</Icon>
          <Typography variant="body2">{title}</Typography>
        </>}>
        <div>{getSubstring(title, 12)}</div>
      </Tooltip>
    }
          style={{margin: 8}}
          color="primary"
          icon={<Icon fontSize={"small"} style={{marginRight: "8px", color:"white"}}>{icon.toLowerCase()}</Icon>}/>
  )
}

export default SelectedUniqueSellingPointChip
