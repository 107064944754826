import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TranslationField from "./TranslationField";
import {v4 as uuid} from "uuid";
import {useProductTranslationProvider} from "./ProductTranslationContext";

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addTranslationButton: {
        float: "right"
    }
}))

const TranslatableTextField = ({
                                   label,
                                   required,
                                   error,
                                   helperText,
                                   multiline = false,
                                   name,
                                   value,
                                   size = "medium",
                                   variant = 'filled',
                                   onChange,
                                   alwaysShowTranslationFields = false,
                                   rows
                               }) => {
    const classes = useStyles()
    const [originalText, setOriginalText] = useState("");
    const [translations, setTranslations] = useState([]);
    const {showTranslationFields} = useProductTranslationProvider();


    useEffect(() => {
        setOriginalText(value?.value || "")
        setTranslations(value?.translations || [])
    }, [value])

    const addTranslation = () => {
        translations.find(translation => translation.locale === "en") ?
            setTranslations(prevState => [...prevState, {id: uuid(), locale: "", translation: ""}]) :
            setTranslations(prevState => [...prevState, {id: uuid(), locale: "en", translation: ""}])
    }

    const updateTranslation = (newValue, translationId, propertyToUpdate, emitTranslationValue) => {
        const updatedTranslations = [...translations]
        const translationIndex = updatedTranslations.findIndex(t => t.id === translationId)
        updatedTranslations[translationIndex][propertyToUpdate] = newValue
        setTranslations(updatedTranslations)
        if (emitTranslationValue) {
            onChange({value: originalText, translations: updatedTranslations})
        }
    }

    const updateOriginalText = (event) => {
        if (event.target.value !== value?.value) {
            onChange({value: event.target.value, translations: translations})
            setOriginalText(event.target.value)
        }
    }

    const removeTranslation = (id) => {
        onChange({value: originalText, translations: translations.filter(translation => translation.id !== id)})
        setTranslations(prevState => prevState.filter(translation => translation.id !== id))
    }

    return (
        <div>
            <TextField label={label}
                       className={classes.textfield}
                       required={required}
                       error={error}
                       helperText={helperText}
                       name={name}
                       variant={variant}
                       fullWidth
                       size={size}
                       rows={rows}
                       multiline={multiline}
                       value={originalText}
                       onBlur={updateOriginalText}
                       onChange={(event) => setOriginalText(event.target.value)}/>
            {(showTranslationFields || alwaysShowTranslationFields) && (
                <Button className={classes.addTranslationButton} onClick={() => addTranslation()}>
                    +oversettelse
                </Button>
            )}
            {(showTranslationFields || alwaysShowTranslationFields) && translations && translations.map(translation => (
                <TranslationField key={translation.id}
                                  onTranslationChanged={(newValue, translationId, property, emitTranslationValue) => {
                                      updateTranslation(newValue, translationId, property, emitTranslationValue)
                                  }}
                                  translation={translation}
                                  variant={variant}
                                  multiline={multiline}
                                  translations={translations}
                                  onTranslationRemoved={removeTranslation}/>
            ))}
        </div>
    )
}

export default React.memo(TranslatableTextField)
