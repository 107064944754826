import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {Card, CardContent, CardHeader, Grid} from "@mui/material"
import {formatNOK, formatPercent} from "../helper-functions/numberFormat"
import {GridLabel, GridValue} from "../order-details/gridComponents"
import OrderLineDetail from "../order-details/OrderDetailsDialogOrderLineDetails"

const VoucherOrderLinesDetails = ({loading, voucher, orderLines}) => {
    const t = useBaseTranslation()

    const [gridRows, setGridRows] = useState([])

    useEffect(() => {
        if (voucher) {
            const {quantity, vat, sum} = voucher
            setGridRows([
                {label: t("quantity"), value: quantity},
                {label: t("vat"), value: formatPercent(vat, 0)},
                {label: t("sumIncludingVat"), value: formatNOK(sum, 2)},
            ])
        }
    }, [voucher, t])

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined">
               <CardHeader title={t("orderLines")}
                           titleTypographyProps={{color: "primary"}}
               />
               <CardContent>
                   {loading
                    ? <ProgressIndicator/>
                    : <Grid container
                            direction="column">
                        {gridRows?.map(({label, value}, index) =>
                            value &&
                            <Grid container
                                  key={index}
                                  alignItems="center">
                                <GridLabel>{label && `${label}:`}</GridLabel>
                                <GridValue>{value}</GridValue>
                            </Grid>,
                        )}
                        {orderLines?.map((line) => (
                            <OrderLineDetail key={line.id}
                                             loading={loading}
                                             orderLine={line}/>
                        ))}
                    </Grid>
                   }
               </CardContent>
           </Card>

}

export default VoucherOrderLinesDetails