import React from "react"
import CardHeader from "@mui/material/CardHeader"
import {Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import TestCards from "./TestCards"
import {ProgressIndicator} from "../ProgressIndicator"

const CreateOrderDialogPaymentTypes = ({
                                           paymentTypes,
                                           loading,
                                           radioGroupPaymentTypeValue,
                                           handlePaymentTypeSelect,
                                           showTestCards,
                                           style,
                                       }) => {
    const t = useBaseTranslation()

    return loading
        ? <ProgressIndicator linear={false}/>
        : paymentTypes?.length
            ? <Card square
                    style={style}>
                <CardHeader title={t("paymentType")}
                            titleTypographyProps={{color: "primary"}}/>
                <CardContent>
                    <FormControl margin="dense"
                                 size="small"
                                 variant="outlined"
                                 component="fieldset">
                        <RadioGroup value={radioGroupPaymentTypeValue}
                                    onChange={handlePaymentTypeSelect}>
                            {paymentTypes.map(({name}, index) => (
                                <FormControlLabel key={index}
                                                  control={<Radio/>}
                                                  value={index.toString()}
                                                  label={t(name)}/>
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <TestCards hidden={!showTestCards}/>
                </CardContent>
            </Card>
            : null
}

export default CreateOrderDialogPaymentTypes