import React from "react"
import {
    Checkbox,
    FormControl,
    Grid,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from "@mui/material"
import {getFamilyDiscountLimitsString} from "../../../settings/products-settings/family-discounts/FamilyDiscountsTable"
import {useBaseTranslation} from "../../baseTranslationContext"
import SelectedFamilyDiscountChip from "./SelectedFamilyDiscountChip"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const getErrorText = (unAccessibleLimits) => {
    return `Produktet mangler priskategori${unAccessibleLimits.length > 1 ? "ene" : ""}: ${unAccessibleLimits
        .map(({priceCategoryName}) => priceCategoryName.toUpperCase())
        .join(", ")}!`
}

const ListItemTextPrimary = ({discount: {name, unAccessibleLimits, active}}) => (
    <Grid container spacing={1} alignItems="center" justifyContent="space-between">
        <Grid item>
            <Typography variant="h6">{name}</Typography>
        </Grid>
        {!active
            ? <Grid item>
                <Typography variant="subtitle2" color="textSecondary">Deaktivert i innstillinger!</Typography>
            </Grid>
            : null}
        {unAccessibleLimits?.length
            ? <Grid item>
                <Typography variant="subtitle2" color="error">{getErrorText(unAccessibleLimits)}</Typography>
            </Grid>
            : null}
    </Grid>
)

const ProductFamilyDiscountsSelector = ({selected, available, handleChange}) => {
    const t = useBaseTranslation()

    const _handleChange = ({target: {value}}) => {
        // Hack to enable unchecking items in list
        handleChange(value.filter(v => value.filter(({id}) => id === v.id)?.length < 2))
    }

    return (
        <FormControl style={{margin: 8, width: "100%"}}>
            <InputLabel id="mutiple-chip-label">{t("selectDiscounts")}</InputLabel>
            <Select labelId="mutiple-chip-label"
                    multiple
                    value={selected}
                    onChange={_handleChange}
                    input={<Input/>}
                    renderValue={(selected) => (
                        <div style={{display: "flex", flexWrap: "wrap"}}>
                            {selected.map((discount) => (
                                <SelectedFamilyDiscountChip key={discount.id} discount={discount}/>
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}>
                {available.map((discount) => (
                    <MenuItem key={discount.id}
                              value={discount}
                              disabled={!!discount?.unAccessibleLimits?.length || !discount?.active}>
                        <Checkbox color={"primary"}
                                  checked={selected.filter(({id}) => id === discount.id).length > 0}/>
                        <ListItemText primary={<ListItemTextPrimary discount={discount}/>}
                                      secondary={getFamilyDiscountLimitsString(discount.limits)}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ProductFamilyDiscountsSelector

