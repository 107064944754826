import React, { useRef } from "react";
import { Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import GenericImage from "../../../asset/GenericImage.png";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { useBaseTranslation } from "../../baseTranslationContext";

const ThumbnailContainer = styled(Paper)(({theme}) => ({
    height: "200px",
    width: "200px",
    background: "whitesmoke",
    textAlign: "center",
    "& > img": {
        width: "200px",
        height: "200px",
        objectFit: "cover",
    }, "&:hover": {
        boxShadow: theme.shadows[6],
        cursor: "pointer",
    },
}));

const StyledButton = styled(Button)(({theme}) => ({
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
}));

const RemoveButton = styled(StyledButton)(({theme}) => ({
    background: "#d32f2f",
    color: "white",
    "&:hover": {
        background: "#9a0007",
    },
}));

export const UploadMediaButton = ({
                                      mediaItem, isUploading,
                                      onImageSelected,
                                      deleteProductImage,
                                      currentImageUrl,
                                      uploadProgress,
                                      imageFilters= null
                                  }) => {
    const inputRef = useRef(null);
    const t = useBaseTranslation()

    const getMediaTypeFromMIMEType = (mime) => {
        if (!mime) {
            return null;
        }

        if (mime.startsWith("image/")) {
            return "image";
        } else if (mime.startsWith("video/")) {
            return "video";
        } else if (mime.startsWith("application/")) {
            return "attachment";
        }

        return null;
    };

    const handleUpload = async (files) => {
        if (files === null) {
            return;
        }
        const file = Array.from(files).filter(file => {
            const fileType = getMediaTypeFromMIMEType(file.type);
            return (
              !!fileType && ((fileType === "image"))
            )
        })[0];

        if (!file) {
            return;
        }

        const src = URL.createObjectURL(file);
        let type = "image";
        if (onImageSelected) {
            onImageSelected({
                file: file,
                id: file.name,
                name: file.name,
                src,
                type,
                thumbnail: src,
            })
        }

    }

    return (
      <>
          <input ref={inputRef}
                 type="file"
                 accept="image/png, image/gif, image/jpeg"
                 style={{display: "none"}}
                 multiple={false}
                 onChange={event => handleUpload(event?.target?.files || null)}/>
          <div>
              <ThumbnailContainer onClick={() => inputRef?.current?.click()}>
                  {isUploading ? <CircularProgressWithLabel value={uploadProgress}/> :
                    <img src={mediaItem?.thumbnail || currentImageUrl|| GenericImage}
                         title={mediaItem?.name || "Arrangementbilde"}
                         alt={mediaItem?.name || "bilde som vises i portal"}
                         style={imageFilters ? {filter: `brightness(${imageFilters.brightness}) blur(${imageFilters.blur}px) saturate(${imageFilters.saturation})`} : {}}
                         draggable={false}/>}
              </ThumbnailContainer>
              <StyledButton
                variant="text"
                color="primary"
                startIcon={currentImageUrl ? <EditIcon/> : <AddIcon/>}
                onClick={() => inputRef?.current?.click()}>
                  {currentImageUrl ? t("edit") : t("add")}
              </StyledButton>
              {(mediaItem || currentImageUrl) &&
                <RemoveButton
                  variant="contained"
                  startIcon={<DeleteIcon/>}
                  onClick={() => deleteProductImage()}>
                    {t("delete")}
                </RemoveButton>}
          </div>
      </>
    );
}

export default UploadMediaButton;
