import React from "react"
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: "rotate(90deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
}))

const ExpandCardButton = ({expanded, handleExpandClick}) => {
    const classes = useStyles()

    return (
        <IconButton
            onClick={handleExpandClick}
            className={clsx(classes.expand,
                {[classes.expandOpen]: expanded}
            )}
            size="large">
            <MoreVertIcon/>
        </IconButton>
    );
}

export default ExpandCardButton