import React, {useEffect, useState} from "react"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import Grid from "@mui/material/Grid"
import GenericTable from "../generic-table/GenericTable"
import {Typography, useMediaQuery} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import RevenueCardActions from "./RevenueCardActions"
import {
    createExcelDatasetFromDataGrid,
    createRevenueChartData,
    createRevenueTableHeaders,
    createRevenueTableRows,
} from "./revenueHelperFunctions"
import {getDateLabelBasedOnFequency} from "../helper-functions/dateFormat"
import StackedBarChart from "../components/StackedBarChart"
import _ from "lodash"
import DashboardCard from "../components/DashboardCard"

const RevenueCard = ({
                         data,
                         loading,
                         testOrders,
                         handleSelectGroupByClick,
                         handleSelectDateFrequencyClick,
                         handleDateFilterDialogOpen,
                         dateFilterLabel,
                         frequency,
                         groupBy,
                     }) => {
    const t = useBaseTranslation()
    const {breakpoints} = useTheme()
    const smDown = useMediaQuery(breakpoints.down("sm"))
    const xsDown = useMediaQuery(breakpoints.down("xs"))

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [excelDataSet, setExcelDataSet] = useState()
    const [uniqueGroups, setUniqueGroups] = useState()
    const [chartData, setChartData] = useState()

    useEffect(() => {
        if (data) {
            const {groupedOrderSums} = data

            if (groupedOrderSums) {
                const {columns: _columns, data: _data} = groupedOrderSums

                const headers = createRevenueTableHeaders(_columns, t, smDown, xsDown)
                setColumns(headers)

                const _rows = createRevenueTableRows(_data, _columns, frequency, t, groupBy)
                setRows(_rows)

                const _groupBy = _.camelCase(groupBy)
                const _uniqueGroups = [...new Set(_rows.map(r => r[_groupBy]))]
                setUniqueGroups(_uniqueGroups)

                const dateGroupByLabel = getDateLabelBasedOnFequency(frequency)
                const uniqueDates = [...new Set(_rows.map(r => r[dateGroupByLabel]))]
                setChartData(createRevenueChartData(_rows, uniqueDates, _uniqueGroups, _groupBy, dateGroupByLabel))
            } else {
                setColumns([])
                setRows([])
                setUniqueGroups([])
                setChartData([])
            }
        } else {
            setColumns([])
            setRows([])
            setUniqueGroups([])
            setChartData([])
        }
    }, [data, t, frequency, groupBy, smDown, xsDown])

    useEffect(() => {
        const dataSet = createExcelDatasetFromDataGrid(columns, rows)
        setExcelDataSet(dataSet)
    }, [rows, columns])

    const RevenueGrid = () => {
        return rows.length
               ? <Grid container
                       direction="column"
                       spacing={1}>
                   <Grid item>
                       <StackedBarChart data={chartData}
                                        groups={uniqueGroups}
                                        xAxisLabel={t(getDateLabelBasedOnFequency(frequency))}
                                        yAxisLabel={t("revenue")}/>
                   </Grid>
                   <Grid item>
                       <GenericTable columns={columns}
                                     rows={rows}/>
                   </Grid>

               </Grid>
               : <Typography>{t("noDataToShow")}</Typography>
    }

    return (
        <DashboardCard title={t("revenue")}
                       testOrders={testOrders}
                       action={
                           <RevenueCardActions excelDataSet={excelDataSet}
                                               groupBy={groupBy}
                                               handleDateFilterDialogOpen={handleDateFilterDialogOpen}
                                               dateFilterLabel={dateFilterLabel}
                                               frequency={frequency}
                                               handleSelectDateFrequencyClick={handleSelectDateFrequencyClick}
                                               handleSelectGroupByClick={handleSelectGroupByClick}/>
                       }>
            {loading
             ? <ProgressIndicator/>
             : <RevenueGrid/>
            }
        </DashboardCard>
    )
}

export default RevenueCard