import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import SnackbarContext from './SnackbarContext'

function DefaultSnackbar({message, action, ButtonProps, SnackbarProps, customParameters}) {
    return (
        <Snackbar {...SnackbarProps}
                  message={message || ''}
                  action={action != null && (
                      <Button color='secondary'
                              size='small'
                              {...ButtonProps}>
                          {action}
                      </Button>
                  )}
        />
    )
}

export default class SnackbarProvider extends PureComponent {
    state = {
        message: null,
        open: false
    }

    constructor(props) {
        super(props)
        this.contextValue = {
            showMessage: this.showMessage
        }
    }

    showMessage = (message, action, handleAction, customParameters) => {
        this.setState({open: true, message, action, handleAction, customParameters})
    }

    handleActionClick = () => {
        this.handleClose()
        this.state.handleAction()
    }

    handleClose = () => {
        this.setState({open: false, handleAction: null})
    }

    render() {
        const {
            action,
            message,
            open,
            customParameters
        } = this.state

        const {
            ButtonProps = {},
            children,
            SnackbarProps = {},
            SnackbarComponent = DefaultSnackbar
        } = this.props

        return (
            <>
                <SnackbarContext.Provider value={this.contextValue}>
                    {children}
                </SnackbarContext.Provider>
                <SnackbarComponent message={message}
                                   action={action}
                                   ButtonProps={{...ButtonProps, onClick: this.handleActionClick}}
                                   SnackbarProps={{...SnackbarProps, open, onClose: this.handleClose}}
                                   customParameters={customParameters}/>
            </>
        )
    }
}

SnackbarProvider.propTypes = {
    ButtonProps: PropTypes.object,
    children: PropTypes.node,
    SnackbarComponent: PropTypes.elementType,
    SnackbarProps: PropTypes.object
}
