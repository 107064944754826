import React, { useEffect, useState } from "react";
import { styled, TextField } from "@mui/material";
import { getMuseumsForUser } from "../endpoints/museums";
import { useAuthsState } from "../auths/authsContext";
import { Autocomplete } from '@mui/material';
import {
    SET_SELECTED_MUSEUM,
    useMuseumSelectorDispatch,
    useMuseumSelectorState
} from "../museum-selector/museumSelectorContext";
import { useMenuTranslation } from "./menuTranslationContext";
import { ADMIN_APP_ID, getRoleByMuseumId } from "./MuseumSelectorHelpers";

const MuseumsSelectorAutocomplete = styled(Autocomplete)({
    marginTop: "15px",
    marginLeft: "8px",
    marginRight: "8px",
})

export const ROLES_VALUES = {
    GLOBAL_ADMIN: 5,
    APP_ADMIN: 4,
    ADMIN: 3,
    EDITOR: 2,
    USER: 1,
}

export const ROLES = {
    GLOBAL_ADMIN: "Global admin",
    APP_ADMIN: "App-admin",
    ADMIN: "Admin",
    EDITOR: "Editor",
    USER: "User",
}

const MuseumSelector = () => {
    const {userData, userAccessMuseumIds} = useAuthsState()
    const t = useMenuTranslation()
    const [options, setOptions] = useState([])
    const [isMounted, setIsMounted] = useState(false);
    const {selectedMuseum} = useMuseumSelectorState()
    const [value, setValue] = useState(selectedMuseum || {name: "", category: ""})
    const dispatch = useMuseumSelectorDispatch()
    const isAppAdmin = userData?.appAccess?.apps?.find(app => app.id === window._env_.REACT_APP_ID && app.role.name === ROLES.ADMIN)
    const isGlobalEkulturAdmin = userData?.appAccess?.apps?.find(app => app.id === ADMIN_APP_ID && app.role.name === ROLES.ADMIN)

    const rootMuseum = {
        id: 0,
        name: t("all"),
        type: "root",
        children: [],
        role: {name: "User", value: ROLES_VALUES.USER},
    }

    useEffect(() => {
        setIsMounted(true)
        return () => (setIsMounted(false))
    }, [])


    useEffect(() => {
        if (isMounted) {
            const museumsTheUserHasBpnAccessTo = userData?.appAccess?.museums.filter(museum => {
                return  !!museum.applications.find(application => (application.id === window._env_.REACT_APP_ID))?.role?.name
            })

            getMuseumsForUser(museumsTheUserHasBpnAccessTo).then(museums => {
                const options = museums.map((museum) => {
                    const firstLetter = museum.name[0].toUpperCase()
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                        role: getRoleByMuseumId(museum.id, userAccessMuseumIds, isAppAdmin, isGlobalEkulturAdmin),
                        ...museum,
                    };
                })

                if (isMounted) {
                    setOptions(options.sort((a, b) =>
                      b.type.localeCompare(a.type) || -b.firstLetter.localeCompare(a.firstLetter)))
                    if (options.length === 1) {
                        setValue(options[0])
                        dispatch({
                            type: SET_SELECTED_MUSEUM, payload: options[0],
                        })
                    }
                }
            })
        }
    }, [dispatch, isAppAdmin, isGlobalEkulturAdmin, isMounted, userAccessMuseumIds, userData])

    return (
      <MuseumsSelectorAutocomplete
        options={options}
        size="small"
        value={value}
        isOptionEqualToValue={(option, value) => option.name === value.name || value.type === "root"}
        onChange={(event, newValue) => {
            if (newValue) {
                setValue(newValue)
                dispatch({
                    type: SET_SELECTED_MUSEUM, payload: newValue,
                })
            } else {
                setValue(rootMuseum)
                dispatch({
                    type: SET_SELECTED_MUSEUM, payload: rootMuseum,
                })
            }
        }}
        groupBy={(option) => option.type === "umbrella" ? t("museumGroup") : t("museum")}
        getOptionLabel={(option) => option.name}
        ListboxProps={{style: {fontSize: "0.9em"}}}
        renderInput={(params) => <TextField {...params} size="medium"
                                            label={t("museumSelectorLabel")}
                                            variant="outlined"/>}
      />
    )
}

export default MuseumSelector
