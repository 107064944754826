import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import EventIcon from "@mui/icons-material/Event"
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useState } from "react";
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import {dateFormat} from "../../helper-functions/dateFormat"
import {RECURRING_TYPE_RECURS_NOT} from "../../baseContext"
import {CardActions, Paper, Tab} from "@mui/material"
import {useMuseumSelectorState} from "../../museum-selector/museumSelectorContext"
import {TabContext, TabList, TabPanel} from "@mui/lab";
import ScheduleEventAccordion from "./ScheduleEventAccordion"
import {eventIsActive} from "../../helper-functions/scheduleEventHelper"
import {useAxiosBpn} from "../../axios"
import ScheduleEventSummary from "./ScheduleEventSummary"
import ScheduleEventDetails from "./ScheduleEventDetails"
import ScheduleEventExistingOrdersDialog from "./ScheduleEventDetailsExistingOrdersDialog"

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        marginLeft: "auto",
    },
    tabPaper: {
        marginLeft: 24,
        marginRight: 24
    },

}))

const ScheduleEvents = ({product, unsavedChanges, setUnsavedChanges, updateProductState}) => {
    const classes = useStyles()
    const t = useProductEditorTranslation()
    const {selectedMuseum} = useMuseumSelectorState()

    const [scheduleEventsModule, setScheduleEventsModule] = useState()
    const [scheduleEvents, setScheduleEvents] = useState([])
    const [deletedScheduleEvents, setDeletedScheduleEvents] = useState([])
    const [selectedTab, setSelectedTab] = useState("active")
    const [selectedEvent, setSelectedEvent] = useState()
    const [expanded, setExpanded] = useState(null)
    const [existingOrdersDialogOpen, setExistingOrdersDialogOpen] = useState(false)

    const [{data: ordersData}, getOrders] = useAxiosBpn({url: "orders"}, {manual: true})

    const [{
        data: deletedScheduleEventsData,
    }, getDeletedScheduleEvents] = useAxiosBpn({
        url: "schedule_events",
        params: {productId: product?.id, deletedOnly: true}
    }, {manual: true})

    const fetchOrders = (id, num) => getOrders({
        params: {scheduleEventId: id, recurNumber: num, confirmedExceptCredited: true, detailed: true}
    }).catch(() => {
    })

    useEffect(() => {
        setScheduleEventsModule(product?.scheduleEventsModule)
        setScheduleEvents(product?.scheduleEvents || [])
        getDeletedScheduleEvents().catch(() => {
        })
    }, [product, getDeletedScheduleEvents])

    useEffect(() => {
        if (deletedScheduleEventsData && Number.isInteger(product?.id)) {
            setDeletedScheduleEvents(deletedScheduleEventsData?.scheduleEvents)
        }
    }, [deletedScheduleEventsData, product])

    const _updateProductState = (newEvents) => {
        updateProductState({
            ...product,
            scheduleEventsModule: scheduleEventsModule,
            scheduleEvents: newEvents
        })
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleScheduleEventsModuleChange = (e) => {
        e.persist()
        setScheduleEventsModule(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleEventsChange = changedEvent => _updateProductState(scheduleEvents.map(event => event.id === changedEvent.id ? changedEvent : event))

    const handleEventsDelete = (id, warningAccepted = false) => {
        if (!Number.isInteger(id) || warningAccepted) {
            _updateProductState(scheduleEvents.filter(event => event.id !== id))
        } else {
            fetchOrders(id).then(({data: {orders}}) => {
                setSelectedEvent(scheduleEvents.filter(event => event.id === id)[0])
                setExistingOrdersDialogOpen(orders.length > 0)
                if (!orders.length) _updateProductState(scheduleEvents.filter(event => event.id !== id))
            })
        }
    }

    const handleNewEvent = () => {
        let newEvents = scheduleEvents

        const newEvent = {
            id: `new-${Math.random() * 1000}`,
            startDate: dateFormat(new Date(), "yyyy-MM-dd"),
            endDate: null,
            startTime: dateFormat(new Date().setMinutes(60, 0), "HH:mm:ss"),
            endTime: dateFormat(new Date().setMinutes(120, 0), "HH:mm:ss"),
            saleLimit: 0,
            museumId: product?.museumId || selectedMuseum.id,
            isRecurring: false,
            scheduleEventRecurringPattern: {type: RECURRING_TYPE_RECURS_NOT},
            isFullDay: false,
        }

        newEvents.push(newEvent)
        _updateProductState(newEvents)
        setScheduleEvents(newEvents)
        setSelectedTab("active")
        setExpanded(newEvent.id)
    }

    const handleExistingOrdersWarningAccept = (scheduleEventId) => {
        handleEventsDelete(scheduleEventId, true)
        setExistingOrdersDialogOpen(false)
    }

    const handleTabChange = (e, tab) => setSelectedTab(tab)
    const handleExpandedChange = (id) => (event, isExpanded) => setExpanded(isExpanded ? id : false)


    return (
        <>
            {scheduleEventsModule && (
                <form noValidate
                      autoComplete="off"
                      onBlur={() => _updateProductState(null, scheduleEvents)}>
                    <TextField label={t("title")}
                               className={classes.textfield}
                               name="title"
                               variant="filled"
                               fullWidth
                               value={scheduleEventsModule?.title || ""}
                               helperText={t("scheduleEventsTitleHelperText")}
                               onChange={handleScheduleEventsModuleChange}/>
                    <TextField label={t("description")}
                               className={classes.textfield}
                               name="description"
                               variant="filled"
                               fullWidth
                               multiline
                               maxRows={3}
                               value={scheduleEventsModule?.description || ""}
                               helperText={t("scheduleEventsDescriptionHelperText")}
                               onChange={handleScheduleEventsModuleChange}/>
                </form>
            )}

            <TabContext value={selectedTab}>
                <Paper className={classes.tabPaper}>
                    <TabList onChange={handleTabChange}
                             textColor="secondary"
                             indicatorColor="secondary">
                        <Tab value="active"
                             label={`${t('active2')} (${scheduleEvents.filter(e => eventIsActive(e))?.length})`}/>
                        <Tab value="passed"
                             label={`${t('passed')} (${scheduleEvents.filter(e => !eventIsActive(e))?.length})`}/>
                        <Tab value="deleted"
                             label={`${t('deleted')} (${deletedScheduleEvents?.length})`}/>
                    </TabList>
                </Paper>

                <TabPanel value="active">
                    {scheduleEvents?.filter(e => eventIsActive(e))?.map((event, index) => (
                        <ScheduleEventAccordion key={`${event.id}-${index}`}
                                                expanded={expanded === event?.id}
                                                handleEventsChange={handleEventsChange}
                                                handleEventsDelete={event?.ticketsSold > 0  ? null : () => handleEventsDelete(event.id)}
                                                handleExpandedChange={handleExpandedChange(event?.id)}
                                                unsavedChanges={unsavedChanges}>
                            <ScheduleEventSummary event={event}/>
                            <ScheduleEventDetails event={event}
                                                  product={product}
                                                  existingOrders={ordersData?.orders}
                                                  fetchOrders={fetchOrders}
                                                  unsavedChanges={unsavedChanges}
                                                  handleEventsChange={handleEventsChange}/>
                        </ScheduleEventAccordion>
                    ))}
                </TabPanel>
                <TabPanel value="passed">
                    {scheduleEvents?.filter(e => !eventIsActive(e))?.map((event, index) => (
                        <ScheduleEventAccordion key={`${event.id}-${index}`}
                                                expanded={expanded === event?.id}
                                                handleEventsChange={handleEventsChange}
                                                handleEventsDelete={event?.ticketsSold > 0 ? null : () => handleEventsDelete(event.id)}
                                                handleExpandedChange={handleExpandedChange(event?.id)}
                                                unsavedChanges={unsavedChanges}>
                            <ScheduleEventSummary event={event}/>
                            <ScheduleEventDetails event={event}
                                                  product={product}
                                                  existingOrders={ordersData?.orders}
                                                  fetchOrders={fetchOrders}
                                                  unsavedChanges={unsavedChanges}
                                                  handleEventsChange={handleEventsChange}/>
                        </ScheduleEventAccordion>
                    ))}
                </TabPanel>
                <TabPanel value="deleted">
                    {deletedScheduleEvents?.map((event, index) => (
                        <ScheduleEventAccordion key={`${event.id}-${index}`}>
                            <ScheduleEventSummary event={event}/>
                        </ScheduleEventAccordion>
                    ))}
                </TabPanel>
            </TabContext>
            <ScheduleEventExistingOrdersDialog open={existingOrdersDialogOpen}
                                               orders={ordersData?.orders}
                                               event={selectedEvent}
                                               product={product}
                                               refetch={fetchOrders}
                                               handleClose={() => setExistingOrdersDialogOpen(false)}
                                               handleAccept={handleExistingOrdersWarningAccept}/>
            <CardActions>
                <Button variant="text"
                        color="primary"
                        startIcon={<EventIcon/>}
                        className={classes.button}
                        onClick={handleNewEvent}>
                    {t("add")}
                </Button>
            </CardActions>
        </>
    )
}

export default ScheduleEvents
