import {nokPercent, nokPrice} from "../../app/helper-functions/numberFormat"
import {dateFormat} from "../../app/helper-functions/dateFormat"
import {parse} from "date-fns"

export const createVouchersTableHeaders = (t,
                                           handleVoucherIdClick,
                                           smDown = false,
                                           xsDown = false) => [
    {field: "id", headerName: t("id"), onClick: handleVoucherIdClick},
    {field: "externalId", headerName: t("externalId"), hide: xsDown},
    {field: "date", headerName: t("date"), type: "date", flex: 2},
    {field: "createdAt", headerName: t("createdAt"), type: "datetime", flex: 2},
    {field: "status", headerName: t("status")},
    {field: "parentMuseumName", headerName: t("mainMuseum")},
    {field: "museumName", headerName: t("museum")},
    {field: "departmentName", headerName: t("department")},
    {field: "productGroup", headerName: t("productGroup"), hide: xsDown},
    {field: "paymentType", headerName: t("paymentType"), hide: xsDown},
    {field: "paymentSubtype", headerName: t("cardType"), hide: smDown},
    {field: "quantity", headerName: t("quantity")},
    {field: "vat", headerName: t("vat"), ...nokPercent, align: "center", hide: smDown},
    {field: "sum", headerName: t("sum"), ...nokPrice, align: "left", hide: smDown},
    {field: "exportedAt", headerName: t("exportedAt")},
]

export const createVouchersTableRows = (_vouchers, t, smDown = false) => {
    return _vouchers?.map(({
                               id,
                               externalId,
                               date,
                               createdAt,
                               status,
                               parentMuseumName,
                               museumName,
                               departmentName,
                               productGroup,
                               paymentType,
                               paymentSubtype,
                               quantity,
                               vat,
                               sum,
                               exportedAt,
                           }) => ({
        id: id,
        externalId: externalId,
        date: dateFormat(parse(date, "yyyy-MM-dd", new Date()), "P"),
        createdAt: dateFormat(new Date(createdAt), "Pp"),
        status: t(status),
        parentMuseumName: parentMuseumName,
        museumName: museumName,
        departmentName: departmentName,
        productGroup: productGroup,
        paymentType: t(paymentType),
        paymentSubtype: paymentSubtype,
        quantity: quantity,
        vat: vat,
        sum: sum,
        exportedAt: exportedAt ? dateFormat(new Date(exportedAt), "Pp") : "",
    }))
}
