import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography} from "@mui/material"
import GenericTable from "../generic-table/GenericTable"
import {dateFormat} from "../helper-functions/dateFormat"
import {levelToName} from "../helper-functions/logSeverity"
import MaximizeTableButton from "../components/MaximizeTableButton"

const OrderDetailsDialogLogEntries = ({loading, entries}) => {
    const t = useBaseTranslation()
    const selectableSeverityLevels = [50, 40, 30, 20, 10]

    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [minSeverityLevel, setMinSeverityLevel] = useState(20)
    const [tableMaximized, setTableMaximized] = useState(false)

    useEffect(() => {
        const createTableHeaders = () => [
            {field: "createdAt", headerName: t("timestamp"), type: "datetime", flex: 2},
            {field: "severity", headerName: t("severity")},
            {field: "className", headerName: t("className"), align: "left"},
            {field: "message", headerName: t("message"), align: "left", flex: 6},
            {field: "createdBy", headerName: t("user"), align: "left", hide: true},
        ]

        const createTableRows = (_entries) => {
            return _entries
                .filter(entry => entry.severity >= minSeverityLevel)
                .map(({
                          createdAt,
                          severity,
                          className,
                          createdBy,
                          message,
                      }) => ({
                    createdAt: dateFormat(new Date(createdAt), "dd.MM.yy pp"),
                    severity: t(levelToName[severity]),
                    className: className,
                    createdBy: createdBy,
                    message: message,
                }))
        }

        if (entries) {
            setColumns(createTableHeaders())
            setRows(createTableRows(entries))
        }
    }, [entries, minSeverityLevel, t])

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined">
               <CardHeader title={t("logEntries")}
                           titleTypographyProps={{color: "primary"}}/>
               <CardContent>
                   <Grid container direction="row">
                       <Grid item xs={6}>
                           <Grid container
                                 alignItems="center">
                               <Grid item>
                                   <Typography variant="caption"
                                               color="textSecondary">
                                       {t("lowestLevel")}:
                                   </Typography>
                               </Grid>
                               <Grid item>
                                   <TextField select
                                              size="small"
                                              variant="standard"
                                              style={{width: 160, marginLeft: 8}}
                                              value={minSeverityLevel || ""}>
                                       {selectableSeverityLevels.map((level) => (
                                           <MenuItem key={level}
                                                     value={level}
                                                     onClick={() => setMinSeverityLevel(level)}>
                                               {t(levelToName[level])}
                                           </MenuItem>
                                       ))}

                                   </TextField>
                               </Grid>
                           </Grid>
                       </Grid>
                       <Grid item xs={6}>
                           <MaximizeTableButton rows={rows}
                                                tableMaximized={tableMaximized}
                                                setTableMaximized={setTableMaximized}/>
                       </Grid>
                   </Grid>
                   {rows?.length
                    ? <GenericTable columns={columns}
                                    rows={tableMaximized ? rows : rows.slice(0, 5)}
                                    size="small"
                                    hideEmptyRows={false}/>
                    : <Typography variant="body1"><br/> Ingen oppføringer på valgt nivå eller over. </Typography>
                   }
               </CardContent>
           </Card>
}

export default OrderDetailsDialogLogEntries