import React, {useCallback, useEffect, useState} from "react"
import {Card, CardActions, CardContent, CardHeader, Grid, useTheme} from "@mui/material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {useAuthsState} from "../../../app/auths/authsContext"
import VismaVoucherSettingsGrid from "./VismaVoucherSettingsGrid"
import SaveButtonWithProgressAndLabel from "../../../app/components/SaveButtonWithProgressAndLabel"
import VismaVoucherSettingsSelector from "./VismaVoucherSettingsSelector"
import {setUpdatedBy} from "../../../app/helper-functions/setUpdatedBy"
import VismaVoucherFieldsSettingsHelperText from "./VismaVoucherFieldsHelperText"
import VismaVoucherSettingsAlerts from "./VismaVoucherSettingsAlerts"

const VismaVoucherSettings = () => {
    const t = useBaseTranslation()
    const {umbrellaId} = useMuseumSelectorState()
    const snackbar = useCustomSnackbar()
    const {userName} = useAuthsState()
    const {palette} = useTheme()

    const [vismaVoucherSettings, setVismaVoucherSettings] = useState()
    const [selectedMuseum, setSelectedMuseum] = useState()
    const [selectedProductGroup, setSelectedProductGroup] = useState()
    const [selectedDepartment, setSelectedDepartment] = useState()
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [
        {
            data: vismaVoucherSettingsData,
            loading: vismaVoucherSettingsLoading,
        }, getVismaVoucherSettings,
    ] = useAxiosBpn({
        url: `voucher_settings/visma`,
        params: {
            umbrellaId: umbrellaId,
            museumId: selectedMuseum?.id,
            productGroupId: selectedProductGroup?.id,
            departmentId: selectedDepartment?.id,
        },
    })

    const [
        {
            data: vismaVoucherSettingsPutData,
            loading: vismaVoucherSettingsPutLoading,
            error: vismaVoucherSettingsPutError,
        }, putVoucherSettings,
    ] = useAxiosBpn({
        url: `voucher_settings/visma`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        getVismaVoucherSettings().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getVismaVoucherSettings().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getVismaVoucherSettings, umbrellaId])

    useEffect(() => {
        setVismaVoucherSettings(null)
        setSelectedMuseum(null)
        setSelectedProductGroup(null)
        setSelectedDepartment(null)
    }, [umbrellaId])

    useEffect(() => {
        setSelectedDepartment(null)
    }, [selectedMuseum])

    const updateState = useCallback((data, umbrellaId, productGroupId, museumId, departmentId) => {
        let {umbrella, museum, productGroup, department, merged} = data

        // To make sure correct ID is set on empty objects
        if (!umbrella) umbrella = {umbrellaId: umbrellaId}
        if (!museum && selectedMuseum) museum = {museumId: museumId}
        if (!productGroup && selectedProductGroup) productGroup = {productGroupId: productGroupId}
        if (!department && selectedDepartment) department = {departmentId: departmentId}

        setVismaVoucherSettings({
            umbrella: setUpdatedBy(umbrella, userName),
            museum: setUpdatedBy(museum, userName),
            productGroup: setUpdatedBy(productGroup, userName),
            department: setUpdatedBy(department, userName),
            merged: merged,
        })
    }, [selectedDepartment, selectedMuseum, selectedProductGroup, userName])

    useEffect(() => {
        if (vismaVoucherSettingsData) {
            updateState(vismaVoucherSettingsData,
                umbrellaId,
                selectedProductGroup?.id,
                selectedMuseum?.id,
                selectedDepartment?.id)
        }
    }, [updateState, selectedMuseum, selectedProductGroup, umbrellaId, vismaVoucherSettingsData, selectedDepartment])

    useEffect(() => {
            if (vismaVoucherSettingsPutData) {
                snackbar.showSuccess(t("voucherSettingsUpdated"))
                updateState(vismaVoucherSettingsPutData,
                    umbrellaId,
                    selectedProductGroup?.id,
                    selectedMuseum?.id,
                    selectedDepartment?.id)
                setUnsavedChanges(false)
            }
        },
        [
            updateState,
            snackbar,
            vismaVoucherSettingsPutData,
            t,
            umbrellaId,
            selectedProductGroup,
            selectedMuseum,
            selectedDepartment,
        ])

    useEffect(() => {
        if (vismaVoucherSettingsPutError) snackbar.showError(t(vismaVoucherSettingsPutError.message))
    }, [vismaVoucherSettingsPutError, snackbar, t])

    const handleSave = () => {
        putVoucherSettings({data: vismaVoucherSettings})
    }

    return (
        <Card square
              variant="outlined">
            <CardHeader title={t("fields")}
                        subheader={t("vismaFieldsSubheader")}
                        titleTypographyProps={{color: "primary", variant: "h6"}}
                        subheaderTypographyProps={{variant: "subtitle2"}}
                        action={<VismaVoucherFieldsSettingsHelperText highlightColor={palette?.primary?.light}/>}/>
            <CardContent>
                <Grid container
                      direction="column"
                      alignItems="center"
                      spacing={1}>
                    <VismaVoucherSettingsAlerts vismaVoucherSettings={vismaVoucherSettings}/>
                    <VismaVoucherSettingsSelector selectedMuseum={selectedMuseum}
                                                  setSelectedMuseum={setSelectedMuseum}
                                                  selectedProductGroup={selectedProductGroup}
                                                  setSelectedProductGroup={setSelectedProductGroup}
                                                  selectedDepartment={selectedDepartment}
                                                  setSelectedDepartment={setSelectedDepartment}
                                                  loading={vismaVoucherSettingsLoading}/>
                    <VismaVoucherSettingsGrid vismaVoucherSettings={vismaVoucherSettings}
                                              setVismaVoucherSettings={setVismaVoucherSettings}
                                              selectedMuseum={selectedMuseum}
                                              selectedProductGroup={selectedProductGroup}
                                              selectedDepartment={selectedDepartment}
                                              unsavedChanges={unsavedChanges}
                                              setUnsavedChanges={setUnsavedChanges}/>

                </Grid>
            </CardContent>
            <CardActions>
                <SaveButtonWithProgressAndLabel unsavedChanges={unsavedChanges}
                                                handleSave={handleSave}
                                                loading={vismaVoucherSettingsPutLoading}/>
            </CardActions>
        </Card>
    )
}

export default VismaVoucherSettings