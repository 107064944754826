import {FormControlLabel, Grid, Switch} from "@mui/material";
import TextField from "@mui/material/TextField"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import _ from "lodash"
import MuiDateTimePicker from "../../components/MuiDateTimePicker"
import {useProductEditorTranslation} from "../productEditorTranslationContext"


const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}))

const ProductValidity = ({product, unsavedChanges, setUnsavedChanges, updateProductState}) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()
    const [data, setData] = useState({})
    const [dynamicValidity, setDynamicValidity] = useState(false)
    const [includeValidatedTicketsInVisitorStats, setIncludeValidatedTicketsInVisitorStats] = useState(false)

    useEffect(() => {
        setData(product)
        if (product) {
            const {daysValid, includeValidatedTicketsInVisitorStats} = product
            setDynamicValidity(!!daysValid)
            setIncludeValidatedTicketsInVisitorStats(includeValidatedTicketsInVisitorStats)
        }
    }, [product])

    const handleChange = (e) => {
        e.persist()
        setData(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleDateChange = (date, name) => {
        setData(prev => ({...prev, [name]: date ? date.getTime() : null}))
        handleSubmit()
    }

    const handleSubmit = () => {
        if (!_.isEqual(data, product)) {
            if (dynamicValidity) {
                data.validFrom = null
                data.validTo = null
            } else {
                data.daysValid = null
            }
            updateProductState(data)

            if (!unsavedChanges) setUnsavedChanges(true)
        }
    }

    const handleIncludeValidatedTicketsInVisitorStatsChange = () => {
        updateProductState({...data, includeValidatedTicketsInVisitorStats: !includeValidatedTicketsInVisitorStats})
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    return (
        <form noValidate
              autoComplete="off"
              onBlur={handleSubmit}>
            <FormControlLabel
                control={
                    <Switch
                        checked={includeValidatedTicketsInVisitorStats}
                        onChange={handleIncludeValidatedTicketsInVisitorStatsChange}
                        name="includeValidatedTicketsInVisitorStatsCheckbox"
                        color="primary"
                    />
                }
                label={t("includeValidatedTicketsInVisitorStats")}
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={dynamicValidity}
                        onChange={() => setDynamicValidity(!dynamicValidity)}
                        name="dynamicValidityCheckbox"
                        color="primary"
                    />
                }
                label={t("dynamicValidity")}
            />
            {dynamicValidity ?
                <TextField label={t("daysValidAfterPurchase")}
                           className={classes.textfield}
                           name='daysValid'
                           variant="filled"
                           type="number"
                           fullWidth
                           value={data?.daysValid || ""}
                           onChange={handleChange}/>
                :
                <Grid container
                      spacing={2}
                      justifyContent="space-between">
                    <Grid item
                          xs={12}
                          sm={6}>
                        <MuiDateTimePicker selected={data?.validFrom || null}
                                           label={t("validFrom")}
                                           isClearable={true}
                                           name='validFrom'
                                           maxDate={data?.validTo || null}
                                           value={data?.validFrom || null}
                                           className={classes.textfield}
                                           onChange={handleDateChange}/>
                    </Grid>
                    <Grid item
                          xs={12}
                          sm={6}>
                        <MuiDateTimePicker selected={data?.validTo || null}
                                           label={t("validTo")}
                                           name='validTo'
                                           isClearable={true}
                                           minDate={data?.validFrom || null}
                                           value={data?.validTo || null}
                                           className={classes.textfield}
                                           onChange={handleDateChange}/>
                    </Grid>
                </Grid>}
        </form>
    )
}

export default ProductValidity