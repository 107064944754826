import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {CardHeader, CardContent, Grid, Card} from "@mui/material"
import {useAxiosBpn} from "../axios"
import useCustomSnackbar from "../components/useCustomSnackbar"
import {GridLabel, GridValue} from "./gridComponents"
import {formatNOK} from "../helper-functions/numberFormat"
import OrderLineDetail from "./OrderDetailsDialogOrderLineDetails"

const OrderDetailsDialogOrderLinesDetails = ({loading, order, orderLines}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()

    const [gridRows, setGridRows] = useState([])
    const [orderSums, setOrderSums] = useState()

    const [{data: orderSumsData, loading: orderSumsLoading, error: orderSumsError}] = useAxiosBpn({
        url: "orders/sums",
        params: {
            orderId: order?.id,
            allOrderStatuses: true,
            test: order?.test
        }
    })

    useEffect(() => {
        if (orderSumsData) {
            setOrderSums(orderSumsData.orderSums)
        }
    }, [orderSumsData])

    useEffect(() => {
        if (orderSumsError) {
            snackbar.showError(orderSumsError.message)
        }
    }, [orderSumsError, snackbar])

    useEffect(() => {
        if (orderSums) {
            setGridRows([
                {label: t("unitsSold"), value: orderSums.unitsSold},
                {label: t("sumExcludingVat"), value: formatNOK(orderSums.revenueExcludingVat, 2)},
                {label: t("sumIncludingVat"), value: formatNOK(orderSums.revenueIncludingVat, 2)}
            ])
        }
    }, [orderSums, t])

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined">
               <CardHeader title={t("orderLines")}
                           titleTypographyProps={{color: "primary"}}
               />
               <CardContent>
                   {orderSumsLoading
                    ? <ProgressIndicator/>
                    : <Grid container
                            direction="column">
                        {gridRows?.map(({label, value}, index) =>
                            value &&
                            <Grid container
                                  key={index}
                                  alignItems="center">
                                <GridLabel>{label && `${label}:`}</GridLabel>
                                <GridValue>{value}</GridValue>
                            </Grid>
                        )}
                        {orderLines?.map((line) => (
                            <OrderLineDetail key={line.id}
                                             loading={loading}
                                             orderLine={line}/>
                        ))}
                    </Grid>
                   }
               </CardContent>
           </Card>

}

export default OrderDetailsDialogOrderLinesDetails