import React, {useEffect, useState} from "react"
import CardHeader from "@mui/material/CardHeader"
import {Card, CardContent, MenuItem, TextField, Typography} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {useAxiosBpn} from "../axios"

const CreateOrderDialogDiscountCode = ({discountCode, handleDiscountCodeChange, productIds, style}) => {
    const t = useBaseTranslation()

    const [discountCodes, setDiscountCodes] = useState()

    const [{data: discountCodesData}] = useAxiosBpn({
        url: "discount_codes",
        params: {productIds: productIds?.join()},
    })

    useEffect(() => setDiscountCodes(discountCodesData?.discountCodes), [discountCodesData])

    return (
        <Card square
              style={style}>
            <CardHeader title={t("discountCode")}
                        titleTypographyProps={{color: "primary"}}/>
            <CardContent>
                {discountCodes?.length
                 ? <TextField value={discountCode || ""}
                              select
                              name="discountCode"
                              label={t("discountCode")}
                              variant="outlined"
                              fullWidth
                              margin="dense">
                     <MenuItem value={""} onClick={() => handleDiscountCodeChange(undefined)}>{t("none")}</MenuItem>
                     {discountCodes?.map((discountCode) => (
                         <MenuItem key={discountCode.id}
                                   value={discountCode}
                                   onClick={() => handleDiscountCodeChange(discountCode)}>
                             {`${discountCode.code} (${discountCode.discount}%)`}
                         </MenuItem>
                     ))}
                 </TextField>
                 : productIds?.length === 1
                   ? <Typography color="textSecondary">{t("noAvailableDiscountCodesForThisProduct")}</Typography>
                   : <Typography color="textSecondary">{t("noAvailableDiscountCodesForTheseProducts")}</Typography>
                }

            </CardContent>
        </Card>
    )
}

export default CreateOrderDialogDiscountCode