import React, {useEffect, useState} from "react"
import {CardContent, Chip, Grid, Typography} from "@mui/material"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import DashboardCard from "../../app/components/DashboardCard"

const VouchersCountCard = ({count, loading, testOrders}) => {
    const t = useBaseTranslation()

    const [_orderLinesWithNoVouchers, setOrderLinesWithNoVouchers] = useState()
    const [_prStatus, setPrStatus] = useState({})

    useEffect(() => {
        if (count) {
            const {orderLinesWithNoVouchers, prStatus} = count
            setOrderLinesWithNoVouchers(orderLinesWithNoVouchers)
            setPrStatus(prStatus)
        }
    }, [count])

    const InfoRow = ({label, text, subText}) => (
        <Grid container
              direction="row"
              wrap="nowrap"
              spacing={2}
              alignItems="baseline"
              justifyContent="space-between">
            <Grid item>
                <Typography variant="subtitle2"
                            noWrap>{label}:</Typography>
            </Grid>
            <Grid container
                  justifyContent="flex-end"
                  spacing={1}>
                <Grid item>
                    <Typography variant="body2">{text}</Typography>
                </Grid>
                {subText && (
                    <Grid item>
                        <Typography variant="body2"
                                    color="textSecondary">{subText}</Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )

    const Title = () => (
        <Grid container
              spacing={1}>
            <Grid item>
                {t("voucherNumbers")}
            </Grid>
            <Grid item>
                <Chip label="Visma"
                      color="primary"
                      size="small"/>
            </Grid>
        </Grid>
    )

    const SubHeader = () => (
        <Typography variant="subtitle1"
                    color="textSecondary">
            {t("forSelectedMuseumOrUmbrella")}
        </Typography>
    )

    return (
        <DashboardCard title={<Title/>}
                       testOrders={testOrders}
                       hideTestOrdersLabel
                       loading={loading}
                       linearProgress={false}
                       subheader={<SubHeader/>}>
            <CardContent>
                <>
                    {Object.entries(_prStatus).map(([key, value]) => (
                        <InfoRow key={key}
                                 label={t(key)}
                                 text={value}/>
                    ))}
                    <InfoRow label={t("orderLinesWithNoVouchers")}
                             text={_orderLinesWithNoVouchers}/>
                </>
            </CardContent>
        </DashboardCard>
    )
}

export default VouchersCountCard