import React, {useEffect, useState} from "react"
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const randomColor = require("randomcolor")

const StackedBarChart = ({data, groups, xAxisLabel, yAxisLabel}) => {
    const [colors, setColors] = useState([])

    useEffect(() => {
        setColors(randomColor({
            count: groups?.length,
            luminosity: "bright",
        }))
    }, [groups])

    return (
        <ResponsiveContainer width="100%"
                             height={500}>
            <AreaChart data={data}
                       margin={{
                           top: 10,
                           right: 30,
                           left: 30,
                           bottom: 20,
                       }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="xAxisKey"
                       label={{value: xAxisLabel, offset: -10, position: "insideBottom"}}/>
                <YAxis label={{value: yAxisLabel, angle: -90, position: "insideLeft"}}/>
                <Tooltip wrapperStyle={{zIndex: 1500}}/>
                <Legend verticalAlign="top"
                        height={36}/>
                {groups?.map((group, idx) => (
                    <Area key={idx}
                          type="monotone"
                          dataKey={group}
                          stackId="1"
                          stroke={colors[idx]}
                          fill={colors[idx]}/>
                ))}
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default StackedBarChart
