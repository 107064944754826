import React from "react"
import {Typography} from "@mui/material"

const UserPortalDesignSettingsHelperText = () => {
    return (
        <>
            <Typography variant="body2">
                Benytt dette kortet til å personalisere nettsiden kundene handler billetter gjennom.
                <br/><br/>
                Ingen felter er påkrevd, men anbefales allikevel å fylles ut slik at opplevelsen for kundene blir best mulig og at brandingen til portalen går igjen med ditt museums egen branding.
            </Typography>
        </>
    )
}

export default UserPortalDesignSettingsHelperText
