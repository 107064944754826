import React, { useEffect, useMemo, useState } from "react";
import {Avatar, Card, CardActions, Grid, IconButton, Tooltip, Typography} from "@mui/material"
import {useMuseumSelectorState} from "../../museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../axios"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import RelatedProductsSelectorDialog from "./RelatedProductsSelectorDialog"
import _ from "lodash"
import TranslatableTextField from "../TranslatableTextField";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import ChipWithTooltip from "../../components/ChipWithTooltip"
import ProductTypeIcon from "../../components/ProductTypeIcon"
import {getSubstring} from "../../helper-functions/stringUtility"
import {deepOrange} from "@mui/material/colors"
import DeleteIcon from "@mui/icons-material/Delete"
import arrayMove from "array-move"

const ProductCard = ({id, name, type, typeTooltip, museumName, handleDelete}) => (
    <Card variant="outlined"
          style={{paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 8, marginBottom: 4}}>
        <Grid container alignItems="center" spacing={1} justifyContent="space-between">
            <Grid item>
                <Avatar style={{height: 32, width: 32, background: deepOrange[300]}}>
                    <Tooltip title={typeTooltip}>
                        <div><ProductTypeIcon type={type}/></div>
                    </Tooltip>
                </Avatar>
            </Grid>
            <Grid item xs>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography>{getSubstring(name, 32)}</Typography>
                    </Grid>
                    <Grid item><ChipWithTooltip maxLength={32} label={museumName} size="small" color="default"/></Grid>
                </Grid>
            </Grid>
            <Grid item><IconButton onClick={() => handleDelete(id)} size="large"><DeleteIcon/></IconButton></Grid>
        </Grid>
    </Card>
)

const RelatedProducts = ({product, updateProductState, unsavedChanges, setUnsavedChanges}) => {
    const t = useProductEditorTranslation()
    const {umbrellaId} = useMuseumSelectorState()

    const [_product, setProduct] = useState({})
    const [relatedProductsSelectorDialogOpen, setRelatedProductsSelectorDialogOpen] = useState(false)
    const [availableProducts, setAvailableProducts] = useState([])

    const [{data: availableProductsData, loading: availableProductsLoading}, getAvailableProducts] = useAxiosBpn({
        url: "products",
        params: {
            status: "draft,published",
            umbrellaMuseumId: umbrellaId,
            includeUmbrellaChildren: true,
        },
    })

    useEffect(() => getAvailableProducts().catch(() => {
    }), [getAvailableProducts])

    useEffect(() => setAvailableProducts(availableProductsData?.products?.filter(({id}) => id !== product?.id)),
        [availableProductsData, product])

    useEffect(() => setProduct(product), [product])


    const getSelectedProducts = () => {
        if (availableProducts && _product?.relatedProductsFullInfo) {
            const availableWithSortOrder = availableProducts.map(aProduct => {
                const relatedProduct = _product.relatedProductsFullInfo.find(related => related.id === aProduct.id)
                if (relatedProduct) {
                    return {...aProduct, sortOrder: relatedProduct.sortOrder}
                }

                return null
            }).filter(product => !!product)


            return _.sortBy(availableWithSortOrder, ['sortOrder'])
        }

        return []
    }

    const handleSelectedProductsChange = (relatedProducts) => {
        updateProductState({...product, relatedProductsFullInfo: relatedProducts, relatedProducts: relatedProducts})

        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const onTranslatableFieldChanged = (propertyKey, updatedValue) => {
        setUnsavedChanges(true)
        updateProductState({..._product, [propertyKey]: updatedValue})
    }

    const handleDelete = (id) => handleSelectedProductsChange(getSelectedProducts().filter((p => p.id !== id)))

    const onDragEnd = ({source, destination}) => {
        const updateSortOrder = _array => {
            for (let i = 0; i < _array?.length; i++) {
                _array[i].sortOrder = i
            }
            return _array
        }

        // dropped outside the list
        if (!destination) return

        const {droppableId: sourceId, index: sourceIndex} = source
        const {droppableId: destinationId, index: destinationIndex} = destination

        if (destinationId === sourceId && destinationIndex === sourceIndex) {
            return
        }

        const newSelectedProducts = updateSortOrder(arrayMove(getSelectedProducts(), sourceIndex, destinationIndex))

        handleSelectedProductsChange(newSelectedProducts)
    }


    return <>
        <TranslatableTextField label={t("relatedProductsHeading")}
                               required={false}
                               name="relatedProductsHeading"
                               value={_product?.relatedProductsHeading || ""}
                               onChange={(updatedValue) => onTranslatableFieldChanged("relatedProductsHeading", updatedValue)}/>

        {getSelectedProducts()?.length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppableProducts">
                    {(provided) => (
                        <div ref={provided.innerRef}
                             {...provided.droppableProps}>
                            {getSelectedProducts()?.length
                                ? getSelectedProducts()?.map(({
                                                             id,
                                                             name,
                                                             type,
                                                             museumName,
                                                         }, index) => (
                                    <Draggable key={id}
                                               draggableId={`${id}`}
                                               index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}>
                                                <ProductCard key={id}
                                                             id={id}
                                                             name={name}
                                                             type={type}
                                                             typeTooltip={t(type)}
                                                             handleDelete={handleDelete}
                                                             museumName={museumName}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                                : "noProductsText"}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )}

        <RelatedProductsSelectorDialog loading={availableProductsLoading}
                                       open={relatedProductsSelectorDialogOpen}
                                       available={availableProducts}
                                       selected={getSelectedProducts()}
                                       handleClose={() => setRelatedProductsSelectorDialogOpen(false)}
                                       handleChange={handleSelectedProductsChange}/>

        <CardActions>
            <Button onClick={() => setRelatedProductsSelectorDialogOpen(true)}
                    variant="text"
                    color="primary"
                    style={{marginTop: 16, marginLeft: "auto"}}
                    disabled={availableProducts?.length === 0}
                    startIcon={<AddIcon/>}>
                {`${t("add")} / ${_.lowerFirst(t("change"))}`}
            </Button>
        </CardActions>
    </>
}

export default RelatedProducts
