import {Avatar, Card, CardHeader, Chip, Grid, Link, Tooltip, Typography} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import makeStyles from '@mui/styles/makeStyles';
import {distanceToNow} from "../../../app/helper-functions/dateFormat"
import React from "react"
import {useHistory} from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import ArchiveIcon from "@mui/icons-material/Archive"
import UnarchiveIcon from "@mui/icons-material/Unarchive"
import {ENTRANCE_BASE_URL} from "../../../app/Routes"
import ImageCropper from "../../../app/components/ImageCropper"
import MuseumIcon from "@mui/icons-material/Museum"
import {ShoppingCart} from "@mui/icons-material"
import * as PropTypes from "prop-types"
import {useEntranceTranslation} from "../entranceTranslationContext"
import {startOfDay} from "date-fns"
import {ROLES_VALUES} from "../../../app/menu/MuseumSelector"

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.dark,
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    title: {
        cursor: "pointer",
        [theme.breakpoints.up("md")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "1.1em",
        },
    },
    subTitle: {
        [theme.breakpoints.up("md")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "1.0em",
        },
    },
    subSubTitle: {
        [theme.breakpoints.up("md")]: {
            fontSize: "1.0em",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "0.8em",
        },
    },
    entranceCard: {
        marginTop: "8px",
        backgroundColor: ({isEntrenceExpired}) => {
            if (isEntrenceExpired) return "#80808069"
            return ""
        },
        cursor: "pointer",
        border: 0,
    },
}))

const isEntranceExpired = (entrance) => {
    const now = new Date()
    const nowInMs = startOfDay(now).getTime()
    return entrance?.availableTo ? entrance?.availableTo <= nowInMs : false
}

export const EntranceCard = ({entrance, roleValue, onArchiveButtonClicked, onDeleteButtonClicked}) => {
    const classes = useStyles({isEntranceExpired: isEntranceExpired(entrance)})
    const t = useEntranceTranslation()
    const history = useHistory()

    const subSubTitleProps = {
        variant: "subtitle1",
        className: classes.subSubTitle,
        color: "textSecondary",
    }

    const getAvatar = (product) => {
        return product?.imageUrl ? <Avatar variant="square"
                                           className={classes.avatar}>
                <ImageCropper height="100%"
                              src={product?.imageUrl}/>
            </Avatar> :
            <></>
    }

    const getTitle = ({
                          name,
                          status,
                          pointOfSaleSystemEnabled,
                          availableFrom,
                          availableTo,
                      }) => {
        return (
            <Grid container
                  spacing={1}
                  alignItems="center">
                <Grid item>
                    <Tooltip title={t("linkToProductDetailsTooltip")}>
                        <Typography variant="h6"
                                    className={classes.title}>
                            {name}{isEntranceExpired(entrance) && "(Utgått)"}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2"
                                className={classes.subTitle}
                                color="textSecondary">
                        {t(status)}
                    </Typography>
                </Grid>
                {availableTo && availableFrom && (
                    <Grid item>
                        <Tooltip title={t("eventAvailablePeriod")}>
                            <Typography>{`${new Date(availableFrom).toLocaleDateString("no-NB")} -
                   ${new Date(availableTo).toLocaleDateString("no-NB")}`}</Typography>
                        </Tooltip>
                    </Grid>
                )}
                {pointOfSaleSystemEnabled && (
                    <Grid item>
                        <Chip label="KDR"
                              size="small"
                              style={{
                                  backgroundColor: "green",
                                  color: "white",
                              }}/>
                    </Grid>
                )}
            </Grid>
        )
    }

    const getSubheader = ({museumName, updatedAt, newestSaleAt}) => {
        return (
            <Grid container
                  spacing={1}
                  alignItems="baseline">
                <Grid item>
                    <Chip label={museumName}
                          icon={<MuseumIcon/>}
                          size="small"/>
                </Grid>
                <Grid item>
                    <Typography {...subSubTitleProps}>
                        {`${t("updatedFor")} ${distanceToNow(new Date(updatedAt))}`}
                    </Typography>
                </Grid>
                {newestSaleAt && (
                    <Grid item>
                        <Typography {...subSubTitleProps}>
                            {`- ${t("latestSaleFor")} ${distanceToNow(new Date(newestSaleAt))}`}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        )
    }

    const getAction = (product) => {
        const handleArchive = (e) => {
            e.stopPropagation()
            onArchiveButtonClicked(product, product.status !== "archived")
        }

        const handleDelete = (e) => {
            e.stopPropagation()
            onDeleteButtonClicked(product.productId)
        }

        return <>
            {product.status === "published" && (
                <Tooltip title={t("linkToProductPortalTooltip")}>
                    <Link href={`${window._env_.REACT_APP_BPN_PORTAL}museum/${product.museumId}/produkt/${product.productId}`}
                          target="portal">
                        <IconButton size="large">
                            <ShoppingCart/>
                        </IconButton>
                    </Link>
                </Tooltip>
            )}
            {roleValue >= ROLES_VALUES.EDITOR && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        history.push(`${ENTRANCE_BASE_URL}/${product.productId}/edit`)
                    }}
                    size="large">
                    <EditIcon/>
                </IconButton>
            )}

            {roleValue >= ROLES_VALUES.EDITOR && (
                !!product?.newestSaleAt
                    ? <Tooltip title={product.status !== "archived" ? t("archive") : t("restore")}>
                        <IconButton onClick={handleArchive} size="large">
                            {product.status !== "archived" ? <ArchiveIcon/> : <UnarchiveIcon/>}
                        </IconButton>
                    </Tooltip>
                    : <IconButton onClick={handleDelete} size="large"> <DeleteIcon/> </IconButton>
            )}
        </>;
    }

    return (
        <Card square
              variant="outlined"
              className={classes.entranceCard}
              onClick={() => history.push(`${ENTRANCE_BASE_URL}/${entrance.productId}/details`)}>
            <CardHeader title={getTitle(entrance)}
                        avatar={getAvatar(entrance)}
                        action={getAction(entrance)}
                        subheader={getSubheader(entrance)}/>
        </Card>)
}

EntranceCard.propTypes = {
    onDeleteButtonClicked: PropTypes.func,
    product: PropTypes.any,
    onArchiveButtonClicked: PropTypes.func,
}


