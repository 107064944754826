import {dateFormat, getFormatStringBasedOnFequency} from "../helper-functions/dateFormat"
import {parse} from "date-fns"
import _ from "lodash"
import {nokNumber} from "../helper-functions/numberFormat"

const _dateFormat = (c, t, frequency) => {
    if (c === "naN") return t("noDate")
    return dateFormat(parse(c, "yyyyMMddHH:mm", new Date()), getFormatStringBasedOnFequency(frequency))
}

export const createVisitorsTableHeaders = (columns, t, frequency) => {
    let _columns = [...columns]

    // Remove first column. First column is always variable the data is grouped by
    const group = _columns?.splice(0, 1)[0]

    let headers = [
        {
            field: group,
            headerName: t(group),
            align: "left",
            flex: 2,
        },
    ]

    headers.push(..._columns.map(c => ({
        field: _dateFormat(c, t, frequency),
        headerName: _dateFormat(c, t, frequency),
        flex: 1,
    })))

    return headers
}

export const createVisitorsPivotTableHeaders = (columns, t, groupsCount = 2) => {
    let _columns = [...columns]
    let headers = []

    const groups = _columns?.splice(0, groupsCount)

    headers.push(...groups.map(group => ({
        field: group[0],
        headerName: t(group[0]),
        align: "left",
        flex: 2,
    })))

    headers.push(..._columns?.map(column => ({
        field: column[1],
        headerName: column[1],
        flex: 1,
    })))

    return headers
}

export const createVisitorsTableRows = (_data, _columns) => _data.map((row) => {
    return Object.assign({}, ...row.map((c, idx) => {
        if (_columns[idx]) return {[_columns[idx].field]: c}
        return null
    }))
})

export const createCollapsibleVisitorsPivotTableHeaders = (columns,
                                                           t,
                                                           firstHeader = 'price_category_name',
                                                           groupsCount = 2) => {
    let _columns = [...columns]
    let headers = [{
        field: firstHeader,
        headerName: t(firstHeader),
        align: "left",
        flex: 2,
    }]

    // Remove group columns from _columns variable to it's own const.
    _columns.splice(0, groupsCount)

    headers.push(..._columns?.map(column => ({
        field: column[1],
        ...nokNumber,
        headerName: column[1],
        flex: 1,
    })))

    return headers
}

const mapToRowObject = (row, _columns) => {
    return Object.assign({}, ...row.map((c, idx) => {
        if (_columns[idx]) return {[_columns[idx].field]: c}
        return null
    }))
}

export const createCollapsibleVisitorsTableRows = (data, columns, t) => {
    const _columns = createCollapsibleVisitorsPivotTableHeaders(columns, t)
    const collapsedTableColumns = createCollapsibleVisitorsPivotTableHeaders(columns, t, 'product_name')

    // Remove last row (sum)
    let totalSumRow = data.pop()

    // Remove second column (productName)
    totalSumRow.splice(1, 1)

    // Need a set of unique values to group rows
    let groupNames = [...new Set(data.map(r => r[0]))]

    const groupedData = Object.assign({},
        ...groupNames.map(groupName => (
            {
                [groupName]: data
                    .filter(d => d[0] === groupName)
                    .map(row => row.splice(1, row.length - 1)),
            }),
        ),
    )

    let groupSums = []

    // Merge and sum all rows in each group
    for (const [_groupName, group] of Object.entries(groupedData)) {

        // Remove non-numbers
        const filteredGroup = group.map(row => {
            const numbers = []
            for (const r of row) if (!isNaN(r)) numbers.push(r)
            return numbers
        })

        const merged = _.reduce(filteredGroup, (prev, curr) => _.mergeWith(prev, curr, _.add))
        groupSums.push([_groupName, ...merged])
    }

    // Put back bottom row with totals
    groupSums.push(totalSumRow)

    // Map to row objects
    return groupSums.map((row) => {
        let rowObject = mapToRowObject(row, _columns)
        let rowName = row[0]

        if (rowName !== "Sum") rowObject = {
            ...rowObject,
            collapsibles: [
                {
                    columns: collapsedTableColumns,
                    rows: groupedData[rowName]?.map(r => mapToRowObject(r, collapsedTableColumns)),
                },
            ],
        }
        return rowObject
    })
}

const extractChartDataset = (dates, groups) => dates.map(d => ({
    xAxisKey: d,
    ...groups.reduce((current, item) => {
        current[item] = 0
        return current
    }, {}),
}))

export const createVisitorStatsChartData = (rows, dates, groups, groupBy) => {
    let dataset = extractChartDataset(dates, groups)

    for (const r of rows) {
        let rowEntries = Object.entries(r)
        const index = rowEntries.findIndex(([key]) => key === _.camelCase(groupBy))
        const groupName = rowEntries?.splice(index, 1)[0][1]

        for (let [key, value] of rowEntries) {
            const _index = dataset.findIndex(d => d.xAxisKey === key)

            if (dataset[_index]) dataset[_index][groupName] = value
        }
    }

    return dataset
}

export const createVisitorStatsExcelDataset = (headers, rows) => {
    let columns = headers.map(({headerName, flex}) => ({title: headerName, width: {wpx: flex * 100 || 100}}))

    let data = rows.map((row) => {
        return Object.values(row).map(v => ({value: v || ""}))
    })
    return [{columns: columns, data: data}]
}
