import React, {useEffect, useState} from "react"
import {Grid, Link, Typography} from "@mui/material"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import DashboardCard from "../components/DashboardCard"

const ProductGeneralDetailsCard = ({product, loading, testOrders}) => {
    const {
        id,
        approvedAt,
        approvedBy,
        createdAt,
        createdBy,
        museumId,
        museumName,
        status,
        type,
        updatedAt,
        updatedBy,
        discountCodes,
        isGiftProduct,
        newsletterOptionEnabled,
        rubicIntegrationEnabled
    } = product
    const t = useBaseTranslation()
    const [portalLink, setPortalLink] = useState()

    useEffect(() => setPortalLink(`${window._env_.REACT_APP_BPN_PORTAL}museum/${museumId}/produkt/${id}`), [id, museumId])

    const items = [
        {
            label: t("museumId"),
            text: museumName,
            subText: `(${museumId})`,
        },
        {
            label: t("created"),
            text: new Date(createdAt).toLocaleString("nb-NO"),
            subText: `(${t("by").toLowerCase()} ${createdBy || "<" + t("user").toLowerCase() + ">"})`,
        },
        {
            label: t("updated"),
            text: updatedAt ? new Date(updatedAt).toLocaleString("nb-NO") : t("never"),
            subText: `(${t("by").toLowerCase()} ${updatedBy || "<" + t("user").toLowerCase() + ">"})`,
        },
        {
            label: t("approved"),
            text: approvedAt ? new Date(approvedAt).toLocaleString("nb-NO") : t("never"),
            subText: approvedBy ? `(${t("by").toLowerCase()} ${updatedBy})` : null,
        },
        {
            label: t("status"),
            text: t(status),
        },
        {
            label: t("type"),
            text: t(type),
        },
        {
            label: t("urlToSalesPortal"),
            text: status === "published" && portalLink
                  ? <Link href={portalLink}
                          target="portal">
                      {portalLink}
                  </Link>
                  : `${t("productMustBePublishedFirst")}!`,
        },
        {
            label: t('discountCodes'),
            text: discountCodes.length ? t('yes') : t('no'),
            subText: discountCodes.length ? `(${discountCodes.length})` : null
        },
        {
            label: t('giftProduct'),
            text: isGiftProduct ? t('yes') : t('no')
        },
        {
            label: t('newsletter'),
            text: newsletterOptionEnabled ? t('yes') : t('no')
        },
        {
            label: t('rubicIntegration'),
            text: rubicIntegrationEnabled ? t('yes') : t('no')
        },
    ]

    return (
        <DashboardCard testOrders={testOrders}>
            {loading
             ? <ProgressIndicator/>
             : items.map(({label, text, subText}, index) => (
                    <Grid key={index}
                          container
                          direction="row"
                          wrap="nowrap"
                          spacing={2}
                          alignItems="baseline"
                          justifyContent="space-between">
                        <Grid item>
                            <Typography variant="subtitle2"
                                        noWrap>{label}:</Typography>
                        </Grid>
                        <Grid container
                              justifyContent="flex-end"
                              spacing={1}>
                            <Grid item>
                                <Typography variant="body2">{text}</Typography>
                            </Grid>
                            {subText && (
                                <Grid item>
                                    <Typography variant="body2"
                                                color="textSecondary">{subText}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ))}
        </DashboardCard>
    )
}

export default ProductGeneralDetailsCard
