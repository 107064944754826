import {Chip} from "@mui/material";
import React from "react";

export const SelectedChip = ({selectedLabel, handleUnselect}) => {
    return (
        <div style={{
            width: "100%",
            display: selectedLabel ? "flex" : "none",
            justifyContent: "flex-end",
        }}>
            <Chip variant="outlined"
                  style={{marginBottom: 16}}
                  onDelete={handleUnselect}
                  label={selectedLabel}/>
        </div>
    )
}