import React from "react"
// noinspection ES6CheckImport
import {Redirect, Route, Switch} from "react-router-dom"
import {useAuthsState} from "../app/auths/authsContext"
import {SALES_BASE_URL} from "../app/Routes"
import {SalesList} from "./sales-list/SalesList"
import {SalesRevenue} from "./sales-revenue/SalesRevenue"
import {Visitors} from "./visitors/Visitors"
import {Vouchers} from "./vouchers/Vouchers"
import {CustomersList} from "./customers/CustomersList"
import {useMuseumSelectorState} from "../app/museum-selector/museumSelectorContext"
import {ROLES_VALUES} from "../app/menu/MuseumSelector"

export const SalesRoutes = () => {
    const {userIsAuthenticated} = useAuthsState()
    const {selectedMuseum} = useMuseumSelectorState()

    const getRoutes = () => {
        if (userIsAuthenticated) {
            return (
                <>
                    <Route exact
                           path={SALES_BASE_URL}>
                        <Redirect to={`${SALES_BASE_URL}/list`}/>
                    </Route>
                    <Route path={`${SALES_BASE_URL}/list`}>
                        <SalesList/>
                    </Route>
                    <Route path={`${SALES_BASE_URL}/customers`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.APP_ADMIN
                            ? <CustomersList/>
                            : <Redirect to={`${SALES_BASE_URL}/list`}/>}
                    </Route>
                    <Route path={`${SALES_BASE_URL}/revenue`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <SalesRevenue/>
                            : <Redirect to={`${SALES_BASE_URL}/list`}/>}
                    </Route>
                    <Route path={`${SALES_BASE_URL}/visitors`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.USER
                            ? <Visitors/>
                            : <Redirect to={`${SALES_BASE_URL}/list`}/>}
                    </Route>
                    <Route path={`${SALES_BASE_URL}/vouchers`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <Vouchers/>
                            : <Redirect to={`${SALES_BASE_URL}/list`}/>}
                    </Route>
                </>
            )
        } else {
            return null
        }
    }

    return (
        <Switch>
            {getRoutes()}
        </Switch>
    )
}