import React, {useState} from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {
    CONFIRMED_ORDER_STATUSES,
    ONLY_NEW_ORDER_STATUSES,
    OTHER_ORDER_STATUSES,
} from "../helper-functions/orderStatuses"
import {useAxiosBpn} from "../axios"

const OrderDetailsDialogOverrideOrderDialog = ({
                                                   open,
                                                   onClose,
                                                   order,
                                                   onOrderUpdated,
                                               }) => {
    const t = useBaseTranslation()
    const [orderStatus, setOrderStatus] = useState(order.status)
    const allOrderStatuses = [...CONFIRMED_ORDER_STATUSES, ...ONLY_NEW_ORDER_STATUSES, ...OTHER_ORDER_STATUSES]

    // eslint-disable-next-line no-empty-pattern
    const [{}, overrideOrder] = useAxiosBpn({url: `orders/${order.id}/override`, method: "PUT"}, {manual: true})

    const handleSaveNewStatus = () => {
        overrideOrder({data: {lockedByGlobalAdmin: true, status: orderStatus}}).then(() => {onOrderUpdated()})
        onClose()
    }

    return (
        <Dialog open={open}
                maxWidth="md"
                onClose={onClose}>
            <DialogContent style={{padding: "2rem"}}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">{t("orderStatus")}</FormLabel>
                    <RadioGroup value={orderStatus} onChange={(e) => setOrderStatus(e.target.value)}>
                        <div style={{display: "flex", flexDirection: "row", flexFlow: "wrap"}}>
                            {allOrderStatuses.map((orderStatus, index) => (
                                <FormControlLabel value={orderStatus}
                                                  key={`${index}-${orderStatus}`}
                                                  control={<Radio/>}
                                                  label={t(orderStatus)}/>
                            ))}
                        </div>
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">{t("cancel")}</Button>
                <Button onClick={handleSaveNewStatus} color="primary">{t("save")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OrderDetailsDialogOverrideOrderDialog
