import React, {useEffect, useState} from "react"
import {Card, CardContent, CardHeader, Chip, Grid, Typography} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {GridLabel, GridValue} from "./gridComponents"
import {dateFormat} from "../helper-functions/dateFormat"
import {useAxiosBpn} from "../axios"
import {ButtonWithProgress} from "../components/ButtonWithProgress"
import {Help, Save} from "@mui/icons-material"
import useCustomSnackbar from "../components/useCustomSnackbar"
import OrderDetailsDialogQueryDetails from "./OrderDetailsDialogQueryDetails"
import {isConfirmed} from "../helper-functions/orderStatuses"

const OrderDetailsDialogPointOfSaleSystemDetails = ({order, loading, refetchOrder}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()

    const [kdrGridRows, setKdrGridRows] = useState([])
    const [_pointOfSaleSystemName, setPointOfSaleSystemName] = useState()
    const [queryResponse, setQueryResponse] = useState()

    const [{data: processKdrData, loading: processKdrLoading, error: processKdrError}, processKdrOrder] = useAxiosBpn({
        url: `orders/${order?.id}/kdr/process`, method: "PUT",
    }, {manual: true})

    const [
        {
            data: queryData,
            loading: queryLoading,
            error: queryError,
        }, queryOrder,
    ] = useAxiosBpn({url: `orders/${order?.id}/kdr`}, {manual: true})

    useEffect(() => {
        const {pointOfSaleSystemName} = order
        setPointOfSaleSystemName(pointOfSaleSystemName)
    }, [order])

    useEffect(() => {
        if (order && _pointOfSaleSystemName === "kdr") {
            const {kdrStatus, kdrExportedAt} = order
            setKdrGridRows([
                {label: t("pointOfSaleSystem"), value: t(_pointOfSaleSystemName)},
                {label: t("connectedTo"), value: order.pointOfSaleSystem?.museumName},
                {label: t("kdrStatus"), value: t(kdrStatus)},
                {label: t("kdrExported"), value: dateFormat(kdrExportedAt, "PPPp")},
                {
                    label: t("orderProcessing"),
                    value: <div style={{display: "flex", alignItems: "center"}}>
                        <ButtonWithProgress label={t("saveToKdr")}
                                            startIcon={<Save/>}
                                            loading={processKdrLoading}
                                            onClick={processKdrOrder}
                                            disabled={!isConfirmed(order.status)}
                                            variant="text"
                                            color="primary"
                                            size="small"/>
                        {!isConfirmed(order.status) && (
                            <Typography variant="caption"
                                        color="textSecondary"
                                        style={{marginLeft: 8}}>
                                {t("orderIsNotConfirmed")}
                            </Typography>
                        )}
                    </div>,
                },
                {
                    label: t("kdrQuery"),
                    value: <div style={{display: "flex", alignItems: "center"}}>
                        <ButtonWithProgress label={t("getKdrOrder")}
                                            startIcon={<Help/>}
                                            loading={queryLoading}
                                            onClick={queryOrder}
                                            disabled={!isConfirmed(kdrStatus)}
                                            variant="text"
                                            color="primary"
                                            size="small"/>
                        {!isConfirmed(kdrStatus) && (
                            <Typography variant="caption"
                                        color="textSecondary"
                                        style={{marginLeft: 8}}>
                                {t("kdrOrderIsNotConfirmed")}
                            </Typography>
                        )}
                    </div>,
                },
            ])
        }
    }, [order, _pointOfSaleSystemName, t, processKdrLoading, processKdrOrder, queryLoading, queryOrder])

    useEffect(() => {
        setQueryResponse(queryData?.result)
    }, [queryData])

    useEffect(() => {
        if (queryData) {
            snackbar.showInfo(t("kdrOrderQueried"))
        }
    }, [queryData, snackbar, t])

    useEffect(() => {
        if (processKdrData) {
            snackbar.showInfo(t(processKdrData?.text || "orderProcessed"))
            refetchOrder()
        }
    }, [processKdrData, refetchOrder, snackbar, t])

    useEffect(() => {
        if (processKdrError) {
            snackbar.showError(processKdrError.message)
        }
    }, [processKdrError, snackbar])

    useEffect(() => {
        if (queryError) {
            snackbar.showError(queryError.message)
        }
    }, [queryError, snackbar])

    function getTitle() {
        return (
            <Grid container
                  spacing={1}>
                <Grid item>
                    {t("pointOfSaleSystem")}
                </Grid>
                <Grid item>
                    <Chip label={t(_pointOfSaleSystemName)}
                          color="primary"
                          size="small"/>
                </Grid>
            </Grid>)
    }

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined">
               <CardHeader title={getTitle()}
                           titleTypographyProps={{color: "primary"}}
               />
               <CardContent>
                   <Grid container
                         direction="column">
                       {kdrGridRows?.map(({label, value}, index) =>
                           value &&
                           <Grid container
                                 key={index}
                                 alignItems="center">
                               <GridLabel>{label && `${label}:`}</GridLabel>
                               <GridValue>{value}</GridValue>
                           </Grid>,
                       )}
                       {queryResponse && <OrderDetailsDialogQueryDetails queryResponse={queryResponse}
                                                                         system="kdr"
                                                                         handleClose={() => setQueryResponse()}/>}
                   </Grid>
               </CardContent>
           </Card>
}

export default OrderDetailsDialogPointOfSaleSystemDetails