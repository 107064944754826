import {Typography} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';

import React, {useEffect, useState} from "react"
import SectionHeader from "../../../app/components/SectionHeader"
import ScrollableSectionView from "../../../app/ScrollableSectionView"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {useAuthsState} from "../../../app/auths/authsContext"
import {useEntranceTranslation} from "../entranceTranslationContext"
import {ENTRANCE_BASE_URL} from "../../../app/Routes"
import DeleteConfirmationDialog from "../../../app/components/DeleteConfirmationDialog"
import {EntranceCard} from "./EntranceCard"
import DropdownSelectorFilter from "../../../app/components/DropdownSelectorFilter"
import DebouncedTextFilter from "../../../app/components/DebouncedTextFilter"
import {
    PRODUCT_STATUS_DRAFT,
    PRODUCT_STATUS_PUBLISHED,
    SELECTABLE_PRODUCT_STATUSES,
} from "../../../app/helper-functions/productStatuses"
import TestOrdersSwitch from "../../../app/components/TestOrdersSwitch"

const LOCAL_STORAGE_KEY = "bpn.testOrders"

const useStyles = makeStyles(theme => ({
    filterSectionContainer: {
        display: "flex",
        marginBottom: theme.spacing(1),
    },
    filterContainer: {
        display: "flex",
        alginItems: "center",
        marginRight: theme.spacing(2),
    },
    textField: {
        fontWeight: 500,
        background: "white",
    },
}))

export const EntrancesList = () => {
    const classes = useStyles()
    const t = useEntranceTranslation()
    const {umbrellaAndChildrenIds, selectedMuseum} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const snackbar = useCustomSnackbar()
    const [selectedStatuses, setSelectedStatuses] = useState([PRODUCT_STATUS_DRAFT, PRODUCT_STATUS_PUBLISHED])
    const [searchValue, setSearchValue] = useState("")
    const [entranceTickets, setProductEvents] = useState([])
    const [selectedProductId, setSelectedProductId] = useState()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)

    const [{data, loading}, getEntranceTickets] = useAxiosBpn({
        url: "entrance_tickets",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                ? umbrellaAndChildrenIds.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
            test: testOrders,
            statuses: selectedStatuses.join()
        },
    })

    const [{data: patchData, loading: patchLoading, error: patchError}, patchProduct] = useAxiosBpn({
        method: "PATCH",
    }, {manual: true})

    const [{data: deleteData, loading: deleteLoading, error: deleteError}, deleteEvent] = useAxiosBpn({
        method: "DELETE",
    }, {manual: true})

    useEffect(() => {
        if (data?.entranceTickets) {
            let filteredResult = data.entranceTickets
                .filter(entrance => selectedStatuses.includes(entrance.status))
            if (searchValue) {
                filteredResult = filteredResult
                    .filter(entrance => entrance.name.toUpperCase()
                        .includes(searchValue.toUpperCase()))
            }

            setProductEvents(filteredResult)
        }
    }, [selectedStatuses, searchValue, data])

    useEffect(() => {
        getEntranceTickets().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (deleteData) {
            snackbar.showSuccess(`${t("entranceTicket")} ${t("deleted").toLowerCase()}`)
            getEntranceTickets().catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [deleteData, getEntranceTickets, snackbar, t])

    useEffect(() => {
        if (patchData) {
            snackbar.showSuccess(`${t("entranceTicket")} ${t("archived").toLowerCase()}`)
            getEntranceTickets().catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [getEntranceTickets, patchData, snackbar, t])

    useEffect(() => {
        if (deleteError) snackbar.showError(t(deleteError.message))
    }, [deleteError, snackbar, t])

    useEffect(() => {
        if (patchError) snackbar.showError(t(patchError.message))
    }, [patchError, snackbar, t])

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    const breadcrumbs = [
        {label: t("entrance"), url: ENTRANCE_BASE_URL},
        {label: t("list")},
    ]

    const handleDeleteButtonClick = productId => {
        setSelectedProductId(productId)
        setDeleteDialogOpen(true)
    }

    const handleArchiveButtonClick = (entrance, setAsArchived) => {
        setSelectedProductId(entrance.productId)
        const status = setAsArchived ? "archived" : "draft"
        patchProduct({url: `products/${entrance.productId}`, params: {status: status}})
    }

    const handleDelete = productId => {
        setDeleteDialogOpen(false)
        deleteEvent({url: `products/${productId}`})
    }

    const handleStatusFilterChange = (event) => {
        const value = event.target.value
        setSelectedStatuses(value)
    }

    const onNameFilterValueChange = event => {
        setSearchValue(event.target.value)
    }

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <div className={classes.filterSectionContainer}>
                <div className={classes.filterContainer}>
                    <DebouncedTextFilter onChange={onNameFilterValueChange}
                                         debounceTime={300}
                                         filterName={t("name")}/>
                </div>
                <div className={classes.filterContainer}>
                    <DropdownSelectorFilter onChange={handleStatusFilterChange}
                                            value={selectedStatuses}
                                            options={SELECTABLE_PRODUCT_STATUSES}/>
                </div>
            </div>
            <ScrollableSectionView>
                {loading || patchLoading || deleteLoading
                    ? <ProgressIndicator/>
                    : entranceTickets?.length
                        ? entranceTickets.map((entrance) =>
                            <EntranceCard key={entrance.productId}
                                          entrance={entrance}
                                          roleValue={selectedMuseum?.role?.value}
                                          onArchiveButtonClicked={handleArchiveButtonClick}
                                          onDeleteButtonClicked={handleDeleteButtonClick}/>)
                        : <Typography style={{margin: "16px"}}>{t("noEntranceTicketsYet")}!</Typography>}
            </ScrollableSectionView>
            <DeleteConfirmationDialog
                open={deleteDialogOpen}
                content={`${t("deleteEntranceTicketDialogText")}?`}
                handleClose={() => setDeleteDialogOpen(false)}
                handleDelete={() => handleDelete(selectedProductId)}/>
        </>
    )
}

