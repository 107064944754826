import React from "react"
import {Redirect, Route, Switch} from "react-router-dom"
import {DASHBOARD_BASE_URL, JOINT_PRODUCTS_DISCOUNT_BASE_URL} from "../app/Routes"
import {useAuthsState} from "../app/auths/authsContext"
import {JointProductsDiscounts} from "./joint-products-discounts/JointProductsDiscounts"
import {useMuseumSelectorState} from "../app/museum-selector/museumSelectorContext"
import {Typography} from "@mui/material"
import {useBaseTranslation} from "../app/baseTranslationContext"
import {ROLES_VALUES} from "../app/menu/MuseumSelector"

export const JointProductsDiscountRoutes = () => {
    const t = useBaseTranslation()
    const {userIsAuthenticated} = useAuthsState()
    const {umbrellaAndChildrenIdsInAccessList, selectedMuseum} = useMuseumSelectorState()

    const getRoutes = () => {
        if (userIsAuthenticated) {
            return (
                <>
                    <Route exact
                           path={JOINT_PRODUCTS_DISCOUNT_BASE_URL}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <Redirect to={`${JOINT_PRODUCTS_DISCOUNT_BASE_URL}/list`}/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                    <Route path={`${JOINT_PRODUCTS_DISCOUNT_BASE_URL}/list`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <JointProductsDiscounts/>
                            : <Redirect to={`${DASHBOARD_BASE_URL}`}/>}
                    </Route>
                </>
            )
        } else {
            return null
        }
    }

    if (umbrellaAndChildrenIdsInAccessList?.length) {
        return (
            <Switch>
                {getRoutes()}
            </Switch>
        )
    } else {
        return (
            <Typography>{t("youNeedToSelectAMuseumToChangeJointDiscounts")}.</Typography>
        )
    }
}