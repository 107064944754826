export const createCustomerTableHeaders = (t, handleCustomerIdClick, smDown = false) => [
    {field: "customerId", headerName: t("customerId"), onClick: handleCustomerIdClick},
    {field: "status", headerName: t("status")},
    {field: "firstName", headerName: t("firstName"), align: "left", flex: 2},
    {field: "lastName", headerName: t("lastName"), align: "left", flex: 2},
    {field: "email", headerName: t("email"), align: "left", flex: 3, hide: smDown},
    // {field: "address", headerName: t("address"), align: "left", flex: 3, hide: smDown},
]

export const createCustomerTableRows = (_customers, t) => {
    return _customers?.map(({
                                id,
                                status,
                                firstName,
                                lastName,
                                email,
                                address,
                            }) => {

        return ({
            customerId: id,
            status: t(status),
            firstName: firstName,
            lastName: lastName,
            email,
            address,
        })
    })
}
