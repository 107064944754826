import React, {useEffect, useState} from "react"
import ProductFamilyDiscountsSelector from "./ProductFamilyDiscountsSelector"

const ProductFamilyDiscounts = ({
                                    product,
                                    availableFamilyDiscounts,
                                    updateProductState,
                                    unsavedChanges,
                                    setUnsavedChanges,
                                }) => {
    const [discountSelectorDialogOpen, setDiscountSelectorDialogOpen] = useState(false)
    const [_product, setProduct] = useState({})
    const [selectedDiscounts, setSelectedDiscounts] = useState([])
    const [_availableFamilyDiscounts, setAvailableFamilyDiscounts] = useState([])
    const [availablePriceCategoryIds, setAvailablePriceCategoryIds] = useState([])

    useEffect(() => {
        if (product) {
            setProduct(product)
            setAvailablePriceCategoryIds(product?.prices?.map(price => price.priceCategoryId))
        }
    }, [_product, product])

    useEffect(() => {
        if (availableFamilyDiscounts && _product?.familyDiscounts) {
            setSelectedDiscounts(availableFamilyDiscounts.filter((e) =>
              _product.familyDiscounts?.some((ae) => e.id === ae.id || e.id === ae.id),
            ))
        }
        if (availableFamilyDiscounts) {
            setAvailableFamilyDiscounts(availableFamilyDiscounts?.map(discount => {
                return {
                    ...discount,
                    unAccessibleLimits: discount?.limits
                        .filter(({priceCategoryId}) => !availablePriceCategoryIds?.includes(priceCategoryId)),
                }
            }))
        }
    }, [_product, availableFamilyDiscounts, availablePriceCategoryIds])

    const handleSelectedDiscountsChange = (productFamilyDiscounts) => {
        updateProductState({...product, familyDiscounts: productFamilyDiscounts})

        if (!unsavedChanges) setUnsavedChanges(true)
    }

    return (
        <ProductFamilyDiscountsSelector open={discountSelectorDialogOpen}
                                        selected={selectedDiscounts}
                                        available={_availableFamilyDiscounts}
                                        onClose={() => setDiscountSelectorDialogOpen(false)}
                                        handleChange={handleSelectedDiscountsChange}/>
    )
}

export default React.memo(ProductFamilyDiscounts)
