import React from "react"
import {Chip, Tooltip, Typography} from "@mui/material"
import {getSubstring} from "../../helper-functions/stringUtility"
import {getFamilyDiscountLimitsString} from "../../../settings/products-settings/family-discounts/FamilyDiscountsTable"
import {LocalOffer} from "@mui/icons-material"

const SelectedFamilyDiscountChip = ({discount}) => {
    const {name, active, limits} = discount

    return (
        <Chip disabled={!active} label={
            <Tooltip title={
                <>
                    <Typography variant="h6"><strong>{name}</strong></Typography>
                    <Typography variant="body2">{getFamilyDiscountLimitsString(limits)}</Typography>
                </>}>
                <Typography variant="caption">{getSubstring(name, 12)}</Typography>
            </Tooltip>
        }
              style={{margin: 8}}
              color={active ? "primary" : "default"}
              icon={<LocalOffer fontSize="small"/>}/>
    )
}

export default SelectedFamilyDiscountChip