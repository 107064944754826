import React from "react"
import {Avatar} from "@mui/material"
import {Equalizer} from "@mui/icons-material"

export const ChartCardAvatar = ({backgroundColor}) => (
    <Avatar style={{backgroundColor: backgroundColor}}>
        <Equalizer/>
    </Avatar>
)

