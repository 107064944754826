import React, {useEffect, useState} from "react"
import {Dialog, DialogActions, DialogContent} from "@mui/material"
import Button from "@mui/material/Button"
import {useAxiosBpn} from "../axios"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import CustomerDetailsDialogGeneral from "./CustomerDetailsDialogGeneral"
import CustomerDetailsDialogOrders from "./CustomerDetailsDialogOrders"

const CustomerDetailsDialog = ({customerId, open, handleClose}) => {
    const t = useBaseTranslation()

    const [customer, setCustomer] = useState({})
    const [_customerId, setCustomerId] = useState(customerId)

    const [{data: customerData, loading: customerLoading}, getCustomer] = useAxiosBpn(
        {url: `customers/${_customerId}`},
        {manual: true})

    const [{data: ordersData, loading: ordersLoading}, getOrders] = useAxiosBpn(
        {
            url: "orders",
            params: {
                customerId: customer?.id,
                detailed: true,
            },
        },
        {manual: true})

    useEffect(() => {
        setCustomerId(customerId)
    }, [customerId])

    useEffect(() => {
        if (customer?.id) {
            getOrders().catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [customer, getOrders])

    useEffect(() => {
        if (_customerId) {
            getCustomer().catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [getCustomer, _customerId])

    useEffect(() => {
        if (customerData) setCustomer(customerData.customer)
    }, [customerData])

    return customerLoading
           ? <ProgressIndicator/>
           : <Dialog open={open}
                     onClose={handleClose}
                     fullWidth
                     maxWidth="md">
               <DialogContent style={{minHeight: 600}}>
                   <div style={{marginBottom: 8}}>
                       <CustomerDetailsDialogGeneral loading={customerLoading}
                                                     customer={customer}
                                                     handleClose={handleClose}/>
                   </div>
                   <div style={{marginBottom: 8}}>
                       <CustomerDetailsDialogOrders loading={ordersLoading}
                                                    orders={ordersData?.orders}/>
                   </div>
               </DialogContent>
               <DialogActions>
                   <Button onClick={handleClose} color="primary"> {t("close")} </Button>
               </DialogActions>
           </Dialog>
}

export default CustomerDetailsDialog