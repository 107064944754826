import {museumsAPI} from "../axios"

export const getMuseumsForUser = (museumAccessList) => {
    return museumsAPI.get("museums").then(({data: {museums}}) => {
        if (!museumAccessList) {
            return []
        }
        return museums.filter(museum => existsInAccessList(museum, museumAccessList))
    })
}

export const getUmbrellaMuseums = () => {
    return museumsAPI.get("museums").then(result => {
        const {data: {museums}} = result
        return museums.filter(museum => museum.type === "umbrella")
    })
}

export const getUmbrellaMuseumChildren = (id, museumAccessList) => {
    return museumsAPI.get(`museums/${id}/children/`).then(({data}) => {
        if (!museumAccessList) {
            return []
        }
        const umbrellaChildren = data.filter(museum => existsInAccessList(museum, museumAccessList))
        return getChildren(umbrellaChildren).then(children => {
            const childrenIds = children.map(({id}) => id)
            return {children: children, ids: [id, ...childrenIds]}
        })
    })
}

export const getMuseum = (id) => {
    return museumsAPI.get(`museums/${id}`).then(result => {
        return result?.data?.museum
    })
}

export const getParent = (id) => {
    return museumsAPI.get(`museums/${id}/parent/`).then(({data: {museumId}}) => {
        return getMuseum(museumId)
    }).catch(() => {})
}

const getChildren = async (museums) => {
    return Promise.all(museums.map(({museumId}) => getMuseum(museumId)))
}

const existsInAccessList = (museum, museumAccessList) => museumAccessList
    .filter(allowedMuseum => allowedMuseum.id === museum.id | allowedMuseum.id === museum.museumId).length > 0
