import React, {useEffect, useState} from "react"
import {Avatar, Card, CardContent, CardHeader, FormControlLabel, Grid, Switch, Tooltip} from "@mui/material"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import Typography from "@mui/material/Typography"
import {useAxiosBpn} from "../../app/axios"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {useAuthsState} from "../../app/auths/authsContext"
import {deepOrange, red} from "@mui/material/colors"
import {ProgressIndicator} from "../../app/ProgressIndicator"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import ProductTypeIcon from "../../app/components/ProductTypeIcon"
import ChipWithTooltip from "../../app/components/ChipWithTooltip"
import {Info} from "@mui/icons-material"
import ProductTypeSelector from "../../app/components/ProductTypeSelector"
import arrayMove from "array-move"

const DROP_CARDS = {JOINT: "jointProducts", AVAILABLE: "availableProducts"}

const getListStyle = (isDraggingOver) => ({
    padding: 8,
    height: "calc(100% - 37px)",
    overflowY: "auto",
    background: isDraggingOver ? "lightblue" : "lightgrey",
})

const MainCard = ({t, title, subheader, action, loading, products, noProductsText}) => (
    <Card square variant="outlined" style={{marginTop: 8, padding: 8, height: "100%"}}>
        <CardHeader title={<Typography variant="subtitle2">{t(title)}</Typography>}
                    subheader={<Typography variant="caption" color="textSecondary">{subheader}</Typography>}/>
        <CardContent style={{height: `calc(100% - 74px)`, paddingBottom: 16}}>
            <>
                <div style={{paddingBottom: action ? 8 : 37}}>{action}</div>
                <Droppable droppableId={title}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}
                             style={getListStyle(snapshot.isDraggingOver)}
                             {...provided.droppableProps}>
                            {loading
                                ? <ProgressIndicator/>
                                : products?.length
                                    ? products?.map(({
                                                         id,
                                                         name,
                                                         type,
                                                         museumName,
                                                         jointProductsDiscountName
                                                     }, index) => (
                                        <Draggable key={id}
                                                   draggableId={`${id}`}
                                                   index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef}
                                                     {...provided.draggableProps}
                                                     {...provided.dragHandleProps}>
                                                    <ProductCard key={id}
                                                                 name={name}
                                                                 jointProductsDiscountName={jointProductsDiscountName}
                                                                 type={type}
                                                                 typeTooltip={t(type)}
                                                                 museumName={museumName}/>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                    : noProductsText}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </>
        </CardContent>
    </Card>
)

const ProductCard = ({name, type, typeTooltip, museumName, jointProductsDiscountName}) => (
    <Card variant="outlined"
          style={{marginTop: 4, padding: 2, backgroundColor: jointProductsDiscountName ? red[100] : null}}>
        <CardHeader title={name}
                    subheader={
                        <Grid container
                              spacing={1}
                              alignItems="center">
                            <Grid item>
                                <ChipWithTooltip label={museumName} size="small" color="default"/>
                            </Grid>
                            {jointProductsDiscountName && (
                                <ChipWithTooltip label={jointProductsDiscountName}
                                                 maxLength={12}
                                                 size="small"
                                                 color="primary"/>
                            )}
                        </Grid>
                    }
                    avatar={
                        <Avatar style={{backgroundColor: deepOrange[300]}}>
                            <Tooltip title={typeTooltip}>
                                <div>
                                    <ProductTypeIcon type={type}/>
                                </div>
                            </Tooltip>
                        </Avatar>
                    }/>
    </Card>
)

const JointProductsDiscountDetailsDragDrop = ({jointProducts, handleJointProductsChange}) => {
    const t = useBaseTranslation()
    const {umbrellaAndChildrenIdsInAccessList} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()

    const [availableProducts, setAvailableProducts] = useState([])
    const [currentJointProducts, setCurrentJointProducts] = useState([])
    const [selectedProductType, setSelectedProductType] = useState("all")
    const [onlyNonJointProducts, setOnlyNonJointProducts] = useState(true)

    const [{data: availableProductsData, loading: availableProductsLoading}, getAvailableProducts] = useAxiosBpn({
        url: "products",
        params: {
            status: "draft,published",
            type: selectedProductType ? (selectedProductType !== "all" ? selectedProductType : null) : null,
            museumIds: umbrellaAndChildrenIdsInAccessList?.length
                ? umbrellaAndChildrenIdsInAccessList.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
        },
    })

    useEffect(() => getAvailableProducts(), [getAvailableProducts, selectedProductType])
    useEffect(() => setCurrentJointProducts(jointProducts || []), [jointProducts])

    useEffect(() => {
        if (availableProductsData) {
            const {products} = availableProductsData
            setAvailableProducts(onlyNonJointProducts
                ? products.filter(({jointProductsDiscountId}) => !jointProductsDiscountId)
                : products)
        }
    }, [availableProductsData, onlyNonJointProducts])

    const onDragEnd = ({source, destination}) => {
        const updateSortOrder = _array => {
            for (let i = 0; i < _array?.length; i++) {
                _array[i].sortOrder = i
            }
            return _array
        }

        // dropped outside the list
        if (!destination) return

        const {droppableId: sourceId, index: sourceIndex} = source
        const {droppableId: destinationId, index: destinationIndex} = destination

        let _availableProducts = availableProducts
        let _currentJointProducts = currentJointProducts

        if (sourceId !== destinationId) {
            if (sourceId === DROP_CARDS.AVAILABLE) {
                const object = _availableProducts.splice(sourceIndex, 1)[0]
                _currentJointProducts.splice(destinationIndex, 0, object)
            } else if (sourceId === DROP_CARDS.JOINT) {
                const object = _currentJointProducts.splice(sourceIndex, 1)[0]
                _availableProducts.splice(destinationIndex, 0, object)
            }
        } else {
            if (sourceId === DROP_CARDS.AVAILABLE) {
                _availableProducts = arrayMove(_availableProducts, sourceIndex, destinationIndex)
            } else if (sourceId === DROP_CARDS.JOINT) {
                _currentJointProducts = arrayMove(_currentJointProducts, sourceIndex, destinationIndex)
            }
        }
        _currentJointProducts = updateSortOrder(_currentJointProducts)
        setAvailableProducts(_availableProducts)
        setCurrentJointProducts(_currentJointProducts)
        handleJointProductsChange(_currentJointProducts)
    }

    const handleSelectedProductTypeChange = (type) => setSelectedProductType(type)

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={1} wrap="nowrap" style={{height: "calc(100% - 152px)"}}>
                <Grid item xs>
                    <MainCard t={t}
                              title={DROP_CARDS.JOINT}
                              subheader={t("jointProductsHelperText")}
                              products={currentJointProducts}
                              noProductsText={t("noJointProductsYet")}/>
                </Grid>
                <Grid item xs>
                    <MainCard t={t}
                              title={DROP_CARDS.AVAILABLE}
                              subheader={t("availableJointProductsHelperText")}
                              products={availableProducts}
                              loading={availableProductsLoading}
                              noProductsText={t("noJointProductsYet")}
                              action={
                                  <Grid container
                                        spacing={1}
                                        alignItems="center"
                                        justifyContent="space-between">
                                      <Grid item>
                                          <div style={{display: "flex", alignItems: "center"}}>
                                              <FormControlLabel label={t("showOnlyNonJoint")}
                                                                labelPlacement="start"
                                                                control={
                                                                    <Switch color="primary"
                                                                            checked={onlyNonJointProducts}
                                                                            onChange={() => setOnlyNonJointProducts(!onlyNonJointProducts)}/>
                                                                }/>
                                              {!onlyNonJointProducts && (
                                                  <Tooltip title={t("onlyNonJointProductsHelperText")}>
                                                      <Info style={{marginLeft: 8, color: red[400]}}/>
                                                  </Tooltip>
                                              )}
                                          </div>
                                      </Grid>
                                      <Grid item>
                                          <ProductTypeSelector label={t("type")}
                                                               selected={selectedProductType}
                                                               handleChange={handleSelectedProductTypeChange}/>
                                      </Grid>
                                  </Grid>
                              }/>
                </Grid>
            </Grid>
        </DragDropContext>
    )
}

export default JointProductsDiscountDetailsDragDrop