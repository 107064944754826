import React, {createContext, useContext} from "react"
import {useTranslation} from "react-i18next"
import {ProgressIndicator} from "../../app/ProgressIndicator"

const EntranceTranslationContext = createContext(undefined)

export const EntranceTranslationProvider = ({children}) => {
    const {t, ready} = useTranslation("bpn.entrance", {useSuspense: false})

    if (ready) {
        return (
            <EntranceTranslationContext.Provider value={t}>
                {children}
            </EntranceTranslationContext.Provider>
        )
    } else {
        return <ProgressIndicator/>
    }
}

export const useEntranceTranslation = () => {
    return useContext(EntranceTranslationContext)
}