import {camelCase, kebabCase, lowerCase, snakeCase, startCase, upperCase, upperFirst} from "lodash"


export const toCamelCase = (str) => {
    return camelCase(str)
}

export const toTitleCase = (str) => {
    return startCase(camelCase(str))
}

export const toPascalCase = (str) => {
    return startCase(camelCase(str)).replace(/ /g, "");
}

export const toConstantCase = (str) => {
    return upperCase(str).replace(/ /g, "_");
}

export const toDotCase = (str) => {
    return lowerCase(str).replace(/ /g, ".");
}

export const toKebabCase = (str) => {
    return kebabCase(str)
}

export const toLowerCase = (str) => {
    return lowerCase(str).replace(/ /g, "");
}

export const toPathCase = (str) => {
    return lowerCase(str).replace(/ /g, "/");
}

export const toSnakeCase = (str) => {
    return snakeCase(str)
}

export const toSentenceCase = (str) => {
    return upperFirst(lowerCase(str))
}

export const getSubstring = (string, maxLength) => {
    let _string = string?.substring(0, maxLength)
    return `${_string}${string?.length > maxLength ? "..." : ""}`
}