export const VOUCHER_STATUS_DELETED = "deleted"
export const VOUCHER_STATUS_ARCHIVED = "archived"
export const VOUCHER_STATUS_NEW = "new"
export const VOUCHER_STATUS_CONFIRMED = "confirmed"

export const VOUCHER_SETTINGS_TYPE_UMBRELLA = "umbrella"
export const VOUCHER_SETTINGS_TYPE_MUSEUM = "museum"
export const VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP = "productGroup"
export const VOUCHER_SETTINGS_TYPE_DEPARTMENT = "department"
export const VOUCHER_SETTINGS_TYPE_MERGED = "merged"

export const VOUCHER_FORMAT_NONE = "none"
export const VOUCHER_FORMAT_VISMA = "visma"