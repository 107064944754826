import React, {useEffect, useState} from "react"
import {
    getOrderStatusDescription,
    ORDER_STATUS_CONFIRMED_CREDIT_NOTE,
    ORDER_STATUS_CONFIRMED_CREDITED,
    ORDER_STATUS_CONFIRMED_PARTIAL_CREDIT_NOTE,
    ORDER_STATUS_CONFIRMED_PARTIAL_CREDITED,
    ORDER_STATUS_ISSUER_IS_UNDETERMINED,
    ORDER_STATUS_PAYMENT_PENDING,
} from "../helper-functions/orderStatuses"
import { Alert, AlertTitle } from '@mui/material';
import {useBaseTranslation} from "../baseTranslationContext"
import {Button, Typography} from "@mui/material"

const OrderDetailsDialogAlerts = ({status, creditNoteId, creditNoteForOrderId, setOrderId, processOrder}) => {
    const t = useBaseTranslation()
    const [alert, setAlert] = useState()

    useEffect(() => {
        switch (status) {
            case ORDER_STATUS_CONFIRMED_CREDIT_NOTE:
                setAlert({
                    severity: "error",
                    title: t(status),
                    text: `${t("thisIsACreditNoteForOrder")} ${creditNoteForOrderId}`,
                    button: {
                        onClick: setOrderId,
                        text: t("goToCreditedOrder"),
                    },
                })
                break
            case ORDER_STATUS_CONFIRMED_PARTIAL_CREDIT_NOTE:
                setAlert({
                    severity: "error",
                    title: t(status),
                    text: `${t("thisIsAPartialCreditNoteForOrder")} ${creditNoteForOrderId}`,
                    button: {
                        onClick: setOrderId,
                        text: t("goToCreditedOrder"),
                    },
                })
                break
            case ORDER_STATUS_CONFIRMED_CREDITED:
                setAlert({
                    severity: "error",
                    title: t("thisOrderIsCredited"),
                    text: `${t("theCreditNoteHasOrderId")} ${creditNoteId}`,
                    button: {
                        onClick: setOrderId,
                        text: t("goToCreditNote"),
                    },
                })
                break
            case ORDER_STATUS_CONFIRMED_PARTIAL_CREDITED:
                setAlert({
                    severity: "error",
                    title: t("thisOrderIsPartiallyCredited"),
                    text: `${t("theCreditNoteHasOrderId")} ${creditNoteId}`,
                    button: {
                        onClick: setOrderId,
                        text: t("goToCreditNote"),
                    },
                })
                break
            case ORDER_STATUS_PAYMENT_PENDING:
                setAlert({
                    severity: "warning",
                    title: t(status),
                    text: getOrderStatusDescription(status),
                    button: {
                        onClick: processOrder,
                        text: t("processAFresh"),
                    },
                })
                break
            case ORDER_STATUS_ISSUER_IS_UNDETERMINED:
                setAlert({
                    severity: "error",
                    title: t(status),
                    text: getOrderStatusDescription(status),
                    button: {
                        onClick: processOrder,
                        text: t("processAFresh"),
                    },
                })
                break
            default:
                setAlert({
                    severity: "info",
                    title: t(status),
                    text: getOrderStatusDescription(status),
                })
        }
    }, [status, creditNoteForOrderId, creditNoteId, t, setOrderId, processOrder])


    return (
        <>
            {alert?.text && (
                <Alert severity={alert.severity}
                       style={{marginBottom: 8}}
                       action={alert?.button
                               ? <Button color="inherit"
                                         onClick={() => alert.button.onClick(creditNoteId | creditNoteForOrderId)}>
                                   <Typography variant="button"
                                               noWrap>
                                       {alert.button.text}
                                   </Typography>
                               </Button>
                               : null}>
                    <AlertTitle>{alert.title}</AlertTitle>
                    {alert.text}
                </Alert>
            )}
        </>
    )
}

export default OrderDetailsDialogAlerts