import React, { useEffect, useState } from "react";
import { useBaseContextState } from "../../app/baseContext";
import SectionHeader from "../../app/components/SectionHeader";
import ScrollableSectionView from "../../app/ScrollableSectionView";
import { SELECTED_TYPE_UMBRELLA, useMuseumSelectorState } from "../../app/museum-selector/museumSelectorContext";
import EditorCardModule from "../../app/components/EditorCardModule";
import { useAxiosBpn } from "../../app/axios";
import { useTheme } from "@mui/material/styles";
import { ProgressIndicator } from "../../app/ProgressIndicator";
import { useBaseTranslation } from "../../app/baseTranslationContext";
import UserPortalDesignSettingsHelperText from "./design-settings/UserPortalDesignSettingsHelperText";
import UserPortalDesignSettings from "./design-settings/UserPortalDesignSettings";
import UserPortalRegistrationSettingsHelperText from "./registration-settings/UserPortalRegistrationSettingsHelperText";
import UserPortalRegistrationSettings from "./registration-settings/UserPortalRegistrationSettings";

export const defaultPortalDesignSettings = {
    borderRounding: 0.5,
    imageBrightness: 1,
    imageBlur: 0,
    imageSaturation: 1,
}

export const defaultPortalRegistrationSettings = {
    collectZipCodes: false
}


const UserPortalSettings = () => {
    const {spacing} = useTheme()
    const t = useBaseTranslation()
    const {currentBaseUrl} = useBaseContextState()
    const {selectedMuseum} = useMuseumSelectorState()
    const [activeBreadcrumb, setActiveBreadcrumb] = useState(t("products"))
    const [portalDesignSettings, setPortalDesignSettings] = useState()
    const [portalRegistrationSettings, setPortalRegistrationSettings] = useState()

    const [{data: portalDesignSettingsData, loading: portalDesignSettingsLoading}, getPortalDesignSettings] = useAxiosBpn({
        url: `portal_design_settings/${selectedMuseum?.id}`,
    })

    const [{data: portalRegistrationSettingsData, loading: portalRegistrationSettingsLoading}, getPortalRegistrationSettings] = useAxiosBpn({
        url: `portal_registration_settings/${selectedMuseum?.id}`,
    })

    useEffect(() => {
        getPortalDesignSettings().catch(() => {
            setPortalDesignSettings(defaultPortalDesignSettings)
        })
    }, [getPortalDesignSettings, selectedMuseum])

    useEffect(() => {
        getPortalRegistrationSettings().catch(() => {
            setPortalRegistrationSettings(defaultPortalRegistrationSettings)
        })
    }, [getPortalRegistrationSettings, selectedMuseum])

    useEffect(() => {
        if (portalDesignSettingsData) {
          setPortalDesignSettings(portalDesignSettingsData)
        } else{
          setPortalDesignSettings(defaultPortalDesignSettings)
        }
    }, [portalDesignSettingsData])

    useEffect(() => {
        if (portalRegistrationSettingsData) {
            setPortalRegistrationSettings(portalRegistrationSettingsData)
        } else{
            setPortalRegistrationSettings(defaultPortalRegistrationSettings)
        }
    }, [portalRegistrationSettingsData])

    useEffect(() => {
        setActiveBreadcrumb(`${t("userPortal")} (${selectedMuseum?.name})`)
    }, [t, selectedMuseum])

    const breadcrumbs = [
        {label: t("settings"), url: currentBaseUrl},
        {label: activeBreadcrumb},
    ]

    return (portalDesignSettingsLoading || portalRegistrationSettingsLoading) ? <ProgressIndicator/> : (
      <>
          <SectionHeader breadcrumbs={breadcrumbs}/>
          <ScrollableSectionView>
              <div style={{paddingRight: spacing(1)}}>
                  <EditorCardModule expanded={true}
                                    title="userPortalDesignSettings"
                                    helperTextComponent={<UserPortalDesignSettingsHelperText />}
                                    subheader={t("portalDesignSettingsHelperText")}>
                      <UserPortalDesignSettings designSettings={portalDesignSettings}
                                                getMuseumSettings={getPortalDesignSettings}/>
                  </EditorCardModule>
              </div>
              {selectedMuseum?.type  === SELECTED_TYPE_UMBRELLA &&
                <div style={{paddingRight: spacing(1)}}>
                  <EditorCardModule expanded={true}
                                    title="userPortalRegistrationSettings"
                                    helperTextComponent={<UserPortalRegistrationSettingsHelperText />}>
                      <UserPortalRegistrationSettings registrationSettings={portalRegistrationSettings}
                                                getMuseumSettings={getPortalRegistrationSettings}/>
                  </EditorCardModule>
              </div>}
          </ScrollableSectionView>
      </>
    )
}

export default UserPortalSettings
