import React, {useState} from "react"
import {Grid, Tooltip, Typography} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import {Block} from "@mui/icons-material"


const ScheduleEventExceptionsGridRowCancelled = ({isCancelled}) => {
    const theme = useTheme()
    const [color] = useState(isCancelled ? "darkred" : "lightgray")

    const TooltipTitle = () => (
        <Typography variant="subtitle2">Denne kalenderhendelsen er {isCancelled ? "" : "ikke"} avlyst</Typography>
    )

    return (
        <Tooltip title={<TooltipTitle/>}>
            <Grid container item direction="column" alignItems="center">
                <Grid item><Block style={{color: color}}/></Grid>
                <Grid item>
                    <Typography style={{color: color, marginTop: -8, fontSize: theme.typography.pxToRem(11)}}>
                        Avlyst
                    </Typography>
                </Grid>
            </Grid>
        </Tooltip>
    )
}

export default ScheduleEventExceptionsGridRowCancelled