import {Breadcrumbs, IconButton, Tooltip} from "@mui/material"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import makeStyles from '@mui/styles/makeStyles';
import React from "react"
// noinspection ES6CheckImport
import {useHistory} from "react-router-dom"
import {ArrowBack} from "@mui/icons-material"
import DashboardIcon from "@mui/icons-material/Dashboard"
import {useBaseTranslation} from "../baseTranslationContext"

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
}))

const SectionHeader = ({breadcrumbs, children, backButton = false, detailsLink = false}) => {
    const classes = useStyles()
    const t = useBaseTranslation()
    const history = useHistory()
    const handleClick = url => history.push(url)

    return (
        <div className={classes.root}>
            <Grid container
                  alignItems="center"
                  wrap="nowrap">
                {backButton && (
                    <Grid item>
                        <IconButton onClick={() => history.goBack()} size="large">
                            <ArrowBack/>
                        </IconButton>
                    </Grid>
                )}
                <Grid container
                      item
                      alignItems="center"
                      justifyContent={"space-between"}>
                    <Grid item>
                        <Grid container
                              alignItems="center">
                            <Grid item>
                                <Breadcrumbs>
                                    {breadcrumbs.map(({label, url}, key) =>
                                        url ? (
                                            <Link color="primary"
                                                  key={key}
                                                  component="button"
                                                  variant="h6"
                                                  onClick={() => handleClick(url)}>
                                                {label}
                                            </Link>
                                        ) : (
                                            <Typography variant="h6"
                                                        key={key}
                                                        color="textSecondary">
                                                {label}
                                            </Typography>
                                        ),
                                    )}
                                </Breadcrumbs>
                            </Grid>
                            {detailsLink && (
                                <Grid item>
                                    <Tooltip title={t('linkToProductDetailsTooltip')}>
                                        <IconButton onClick={() => handleClick(detailsLink)} size="large">
                                            <DashboardIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
export default SectionHeader