import React, {useEffect, useState} from "react"
import {useTheme} from "@mui/material/styles"
import {Grid, IconButton, InputAdornment, MenuItem, TextField, Typography, useMediaQuery} from "@mui/material"
import {NavigateBefore, NavigateNext, Search} from "@mui/icons-material"
import {useAxiosBpn} from "../axios"
import {useBaseTranslation} from "../baseTranslationContext"
import {useViewport} from "../viewport/useViewport"
import {ProgressIndicator} from "../ProgressIndicator"
import GenericTable from "../generic-table/GenericTable"
import {createCustomerTableHeaders, createCustomerTableRows} from "./customerTableHelpers"

const MAIN_TOOLBAR_HEIGHT = 61
const CARD_HEADER = 97
const TABLE_HEADER = 61
const TABLE_FOOTER = 51

const ROW_LIMIT_OPTIONS = [10, 25, 50, 100, 500]

export const CustomerTable = ({refetchCustomers, handleCustomerIdClick}) => {
    const t = useBaseTranslation()
    const {breakpoints} = useTheme()
    const {height} = useViewport()
    const {spacing} = useTheme()
    const smDown = useMediaQuery(breakpoints.down("sm"))

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [textQuery, setTextQuery] = useState()
    const [tableHeight, setTableHeight] = useState(0)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(25)
    const [count, setCount] = useState(0)

    const [{data: customerData, loading: customersLoading}, getCustomers] = useAxiosBpn({
        url: "customers",
        params: {
            search: textQuery,
            limit: limit,
            offset: offset,
        },
    })

    useEffect(() => {
        setTableHeight(height - MAIN_TOOLBAR_HEIGHT - CARD_HEADER - TABLE_HEADER - TABLE_FOOTER - spacing(1))
    }, [height, spacing])

    useEffect(() => {
        getCustomers().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getCustomers().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getCustomers, refetchCustomers])

    useEffect(() => {
        if (customerData) {
            const {customers, pagination} = customerData

            if (pagination?.count) {
                setCount(pagination?.count)
            }
            const _columns = createCustomerTableHeaders(t, _handleCustomerIdClick, smDown)
            setColumns(_columns)
            const _rows = createCustomerTableRows(customers, t)
            setRows(_rows)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData, t, smDown])

    const handleSearchChange = event => {
        event.persist()
        setTextQuery(event.target.value)
    }

    const _handleCustomerIdClick = (e) => handleCustomerIdClick(e?.target?.textContent)

    const handleLimitChange = (_limit) => {
        setLimit(_limit)
        setOffset(0)
    }
    return <>
        <Grid container
              spacing={3}
              alignItems="center"
              justifyContent="flex-end">
            <Grid item>
                <TextField variant="standard"
                           style={{marginBottom: 4}}
                           onChange={handleSearchChange}
                           helperText={t("customerTableSeachHelperText")}
                           InputProps={{
                               endAdornment: (
                                   <InputAdornment position="start">
                                       <Search/>
                                   </InputAdornment>
                               ),
                           }}
                           label={t("search")}/>
            </Grid>
        </Grid>
        {customersLoading && <ProgressIndicator/>}
        <Grid container
              direction="column"
              spacing={1}>
            <Grid item
                  style={{width: "100%"}}>
                <GenericTable columns={columns}
                              rows={rows}
                              tableHeight={tableHeight}
                              size="small"/>
            </Grid>
            <Grid container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}>
                <Grid item>
                    <TextField label={t("rowsPrPage")}
                               style={{minWidth: 100}}
                               select
                               size="small"
                               variant="standard"
                               value={limit || 0}>
                        {ROW_LIMIT_OPTIONS.map((option, index) => (
                            <MenuItem key={index}
                                      value={option}
                                      onClick={() => handleLimitChange(option)}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">
                        {offset}-{(offset + limit) > count ? count : offset + limit} av {count}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={offset === 0}
                        onClick={() => setOffset(offset - limit)}
                        size="large">
                        <NavigateBefore/>
                    </IconButton>
                    <IconButton
                        disabled={(offset + limit) > count}
                        onClick={() => setOffset(offset + limit)}
                        size="large">
                        <NavigateNext/>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    </>;
}
