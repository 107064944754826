import {convertValidToToDatetime, dateFormat} from "./dateFormat"
import {parse} from "date-fns"
import {
    RECURRING_OPTION_DAY,
    RECURRING_OPTION_MONTH,
    RECURRING_OPTION_NEVER,
    RECURRING_OPTION_WEEK,
    RECURRING_OPTION_WEEKDAY,
    RECURRING_OPTION_YEAR,
    RECURRING_TYPE_DAILY,
    RECURRING_TYPE_MONTHLY,
    RECURRING_TYPE_RECURS_NOT,
    RECURRING_TYPE_WEEKDAILY,
    RECURRING_TYPE_WEEKLY,
    RECURRING_TYPE_YEARLY,
} from "../baseContext"
import {lowerFirst, toInteger} from "lodash"

export const patternIsRecurring = recurringPattern => recurringPattern?.type !== RECURRING_TYPE_RECURS_NOT
export const eventHasTicketsSold = event => !!(event?.ticketsSold || event?.testTicketsSold)
export const getMinSaleLimit = event => Math.max(event?.ticketsSold, event?.testTicketsSold, 10)
export const getStartDate = event => parse(event.startDate, "yyyy-MM-dd", new Date())
export const getEndDate = event => event.endDate ? parse(event.endDate, "yyyy-MM-dd", new Date()) : null
export const getStartTime = event => parse(event.startTime, "HH:mm:ss", new Date())
export const getEndTime = event => event?.endTime ? parse(event.endTime, "HH:mm:ss", new Date()) : null
export const getDayOfWeek = event => [parseInt(dateFormat(getStartDate(event), "e")) - 1]
export const getDayOfMonth = event => parseInt(dateFormat(getStartDate(event), "d"))
export const getMonthOfYear = event => parseInt(dateFormat(getStartDate(event), "M"))

export const getRecurringOption = type => {
    switch (type) {
        case RECURRING_TYPE_RECURS_NOT:
            return RECURRING_OPTION_NEVER
        case RECURRING_TYPE_DAILY:
            return RECURRING_OPTION_DAY
        case RECURRING_TYPE_WEEKDAILY:
            return RECURRING_OPTION_WEEKDAY
        case RECURRING_TYPE_WEEKLY:
            return RECURRING_OPTION_WEEK
        case RECURRING_TYPE_MONTHLY:
            return RECURRING_OPTION_MONTH
        case RECURRING_TYPE_YEARLY:
            return RECURRING_OPTION_YEAR
        default:
            return null
    }
}

export const getRecurringType = option => {
    switch (option) {
        case RECURRING_OPTION_NEVER:
            return RECURRING_TYPE_RECURS_NOT
        case RECURRING_OPTION_DAY:
            return RECURRING_TYPE_DAILY
        case RECURRING_OPTION_WEEKDAY:
            return RECURRING_TYPE_WEEKDAILY
        case RECURRING_OPTION_WEEK:
            return RECURRING_TYPE_WEEKLY
        case RECURRING_OPTION_MONTH:
            return RECURRING_TYPE_MONTHLY
        case RECURRING_OPTION_YEAR:
            return RECURRING_TYPE_YEARLY
        default:
            return null
    }
}

export const getDefaultPattern = () => {
    return {
        type: RECURRING_TYPE_RECURS_NOT,
        separationCount: 1,
        dayOfWeek: null,
    }
}

export const getRecurringPattern = (event, type) => {
    const defaultPattern = {
        dayOfWeek: null,
        dayOfMonth: null,
        monthOfYear: null,
        occurrence: null
    }

    switch (type) {
        case RECURRING_TYPE_RECURS_NOT:
            return {
                ...defaultPattern,
                type: RECURRING_TYPE_RECURS_NOT,
            }
        case RECURRING_TYPE_DAILY:
            return {
                ...defaultPattern,
                type: RECURRING_TYPE_DAILY,
            }
        case RECURRING_TYPE_WEEKDAILY:
            return {
                ...defaultPattern,
                type: RECURRING_TYPE_WEEKDAILY,
            }
        case RECURRING_TYPE_WEEKLY:
            return {
                ...defaultPattern,
                type: RECURRING_TYPE_WEEKLY,
                dayOfWeek: getDayOfWeek(event),
            }
        case RECURRING_TYPE_MONTHLY:
            return {
                ...defaultPattern,
                type: RECURRING_TYPE_MONTHLY,
                dayOfWeek: null,
                dayOfMonth: getDayOfMonth(event),
            }
        case RECURRING_TYPE_YEARLY:
            return {
                ...defaultPattern,
                type: RECURRING_TYPE_YEARLY,
                dayOfMonth: getDayOfMonth(event),
                monthOfYear: getMonthOfYear(event),
            }
        default:
            return null
    }
}

export const getRecurringPatternDescription = (t, pattern, startDate, endDate) => {
    const weekDays = [
        t("mondays"),
        t("tuesdays"),
        t("wednesdays"),
        t("thursdays"),
        t("fridays"),
        t("saturdays"),
        t("sundays"),
    ]

    const weekDay = [
        t("monday"),
        t("tuesday"),
        t("wednesday"),
        t("thursday"),
        t("friday"),
        t("saturday"),
        t("sunday"),
    ]

    const getOccurrenceLabel = () => {
        switch (pattern?.occurrence) {
            case 1:
                return "første"
            case 2:
                return "andre"
            case 3:
                return "tredje"
            case 4:
                return "fjerde"
            case 5:
                return "femte"
            case -1:
                return "siste"
            default:
                return null
        }
    }

    const getWeekDays = () => {
        let list = weekDays.filter((day, index) => pattern?.dayOfWeek.includes(index))

        return list.map((day, index) => {
            if (index === list.length - 1 && list.length > 1) day = ` ${t("and")} ${day}`
            else if (index !== 0) day = ` ${day}`
            return day.toLowerCase()
        })
    }

    const getWeekDay = () => weekDay[pattern?.dayOfWeek]?.toLowerCase()

    let description = t("recurs")

    if (pattern?.type === "monthly" && pattern?.dayOfWeek && pattern?.occurrence) {
        description += ` de${pattern?.dayOfWeek.length > 1 ? "" : "n"} ${getOccurrenceLabel()} ${pattern?.dayOfWeek.length > 1 ? getWeekDays() : getWeekDay() + "en"}`
        description += ` ${lowerFirst(t("every"))} ${pattern?.separationCount > 1 ? pattern?.separationCount + "." : ""} måned`
    } else {
        description +=
            pattern?.separationCount > 1
                ? ` ${lowerFirst(t("every"))} ${pattern?.separationCount}. ${lowerFirst(t(getRecurringOption(pattern?.type)))}`
                : ` ${lowerFirst(t(pattern.type))}`
    }
    if (pattern?.type === "weekly") description += ` ${lowerFirst(t("on"))} ${getWeekDays()}`
    description += ` ${lowerFirst(t("from"))} ${dateFormat(new Date(startDate))}.`

    if (endDate || pattern?.maxNumberOfOccurrences) {
        description += ` ${t("ends")}`
        if (pattern?.maxNumberOfOccurrences) {
            description +=
                ` ${lowerFirst(t("after"))} ${pattern?.maxNumberOfOccurrences} ${lowerFirst(t("occurrences"))}`
            if (endDate) description += ` ${lowerFirst(t("andOr"))}`
        }
        if (endDate) description += ` ${lowerFirst(t("latest"))} ${dateFormat(new Date(endDate))}`
        description += "."
    }

    return description
}

export const extractPattern = event => {
    if (event?.scheduleEventRecurringPattern) {
        return {
            ...event.scheduleEventRecurringPattern,
            dayOfWeek: event.scheduleEventRecurringPattern.dayOfWeek
                ? typeof event.scheduleEventRecurringPattern.dayOfWeek === "string"
                    ? event.scheduleEventRecurringPattern.dayOfWeek.split(",").map(day => toInteger(day))
                    : event.scheduleEventRecurringPattern.dayOfWeek
                : event.scheduleEventRecurringPattern.type === RECURRING_TYPE_WEEKLY ||
                (event.scheduleEventRecurringPattern.type === RECURRING_TYPE_MONTHLY && event.scheduleEventRecurringPattern.occurrence)
                    ? getDayOfWeek(event)
                    : null,
            separationCount: event.scheduleEventRecurringPattern.separationCount
                ? event.scheduleEventRecurringPattern.separationCount
                : 1,
            maxNumberOfOccurrences: event.scheduleEventRecurringPattern.maxNumberOfOccurrences
                ? event.scheduleEventRecurringPattern.maxNumberOfOccurrences
                : 0,
        }
    } else {
        return getDefaultPattern
    }
}

const eventIsRecurringWithoutEndDate = ({isRecurring, endDate}) => isRecurring && !endDate

const eventIsInFuture = ({
                             startDate,
                             endDate,
                             endTime
                         }) => convertValidToToDatetime(startDate, endDate, endTime) > Date.now()

export const eventIsActive = (e) => eventIsInFuture(e) || eventIsRecurringWithoutEndDate(e) || !!e.dateChanged
