import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import {grey} from "@mui/material/colors"
import React, {useEffect, useState} from "react"
import {useMuseumSelectorState} from "./museum-selector/museumSelectorContext"

export const defaultThemeOptions = {
    palette: {
        primary: {
            main: "#673ab7",
            light: "#9a67ea",
            dark: "#320b86",
            test: "#FFF59D",
        },
        secondary: {
            main: "#00BCD4",
        },
        background: {
            default: grey[200],
            dark: grey[300],
        },
        testOrders: {
            main: "#ffff8d",
            light: "#ffffbf",
            dark: "#cacc5d",
        },
        charts: {
            bar: {
                main: "#43a047",
                light: "#76d275",
                dark: "#00701a",
            },
        },
    },
    props: {
        MuiTextField: {
            variant: "filled",
            color: "secondary",
        },
        MuiFormLabel: {
            color: "secondary",
        },
        MuiSelect: {
            variant: "filled",
            color: "secondary",
        },
    },
    typography: {
      fontFamily: 'Akkurat, sans-serif'  ,
    },
    drawerWidth: 240,
}

const generateClassName = createGenerateClassName({
    seed: "ekulturMuseumsbillettAdmin",
})

export const Theme = ({children}) => {
    const [themeOptions, setThemeOptions] = useState(defaultThemeOptions)
    const [appTheme, setAppTheme] = useState(createTheme(defaultThemeOptions))
    const {selectedMuseum} = useMuseumSelectorState()

    useEffect(() => {
        setAppTheme(createTheme(themeOptions))
    }, [themeOptions])

    useEffect(() => {
        if (selectedMuseum?.primary_color) {
            const {primary_color, dark_color, light_color} = selectedMuseum
            setThemeOptions(prev => ({
                ...prev,
                palette: {
                    ...prev.palette,
                    primary: {
                        ...prev.palette.primary,
                        main: primary_color,
                        dark: dark_color,
                        light: light_color,
                    },
                },
            }))
        }
    }, [selectedMuseum])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <StylesProvider generateClassName={generateClassName}>
                    {children}
                </StylesProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
