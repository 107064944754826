import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useMuseumSelectorState } from "../../../app/museum-selector/museumSelectorContext";
import { useAxiosBpn } from "../../../app/axios";
import useCustomSnackbar from "../../../app/components/useCustomSnackbar";
import { lowerFirst } from "lodash";
import { ProgressIndicator } from "../../../app/ProgressIndicator";
import { useBaseTranslation } from "../../../app/baseTranslationContext";
import GoogleTagManagerFields from "./GoogleTagManagerFields";

const GoogleTagManagerSettings = ({umbrellaSettings, getMuseumSettings}) => {
    const t = useBaseTranslation()
    const {selectedMuseum, umbrellaId, umbrellaName} = useMuseumSelectorState()
    const snackbar = useCustomSnackbar()

    const [
        {
            data: umbrellaPutData,
            loading: umbrellaPutLoading,
            error: umbrellaPutError,
        }, putUmbrellaMuseumSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    useEffect(() => {
        if (umbrellaPutData) {
            snackbar.showSuccess(`${t("googleTagManagerSettings")} for ${umbrellaName} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }

    }, [selectedMuseum, t, umbrellaName, snackbar, getMuseumSettings, umbrellaPutData])

    useEffect(() => {
        if (umbrellaPutError) {
            snackbar.showError(t(umbrellaPutError.message))
        }
    }, [t, snackbar, umbrellaPutError])

    const handleUmbrellaUrlSave = (gtmId) => {
        const id = umbrellaSettings?.museumId ? umbrellaSettings.museumId : umbrellaId
        putUmbrellaMuseumSettings({
            url: `museum_settings/${id}`,
            data: {
                ...umbrellaSettings,
                museumName: umbrellaName,
                gtmId: gtmId,
            },
        })
    }

    return (
      <>
          <Grid container
                spacing={3}
                direction="column">
              {umbrellaPutLoading
                ? <ProgressIndicator/>
                : <>

                    <Grid container
                          item
                          xs={12}>
                        <GoogleTagManagerFields handleSaveGoogleTagManagerSettings={handleUmbrellaUrlSave}
                                                museumSettings={umbrellaSettings}
                                                readonly={false}/>
                    </Grid>
                </>
              }
          </Grid>
      </>
    )
}

export default GoogleTagManagerSettings
