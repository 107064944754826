import React from "react"
import Grid from "@mui/material/Grid"
import {Button, Typography} from "@mui/material"
import {ArrowDropDown} from "@mui/icons-material"
import ReactExport from "react-data-export"
import {useBaseTranslation} from "../baseTranslationContext"
import ProductSelectorAutocomplete from "../menu/ProductSelectorAutocomplete"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const RevenueCardActions = ({
                                excelDataSet,
                                groupBy,
                                handleSelectGroupByClick,
                                frequency,
                                productsOptions,
                                productsLoading,
                                selectedProduct,
                                setSelectedProduct,
                                handleSelectDateFrequencyClick,
                                dateFilterLabel,
                                handleDateFilterDialogOpen,
                                excelFileName = "Omsetning Museumsbillett",
                            }) => {
    const t = useBaseTranslation()

    return (
        <Grid container
              spacing={3}
              alignItems="center"
              justifyContent="flex-end">
            {excelDataSet && (
                <Grid item>
                    <ExcelFile filename={excelFileName}
                               element={<Button>{t("downloadXLSX")}</Button>}>
                        <ExcelSheet dataSet={excelDataSet}
                                    name={excelFileName}/>
                    </ExcelFile>
                </Grid>
            )}
            {!productsLoading && productsOptions && (
                <Grid item>
                    <Grid container
                          alignItems="center">
                        <Grid item>
                            <Typography variant="caption"
                                        color="textSecondary">
                                {t("productSelector")}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ProductSelectorAutocomplete options={productsOptions}
                                                         selectedProduct={selectedProduct}
                                                         setSelectedProduct={setSelectedProduct}/>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {groupBy && (
                <Grid item>
                    <Grid container
                          alignItems="center">
                        <Grid item>
                            <Typography variant="caption"
                                        color="textSecondary">
                                {t("groupOn")}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleSelectGroupByClick}
                                    color="primary"
                                    endIcon={<ArrowDropDown/>}>
                                {t(groupBy)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {frequency && (
                <Grid item>
                    <Grid container
                          alignItems="center">
                        <Grid item>
                            <Typography variant="caption"
                                        color="textSecondary">
                                {t("dateGrouping")}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleSelectDateFrequencyClick}
                                    color="primary"
                                    endIcon={<ArrowDropDown/>}>
                                {t(frequency)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {handleDateFilterDialogOpen && (
                <Grid item>
                    <Button onClick={handleDateFilterDialogOpen}
                            color="primary"
                            endIcon={<ArrowDropDown/>}>
                        {dateFilterLabel}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default RevenueCardActions