import React, {useEffect, useState} from "react"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import {Grid} from "@mui/material"
import {
    createVisitorsTableHeaders,
    createVisitorsTableRows,
    createVisitorStatsChartData,
    createVisitorStatsExcelDataset,
} from "./visitorsHelperFunctions"
import RevenueCardActions from "../revenue/RevenueCardActions"
import GenericTable from "../generic-table/GenericTable"
import StackedBarChart from "../components/StackedBarChart"
import {getDateLabelBasedOnFequency} from "../helper-functions/dateFormat"
import DashboardCard from "../components/DashboardCard"

const VisitorStatsCard = ({
                              data,
                              loading,
                              testOrders,
                              productsOptions,
                              productsLoading,
                              selectedProduct,
                              setSelectedProduct,
                              handleSelectGroupByClick,
                              handleSelectDateFrequencyClick,
                              handleDateFilterDialogOpen,
                              dateFilterLabel,
                              frequency,
                              groupBy,
                          }) => {
    const t = useBaseTranslation()

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [excelDataSet, setExcelDataSet] = useState()
    const [uniqueGroups, setUniqueGroups] = useState()
    const [chartData, setChartData] = useState()

    useEffect(() => {
        if (data) {
            const {groupedVisitorStats} = data

            if (groupedVisitorStats) {
                const {columns: _columns, data: _data} = groupedVisitorStats

                const headers = createVisitorsTableHeaders(_columns, t, frequency)
                setColumns(headers)

                const _rows = createVisitorsTableRows(_data, headers)
                setRows(_rows)

                const _uniqueGroups = [...new Set(_rows.map(r => r[headers[0].field]))]
                setUniqueGroups(_uniqueGroups)

                let _headers = [...headers]
                _headers.splice(0, 1)

                const _uniqueDates = _headers.map(h => h.field)
                setChartData(createVisitorStatsChartData(_rows, _uniqueDates, _uniqueGroups, groupBy))
            } else {
                setColumns([])
                setRows([])
                setUniqueGroups([])
                setChartData([])
            }
        } else {
            setColumns([])
            setRows([])
            setUniqueGroups([])
            setChartData([])
        }
    }, [data, t, frequency, groupBy, loading])

    useEffect(() => {
        if (columns) {
            const dataSet = createVisitorStatsExcelDataset(columns, rows)
            setExcelDataSet(dataSet)
        }
    }, [rows, columns])

    return (
        <DashboardCard title={t("visitorNumbers")}
                       testOrders={testOrders}
                       action={
                           <RevenueCardActions excelDataSet={excelDataSet}
                                               groupBy={groupBy}
                                               handleDateFilterDialogOpen={handleDateFilterDialogOpen}
                                               dateFilterLabel={dateFilterLabel}
                                               frequency={frequency}
                                               productsOptions={productsOptions}
                                               productsLoading={productsLoading}
                                               selectedProduct={selectedProduct}
                                               setSelectedProduct={setSelectedProduct}
                                               handleSelectDateFrequencyClick={handleSelectDateFrequencyClick}
                                               handleSelectGroupByClick={handleSelectGroupByClick}
                                               excelFileName="Besøkstall Museumsbillett"/>
                       }>
            {loading
             ? <ProgressIndicator/>
             : <Grid container
                     direction="column"
                     spacing={1}>
                 <Grid container>
                     <Grid item
                           xs={12}>
                         <StackedBarChart data={chartData}
                                          groups={uniqueGroups}
                                          xAxisLabel={t(getDateLabelBasedOnFequency(frequency))}
                                          yAxisLabel={t("visitorNumbers")}/>
                     </Grid>
                     <Grid item
                           xs={12}
                           style={{width: "100%"}}>
                         <GenericTable columns={columns}
                                       rows={rows}/>
                     </Grid>
                 </Grid>
             </Grid>
            }
        </DashboardCard>
    )
}

export default VisitorStatsCard