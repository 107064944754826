import React, {useEffect, useState} from "react"
import {CheckCircleOutline, ErrorOutline, InfoOutlined, WarningOutlined} from "@mui/icons-material"
import {ClickAwayListener, IconButton, Paper, Popper} from "@mui/material"

const AlertButton = ({severity = "info", children}) => {
    const [alertPopperOpen, setAlertPopperOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState()
    const [color, setColor] = useState()

    useEffect(() => {
        switch (severity) {
            case "error":
                return setColor("#ef5350")
            case "warning":
                return setColor("#ffa117")
            case "success":
                return setColor("#5cb660")
            default:
                return setColor("#1ab1f5")
        }
    }, [severity])

    const getIcon = () => {
        switch (severity) {
            case "error":
                return <ErrorOutline/>
            case "warning":
                return <WarningOutlined />
            case "success":
                return <CheckCircleOutline />
            default:
                return <InfoOutlined/>
        }
    }

    const handleAlertButtonClick = (event) => {
        setAnchorEl(event.currentTarget)
        setAlertPopperOpen(!alertPopperOpen)
    }

    return children
           ? <>
               <IconButton style={{color: color}} onClick={handleAlertButtonClick} size="large">{getIcon()}</IconButton>
               <Popper open={alertPopperOpen}
                       anchorEl={anchorEl}
                       style={{zIndex: 2000}}
                       placement="right">
                   <ClickAwayListener onClickAway={() => setAlertPopperOpen(false)}>
                       <Paper elevation={8}
                              style={{padding: 24, maxWidth: 600}}>
                           {children}
                       </Paper>
                   </ClickAwayListener>
               </Popper>
           </>
           : null;
}

export default AlertButton