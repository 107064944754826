import React from "react"
import {FormLabel, Grid, TextField, Tooltip} from "@mui/material"
import {ArrowForwardIos} from "@mui/icons-material"
import {lowerFirst} from "lodash"
import {
    VOUCHER_SETTINGS_TYPE_DEPARTMENT,
    VOUCHER_SETTINGS_TYPE_MERGED,
    VOUCHER_SETTINGS_TYPE_MUSEUM,
    VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP,
    VOUCHER_SETTINGS_TYPE_UMBRELLA,
} from "../../../app/helper-functions/voucherStatuses"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useTheme} from "@mui/material/styles"

const gridSizes = {xs: 12, sm: 5, md: 4, lg: 3, xl: 2}

const GridMainLabel = ({label, text}) => (
    <Grid item {...gridSizes}>
        <Grid container
              justifyContent="flex-end"
              alignItems="center">
            <Grid item>
                <Tooltip title={text}>
                    <FormLabel>{label}</FormLabel>
                </Tooltip>
            </Grid>
        </Grid>
    </Grid>
)

const TextFieldGridItem = ({name, value, onChange, hideArrow = false, disabled = false, highlightColor = null}) => (
    <Grid item
          xs>
        <Grid container
              alignItems="center"
              spacing={1}>
            {!hideArrow && (
                <Grid item>
                    <ArrowForwardIos style={{marginTop: 8}}
                                     color="disabled"/>
                </Grid>
            )}

            <Grid item
                  xs>
                <TextField name={name}
                           size="small"
                           fullWidth
                           style={{backgroundColor: highlightColor}}
                           InputLabelProps={{disabled: true}}
                           disabled={disabled}
                           variant="outlined"
                           value={value || ""}
                           onChange={onChange}>
                </TextField>
            </Grid>
        </Grid>
    </Grid>
)

const VismaVoucherSettingsGridRow = ({
                                         handleSubmit,
                                         formLabel,
                                         formHelperText,
                                         textFieldName,
                                         textFieldLabel,
                                         textFieldProperty,
                                         handleChange,
                                         selectedProductGroup,
                                         selectedMuseum,
                                         selectedDepartment,
                                     }) => {
    const {umbrellaName} = useMuseumSelectorState()
    const {palette} = useTheme()

    const getHighlightColor = (type) => {
        const fieldValue = textFieldProperty(type, textFieldName)
        const activeValue = textFieldProperty(VOUCHER_SETTINGS_TYPE_MERGED, textFieldName)

        if (fieldValue && fieldValue === activeValue) return palette?.primary?.light
    }

    return (
        <Grid container
              style={{maxWidth: 1200}}
              spacing={2}
              alignItems="center"
              item>
            <GridMainLabel label={formLabel}
                           text={formHelperText}/>
            <Grid item
                  xl={12 - (gridSizes.xl * 2)}
                  lg={12 - (gridSizes.lg * 2)}
                  md={12 - (gridSizes.md * 2)}
                  sm={12 - (gridSizes.sm * 2)}
                  xs={12}>
                <form noValidate
                      autoComplete="off"
                      style={{display: "inline-flex", width: "100%"}}
                      onBlur={handleSubmit}>
                    <Grid container
                          alignItems="center"
                          spacing={1}>
                        <TextFieldGridItem name={textFieldName}
                                           hideArrow={true}
                                           highlightColor={getHighlightColor(VOUCHER_SETTINGS_TYPE_UMBRELLA)}
                                           label={`${textFieldLabel} for ${umbrellaName}`}
                                           value={textFieldProperty(VOUCHER_SETTINGS_TYPE_UMBRELLA, textFieldName)}
                                           onChange={e => handleChange(e, VOUCHER_SETTINGS_TYPE_UMBRELLA)}/>
                        {selectedProductGroup && (
                            <TextFieldGridItem name={textFieldName}
                                               highlightColor={getHighlightColor(VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP)}
                                               label={`${textFieldLabel} for ${lowerFirst(selectedProductGroup.name)}`}
                                               value={textFieldProperty(VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP,
                                                   textFieldName)}
                                               onChange={e => handleChange(e, VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP)}/>
                        )}
                        {selectedMuseum && (
                            <TextFieldGridItem name={textFieldName}
                                               highlightColor={getHighlightColor(VOUCHER_SETTINGS_TYPE_MUSEUM)}
                                               label={`${textFieldLabel} for ${selectedMuseum.name}`}
                                               value={textFieldProperty(VOUCHER_SETTINGS_TYPE_MUSEUM, textFieldName)}
                                               onChange={e => handleChange(e, VOUCHER_SETTINGS_TYPE_MUSEUM)}/>
                        )}
                        {selectedDepartment && (
                            <TextFieldGridItem name={textFieldName}
                                               highlightColor={getHighlightColor(VOUCHER_SETTINGS_TYPE_DEPARTMENT)}
                                               label={`${textFieldLabel} for ${selectedDepartment.name}`}
                                               value={textFieldProperty(VOUCHER_SETTINGS_TYPE_DEPARTMENT,
                                                   textFieldName)}
                                               onChange={e => handleChange(e, VOUCHER_SETTINGS_TYPE_DEPARTMENT)}/>
                        )}
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}

export default VismaVoucherSettingsGridRow