import React, {createContext, useContext, useEffect, useReducer} from "react"
import {useMenuTranslation} from "./menu/menuTranslationContext"
import { ENTRANCE_BASE_URL, EVENTS_BASE_URL, SEASONAL_PASSES_BASE_URL } from "./Routes";

export const SET_CURRENT_PRODUCT_TYPE = "base/setCurrentProductType"
export const SET_CURRENT_BASE_URL = "base/setCurrentBaseUrl"
export const SET_NEW_PRODUCT_BUTTON = "base/setNewProductButton"
export const FORCE_FETCH = "base/forceFetch"

export const PRODUCT_TYPE_EVENT = "event"
export const PRODUCT_TYPE_SEASONAL_PASS = "seasonal_pass"
export const PRODUCT_TYPE_ENTRANCE_TICKET = "entrance_ticket"

export const RECURRING_TYPE_RECURS_NOT = "recursNot"
export const RECURRING_TYPE_DAILY = "daily"
export const RECURRING_TYPE_WEEKDAILY = "weekdaily"
export const RECURRING_TYPE_WEEKLY = "weekly"
export const RECURRING_TYPE_MONTHLY = "monthly"
export const RECURRING_TYPE_YEARLY = "yearly"

export const RECURRING_EVENT_TYPES = [
    RECURRING_TYPE_RECURS_NOT,
    RECURRING_TYPE_DAILY,
    RECURRING_TYPE_WEEKDAILY,
    RECURRING_TYPE_WEEKLY,
    RECURRING_TYPE_MONTHLY,
    RECURRING_TYPE_YEARLY
]

export const RECURRING_OPTION_NEVER = "never"
export const RECURRING_OPTION_DAY = "day"
export const RECURRING_OPTION_WEEKDAY = "weekday"
export const RECURRING_OPTION_WEEK = "week"
export const RECURRING_OPTION_MONTH = "month"
export const RECURRING_OPTION_YEAR = "year"

export const RECURRING_OPTIONS = [
    RECURRING_OPTION_NEVER,
    RECURRING_OPTION_DAY,
    RECURRING_OPTION_WEEKDAY,
    RECURRING_OPTION_WEEK,
    RECURRING_OPTION_MONTH,
    RECURRING_OPTION_YEAR
]

const BaseStateContext = createContext(undefined)
const BaseDispatchContext = createContext(undefined)

const initialState = {
    currentProductType: null,
    currentBaseUrl: "/",
    newProductButton: {name: undefined, disabled: true},
    forceFetch: false
}

const BaseContextReducer = (state, action) => {
    switch (action.type) {
        case SET_CURRENT_PRODUCT_TYPE:
            return {
                ...state,
                currentProductType: action.payload
            }
        case SET_CURRENT_BASE_URL:
            return {
                ...state,
                currentBaseUrl: action.payload
            }
        case SET_NEW_PRODUCT_BUTTON:
            return {
                ...state,
                newProductButton: action.payload
            }
        case FORCE_FETCH:
            return {
                ...state,
                forceFetch: action.payload
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const BaseContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(BaseContextReducer, initialState)
    const t = useMenuTranslation()

    useEffect(() => {
        let newProductButton
        let currentProductType

        switch (state.currentBaseUrl) {
            case EVENTS_BASE_URL:
                newProductButton = {name: t("newEvent"), disabled: false}
                currentProductType = PRODUCT_TYPE_EVENT
                break
            case ENTRANCE_BASE_URL:
                newProductButton = {name: t("newEntrance"), disabled: true}
                currentProductType = PRODUCT_TYPE_ENTRANCE_TICKET
                break
            case SEASONAL_PASSES_BASE_URL:
                newProductButton = {name: t("newSeasonalPass"), disabled: true}
                currentProductType = PRODUCT_TYPE_SEASONAL_PASS
                break
            default:
                newProductButton = {name: t("newProduct"), disabled: true}
                currentProductType = null
        }
        dispatch({type: SET_NEW_PRODUCT_BUTTON, payload: newProductButton})
        dispatch({type: SET_CURRENT_PRODUCT_TYPE, payload: currentProductType})
    }, [state.currentBaseUrl, t])

    return (
        <BaseStateContext.Provider value={state}>
            <BaseDispatchContext.Provider value={dispatch}>
                {children}
            </BaseDispatchContext.Provider>
        </BaseStateContext.Provider>
    )
}

export let useBaseContextState = () => useContext(BaseStateContext)
export const useBaseContextDispatch = () => useContext(BaseDispatchContext)