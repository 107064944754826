import React, {createContext, useContext} from "react"
import {useTranslation} from "react-i18next"
import {ProgressIndicator} from "../app/ProgressIndicator"

const BaseTranslationContext = createContext(undefined)

export const BaseTranslationProvider = ({children}) => {
    const {t, ready} = useTranslation("bpn.base", {useSuspense: false})

    if (ready) {
        return (
            <BaseTranslationContext.Provider value={t}>
                {children}
            </BaseTranslationContext.Provider>
        )
    } else {
        return <ProgressIndicator/>
    }
}

export const useBaseTranslation = () => {
    return useContext(BaseTranslationContext)
}