import React from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import CardHeader from "@mui/material/CardHeader"
import {Card, CardContent} from "@mui/material"
import {ProgressIndicator} from "../ProgressIndicator"
import {OrderTableMini} from "../order-table/OrderTableMini"

const CustomerDetailsDialogOrders = ({loading, orders}) => {
    const t = useBaseTranslation()

    return (
        <Card square
              variant="outlined">
            <CardHeader title={t("orderHistory")}
                        titleTypographyProps={{color: "primary"}}/>
            <CardContent>
                {loading
                 ? <ProgressIndicator/>
                 : orders?.length
                   ? <OrderTableMini orders={orders}/>
                   : <> Ingen ordrehistorikk! </>}
            </CardContent>
        </Card>
    )
}

export default CustomerDetailsDialogOrders
