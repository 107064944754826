import React from "react"
import {Redirect, Route, Switch} from "react-router-dom"
import {useAuthsState} from "../../app/auths/authsContext"
import {ENTRANCE_BASE_URL} from "../../app/Routes"
import {EntranceCalendar} from "./entrance-calendar/EntranceCalendar"
import {ProductEditorTranslationProvider} from "../../app/product-editor/productEditorTranslationContext"
import ProductEditor from "../../app/product-editor/ProductEditor"
import {PRODUCT_TYPE_ENTRANCE_TICKET} from "../../app/baseContext"
import {EntrancesList} from "./entrance-list/EntrancesList"
import {EntrancesOverview} from "./entrance-overview/EntrancesOverview"
import ProductDetails from "../../app/product-details/ProductDetails"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {ROLES_VALUES} from "../../app/menu/MuseumSelector"


export const EntranceRoutes = () => {
    const {userIsAuthenticated} = useAuthsState()
    const {selectedMuseum} = useMuseumSelectorState()

    const getRoutes = () => {
        if (userIsAuthenticated) {
            return (
                <>
                    <Route exact
                           path={ENTRANCE_BASE_URL}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <EntrancesOverview/>
                            : <Redirect to={`${ENTRANCE_BASE_URL}/list`}/>}
                    </Route>
                    <Route path={`${ENTRANCE_BASE_URL}/list`}>
                        <EntrancesList/>
                    </Route>
                    <Route path={`${ENTRANCE_BASE_URL}/calendar`}>
                        <EntranceCalendar/>
                    </Route>
                    <Route path={`${ENTRANCE_BASE_URL}/:id/details`}>
                        <ProductDetails type={PRODUCT_TYPE_ENTRANCE_TICKET}/>
                    </Route>
                    <Route path={`${ENTRANCE_BASE_URL}/:id/edit`}>
                        {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR
                            ? <ProductEditorTranslationProvider>
                                <ProductEditor type={PRODUCT_TYPE_ENTRANCE_TICKET}/>
                            </ProductEditorTranslationProvider>
                            : <Redirect to={`${ENTRANCE_BASE_URL}/list`}/>}
                    </Route>
                </>
            )
        } else {
            return null
        }
    }

    return (
        <Switch>
            {getRoutes()}
        </Switch>
    )
}
