import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import DashboardCard from "../../app/components/DashboardCard"
import {dateFormat} from "../../app/helper-functions/dateFormat"
import GenericTable from "../../app/generic-table/GenericTable"
import OrderDetailsDialog from "../../app/order-details/OrderDetailsDialog"
import MaximizeTableButton from "../../app/components/MaximizeTableButton"

const VouchersOrderLinesWithNoVouchersCard = ({orderLinesWithNoVouchers, loading, testOrders}) => {
    const t = useBaseTranslation()

    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [selectedOrderId, setSelectedOrderId] = useState()
    const [orderDialogOpen, setOrderDialogOpen] = useState(false)
    const [tableMaximized, setTableMaximized] = useState(false)

    useEffect(() => {
        const createTableHeaders = (handleOrderIdClick) => [
            {field: "orderId", headerName: t("orderId"), onClick: handleOrderIdClick},
            {field: "orderDatetime", headerName: t("date"), type: "datetime", flex: 2},
            {field: "museumName", headerName: t("museum")},
            {field: "paymentSubtype", headerName: t("cardType")},
            {field: "paymentType", headerName: t("paymentType")},
            {field: "productGroup", headerName: t("productGroup")},
        ]

        const createTableRows = (_orderLines) => {
            return _orderLines?.map(({
                                         orderId,
                                         orderDatetime,
                                         museumName,
                                         productGroup,
                                         paymentType,
                                         paymentSubtype,
                                     }) => ({
                orderId: orderId,
                orderDatetime: dateFormat(new Date(orderDatetime), "Pp"),
                museumName: museumName,
                productGroup: productGroup,
                paymentType: t(paymentType),
                paymentSubtype: paymentSubtype,
            }))
        }

        if (orderLinesWithNoVouchers) {
            setColumns(createTableHeaders(handleOrderIdClick))
            setRows(createTableRows(orderLinesWithNoVouchers))
        }
    }, [orderLinesWithNoVouchers, t])

    useEffect(() => {
        if (selectedOrderId) {
            setOrderDialogOpen(true)
        }
    }, [selectedOrderId])

    const handleOrderIdClick = (e) => {
        setSelectedOrderId(e?.target?.textContent)
    }

    const handleOrderDialogClose = () => {
        setOrderDialogOpen(false)
        setSelectedOrderId(null)
    }

    return (
        <DashboardCard title={t("orderLinesWithNoVouchers")}
                       testOrders={testOrders}
                       loading={loading}
                       linearProgress={false}>
            <MaximizeTableButton rows={rows} tableMaximized={tableMaximized} setTableMaximized={setTableMaximized}/>
            <GenericTable columns={columns}
                          rows={tableMaximized ? rows : rows.slice(0, 5)}
                          size="small"/>
            <OrderDetailsDialog orderId={selectedOrderId}
                                open={orderDialogOpen}
                                handleClose={handleOrderDialogClose}/>
        </DashboardCard>
    )
}

export default VouchersOrderLinesWithNoVouchersCard

