import React, {useEffect, useState} from "react"
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Divider,
    Grid,
    Popper,
    Typography,
} from "@mui/material"
import {endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear} from "date-fns"
import MuiDatePicker from "./MuiDatePicker"
import makeStyles from '@mui/styles/makeStyles';
import {dateFormat} from "../helper-functions/dateFormat"
import {toSentenceCase} from "../helper-functions/stringUtility"

const ButtonProps = {
    variant: "text",
    size: "small",
    color: "primary",
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(1),
    },
    dateFilterPaper: {
        zIndex: 2,
    },
}))

const DateFilterDialog = (({t, anchorEl, open, handleClose, title}) => {
    const classes = useStyles()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    useEffect(() => {
        if (endDate < startDate) setEndDate(startDate)
    }, [startDate, endDate])

    const handleAllDatesClick = () => {
        handleClose()
    }

    const handleLastYearClick = () => {
        const lastYear = new Date(new Date().getFullYear() - 1, 0, 1)
        const after = startOfYear(lastYear).getTime()
        const before = endOfYear(lastYear).getTime()
        const label = `${t("wholeOf")} ${dateFormat(lastYear, "y")}`
        handleClose(after, before, label)
    }

    const handleThisYearClick = () => {
        const now = new Date()
        const after = startOfYear(now).getTime()
        const before = endOfYear(now).getTime()
        const label = `${t("thusFar")} ${t("in").toLowerCase()} ${dateFormat(now, "y")}`
        handleClose(after, before, label)
    }

    const handleThisMonthClick = () => {
        const now = new Date()
        const after = startOfMonth(now).getTime()
        const before = endOfMonth(now).getTime()
        const label = `${toSentenceCase(dateFormat(now, "MMMM"))} ${t("in").toLowerCase()} ${dateFormat(now, "y")}`
        handleClose(after, before, label)
    }

    const handleThisWeekClick = () => {
        const now = new Date()
        const after = startOfWeek(now, {weekStartsOn: 1}).getTime()
        const before = endOfWeek(now, {weekStartsOn: 1}).getTime()
        const label = `${t("week")} ${dateFormat(now, "w")} ${t("in").toLowerCase()} ${dateFormat(now, "y")}`
        handleClose(after, before, label)
    }

    const handleToDayClick = () => {
        const now = new Date()
        const after = startOfDay(now).getTime()
        const before = endOfDay(now).getTime()
        const label = dateFormat(now, "P")
        handleClose(after, before, label)
    }

    const handleButtonClick = () => {
        const after = startOfDay(startDate).getTime()
        const before = endOfDay(endDate).getTime()
        const label = `${dateFormat(startDate, "P")}-${dateFormat(endDate, "P")}`
        handleClose(after, before, label)
    }

    return (
        <Popper open={open}
                anchorEl={anchorEl}
                className={classes.dateFilterPaper}>
            <ClickAwayListener onClickAway={() => handleClose()}>
                <Card raised>
                    <CardHeader title={title || t("filterOnDate")}/>
                    <CardContent>
                        <Divider/>
                        <Grid container
                              spacing={3}>
                            <Grid item>
                                <Button onClick={handleToDayClick} {...ButtonProps}>
                                    {t("toDay")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleThisWeekClick} {...ButtonProps}>
                                    {t("thisWeek")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleThisMonthClick} {...ButtonProps}>
                                    {t("thisMonth")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleThisYearClick} {...ButtonProps}>
                                    {t("thisYear")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleLastYearClick} {...ButtonProps}>
                                    {t("lastYear")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleAllDatesClick} {...ButtonProps}>
                                    {t("allDates")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container
                              className={classes.container}
                              alignItems="center"
                              justifyContent="space-around">
                            <Grid item>
                                <MuiDatePicker selected={startDate}
                                               size="small"
                                               inputVariant="outlined"
                                               color="primary"
                                               label={t("fromDate")}
                                               onChange={(date) => setStartDate(date)}/>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4">-</Typography>
                            </Grid>
                            <Grid item>
                                <MuiDatePicker selected={endDate}
                                               size="small"
                                               minDate={startDate}
                                               inputVariant="outlined"
                                               label={t("toDate")}
                                               onChange={(date) => setEndDate(date)}/>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleButtonClick}
                                        style={{marginTop: "4px"}}>
                                    {t("update")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </CardContent>
                </Card>
            </ClickAwayListener>
        </Popper>
    )
})

export default DateFilterDialog