import {
    Button,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Collapse from "@mui/material/Collapse"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import ButtonWithColorPicker from "./ButtonWithColorPicker"
import ButtonWithPushSettingsUrl from "./ButtonWithPushSettingsUrl"
import ExpandCardButton from "./ButtonWithExpandCard"
import HelpButton from "./HelpButton"
import {Settings} from "@mui/icons-material"
import AlertButton from "./AlertButton"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: theme.spacing(1),
    },
}))

const EditorCardModule = ({
                              title,
                              children,
                              expanded = false,
                              helperTextComponent,
                              color,
                              colorButtonTooltip = "",
                              handleColorChange,
                              alert,
                              settingsUrl,
                              settingsDialogTitle,
                              settingsDialogComponent,
                              onSettingsDialogClose,
                              subheader,
                              action: actions,
                              handleMouseEnter,
                          }) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const [_expanded, setExpanded] = useState(expanded)
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)
    const [alertComponent, setAlertComponent] = useState()
    const [alertSeverity, setAlertSeverity] = useState()

    useEffect(() => {
        setAlertComponent(alert?.component)
        setAlertSeverity(alert?.severity)
    }, [alert])

    const handleSettingsDialogClose = () => {
        if (onSettingsDialogClose) onSettingsDialogClose()
        setSettingsDialogOpen(false)
    }

    function getTitle() {
        return (
            <Typography variant="h6"
                        style={{cursor: "pointer"}}
                        onClick={() => setExpanded(!_expanded)}>
                {t(title)}
            </Typography>
        )
    }

    return (
        <Card className={classes.root}
              square
              onClick={() => handleMouseEnter && handleMouseEnter(title)}
              elevation={2}>
            <CardHeader title={getTitle()}
                        subheader={subheader}
                        subheaderTypographyProps={{variant: "body2"}}
                        action={
                            <>
                                {alertComponent && <AlertButton severity={alertSeverity}>{alertComponent}</AlertButton>}
                                {handleColorChange && <ButtonWithColorPicker colorButtonTooltip={colorButtonTooltip}
                                                                             handleColorChange={handleColorChange}
                                                                             color={color}/>}
                                {helperTextComponent && <HelpButton>{helperTextComponent}</HelpButton>}
                                {settingsUrl && <ButtonWithPushSettingsUrl settingsUrl={settingsUrl}/>}
                                {settingsDialogComponent && (
                                    <IconButton onClick={() => setSettingsDialogOpen(true)} size="large">
                                        <Settings/>
                                    </IconButton>
                                )}
                                <ExpandCardButton expanded={_expanded}
                                                  handleExpandClick={() => setExpanded(!_expanded)}/>
                            </>
                        }/>
            <Collapse in={_expanded}>
                <CardContent>
                    {children}
                </CardContent>
            </Collapse>
            {actions && (
                <CardActions>
                    {actions}
                </CardActions>
            )}
            <Dialog open={settingsDialogOpen}
                    fullWidth
                    maxWidth="xl"
                    onClose={handleSettingsDialogClose}>
                <DialogTitle>{settingsDialogTitle}</DialogTitle>
                <DialogContent>{settingsDialogComponent}</DialogContent>
                <DialogActions>
                    <Button onClick={handleSettingsDialogClose}>Lukk</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}

export default EditorCardModule