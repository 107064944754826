import React, {useEffect, useState} from "react"
import {FormControlLabel, Grid, Switch} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {lowerFirst} from "lodash"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import NewsletterInputFields from "./NewsletterInputFields"
import Typography from "@mui/material/Typography"

/**
 *
 * @param umbrellaSettings { Partial<MuseumSettings> }
 * @param museumSettings { Partial<MuseumSettings> }
 * @param getMuseumSettings { () => void }
 * @return {JSX.Element}
 * @constructor
 */
const NewsletterSettings = ({umbrellaSettings, museumSettings, getMuseumSettings}) => {
    const t = useBaseTranslation()
    const {selectedMuseum, umbrellaId, umbrellaName} = useMuseumSelectorState()
    const [overrideUmbrellaSetting, setOverrideUmbrellaSetting] = useState(false)
    const snackbar = useCustomSnackbar()

    const [
        {
            data: umbrellaPutData,
            error: umbrellaPutError,
        }, putUmbrellaMuseumSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    const [
        {
            data: museumPutData,
            loading: museumPutLoading,
            error: museumPutError,
        }, putMuseumSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    useEffect(() => {
        if (museumSettings?.mcApiKey || museumSettings?.mcAudienceId || museumSettings?.makeApiKey || museumSettings?.makeClientId || museumSettings?.makeListId) {
            setOverrideUmbrellaSetting(true)
        } else {
            setOverrideUmbrellaSetting(false)
        }
    }, [museumSettings])

    useEffect(() => {
        if (umbrellaPutData) {
            snackbar.showSuccess(`${t("mailchimpSettings")} for ${umbrellaName} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
        if (museumPutData) {
            snackbar.showSuccess(`${t("mailchimpSettings")} for ${selectedMuseum?.name} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
    }, [selectedMuseum, museumPutData, t, umbrellaName, snackbar, getMuseumSettings, umbrellaPutData])

    useEffect(() => {
        if (museumPutError) {
            snackbar.showError(t(museumPutError.message))
        }
        if (umbrellaPutError) {
            snackbar.showError(t(umbrellaPutError.message))
        }
    }, [museumPutError, t, snackbar, umbrellaPutError])

    const handleUmbrellaUrlSave = ({ mcAudienceId, mcApiKey, makeClientId, makeApiKey, makeListId, newsletterProvider }) => {
        const id = umbrellaSettings?.museumId ? umbrellaSettings.museumId : umbrellaId
        putUmbrellaMuseumSettings({
            url: `museum_settings/${id}`,
            data: {
                ...umbrellaSettings,
                museumName: umbrellaName,
                mcAudienceId: mcAudienceId,
                mcApiKey: mcApiKey,
                makeClientId: makeClientId,
                makeApiKey: makeApiKey,
                makeListId: makeListId,
                newsletterProvider: newsletterProvider,
            },
        })
    }

    const handleMuseumUrlSave = ({ mcAudienceId, mcApiKey, makeClientId, makeApiKey, makeListId, newsletterProvider }) => {
        const id = museumSettings?.museumId ? museumSettings.museumId : selectedMuseum?.id
        const updatedMuseumSettings = {
            ...museumSettings,
            museumName: selectedMuseum?.name,
        }
        if (!overrideUmbrellaSetting) {
            delete updatedMuseumSettings.mcApiKey
            delete updatedMuseumSettings.mcAudienceId
            delete updatedMuseumSettings.makeClientId
            delete updatedMuseumSettings.makeApiKey
            delete updatedMuseumSettings.makeListId
            delete updatedMuseumSettings.newsletterProvider
        } else {
            updatedMuseumSettings.mcAudienceId = mcAudienceId
            updatedMuseumSettings.mcApiKey = mcApiKey
            updatedMuseumSettings.makeClientId = makeClientId
            updatedMuseumSettings.makeApiKey = makeApiKey
            updatedMuseumSettings.makeListId = makeListId
            updatedMuseumSettings.newsletterProvider = newsletterProvider
        }

        putMuseumSettings({
            url: `museum_settings/${id}`,
            data: updatedMuseumSettings,
        })
    }

    const getOverrideSwitcherLabel = () => {
        return (
            <>
                <Typography>{t("ownMailchimpSettingForMuseum")}</Typography>
                <Typography variant="subtitle1"
                            color="textSecondary">{t("ownUrlForMuseumHelperText")}</Typography>
            </>
        )
    }

    const handleToggleOverrideUmbrellaSettings = () => {
        setOverrideUmbrellaSetting(!overrideUmbrellaSetting)
    }

    return (
        <>
            <Grid container
                  spacing={3}
                  direction="column">
                {museumPutLoading
                 ? <ProgressIndicator/>
                 : <>

                     <Grid container
                           item
                           xs={12}>
                         <NewsletterInputFields handleSaveMailchimpSettings={handleUmbrellaUrlSave}
                                                museumSettings={umbrellaSettings}
                                                isUmbrellaFields={true}
                                                readonly={false}/>
                         {selectedMuseum?.type === "museum" && (<>
                             <Grid item mt={2}>
                                 <FormControlLabel label={getOverrideSwitcherLabel()}
                                                   control={
                                                       <Switch checked={overrideUmbrellaSetting}
                                                               onChange={() => handleToggleOverrideUmbrellaSettings()}/>
                                                   }/>
                             </Grid>
                             <NewsletterInputFields handleSaveMailchimpSettings={handleMuseumUrlSave}
                                                    museumSettings={museumSettings}
                                                    readonly={!overrideUmbrellaSetting}/></>)}

                     </Grid>
                 </>
                }
            </Grid>
        </>
    )
}

export default NewsletterSettings
