import React from "react"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"

const DeleteConfirmationDialog = ({open, handleClose, handleDelete, title = null, content = null}) => {
    const t = useBaseTranslation()

    return (
        <Dialog open={open}
                onClose={handleClose}>
            {title && (
                <DialogTitle>{title}</DialogTitle>
            )}
            {content && (
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleClose} color="primary">{t("cancel")}</Button>
                <Button onClick={handleDelete} color="primary">{t("delete")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteConfirmationDialog