import React, {useEffect, useState} from "react"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import {SALES_BASE_URL} from "../../app/Routes"
import {useAxiosBpn} from "../../app/axios"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {useAuthsState} from "../../app/auths/authsContext"
import RevenueCard from "../../app/revenue/RevenueCard"
import DateFilterDialog from "../../app/components/DateFilterDialog"
import {dateFormat, MONTHLY} from "../../app/helper-functions/dateFormat"
import {endOfYear, startOfYear} from "date-fns"
import DateFrequencySelectMenu from "../../app/components/DateFrequencySelectMenu"
import OrderSumsGroupBySelectMenu, {
    GROUP_BY_ALTERNATIVES,
    GROUP_BY_MUSEUM_NAME
} from "../../app/components/OrderSumsGroupBySelectMenu"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import TestOrdersSwitch from "../../app/components/TestOrdersSwitch"
import {useQuery} from "../../app/hooks/useQuery"

const LOCAL_STORAGE_KEY = "bpn.testOrders"
const defaultDateFilterLabel = (t) => `${t("thusFar")} ${t("in").toLowerCase()} ${dateFormat(new Date(), "y")}`

export const SalesRevenue = () => {
    const t = useBaseTranslation()
    const query = useQuery()

    const {umbrellaAndChildrenIds} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const [dateFilterLabel, setDateFilterLabel] = useState(defaultDateFilterLabel(t))
    const [createdBefore, setCreatedBefore] = useState(endOfYear(new Date()).getTime())
    const [createdAfter, setCreatedAfter] = useState(startOfYear(new Date()).getTime())
    const [groupByMenuOpen, setGroupByMenuOpen] = useState(false)
    const [dateFrequencyMenuOpen, setDateFrequencyMenuOpen] = useState(false)
    const [dateFilterDialogOpen, setDateFilterDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [frequency, setFrequency] = useState(MONTHLY)
    const [groupBy, setGroupBy] = useState(GROUP_BY_MUSEUM_NAME)

    const [{data: orderSumsData, loading: loadingOrderSums}, getOrderSums] = useAxiosBpn({
        url: "orders/sums",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                ? umbrellaAndChildrenIds.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
            groupBy: groupBy,
            groupByDateFrequency: frequency,
            test: testOrders,
            createdBefore: createdBefore,
            createdAfter: createdAfter,
        },
    })


    useEffect(() => {
        if (query) {
            const _groupBy = query.get('groupBy')

            if (_groupBy && GROUP_BY_ALTERNATIVES.includes(_groupBy)) setGroupBy(_groupBy)
        }
    }, [query])

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])
    useEffect(() => getOrderSums().catch(() => {
    }), [getOrderSums, testOrders])

    const breadcrumbs = [
        {label: t("sales"), url: SALES_BASE_URL},
        {label: t("revenue")},
    ]

    const handleSelectGroupByClick = (event) => {
        setGroupByMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleGroupBySelect = (_groupBy) => {
        setGroupBy(_groupBy)
        setGroupByMenuOpen(false)
    }

    const handleSelectDateFrequencyClick = (event) => {
        setDateFrequencyMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleDateFrequencySelect = (freq) => {
        setFrequency(freq)
        setDateFrequencyMenuOpen(false)
    }

    const handleDateFilterDialogOpen = (event) => {
        setDateFilterDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleDateFilterDialogClose = (after = null, before = null, label = null) => {
        setDateFilterDialogOpen(false)
        setDateFilterLabel(label || t("allDates"))
        setCreatedAfter(after)
        setCreatedBefore(before)
    }

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <ScrollableSectionView>
                <RevenueCard data={orderSumsData}
                             loading={loadingOrderSums}
                             getOrderSums={getOrderSums}
                             testOrders={testOrders}
                             handleSelectGroupByClick={handleSelectGroupByClick}
                             handleSelectDateFrequencyClick={handleSelectDateFrequencyClick}
                             handleDateFilterDialogOpen={handleDateFilterDialogOpen}
                             dateFilterLabel={dateFilterLabel}
                             frequency={frequency}
                             groupBy={groupBy}/>
            </ScrollableSectionView>
            <OrderSumsGroupBySelectMenu open={groupByMenuOpen}
                                        setOpen={setGroupByMenuOpen}
                                        anchorEl={anchorEl}
                                        handleSelect={handleGroupBySelect}/>
            <DateFrequencySelectMenu open={dateFrequencyMenuOpen}
                                     setOpen={setDateFrequencyMenuOpen}
                                     anchorEl={anchorEl}
                                     handleSelect={handleDateFrequencySelect}/>
            <DateFilterDialog t={t}
                              open={dateFilterDialogOpen}
                              anchorEl={anchorEl}
                              handleClose={handleDateFilterDialogClose}/>
        </>
    )
}