import React, {useEffect, useState} from "react"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import {Grid, IconButton, Tooltip, Typography} from "@mui/material"
import {
    createVisitorsTableHeaders,
    createVisitorsTableRows,
    createVisitorStatsChartData,
} from "./visitorsHelperFunctions"
import StackedBarChart from "../components/StackedBarChart"
import {getDateLabelBasedOnFequency} from "../helper-functions/dateFormat"
import {OpenInNew, TableChart} from "@mui/icons-material"
import GenericTable from "../generic-table/GenericTable"
import GenericPopper from "../components/GenericPopper"
import DashboardCard from "../components/DashboardCard"
import {useHistory} from "react-router-dom"

const SmallVisitorStatsCard = ({
                                   data,
                                   loading,
                                   frequency,
                                   groupBy,
                                   testOrders,
                                   title,
                                   shortcutTo,
                                   subheader,
                                   avatar,
                               }) => {
    const t = useBaseTranslation()
    const history = useHistory()

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [uniqueGroups, setUniqueGroups] = useState()
    const [chartData, setChartData] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [tableDialogOpen, setTableDialogOpen] = useState(false)

    useEffect(() => {
        if (data) {
            const {groupedVisitorStats} = data

            if (groupedVisitorStats) {
                const {columns: _columns, data: _data} = groupedVisitorStats

                const headers = createVisitorsTableHeaders(_columns, t, frequency)
                setColumns(headers)

                const _rows = createVisitorsTableRows(_data, headers)
                setRows(_rows)

                const _uniqueGroups = [...new Set(_rows.map(r => r[headers[0].field]))]
                setUniqueGroups(_uniqueGroups)

                let _headers = [...headers]
                _headers.splice(0, 1)

                const _uniqueDates = _headers.map(h => h.field)
                setChartData(createVisitorStatsChartData(_rows, _uniqueDates, _uniqueGroups, groupBy))
            } else {
                setColumns([])
                setRows([])
                setUniqueGroups([])
                setChartData([])
            }
        } else {
            setColumns([])
            setRows([])
            setUniqueGroups([])
            setChartData([])
        }
    }, [data, t, frequency, groupBy, loading])

    const handleTableDialogOpen = (event) => {
        setTableDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const Content = () => {
        return rows.length
            ? <StackedBarChart data={chartData}
                               groups={uniqueGroups}
                               xAxisLabel={t(getDateLabelBasedOnFequency(frequency))}
                               yAxisLabel={t("visitorNumbers")}/>
            : <Typography>{t("noDataToShow")}</Typography>
    }

    const getCardAction = () => (
        <Grid container spacing={1}>
            {shortcutTo && shortcutTo?.url && (
                <Grid item>
                    <Tooltip title={shortcutTo?.label ? shortcutTo.label : "Gå til hovedside for besøkstall"}>
                        <IconButton onClick={() => history.push(shortcutTo.url)} size="large">
                            <OpenInNew/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            <Grid item>
                <Tooltip title={t("seeTableData")}>
                    <IconButton onClick={handleTableDialogOpen} size="large">
                        <TableChart/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

    return (
        <>
            <DashboardCard maxHeight={650}
                           title={title}
                           subheader={subheader}
                           testOrders={testOrders}
                           action={rows.length ? getCardAction() : null}
                           avatar={avatar}
                           cardContentStyle={{
                               overflowY: "auto",
                               overflowX: "hidden",
                               maxHeight: 650 - 64,
                           }}>
                {loading
                    ? <ProgressIndicator/>
                    : <Content/>
                }
            </DashboardCard>
            <GenericPopper anchorEl={anchorEl}
                           open={tableDialogOpen}
                           handleClose={() => setTableDialogOpen(false)}>
                <GenericTable columns={columns}
                              rows={rows}
                              disableOverflowScroll/>
            </GenericPopper>
        </>
    )
}

export default SmallVisitorStatsCard