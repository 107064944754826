import AddIcon from "@mui/icons-material/Add"
import React from "react"
import {Button, CardActions} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import {useBaseTranslation} from "../../app/baseTranslationContext"

const useStyles = makeStyles(() => ({
    button: {
        marginLeft: "auto",
    },
}))

const ApiClients = ({children, handleNewApiClient}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    return (
        <>
            {children}
            <CardActions>
                <Button variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={handleNewApiClient}
                        endIcon={<AddIcon/>}>
                    {t("add")}
                </Button>
            </CardActions>
        </>
    )
}

export default ApiClients