import React from "react"
import {Card, Grid} from "@mui/material"
import ScheduleEventExceptionsGridRowMonth from "./ScheduleEventExceptionsGridRowMonth"
import ScheduleEventExceptionsGridRowTimeAndName from "./ScheduleEventExceptionsGridRowTimeAndName"
import ScheduleEventExceptionsGridRowSaleLimit from "./ScheduleEventExceptionsGridRowSaleLimit"
import ScheduleEventExceptionsGridRowCancelled from "./ScheduleEventExceptionsGridRowCancelled"
import ScheduleEventExceptionsGridRowRescheduled from "./ScheduleEventExceptionsGridRowRescheduled"
import ScheduleEventExceptionsGridRowRenamed from "./ScheduleEventExceptionsGridRowRenamed"
import ScheduleEventExceptionsGridRowDelete from "./ScheduleEventExceptionsGridRowDelete"

const ScheduleEventExceptionsGridRow = ({exception, handleRemove, exceptionIdLoading}) => {
    const {
        startDate,
        originalStartDate,
        endTime,
        originalEndTime,
        originalStartTime,
        startTime,
        name,
        originalName,
        saleLimit,
        originalSaleLimit,
        isCancelled
    } = exception
    return (
        <Card square variant="outlined" style={{marginBottom: 1, paddingTop: 8, paddingRight: 4, paddingBottom: 8}}>
            <Grid container wrap="nowrap" spacing={2} alignItems="center" justifyContent="space-evenly">
                <ScheduleEventExceptionsGridRowMonth originalStartDate={originalStartDate} startDate={startDate}/>
                <ScheduleEventExceptionsGridRowTimeAndName name={name}
                                                           originalName={originalName}
                                                           startTime={startTime}
                                                           originalStartTime={originalStartTime}
                                                           endTime={endTime}
                                                           originalEndTime={originalEndTime}/>
                <Grid container item wrap="nowrap" spacing={1} alignItems="center" justifyContent="flex-end" xs={3}>
                    <ScheduleEventExceptionsGridRowRenamed name={name} originalName={originalName}/>
                    <ScheduleEventExceptionsGridRowSaleLimit saleLimit={saleLimit}
                                                             originalSaleLimit={originalSaleLimit}/>
                    <ScheduleEventExceptionsGridRowCancelled isCancelled={isCancelled}/>
                    <ScheduleEventExceptionsGridRowRescheduled originalStartDate={originalStartDate}
                                                               startDate={startDate}
                                                               startTime={startTime}
                                                               originalStartTime={originalStartTime}
                                                               endTime={endTime}
                                                               originalEndTime={originalEndTime}/>
                    <ScheduleEventExceptionsGridRowDelete exception={exception}
                                                          handleRemove={handleRemove}
                                                          exceptionIdLoading={exceptionIdLoading}/>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ScheduleEventExceptionsGridRow