import React from "react"
import {Typography} from "@mui/material"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import ScheduleEventExceptionsGridRow from "./ScheduleEventExceptionsGridRow"

const ScheduleEventExceptions = ({exceptions, exceptionIdLoading, handleRemove}) => {
    const t = useProductEditorTranslation()

    return (
        <>
            <Typography style={{marginTop: 24}} variant="button"> {t("exceptions")} </Typography>
            {exceptions.map((exception, idx) => <ScheduleEventExceptionsGridRow key={idx}
                                                                                exceptionIdLoading={exceptionIdLoading}
                                                                                handleRemove={handleRemove}
                                                                                exception={exception}/>)}

        </>
    )
}

export default ScheduleEventExceptions