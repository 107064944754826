import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import makeStyles from '@mui/styles/makeStyles';
import React from "react"
import {useOtherTranslation} from "./otherTranslationContext"

const useStyles = makeStyles(theme => ({
    dashboard: {
        height: "100%",
        width: "100%"
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    grid: {
        paddingTop: theme.spacing(2)
    }
}))

export const OtherDashboard = () => {
    const classes = useStyles()
    const t = useOtherTranslation()

    return (
        <div className={classes.dashboard}>
            <Typography variant={"h3"}>{t("otherDashboard")}</Typography>
            <Grid container
                  className={classes.grid}
                  spacing={2}>
                <Grid item>
                    <Typography variant={"h6"}>
                        Punktliste
                    </Typography>
                    <Paper className={classes.paper}>
                        <ul>
                            <li>Opprett nytt produkt</li>
                        </ul>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}