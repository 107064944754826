import React from "react"
import {Checkbox, TableCell, TableHead, TableRow, Typography} from "@mui/material"
import {getGenericTableCellAlign, getGenericTableCellVariant} from "./GenericTable"

const GenericTableHead = ({
                              columns,
                              collapsible,
                              selectable,
                              emptyFirstCell = false,
                              numSelected,
                              numTotal,
                              handleSelectAllClick,
                              size,
                          }) => {

    const getFirstCellInHeader = () => {
        if (collapsible) {
            return <TableCell/>
        } else if (selectable) {
            return (
                <TableCell padding="checkbox">
                    <Checkbox indeterminate={numSelected > 0 && numSelected < numTotal}
                              checked={numSelected > 0 && numSelected === numTotal}
                              onChange={handleSelectAllClick}/>
                </TableCell>
            )
        } else if (emptyFirstCell) {
            return <TableCell/>
        }
    }

    return (
        <>
            <TableHead>
                <TableRow>
                    {getFirstCellInHeader()}
                    {columns?.map(({field, headerName, align, flex, hide = false, type}, index) => {
                        return hide ? null : (
                            <TableCell key={`${field}-${index}`}
                                       style={{minWidth: flex ? flex * 75 : 75}}
                                       align={getGenericTableCellAlign(align, type)}>
                                <Typography variant={getGenericTableCellVariant(size)}>
                                    <strong>
                                        {headerName}
                                    </strong>
                                </Typography>
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        </>
    )
}

export default GenericTableHead