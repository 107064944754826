import React from "react"
import {Grid, Typography} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"

const gridSizes = {xs: 12, sm: 5, md: 4, lg: 3, xl: 2}

const GridLabel = ({label, marginLeft = 0}) => (
    <Grid item
          xs>
        <Typography variant="button"
                    style={{marginLeft: marginLeft}}
                    color="textSecondary">
            {label}
        </Typography>
    </Grid>
)

const VismaVoucherSettingsGridHeader = ({selectedProductGroup, selectedDepartment, selectedMuseum}) => {
    const {umbrellaName} = useMuseumSelectorState()

    return (
        <Grid container
              spacing={2}
              alignItems="center"
              item
              style={{marginBottom: -16, maxWidth: 1200}}>
            <Grid item {...gridSizes}/>
            <Grid item
                  xl={12 - (gridSizes.xl * 2)}
                  lg={12 - (gridSizes.lg * 2)}
                  md={12 - (gridSizes.md * 2)}
                  sm={12 - (gridSizes.sm * 2)}
                  xs={12}>
                <Grid container
                      spacing={1}>
                    <GridLabel label={umbrellaName}/>
                    {selectedProductGroup && (
                        <GridLabel label={selectedProductGroup.name}
                                   marginLeft={32}/>
                    )}
                    {selectedMuseum && (
                        <GridLabel label={selectedMuseum.name}
                                   marginLeft={32}/>
                    )}
                    {selectedDepartment && (
                        <GridLabel label={selectedDepartment.name}
                                   marginLeft={32}/>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default VismaVoucherSettingsGridHeader