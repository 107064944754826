import React, {useEffect, useState} from "react"
import {Grid, Tooltip, Typography} from "@mui/material"
import {dateFormat} from "../../helper-functions/dateFormat"
import makeStyles from '@mui/styles/makeStyles';
import _ from "lodash"

const useStyles = makeStyles(theme => ({
    month: {
        fontSize: theme.typography.pxToRem(12),
        textTransform: "uppercase",
        marginBottom: theme.spacing(-1),
    },
    dayOfMonth: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: "bold",
    }
}))


const ScheduleEventExceptionsGridRowMonth = ({startDate, originalStartDate,}) => {
    const classes = useStyles()

    const [monthLabel, setMonthLabel] = useState("")
    const [dayOfMonthLabel, setDayOfMonthLabel] = useState("")

    useEffect(() => {
        setDayOfMonthLabel(dateFormat(new Date(originalStartDate || startDate), "dd"))
        setMonthLabel(dateFormat(new Date(originalStartDate || startDate), "MMM").substring(0, 3))
    }, [startDate, originalStartDate])


    const DateTooltipTitle = () => (
        <>
            <Typography variant="caption">
                Opprinnelig dato:
            </Typography>
            <Typography variant="subtitle2">
                {_.upperFirst(dateFormat(new Date(originalStartDate || startDate), "PPPP"))}
            </Typography>
        </>
    )

    return (
        <Tooltip title={<DateTooltipTitle/>}>
            <Grid container item direction="column" alignItems="center" xs={2}>
                <Grid item><Typography className={classes.month}>{monthLabel}</Typography></Grid>
                <Grid item><Typography className={classes.dayOfMonth}>{dayOfMonthLabel}</Typography></Grid>
            </Grid>
        </Tooltip>
    )
}

export default ScheduleEventExceptionsGridRowMonth