import React, {memo, useCallback, useEffect, useState} from "react"
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext"
import {useTheme} from "@mui/material/styles"
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material"
import ReactExport from "react-data-export"
import {ArrowDropDown, NavigateBefore, NavigateNext, Search} from "@mui/icons-material"
import DateFilterDialog from "../components/DateFilterDialog"
import EventFilterDialog from "../components/EventFilterDialog"
import {useAxiosBpn} from "../axios"
import {useBaseTranslation} from "../baseTranslationContext"
import {useAuthsState} from "../auths/authsContext"
import {
    createExcelDatasetFromOrderData,
    createOrderLinesTableHeaders,
    createOrderLinesTableRows,
    createOrderTableHeaders,
    createOrderTableRows,
} from "./orderTableHelpers"
import {useViewport} from "../viewport/useViewport"
import {ButtonWithProgress} from "../components/ButtonWithProgress"
import {ProgressIndicator} from "../ProgressIndicator"
import {ColumnSelector} from "./ColumnSelector"
import GenericTable from "../generic-table/GenericTable"
import { debounce } from "@mui/material";
import {endOfMonth, startOfMonth} from "date-fns"
import {toSentenceCase} from "../helper-functions/stringUtility"
import {dateFormat, getEventLabel} from "../helper-functions/dateFormat"
import {
    CONFIRMED_EXCEPT_CREDITED_ORDER_STATUSES,
    CONFIRMED_ORDER_STATUSES,
    FAILED_ORDER_STATUSES,
    getSelectableStatusFiltersBasedOnSingleStatus,
    ONLY_NEW_ORDER_STATUSES, ORDER_STATUS_CONFIRMED_CREDIT_NOTE, ORDER_STATUS_CONFIRMED_CREDITED,
    OTHER_ORDER_STATUSES
} from "../helper-functions/orderStatuses";
import ScheduleEventTreeView from "../components/ScheduleEventTreeView"
import {useQuery} from "../hooks/useQuery"

const VISIBLE_COLUMNS_LOCAL_STORAGE_KEY = "bpn.visibleColumnsOrderTable"
const MAIN_TOOLBAR_HEIGHT = 61
const CARD_HEADER = 97
const TABLE_HEADER = 61
const TABLE_FOOTER = 51

const ExcelFile = ReactExport?.ExcelFile
const ExcelSheet = ReactExport?.ExcelFile?.ExcelSheet
const ROW_LIMIT_OPTIONS = [10, 25, 50, 100, 500]


const OrderTable = ({
                        productId,
                        refetchOrders,
                        testOrders,
                        handleOrderIdClick,
                        groupedScheduleEvents,
                        eventFilterButtonHidden,
                        registrationFormQuestion,
                        defaultToCurrentMonth = false,
                        subtractHeight = 0,
                    }) => {
    const t = useBaseTranslation()
    const query = useQuery()
    const {umbrellaAndChildrenIds, umbrellaId, selectedMuseum} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const {breakpoints} = useTheme()
    const {height} = useViewport()
    const {spacing} = useTheme()
    const smDown = useMediaQuery(breakpoints.down("sm"))
    const xsDown = useMediaQuery(breakpoints.down("xs"))

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [visibleColumns, setVisibleColumns] = useState(
        localStorage.getItem(VISIBLE_COLUMNS_LOCAL_STORAGE_KEY)
            ? JSON.parse(localStorage.getItem(VISIBLE_COLUMNS_LOCAL_STORAGE_KEY))
            : [],
    )
    const [selectedStatusFilter, setSelectedStatusFilter] = useState(CONFIRMED_ORDER_STATUSES)
    const [excelDataSet, setExcelDataSet] = useState()
    const [textQuery, setTextQuery] = useState()
    const [filename, setFilename] = useState("excel")
    const [before, setBefore] = useState(null)
    const [after, setAfter] = useState(null)
    const [selectedPaymentTypeIds, setSelectedPaymentTypeIds] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [eventFilterDialogOpen, setEventFilterDialogOpen] = useState(false)
    const [eventFilterLabel, setEventFilterLabel] = useState(t("allEvents"))
    const [paymentTypeLabel, setPaymentTypeLabel] = useState(t("allPaymentTypes"))
    const [paymentTypeDialogOpen, setPaymentTypeDialogOpen] = useState(false)
    const [dateFilterDialogOpen, setDateFilterDialogOpen] = useState(false)
    const [dateFilterLabel, setDateFilterLabel] = useState(t("allDates"))
    const [anchorEl, setAnchorEl] = useState(null)
    const [kdrEnabled, setKdrEnabled] = useState(false)
    const [tableHeight, setTableHeight] = useState(0)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(25)
    const [count, setCount] = useState(0)
    const [discountCodes, setDiscountCodes] = useState([])

    const selectableStatusFilters = [
        {label: t("confirmedOnly"), value: CONFIRMED_EXCEPT_CREDITED_ORDER_STATUSES},
        {label: t("confirmed"), value: CONFIRMED_ORDER_STATUSES},
        {label: t("credited"), value: ORDER_STATUS_CONFIRMED_CREDITED},
        {label: t("confirmed_credit_note"), value: ORDER_STATUS_CONFIRMED_CREDIT_NOTE},
        {label: t("failed"), value: FAILED_ORDER_STATUSES},
        {label: t("Other"), value: OTHER_ORDER_STATUSES},
        {label: t("onlyNew"), value: ONLY_NEW_ORDER_STATUSES},
        {label: t("all"), value: "all"},
    ]

    // noinspection JSIncompatibleTypesComparison
    const params = {
        museumIds: umbrellaAndChildrenIds?.length
            ? umbrellaAndChildrenIds.join()
            : Array.from(userAccessMuseumIds.keys()).join(),
        productId: productId,
        detailed: true,
        textQuery: textQuery,
        createdAfter: after,
        paymentTypeIds: selectedPaymentTypeIds.join(),
        createdBefore: before,
        confirmedExceptCredited: selectedStatusFilter === CONFIRMED_EXCEPT_CREDITED_ORDER_STATUSES,
        scheduleEventId: selectedEvent?.id,
        recurNumber: selectedEvent?.recurNumber,
        test: testOrders,
        status: selectedStatusFilter ? (selectedStatusFilter !== "all" ? selectedStatusFilter.toString() : null) : null,
    }

    const [{data: orderData, loading: ordersLoading}, getOrders] = useAxiosBpn({
        url: "orders",
        params: {
            ...params,
            limit: limit,
            offset: offset,
        },
    })
    const [{data: orderLinesData, loading: orderLinesLoading}, getOrderLines] = useAxiosBpn({
        url: "order_lines",
        params: params,
    }, {manual: true})

    const [{data: discountCodesData}, getMuseumDiscountCodes] = useAxiosBpn({
        url: "discount_codes",
        params: {museumId: umbrellaId ? umbrellaId : (selectedMuseum.id || null), fetchDeleted: true},
    }, {manual: true})

    const [{data: kdrData}, getKdr] = useAxiosBpn({url: `point_of_sale_systems/kdr/museum/${umbrellaId}`},
        {manual: true})

    const [{data: scheduleEventData}, getScheduleEvent] = useAxiosBpn({url: ""}, {manual: true})

    const [{data: paymentTypes}, getPaymentTypes] = useAxiosBpn({url: `payment_types`, params: {museumId: umbrellaId ? umbrellaId : (selectedMuseum.id || null)}}, {manual: true})

    const _getEventLabel = useCallback((event) => getEventLabel(t, event), [t])

    useEffect(() => {
        if (query) {
            const scheduleEventId = query.get('scheduleEventId')
            const recurNumber = query.get('recurNumber')
            const status = query.get('status')

            if (scheduleEventId) {
                getScheduleEvent({
                    url: `schedule_events/${scheduleEventId}`,
                    params: {recurNumber: recurNumber}
                }).catch(() => {
                })
            }
            if (status) setSelectedStatusFilter(getSelectableStatusFiltersBasedOnSingleStatus(status))
        }
    }, [query, getScheduleEvent])

    useEffect(() => {
        if (scheduleEventData?.scheduleEvent) {
            const {scheduleEvent} = scheduleEventData
            setSelectedEvent(scheduleEvent)
            setEventFilterLabel(_getEventLabel(scheduleEvent))
        }
    }, [scheduleEventData, _getEventLabel])

    useEffect(() => {
        if (defaultToCurrentMonth) {
            const label = `${toSentenceCase(dateFormat(new Date(), "MMMM"))} ${dateFormat(new Date(), "y")}`
            setDateFilterLabel(label)
            setBefore(endOfMonth(new Date()).getTime())
            setAfter(startOfMonth(new Date()).getTime())
        }
    }, [defaultToCurrentMonth])

    useEffect(() => {
        setTableHeight(height - MAIN_TOOLBAR_HEIGHT - CARD_HEADER - TABLE_HEADER - TABLE_FOOTER - 8 - subtractHeight)
    }, [height, spacing, subtractHeight])

    useEffect(() => {
        getMuseumDiscountCodes().catch(() => {
            // Just to avoid annoying console error
        })

        getOrders().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getMuseumDiscountCodes, getOrders, refetchOrders, umbrellaAndChildrenIds])

    useEffect(() => {
        if (discountCodesData) {
            setDiscountCodes(discountCodesData.discountCodes)
        }
    }, [discountCodesData])

    useEffect(() => {
        if (umbrellaId) {
            getKdr().catch(() => {
                // Just to avoid annoying console error
            })
        }
    }, [getKdr, umbrellaId])

    useEffect(() => {
        if (kdrData) {
            const {pointOfSaleSystemKdr} = kdrData

            if (pointOfSaleSystemKdr) {
                if (pointOfSaleSystemKdr.enabled) {
                    setKdrEnabled(true)
                } else {
                    setKdrEnabled(false)
                }
            } else {
                setKdrEnabled(false)
            }
        } else {
            setKdrEnabled(false)
        }
    }, [kdrData])

    useEffect(() => {
        if (orderLinesData && orderData) {
            const {orders} = orderData
            const {orderLines} = orderLinesData
            const _columns = createOrderLinesTableHeaders(orderLines, t, _handleOrderIdClick, false, false, kdrEnabled)
            const _rows = createOrderLinesTableRows(orderLines, t, discountCodes, orders, registrationFormQuestion)
            const dataSet = createExcelDatasetFromOrderData(_columns, _rows)
            setExcelDataSet(dataSet)
        }
        // eslint-disable-next-line
    }, [orderLinesData, kdrEnabled, t])

    useEffect(() => {
        if (orderData) {
            const {orders, pagination} = orderData

            if (pagination?.count) {
                setCount(pagination?.count)
            }
            const _columns = createOrderTableHeaders(t, _handleOrderIdClick, smDown, xsDown, kdrEnabled, false, productId)
            setColumns(_columns)
            if (!(visibleColumns.length > 0)) {
                setVisibleColumns(_columns.map(col => col.headerName))
            }
            const _rows = createOrderTableRows(orders, t, smDown, discountCodes, productId, registrationFormQuestion)
            setRows(_rows)
        }
        // eslint-disable-next-line
    }, [orderData, kdrEnabled, t, smDown, xsDown])

    useEffect(() => {
        setFilename(
            `${t("orderReport")} - ${dateFilterLabel
                ? dateFilterLabel
                : t("allDates")} - ${testOrders
                ? "Test"
                : "Prod"}`)
    }, [testOrders, dateFilterLabel, t])

    useEffect(() => {
        getPaymentTypes()
    }, [getPaymentTypes])
    // HACK: "Kassepunkt" is a hard coded payment type, and it's just the order.payment_type_name that has it (no foreign key)
    //  it has special treatment on the backend...
    const paymentTypeOptions = [{
        id: "Kassepunkt",
        name: "Kassepunkt",
    }, ...(paymentTypes?.paymentTypes || []).map(({id, name}) => ({id, name}))]

    const handleEventFilterDialogOpen = (event) => {
        setEventFilterDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handlePaymentTypeDialogOpen = (event) => {
        setPaymentTypeDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleEventFilterSelect = (event, label = null) => {
        setEventFilterLabel(label || t("allEvents"))
        setSelectedEvent(event)
    }

    const handleDateFilterDialogOpen = (event) => {
        setDateFilterDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleDateFilterDialogClose = (after = null, before = null, label = null) => {
        setDateFilterDialogOpen(false)
        setDateFilterLabel(label || t("allDates"))
        setAfter(after)
        setBefore(before)
    }

    const handleSearchChange = event => {
        event.persist()
        setTextQuery(event.target.value)
    }

    const handleStatusFilterChange = (newStatus) => setSelectedStatusFilter(newStatus)

    const handlePaymentTypeFilterChange = (newPaymentTypes) => {
        setSelectedPaymentTypeIds(newPaymentTypes);
        if (newPaymentTypes.length === 0) {
            setPaymentTypeLabel(t("allPaymentTypes"))
        } else {
            setPaymentTypeLabel(t(paymentTypeOptions.find(({id}) => id === newPaymentTypes[0]).name) + `${newPaymentTypes.length > 1 ? ` (+${newPaymentTypes.length - 1})` : ""}`)
        }
    }

    const _handleOrderIdClick = (e) => handleOrderIdClick(e?.target?.textContent)

    const handleLimitChange = (_limit) => {
        setLimit(_limit)
        setOffset(0)
    }

    const XlsxDownloadButton = () => (
        <div style={{display: "flex", alignItems: "center"}}>
            <ButtonWithProgress label={t("downloadXLSX")}
                                variant="text"
                                color="primary"
                                onClick={getOrderLines}
                                loading={orderLinesLoading}/>
        </div>
    )

    const handleChangeVisibleColumns = (event) => {
        const value = event.target.value || []
        for (let i = 0, l = value.length; i < l; i += 1) {
            if (value[i].selected) {
                value.push(value[i].value)
            }
        }

        setVisibleColumns(value)
        localStorage.setItem(VISIBLE_COLUMNS_LOCAL_STORAGE_KEY, JSON.stringify(value))
    }

    return <>
        <Grid container
              spacing={3}
              alignItems="center"
              justifyContent="flex-end">
            <Grid item>
                <TextField variant="standard"
                           style={{marginBottom: 4}}
                           onChange={debounce(handleSearchChange, 500)}
                           helperText={t("orderTableSeachHelperText")}
                           InputProps={{
                               endAdornment: (
                                   <InputAdornment position="start">
                                       <Search/>
                                   </InputAdornment>
                               ),
                           }}
                           label={t("search")}/>
            </Grid>
            <Grid item>
                <>
                    <XlsxDownloadButton/>
                    {excelDataSet && (
                        <ExcelFile filename={filename}
                                   hideElement={true}>
                            <ExcelSheet dataSet={excelDataSet}
                                        name={t("orderReport")}/>
                        </ExcelFile>
                    )}
                </>
            </Grid>
            {!eventFilterButtonHidden && (
                <Grid item>
                    <Tooltip title={t("filterOnScheduleEvent")}>
                        <Button onClick={handleEventFilterDialogOpen}
                                color="primary"
                                endIcon={<ArrowDropDown/>}>
                            {eventFilterLabel}
                        </Button>
                    </Tooltip>
                </Grid>
            )}
            <Grid item>
                <Tooltip title={t('filterOnPaymentType')}>
                    <Button onClick={handlePaymentTypeDialogOpen}
                            color="primary"
                            endIcon={<ArrowDropDown/>}>
                        {paymentTypeLabel}
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title={t("filterOnOrderDate")}>
                    <Button onClick={handleDateFilterDialogOpen}
                            color="primary"
                            endIcon={<ArrowDropDown/>}>
                        {dateFilterLabel}
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item>
                <Grid container
                      alignItems="center">
                    <Grid item>
                        <Typography variant="caption"
                                    color="textSecondary">
                            {t("status")}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField select
                                   size="small"
                                   variant="standard"
                                   style={{width: 160, marginLeft: 8}}
                                   value={selectedStatusFilter || ""}>
                            {selectableStatusFilters.map(({value, label}, index) => (
                                <MenuItem key={index}
                                          value={value}
                                          onClick={() => handleStatusFilterChange(value)}>
                                    {label}
                                </MenuItem>
                            ))}

                        </TextField>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item>
                <ColumnSelector columns={columns}
                                handleChangeVisibleColumns={handleChangeVisibleColumns}
                                visibleColumns={visibleColumns}/>
            </Grid>
        </Grid>
        {ordersLoading && <ProgressIndicator/>}
        <Grid container
              direction="column"
              spacing={1}>
            <Grid item
                  style={{backgroundColor: testOrders ? "white" : null, width: "100%"}}>
                <GenericTable columns={columns.filter(col => visibleColumns.indexOf(col.headerName) > -1)}
                              rows={rows}
                              tableHeight={tableHeight}
                              collapsible={true}
                              size="small"/>
            </Grid>
            <Grid container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}>
                <Grid item>
                    <TextField label={t("rowsPrPage")}
                               style={{minWidth: 100}}
                               select
                               size="small"
                               variant="standard"
                               value={limit || 0}>
                        {ROW_LIMIT_OPTIONS.map((option, index) => (
                            <MenuItem key={index}
                                      value={option}
                                      onClick={() => handleLimitChange(option)}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">
                        {offset}-{(offset + limit) > count ? count : offset + limit} av {count}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={offset === 0}
                        onClick={() => setOffset(offset - limit)}
                        size="large">
                        <NavigateBefore/>
                    </IconButton>
                    <IconButton
                        disabled={(offset + limit) > count}
                        onClick={() => setOffset(offset + limit)}
                        size="large">
                        <NavigateNext/>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>

        <DateFilterDialog t={t}
                          title={t("filterOnOrderDate")}
                          open={dateFilterDialogOpen}
                          anchorEl={anchorEl}
                          handleClose={handleDateFilterDialogClose}/>

        <EventFilterDialog anchorEl={anchorEl}
                           open={eventFilterDialogOpen}
                           handleClose={() => setEventFilterDialogOpen(false)}>
            <ScheduleEventTreeView groupedScheduleEvents={groupedScheduleEvents}
                                   selectedEvent={selectedEvent}
                                   handleClose={() => setEventFilterDialogOpen(false)}
                                   handleSelect={handleEventFilterSelect}/>
        </EventFilterDialog>

        <EventFilterDialog
            title={t("filterOnPaymentType")}
            open={paymentTypeDialogOpen}
            anchorEl={anchorEl}
            handleClose={() => setPaymentTypeDialogOpen(false)}
        >

            <FormControlLabel control={
                <Checkbox checked={selectedPaymentTypeIds.length === 0} onChange={(_, checked) => checked && handlePaymentTypeFilterChange([])} />
            } label={t("allPaymentTypes")} />

            {paymentTypeOptions.map(({id, name}) => (
                <FormControlLabel key={id}
                                  control={
                                      <Checkbox checked={selectedPaymentTypeIds.includes(id)} onChange={(_, checked) => {
                                          if (checked) {
                                              handlePaymentTypeFilterChange([...selectedPaymentTypeIds, id])
                                          } else {
                                              handlePaymentTypeFilterChange(selectedPaymentTypeIds.filter((paymentTypeId) => paymentTypeId !== id))
                                          }
                                      }}/>
                                  }
                                  label={t(name)}/>
            ))}

        </EventFilterDialog>
    </>;
}

export default memo(OrderTable)
