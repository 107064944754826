import React from "react"
import {Avatar, Card, CardHeader, IconButton} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import DeleteIcon from "@mui/icons-material/Delete"

const JointProductsDiscountsListCard = ({name, description, percent, onClick, selected, onDeleteButtonClicked}) => {
    const {palette: {primary: {dark}}, spacing} = useTheme()

    return (
        <Card square
              variant="outlined"
              onClick={onClick}
              style={{
                  marginTop: 8,
                  cursor: "pointer",
                  backgroundColor: selected ? "#e5e5e5" : null,
              }}>
            <CardHeader title={<Typography variant="subtitle2"><strong>{name?.value || name}</strong></Typography>}
                        subheader={
                            <Typography color="textSecondary"
                                        variant="caption">
                                {description.value
                                 ? description.value
                                 : typeof description === "object" && true && !Array.isArray(description)
                                   ? "..."
                                   : description}
                            </Typography>
                        }
                        style={{padding: 6}}
                        action={
                            <IconButton onClick={onDeleteButtonClicked} size="large">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        avatar={
                            <Avatar variant="square"
                                    style={{
                                        backgroundColor: dark,
                                        width: spacing(8),
                                        height: spacing(6),
                                    }}>
                                <Typography variant="h6"><strong>{percent}</strong></Typography>
                                <Typography variant="subtitle2"><strong>%</strong></Typography>
                            </Avatar>
                        }/>
        </Card>
    );
}

export default JointProductsDiscountsListCard
