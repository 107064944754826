import {Card, CardContent, useTheme} from "@mui/material"

import React, {useEffect, useState} from "react"
import SectionHeader from "../../app/components/SectionHeader"
import {SALES_BASE_URL} from "../../app/Routes"
import {toLowerCase} from "../../app/helper-functions/stringUtility"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import Grid from "@mui/material/Grid"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import {useAxiosBpn} from "../../app/axios"
import VouchersTable from "./VouchersTable"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import VouchersCountCard from "./VouchersCountCard"
import {endOfMonth, startOfMonth} from "date-fns"
import {useAuthsState} from "../../app/auths/authsContext"
import TestOrdersSwitch from "../../app/components/TestOrdersSwitch"
import VouchersOrderLinesWithNoVouchersCard from "./VouchersOrderLinesWithNoVouchersCard"
import VoucherDetailsDialog from "../../app/voucher-details/VoucherDetailsDialog"

const LOCAL_STORAGE_KEY = "bpn.testOrders"

export const Vouchers = () => {
    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const t = useBaseTranslation()
    const {umbrellaAndChildrenIds, umbrellaId} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()

    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const [count, setCount] = useState()
    const [orderLinesWithNoVouchers, setOrderLinesWithNoVouchers] = useState([])
    const [before, setBefore] = useState(endOfMonth(new Date()).getTime())
    const [after, setAfter] = useState(startOfMonth(new Date()).getTime())
    const [voucherDialogOpen, setVoucherDialogOpen] = useState(false)
    const [selectedVoucherId, setSelectedVoucherId] = useState()
    const [refetchVouchers, setRefetchVouchers] = useState(false)

    const testOrderString = ` (${toLowerCase(t("testOrders"))})`
    const breadcrumbs = [
        {label: t("sales"), url: SALES_BASE_URL},
        {label: `${t("vouchers")}${testOrders ? testOrderString : ""}`},
    ]

    const [{data, loading}, getVouchers] = useAxiosBpn({
        url: `vouchers/museums/${umbrellaId}`,
        params: {
            test: testOrders,
            before: before,
            after: after,
            museumIds: umbrellaAndChildrenIds?.length
                       ? umbrellaAndChildrenIds.join()
                       : Array.from(userAccessMuseumIds.keys()).join(),
        },
    })

    useEffect(() => {
        getVouchers().catch(() => {
            // Just to avoid annoying console error
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getVouchers().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getVouchers, umbrellaId])

    useEffect(() => {
        if (data) {
            const {orderLinesWithNoVouchers: _orderLinesWithNoVouchers} = data
            setOrderLinesWithNoVouchers(_orderLinesWithNoVouchers)
        }
    }, [data])

    useEffect(() => {
        if (selectedVoucherId) {
            setVoucherDialogOpen(true)
        }
    }, [selectedVoucherId])

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])
    useEffect(() => setCount(data?.count), [data])

    const handleVoucherDialogClose = () => {
        setVoucherDialogOpen(false)
        setSelectedVoucherId(null)
        setRefetchVouchers(!refetchVouchers)
    }

    const handleVoucherIdClick = (id) => {
        setSelectedVoucherId(id)
    }

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <ScrollableSectionView>
                <Grid container
                      direction="column"
                      spacing={1}>
                    <Grid container
                          item
                          spacing={1}
                          justifyContent="flex-start">
                        <Grid item>
                            <VouchersCountCard count={count}
                                               loading={loading}
                                               testOrders={testOrders}/>
                        </Grid>
                        {count?.orderLinesWithNoVouchers
                         ? <Grid item>
                             <VouchersOrderLinesWithNoVouchersCard orderLinesWithNoVouchers={orderLinesWithNoVouchers}
                                                                   loading={loading}
                                                                   testOrders={testOrders}/>
                         </Grid>
                         : null}
                    </Grid>
                    <Grid item
                          xs={12}>
                        <Card style={{backgroundColor: testOrders ? testOrdersPalette?.light : null}}>
                            <CardContent>
                                <VouchersTable data={data}
                                               loading={loading}
                                               testOrders={testOrders}
                                               refetch={getVouchers}
                                               handleVoucherIdClick={handleVoucherIdClick}
                                               after={after}
                                               setAfter={setAfter}
                                               before={before}
                                               setBefore={setBefore}/>
                            </CardContent>
                            <VoucherDetailsDialog voucherId={selectedVoucherId}
                                                  open={voucherDialogOpen}
                                                  handleClose={handleVoucherDialogClose}/>
                        </Card>
                    </Grid>
                </Grid>
            </ScrollableSectionView>
        </>
    )
}