const currencyFormatter = (decimals = 0) => new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
})

const percentFormatter = (decimals = 0) => new Intl.NumberFormat("nb-NO", {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
})

const numberFormatter = (decimals = 0) => new Intl.NumberFormat("nb-NO")

export const formatNOK = (value, decimals = 0) => currencyFormatter(decimals).format(Number(value))
export const formatPercent = (value, decimals = 0) => percentFormatter(decimals).format(Number(value / 100))

export const nokPrice = {
    type: "number",
    width: 100,
    valueFormatter: ({value}) => typeof value === 'number' && !Number.isNaN(value) ? currencyFormatter(2).format(Number(value)) : value,
}

export const nokNumber = {
    type: "number",
    width: 100,
    valueFormatter: ({value}) => value ? numberFormatter().format(Number(value)) : value,
}

export const nokPercent = {
    type: "number",
    width: 100,
    valueFormatter: ({value}) => value ? percentFormatter().format(Number(value / 100)) : value,
}