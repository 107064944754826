import React, { useState } from "react";
import { Checkbox, IconButton, Input, ListItemText, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FilterListIcon from "@mui/icons-material/FilterList";


export const ColumnSelector = ({columns, visibleColumns, handleChangeVisibleColumns}) => {
  const [columnSelectorOpen, setColumnSelectorOpen] = useState(false)

  return (
    <FormControl>
      <IconButton
        id="filterButton"
        aria-label="filter"
        onClick={() => setColumnSelectorOpen(true)}
        size="large">
        <FilterListIcon />
      </IconButton>
      <Select
        multiple
        value={visibleColumns}
        style={{ display: "none" }}
        open={columnSelectorOpen}
        onClose={() => setColumnSelectorOpen(false)}
        onChange={handleChangeVisibleColumns}
        input={<Input />}
        MenuProps={{
          anchorEl: document.getElementById("filterButton")
        }}>
        {columns.map((column) => (
          <MenuItem key={column.field} value={column.headerName}>
            <Checkbox color={"primary"} checked={visibleColumns.filter(col => col === column.headerName).length > 0} />
            <ListItemText primary={column.headerName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}