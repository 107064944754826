import Drawer from "@mui/material/Drawer"
import Hidden from "@mui/material/Hidden"
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from "@mui/material/Toolbar"
import {LocalOffer} from "@mui/icons-material"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ReceiptIcon from "@mui/icons-material/Receipt"
import SettingsIcon from "@mui/icons-material/Settings"
import React, {useEffect, useState} from "react"
import {useHistory, useLocation} from "react-router-dom"
import {MENU_CLOSED, useHeaderDispatch, useHeaderState} from "../header/headerContext"
import {useMenuTranslation} from "./menuTranslationContext"
import MenuItem from "./MenuItem"
import {
    PRODUCT_TYPE_ENTRANCE_TICKET,
    PRODUCT_TYPE_EVENT,
    PRODUCT_TYPE_SEASONAL_PASS,
    useBaseContextState,
} from "../baseContext"
import {
    DASHBOARD_BASE_URL,
    ENTRANCE_BASE_URL,
    EVENTS_BASE_URL,
    JOINT_PRODUCTS_DISCOUNT_BASE_URL,
    SALES_BASE_URL,
    SEASONAL_PASSES_BASE_URL,
    SETTINGS_BASE_URL,
} from "../Routes"
import MuseumSelector, {ROLES_VALUES} from "./MuseumSelector"
import {useAuthsState} from "../auths/authsContext"
import NewContentButton from "./NewContentButton"
import {Divider, Typography} from "@mui/material"
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext"
import ProductTypeIcon from "../components/ProductTypeIcon"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: theme.drawerWidth,
            flexShrink: 0,
            zIndex: 1,
        },
    },
    drawerPaper: {
        width: theme.drawerWidth,
        overflow: "hidden",
    },
    museumSelector: {
        marginTop: theme.spacing(2),
    },
    buttonNew: {},
}))

const iconStyle = {color: "gray", fontSize: 20}

const getSubMenuItems = (t) => {
    return [
        {parent: "events", label: t("overview"), url: EVENTS_BASE_URL, requiredRole: ROLES_VALUES.EDITOR},
        {parent: "events", label: t("calendar"), url: `${EVENTS_BASE_URL}/calendar`, requiredRole: ROLES_VALUES.USER},
        {parent: "events", label: t("list"), url: `${EVENTS_BASE_URL}/list`, requiredRole: ROLES_VALUES.USER},

        {parent: "entrance", label: t("overview"), url: ENTRANCE_BASE_URL, requiredRole: ROLES_VALUES.EDITOR},
        {
            parent: "entrance",
            label: t("calendar"),
            url: `${ENTRANCE_BASE_URL}/calendar`,
            requiredRole: ROLES_VALUES.USER,
        },
        {parent: "entrance", label: t("list"), url: `${ENTRANCE_BASE_URL}/list`, requiredRole: ROLES_VALUES.USER},

        {
            parent: "seasonalPasses",
            label: t("overview"),
            url: SEASONAL_PASSES_BASE_URL,
            requiredRole: ROLES_VALUES.EDITOR,
        },
        {
            parent: "seasonalPasses",
            label: t("list"),
            url: `${SEASONAL_PASSES_BASE_URL}/list`,
            requiredRole: ROLES_VALUES.USER,
        },

        {
            parent: "jointProductsDiscounts",
            label: t("overview"),
            disabled: true,
            url: JOINT_PRODUCTS_DISCOUNT_BASE_URL,
            requiredRole: ROLES_VALUES.APP_ADMIN,
        },
        {
            parent: "jointProductsDiscounts",
            label: t("list"),
            url: `${JOINT_PRODUCTS_DISCOUNT_BASE_URL}/list`,
            requiredRole: ROLES_VALUES.APP_ADMIN,
        },

        {parent: "sales", label: t("list"), url: `${SALES_BASE_URL}/list`, requiredRole: ROLES_VALUES.USER},
        {
            parent: "sales",
            label: t("customers"),
            url: `${SALES_BASE_URL}/customers`,
            requiredRole: ROLES_VALUES.APP_ADMIN,
        },
        {parent: "sales", label: t("revenue"), url: `${SALES_BASE_URL}/revenue`, requiredRole: ROLES_VALUES.EDITOR},
        {
            parent: "sales",
            label: t("visitorNumbers"),
            url: `${SALES_BASE_URL}/visitors`,
            requiredRole: ROLES_VALUES.USER,
        },
        {parent: "sales", label: t("vouchers"), url: `${SALES_BASE_URL}/vouchers`, requiredRole: ROLES_VALUES.EDITOR},

        {
            parent: "settings",
            label: t("products"),
            url: `${SETTINGS_BASE_URL}/products`,
            requiredRole: ROLES_VALUES.EDITOR,
        },
        {
            parent: "settings",
            label: t("payment"),
            url: `${SETTINGS_BASE_URL}/payment`,
            requiredRole: ROLES_VALUES.APP_ADMIN,
        },
        {
            parent: "settings",
            label: t("vouchers"),
            url: `${SETTINGS_BASE_URL}/vouchers`,
            requiredRole: ROLES_VALUES.EDITOR,
        },
        {
            parent: "settings",
            label: t("api"),
            url: `${SETTINGS_BASE_URL}/api`,
            requiredRole: ROLES_VALUES.APP_ADMIN,
        },
        {
            parent: "settings",
            label: t("userPortal"),
            url: `${SETTINGS_BASE_URL}/user-portal`,
            requiredRole: ROLES_VALUES.EDITOR,
        },
        {parent: "settings", label: t("other"), url: `${SETTINGS_BASE_URL}/other`, requiredRole: ROLES_VALUES.EDITOR},
    ]
}

const getMenuItems = (t, userHasAccess, subItems, roleValue) => [
    {
        label: t("dashboard"),
        icon: <DashboardIcon style={iconStyle}/>,
        url: DASHBOARD_BASE_URL,
        disabled: !userHasAccess,
    },
    {
        label: t("events"),
        icon: <ProductTypeIcon type={PRODUCT_TYPE_EVENT} iconStyle={iconStyle}/>,
        url: EVENTS_BASE_URL,
        disabled: !userHasAccess,
        subItems: subItems.filter(subItem => subItem.parent === "events" && roleValue >= subItem.requiredRole),
    },
    {
        label: t("entrance"),
        icon: <ProductTypeIcon type={PRODUCT_TYPE_ENTRANCE_TICKET} iconStyle={iconStyle}/>,
        url: ENTRANCE_BASE_URL,
        disabled: !userHasAccess,
        subItems: subItems.filter(subItem => subItem.parent === "entrance" && roleValue >= subItem.requiredRole),
    },
    {
        label: t("seasonalPasses"),
        icon: <ProductTypeIcon type={PRODUCT_TYPE_SEASONAL_PASS} iconStyle={iconStyle}/>,
        url: SEASONAL_PASSES_BASE_URL,
        disabled: !userHasAccess,
        subItems: subItems.filter(subItem => subItem.parent === "seasonalPasses" && roleValue >= subItem.requiredRole),
    },
    {
        label: t("jointDiscounts"),
        icon: <LocalOffer style={iconStyle}/>,
        url: JOINT_PRODUCTS_DISCOUNT_BASE_URL,
        disabled: !userHasAccess || roleValue < ROLES_VALUES.EDITOR,
        subItems: subItems.filter(subItem => subItem.parent === "jointProductsDiscounts" && roleValue >= subItem.requiredRole),
    },
    {
        label: t("sales"),
        icon: <ReceiptIcon style={iconStyle}/>,
        url: SALES_BASE_URL,
        disabled: !userHasAccess,
        subItems: subItems.filter(subItem => subItem.parent === "sales" && roleValue >= subItem.requiredRole),
    },
    {
        label: t("settings"),
        icon: <SettingsIcon style={iconStyle}/>,
        url: SETTINGS_BASE_URL,
        disabled: !userHasAccess || roleValue < ROLES_VALUES.EDITOR,
        subItems: subItems.filter(subItem => subItem.parent === "settings" && roleValue >= subItem.requiredRole),
    },
]

export const Menu = () => {
    const classes = useStyles()
    const {userIsAuthenticated, userHasAccess} = useAuthsState()
    const t = useMenuTranslation()
    const {menuOpen} = useHeaderState()
    const dispatch = useHeaderDispatch()
    const {currentBaseUrl} = useBaseContextState()
    const history = useHistory()
    const {pathname} = useLocation()
    const {selectedMuseum} = useMuseumSelectorState()
    const menuItems = getMenuItems(t, userHasAccess, getSubMenuItems(t), selectedMuseum?.role?.value)
    const [hidden, setHidden] = useState(false)
    const [expanded, setExpanded] = useState(null)

    useEffect(() => {
        if (userHasAccess) {
            setExpanded(currentBaseUrl)
        }
    }, [currentBaseUrl, userHasAccess])

    useEffect(() => setHidden(pathname.endsWith("/edit")), [pathname])

    const handleExpandedChange = (key, url) => (event, newExpanded) => {
        onMenuClick(url, false)
        setExpanded(newExpanded ? key : false)
    }

    const onMenuClick = (url, closeMenu = true) => {
        if (closeMenu) {
            dispatch({type: MENU_CLOSED})
        }
        history.push(url)
    }

    const drawer = (
        <div className={classes.drawer}>
            <Hidden smDown>
                <Toolbar/>
            </Hidden>
            {userHasAccess && (
                <>
                    <MuseumSelector/>
                    <NewContentButton/>
                    <Divider/>
                </>
            )}
            {menuItems.map(({label, icon, url, subItems, disabled = false}, index) => (
                <MenuItem key={index}
                          itemKey={url}
                          label={label}
                          disabled={disabled}
                          onMenuClick={onMenuClick}
                          url={url}
                          icon={icon}
                          expanded={expanded}
                          subItems={subItems}
                          handleExpandedChange={handleExpandedChange}/>
            ))}
            <div style={{width: "100%", display: "flex", justifyContent: "flex-end", paddingTop: 8, paddingRight: 8}}>
                <Typography variant="caption"
                            color="textSecondary">
                    {t('role')}: {_.toUpper(selectedMuseum?.role?.name)} {selectedMuseum?.role?.value === ROLES_VALUES.USER ? "(kun lesetilgang)" : ""}
                </Typography>
            </div>
        </div>
    )

    return userIsAuthenticated ? (
        <nav className={hidden ? undefined : classes.drawer}>
            <Hidden smUp
                    implementation="css">
                <Drawer variant="temporary"
                        open={menuOpen}
                        onClose={onMenuClick}
                        classes={{paper: classes.drawerPaper}}
                        ModalProps={{keepMounted: true}}>
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden smDown
                    implementation="css">
                {!hidden && (
                    <Drawer variant="permanent"
                            PaperProps={{elevation: 4}}
                            classes={{paper: classes.drawerPaper}}>
                        {drawer}
                    </Drawer>
                )}
            </Hidden>
        </nav>
    ) : null;
}
