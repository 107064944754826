import {Card, CardContent} from "@mui/material"

import React, {useEffect, useState} from "react"
import SectionHeader from "../../app/components/SectionHeader"
import {SALES_BASE_URL} from "../../app/Routes"
import {useSalesTranslation} from "../salesTranslationContext"
import {calculateHeight} from "../../app/helper-functions/calculateHeight"
import {CustomerTable} from "../../app/customer-table/CustomerTable"
import CustomerDetailsDialog from "../../app/customer-details/CustomerDetailsDialog"

export const CustomersList = () => {
    const t = useSalesTranslation()

    const [customerDialogOpen, setCustomerDialogOpen] = useState(false)
    const [selectedCustomerId, setSelectedCustomerId] = useState()
    const [refetchCustomers, setRefetchCustomers] = useState(false)

    const breadcrumbs = [
        {label: t("sales"), url: SALES_BASE_URL},
        {label: t("customers")},
    ]

    useEffect(() => {
        if (selectedCustomerId) {
            setCustomerDialogOpen(true)
        }
    }, [selectedCustomerId])

    const handleCustomerDialogClose = () => {
        setCustomerDialogOpen(false)
        setSelectedCustomerId(null)
        setRefetchCustomers(!refetchCustomers)
    }

    const handleCustomerIdClick = (id) => {
        setSelectedCustomerId(id)
    }

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}/>
            <div style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: calculateHeight(false, true, true),
            }}>
                <Card style={{flex: 1, height: "100%"}}>
                    <CardContent>
                        <CustomerTable refetchCustomers={refetchCustomers}
                                       handleCustomerIdClick={handleCustomerIdClick}/>
                    </CardContent>
                    <CustomerDetailsDialog customerId={selectedCustomerId}
                                           open={customerDialogOpen}
                                           handleClose={handleCustomerDialogClose}/>
                </Card>
            </div>
        </>
    )
}