import React from "react"
import {Grid, Paper, Typography} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"

const testCards = [
    {
        name: "Visa (Debit)",
        cardNumber: 4925000000000004,
        expiryDate: "Vilkårlig, fremtidig dato",
        verificationCode: "Tre vilkårlige siffer",
    },
    {
        name: "Mastercard (Debit)",
        cardNumber: 5413000000000000,
        expiryDate: "Vilkårlig, fremtidig dato",
        verificationCode: "Tre vilkårlige siffer",
    },
]

const TestCards = ({hidden}) => {
    const t = useBaseTranslation()

    return hidden ? null : (
        <>
            <Typography variant="subtitle2"
                        style={{marginTop: "16px"}}
                        color="primary">Testkort til bruk i Netaxeptterminal</Typography>
            <Grid container
                  spacing={2}>
                {testCards.map((card, index) => (
                    <Grid key={index}
                          item>
                        <Paper style={{padding: "8px", backgroundColor: "#ff8989"}}
                               elevation={5}>
                            <Grid container
                                  direction="column">
                                <Grid item>
                                    <Typography variant="subtitle2">
                                        <strong>{card.name}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        <strong>{`${t("cardNumber")}: `}</strong> {card.cardNumber}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        <strong>{`${t("expiryDate")}: `}</strong> {card.expiryDate}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        <strong>{`${t("verificationCode")}: `}</strong> {card.verificationCode}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default TestCards