import React, {useRef, useState} from "react"
import {ClickAwayListener, Grow, Paper, Popper, Tooltip, Typography} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import {Palette} from "@mui/icons-material"
import {SketchPicker} from "react-color"
import {useBaseTranslation} from "../baseTranslationContext"

const ButtonWithColorPicker = ({colorButtonTooltip, color, handleColorChange}) => {
    const t = useBaseTranslation()
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const anchorRef = useRef(null)

    const handleColorPickerClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setDisplayColorPicker(false)
    }

    const handleColorPickerChangeComplete = ({hex}) => {
        handleColorChange(hex)
    }

    const getIconButton = () => (
        <IconButton
            style={{color: color}}
            ref={anchorRef}
            onClick={() => setDisplayColorPicker(!displayColorPicker)}
            size="large">
            <Palette/>
        </IconButton>
    )

    return (
        <>
            <Typography variant="caption"
                        color="textSecondary">{t("chooseColor")}</Typography>
            {colorButtonTooltip
             ? <Tooltip title={colorButtonTooltip}>
                 {getIconButton()}
             </Tooltip>
             : getIconButton()}
            <Popper open={displayColorPicker}
                    style={{zIndex: 1500}}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow {...TransitionProps}
                          style={{
                              transformOrigin: placement === "bottom"
                                               ? "center top"
                                               : "center bottom",
                          }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleColorPickerClose}>
                                <SketchPicker disableAlpha
                                              color={color}
                                              onChangeComplete={handleColorPickerChangeComplete}/>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default ButtonWithColorPicker