import React, {useEffect, useState} from "react"
import {Button, Grid, Slider, TextField, Typography, useTheme} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import _ from "lodash/fp"
import {bpnAPI, useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {lowerFirst} from "lodash"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import ButtonWithColorPicker from "../../../app/components/ButtonWithColorPicker"
import UploadMediaButton from "../../../app/product-editor/general-settings/UploadMediaButton"
import axios from "axios"
import SaveButtonWithProgressAndLabel from "../../../app/components/SaveButtonWithProgressAndLabel"
import makeStyles from '@mui/styles/makeStyles';
import {Delete} from "@mui/icons-material"
import {defaultPortalDesignSettings} from "../UserPortalSettings"

const useStyles = makeStyles((theme) => ({
    inputField: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}))

const UserPortalDesignSettings = ({designSettings, getMuseumSettings}) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const {selectedMuseum, umbrellaName} = useMuseumSelectorState()
    const [portalDesignSettings, setPortalDesignSettings] = useState(defaultPortalDesignSettings)
    const [uploadingBackgroundImage, setUploadingBackgroundImage] = useState()
    const [uploadBackgroundProgress, setUploadBackgroundProgress] = useState(0)
    const snackbar = useCustomSnackbar()
    const theme = useTheme()

    const [
        {
            data: portalDesignSettingsPutData,
            loading: portalDesignSettingsPutLoading,
            error: portalDesignSettingsPutError,
        }, putPortalDesignSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    const [
        {loading: portalDesignSettingsDeleteLoading}, deletePortalDesignSettings,
    ] = useAxiosBpn({method: "DELETE"}, {manual: true})

    useEffect(() => {
        if (portalDesignSettingsPutData) {
            snackbar.showSuccess(`Kjøprsportal innstillinger for ${selectedMuseum?.name} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
    }, [selectedMuseum, portalDesignSettingsPutData, t, umbrellaName, snackbar, getMuseumSettings])

    useEffect(() => {
        if (designSettings) {
            setPortalDesignSettings(designSettings)
        } else {
            setPortalDesignSettings(defaultPortalDesignSettings)
        }
    }, [designSettings])

    useEffect(() => {
        if (portalDesignSettingsPutError) {
            snackbar.showError(t(portalDesignSettingsPutError.message))
        }
    }, [portalDesignSettingsPutError, t, snackbar])

    const handleMuseumUrlSave = () => {
        const id = portalDesignSettings?.museumId ? portalDesignSettings.museumId : selectedMuseum?.id
        putPortalDesignSettings({
            url: `portal_design_settings/${id}`,
            data: {
                backgroundImageUrl: portalDesignSettings.backgroundImageUrl,
                pageBackgroundColor: portalDesignSettings.pageBackgroundColor,
                containerBackgroundColor: portalDesignSettings.containerBackgroundColor,
                buttonColor: portalDesignSettings.buttonColor,
                borderRounding: portalDesignSettings.borderRounding,
                imageBrightness: portalDesignSettings.imageBrightness,
                imageBlur: portalDesignSettings.imageBlur,
                imageSaturation: portalDesignSettings.imageSaturation,
                svgMuseumLogo: portalDesignSettings.svgMuseumLogo
                               ? addXmlnsIfMissing(portalDesignSettings.svgMuseumLogo)
                               : "",
            },
        })
    }

    const addXmlnsIfMissing = (svgLogoString) => {
        let svgCodeArray = svgLogoString.split(" ")
        const hasXmlnsSet = !!svgCodeArray.find(element => element.toLowerCase().startsWith("xmlns"))
        if (!hasXmlnsSet) {
            svgCodeArray.splice(1, 0, `xmlns="http://www.w3.org/2000/svg"`)
        }

        return svgCodeArray.join(" ")
    }

    const handleDelete = () => {
        const id = portalDesignSettings?.museumId ? portalDesignSettings.museumId : selectedMuseum?.id
        deletePortalDesignSettings({
            url: `portal_design_settings/${id}/delete`,
        })
        setPortalDesignSettings(defaultPortalDesignSettings)
    }

    const uploadImage = async (image) => {
        setUploadBackgroundProgress(0)
        if (!image) {
            throw new Error("File must be provided!")
        }

        const uploadTo = await bpnAPI.get(`media/image/order_upload_url/${encodeURI(image?.name)}`)

        if (!uploadTo?.data?.upload_url) {
            throw uploadTo
        }

        // Upload the media
        const headers = new Headers({
            "Content-Type": image.type,
            "Slug": image.name,
        })

        const config = {
            method: "put",
            data: image.file,
            headers,
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setUploadBackgroundProgress(percentCompleted * 0.9)
            },
        }

        try {
            await axios.put(uploadTo.data.upload_url, image.file, config)
        } catch (e) {
            console.log(e)
        }

        await new Promise(r => setTimeout(r, 3000))
        setUploadBackgroundProgress(100)
        return `${uploadTo.data.url}?dimension=max`
    }

    const handleSettingsChange = (e) => {
        setPortalDesignSettings(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleSliderChange = (event, newValue, prop) => {
        setPortalDesignSettings(prev => ({...prev, [prop]: newValue}))
    }

    const handleColorChange = (property, color) => {
        setPortalDesignSettings(prev => ({...prev, [property]: color}))
    }

    const handleImageChange = async (image, imageType) => {
        if (imageType === "pageBackground") {
            setUploadingBackgroundImage(true)
            const imageUrl = await uploadImage(image)
            setUploadingBackgroundImage(false)
            setPortalDesignSettings(prev => ({
                ...prev,
                "pageBackgroundImage": image ? image : null,
                "backgroundImageUrl": imageUrl,
            }))
        }
    }

    const handleDeleteProductImage = () => {
        setPortalDesignSettings(prev => ({...prev, "backgroundImageUrl": null, "pageBackgroundImage": null}))
    }

    const isValidHexCode = (hexCode) => {
        if (!!!hexCode) {
            return true
        }

        const validHexCodeFormat = /^#([0-9a-f]{3}){1,2}$/i
        return validHexCodeFormat.test(hexCode)
    }

    return (
        <>
            <Grid container
                  spacing={3}>
                <Grid container
                      item
                      xs={12}
                      direction="column"
                      spacing={2}>
                    <Grid item
                          xs>
                        <Typography variant={"h6"}>{t("museumSvgLogo")}:</Typography>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <TextField variant="outlined"
                                       size="small"
                                       multiline={true}
                                       minRows={5}
                                       maxRows={10}
                                       style={{width: "70%"}}
                                       className={classes.inputField}
                                       name={"svgMuseumLogo"}
                                       value={portalDesignSettings?.svgMuseumLogo || ""}
                                       onChange={handleSettingsChange}/>
                        </div>
                    </Grid>
                    <Grid item
                          xs>
                        <Typography variant={"h6"}>{t("colorPalette")}:</Typography>
                        <Typography>{t("backgroundColor")}</Typography>
                        <Typography variant={"body2"}>{t("backgroundColorHelperText")}</Typography>
                        <TextField variant="outlined"
                                   size="small"
                                   className={classes.inputField}
                                   name={"pageBackgroundColor"}
                                   value={portalDesignSettings?.pageBackgroundColor || ""}
                                   helperText={isValidHexCode(portalDesignSettings?.pageBackgroundColor) ? "" : t("colorHexCodeInputHelperText")}
                                   onChange={handleSettingsChange}/>
                        <ButtonWithColorPicker color={portalDesignSettings?.pageBackgroundColor || ""}
                                               handleColorChange={(color) => handleColorChange("pageBackgroundColor",
                                                   color)}/>
                    </Grid>

                    <Grid item
                          xs>
                        <Typography>{t("containerBackgroundColor")}</Typography>
                        <TextField variant="outlined"
                                   size="small"
                                   name={"containerBackgroundColor"}
                                   helperText={isValidHexCode(portalDesignSettings?.containerBackgroundColor) ? "" : t("colorHexCodeInputHelperText")}
                                   className={classes.inputField}
                                   value={portalDesignSettings?.containerBackgroundColor || ""}
                                   onChange={handleSettingsChange}/>
                        <ButtonWithColorPicker color={portalDesignSettings?.containerBackgroundColor || ""}
                                               handleColorChange={(color) => handleColorChange(
                                                   "containerBackgroundColor",
                                                   color)}/>
                    </Grid>

                    <Grid item
                          xs>
                        <Typography>{t("buttonColor")}</Typography>
                        <TextField variant="outlined"
                                   size="small"
                                   name={"buttonColor"}
                                   className={classes.inputField}
                                   value={portalDesignSettings?.buttonColor || ""}
                                   helperText={isValidHexCode(portalDesignSettings?.buttonColor) ? "" : t("colorHexCodeInputHelperText")}
                                   onChange={handleSettingsChange}/>
                        <ButtonWithColorPicker color={portalDesignSettings?.buttonColor || ""}
                                               handleColorChange={(color) => handleColorChange("buttonColor", color)}/>
                    </Grid>


                    <Grid item
                          xs>
                        <Typography variant={"h6"}>{t("backgroundImage")}:</Typography>
                        <div style={{display: "flex", gap: "2rem"}}>
                            <UploadMediaButton isUploading={uploadingBackgroundImage}
                                               mediaItem={portalDesignSettings?.pageBackgroundImage}
                                               currentImageUrl={portalDesignSettings?.backgroundImageUrl
                                                                ? portalDesignSettings?.backgroundImageUrl
                                                                : ""}
                                               onImageSelected={image => handleImageChange(image, "pageBackground")}
                                               deleteProductImage={() => handleDeleteProductImage()}
                                               imageFilters={{
                                                   brightness: portalDesignSettings.imageBrightness,
                                                   blur: portalDesignSettings.imageBlur,
                                                   saturation: portalDesignSettings.imageSaturation,
                                               }}
                                               uploadProgress={uploadBackgroundProgress}/>
                            <div style={{width: "300px"}}>
                                <div>
                                    <Typography variant={"body1"}
                                                fontWeight={"bold"}>{t("imageBrightness")}:</Typography>
                                    <Slider
                                        value={portalDesignSettings?.imageBrightness}
                                        onChange={(e, newValue) => handleSliderChange(e, newValue, "imageBrightness")}
                                        step={0.1}
                                        min={0}
                                        max={1}
                                        defaultValue={portalDesignSettings?.imageBrightness}
                                        color={"primary"}
                                        marks
                                    />
                                </div>

                                <div>
                                    <Typography variant={"body1"} fontWeight={"bold"}>{t("imageBlur")}:</Typography>
                                    <Slider
                                        value={portalDesignSettings?.imageBlur}
                                        onChange={(e, newValue) => handleSliderChange(e, newValue, "imageBlur")}
                                        step={1}
                                        min={0}
                                        max={20}
                                        defaultValue={portalDesignSettings?.imageBlur}
                                        color={"primary"}
                                        marks
                                    />
                                </div>

                                <div>
                                    <Typography variant={"body1"}
                                                fontWeight={"bold"}>{t("imageSaturation")}:</Typography>
                                    <Slider
                                        value={portalDesignSettings?.imageSaturation}
                                        onChange={(e, newValue) => handleSliderChange(e, newValue, "imageSaturation")}
                                        step={0.1}
                                        min={0}
                                        max={1}
                                        defaultValue={portalDesignSettings?.imageSaturation}
                                        color={"primary"}
                                        marks
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item
                          xs>
                        <Typography variant={"h6"}>{t("containerBorderRadius")}:</Typography>
                        <Slider
                            value={portalDesignSettings?.borderRounding}
                            onChange={(e, newValue) => handleSliderChange(e, newValue, "borderRounding")}
                            step={0.1}
                            min={0}
                            max={1.5}
                            style={{width: "300px"}}
                            defaultValue={portalDesignSettings?.borderRounding}
                            aria-labelledby="input-slider"
                            color={"primary"}
                            marks
                        />

                        <div style={{
                            width: "200px",
                            height: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: `${portalDesignSettings?.borderRounding}rem`,
                        }}>
                            {portalDesignSettings?.borderRounding ?
                             <Typography variant={"h5"}>{`${portalDesignSettings?.borderRounding}REM`}</Typography> :
                             <Typography variant={"h5"}>{`0.0REM`}</Typography>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button onClick={handleDelete}
                                disabled={portalDesignSettingsDeleteLoading}
                                startIcon={<Delete/>}>
                            {t("delete")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <SaveButtonWithProgressAndLabel unsavedChanges={!_.isEqual(portalDesignSettings,
                            designSettings)}
                                                        handleSave={handleMuseumUrlSave}
                                                        loading={portalDesignSettingsPutLoading}/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default UserPortalDesignSettings
