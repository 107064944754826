import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import {useMuseumSelectorState} from "../../museum-selector/museumSelectorContext"
import {CardActions, Chip, FormControlLabel, Grid, Link, Switch, Tooltip} from "@mui/material"
import {useHistory} from "react-router-dom"
import {SETTINGS_BASE_URL} from "../../Routes"
import {useAxiosBpn} from "../../axios"
import Accordion from "@mui/material/Accordion"
import ProductPriceSummary from "./ProductPriceSummary"
import AccordionActions from "@mui/material/AccordionActions"
import DeleteIcon from "@mui/icons-material/Delete"
import {LocalOffer, LockOpen} from "@mui/icons-material"
import { Alert, AlertTitle } from '@mui/material';
import ProductPriceDetails from "./ProductPriceDetails"
import AlertBox from "../../components/AlertBox"
import {ROLES_VALUES} from "../../menu/MuseumSelector"

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        marginLeft: "auto",
    },
    deleteButton: {
        background: "#d32f2f",
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: "white",
        "&:hover": {
            background: "#9a0007",
        },
    },
}))

const ProductCategoriesAndPrices = ({
                                        product,
                                        setAlert,
                                        unsavedChanges,
                                        setUnsavedChanges,
                                        updateProductState,
                                        refetch,
                                    }) => {
    const arrayMove = require("array-move")
    const t = useProductEditorTranslation()
    const classes = useStyles()
    const history = useHistory()

    const {umbrellaName, umbrellaId, selectedMuseum} = useMuseumSelectorState()

    const [prices, setPrices] = useState([])
    const [priceModule, setPriceModule] = useState()
    const [expanded, setExpanded] = useState(false)
    const [priceCategories, setPriceCategories] = useState([])
    const [kdrEnabledForProduct, setKdrEnabledForProduct] = useState(false)
    const [kdrTicketCodesEnabledForProduct, setKdrTicketCodesEnabledForProduct] = useState(false)
    const [duellTicketCodesEnabledForProduct, setDuellTicketCodesEnabledForProduct] = useState(false)
    const [kdrTicketDomainId, setKdrTicketDomainId] = useState()
    const [kdr, setKdr] = useState({enabled: false})
    const [kdrMenuId, setKdrMenuId] = useState()
    const [kdrLocked, setKdrLocked] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false)

    const [{data: priceCategoriesData}, getPriceCategories] = useAxiosBpn({
        url: "price_categories",
        params: {museumId: umbrellaId},
    })
    const [{data: kdrData}, getKdr] = useAxiosBpn(
        {url: `point_of_sale_systems/kdr/museum/${umbrellaId}`},
        {manual: true})
    const [{data: kdrLocalSettingsData}, getKdrLocalSettings] = useAxiosBpn({
        url: `point_of_sale_systems/kdr/museum/${product?.museumId}/local_settings`,
    }, {
        manual: true,
    })

    useEffect(() => {
        if (refetch && umbrellaId) {
            getKdr().catch(() => {})
            getPriceCategories().catch(() => {})
        }

        getKdrLocalSettings().catch(() => {})
    },[getKdr, getKdrLocalSettings, getPriceCategories, refetch, umbrellaId])

    useEffect(() => {
        if (umbrellaId) {
            getKdr().catch(() => {})
            getPriceCategories().catch(() => {})
        }
    }, [getKdr, getPriceCategories, umbrellaId])

    useEffect(() => {
            getKdrLocalSettings().catch(() => {})
    }, [getKdrLocalSettings])

    useEffect(() => {
        if (!unsavedChanges && (kdrEnabledForProduct || kdrTicketCodesEnabledForProduct) && (product?.unitsSold || product?.unitsSoldTest)) {
            setKdrLocked(true)
        }
    }, [unsavedChanges, product, kdrEnabledForProduct, kdrTicketCodesEnabledForProduct])

    useEffect(() => {
        let severity = "info"
        let alerts = []

        if (kdr?.enabled && !kdrMenuId) {
            severity = "warning"
            alerts.push({
                severity: "warning",
                text:
                    <>
                        KDR er aktivert for <strong>{umbrellaName}</strong>, men ingen menyID er satt
                        for <u>{selectedMuseum.name}</u>.<br/> <br/>
                        Gå til <i>INNSTILLINGER > BETALING</i> for å sette menyID.
                    </>,
            })
        }

        if (kdrEnabledForProduct) {
            if (!product?.kdrMenuId) {
                severity = "error"
                alerts.push({severity: "error", text: "KDR er aktivert men menyID mangler på produktet"})
            }
            if (product?.kdrMenuId !== kdrMenuId) {
                severity = "error"
                alerts.push({
                    severity: "error",
                    text:
                        <>
                            MenyID satt for <u>{selectedMuseum.name}</u> i innstillinger er ikke det
                            samme som menyID lagret på dette produktet.<br/><br/>
                            Kanskje IDen er endret etter at produktet ble opprettet?<br/><br/>
                            For å friske opp menyID på dette produktet, forsøk å skru av og på bryter
                            for KDR-integrasjon, og deretter lagre produktet på nytt.
                        </>,
                })
            }
        }
        if (alerts.length) {
            setAlert({
                severity: severity,
                component: <>
                    {alerts.map(({severity, text}, index) => (
                        <AlertBox key={index}
                                  severity={severity}
                                  text={text}/>
                    ))}
                </>,
            })
        } else {
            setAlert(undefined)
        }
    }, [kdr, kdrEnabledForProduct, kdrMenuId, product, selectedMuseum, setAlert, umbrellaName])

    useEffect(() => setPriceCategories(priceCategoriesData?.priceCategories), [priceCategoriesData])
    useEffect(() => setKdrMenuId(kdrLocalSettingsData?.kdrLocalSettings?.menuId), [kdrLocalSettingsData])
    useEffect(() => setIsGlobalAdmin(selectedMuseum?.role?.value === ROLES_VALUES.GLOBAL_ADMIN || selectedMuseum?.role?.value === ROLES_VALUES.APP_ADMIN), [selectedMuseum])
    useEffect(() => setIsAdmin(selectedMuseum?.role?.value === ROLES_VALUES.ADMIN), [selectedMuseum])

    useEffect(() => {
        if (kdrData) {
            const {pointOfSaleSystemKdr} = kdrData
            setKdr(pointOfSaleSystemKdr)

            if (pointOfSaleSystemKdr?.enabled && product?.museumId) {
                if (!kdrMenuId) {
                    getKdrLocalSettings().catch(() => {})
                }
                if (pointOfSaleSystemKdr?.id === product?.pointOfSaleSystemId) {
                    setKdrEnabledForProduct(true)
                } else {
                    setKdrEnabledForProduct(false)
                }
            }
        } else {
            setKdrEnabledForProduct(false)
        }
    }, [getKdrLocalSettings, kdrData, product, kdrMenuId])

    useEffect(() => {
        setPrices(product?.prices || [])
        setPriceModule(product?.priceModule)
        setKdrTicketCodesEnabledForProduct(!!product?.kdrTicketCodesEnabled)
        setDuellTicketCodesEnabledForProduct(!!product?.duellTicketCodesEnabled)
        setKdrTicketDomainId(product?.kdrTicketDomainId)
    }, [product])

    const _updateProductState = (newPrices) => {
        updateProductState({
            ...product,
            prices: newPrices,
            priceModule: priceModule,
        })

        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const updatePricesSortOrder = newPrices => {
        for (let i = 0; i < newPrices?.length; i++) {
            newPrices[i].sortOrder = i
        }
        return newPrices
    }

    const handleExpandedChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false)
    }

    const handlePriceModuleChange = (e) => {
        e.persist()
        setPriceModule(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handlePricesChange = (index, price) => {
        let newPrices = prices
        newPrices[index] = price
        _updateProductState(newPrices)
    }

    const handlePriceDelete = index => {
        let newPrices = prices
        newPrices.splice(index, 1)
        setExpanded(false)
        _updateProductState(newPrices)
    }

    const handleNewPrice = () => {
        let newPrices = prices

        newPrices.push({
            amount: 0,
            name: {value: "", translations: []},
            description: {value: "", translations: []},
        })

        setExpanded(prices?.length - 1)
        _updateProductState(newPrices)
    }

    const handleKdrEnabledForProductChange = () => {
        updateProductState({
            ...product,
            kdrMenuId: kdrEnabledForProduct ? null : kdrMenuId,
            pointOfSaleSystemEnabled: !kdrEnabledForProduct,
            pointOfSaleSystemId: kdrEnabledForProduct ? null : kdr.id,
        })
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleKdrTicketCodesEnabledForProductChange = () => {
        updateProductState({
            ...product,
            kdrTicketCodesEnabled: !kdrTicketCodesEnabledForProduct,
        })
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleDuellTicketCodesEnabledForProductChange = () => {
        updateProductState({
            ...product,
            duellTicketCodesEnabled: !duellTicketCodesEnabledForProduct,
        })
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleDomainIdChange = ({target: {value}}) => {
        updateProductState({
            ...product,
            kdrTicketDomainId: value,
        })
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const onDragEnd = ({destination, source}) => {
        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        const newPrices = arrayMove(prices, source.index, destination.index)

        // Set prices temporarily to avoid flicker in GUI
        setPrices(newPrices)

        _updateProductState(updatePricesSortOrder(newPrices))
    }

    const generateDraggableId = ({productPriceCategoryId: id, name}, index) => {
        return `id-${name?.value?.toLowerCase().replace(/\\s+/g, " ").trim() | 0}-${id | 0}-${index}`;
    }

    const getDragDropContext = () => (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppablePrices">
                {provided => <div ref={provided.innerRef}
                                  {...provided.droppableProps}>
                    {prices.map((price, index) => (
                        <Draggable draggableId={generateDraggableId(price, index)}
                                   key={index}
                                   index={index}>
                            {provided => (
                                <Accordion ref={provided.innerRef}
                                           {...provided.draggableProps}
                                           expanded={expanded === index}
                                           onChange={handleExpandedChange(index)}>
                                    <ProductPriceSummary price={price}
                                                         provided={provided}
                                                         expanded={expanded}
                                                         kdrEnabled={false}
                                                         index={index}/>
                                    <ProductPriceDetails price={price}
                                                         isGlobalAdmin={isGlobalAdmin}
                                                         kdrTicketCodesEnabled={false}
                                                         priceCategories={priceCategories}
                                                         kdrEnabled={false}
                                                         kdrMenuId={kdrMenuId}
                                                         kdrPosId={kdr ? kdr.id : null}
                                                         handlePricesChange={handlePricesChange}
                                                         index={index}/>
                                    <AccordionActions>
                                        <Button className={classes.deleteButton}
                                                variant="contained"
                                                size="small"
                                                startIcon={<DeleteIcon/>}
                                                onClick={() => handlePriceDelete(index)}>
                                            {t("delete")}
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            )}
                        </Draggable>))
                    }
                    {provided.placeholder}
                </div>
                }
            </Droppable>
        </DragDropContext>
    )

    return <>
        {priceModule &&
            <form noValidate
                  autoComplete="off"
                  onBlur={() => _updateProductState(prices)}>
                <TextField label={t("title")}
                           className={classes.textfield}
                           name="title"
                           variant="filled"
                           fullWidth
                           value={priceModule.title || ""}
                           helperText={t("productCategoriesAndPricesTitleHelperText")}
                           onChange={handlePriceModuleChange}/>
                <TextField label={t("description")}
                           className={classes.textfield}
                           name="description"
                           variant="filled"
                           fullWidth
                           multiline
                           value={priceModule.description || ""}
                           helperText={t("productCategoriesAndPricesDescriptionHelperText")}
                           onChange={handlePriceModuleChange}/>
            </form>}

{/*        {kdr && kdr.enabled &&
            <Grid container
                  justifyContent="space-between">
                <Grid item>
                    <FormControlLabel label={t("kdrEnabledForProduct")}
                                      labelPlacement="end"
                                      control={
                                          <Switch checked={kdrEnabledForProduct}
                                                  disabled={kdrLocked}
                                                  onChange={handleKdrEnabledForProductChange}/>
                                      }/>
                </Grid>
                <Grid item>
                    <Chip label={<Tooltip title={t(kdrEnabledForProduct ? "active" : "notActive")}>
                        <div>KDR</div>
                    </Tooltip>}
                          size="small"
                          style={{
                              backgroundColor: kdrEnabledForProduct ? "green" : "lightgray",
                              color: kdrEnabledForProduct ? "white" : "gray",
                          }}/>
                </Grid>
            </Grid>
        }*/}
        {(isAdmin || isGlobalAdmin) && (
            <Grid container
                  alignItems="center"
                  justifyContent="space-between">
                <Grid item>
                    <FormControlLabel label={t("duellCompatibleTicketCodes")}
                                      labelPlacement="end"
                                      control={
                                          <Switch checked={duellTicketCodesEnabledForProduct}
                                                  onChange={handleDuellTicketCodesEnabledForProductChange}/>
                                      }/>
                </Grid>
                {/*<Grid item>
                    <FormControlLabel label={t("kdrCompatibleTicketCodes")}
                                      labelPlacement="end"
                                      control={
                                          <Switch checked={kdrTicketCodesEnabledForProduct}
                                                  disabled={kdrLocked}
                                                  onChange={handleKdrTicketCodesEnabledForProductChange}/>
                                      }/>
                </Grid>
                {kdrTicketCodesEnabledForProduct && (
                    <Grid item>
                        <TextField label={t("domainId")}
                                   disabled={kdrLocked}
                                   required
                                   error={!kdrTicketDomainId}
                                   name="kdrTicketDomainId"
                                   variant="outlined"
                                   size="small"
                                   onChange={handleDomainIdChange}
                                   value={kdrTicketDomainId || ""}/>
                    </Grid>
                )}
                <Grid item>
                    <Chip size="small"
                          label={
                              <Tooltip title={t(kdrTicketCodesEnabledForProduct ? "active" : "notActive")}>
                                  <div>{t("ticketCodes")}</div>
                              </Tooltip>
                          }
                          style={{
                              backgroundColor: kdrTicketCodesEnabledForProduct ? "green" : "lightgray",
                              color: kdrTicketCodesEnabledForProduct ? "white" : "gray",
                          }}/>
                </Grid>*/}
            </Grid>
        )}
        {/*{kdrLocked && (
            <Alert severity="error"
                   style={{marginTop: 8}}
                   action={
                       <Button color="inherit"
                               onClick={() => setKdrLocked(false)}
                               startIcon={<LockOpen/>}>
                           <Typography variant="button"
                                       noWrap>
                               Lås opp
                           </Typography>
                       </Button>}>
                <AlertTitle>KDR-integrasjon er fortsatt i beta!</AlertTitle>
                Siden det allerede har blitt solgt billetter til dette produktet, kan deaktivering av KDR føre
                til
                uforutsette problemer og <i>bør unngås hvis mulig.</i> <br/><br/>
                Hvis det likevel er behov, kan bryteren over låses opp midlertidig.
            </Alert>
        )}*/}
        <Typography className={classes.textfield}>
            {prices?.length || 0} {prices?.length && prices?.length === 1 ?
                                   t("price").toLowerCase() : t("prices").toLowerCase()}
        </Typography>

        {prices?.length ?
         getDragDropContext() : (
             priceCategories?.length === 0 ?
             <>
                 <Typography color="error">
                     {umbrellaName} {t("hasNoPriceCategories").toLowerCase() + "! "}
                     {t("notPossibleToAddPriceWithoutCategory")}!
                 </Typography>
                 <Typography>{t("goTo") + " "}
                     <Link component="button"
                           onClick={() => history.push(SETTINGS_BASE_URL)}>
                         {t("settings").toLowerCase()}
                     </Link> {t("toAddPriceCategories").toLowerCase()}.
                 </Typography>
             </> :
             <Typography>{t("noPricesYet")}! {t("clickAdd")}</Typography>
         )}
        <CardActions>
            <Button onClick={handleNewPrice}
                    variant="text"
                    color="primary"
                    disabled={priceCategories?.length === 0}
                    startIcon={<LocalOffer/>}
                    className={classes.button}>
                {t("add")}
            </Button>
        </CardActions>
    </>
}

export default React.memo(ProductCategoriesAndPrices)
