const CRITICAL = 50
const ERROR = 40
const WARNING = 30
const INFO = 20
const DEBUG = 10
const NOTSET = 0

export const levelToName = {
    [CRITICAL]: 'critical',
    [ERROR]: 'error',
    [WARNING]: 'warning',
    [INFO]: 'info',
    [DEBUG]: 'debug',
    [NOTSET]: 'notSet',
}

export const nameToLevel = {
    'CRITICAL': CRITICAL,
    'ERROR': ERROR,
    'WARN': WARNING,
    'WARNING': WARNING,
    'INFO': INFO,
    'DEBUG': DEBUG,
    'NOTSET': NOTSET,
}
