import {Grid, Typography} from "@mui/material"
import React, {useState, useEffect} from "react"

export const GridLabel = ({size = "medium", children}) => {
    const [sizeProps, setSizeProps] = useState({xs: 12, sm: 4})
    const [fontSize, setFontSize] = useState("1.0rem")

    useEffect(() => {
        if (size === "small") {
            setFontSize("0.93em")
            setSizeProps({xs: 5, sm: 3})
        }
    }, [size])

    return (
        <Grid item
              {...sizeProps}>
            <Typography variant="subtitle1"
                        style={{fontSize: fontSize}}><strong>{children}</strong></Typography>
        </Grid>
    )
}

export const GridValue = ({size = "medium", children}) => {
    const [sizeProps, setSizeProps] = useState({xs: 12, sm: 4})
    const [fontSize, setFontSize] = useState("1.0rem")

    useEffect(() => {
        if (size === "small") {
            setFontSize("0.93em")
            setSizeProps({xs: 7, sm: 9})
        }
    }, [size])

    return (
        <Grid item
              {...sizeProps}>
            <Typography variant="subtitle1"
                        style={{fontSize: fontSize}}>{children}</Typography>
        </Grid>
    )
}