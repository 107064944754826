import React, {useEffect, useState} from "react"
// noinspection ES6CheckImport
import {useHistory, useLocation} from "react-router-dom"
import {useAuthsState} from "./auths/authsContext"
import {useAxiosBpn} from "./axios"
import useCustomSnackbar from "./components/useCustomSnackbar"
import {getBaseRouteBasedOnProductType} from "./helper-functions/getBaseUrlBasedOnProductType"
import {ProgressIndicator} from "./ProgressIndicator"

export const NetaxeptCallback = ({children}) => {
    const {userIsAuthenticated} = useAuthsState()
    const history = useHistory()
    const snackbar = useCustomSnackbar()
    const {search} = useLocation()
    const [transactionId, setTransactionId] = useState()
    const [responseCode, setResponseCode] = useState()

    const [{loading, error: orderProcessError}, processOrder] = useAxiosBpn({
        url: "orders/process",
        params: {transactionId: transactionId},
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        const searchParams = new URLSearchParams(search)
        if (searchParams && userIsAuthenticated) {
            setTransactionId(searchParams.get("transactionId"))
            setResponseCode(searchParams.get("responseCode"))
        }
    }, [search, userIsAuthenticated])

    useEffect(() => {
        if (orderProcessError) {
            snackbar.showError(orderProcessError.message)
        }
    }, [orderProcessError, snackbar])

    useEffect(() => {
        if (transactionId) {
            console.log("Netaxept: callback received", {transactionId: transactionId, responseCode: responseCode})

            if (responseCode === "OK") {
                console.log("Order process: executing...")
                processOrder().then(({data: {order}}) => {
                    if (order.orderLines && order.orderLines.length) {
                        const {productType, productId} = order.orderLines[0]
                        history.push(`${getBaseRouteBasedOnProductType(productType)}/${productId}/details`)
                    }
                })
            }
        }
    }, [processOrder, history, responseCode, transactionId])

    return (
        <>
            {loading
             ? <ProgressIndicator/>
             : children
            }
        </>

    )
}
