import React, {useCallback, useEffect, useState} from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    Tooltip
} from "@mui/material"
import {getBaseRouteBasedOnProductType} from "../../helper-functions/getBaseUrlBasedOnProductType"
import Typography from "@mui/material/Typography"
import {dateFormat} from "../../helper-functions/dateFormat"
import {Update} from "@mui/icons-material"
import { Alert } from '@mui/material';

const ScheduleEventDetailsExistingOrdersDialog = ({
                                                      open,
                                                      product,
                                                      exception,
                                                      event,
                                                      refetch,
                                                      tryingToRemove,
                                                      orders,
                                                      handleClose,
                                                      handleAccept
                                                  }) => {

    const [productDetailsLink, setProductDetailsLink] = useState()
    const [lastUpdatedAt, setLastUpdatedAt] = useState(dateFormat(new Date(), "pp"))
    const [tryingToCancel, setTryingToCancel] = useState(false)
    const [tryingToRemoveCancellation, setTryingToRemoveCancellation] = useState(false)
    const [tryingToReschedule, setTryingToReschedule] = useState(false)
    const [tryingToRemoveRescheduling, setTryingToRemoveRescheduling] = useState(false)
    const [tryingToReduceSaleLimit, setTryingToReduceSaleLimit] = useState(false)
    const [tryingToIncreaseSaleLimit, setTryingToIncreaseSaleLimit] = useState(false)
    const [tryingToDelete, setTryingToDelete] = useState(false)
    const [ticketsSold, setTicketsSold] = useState(0)
    const [changesCount, setChangesCount] = useState(0)

    const reset = () => {
        setTryingToCancel(false)
        setTryingToRemoveCancellation(false)
        setTryingToReschedule(false)
        setTryingToRemoveRescheduling(false)
        setTryingToReduceSaleLimit(false)
        setTryingToIncreaseSaleLimit(false)
        setTryingToDelete(false)
    }

    const _handleAccept = useCallback(() => {
        reset()
        handleAccept(exception || event?.id)
    }, [exception, event, handleAccept])

    useEffect(() => {
        if (product && (event || exception)) {
            const {type, id} = product
            const baseUrl = `/${getBaseRouteBasedOnProductType(type)}/${id}/details`
            let scheduleEventId = exception ? exception.scheduleEventId : event.id
            let recurNumber = exception ? exception.recurNumber : null
            const queryParams = `?scheduleEventId=${scheduleEventId}&recurNumber=${recurNumber}&status=confirmedExceptCredited`
            setProductDetailsLink(`${baseUrl}${queryParams}`)
        }
    }, [product, exception, event])

    useEffect(() => {
        if (exception) {
            const {isCancelled, isRescheduled, isSaleLimitIncreased, isSaleLimitReduced} = exception

            if (isCancelled) tryingToRemove ? setTryingToRemoveCancellation(true) : setTryingToCancel(true)
            if (isRescheduled) tryingToRemove ? setTryingToRemoveRescheduling(true) : setTryingToReschedule(true)

            setTryingToReduceSaleLimit(isSaleLimitReduced)
            setTryingToIncreaseSaleLimit(isSaleLimitIncreased)
        } else if (event) setTryingToDelete(true)
    }, [exception, event, tryingToRemove])

    useEffect(() => {
        let count = 0
        if (tryingToCancel) count += 1
        if (tryingToRemoveCancellation) count += 1
        if (tryingToReschedule) count += 1
        if (tryingToRemoveRescheduling) count += 1
        if (tryingToReduceSaleLimit) count += 1
        if (tryingToIncreaseSaleLimit) count += 1

        setChangesCount(count)
    }, [tryingToCancel, tryingToRemoveCancellation, tryingToReschedule, tryingToRemoveRescheduling, tryingToReduceSaleLimit, tryingToIncreaseSaleLimit])

    useEffect(() => {
        if (product && orders && (exception || event)) {
            const scheduleEventId = exception ? exception?.scheduleEventId : event?.id
            const recurNumber = exception ? exception?.recurNumber : null

            const sold = orders.reduce((allTotal, order) => {
                const sum = order?.orderLines?.reduce((total, line) => {
                    let line_count = 0
                    if (line.productId === product.id &&
                        line.scheduleEventId === scheduleEventId &&
                        (line.recurNumber === recurNumber || !recurNumber)) {
                        const {groupTicketCount, quantity} = line
                        line_count += groupTicketCount ? groupTicketCount : quantity
                    }
                    return total + line_count
                }, 0)
                return allTotal + sum
            }, 0)
            setTicketsSold(sold)
        }
    }, [product, exception, orders, event])

    useEffect(() => {
        // Removing a cancellation should not be a problem for existing orders
        if (tryingToRemoveCancellation) _handleAccept()
    }, [tryingToRemoveCancellation, _handleAccept, exception])

    useEffect(() => {
        // Increasing sale limit should not be a problem for existing orders
        if (tryingToIncreaseSaleLimit && changesCount === 1) _handleAccept()
    }, [tryingToIncreaseSaleLimit, changesCount, _handleAccept, exception])

    const handleRefresh = () => {
        refetch(exception?.scheduleEventId, exception?.recurNumber)
        setLastUpdatedAt(dateFormat(new Date(), "pp"))
    }

    const _handleClose = () => {
        reset()
        handleClose()
    }

    const getTitle = () => (
        <Grid container direction="row">
            <Grid item xs={6}>{`Eksisterende ordre${orders?.length > 1 ? "r" : ""}`}</Grid>
            <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                <Grid item>
                    <Typography variant="caption">
                        {`Sist oppdatert: ${lastUpdatedAt}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title="Sjekk på nytt">
                        <IconButton onClick={handleRefresh} size="large">
                            <Update/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    )

    const Content = () => {
        const getAction = () => {
            if (tryingToCancel) return "avlyse"
            if (tryingToDelete) return "slette"
            if (tryingToRemoveCancellation) return "fjerne et unntak som avlyser"
            if ((tryingToReschedule || tryingToRemoveRescheduling) && changesCount === 1) return "flytte"
            return `gjøre ${changesCount <= 1 ? "en endring" : "endringer"} på`
        }

        return (
            <>
                <Typography>
                    Du forsøker å {getAction()} en kalenderhendelse som det allerede er solgt {ticketsSold} billetter
                    til (
                    <strong>{orders?.length} ordre{orders?.length > 1 ? "r " : " "}</strong> vil potensielt bli berørt).
                </Typography>
                <br/>
                {(tryingToCancel) && (
                    <Alert severity="warning"
                           style={{marginBottom: 8}}>
                        Ved avlysning bør kanskje kundene få beskjed?
                    </Alert>
                )}
                {(tryingToDelete) && (
                    <Alert severity="warning"
                           style={{marginBottom: 8}}>
                        Ved sletting bør kanskje kundene få beskjed?
                    </Alert>
                )}
                {(tryingToReschedule || tryingToRemoveRescheduling) && (
                    <Alert severity="warning"
                           style={{marginBottom: 8}}>
                        Kjøpte billetter (også online) viser tidspunktet som var registrert på kalenderhendelsen i
                        kjøpsøyeblikket. Hvis tidspunktet endres, bør kanskje kundene få beskjed?
                    </Alert>
                )}
                {tryingToReduceSaleLimit && ticketsSold > exception?.saleLimit && (
                    <Alert severity="warning"
                           style={{marginBottom: 8}}>
                        Plassbegrensing reduseres til {exception?.saleLimit} men det er allerede
                        solgt {ticketsSold} billetter, noe som potensielt fører til overbooking.
                    </Alert>
                )}
                <Typography>
                    Gå til
                    <Link href={`${productDetailsLink}&tab=sales`} target="ordretabell">
                        <Button variant="text" color="primary">Ordretabellen</Button>
                    </Link><small>(åpnes i egen fane)</small> hvis det er behov for å kreditere eller kansellere
                    disse ordrene.
                </Typography>
                {exception && (
                    <Typography>
                        Gå til
                        <Link href={`${productDetailsLink}&tab=messaging`} target="kommunikasjon">
                            <Button variant="text" color="primary">kundekommunikasjon</Button>
                        </Link><small>(åpnes i egen fane)</small> hvis det er behov for å sende epost til de kundene som
                        blir berørt.
                    </Typography>
                )}
                <br/><br/>
                <Typography variant="button"><strong>Er du sikker på at du vil {getAction()} denne
                                                     kalenderhendelsen?</strong></Typography>
            </>
        )
    }

    return (
        <Dialog open={open}
                fullWidth
                maxWidth="md"
                onClose={_handleClose}>
            <DialogTitle>{getTitle()}</DialogTitle>
            <DialogContent>
                {orders?.length
                    ? <Content/>
                    : <>
                        <Typography>Det er ikke lenger noen ordrer som vil bli berørt av denne endringen.</Typography>
                        <br/>
                    </>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={_handleClose}>Avbryt</Button>
                <Button onClick={_handleAccept} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScheduleEventDetailsExistingOrdersDialog