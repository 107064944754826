import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect} from "react"
// noinspection ES6CheckImport
import {Route, Switch, useLocation} from "react-router-dom"
import {Dashboard} from "../dashboard/Dashboard"
import {DashboardTranslationProvider} from "../dashboard/dashboardTranslationContext"
import {EntranceTranslationProvider} from "../products/entrance/entranceTranslationContext"
import {EventsTranslationProvider} from "../products/events/eventsTranslationContext"
import {SeasonalPassesTranslationProvider} from "../products/seasonal_passes/membershipsTranslationContext"
import {OtherDashboard} from "../other/OtherDashboard"
import {OtherTranslationProvider} from "../other/otherTranslationContext"
import {SalesTranslationProvider} from "../sales/salesTranslationContext"
import {Settings} from "../settings/Settings"
import {SettingsTranslationProvider} from "../settings/settingsTranslationContext"
import {useAuthsState} from "./auths/authsContext"
import {Callback} from "./auths/Callback"
import {SET_CURRENT_BASE_URL, useBaseContextDispatch} from "./baseContext"
import {SalesRoutes} from "../sales/SalesRoutes"
import {EventsRoutes} from "../products/events/EventsRoutes"
import {EntranceRoutes} from "../products/entrance/EntranceRoutes"
import {SeasonalPassesRoutes} from "../products/seasonal_passes/SeasonalPassesRoutes"
import AlertBox from "./components/AlertBox"
import {useBaseTranslation} from "./baseTranslationContext"
import {JointProductsDiscountRoutes} from "../joint-products-discount/JointProductsDiscountRoutes"

export const ENTRANCE_BASE_URL = "/entrance"
export const EVENTS_BASE_URL = "/events"
export const SETTINGS_BASE_URL = "/settings"
export const SALES_BASE_URL = "/sales"
export const OTHER_BASE_URL = "/other"
export const JOINT_PRODUCTS_DISCOUNT_BASE_URL = "/jointproductsdiscount"
export const SEASONAL_PASSES_BASE_URL = "/seasonalpass"
export const DASHBOARD_BASE_URL = "/"

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        padding: theme.spacing(1),
    },
}))
export const Routes = () => {
    const classes = useStyles()
    const t = useBaseTranslation()
    const {userIsAuthenticated, userHasAccess} = useAuthsState()
    const location = useLocation()
    const dispatch = useBaseContextDispatch()

    useEffect(() => {
        const newBaseUrl = location?.pathname?.split("/").slice(0, 2).join("/")
        dispatch({type: SET_CURRENT_BASE_URL, payload: newBaseUrl})
    }, [location, dispatch])

    const getRoutes = () => {
        if (userIsAuthenticated) {
            if (userHasAccess) {
                return (
                    <>
                        <Route exact
                               path={DASHBOARD_BASE_URL}>
                            <DashboardTranslationProvider>
                                <Dashboard/>
                            </DashboardTranslationProvider>
                        </Route>
                        <Route path={EVENTS_BASE_URL}>
                            <EventsTranslationProvider>
                                <EventsRoutes/>
                            </EventsTranslationProvider>
                        </Route>
                        <Route path={ENTRANCE_BASE_URL}>
                            <EntranceTranslationProvider>
                                <EntranceRoutes/>
                            </EntranceTranslationProvider>
                        </Route>
                        <Route path={SEASONAL_PASSES_BASE_URL}>
                            <SeasonalPassesTranslationProvider>
                                <SeasonalPassesRoutes/>
                            </SeasonalPassesTranslationProvider>
                        </Route>
                        <Route path={JOINT_PRODUCTS_DISCOUNT_BASE_URL}>
                            <JointProductsDiscountRoutes/>
                        </Route>
                        <Route path={OTHER_BASE_URL}>
                            <OtherTranslationProvider>
                                <OtherDashboard/>
                            </OtherTranslationProvider>
                        </Route>
                        <Route path={SALES_BASE_URL}>
                            <SalesTranslationProvider>
                                <SalesRoutes/>
                            </SalesTranslationProvider>
                        </Route>
                        <Route path={SETTINGS_BASE_URL}>
                            <SettingsTranslationProvider>
                                <Settings/>
                            </SettingsTranslationProvider>
                        </Route>
                    </>
                )
            } else {
                return (
                    <AlertBox title={t("noAccess")}
                              text={t("noUserAccessText")}/>
                )
            }
        } else {
            return null
        }
    }

    return (
        <div className={classes.root}>
            <Switch>
                <Route exact
                       path={"/oauth2/callback"}>
                    <Callback/>
                </Route>
                {getRoutes()}
            </Switch>
        </div>

    )
}
