import { v4 as uuid } from "uuid";

export const getTranslationsForLanguage = (languageLocale, objectWithTranslations) => {
  return objectWithTranslations.translations?.find(translation => translation.languageLocale === languageLocale)
}

export const getTranslatableFieldValueFromObject = (obj, propName) => {
  const translatableFieldValue = {}
  translatableFieldValue.translations = []
  translatableFieldValue.value = obj[propName]

  if (!obj.translations) {
    return translatableFieldValue
  }

  obj.translations.forEach(translation => {
    for (const [key, value] of Object.entries(translation)) {
      if (key === "languageLocale" || key === "languageId" || key === "productId") {
        continue
      }

      if (key === propName) {
        translatableFieldValue.translations.push({locale: translation.languageLocale, id: uuid(), translation: value})
      }
    }
  })

  return  translatableFieldValue
}


//generate object matching wanted backend payload for translations
export const getTranslationsPayloadFromObject = (object) => {
  delete object.translations
  const translations = []
  for (const [key, value] of Object.entries(object)) {
    //check if is translateable field
    if (value?.translations) {
      //add other specified languages in translation array
      value.translations.forEach(translation => {
        const existingTranslation = translations.find(trans => trans.languageLocale === translation.locale)
        if (existingTranslation) {
          existingTranslation[key] = translation.translation
        } else {
          translations.push({languageLocale: translation.locale, [key]: translation.translation})
        }
      })
    }

  }

  addBlankTranslationsToExistingTranslations(object, translations)
  addMissingKeysToPayloadObject(translations)
  setValuesForTranslatableFields(object)
  return translations
}
const setValuesForTranslatableFields = (object) => {
  for (const [key, value] of Object.entries(object)) {
    if (value?.translations) {
      object[key] = value.value
    }
  }
}

const addBlankTranslationsToExistingTranslations = (object, translations) => {
  for (const [key, value] of Object.entries(object)) {
    if (value?.translations) {
      //object[key] = value.value
      if (value.translations.length === 0) {
        translations.forEach(trans => {
          trans[key] = ""
        })
      }
    }
  }
}

//used to populate translations object for languages that does not have all translations specified
const addMissingKeysToPayloadObject = (translations) => {
  const allKeys = translations.reduce((keys, object) => keys.concat(Object.keys(object)), [])
  translations.forEach(translation => {
    allKeys.forEach(key => {
      if (!translation.hasOwnProperty(key)) {
        translation[key] = ""
      }
    })
  })
}
