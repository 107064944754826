import { InputAdornment, TextField } from "@mui/material";
import React from "react"
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers";

const MuiTimePicker = ({
                           selected,
                           label,
                           name,
                           onChange,
                           fullWidth = true,
                           size = "medium",
                           inputVariant = "filled",
                           margin = 'normal',
                           helperText = "",
                           disabled = false,
                           isClearable = false,
                           onClear = null,
                           error = false,
                           required = false
                       }) => (
    <TimePicker value={selected}
                autoOk
                ampm={false}
                format="HH:mm"
                variant="inline"
                renderInput={(props) => <TextField {...props} variant={inputVariant} fullWidth={fullWidth} margin={margin} />}
                minutesStep={5}
                label={label}
                disabled={disabled}
                size={size}
                helperText={helperText}
                fullWidth={fullWidth}
                required={required}
                margin={margin}
                error={error}
                InputProps={{
                    endAdornment: selected && isClearable && onClear ? (
                        <InputAdornment position="end">
                            <IconButton size="small"
                                        onClick={(event) => onClear(event)}>
                                <Clear/>
                            </IconButton>
                        </InputAdornment>
                    ) : null
                }}
                onChange={date => onChange(date, name)}/>
)

export default MuiTimePicker
