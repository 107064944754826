import React, {useEffect, useState} from "react"
import {Accordion, AccordionDetails, Button, Card, CardHeader, IconButton, Typography} from "@mui/material"
import ScheduleEventTreeView from "../components/ScheduleEventTreeView"
import {useAxiosBpn} from "../axios"
import {ProgressIndicator} from "../ProgressIndicator"
import CreateOrderDialogProductAccordionSummary from "./CreateOrderDialogProductAccordionSummary"
import {Add, Remove} from "@mui/icons-material"
import EventFilterDialog from "../components/EventFilterDialog"
import {useBaseTranslation} from "../baseTranslationContext"
import EventIcon from "@mui/icons-material/Event"

function countOrderLines(orderLines, product, id, recurNumber) {
    return orderLines?.reduce((total, line) => {
        let line_count = 0
        if (line.productId === product.id &&
            line.scheduleEventId === id &&
            (line.recurNumber === recurNumber || !recurNumber)) {
            const {groupTicketCount, quantity} = line
            line_count += groupTicketCount ? groupTicketCount : quantity
        }
        return total + line_count
    }, 0)
}

const CreateOrderDialogProductAccordion = ({
                                               product,
                                               orderLines,
                                               loading,
                                               remainingTicketsAfterOrderUpdate,
                                               updateOrder,
                                               expanded,
                                               handleExpandedChange,
                                           }) => {
    const t = useBaseTranslation()
    const [_product, setProduct] = useState({})
    const [prices, setPrices] = useState([])
    const [selectedEvent, setSelectedEvent] = useState()
    const [eventLabel, setEventLabel] = useState(t("selectEvent"))
    const [eventDialogOpen, setEventDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [orders, setOrders] = useState([])
    const [ticketsSold, setTicketsSold] = useState(0)
    const [ticketsReserved, setTicketsReserved] = useState(0)
    const [ticketsInCart, setTicketsInCart] = useState(0)
    const [saleLimit, setSaleLimit] = useState()
    const [ticketsLeft, setTicketsLeft] = useState()

    const [{data, loading: productLoading}, getProduct] = useAxiosBpn({url: `products/${product?.id}`}, {manual: true})

    const [{data: productScheduleEventsData}] = useAxiosBpn({
        url: "schedule_events/grouped",
        params: {productId: product?.id},
    })

    const [{data: ordersData}, getOrders] = useAxiosBpn({url: "orders"}, {manual: true})

    useEffect(() => setTicketsLeft(saleLimit ? saleLimit - ticketsSold - ticketsReserved - ticketsInCart : undefined), [saleLimit, ticketsSold, ticketsReserved, ticketsInCart])

    useEffect(() => {
        if (ordersData) setOrders(ordersData?.orders || [])
    }, [ordersData])

    useEffect(() => {
        if (product && selectedEvent && remainingTicketsAfterOrderUpdate) {
            const {id, recurNumber} = selectedEvent
            const remaining = remainingTicketsAfterOrderUpdate
                ?.find(x => x.productId === product.id && x.scheduleEventId === id && (x.recurNumber === recurNumber || !recurNumber))

            if (remaining) {
                const {reservedTotal, reservedInOrder} = remaining
                setTicketsReserved(reservedTotal - reservedInOrder || 0)
            } else setTicketsReserved(0)
        }
    }, [product, selectedEvent, remainingTicketsAfterOrderUpdate])

    useEffect(() => {
        if (product && orders && selectedEvent) {
            const {id, recurNumber, saleLimit} = selectedEvent

            const sold = orders.reduce((allTotal, order) => {
                const sum = countOrderLines(order?.orderLines, product, id, recurNumber)
                return allTotal + sum
            }, 0)

            const countInCart = countOrderLines(orderLines, product, id, recurNumber)

            setTicketsSold(sold)
            setSaleLimit(saleLimit)
            setTicketsInCart(countInCart)
        } else {
            setTicketsSold(undefined)
            setSaleLimit(undefined)
            setTicketsInCart(undefined)
        }
    }, [product, orders, selectedEvent, orderLines])

    useEffect(() => {
        if (product) setProduct(product)
    }, [product])

    useEffect(() => {
        if (_product?.prices) {
            const _prices = _product.prices.map(price => {
                const lines = orderLines?.filter(line => line.productPriceCategoryId === price.productPriceCategoryId)
                return {...price, quantity: lines?.map(({quantity: q}) => q)?.reduce((p, c) => p + c, 0) || 0}
            })
            setPrices(_prices)
        } else {
            getProduct().catch(() => {
            })
        }
    }, [getProduct, _product, orderLines])

    useEffect(() => {
        if (data) setProduct(data.product)
    }, [data])

    const handleEventSelect = (event, label = null) => {
        setEventLabel(label)
        setSelectedEvent(event)
        const {id, recurNumber} = event
        getOrders({
            params: {scheduleEventId: id, recurNumber: recurNumber, confirmedExceptCredited: true, detailed: true}
        }).catch(() => {
        })
    }

    const handleEventDialogOpen = (event) => {
        setEventDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleQuantityChange = (productPriceCategoryId, newQuantity) => {
        const mergedOrderlines = Object.values(orderLines.reduce((res, obj) => {
            res[obj.productPriceCategoryId] = res[obj.productPriceCategoryId] ||
                {
                    ...obj,
                    productPriceCategoryId: obj.productPriceCategoryId,
                    quantity: 0,
                }
            res[obj.productPriceCategoryId].quantity += obj.quantity
            return res
        }, []))

        const newOrderLine = () => {
            const {recurNumber, startTime, startDate, id, endTime} = selectedEvent
            return {
                productId: product.id,
                productPriceCategoryId: productPriceCategoryId,
                endDate: startDate,
                startDate: startDate,
                startTime: startTime,
                endTime: endTime,
                scheduleEventId: id,
                recurNumber: recurNumber,
                quantity: newQuantity,
            }
        }

        let newOrderLines = mergedOrderlines

        if (newOrderLines?.length) {
            const index = newOrderLines.findIndex(line => line.productPriceCategoryId === productPriceCategoryId)

            if (index >= 0) {
                newOrderLines[index] = {...newOrderLines[index], quantity: newQuantity}
            } else {
                newOrderLines = [...newOrderLines, newOrderLine()]
            }
        } else {
            newOrderLines = [newOrderLine()]
        }
        updateOrder(newOrderLines)
    }

    return productLoading
        ? <ProgressIndicator/>
        : <>
            <Accordion square
                       expanded={expanded === product?.id}
                       onChange={handleExpandedChange(product?.id)}>
                <CreateOrderDialogProductAccordionSummary name={product?.name}
                                                          museumName={product?.museumName}
                                                          type={product?.type}
                                                          jointProductsDiscountName={product?.jointProductsDiscountName}/>
                <AccordionDetails>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", width: "100%"}}>
                        {productScheduleEventsData?.groupedScheduleEvents && (
                            <Button onClick={handleEventDialogOpen}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<EventIcon/>}>
                                {eventLabel}
                            </Button>
                        )}
                        {saleLimit && (
                            <Typography variant="h6" color="textSecondary" style={{marginTop: 8}}>
                                {ticketsLeft || 0} billett{ticketsLeft !== 1 ? "er" : ""} igjen {ticketsReserved || ticketsSold ? `(${ticketsSold} solgt${ticketsSold !== 1 ? "e" : ""} og ${ticketsReserved} reservert${ticketsReserved !== 1 ? "e" : ""})` : ""}
                            </Typography>
                        )}
                        {selectedEvent && (
                            <div style={{marginTop: 8, width: "100%"}}>
                                {prices?.map(({
                                                  productPriceCategoryId,
                                                  name,
                                                  description,
                                                  amount,
                                                  quantity,
                                              }) => (
                                    <Card key={productPriceCategoryId}
                                          variant="outlined"
                                          square
                                          style={{marginBottom: 8}}>
                                        <CardHeader title={<strong>{name}</strong>}
                                                    titleTypographyProps={{variant: "h6"}}
                                                    subheader={description}
                                                    subheaderTypographyProps={{
                                                        variant: "body1",
                                                        color: "textSecondary",
                                                    }}
                                                    action={
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            {quantity === 0
                                                                ? <Typography variant="h6">{amount},-</Typography>
                                                                : <>
                                                                    <IconButton
                                                                        color="primary"
                                                                        disabled={loading || productLoading}
                                                                        onClick={() => (
                                                                            handleQuantityChange(
                                                                                productPriceCategoryId,
                                                                                quantity - 1)
                                                                        )}
                                                                        size="large">
                                                                        <Remove fontSize="large"/>
                                                                    </IconButton>
                                                                    <Typography variant="h6">{quantity}</Typography>
                                                                </>
                                                            }
                                                            <IconButton
                                                                color="primary"
                                                                disabled={loading || productLoading}
                                                                onClick={() => (
                                                                    handleQuantityChange(
                                                                        productPriceCategoryId,
                                                                        quantity + 1)
                                                                )}
                                                                size="large">
                                                                <Add fontSize="large"/>
                                                            </IconButton>
                                                        </div>
                                                    }/>
                                    </Card>
                                ))}
                            </div>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
            <EventFilterDialog anchorEl={anchorEl}
                               title={t("selectScheduleEvent")}
                               open={eventDialogOpen}
                               handleClose={() => setEventDialogOpen(false)}>
                <ScheduleEventTreeView groupedScheduleEvents={productScheduleEventsData?.groupedScheduleEvents}
                                       selectedEvent={selectedEvent}
                                       handleClose={() => setEventDialogOpen(false)}
                                       handleSelect={handleEventSelect}/>
            </EventFilterDialog>
        </>;
}

export default CreateOrderDialogProductAccordion