import React from "react"
import {Typography} from "@mui/material"

const DiscountCodesHelperText = () => {
    return (
        <>
            <Typography variant="subtitle2">
                Bruk dette skjermbildet for å opprette rabattkoder.
            </Typography>
            <br/>
            <Typography variant="body2">
                Rabattkodene blir ikke automatisk aktivert for alle produkter. For å aktivere en rabattkode må man legge
                til rabattkoden på et produkt man ønsker at skal være mulig å rabattere.
                Dette gjøres i samme vindu hvor man redigerer eller setter opp nye produkter.
                <br/><br/>
            </Typography>
        </>
    )
}

export default DiscountCodesHelperText