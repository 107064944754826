import React, {useEffect, useState} from "react"
import {VOUCHER_SETTINGS_TYPE_MERGED} from "../../../app/helper-functions/voucherStatuses"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import AlertBox from "../../../app/components/AlertBox"

const VismaVoucherSettingsAlerts = ({vismaVoucherSettings}) => {
    const t = useBaseTranslation()
    const [alert, setAlert] = useState()

    useEffect(() => {
        setAlert(null)
        if (vismaVoucherSettings) {
            if (!vismaVoucherSettings[VOUCHER_SETTINGS_TYPE_MERGED]?.debitAccount) {
                setAlert({
                    severity: "error",
                    title: t("debitAccountMissing"),
                    text: t("debitAccountMissingText"),
                })
            }
        }
    }, [t, vismaVoucherSettings])

    return <AlertBox title={alert?.title}
                     text={alert?.text}
                     severity={alert?.severity}/>
}

export default VismaVoucherSettingsAlerts