import React from "react"
import {PRODUCT_TYPE_ENTRANCE_TICKET, PRODUCT_TYPE_EVENT, PRODUCT_TYPE_SEASONAL_PASS} from "../baseContext"
import {EventSeat, FiberManualRecord} from "@mui/icons-material"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import LoyaltyIcon from "@mui/icons-material/Loyalty"

const ProductTypeIcon = ({type, iconStyle}) => {
    switch (type) {
        case PRODUCT_TYPE_EVENT:
            return <EventSeat style={iconStyle}/>
        case PRODUCT_TYPE_ENTRANCE_TICKET:
            return <MeetingRoomIcon style={iconStyle}/>
        case PRODUCT_TYPE_SEASONAL_PASS:
            return <LoyaltyIcon style={iconStyle}/>
        default:
            return <FiberManualRecord/>
    }
}

export default ProductTypeIcon