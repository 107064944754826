import React, {useState} from "react"
import {Avatar, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Typography} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {QRCode} from "react-qr-svg"
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {lowerFirst} from "lodash"
import SubTicket from "./SubTicket"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}))

const OrderDetailsDialogMainTicketCard = ({order, mainTicket}) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)

    const getProductNameById = (id) => {
        return order.orderLines?.find(orderLine => orderLine.productId === id) || ""
    }

    function getTitle() {
        return (
            <Grid container
                  spacing={1}>
                <Grid item><strong>{getProductNameById(mainTicket?.productId).productName || t("mainTicket")}</strong></Grid>
                <Grid item>{` ( ${mainTicket?.code} )`}</Grid>
            </Grid>
        )
    }

    function getSubheader() {
        return (
            <Grid container
                  spacing={1}>
                <Grid item> <strong>{mainTicket?.subTickets?.length}</strong> {` ${lowerFirst(t("subTickets"))}`}
                </Grid>
            </Grid>
        )
    }

    function getAvatar() {
        return mainTicket ? <Avatar variant="square"><QRCode value={mainTicket?.code}/> </Avatar> : null
    }

    function getAction() {
        return (
            <IconButton
                className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
                onClick={() => setExpanded(!expanded)}
                size="large">
                <ExpandMoreIcon/>
            </IconButton>
        );
    }

    return (<Card square
                  variant="outlined"
                  style={{marginTop: 8}}
                  key={mainTicket.code}>
        <CardHeader title={getTitle(mainTicket)}
                    subheader={getSubheader(mainTicket.subTickets)}
                    avatar={getAvatar(mainTicket)}
                    action={getAction()}/>
        <Collapse in={expanded}
                  timeout="auto"
                  unmountOnExit>
            <CardContent>
                <>
                    <Typography variant="subtitle2"
                                color="primary"
                                style={{marginBottom: 16}}>
                        {t("subTickets")}
                    </Typography>
                    {mainTicket.subTickets?.map((ticket, index) => (
                        <SubTicket key={`${ticket.code}-${index}`}
                                   ticket={ticket}
                                   order={order}/>
                    ))}
                </>
            </CardContent>
        </Collapse>
    </Card>)
}

export default OrderDetailsDialogMainTicketCard
