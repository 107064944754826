import React, {useEffect, useRef, useState} from "react"
import {ArrowDropDown} from "@mui/icons-material"
import {useMenuTranslation} from "./menuTranslationContext"
import MuiButton from "@mui/material/Button"
import withStyles from '@mui/styles/withStyles';
import {ClickAwayListener, Grid, MenuItem, MenuList, Paper, Popper, Tooltip} from "@mui/material"
import Typography from "@mui/material/Typography"
import {useHistory} from "react-router-dom"
import {ENTRANCE_BASE_URL, EVENTS_BASE_URL, SEASONAL_PASSES_BASE_URL} from "../Routes"
import {
    SELECTED_TYPE_MUSEUM,
    SELECTED_TYPE_UMBRELLA,
    useMuseumSelectorState,
} from "../museum-selector/museumSelectorContext"
import {PRODUCT_TYPE_ENTRANCE_TICKET, PRODUCT_TYPE_EVENT, PRODUCT_TYPE_SEASONAL_PASS} from "../baseContext"
import ProductTypeIcon from "../components/ProductTypeIcon"

const Button = withStyles(theme => ({
    root: {
        margin: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
}))(MuiButton)

const iconStyle = {color: "gray", fontSize: 20, marginTop: "1px"}

const NewContentButton = () => {
    const t = useMenuTranslation()
    const history = useHistory()
    const {selectedMuseum} = useMuseumSelectorState()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
        if (selectedMuseum?.type === SELECTED_TYPE_UMBRELLA || selectedMuseum?.type === SELECTED_TYPE_MUSEUM) {
            setEnabled(true)
        } else if (enabled) {
            setEnabled(false)
        }
    }, [enabled, selectedMuseum])

    const menuItems = [
        {
            label: t("newEvent"),
            icon: <ProductTypeIcon type={PRODUCT_TYPE_EVENT} iconStyle={iconStyle}/>,
            url: `${EVENTS_BASE_URL}/new/edit`,
        },
        {
            label: t("newEntranceTicket"),
            icon: <ProductTypeIcon type={PRODUCT_TYPE_ENTRANCE_TICKET} iconStyle={iconStyle}/>,
            url: `${ENTRANCE_BASE_URL}/new/edit`,
        },
        {
            label: t("newSeasonalPass"),
            icon: <ProductTypeIcon type={PRODUCT_TYPE_SEASONAL_PASS} iconStyle={iconStyle}/>,
            url: `${SEASONAL_PASSES_BASE_URL}/new/edit`,
        },
    ]

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        // noinspection JSUnresolvedFunction
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const handleMenuItemSelect = url => {
        setOpen(false)
        history.push(url)
    }

    return (
        <>
            <Tooltip title={enabled
                            ? "Lag et nytt produkt"
                            : "Du må velge museum eller selskap før du oppretter nytt produkt!"}>
                <div style={{textAlign: "center"}}>
                    <Button variant="contained"
                            color="primary"
                            ref={anchorRef}
                            disabled={!enabled}
                            endIcon={<ArrowDropDown/>}
                            onClick={handleToggle}>
                        {t("newContent")}
                    </Button>
                </div>
            </Tooltip>
            <Popper open={open}
                    anchorEl={anchorRef.current}
                    style={{zIndex: 5000}}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {menuItems.map(({label, url, icon, disabled = false}, index) => (
                                <MenuItem key={index}
                                          disabled={disabled}
                                          onClick={() => handleMenuItemSelect(url)}
                                          dense>
                                    <Grid container
                                          spacing={1}>
                                        <Grid item>
                                            {icon}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"
                                                        style={{fontSize: "1em"}}>
                                                {label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    )
}

export default NewContentButton