import React, { useEffect, useState } from "react";
import { Checkbox, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { useMuseumSelectorState } from "../../museum-selector/museumSelectorContext";
import { useAxiosBpn } from "../../axios";
import { useProductEditorTranslation } from "../productEditorTranslationContext";

const ProductDiscountCodes = ({product, updateProductState, unsavedChanges, setUnsavedChanges, reFetchDiscountCodes}) => {
    const { umbrellaId} = useMuseumSelectorState()
    const [data, setData] = useState({})
    const t = useProductEditorTranslation()
    const [discountCodes, setDiscountCodes] = useState([])
    const [selectedDiscountCodes, setSelectedDiscountCodes] = useState([])
    const [{data: discountCodesData}, getDiscountCodes] = useAxiosBpn({
        url: "discount_codes",
        params: {museumId: umbrellaId},
    })

    useEffect(() => {
        if (product) {
            setData(product)
        }
    }, [product])

    useEffect(() => {
        if (discountCodes && data.discountCodes) {
            setSelectedDiscountCodes(discountCodes.filter((e) =>
              data.discountCodes.some((ae) => e.id === ae.id || e.id === ae.id)
            ))
        }
    }, [discountCodes, data])


    useEffect(() => {
        setDiscountCodes(discountCodesData?.discountCodes)
    }, [discountCodesData])

    useEffect(() => {
        if (umbrellaId) {
            getDiscountCodes().catch(() => {})
        }
    }, [getDiscountCodes, umbrellaId, reFetchDiscountCodes])


    const handleDiscountCodeChange = ({target: {value}}) => {
        updateProductState({
            ...product,
            discountCodes: value.map((code => ({id: code.id, discount: code.discount, code: code.code, museumId: umbrellaId})))
        })

        if (!unsavedChanges) setUnsavedChanges(true)
    }

    return <>
        {discountCodes?.length > 0 ?
        <Select
          multiple
          fullWidth
          value={selectedDiscountCodes}
          variant={"outlined"}
          onChange={handleDiscountCodeChange}
          input={<OutlinedInput />}
          renderValue={(selected) => selected.map(discountCode => discountCode.code).join(', ')}>
            {discountCodes.map((discountCode) => (
              <MenuItem key={discountCode.id} value={discountCode}>
                  <ListItemIcon>
                      <Checkbox  color={"primary"} checked={selectedDiscountCodes?.filter(code => code.id === discountCode.id).length > 0} />
                  </ListItemIcon>
                  <ListItemText primary={discountCode.code + `  (${discountCode.discount} %)`} />
              </MenuItem>
            ))}
        </Select> : <Typography>{t("noDiscountCodes")}</Typography>
        }
    </>
}

export default ProductDiscountCodes
