import React, {useEffect, useState} from "react"
import {Dialog, DialogActions, DialogContent, useTheme} from "@mui/material"
import Button from "@mui/material/Button"
import {useAxiosBpn} from "../axios"
import {ProgressIndicator} from "../ProgressIndicator"
import useCustomSnackbar from "../components/useCustomSnackbar"
import OrderDetailsDialogCustomerDetails from "./OrderDetailsDialogCustomerDetails"
import OrderDetailsDialogGeneralDetails from "./OrderDetailsDialogGeneralDetails"
import OrderDetailsDialogPaymentDetails from "./OrderDetailsDialogPaymentDetails"
import OrderDetailsDialogReceiptDetails from "./OrderDetailsDialogReceiptDetails"
import OrderDetailsDialogOrderLinesDetails from "./OrderDetailsDialogOrderLinesDetails"
import OrderDetailsDialogLogEntries from "./OrderDetailsDialogLogEntries"
import {useBaseTranslation} from "../baseTranslationContext"
import OrderDetailsDialogPointOfSaleSystemDetails from "./OrderDetailsDialogPointOfSaleSystemDetails"
import OrderDetailsDialogComments from "./OrderDetailsDialogComments"
import OrderDetailsDialogQuestionAnswers from "./OrderDetailsDialogQuestionAnswers"
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext"
import {ROLES_VALUES} from "../menu/MuseumSelector"

const OrderDetailsDialog = ({orderId, open, handleClose}) => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()
    const snackbar = useCustomSnackbar()
    const {selectedMuseum} = useMuseumSelectorState()

    const [order, setOrder] = useState({})
    const [_orderId, setOrderId] = useState(orderId)
    const [{data, loading}, getOrder] = useAxiosBpn({url: `orders/${_orderId}`}, {manual: true})

    const [
        {
            data: orderProcessData,
            loading: orderProcessLoading,
            error: orderProcessError,
        }, processOrder,
    ] = useAxiosBpn({
        url: "orders/process",
        params: {transactionId: order.transactionId},
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        setOrderId(orderId)
    }, [orderId])

    useEffect(() => {
        if (_orderId) {
            getOrder().catch(() => {
            })
        }
    }, [getOrder, _orderId])

    useEffect(() => {
        if (data) setOrder(data.order)
    }, [data])

    useEffect(() => {
        if (orderProcessData) {
            snackbar.showInfo(t("orderProcessed"))
            getOrder()
        }
    }, [orderProcessData, getOrder, snackbar, t])

    useEffect(() => {
        if (orderProcessError) {
            snackbar.showError(orderProcessError.message)
        }
    }, [orderProcessError, snackbar])

    const handleProcessOrderClick = () => {
        let params = {}

        if (order.transactionId) {
            params = {transactionId: order.transactionId}
        } else {
            params = {orderId: order.externalId}
        }

        processOrder({params: params})
    }

    return loading
        ? <ProgressIndicator/>
        : <Dialog open={open}
                  onClose={handleClose}
                  fullWidth
                  maxWidth="md">
            <DialogContent style={{minHeight: 600, backgroundColor: order?.test ? testOrdersPalette?.light : null}}>
                <div style={{marginBottom: 8}}>
                    <OrderDetailsDialogGeneralDetails order={order}
                                                      setOrderId={setOrderId}
                                                      loading={loading}
                                                      roleValue={selectedMuseum?.role?.value}
                                                      processOrder={processOrder}
                                                      refetchOrder={getOrder}/>
                </div>
                <div style={{marginBottom: 8}}>
                    <OrderDetailsDialogCustomerDetails loading={loading}
                                                       order={order}/>
                </div>
                {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR && (
                    <div style={{marginBottom: 8}}>
                        <OrderDetailsDialogLogEntries loading={loading} entries={order?.logEntries}/>
                    </div>
                )}
                <div style={{marginBottom: 8}}>
                    <OrderDetailsDialogPaymentDetails loading={loading}
                                                      order={order}
                                                      paymentType={order?.paymentType}
                                                      orderProcessLoading={orderProcessLoading}
                                                      processOrder={handleProcessOrderClick}/>
                </div>
                {order?.pointOfSaleSystem?.enabled && (
                    <div style={{marginBottom: 8}}>
                        <OrderDetailsDialogPointOfSaleSystemDetails loading={loading}
                                                                    order={order}
                                                                    refetchOrder={getOrder}/>
                    </div>
                )}
                <div style={{marginBottom: 8}}>
                    <OrderDetailsDialogReceiptDetails loading={loading}
                                                      order={order}
                                                      refetchOrder={getOrder}/>
                </div>
                {order.formQuestionAnswers?.length > 0 && <div style={{marginBottom: 8}}>
                    <OrderDetailsDialogQuestionAnswers loading={loading} order={order}/>
                </div>}
                {order.comments?.length > 0 && <div style={{marginBottom: 8}}>
                    <OrderDetailsDialogComments loading={loading} order={order}/>
                </div>}
                <OrderDetailsDialogOrderLinesDetails loading={loading}
                                                     order={order}
                                                     orderLines={order?.orderLines}/>
            </DialogContent>
            <DialogActions style={{backgroundColor: order?.test ? testOrdersPalette?.light : null}}>
                <Button onClick={handleClose}
                        color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
}

export default OrderDetailsDialog
