import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {AuthsProvider} from "./auths/authsContext";
import {WithAuthentication} from "./auths/withAuthentication";
import {BaseContextProvider} from "./baseContext";
import {Header} from "./header/header";
import {HeaderProvider} from "./header/headerContext";
import {MenuTranslationProvider} from "./menu/menuTranslationContext";
import {MuseumSelectorProvider} from "./museum-selector/museumSelectorContext";
import {Routes} from "./Routes";
import {Theme} from "./Theme";
import {ViewportProvider} from "./viewport/viewportContext";
import {WhenHeaderIsReady} from "./whenHeaderIsReady";
import {Menu} from "./menu/Menu";
import CustomSnackbar from "./components/CustomSnackbar";
import {NetaxeptCallback} from "./NetaxeptCallback";
import {BaseTranslationProvider} from "./baseTranslationContext";
import SnackbarProvider from "./snackbar/SnackbarProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";
import nbLocale from 'date-fns/locale/nb';

function App() {

    if (!window._env_ || !window._env_.REACT_APP_API_GATEWAY) {
        return null
    }

    return (
        <Router>
            <AuthsProvider>
                <WithAuthentication>
                    <MuseumSelectorProvider>
                        <Theme>
                            <CssBaseline/>
                            <ViewportProvider>
                                <HeaderProvider>
                                    <Header/>
                                    <WhenHeaderIsReady>
                                        <BaseTranslationProvider>
                                            <MenuTranslationProvider>
                                                <BaseContextProvider>
                                                    <Menu/>
                                                    <main style={{width: "100%",
                                                        height: "100%",
                                                        overflow: "hidden"}}>
                                                        <Toolbar disableGutters={true}/>
                                                        <SnackbarProvider SnackbarProps={{autoHideDuration: 8000}}
                                                                          SnackbarComponent={CustomSnackbar}>
                                                            <NetaxeptCallback>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nbLocale}>
                                                                    <Routes/>
                                                                </LocalizationProvider>
                                                            </NetaxeptCallback>
                                                        </SnackbarProvider>
                                                    </main>
                                                </BaseContextProvider>
                                            </MenuTranslationProvider>
                                        </BaseTranslationProvider>
                                    </WhenHeaderIsReady>
                                </HeaderProvider>
                            </ViewportProvider>
                        </Theme>
                    </MuseumSelectorProvider>
                </WithAuthentication>
            </AuthsProvider>
        </Router>
    )
}

export default App
