import React from "react";
import { useBaseTranslation } from "../baseTranslationContext";
import { Checkbox, InputBase, ListItemIcon, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  input: {
    background: 'white',
    height: '2.1rem',
    borderRadius: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  filterName: {
    marginRight: theme.spacing(2),
    alignSelf: 'center',
    fontWeight: 'bold'
  }
}))

const DropdownSelectorFilter = ({value, options, onChange}) => {
  const classes = useStyles()
  const t = useBaseTranslation()
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };
  return (
    <>
      <Typography className={classes.filterName} color={"primary"}>{t("status")}</Typography>
      <Select
        multiple
        value={value}
        onChange={onChange}
        input={<InputBase className={classes.input} />}
        MenuProps={MenuProps}
        renderValue={(selected) => selected.map(value => t(value)).join(', ')}>
        {options.map((status) => (
          <MenuItem key={status} value={status}>
            <ListItemIcon>
              <Checkbox  color={"primary"} checked={value.indexOf(status) > -1} />
            </ListItemIcon>
            <ListItemText primary={t(status)} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default DropdownSelectorFilter