import React, {useEffect, useState} from "react"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import Button from "@mui/material/Button"
import EditIcon from "@mui/icons-material/Edit"
import {useBaseTranslation} from "../baseTranslationContext"
import {toCamelCase} from "../helper-functions/stringUtility"
import {useHistory, useParams} from "react-router-dom"
import {
    PRODUCT_TYPE_ENTRANCE_TICKET,
    PRODUCT_TYPE_EVENT,
    PRODUCT_TYPE_SEASONAL_PASS,
    useBaseContextState,
} from "../baseContext"
import {useAxiosBpn} from "../axios"
import {ENTRANCE_BASE_URL, EVENTS_BASE_URL, SEASONAL_PASSES_BASE_URL} from "../Routes"
import {getBaseUrlBasedOnProductType} from "../helper-functions/getBaseUrlBasedOnProductType"
import {ProgressIndicator} from "../ProgressIndicator"
import {DetailsDashboard} from "./DetailsDashboard"

const LOCAL_STORAGE_KEY = "bpn.testOrders"

const ProductDetails = ({type}) => {
    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const t = useBaseTranslation()
    const history = useHistory()
    const {currentBaseUrl} = useBaseContextState()
    const {id} = useParams()
    const [activeBreadcrumb, setActiveBreadcrumb] = useState("")
    const [currentEndpointUrl, setCurrentEndpointUrl] = useState("products")
    const [product, setProduct] = useState()
    const [editorLink, setEditorLink] = useState()

    const [{data: productData, loading: productLoading}, getProduct] = useAxiosBpn({
        url: `${currentEndpointUrl}/${id}`,
        params: {
            test: testOrders,
            expandRecurring: true,
            limit: 10,
        },
    })

    useEffect(() => {
        if (productData) {
            switch (type) {
                case PRODUCT_TYPE_EVENT:
                    setProduct(productData?.event)
                    break
                case PRODUCT_TYPE_ENTRANCE_TICKET:
                    setProduct(productData?.entranceTicket)
                    break
                case PRODUCT_TYPE_SEASONAL_PASS:
                    setProduct(productData?.seasonalPass)
                    break
                default:
                    setProduct(productData)
                    break
            }
        }
    }, [productData, type])

    useEffect(() => {
        getProduct().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (type === PRODUCT_TYPE_EVENT) {
            setEditorLink(`${EVENTS_BASE_URL}/${id}/edit`)
        } else if (type === PRODUCT_TYPE_ENTRANCE_TICKET) {
            setEditorLink(`${ENTRANCE_BASE_URL}/${id}/edit`)
        } else if (type === PRODUCT_TYPE_SEASONAL_PASS) {
            setEditorLink(`${SEASONAL_PASSES_BASE_URL}/${id}/edit`)
        }
    }, [type, id])

    useEffect(() => setCurrentEndpointUrl(getBaseUrlBasedOnProductType(type)), [type])
    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    useEffect(() => {
        if (product) {
            const {name} = product
            setActiveBreadcrumb(name)
        }
    }, [product])

    const breadcrumbs = [
        {label: t(toCamelCase(type) + "s"), url: currentBaseUrl},
        {label: t("list"), url: `${currentBaseUrl}/list`},
        {label: activeBreadcrumb},
    ]

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <Button variant="contained"
                        style={{marginBottom: "8px"}}
                        startIcon={<EditIcon/>}
                        onClick={() => history.push(editorLink)}>
                    {t("edit")}
                </Button>
            </SectionHeader>
            <ScrollableSectionView header={false}>
                {productLoading || !product
                 ? <ProgressIndicator/>
                 : <DetailsDashboard productLoading={productLoading}
                                     setTestOrders={setTestOrders}
                                     testOrders={testOrders}
                                     product={product}/>}
            </ScrollableSectionView>
        </>
    )
}

export default ProductDetails
