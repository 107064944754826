import React, {useEffect, useState} from "react"
import {Grid, Tooltip, Typography} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import {EventSeat} from "@mui/icons-material"


const ScheduleEventExceptionsGridRowSaleLimit = ({saleLimit, originalSaleLimit}) => {
    const theme = useTheme()

    const [saleLimitChanged, setSaleLimitChanged] = useState(false)
    const [color, setColor] = useState("lightgray")

    useEffect(() => setColor(saleLimitChanged ? "darkred" : "lightgray"), [saleLimitChanged])

    useEffect(() => {
        if (saleLimit !== originalSaleLimit) setSaleLimitChanged(true)
        else setSaleLimitChanged(false)
    }, [saleLimit, originalSaleLimit])

    const TooltipTitle = () => (
        <Typography variant="subtitle2">
            {
                saleLimitChanged
                    ? `Plassbegrensning endret fra ${originalSaleLimit || "ubegrenset"} til ${saleLimit || "ubegrenset"}`
                    : "Plassbegrensning ikke endret"
            }
        </Typography>
    )

    return (
        <Tooltip title={<TooltipTitle/>}>
            <Grid container item direction="column" alignItems="center">
                <Grid item><EventSeat style={{color: color}}/></Grid>
                <Grid item>
                    <Typography style={{color: color, marginTop: -8, fontSize: theme.typography.pxToRem(11)}}>
                        Plasser
                    </Typography>
                </Grid>
            </Grid>
        </Tooltip>
    )
}

export default ScheduleEventExceptionsGridRowSaleLimit