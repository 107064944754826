import React from "react"
import { Table, TableHead, TableRow, TableBody } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {dateFormat} from "../helper-functions/dateFormat"
import MuiTableCell from "@mui/material/TableCell"
import {useBaseTranslation} from "../baseTranslationContext"
import makeStyles from '@mui/styles/makeStyles';

const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell)

const useStyles = makeStyles((theme) => ({
    validationAttemptsWrapper: {
        padding: theme.spacing(0,4),
    }
}))

const ValidationAttempts = ({validationAttempts}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    return (
        <div className={classes.validationAttemptsWrapper}>
            <Table size="small"
                   padding="none">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("pointOfTime")}</TableCell>
                        <TableCell>{t("user")}</TableCell>
                        <TableCell>{t("type")}</TableCell>
                        <TableCell>{t("status")}</TableCell>
                        <TableCell>{t("museum")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {validationAttempts?.map(({
                                                  attemptAt,
                                                  attemptByUserName,
                                                  status,
                                                  museumId,
                                                  museumName,
                                                  subTicketCount,
                                                  type,
                                                  validSubTickets
                                              }, index) => (
                        <TableRow key={index}>
                            <TableCell>{dateFormat(attemptAt, "Pp")}</TableCell>
                            <TableCell>{attemptByUserName}</TableCell>
                            <TableCell>{t(type)}</TableCell>
                            <TableCell>{t(status)}</TableCell>
                            <TableCell>{museumName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default ValidationAttempts