import React, {useState} from "react"
import {Help} from "@mui/icons-material"
import {ClickAwayListener, IconButton, Paper, Popper} from "@mui/material"

const HelpButton = ({children}) => {
    const [helpPopperOpen, setHelpPopperOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState()

    const handleHelpButtonClick = (event) => {
        setAnchorEl(event.currentTarget)
        setHelpPopperOpen(!helpPopperOpen)
    }

    return children
           ? <>
               <IconButton onClick={handleHelpButtonClick} size="large">
                   <Help color="action"/>
               </IconButton>
               <Popper open={helpPopperOpen}
                       anchorEl={anchorEl}
                       style={{zIndex: 2000}}
                       placement="right">
                   <ClickAwayListener onClickAway={() => setHelpPopperOpen(false)}>
                       <Paper elevation={8}
                              style={{padding: 24, maxWidth: 600}}>
                           {children}
                       </Paper>
                   </ClickAwayListener>
               </Popper>
           </>
           : null;
}

export default HelpButton