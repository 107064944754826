import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {useBaseContextState} from "../../app/baseContext"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import {Grid} from "@mui/material"
import EditorCardModule from "../../app/components/EditorCardModule"
import VoucherExport from "./voucher-export/VoucherExport"
import VismaVoucherSettings from "./visma-voucher-settings/VismaVoucherSettings"
import VismaVoucherPaymentSubTypesSettings from "./visma-voucher-settings/VismaVoucherPaymentSubTypesSettings"

const VouchersSettings = ({showSectionHeader = true}) => {
    const t = useBaseTranslation()
    const {currentBaseUrl} = useBaseContextState()
    const {umbrellaName} = useMuseumSelectorState()
    const [activeBreadcrumb, setActiveBreadcrumb] = useState(t("vouchers"))
    const [voucherTypeSelected, setVoucherTypeSelected] = useState("none")

    useEffect(() => {
        setActiveBreadcrumb(`${t("vouchers")} (${umbrellaName})`)
    }, [t, umbrellaName])

    const breadcrumbs = [
        {label: t("settings"), url: currentBaseUrl},
        {label: activeBreadcrumb},
    ]

    return (
        <>
            {showSectionHeader && <SectionHeader breadcrumbs={breadcrumbs}/>}
            <ScrollableSectionView>
                <Grid container
                      direction="column"
                      spacing={1}>
                    <Grid item>
                        <EditorCardModule expanded={true}
                                          subheader={t("voucherExportModuleHelperText")}
                                          title="voucherExport">
                            <VoucherExport setVoucherTypeSelected={setVoucherTypeSelected}/>
                        </EditorCardModule>
                    </Grid>
                    {voucherTypeSelected === "visma" && (
                        <Grid item>
                            <EditorCardModule expanded={true}
                                              subheader={t("vismaVoucherSettingsModuleHelperText")}
                                              title="visma">
                                <>
                                    <VismaVoucherSettings/>
                                    <div style={{marginTop: 8}}>
                                        <VismaVoucherPaymentSubTypesSettings/>
                                    </div>
                                </>
                            </EditorCardModule>
                        </Grid>
                    )}
                </Grid>
            </ScrollableSectionView>
        </>
    )
}

export default VouchersSettings