import {ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@mui/material"
import React from "react"
import {DATE_FREQUENCIES} from "../helper-functions/dateFormat"
import {useBaseTranslation} from "../baseTranslationContext"

const DateFrequencySelectMenu = ({
                                       open,
                                       anchorEl,
                                       setOpen,
                                       handleSelect,
                                   }) => {
    const t = useBaseTranslation()

    return (
        <Popper open={open}
                anchorEl={anchorEl}
                style={{zIndex: 1500}}>
            <Paper elevation={8}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuList>
                        {DATE_FREQUENCIES.map((dateFrequency, index) => (
                            <MenuItem key={index}
                                      onClick={() => handleSelect(dateFrequency)}>
                                {t(dateFrequency)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    )
}

export default DateFrequencySelectMenu