import {Button, Grid, Hidden, IconButton, InputAdornment, Tooltip, Typography} from "@mui/material"
import TextField from "@mui/material/TextField"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import {useAxiosBpn} from "../../../app/axios"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import _ from "lodash"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import ConfirmationDialog from "../../../app/components/ConfirmationDialog"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    switch: {
        marginLeft: theme.spacing(1),
        verticalAlign: "middle",
    },
    switchLabel: {
        fontSize: "0.9em",
    },
    warning: {
        textAlign: "right",
        color: "lightgray",
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(-1),
    },
}))

const ProductGroup = ({index, data, umbrellaId, removeFromProductGroups, submitChange}) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const snackbar = useCustomSnackbar()

    const [productGroup, setProductGroup] = useState()
    const [inUse, setInUse] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [hasSale, setHasSale] = useState(false)
    const [hasSaleWarningDialogOpen, setHasSaleWarningDialogOpen] = useState(false)

    const [{loading: postLoading, error: postError}, postProductGroup] = useAxiosBpn({
        url: "product_groups",
        method: "POST",
    }, {manual: true})

    const [{loading: putLoading, error: putError}, putProductGroup] = useAxiosBpn({
        url: `product_groups/${productGroup?.id}`,
        method: "PUT",
    }, {manual: true})

    const [{loading: deleteLoading, error: deleteError}, deleteProductGroup] = useAxiosBpn({
        url: `product_groups/${productGroup?.id}`,
        method: "DELETE",
    }, {manual: true})


    const [{data: productGroupData, loading: productGroupLoading}] = useAxiosBpn({
        url: `product_groups/${productGroup?.id}`,
        params: {checkIfHasSale: true}
    })


    useEffect(() => {
        setProductGroup(data)
    }, [data])

    useEffect(() => {
        if (productGroup?.new) setUnsavedChanges(true)
        productGroup?.priceCategories?.length ? setInUse(true) : setInUse(false)
    }, [productGroup])

    useEffect(() => {
        if (postError) snackbar.showError(t(postError.message))
        if (putError) snackbar.showError(t(putError.message))
        if (deleteError) snackbar.showError(t(deleteError.message))
    }, [deleteError, postError, putError, snackbar, t])

    useEffect(() => {
        if (productGroupData) {
            const {productGroup: {hasSale}} = productGroupData
            setHasSale(hasSale)
        }
    }, [productGroupData])

    const handleChange = e => {
        e.persist()
        setProductGroup(prev => ({...prev, [e.target.name]: e.target.value}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleSave = () => {
        if (productGroup?.new) {
            postProductGroup({data: {...productGroup, museumId: umbrellaId}})
                .then(({data: {productGroup: _productGroup}}) => {
                    snackbar.showSuccess(`${t("productGroup")} ${t("added").toLowerCase()}`)
                    submitChange(index, _productGroup)
                    setUnsavedChanges(false)
                })
        } else {
            putProductGroup({data: productGroup})
                .then(({data: {productGroup: _productGroup}}) => {
                    snackbar.showSuccess(`${t("productGroup")} ${t("updated").toLowerCase()}`)
                    submitChange(index, _productGroup)
                    setUnsavedChanges(false)
                })
        }
    }

    const handleDelete = (warningAccepted = false) => {
        if (productGroup?.new) {
            removeFromProductGroups(index)
        } else {
            if (hasSale && !warningAccepted) setHasSaleWarningDialogOpen(true)
            else deleteProductGroup()
                .then(() => {
                    snackbar.showSuccess(`${t("productGroup")} ${t("deleted").toLowerCase()}`)
                    removeFromProductGroups(index)
                    setHasSaleWarningDialogOpen(false)
                }).catch(() => {
                })
        }
    }

    const handleSubmit = () => {
        if (!_.isEqual(productGroup, data)) {
            setUnsavedChanges(true)
            submitChange(index, productGroup)
        }
    }

    const isMissingName = () => {
        return putError || postError ? !productGroup?.name : false
    }

    const tooltipTitle = () => inUse ? `${t("deleteProductGroupTooltipHelper")}` : ""

    return <>
        {postLoading || putLoading || deleteLoading || productGroupLoading ? <ProgressIndicator/> : (
            <form noValidate
                  className={classes.root}
                  autoComplete="off"
                  onBlur={handleSubmit}>
                <Grid container
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="center">
                    <Grid item
                          xs={2}
                          sm={1}>
                        <TextField label={t("id")}
                                   name="id"
                                   variant="outlined"
                                   size="small"
                                   disabled
                                   fullWidth
                                   value={productGroup?.id || ""}/>
                    </Grid>
                    <Grid item
                          xs
                          lg={6}
                          xl={7}>
                        <TextField label={t("name")}
                                   name="name"
                                   variant="outlined"
                                   size="small"
                                   error={isMissingName()}
                                   helperText={isMissingName() && t("mustHaveName")}
                                   fullWidth
                                   required
                                   onChange={handleChange}
                                   value={productGroup?.name || ""}/>
                    </Grid>
                    <Grid item
                          xs={3}
                          sm={2}
                          md={1}
                          lg>
                        <TextField label={t("vat")}
                                   name="vat"
                                   variant="outlined"
                                   fullWidth
                                   size="small"
                                   type="number"
                                   onChange={handleChange}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                   }}
                                   value={productGroup?.vat || 0}/>
                    </Grid>
                    <Hidden smDown
                            xlUp>
                        <Grid item>
                            <Tooltip title={tooltipTitle()}>
                                <span>
                                    <IconButton disabled={inUse} onClick={handleDelete} size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <IconButton disabled={!unsavedChanges} onClick={handleSave} size="large">
                                <SaveIcon/>
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Hidden only={["sm", "md", "lg"]}>
                        <Grid item
                              container
                              justifyContent="flex-end"
                              xs={12}
                              sm>
                            <Tooltip title={tooltipTitle()}>
                                <span>
                                    <Button variant="contained"
                                            disabled={inUse}
                                            onClick={handleDelete}
                                            className={classes.button}
                                            startIcon={<DeleteIcon/>}>
                                        {t("delete")}
                                    </Button>
                                </span>
                            </Tooltip>
                            <Button variant="contained"
                                    className={classes.button}
                                    disabled={!unsavedChanges}
                                    onClick={handleSave}
                                    startIcon={<SaveIcon/>}>
                                {t("save")}
                            </Button>
                        </Grid>
                    </Hidden>
                </Grid>
                {unsavedChanges &&
                    <Typography variant="subtitle2"
                                className={classes.warning}>
                        {t("unsavedChanges") + "!"}
                    </Typography>}
            </form>
        )}
        <ConfirmationDialog open={hasSaleWarningDialogOpen}
                            handleAccept={() => handleDelete(true)}
                            handleClose={() => setHasSaleWarningDialogOpen(false)}>
            Det er salg knyttet til denne varegruppen! <br/><br/>
            <strong>Er du sikker på at du vil slette?</strong>
        </ConfirmationDialog>
    </>;
}

export default ProductGroup