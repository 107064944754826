import {useBaseTranslation} from "../baseTranslationContext"
import {FormHelperText, Grid} from "@mui/material"
import {ButtonWithProgress} from "./ButtonWithProgress"
import {Save} from "@mui/icons-material"
import React from "react"

const SaveButtonWithProgressAndLabel = ({unsavedChanges, handleSave, loading}) => {
    const t = useBaseTranslation()

    return (
        <Grid container
              item
              spacing={1}
              alignItems="center"
              justifyContent="flex-end">
            <Grid item>
                {unsavedChanges && <FormHelperText>{t("unsavedChanges") + "!"}</FormHelperText>}
            </Grid>
            <Grid item>
                <ButtonWithProgress label={t("save")}
                                    disabled={!unsavedChanges}
                                    startIcon={<Save/>}
                                    onClick={handleSave}
                                    loading={loading}
                                    color="primary"/>
            </Grid>
        </Grid>
    )
}

export default SaveButtonWithProgressAndLabel