import React, {useEffect, useState} from "react"
import _ from "lodash"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import VismaVoucherSettingsGridRow from "./VismaVoucherSettingsGridRow"
import VismaVoucherSettingsGridHeader from "./VismaVoucherSettingsGridHeader"

const VismaVoucherSettingsGrid = ({
                                      vismaVoucherSettings,
                                      setVismaVoucherSettings,
                                      selectedMuseum,
                                      selectedProductGroup,
                                      selectedDepartment,
                                      unsavedChanges,
                                      setUnsavedChanges,
                                  }) => {
    const t = useBaseTranslation()
    const [data, setData] = useState({})
    const gridRowsData = [
        {
            formLabel: t("journalType"),
            formHelperText: <>Felt <strong>'VoTp'</strong> i eksportfil</>,
            textFieldName: "journalType",
            textFieldLabel: t("journalType"),
        },
        {
            formLabel: t("standardDebitAccount"),
            formHelperText: <>Felt <strong>'DbAcNo'</strong> i eksportfil. <br/> <i>Blir overstyrt av debetkonto på
                betalingskort</i></>,
            textFieldName: "debitAccount",
            textFieldLabel: t("standardDebitAccount"),
        },
        {
            formLabel: t("debitTaxCode"),
            formHelperText: <>Felt <strong>'DbTxCd'</strong> i eksportfil</>,
            textFieldName: "debitTaxCode",
            textFieldLabel: t("debitTaxCode"),
        },
        {
            formLabel: t("debitTurnoverClass"),
            formHelperText: <>Felt <strong>'DbTrnCl'</strong> i eksportfil</>,
            textFieldName: "debitTurnoverClass",
            textFieldLabel: t("debitTurnoverClass"),
        },
        {
            formLabel: t("creditAccount"),
            formHelperText: <>Felt <strong>'CrAcNo'</strong> i eksportfil</>,
            textFieldName: "creditAccount",
            textFieldLabel: t("creditAccount"),
        },
        {
            formLabel: t("creditTaxCode"),
            formHelperText: <>Felt <strong>'CrTxCd'</strong> i eksportfil</>,
            textFieldName: "creditTaxCode",
            textFieldLabel: t("creditTaxCode"),
        },
        {
            formLabel: t("creditTurnoverClass"),
            formHelperText: <>Felt <strong>'CrTrnCl'</strong> i eksportfil</>,
            textFieldName: "creditTurnoverClass",
            textFieldLabel: t("creditTurnoverClass"),
        },
        {
            formLabel: t("department"),
            formHelperText: <>Felt <strong>'R1'</strong> i eksportfil</>,
            textFieldName: "department",
            textFieldLabel: t("department"),
        },
        {
            formLabel: t("project"),
            formHelperText: <>Felt <strong>'R2'</strong> i eksportfil</>,
            textFieldName: "project",
            textFieldLabel: t("project"),
        },
        {
            formLabel: t("location"),
            formHelperText: <>Felt <strong>'R3'</strong> i eksportfil</>,
            textFieldName: "location",
            textFieldLabel: t("location"),
        },
        {
            formLabel: t("salesPointId"),
            formHelperText: <>En del av felt <strong>'Txt2'</strong> i eksportfil</>,
            textFieldName: "salesPointId",
            textFieldLabel: t("salesPointId"),
        },
    ]

    useEffect(() => {
        if (vismaVoucherSettings) setData(vismaVoucherSettings)
    }, [vismaVoucherSettings])

    const handleChange = (e, type) => {
        e.persist()
        setData(prev => (
                {
                    ...prev,
                    [type]: {
                        ...prev[type],
                        [e.target.name]: e.target.value,
                    },
                }
            ),
        )
    }

    const handleSubmit = () => {
        if (!_.isEqual(data, vismaVoucherSettings)) {
            setVismaVoucherSettings(data)

            if (!unsavedChanges) {
                setUnsavedChanges(true)
            }
        }
    }

    const textFieldProperty = (type, name) => {
        if (data) {
            if (data[type]) {
                return data[type][name]
            }
        }
    }

    return (
        <>
            <VismaVoucherSettingsGridHeader selectedProductGroup={selectedProductGroup}
                                            selectedDepartment={selectedDepartment}
                                            selectedMuseum={selectedMuseum}/>
            {gridRowsData?.map(({formLabel, formHelperText, textFieldName, textFieldLabel}, index) => (
                <VismaVoucherSettingsGridRow key={index}
                                             selectedProductGroup={selectedProductGroup}
                                             selectedMuseum={selectedMuseum}
                                             selectedDepartment={selectedDepartment}
                                             textFieldLabel={textFieldLabel}
                                             formHelperText={formHelperText}
                                             formLabel={formLabel}
                                             handleChange={handleChange}
                                             handleSubmit={handleSubmit}
                                             textFieldProperty={textFieldProperty}
                                             textFieldName={textFieldName}/>
            ))}
        </>
    )
}

export default VismaVoucherSettingsGrid
