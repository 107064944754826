import React, { createContext, useContext, useMemo, useState } from "react";

const ProductEditorTranslationContext = createContext({showTranslationFields: false, setShowTranslationFields: () => {}})

export const ProductTranslationProvider = ({children}) => {
    const [showTranslationFields, setShowTranslationFields] = useState(false)
    const value = useMemo(() => ({ showTranslationFields, setShowTranslationFields }), [showTranslationFields]);

    return (
      <ProductEditorTranslationContext.Provider value={value}>
          {children}
      </ProductEditorTranslationContext.Provider>
    )
}

export const useProductTranslationProvider = () => {
    return useContext(ProductEditorTranslationContext)
}