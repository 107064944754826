import React from "react"
import {Card, CardContent, CardHeader, ClickAwayListener, Popper} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"

const EventFilterDialog = (({anchorEl, open, handleClose, title, children}) => {
    const t = useBaseTranslation()

    return (
        <Popper open={open}
                anchorEl={anchorEl}
                style={{zIndex: 2600}}>
            <ClickAwayListener onClickAway={() => handleClose()}>
                <Card raised>
                    <CardHeader title={title || t("filterOnScheduleEvent")}/>
                    <CardContent>
                        {children}
                    </CardContent>
                </Card>
            </ClickAwayListener>
        </Popper>
    )
})

export default EventFilterDialog