import React from "react"
import {Card, CardContent, Grid, TextField} from "@mui/material"
import CardHeader from "@mui/material/CardHeader"
import {useBaseTranslation} from "../baseTranslationContext"

const CreateOrderDialogCustomerDetails = ({customer, handleCustomerChange, style}) => {
    const t = useBaseTranslation()

    return (
        <Card square
              style={style}>
            <CardHeader title={t("customerInfo")}
                        titleTypographyProps={{color: "primary"}}/>
            <CardContent>
                <TextField autoFocus
                           margin="dense"
                           name="email"
                           label="Epost"
                           variant="outlined"
                           type="email"
                           value={customer.email}
                           onChange={handleCustomerChange}
                           fullWidth/>
                <Grid container
                      spacing={1}
                      justifyContent="space-between">
                    <Grid item
                          xs={6}>
                        <TextField margin="dense"
                                   name="firstName"
                                   variant="outlined"
                                   label="Fornavn"
                                   value={customer.firstName}
                                   onChange={handleCustomerChange}
                                   fullWidth/>
                    </Grid>
                    <Grid item
                          xs={6}>
                        <TextField margin="dense"
                                   name="lastName"
                                   variant="outlined"
                                   label="Etternavn"
                                   value={customer.lastName}
                                   onChange={handleCustomerChange}
                                   fullWidth/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CreateOrderDialogCustomerDetails