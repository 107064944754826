import React from "react"
import {Grid, IconButton, Tooltip, Typography} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import {ProgressIndicator} from "../../ProgressIndicator"


const ScheduleEventExceptionsGridRowDelete = ({exception, handleRemove, exceptionIdLoading}) => {
    const {id} = exception

    const TooltipTitle = () => (
        <Typography variant="subtitle2">Slett unntak</Typography>
    )

    return (
        <Tooltip title={<TooltipTitle/>}>
            <Grid item>
                {exceptionIdLoading === id && id && exceptionIdLoading
                    ? <ProgressIndicator size={30} linear={false}/>
                    : <IconButton size="small" onClick={() => handleRemove(exception)}>
                        <DeleteIcon/>
                    </IconButton>
                }
            </Grid>
        </Tooltip>
    )
}

export default ScheduleEventExceptionsGridRowDelete