import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import DashboardCard from "../../app/components/DashboardCard"
import {List} from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import {Button} from "@mui/material"
import {useAxiosBpn} from "../../app/axios"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {useAuthsState} from "../../app/auths/authsContext"
import {ProgressIndicator} from "../../app/ProgressIndicator"
import JointProductsDiscountsListCard from "./JointProductsDiscountsListCard"
import DeleteConfirmationDialog from "../../app/components/DeleteConfirmationDialog"
import useCustomSnackbar from "../../app/components/useCustomSnackbar"
import {getTranslatableFieldValueFromObject} from "../../app/helper-functions/translationHelpers"

const JointProductsDiscountsList = ({
                                        handleNewDiscountClick,
                                        handleDiscountSelect,
                                        selectedDiscountId,
                                        refetch,
                                        refetchAndDeselect,
                                    }) => {
    const t = useBaseTranslation()
    const {umbrellaAndChildrenIdsInAccessList} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const snackbar = useCustomSnackbar()

    const [jointProductsDiscounts, setJointProductsDiscounts] = useState([])
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [discountIdToBeDeleted, setDiscountIdToBeDeleted] = useState()

    const [{data, loading}, getJointProductsDiscounts] = useAxiosBpn({
        url: "joint_products_discount",
        params: {
            museumIds: umbrellaAndChildrenIdsInAccessList?.length
                       ? umbrellaAndChildrenIdsInAccessList.join()
                       : Array.from(userAccessMuseumIds.keys()).join(),
        },
    })

    const [{error: deleteError}, deleteJointProductsDiscount] = useAxiosBpn({
        method: "DELETE",
    }, {manual: true})

    useEffect(() => {
        getJointProductsDiscounts().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getJointProductsDiscounts, refetch])

    useEffect(() => {
        if (data?.jointProductsDiscounts[0] && !data?.jointProductsDiscounts[0].name?.value) {
            const jointDiscounts = data.jointProductsDiscounts.map(jointDiscount => populateTranslateAbleFieldsFromObject(
                jointDiscount))
            setJointProductsDiscounts(jointDiscounts)
        }
    }, [data])

    useEffect(() => {
        if (deleteError) snackbar.showError(t(deleteError.message))
    }, [deleteError, snackbar, t])

    const handleDeleteButtonClick = id => {
        setDiscountIdToBeDeleted(id)
        setDeleteDialogOpen(true)
    }

    const populateTranslateAbleFieldsFromObject = (objectToPopulate) => {
        if (objectToPopulate) {
            objectToPopulate.name = getTranslatableFieldValueFromObject(objectToPopulate, "name")
            objectToPopulate.description = getTranslatableFieldValueFromObject(objectToPopulate, "description")
        }
        return objectToPopulate
    }

    const handleDelete = () => {
        setDeleteDialogOpen(false)
        deleteJointProductsDiscount({url: `joint_products_discount/${discountIdToBeDeleted}`})
            .then(() => {
                snackbar.showSuccess(`${t("jointProductsDiscount")} ${t("deleted").toLowerCase()}`)
                refetchAndDeselect()
            })
    }

    return (
        <>
            <DashboardCard avatar={<List/>}
                           title={t("jointProductsDiscounts")}
                           action={<Button onClick={handleNewDiscountClick}
                                           startIcon={<AddIcon/>}>{t("discount")}</Button>}>
                {loading
                 ? <ProgressIndicator/>
                 : jointProductsDiscounts?.length
                   ? jointProductsDiscounts.map((discount) => (
                            <JointProductsDiscountsListCard key={discount?.id}
                                                            selected={discount?.id === selectedDiscountId}
                                                            onClick={() => handleDiscountSelect(discount)}
                                                            onDeleteButtonClicked={() => handleDeleteButtonClick(
                                                                discount.id)}
                                                            {...discount}/>
                        ))
                   : t("noDiscountsYet")}
            </DashboardCard>
            <DeleteConfirmationDialog open={deleteDialogOpen}
                                      content={`${t("deleteJointProductsDiscountDialogText")}?`}
                                      handleClose={() => setDeleteDialogOpen(false)}
                                      handleDelete={handleDelete}/>
        </>
    )
}

export default JointProductsDiscountsList
