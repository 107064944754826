// noinspection NpmUsedModulesInstalled
import { refreshToken } from "@ekultur/authentication";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from "react";
// noinspection ES6CheckImport
import { useLocation } from "react-router-dom";
import { useAuthsDispatch, useAuthsState, USER_LOGGED_IN } from "./authsContext";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
    innerWrapper: {
        display: "flex",
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
    },
    outerWrapperBox: {
        zIndex: 1,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    },
}))

export const WithAuthentication = ({children}) => {
    const classes = useStyles()
    const location = useLocation()
    const dispatch = useAuthsDispatch()
    const {userIsAuthenticated} = useAuthsState()

    useEffect(() => {
        if (!location.pathname.startsWith("/oauth2/callback") && !userIsAuthenticated) {
            refreshToken({
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: () => {
                    console.log("Token refreshed!")
                    return (dispatch({type: USER_LOGGED_IN}))
                },
                onError: () => console.log("Could not refresh token. User must log in!"),
            })
        }
    }, [dispatch, location.pathname, userIsAuthenticated])

    return (
        <Box className={classes.outerWrapperBox}>
            <div className={classes.innerWrapper} style={{
                flexDirection: userIsAuthenticated ? "row" : "column",
            }}>
                {children}
            </div>
        </Box>
    )
}
