import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import {ArrowDropDown} from "@mui/icons-material"
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Chip,
    ClickAwayListener,
    Collapse,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@mui/material"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import EditIcon from "@mui/icons-material/Edit"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import makeStyles from '@mui/styles/makeStyles';
import KdrDialog from "./KdrDialog"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import clsx from "clsx"
import KdrMenuIdSetting from "./KdrMenuIdSetting"
import {useAuthsState} from "../../../app/auths/authsContext"
import {getUmbrellaMuseumChildren} from "../../../app/endpoints/museums"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}))

const PointOfSaleSystems = () => {
    const classes = useStyles()
    const t = useBaseTranslation()
    const {umbrellaId} = useMuseumSelectorState()
    const {userData} = useAuthsState()

    const [umbrellaChildren, setUmbrellaChildren] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedPointOfSaleSystem, setSelectedPointOfSaleSystem] = useState({})
    const [kdrDialogOpen, setKdrDialogOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [kdrMenuIdSettings, setKdrMenuIdSettings] = useState([])
    const [pointOfSaleSystems, setPointOfSaleSystems] = useState([])
    const [availablePointOfSaleSystems, setAvailablePointOfSaleSystems] = useState([])

    const [
        {
            data: pointOfSaleSystemsData,
            loading: pointOfSaleSystemsLoading,
        }, getPointOfSaleSystems,
    ] = useAxiosBpn({
        url: "point_of_sale_systems",
        params: {
            museumId: umbrellaId,
        },
    })

    const [{data: availablePointOfSaleSystemsData}, getAvailablePointOfSaleSystems] = useAxiosBpn({
        url: `point_of_sale_systems/${umbrellaId}/available`,
    })

    useEffect(() => {
        let isMounted = true

        function extracted() {
            getUmbrellaMuseumChildren(umbrellaId, userData?.appAccess?.museums)
                .then(({children}) => {
                    if (isMounted) {
                        setUmbrellaChildren(children)
                    }
                })
        }

        if (umbrellaId) {
            extracted()
        }
        return () => (isMounted = false)
    }, [umbrellaId, userData])

    useEffect(() => {
        getPointOfSaleSystems().catch(() => {
            // Just to avoid annoying console error
        })
        getAvailablePointOfSaleSystems().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getPointOfSaleSystems()
    }, [getPointOfSaleSystems, umbrellaId])

    useEffect(() => {
        if (pointOfSaleSystems?.length) {
            const pos = pointOfSaleSystems.find(pos => pos.name === "kdr")
            let kdrLocalSettings = pos.kdrLocalSettings

            umbrellaChildren.forEach(museum => {
                if (!kdrLocalSettings.some(setting => setting.museumId === museum.id)) {
                    kdrLocalSettings.push({
                        museumId: museum.id,
                        museumName: museum.name,
                        menuId: 0,
                        pointOfSaleSystemId: pos.id,
                        new: true,
                    })
                }
            })
            setKdrMenuIdSettings(kdrLocalSettings)
        } else {
            setKdrMenuIdSettings([])
        }
    }, [pointOfSaleSystems, umbrellaChildren])

    useEffect(() => {
        if (pointOfSaleSystemsData) {
            setPointOfSaleSystems(pointOfSaleSystemsData.pointOfSaleSystems)
        }
    }, [pointOfSaleSystemsData])

    useEffect(() => {
        if (availablePointOfSaleSystemsData) {
            setAvailablePointOfSaleSystems(availablePointOfSaleSystemsData.availablePointOfSaleSystems)
        }
    }, [availablePointOfSaleSystemsData])

    const handleAddPointOfSaleSystemClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleAddPointOfSaleSystemClose = () => {
        setAnchorEl(null)
    }

    const handleAddPointOfSaleSystemSelect = (pointOfSaleSystem) => {
        setSelectedPointOfSaleSystem(pointOfSaleSystem)
        setAnchorEl(null)

        if (pointOfSaleSystem.name === "kdr") {
            return setKdrDialogOpen(true)
        }

        return null
    }

    const handleKdrDialogClose = () => {
        setKdrDialogOpen(false)
        setSelectedPointOfSaleSystem({})
        getPointOfSaleSystems()
        getAvailablePointOfSaleSystems()
    }

    function getTitle(pointOfSaleSystem) {
        return <Typography variant="button">{t(pointOfSaleSystem.name)}</Typography>
    }

    function getSubheader() {
        return t("productionEnvironment")
    }

    function getAvatar(pointOfSaleSystem) {
        return <Avatar variant="square"
                       className={classes.avatar}>
            {t(pointOfSaleSystem.name).substring(0, 2)}
        </Avatar>
    }

    function getAction(pointOfSaleSystem) {
        return (
            <Grid container
                         direction="column"
                         alignItems="flex-end">
                <Grid item>
                    <Chip label={pointOfSaleSystem.enabled ? t("active") : t("notActive")}
                          size="small"
                          style={{
                              backgroundColor: pointOfSaleSystem.enabled ? "green" : "lightgray",
                              color: pointOfSaleSystem.enabled ? "white" : "gray",
                          }}/>
                    <IconButton
                        onClick={() => handleAddPointOfSaleSystemSelect(pointOfSaleSystem)}
                        size="large">
                        <EditIcon/>
                    </IconButton>
                </Grid>
                {pointOfSaleSystem.name === "kdr" &&
                 <Grid item>
                     <div style={{display: "flex", alignItems: "center"}}>
                         <Typography variant="caption"
                                     color="textSecondary">{t("menuIds")}</Typography>
                         <IconButton
                             className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
                             onClick={() => setExpanded(!expanded)}
                             size="large">
                             <ExpandMoreIcon/>
                         </IconButton>
                     </div>
                 </Grid>
                }
            </Grid>
        );
    }

    return (
        <div>
            <Grid container
                  justifyContent="flex-end">
                <Button variant="outlined"
                        color="primary"
                        onClick={handleAddPointOfSaleSystemClick}
                        endIcon={<ArrowDropDown/>}>
                    {t("addPointOfSaleSystem")}
                </Button>
                <Popper open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        style={{zIndex: 1500}}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleAddPointOfSaleSystemClose}>
                            {availablePointOfSaleSystems?.length
                             ? <MenuList> {availablePointOfSaleSystems.map((availablePointOfSaleSystem, index) => (
                                    <MenuItem key={index}
                                              onClick={() => handleAddPointOfSaleSystemSelect(availablePointOfSaleSystem)}>
                                        {`${t(availablePointOfSaleSystem.name)}`}
                                    </MenuItem>))}
                             </MenuList>
                             :
                             <Typography style={{padding: "16px"}}>{t("noMoreAvailablePointOfSaleSystems")}!</Typography>}
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Grid>
            <KdrDialog open={kdrDialogOpen}
                       pointOfSaleSystem={selectedPointOfSaleSystem}
                       handleClose={handleKdrDialogClose}/>

            {pointOfSaleSystems?.length
             ? pointOfSaleSystemsLoading
               ? <ProgressIndicator/>
               : pointOfSaleSystems.map((pointOfSaleSystem, index) =>
                        <Card key={index}
                              square
                              variant="outlined"
                              style={{marginTop: "8px"}}>
                            <CardHeader title={getTitle(pointOfSaleSystem)}
                                        avatar={getAvatar(pointOfSaleSystem)}
                                        action={getAction(pointOfSaleSystem)}
                                        subheader={getSubheader()}/>
                            {pointOfSaleSystem.name === "kdr" &&
                             <Collapse in={expanded}
                                       timeout="auto"
                                       unmountOnExit>
                                 <CardContent>
                                     <Typography>{t("menuIdsForEachMuseumInFoundation")}</Typography>
                                     <Typography variant="caption">{t("theseCanBeOverriddenOnEachProduct")}</Typography>
                                     {kdrMenuIdSettings?.length && kdrMenuIdSettings.map((setting, index) =>
                                         <KdrMenuIdSetting key={index}
                                                           data={setting}/>,
                                     )}
                                 </CardContent>
                             </Collapse>
                            }
                        </Card>)
             : <Typography style={{margin: "16px"}}>{t("noPointOfSaleSystemsAdded")}!</Typography>}
        </div>
    )
}

export default PointOfSaleSystems