import React from "react"
import {useHeaderState} from "./header/headerContext"
import {ProgressIndicator} from "./ProgressIndicator"

export const WhenHeaderIsReady = ({children}) => {
    const headerState = useHeaderState()

    if (!headerState.rendered) {
        return <ProgressIndicator/>
    }

    return (
        <>
            {children}
        </>
    )

}
