import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
} from "@mui/material"
import { Alert, AlertTitle } from '@mui/material';
import {useBaseTranslation} from "../baseTranslationContext"

const OrderDetailsDialogCreditConfirmationDialog = ({
                                                        open,
                                                        onClose,
                                                        productsInOrder,
                                                        order,
                                                        selectedProducts,
                                                        handleCreditAccept,
                                                        handleSelectedProductsChange,
                                                    }) => {
    const t = useBaseTranslation()

    const [partialCredit, setPartialCredit] = useState(false)

    const orderContainsRubicProduct = (order) => {
        return order?.orderLines.filter(orderLine => orderLine.rubicIntegrationEnabled).length > 0
    }

    const ProductWithCheckbox = ({product}) => {
        const {museumName, id, name} = product

        return (
            <FormControlLabel label={`${name} (${museumName})`}
                              onChange={e => handleSelectedProductsChange(e, product)}
                              value={product}
                              control={
                                  <Checkbox color={"primary"}
                                            checked={selectedProducts?.filter(
                                                selectedProduct => selectedProduct.id === id).length > 0}/>
                              }/>
        )
    }

    return (
        <Dialog open={open}
                maxWidth="md"
                onClose={onClose}>
            <DialogContent>
                {/*if customer.rubicmemberid is not set, this means that the buyer already is was registered in rubic with his provided member id. */}
                {!order?.customer?.rubicMemberId && orderContainsRubicProduct(order) && (
                    <Alert severity="warning"
                           style={{marginBottom: 8}}>
                        <AlertTitle>{t("warning")}</AlertTitle>
                        {t("creditOrderWithRubicMembershipWarning")}
                    </Alert>
                )}
                {productsInOrder?.length > 1 && (
                    <Alert severity="warning"
                           action={
                               <Button color="inherit" onClick={() => setPartialCredit(!partialCredit)}>
                                   Delkreditering
                               </Button>
                           }
                           style={{marginBottom: 8}}>
                        <AlertTitle>Flere produkter i samme ordre</AlertTitle>
                        Trykk på knappen til høyre hvis du ønsker å kreditere kun deler av ordren.
                    </Alert>
                )}
                {partialCredit && (
                    <FormControl required error={selectedProducts.length === 0} style={{marginTop: 16}}>
                        <FormLabel>Velg hvilke produkter som skal krediteres</FormLabel>
                        {productsInOrder?.map((product) => <ProductWithCheckbox key={product.id}
                                                                                product={product}/>)}
                        {selectedProducts.length === 0 && (
                            <FormHelperText>Minst ett produkt må være valgt</FormHelperText>
                        )}
                    </FormControl>
                )}
                <DialogContentText style={{marginTop: 16}}>
                    Det vil bli opprettet en kreditnota for denne ordren. <br/>
                    {order?.status === "confirmed_paid" && "Betalt beløp vil bli refundert kunden."}
                    <br/><br/> <strong>Vil du fortsette?</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                        color="primary">{t("cancel")}</Button>
                <Button onClick={handleCreditAccept}
                        disabled={partialCredit && selectedProducts.length === 0}
                        color="primary">{`Kreditér ${partialCredit && selectedProducts?.length < productsInOrder?.length ? "deler av " : "hele "}ordren`}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OrderDetailsDialogCreditConfirmationDialog
