import {ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@mui/material"
import React from "react"
import {useBaseTranslation} from "../baseTranslationContext"

export const GROUP_BY_PRICE_CATEGORY_NAME = "price_category_name"
export const GROUP_BY_PAYMENT_TYPE_NAME = "payment_type_name"
export const GROUP_BY_MUSEUM_NAME = "museum_name"
export const GROUP_BY_PRODUCT_NAME = "product_name"
export const GROUP_BY_PRODUCT_TYPE = "product_type"

export const GROUP_BY_ALTERNATIVES = [
    GROUP_BY_PRICE_CATEGORY_NAME,
    GROUP_BY_PAYMENT_TYPE_NAME,
    GROUP_BY_MUSEUM_NAME,
    GROUP_BY_PRODUCT_NAME,
    GROUP_BY_PRODUCT_TYPE,
]

const OrderSumsGroupBySelectMenu = ({
                                        open,
                                        anchorEl,
                                        setOpen,
                                        handleSelect,
                                    }) => {
    const t = useBaseTranslation()

    return (
        <Popper open={open}
                anchorEl={anchorEl}
                style={{zIndex: 1500}}>
            <Paper elevation={8}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuList>
                        {GROUP_BY_ALTERNATIVES.map((groupBy, index) => (
                            <MenuItem key={index}
                                      onClick={() => handleSelect(groupBy)}>
                                {t(groupBy)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    )
}

export default OrderSumsGroupBySelectMenu