import AccordionDetails from "@mui/material/AccordionDetails"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useEffect, useState} from "react"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import _, {lowerFirst} from "lodash"
import { Autocomplete } from '@mui/material';
import {Search} from "@mui/icons-material"
import {Paper} from "@mui/material"
import {useAxiosBpn} from "../../axios"
import useCustomSnackbar from "../../components/useCustomSnackbar"
import TranslatableTextField from "../TranslatableTextField"
import {getTranslatableFieldValueFromObject} from "../../helper-functions/translationHelpers"

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(2),
    },
}))

const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />
}

const ProductPriceDetails = ({
                                 price,
                                 handlePricesChange,
                                 kdrEnabled,
                                 kdrMenuId,
                                 kdrPosId,
                                 isGlobalAdmin,
                                 kdrTicketCodesEnabled,
                                 index,
                                 priceCategories,
                             }) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()
    const snackbar = useCustomSnackbar()

    const [_price, setPrice] = useState()
    const [_kdrMenuId, setKdrMenuId] = useState()
    const [selectedKdrProduct, setSelectedKdrProduct] = useState()
    const [kdrProducts, setKdrProducts] = useState([])
    const [localChanges, setLocalChanges] = useState(false)

    const [
        {
            data: kdrProductsData,
            loading: kdrProductsLoading,
            error: kdrProductsError,
        }, getKdrProducts,
    ] = useAxiosBpn({
        url: `point_of_sale_systems/${kdrPosId}/menu/${kdrMenuId}/products`,
    }, {
        manual: true,
    })

    useEffect(() => {
        if (_price?.kdrProductId) {
            let priceName = _price?.name

            if (kdrProducts) {
                let kdrProduct = kdrProducts.find(product => product.id === _price.kdrProductId)

                if (kdrProduct) {
                    priceName = kdrProduct.name
                }
            }

            setSelectedKdrProduct({id: _price?.kdrProductId, name: priceName, price: _price?.amount})
        }
    }, [_price, kdrProducts])

    const populateTranslateAbleFieldsFromObject = useCallback((objectToPopulate) => {
        if (!objectToPopulate?.name?.translations) {
            objectToPopulate.name = getTranslatableFieldValueFromObject(objectToPopulate, "name")
        }

        if (!objectToPopulate?.description?.translations) {
            objectToPopulate.description = getTranslatableFieldValueFromObject(objectToPopulate, "description")
        }
        return objectToPopulate
    }, [])

    useEffect(() => {
        setPrice(
            populateTranslateAbleFieldsFromObject(price),
        )
    }, [populateTranslateAbleFieldsFromObject, price])

    useEffect(() => {
        if (kdrEnabled) {
            setKdrMenuId(kdrMenuId)
        }
    }, [kdrEnabled, kdrMenuId])

    useEffect(() => {
        if (_kdrMenuId && kdrEnabled) {
            getKdrProducts().catch(() => {
                // Just to avoid annoying console error
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_kdrMenuId && kdrEnabled) {
            getKdrProducts()
        }
    }, [_kdrMenuId, getKdrProducts, kdrEnabled])

    useEffect(() => {
        if (kdrProductsData) {
            setKdrProducts(kdrProductsData.kdrProducts)
        }
    }, [kdrProductsData])

    useEffect(() => {
        if (kdrProductsError) snackbar.showError(t(kdrProductsError.message))
    }, [kdrProductsError, snackbar, t])

    const handlePriceObjChange = (propertyName, value) => {
        setPrice(prev => ({...prev, [propertyName]: value}))
        handlePricesChange(index, {..._price, [propertyName]: value})
        setLocalChanges(true)
    }

    const handlePriceSubmit = () => {
        if (!_.isEqual(_price, price)) {
            handlePricesChange(index, _price)
            setLocalChanges(true)
        }
    }

    const handlePriceCategorySelect = (id, category) => {
        setPrice(prev => ({
            ...prev,
            priceCategoryId: id,
            priceCategoryName: category,
            name: {value: category, translations: []},
        }))
    }

    const handleKdrProductSelect = (kdrProduct) => {
        setPrice(prev => ({
            ...prev,
            kdrProductId: kdrProduct?.id,
            name: kdrProduct?.name,
            amount: kdrProduct?.price,
        }))
    }

    return (
        <AccordionDetails>
            <form noValidate
                  autoComplete="off"
                  onBlur={handlePriceSubmit}>
                {kdrEnabled && (
                    <>
                        <TextField label={`${t("menuId")} (KDR)`}
                                   className={classes.textfield}
                                   disabled
                                   name="menuId"
                                   fullWidth
                                   variant="outlined"
                                   size="small"
                                   value={_kdrMenuId || ""}/>
                        <Autocomplete fullWidth
                                      openOnFocus
                                      options={kdrProducts}
                                      isOptionEqualToValue={(option, value) => option.name === value.name}
                                      getOptionLabel={option => option.name}
                                      value={selectedKdrProduct || null}
                                      onChange={
                                          (event, newValue) =>
                                              handleKdrProductSelect(newValue)
                                      }
                                      loading={kdrProductsLoading}
                                      PaperComponent={CustomPaper}
                                      renderInput={params => (
                                          <TextField {...params}
                                                     label={`KDR-${lowerFirst(t("product"))}/${lowerFirst(t("price"))}`}
                                                     variant="outlined"
                                                     required
                                                     error={!_price?.name && localChanges}
                                                     helperText={_price?.name && localChanges
                                                                 ? ""
                                                                 : t("mustHaveKdrProduct")}
                                                     className={classes.textfield}
                                                     size="small"
                                                     InputProps={{
                                                         ...params.InputProps,
                                                         startAdornment: <Search color="disabled"/>,
                                                     }}/>
                                      )}/>
                    </>
                )}
                {!kdrEnabled && kdrTicketCodesEnabled && isGlobalAdmin && (
                    <TextField label={`KDR-${lowerFirst(t("product"))}/${lowerFirst(t("price"))}ID`}
                               className={classes.textfield}
                               name="kdrProductId"
                               fullWidth
                               variant="outlined"
                               size="small"
                               onChange={(e) => handlePriceObjChange(e.target.name, e.target.value)}
                               value={_price?.kdrProductId || ""}/>
                )}
                {priceCategories && priceCategories.length && (
                    <TextField label={t("priceCategory")}
                               className={classes.textfield}
                               required
                               error={!_price?.priceCategoryName && localChanges}
                               helperText={t("priceCategoryNameHelperText")}
                               name="priceCategoryName"
                               variant="outlined"
                               select
                               size="small"
                               fullWidth
                               value={_price?.priceCategoryName || ""}>
                        {priceCategories.map(({id, name: category}) => (
                            <MenuItem key={id}
                                      onClick={() => handlePriceCategorySelect(id, category)}
                                      value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
                <TranslatableTextField label={t("name")}
                                       className={classes.textfield}
                                       name="name"
                                       helperText={t("priceNameHelperText")}
                                       variant="outlined"
                                       size="small"
                                       fullWidth
                                       onChange={(updatedValue) => handlePriceObjChange("name", updatedValue)}
                                       value={_price?.name || {value: "", translations: []}}/>
                <TranslatableTextField label={t("description")}
                                       className={classes.textfield}
                                       name="description"
                                       helperText={t("priceDescriptionHelperText")}
                                       variant="outlined"
                                       size="small"
                                       fullWidth
                                       onChange={(updatedValue) => handlePriceObjChange("description", updatedValue)}
                                       value={_price?.description || {value: "", translations: []}}/>
                <TextField label={kdrEnabled ? t("kdrPrice") : t("priceAmount")}
                           className={classes.textfield}
                           name="amount"
                           helperText={t("priceAmountHelperText")}
                           variant="outlined"
                           disabled={kdrEnabled}
                           size="small"
                           type="number"
                           fullWidth
                           onChange={(e) => handlePriceObjChange(e.target.name, e.target.value)}
                           value={_price?.amount || ""}/>
            </form>
        </AccordionDetails>
    );
}

export default ProductPriceDetails

