import React from "react"

export const ORDER_STATUS_DRAFT = "draft"
export const ORDER_STATUS_PENDING = "pending"
export const ORDER_STATUS_PROCESSED = "processed"
export const ORDER_STATUS_NEW = "new"
export const ORDER_STATUS_CANCELLED = "cancelled"
export const ORDER_STATUS_ANULLED = "annulled"
export const ORDER_STATUS_EMAIL_FAILED = "email_failed"
export const ORDER_STATUS_PAYMENT_PENDING = "payment_pending"
export const ORDER_STATUS_CONFIRMED = "confirmed"
export const ORDER_STATUS_CONFIRMED_PAID = "confirmed_paid"
export const ORDER_STATUS_CONFIRMED_PAY_LATER = "confirmed_pay_later"
export const ORDER_STATUS_CONFIRMED_CREDITED = "confirmed_credited"
export const ORDER_STATUS_CONFIRMED_PARTIAL_CREDITED = "confirmed_partial_credited"
export const ORDER_STATUS_CONFIRMED_CREDIT_NOTE = "confirmed_credit_note"
export const ORDER_STATUS_CONFIRMED_PARTIAL_CREDIT_NOTE = "confirmed_partial_credit_note"
export const ORDER_STATUS_PAYMENT_FAILED = "payment_failed"
export const ORDER_STATUS_MENU_ID_MISSING = "menu_id_missing"
export const ORDER_STATUS_ISSUER_IS_UNDETERMINED = "issuer_is_undetermined"
export const ORDER_STATUS_UNKNOWN_NETAXEPT_ERROR = "unknown_netaxept_error"
export const ORDER_STATUS_CANCELLED_BY_USER = "cancelled_by_user"
export const ORDER_STATUS_GENERAL_ERROR = "general_error"
export const ORDER_STATUS_NO_TRANSACTION = "no_transaction"
export const ORDER_STATUS_TIMEOUT_EXPERIENCED = "timeout_experienced"
export const ORDER_STATUS_REVERSAL_FAILED = "reversal_failed"
export const ORDER_STATUS_REFUSED_BY_ISSUER = "refused_by_issuer"
export const ORDER_STATUS_DENIED_BY_3D_AUTH = "denied_by_3d_auth"
export const ORDER_STATUS_INTERNAL_FAILURE = "internal_failure"
export const ORDER_STATUS_ALREADY_REVERSED = "already_reversed"
export const ORDER_STATUS_TRANS_REJECTED = "trans_rejected"
export const ORDER_STATUS_TRANS_NOT_FOUND = "trans_not_found"
export const ORDER_STATUS_REFUSED_BY_ISSUER_CONFISCATE = "refused_by_issuer_confiscate"
export const ORDER_STATUS_REFUSED_BY_ISSUER_CONFISCATE_FRAUD = "refused_by_issuer_confiscate_fraud"
export const ORDER_STATUS_REFUSED_BY_ISSUER_INVALID_TRANSACTION = "refused_by_issuer_invalid_transaction"
export const ORDER_STATUS_REFUSED_BY_ISSUER_INVALID_ACCOUNT = "refused_by_issuer_invalid_account"
export const ORDER_STATUS_REFUSED_BY_ISSUER_INVALID_ISSUER = "refused_by_issuer_invalid_issuer"
export const ORDER_STATUS_REFUSED_BY_ISSUER_CARD_LOST = "refused_by_issuer_card_lost"
export const ORDER_STATUS_REFUSED_BY_ISSUER_CARD_STOLEN = "refused_by_issuer_card_stolen"
export const ORDER_STATUS_REFUSED_BY_ISSUER_CLOSED_ACCOUNT = "refused_by_issuer_closed_account"
export const ORDER_STATUS_REFUSED_BY_ISSUER_TRANSACTION_NOT_ALLOWED = "refused_by_issuer_transaction_not_allowed"
export const ORDER_STATUS_REFUSED_BY_ISSUER_STOP_PAYMENT = "refused_by_issuer_stop_payment"
export const ORDER_STATUS_REFUSED_BY_ISSUER_CALLBACK_OF_AUTHORIZATION = "refused_by_issuer_callback_of_authorization"
export const ORDER_STATUS_REFUSED_BY_ISSUER_CALLBACK_OF_ALL_AUTHORIZATIONS = "refused_by_issuer_callback_of_all_authorizations"

export const CONFIRMED_ORDER_STATUSES = [
    ORDER_STATUS_CONFIRMED,
    ORDER_STATUS_CONFIRMED_PAID,
    ORDER_STATUS_CONFIRMED_PAY_LATER,
    ORDER_STATUS_CONFIRMED_CREDITED,
    ORDER_STATUS_CONFIRMED_PARTIAL_CREDITED,
    ORDER_STATUS_CONFIRMED_CREDIT_NOTE,
    ORDER_STATUS_CONFIRMED_PARTIAL_CREDIT_NOTE,
]

export const CONFIRMED_EXCEPT_CREDITED_ORDER_STATUSES = [
    ORDER_STATUS_CONFIRMED,
    ORDER_STATUS_CONFIRMED_PAID,
    ORDER_STATUS_CONFIRMED_PAY_LATER,
    ORDER_STATUS_CONFIRMED_PARTIAL_CREDITED,
]

export const ONLY_NEW_ORDER_STATUSES = [ORDER_STATUS_NEW]

export const OTHER_ORDER_STATUSES = [
    ORDER_STATUS_DRAFT,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_PROCESSED,
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_ANULLED,
    ORDER_STATUS_CANCELLED_BY_USER,
    ORDER_STATUS_PAYMENT_PENDING,
]

export const FAILED_ORDER_STATUSES = [
    ORDER_STATUS_REFUSED_BY_ISSUER_CONFISCATE,
    ORDER_STATUS_REFUSED_BY_ISSUER_CONFISCATE_FRAUD,
    ORDER_STATUS_REFUSED_BY_ISSUER_INVALID_TRANSACTION,
    ORDER_STATUS_REFUSED_BY_ISSUER_INVALID_ACCOUNT,
    ORDER_STATUS_REFUSED_BY_ISSUER_INVALID_ISSUER,
    ORDER_STATUS_REFUSED_BY_ISSUER_CARD_LOST,
    ORDER_STATUS_REFUSED_BY_ISSUER_CARD_STOLEN,
    ORDER_STATUS_REFUSED_BY_ISSUER_CLOSED_ACCOUNT,
    ORDER_STATUS_REFUSED_BY_ISSUER_TRANSACTION_NOT_ALLOWED,
    ORDER_STATUS_REFUSED_BY_ISSUER_STOP_PAYMENT,
    ORDER_STATUS_REFUSED_BY_ISSUER_CALLBACK_OF_AUTHORIZATION,
    ORDER_STATUS_REFUSED_BY_ISSUER_CALLBACK_OF_ALL_AUTHORIZATIONS,
    ORDER_STATUS_EMAIL_FAILED,
    ORDER_STATUS_PAYMENT_FAILED,
    ORDER_STATUS_MENU_ID_MISSING,
    ORDER_STATUS_ISSUER_IS_UNDETERMINED,
    ORDER_STATUS_UNKNOWN_NETAXEPT_ERROR,
    ORDER_STATUS_GENERAL_ERROR,
    ORDER_STATUS_NO_TRANSACTION,
    ORDER_STATUS_TIMEOUT_EXPERIENCED,
    ORDER_STATUS_REVERSAL_FAILED,
    ORDER_STATUS_REFUSED_BY_ISSUER,
    ORDER_STATUS_DENIED_BY_3D_AUTH,
    ORDER_STATUS_INTERNAL_FAILURE,
    ORDER_STATUS_ALREADY_REVERSED,
    ORDER_STATUS_TRANS_REJECTED,
    ORDER_STATUS_TRANS_NOT_FOUND,
]

export const getSelectableStatusFiltersBasedOnSingleStatus = status => {
    if (status === "confirmedExceptCredited") return CONFIRMED_EXCEPT_CREDITED_ORDER_STATUSES

    switch (status) {
        case CONFIRMED_ORDER_STATUSES.includes(status):
            return CONFIRMED_ORDER_STATUSES
        case FAILED_ORDER_STATUSES.includes(status):
            return FAILED_ORDER_STATUSES
        case OTHER_ORDER_STATUSES.includes(status):
            return OTHER_ORDER_STATUSES
        case ONLY_NEW_ORDER_STATUSES.includes(status):
            return ONLY_NEW_ORDER_STATUSES
        default:
            return CONFIRMED_ORDER_STATUSES
    }
}

export const isConfirmed = (status) => CONFIRMED_ORDER_STATUSES.includes(status)

export const getOrderStatusDescription = (status) => {

    switch (status) {
        case ORDER_STATUS_CANCELLED_BY_USER:
            return <>Dette betyr at brukeren har valgt å avbryte i Netaxepts betalingsterminal</>
        case ORDER_STATUS_PAYMENT_PENDING:
            return <>
                Denne ordrestatusen blir satt i det øyeblikket brukeren blir
                <u>videresendt fra vår kjøpsportal til Netaxepts betalingsterminal</u>.
                Siden status er uendret, betyr det at det aldri kom noe respons fra Netaxept etter at brukeren
                ble videresendt. <br/><br/>
                Den vanligste årsaken er at brukeren lukket nettleser uten å trykke på avbryt-knappen,
                enten fordi hen ombestemte seg eller fikk en feilmelding i terminalen.<br/><br/>
                Hvis du prøver å prosessere ordren på nytt, vil det bli gjort en ny spørring til Netaxept. Hvis det har
                skjedd en feil, blir ordrestatus oppdatert basert på eventuell feilkode.
            </>
        case ORDER_STATUS_ISSUER_IS_UNDETERMINED:
            return <>
                "<i>Issuer is undetermined</i>" er en feilmelding vi har fått fra Netaxept. Noen kjente årsaker til
                dette kan være:
                <ul>
                    <li>Bruker har lukket nettleseren <u>uten å trykke på avbryt-knappen</u></li>
                    <ul>
                        <li>Hen ombestemte seg</li>
                        <li>Det er skrevet inn et kortnummer til et annet type kort enn det som ble valgt i første
                            steg
                        </li>
                        <li>Fått en annen feilmelding i betalingsterminalen</li>
                    </ul>
                    <li>Nettleseren er treg eller har hengt seg</li>
                    <ul>
                        <li>Vindu får valg av tredjepartsverifisering (slik som BankID) dukker ikke opp</li>
                        <li>Dårlig linje/dekning</li>
                    </ul>
                </ul>

                <h4>Ting å forsøke:</h4>
                <ol>
                    <li>
                        <u>Prosessér ordren på nytt</u>. Hvis vi får informasjon om en ikke tidligere kommunisert feil i
                                                       Netaxept, vil ordrestatus oppdateres.
                    </li>
                    <li>
                        <u>Dobbeltsjekk at beløp ikke er trukket.</u> Dette gjøres ved trykke <i>kjør spørring</i> under
                                                                      betaling. I rapporten som kommer fra Netaxept skal
                                                                      det være et felt som heter "Beløp trukket" under
                                                                      oppsummering. Hvis beløpet er 0 er det ikke
                                                                      trukket penger fra kundens konto i forbindelse med
                                                                      akkurat denne ordren (<i>men i noen tilfeller kan
                                                                                               beløpet likevel stå som
                                                                                               reservert i
                                                                                               nettbanken!</i>)
                    </li>
                    <li>
                        <u>Send en epostforespørsel til Nets for å sjekke status på denne ordren/transaksjonen.</u>
                        Oppgi transaksjons-ID i eposten (se under betaling).
                    </li>
                    <li>
                        Be kunden gjenta kjøpet med et annet kort
                    </li>
                </ol>
            </>
        case ORDER_STATUS_NEW:
            return <>
                Denne ordrestatusen blir satt helt i begynnelsen av kjøpsprosessen, etter at dato er valgt. At den står
                uendret betyr at nettleservinduet har blitt lukket før brukeren har trykket på betalknappen.
            </>
        default:
            return null
    }
}



