import React from "react"
import {Grid, Typography} from "@mui/material"
import {Check, Drafts} from "@mui/icons-material"
import {useBaseTranslation} from "../baseTranslationContext"

const StatusIcon = ({status}) => {
    const t = useBaseTranslation()

    const getIcon = () => {
        switch (status) {
            case "published":
                return <Check style={{color: "green"}}/>
            case "draft":
                return <Drafts color="disabled"/>
            default:
                return null
        }
    }

    return (
        <Grid container
              alignItems="flex-end">
            <Grid item>
                {getIcon()}
            </Grid>
            <Grid>
                <Typography variant="overline"
                            color="textSecondary">
                    {t(status)}
                </Typography>
            </Grid>

        </Grid>
    )
}

export default StatusIcon