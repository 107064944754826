import React, {useState, useEffect} from "react"
import {
    Typography,
    TextField,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    CardHeader,
    IconButton,
    Chip
} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import _ from "lodash"
import {useAxiosBpn} from "../axios"
import useCustomSnackbar from "../components/useCustomSnackbar"
import {GridLabel, GridValue} from "./gridComponents"
import {Cancel} from "@mui/icons-material"

const textFieldProps = {
    margin: "dense",
    variant: "outlined",
    autoComplete: "new-password"
}

const OrderDetailsDialogCustomerDetails = ({loading, order}) => {
    const t = useBaseTranslation()

    const [_customer, setCustomer] = useState()
    const [editMode, setEditMode] = useState(false)
    const [gridRows, setGridRows] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const snackbar = useCustomSnackbar()

    const [{data: putData, loading: putLoading, error: putError}, putOrder] = useAxiosBpn({
        url: `orders/${order.id}/minimal`,
        method: "PUT",
    }, {manual: true})


    const handleSave = () => {
        putOrder({data: {...order, customer: _customer}})
    }

    useEffect(() => {
        if (order?.customer) {
            setCustomer(order.customer)
        }
    }, [order])

    useEffect(() => {
        if (_customer) {
            setGridRows([
                {name: "email", label: t("email"), value: _customer?.email},
                {name: "firstName", label: t("firstName"), value: _customer?.firstName},
                {name: "lastName", label: t("lastName"), value: _customer?.lastName},
                {name: "phone", label: t("phone"), value: _customer?.phone}
            ])
        }
    }, [_customer, t])

    useEffect(() => {
        if (putData) {
            snackbar.showSuccess(`${t("customer")} ${t("updated").toLowerCase()}`)
            setCustomer(putData.order.customer)
            setUnsavedChanges(false)
            setEditMode(false)
        }
    }, [putData, snackbar, t])

    useEffect(() => {
        if (putError) {
            snackbar.showError(t(putError.message))
        }
    }, [putError, snackbar, t])

    const handleCustomerChange = (e) => {
        e.persist()
        setCustomer(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleCancel = () => {
        setCustomer(order.customer)
        setUnsavedChanges(false)
        setEditMode(false)
    }

    const handleSubmit = () => {
        if (!_.isEqual(_customer, order.customer)) {
            setUnsavedChanges(true)
        }
    }

    const getTitle = () => (
        <Grid container
              spacing={1}>
            <Grid item>
                {`${t("customer")} ${_customer?.id}`}
            </Grid>
            <Grid item>
                <Chip label={t(_customer?.status)}
                      color="primary"
                      size="small"/>
            </Grid>
        </Grid>)

    return loading || putLoading
           ? <ProgressIndicator/>
           : _customer
             ? <Card square
                     variant="outlined">
                 <CardHeader title={getTitle()}
                             titleTypographyProps={{color: "primary"}}
                             subheader={"Personen registrert under kjøpsprosessen og mottager av epostkvittering"}
                             action={
                                 <IconButton onClick={() => setEditMode(!editMode)} size="large">
                                     <EditIcon/>
                                 </IconButton>}/>
                 <CardContent>
                     {editMode
                      ? <form noValidate
                              autoComplete="off"
                              onBlur={handleSubmit}>
                          {gridRows?.map(({name, label, value}, index) => (
                              <TextField {...textFieldProps}
                                         key={index}
                                         fullWidth
                                         label={label}
                                         name={name}
                                         value={value || ""}
                                         onChange={handleCustomerChange}/>
                          ))}
                      </form>
                      : <>
                          <Grid container
                                direction="column">
                              {gridRows?.map(({label, value}, index) =>
                                  value &&
                                  <Grid container
                                        key={index}
                                        alignItems="center">
                                      <GridLabel>{label}:</GridLabel>
                                      <GridValue>{value}</GridValue>
                                  </Grid>
                              )}
                          </Grid>
                      </>}
                 </CardContent>
                 <CardActions style={{justifyContent: "flex-end"}}>
                     {unsavedChanges &&
                     <>
                         <Typography variant="subtitle2"
                                     color="textSecondary">
                             {t("unsavedChanges") + "!"}
                         </Typography>
                         <Button variant="text"
                                 onClick={handleCancel}
                                 color="primary"
                                 startIcon={<Cancel/>}>
                             {t("regret")}
                         </Button>
                     </>
                     }
                     {unsavedChanges || editMode
                      ? <Button variant="text"
                                color="primary"
                                disabled={!unsavedChanges}
                                onClick={handleSave}
                                startIcon={<SaveIcon/>}>
                          {t("save")}
                      </Button>
                      : null
                     }
                 </CardActions>
             </Card>
             : null;
}

export default OrderDetailsDialogCustomerDetails
