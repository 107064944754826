import {AccordionSummary, Hidden} from "@mui/material"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import makeStyles from '@mui/styles/makeStyles';
import React from "react"
import {useProductEditorTranslation} from "../productEditorTranslationContext"

const useStyles = makeStyles(theme => ({
    alert: {
        color: "#d32f2f",
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(13),
        color: theme.palette.text.secondary,
    },
}))

const ProductPriceSummary = ({
                                 provided,
                                 price: {
                                     amount,
                                     kdrProductId,
                                     name,
                                     priceCategoryName,
                                     productGroupName,
                                     vat,
                                 },
                                 expanded,
                                 index,
                                 kdrEnabled,
                             }) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()

    return <>
        {priceCategoryName ? (
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Grid container
                      wrap="nowrap"
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-evenly">
                    <Grid item>
                        <IconButton {...provided.dragHandleProps} draggable disableRipple size="large">
                            <DragHandleIcon/>
                        </IconButton>
                    </Grid>
                    <Grid container
                          direction="column"
                          item
                          xs
                          sm={3}
                          lg={4}>
                        <Grid item>
                            <Typography className={classes.heading}>{name?.value}</Typography>
                        </Grid>
                        <Grid item>
                            <Chip size="small"
                                  color="primary"
                                  label={priceCategoryName}/>
                        </Grid>
                    </Grid>
                    <Hidden smDown>
                        <Grid container
                              item
                              direction="column"
                              spacing={1}
                              xs>
                            <Grid item>
                                <Typography className={classes.secondaryHeading}>
                                    <strong>{t("productGroup")}: </strong>
                                    {productGroupName} - {vat}% {t("vat")}
                                </Typography>
                            </Grid>
                            {kdrEnabled && kdrProductId && (
                                <Grid item>
                                    <Typography className={classes.secondaryHeading}>
                                        <i>
                                            <strong>{`${t("menuId")} (KDR)`}: </strong>
                                            {kdrProductId}
                                        </i>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <Typography variant="h5">{amount}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
        ) : (
             <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                               {...provided.dragHandleProps}>
                 <Grid container
                       alignContent="center"
                       spacing={1}>
                     <Grid item>
                         <Typography>{t("addNewPrice")}</Typography>
                     </Grid>
                     <Grid item>
                         {expanded !== index && (
                             <Typography className={classes.alert}>
                                 {t("rememberToAddPriceCategory")}!
                             </Typography>
                         )}
                     </Grid>
                 </Grid>
             </AccordionSummary>
         )}
    </>;
}

export default ProductPriceSummary