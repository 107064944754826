import React, {useEffect, useState} from "react"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import {Grid, IconButton, Tooltip, Typography} from "@mui/material"
import {createRevenueChartData, createRevenueTableHeaders, createRevenueTableRows} from "./revenueHelperFunctions"
import _ from "lodash"
import {getDateLabelBasedOnFequency} from "../helper-functions/dateFormat"
import StackedBarChart from "../components/StackedBarChart"
import {OpenInNew, TableChart} from "@mui/icons-material"
import GenericPopper from "../components/GenericPopper"
import GenericTable from "../generic-table/GenericTable"
import DashboardCard from "../components/DashboardCard"
import {useHistory} from "react-router-dom"

const SmallRevenueCard = ({
                              data,
                              loading,
                              frequency,
                              groupBy,
                              title,
                              subheader,
                              avatar,
                              shortcutTo,
                              testOrders,
                          }) => {
    const t = useBaseTranslation()
    const history = useHistory()

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [uniqueGroups, setUniqueGroups] = useState()
    const [chartData, setChartData] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [tableDialogOpen, setTableDialogOpen] = useState(false)

    useEffect(() => {
        if (data) {
            const {groupedOrderSums} = data

            if (groupedOrderSums) {
                const {columns: _columns, data: _data} = groupedOrderSums
                const hiddenIndexes = groupBy ? [2, 3, 4] : [1, 2, 3]

                const headers = createRevenueTableHeaders(_columns, t, false, false, hiddenIndexes)
                setColumns(headers)

                const _rows = createRevenueTableRows(_data, _columns, frequency, t, groupBy)
                setRows(_rows)

                const _groupBy = _.camelCase(groupBy)
                const _uniqueGroups = _groupBy ? [...new Set(_rows.map(r => r[_groupBy]))] : [t("revenue")]
                setUniqueGroups(_uniqueGroups)

                const dateGroupByLabel = getDateLabelBasedOnFequency(frequency)
                const uniqueDates = [...new Set(_rows.map(r => r[dateGroupByLabel]))]
                setChartData(createRevenueChartData(
                    _rows,
                    uniqueDates,
                    _uniqueGroups,
                    _groupBy,
                    dateGroupByLabel,
                    t("revenue")))
            } else {
                setColumns([])
                setRows([])
                setUniqueGroups([])
                setChartData([])
            }
        } else {
            setColumns([])
            setRows([])
            setUniqueGroups([])
            setChartData([])
        }
    }, [data, t, frequency, groupBy])

    const handleTableDialogOpen = (event) => {
        setTableDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const Content = () => {
        return rows.length
            ? <StackedBarChart data={chartData}
                               groups={uniqueGroups}
                               xAxisLabel={t(getDateLabelBasedOnFequency(frequency))}
                               yAxisLabel={t("revenue")}/>
            : <Typography>{t("noDataToShow")}</Typography>
    }

    const getCardAction = () => (
        <Grid container spacing={1}>
            {shortcutTo && shortcutTo?.url && (
                <Grid item>
                    <Tooltip title={shortcutTo?.label ? shortcutTo.label : "Gå til hovedside for omsetning"}>
                        <IconButton onClick={() => history.push(shortcutTo.url)} size="large">
                            <OpenInNew/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            <Grid item>
                <Tooltip title={t("seeTableData")}>
                    <IconButton onClick={handleTableDialogOpen} size="large">
                        <TableChart/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

    return (
        <>
            <DashboardCard maxHeight={650}
                           title={title}
                           subheader={subheader}
                           testOrders={testOrders}
                           action={rows.length ? getCardAction() : null}
                           avatar={avatar}
                           cardContentStyle={{
                               overflowY: "auto",
                               overflowX: "hidden",
                               maxHeight: 650 - 64,
                           }}>
                {loading
                    ? <ProgressIndicator/>
                    : <Content/>
                }
            </DashboardCard>
            <GenericPopper anchorEl={anchorEl}
                           open={tableDialogOpen}
                           handleClose={() => setTableDialogOpen(false)}>
                <GenericTable columns={columns}
                              rows={rows}
                              disableOverflowScroll/>
            </GenericPopper>
        </>
    )
}

export default SmallRevenueCard


