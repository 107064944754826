import React, {useEffect, useState} from "react"
import {Grid, Typography} from "@mui/material"
import {parseAndFormatTimeString} from "../../helper-functions/dateFormat"
import makeStyles from '@mui/styles/makeStyles';
import {getSubstring} from "../../helper-functions/stringUtility"

const useStyles = makeStyles(theme => ({
    time: {
        fontSize: theme.typography.pxToRem(10),
        color: theme.palette.text.secondary,
    },
    name: {
        fontSize: theme.typography.pxToRem(11),
        color: theme.palette.text.secondary,
    },
}))


const ScheduleEventExceptionsGridRowTimeAndName = ({
                                                       endTime,
                                                       originalEndTime,
                                                       originalStartTime,
                                                       startTime,
                                                       name,
                                                       originalName,
                                                   }) => {
    const classes = useStyles()

    const [timeLabel, setTimeLabel] = useState("")
    const [nameLabel, setNameLabel] = useState("")

    useEffect(() => {
        setTimeLabel(`${parseAndFormatTimeString(originalStartTime || startTime)}-${parseAndFormatTimeString(originalEndTime || endTime)}`)
    }, [endTime, originalEndTime, originalStartTime, startTime])

    useEffect(() => setNameLabel(getSubstring(originalName || name || "", 30)), [name, originalName])

    return (
        <Grid container direction="column" item xs>
            <Grid item><Typography className={classes.time}>{timeLabel}</Typography></Grid>
            <Grid item><Typography className={classes.name}>{nameLabel}</Typography></Grid>
        </Grid>
    )
}

export default ScheduleEventExceptionsGridRowTimeAndName