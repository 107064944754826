import React from "react"
import { Autocomplete } from '@mui/material';
import {TextField} from "@mui/material"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"

const ProductSelectorAutocomplete = ({options, loading, label, selectedProduct, setSelectedProduct}) => {
    const t = useBaseTranslation()

    const allProducts = {
        id: 0,
        name: t("all"),
        type: "all",
    }

    function getElement() {
        return options
               ? <Autocomplete options={options.sort((a, b) => -t(b.type).localeCompare(t(a.type)))}
                               size="small"
                               value={selectedProduct || allProducts}
                               style={{width: 160, marginLeft: 8}}
                               groupBy={(option) => t(option.type)}
                               getOptionLabel={(option) => option.name}
                               isOptionEqualToValue={(option, value) => option.name === value.name || value.type === "all"}
                               renderInput={(params) => (
                                   <TextField {...params}
                                              color="primary"
                                              label={label}
                                              variant="standard"/>
                               )}
                               onChange={(event, newValue) => {
                                   setSelectedProduct(newValue || allProducts)
                               }}>
               </Autocomplete>
               : null;
    }

    return loading
           ? <ProgressIndicator size={20}
                                linear={false}/>
           : getElement()
}

export default ProductSelectorAutocomplete