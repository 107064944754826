import React from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {Button, ButtonGroup, Grid, Tooltip, Typography} from "@mui/material"
import {NavigateBefore, NavigateNext} from "@mui/icons-material"
import {upperFirst} from "lodash"
import {dateFormat} from "../helper-functions/dateFormat"

const CalendarToolbar = ({handlePreviousMonthClick, handleNextMonthClick, handleTodayClick, timeInterval}) => {
    const t = useBaseTranslation()

    return (
        <div style={{marginBottom: 8}}>
            <Grid container
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                  wrap="nowrap">
                <Grid container
                      item
                      direction="row"
                      spacing={1}
                      wrap="nowrap">
                    <Grid item>
                        <ButtonGroup color="primary"
                                     variant="contained"
                                     size="small">
                            <Button onClick={handlePreviousMonthClick}>
                                <NavigateBefore/>
                            </Button>
                            <Button onClick={handleNextMonthClick}>
                                <NavigateNext/>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <Button color="primary"
                                size="small"
                                onClick={handleTodayClick}
                                variant="contained">
                            {t("today")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container
                      item
                      justifyContent="center">
                    <Typography variant="h4">
                        {upperFirst(dateFormat(timeInterval?.start, "MMMM Y"))}
                    </Typography>
                </Grid>
                <Grid container
                      item
                      justifyContent="flex-end">
                    <Tooltip title="Kalender er under utvikling. For øyeblikket fungerer kun månedsvisning.">
                        <ButtonGroup color="primary"
                                     size="small">
                            <Button>
                                {t("month")}
                            </Button>
                            <Button disabled>
                                {t("week")}
                            </Button>
                            <Button disabled>
                                {t("agenda")}
                            </Button>
                        </ButtonGroup>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    )
}

export default CalendarToolbar