import {Card, CardContent, useTheme} from "@mui/material"

import React, {useEffect, useState} from "react"
import SectionHeader from "../../app/components/SectionHeader"
import {SALES_BASE_URL} from "../../app/Routes"
import {useSalesTranslation} from "../salesTranslationContext"
import {toLowerCase} from "../../app/helper-functions/stringUtility"
import OrderDetailsDialog from "../../app/order-details/OrderDetailsDialog"
import {calculateHeight} from "../../app/helper-functions/calculateHeight"
import OrderTable from "../../app/order-table/OrderTable"
import TestOrdersSwitch from "../../app/components/TestOrdersSwitch"

const LOCAL_STORAGE_KEY = "bpn.testOrders"

export const SalesList = () => {
    const t = useSalesTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()

    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const [orderDialogOpen, setOrderDialogOpen] = useState(false)
    const [selectedOrderId, setSelectedOrderId] = useState()
    const [refetchOrders, setRefetchOrders] = useState(false)

    const testOrderString = ` (${toLowerCase(t("testOrders"))})`

    const breadcrumbs = [
        {label: t("sales"), url: SALES_BASE_URL},
        {label: `${t("list")}${testOrders ? testOrderString : ""}`},
    ]

    useEffect(() => {
        if (selectedOrderId) {
            setOrderDialogOpen(true)
        }
    }, [selectedOrderId])

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    const handleOrderDialogClose = () => {
        setOrderDialogOpen(false)
        setSelectedOrderId(null)
        setRefetchOrders(!refetchOrders)
    }

    const handleOrderIdClick = (id) => {
        setSelectedOrderId(id)
    }

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <div style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: calculateHeight(false, true, true),
            }}>
                <Card style={{flex: 1, height: "100%", backgroundColor: testOrders ? testOrdersPalette?.light : null}}>
                    <CardContent>
                        <OrderTable testOrders={testOrders}
                                    defaultToCurrentMonth={true}
                                    eventFilterButtonHidden={true}
                                    handleOrderIdClick={handleOrderIdClick}/>
                    </CardContent>
                    <OrderDetailsDialog orderId={selectedOrderId}
                                        open={orderDialogOpen}
                                        handleClose={handleOrderDialogClose}/>
                </Card>
            </div>
        </>
    )
}
