import React, {useEffect, useState} from "react"
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, useTheme} from "@mui/material"
import Button from "@mui/material/Button"
import {useAxiosBpn} from "../axios"
import {ProgressIndicator} from "../ProgressIndicator"
import useCustomSnackbar from "../components/useCustomSnackbar"
import {useBaseTranslation} from "../baseTranslationContext"
import { Alert } from '@mui/material';
import CreateOrderDialogCustomerDetails from "./CreateOrderDialogCustomerDetails"
import CreateOrderDialogProductAccordion from "./CreateOrderDialogProductAccordion"
import CreateOrderDialogPaymentTypes from "./CreateOrderDialogPaymentTypes"
import CreateOrderDialogDiscountCode from "./CreateOrderDialogDiscountCode"
import CreateOrderDialogSummary from "./CreateOrderDialogSummary"
import {getErrorMessage} from "../helper-functions/getErrorMessage"
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext"

const initialCustomer = {
    email: "",
    firstName: "",
    lastName: "",
}

const CreateOrderDialog = ({productId, open, handleClose, testOrder = true}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()
    const {umbrellaId} = useMuseumSelectorState()

    const [customer, setCustomer] = useState({...initialCustomer})
    const [discountCode, setDiscountCode] = useState()
    const [paymentTypes, setPaymentTypes] = useState([])
    const [radioGroupPaymentTypeValue, setRadioGroupPaymentTypeValue] = useState("")
    const [selectedPaymentType, setSelectedPaymentType] = useState({})
    const [showTestCards, setShowTestCards] = useState(false)
    const [expanded, setExpanded] = useState(productId)
    const [product, setProduct] = useState()
    const [order, setOrder] = useState()
    const [remainingTicketsAfterOrderUpdate, setRemainingTicketsAfterOrderUpdate] = useState()

    const [{data, loading}, getProduct] = useAxiosBpn({url: `products/${productId}`}, {manual: true})
    const [{data: paymentTypesData, loading: paymentTypesLoading}] = useAxiosBpn({
        url: "payment_types",
        params: {museumId: umbrellaId}
    })

    const [{data: postOrderData, error: postOrderError}, postOrder] = useAxiosBpn({
        url: "orders",
        params: {
            test: testOrder,
        },
        method: "POST",
    }, {manual: true})

    const [
        {
            data: putOrderData,
            loading: putLoading,
            error: putOrderError,
        }, putOrder,
    ] = useAxiosBpn({url: `orders/${order?.id}`, method: "PUT"},
        {manual: true})

    const [, processOrder] = useAxiosBpn({
        url: "orders/process",
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        if (open && !product) getProduct()
    }, [getProduct, open, product])

    useEffect(() => {
        if (open && !order) postOrder({data: {}})
    }, [postOrder, open, order])

    useEffect(() => {
        if (data) setProduct(data.product)
    }, [data])

    useEffect(() => {
        if (postOrderData) {
            const {order, remainingTickets} = postOrderData
            setOrder(order)
            setRemainingTicketsAfterOrderUpdate(remainingTickets)
        }
    }, [postOrderData])

    useEffect(() => {
        if (putOrderData) {
            const {order, remainingTickets} = putOrderData
            setOrder(order)
            setRemainingTicketsAfterOrderUpdate(remainingTickets)
        }
    }, [putOrderData])

    useEffect(() => {
        if (paymentTypesData?.paymentTypes) setPaymentTypes(paymentTypesData?.paymentTypes)
    }, [paymentTypesData])

    useEffect(() => {
        if (testOrder) {
            setShowTestCards(paymentTypes.some(paymentType => paymentType.name === "netaxept"))
        } else {
            setShowTestCards(false)
        }
    }, [paymentTypes, testOrder])

    useEffect(() => {
        if (postOrderError) snackbar.showError(getErrorMessage(postOrderError.response, postOrderError.message, t))
    }, [postOrderError, snackbar, t])

    useEffect(() => {
        if (putOrderError) snackbar.showError(getErrorMessage(putOrderError.response, putOrderError.message, t))
    }, [putOrderError, snackbar, t])

    useEffect(() => {
        if (paymentTypes?.length === 1) {
            setRadioGroupPaymentTypeValue("0")
            setSelectedPaymentType(paymentTypes[0])
        }
    }, [paymentTypes])

    const handleExpandedChange = (id) => (event, isExpanded) => {
        setExpanded(isExpanded ? id : false)
    }

    const handleCustomerChange = (e) => {
        e.persist()
        setCustomer(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handlePaymentTypeSelect = ({target: {value}}) => {
        setRadioGroupPaymentTypeValue(value)
        setSelectedPaymentType(paymentTypes[parseInt(value)])
    }

    const handleEmpty = () => {
        setCustomer({...initialCustomer})
        setOrder(undefined)
        setDiscountCode(undefined)
    }

    const handleEmptyAndClose = () => {
        handleEmpty()
        handleClose()
    }

    const updateOrder = (orderLines) => {
        const _order = {
            ...order,
            customer: {...order?.customer, ...customer},
            paymentTypeId: selectedPaymentType?.id,
            discountCode: discountCode?.code,
            orderLines: orderLines,
        }

        putOrder({data: _order})
    }

    const handleDiscountCodeChange = (_discountCode) => {
        setDiscountCode(_discountCode)
        if (order?.orderLines?.length) putOrder({data: {...order, discountCode: _discountCode?.code}})
    }

    const handleSave = () => {
        putOrder({
            params: {register: true},
            data: {...order, customer: {...order?.customer, ...customer}, discountCode: discountCode?.code},
        })
            .then(({data}) => {
                const {redirectUrl, order} = data
                if (redirectUrl) {
                    handleEmpty()
                    window.location.href = redirectUrl
                } else {
                    processOrder({params: {orderId: order?.externalId}}).then(() => handleEmptyAndClose())
                }
            })
    }

    const getProductIds = () => {
        const jointIds = product?.jointProducts?.map(({id}) => id)

        return jointIds?.length ? [productId, ...jointIds] : [productId]
    }

    return loading
        ? <ProgressIndicator/>
        : <Dialog open={open} fullWidth maxWidth="lg" onClose={handleEmptyAndClose}>
            <DialogTitle style={{backgroundColor: testOrder ? testOrdersPalette?.light : null}}>
                Lag {testOrder ? "test" : ""}ordre
            </DialogTitle>
            <DialogContent style={{backgroundColor: testOrder ? testOrdersPalette?.light : null}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        {!testOrder && (
                            <Alert severity="info"
                                   style={{marginBottom: 8}}>
                                Vær oppmerksom på at dette ikke er en testordre.
                            </Alert>
                        )}
                        <CreateOrderDialogCustomerDetails style={{marginBottom: 8}}
                                                          customer={customer}
                                                          handleCustomerChange={handleCustomerChange}/>
                        <CreateOrderDialogProductAccordion product={product}
                                                           orderLines={order?.orderLines}
                                                           expanded={expanded}
                                                           remainingTicketsAfterOrderUpdate={remainingTicketsAfterOrderUpdate}
                                                           handleExpandedChange={handleExpandedChange}
                                                           loading={putLoading}
                                                           updateOrder={updateOrder}/>
                        {product?.jointProducts?.map(jointProduct => (
                                <CreateOrderDialogProductAccordion key={jointProduct.id}
                                                                   product={jointProduct}
                                                                   orderLines={order?.orderLines}
                                                                   loading={putLoading}
                                                                   remainingTicketsAfterOrderUpdate={remainingTicketsAfterOrderUpdate}
                                                                   expanded={expanded}
                                                                   handleExpandedChange={handleExpandedChange}
                                                                   updateOrder={updateOrder}/>
                            ),
                        )}
                        <CreateOrderDialogDiscountCode discountCode={discountCode}
                                                       productIds={getProductIds()}
                                                       handleDiscountCodeChange={handleDiscountCodeChange}
                                                       style={{marginBottom: 8, marginTop: 8}}/>
                        <CreateOrderDialogPaymentTypes paymentTypes={paymentTypes}
                                                       loading={paymentTypesLoading}
                                                       handlePaymentTypeSelect={handlePaymentTypeSelect}
                                                       radioGroupPaymentTypeValue={radioGroupPaymentTypeValue}
                                                       showTestCards={showTestCards}/>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <CreateOrderDialogSummary order={order}
                                                  product={product}
                                                  discountCode={discountCode}
                                                  jointProducts={product?.jointProducts}/>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={{backgroundColor: testOrder ? testOrdersPalette?.light : null}}>
                <Button onClick={handleEmptyAndClose} color="primary"> {t("cancel")} </Button>
                <Tooltip title={!customer?.email ? t("emailMissing") : ""}>
                       <span>
                           <Button onClick={handleSave} color="primary" disabled={!customer?.email}>{t("save")}</Button>
                       </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
}

export default CreateOrderDialog
