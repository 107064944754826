import React, { useCallback } from "react";
import { debounce, InputBase, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  input: {
    background: 'white',
    height: '2.1rem',
    borderRadius: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  filterName: {
    marginRight: theme.spacing(2),
    alignSelf: 'center',
    fontWeight: 'bold'
  }
}))

const DebouncedTextFilter = ({filterName, onChange, debounceTime}) => {
  const classes = useStyles()

  const debounceOnChangedNameFilter = useCallback(
    debounce(onChange, debounceTime)
    , []);
  return (
    <>
      <Typography  className={classes.filterName} color={"primary"}>{filterName}</Typography>
      <InputBase id="freetext-searchfield" className={classes.input}
                 onChange={debounceOnChangedNameFilter}
      />
    </>
  )
}

export default DebouncedTextFilter
