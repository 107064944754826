import React from "react"
import {AccordionSummary, Avatar, CardHeader, Grid, Tooltip} from "@mui/material"
import ChipWithTooltip from "../components/ChipWithTooltip"
import {deepOrange} from "@mui/material/colors"
import ProductTypeIcon from "../components/ProductTypeIcon"
import {useBaseTranslation} from "../baseTranslationContext"
import {ExpandMore} from "@mui/icons-material"

const CreateOrderDialogProductAccordionSummary = ({
                                                      name,
                                                      museumName,
                                                      type,
                                                      jointProductsDiscountName,
                                                  }) => {
    const t = useBaseTranslation()

    return <AccordionSummary expandIcon={<ExpandMore/>}>
        <CardHeader title={<strong>{name}</strong>}
                    subheader={
                        <Grid container
                              spacing={1}
                              alignItems="center">
                            <Grid item>
                                <ChipWithTooltip label={museumName}
                                                 maxLength={20}
                                                 size="small"
                                                 color="default"/>
                            </Grid>
                            {jointProductsDiscountName && (
                                <ChipWithTooltip label={jointProductsDiscountName}
                                                 maxLength={16}
                                                 size="small"
                                                 color="primary"/>
                            )}
                        </Grid>
                    }
                    avatar={
                        <Avatar style={{backgroundColor: deepOrange[300]}}>
                            <Tooltip title={t(type)}>
                                <div>
                                    <ProductTypeIcon type={type}/>
                                </div>
                            </Tooltip>
                        </Avatar>
                    }/>
    </AccordionSummary>

}

export default CreateOrderDialogProductAccordionSummary