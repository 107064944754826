import React from "react"
import {Grid, IconButton, TextField, Tooltip} from "@mui/material"
import _ from "lodash/fp"
import {Cancel} from "@mui/icons-material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const TermsOfSaleUrlInputField = ({initialValue, value, onChange, onCancel, label, helperText, disabled}) => {
    const t = useBaseTranslation()
    return <><Grid container
            item
            xs={12}>
        <Grid item
              xs>
            <TextField variant="outlined"
                       size="small"
                       fullWidth
                       value={value || ""}
                       onChange={onChange}
                       disabled={disabled}
                       helperText={helperText}
                       label={label}/>
        </Grid>
        <Grid item>
            <Tooltip title={t("cancel")}>
                        <span>
                            <IconButton disabled={_.isEqual(initialValue, value)} onClick={onCancel} size="large">
                                <Cancel/>
                            </IconButton>
                        </span>
            </Tooltip>
        </Grid>
    </Grid>
    </>;
}

export default TermsOfSaleUrlInputField
