import React, {useEffect, useState} from "react"
import {Box, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip} from "@mui/material"
import _ from "lodash/fp"
import SaveIcon from "@mui/icons-material/Save"
import {Cancel} from "@mui/icons-material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import Typography from "@mui/material/Typography";

/**
 *
 * @param museumSettings { Partial<MuseumSettings> }
 * @param handleSaveMailchimpSettings { () }
 * @param isUmbrellaFields
 * @param readonly
 * @return {JSX.Element}
 * @constructor
 */
const NewsletterInputFields = ({museumSettings, handleSaveMailchimpSettings, isUmbrellaFields, readonly}) => {
    const t = useBaseTranslation()

    const [newsletterProvider, setNewsletterProvider] = useState("")
    const [mcAudienceId, setMcAudienceId] = useState("")
    const [initAudienceId, setInitAudienceId] = useState("")
    const [mcApiKey, setMcApiKey] = useState("")
    const [initApiKey, setInitApiKey] = useState("")
    const [makeApiKey, setMakeApiKey] = useState("")
    const [initMakeApiKey, setInitMakeApiKey] = useState("")
    const [makeClientId, setMakeClientId] = useState("")
    const [initMakeClientId, setInitMakeClientId] = useState("")
    const [makeListId, setMakeListId] = useState("")
    const [initMakeListId, setInitMakeListId] = useState("")

    useEffect(() => {
        if (museumSettings) {
            setMcApiKey(museumSettings?.mcApiKey)
            setInitApiKey(museumSettings?.mcApiKey)
            setMcAudienceId(museumSettings?.mcAudienceId)
            setInitAudienceId(museumSettings?.mcAudienceId)
            setMakeClientId(museumSettings?.makeClientId)
            setInitMakeClientId(museumSettings?.makeClientId)
            setMakeApiKey(museumSettings?.makeApiKey)
            setInitMakeApiKey(museumSettings?.makeApiKey)
            setMakeListId(museumSettings?.makeListId)
            setInitMakeListId(museumSettings?.makeListId)
            setNewsletterProvider(museumSettings?.newsletterProvider)
        }
    }, [museumSettings])

    useEffect(() => {
        if (readonly) {
            setMcApiKey("")
            setMcAudienceId("")
            setMakeClientId("")
            setMakeApiKey("")
            setMakeListId("")
            setNewsletterProvider("")
        }
    }, [readonly])

    return (
        <Grid container
              spacing={3}
              direction="column">
            <Grid container
                  item
                  spacing={1}
                  xs={12}>

                <Grid item xs={12}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        marginBottom: 1,
                    }}>
                        <Typography variant='caption'>{t('newsletterProviderLabel')}</Typography>
                        <RadioGroup disabled={readonly} value={newsletterProvider || "mailchimp"} onChange={(e) => setNewsletterProvider(e.target.value)} row>
                            <FormControlLabel disabled={readonly} value="mailchimp" control={<Radio color={"primary"} />} label={'Mailchimp'} />
                            <FormControlLabel disabled={readonly} value="make" control={<Radio color={"primary"} />} label={'Make'} />
                        </RadioGroup>
                    </Box>
                </Grid>

                {!newsletterProvider || newsletterProvider === "mailchimp" ? (
                    <>
                        <Grid item
                              xs>
                            <TextField variant="outlined"
                                       size="small"
                                       fullWidth
                                       value={mcAudienceId || ""}
                                       disabled={readonly}
                                       onChange={e => setMcAudienceId(e.target.value)}
                                       helperText={isUmbrellaFields ?
                                           t("termsOfSaleCommonUrlHelperText") :
                                           t("mailchimpMuseumHelperText")}
                                       label={`Audience id`}/>
                        </Grid>

                        <Grid item
                              xs>
                            <TextField variant="outlined"
                                       size="small"
                                       fullWidth
                                       value={mcApiKey || ""}
                                       disabled={readonly}
                                       onChange={e => setMcApiKey(e.target.value)}
                                       label={`Api key`}/>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item
                              xs>
                            <TextField variant="outlined"
                                       size="small"
                                       fullWidth
                                       value={makeClientId || ""}
                                       disabled={readonly}
                                       onChange={e => setMakeClientId(e.target.value)}
                                       helperText={isUmbrellaFields ?
                                           t("termsOfSaleCommonUrlHelperText") :
                                           t("mailchimpMuseumHelperText")}
                                       label={`BrukerID`}/>
                        </Grid>

                        <Grid item
                              xs>
                            <TextField variant="outlined"
                                       size="small"
                                       fullWidth
                                       value={makeApiKey || ""}
                                       disabled={readonly}
                                       onChange={e => setMakeApiKey(e.target.value)}
                                       label={`Api key`}/>
                        </Grid>

                        <Grid item
                              xs>
                            <TextField variant="outlined"
                                       size="small"
                                       fullWidth
                                       value={makeListId || ""}
                                       disabled={readonly}
                                       onChange={e => setMakeListId(e.target.value)}
                                       helperText={<Typography variant='caption'>Liste-ID finner du i URL-en når du går inn på en liste på Make</Typography>}
                                       label={`Liste-ID`}/>
                        </Grid>
                    </>
                )}

                <Grid item>
                    <Tooltip title={t("cancel")}>
                            <span>
                                <IconButton
                                    disabled={(_.isEqual(initAudienceId, mcAudienceId) && _.isEqual(initApiKey,
                                        mcApiKey) && _.isEqual(initMakeClientId, makeClientId) && _.isEqual(initMakeApiKey, makeApiKey) && _.isEqual(initMakeListId, makeListId)) || readonly}
                                    onClick={() => {
                                        setMcAudienceId(initAudienceId)
                                        setMcApiKey(initApiKey)
                                        setMakeClientId(initMakeClientId)
                                        setMakeApiKey(initMakeApiKey)
                                        setMakeListId(initMakeListId)
                                    }}
                                    size="large">
                                    <Cancel/>
                                </IconButton>
                            </span>
                    </Tooltip>
                    <Tooltip title={t("save")}>
                            <span>
                                <IconButton
                                    disabled={(_.isEqual(initAudienceId, mcAudienceId) && _.isEqual(initApiKey,
                                        mcApiKey) && _.isEqual(initMakeClientId, makeClientId) && _.isEqual(initMakeApiKey, makeApiKey) && _.isEqual(initMakeListId, makeListId)) && readonly}
                                    onClick={() => handleSaveMailchimpSettings({
                                        mcAudienceId,
                                        mcApiKey,
                                        isUmbrellaFields,
                                        makeClientId,
                                        makeApiKey,
                                        makeListId,
                                        newsletterProvider,
                                    })}
                                    size="large">
                                    <SaveIcon/>
                                </IconButton>
                            </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default NewsletterInputFields
