import React, {useEffect, useState} from "react"
import "./style.css"
import { InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

const MuiDatePicker = ({
                           selected,
                           label,
                           name,
                           onChange,
                           fullWidth = true,
                           size = "medium",
                           variant = "inline",
                           inputVariant = "filled",
                           margin = "normal",
                           minDate = null,
                           maxDate = null,
                           helperText = "",
                           disabled = false,
                           isClearable = false,
                           onClear = null,
                           error = false,
                           required = false,
                       }) => {
    const [minMaxDateProps, setMinMaxDateProps] = useState({})

    useEffect(() => {
        if (minDate) setMinMaxDateProps({minDate: minDate})
        if (maxDate) setMinMaxDateProps({maxDate: maxDate})
    }, [minDate, maxDate])

    return (
        <DatePicker value={selected}
                    autoOk
                    format="dd.MM.yyyy"
                    variant={variant}
                    inputVariant={inputVariant}
                    label={label}
                    {...minMaxDateProps}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    required={required}
                    helperText={helperText}
                    size={size}
                    margin={margin}
                    renderInput={(props) => <TextField {...props} variant={inputVariant} fullWidth={fullWidth} margin={margin}/>}
                    error={error}
                    InputProps={{
                        endAdornment: selected && isClearable && onClear ? (
                            <InputAdornment position="end">
                                <IconButton size="small"
                                            onClick={(event) => onClear(event)}>
                                    <Clear/>
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                    onChange={date => onChange(date, name)}/>
    )
}

export default MuiDatePicker
