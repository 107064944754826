import React, {useEffect, useState} from "react"
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import Button from "@mui/material/Button"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {useAxiosBpn} from "../../../app/axios"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const initialData = {
    merchantId: "",
    museumId: 0,
    museumName: "",
    test: false,
    token: "",
}

const NetaxeptDialog = ({paymentType, open, handleClose}) => {
    const t = useBaseTranslation()
    const {umbrellaId, umbrellaName} = useMuseumSelectorState()
    const [data, setData] = useState({...initialData})
    const snackbar = useCustomSnackbar()

    const [{error: postError}, postPaymentTypeNetaxept] = useAxiosBpn({
        url: "payment_types/netaxept",
        method: "POST",
    }, {manual: true})

    useEffect(() => {
        setData(prev => ({...prev, museumId: umbrellaId, museumName: umbrellaName}))
    }, [umbrellaId, umbrellaName])

    useEffect(() => {
        if (paymentType) {
            setData(prev => ({
                    ...prev,
                    test: paymentType.test,
                    merchantId: paymentType.merchantId,
                    token: paymentType.token,
                }),
            )
        }
    }, [paymentType])

    useEffect(() => {
        if (postError) {
            snackbar.showError(t(postError.message))
        }
    }, [postError, snackbar, t])

    const handleChange = event => {
        event.persist()
        setData(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    const handleEmptyAndClose = () => {
        setData({...initialData})
        handleClose()
    }

    const handleSave = () => {
        postPaymentTypeNetaxept({data: data}).then(() => {
            snackbar.showSuccess(`${t("paymentType")} Netaxept ${paymentType.id ? t("edited") : t("added")
                .toLowerCase()}`)
            handleEmptyAndClose()
        })
    }

    return (
        <Dialog open={open}
                onClose={handleEmptyAndClose}
                fullWidth
                maxWidth="sm">
            <DialogTitle>
                {`${paymentType && paymentType.id
                    ? t("edit")
                    : t("add")} ${t("netaxept")}-${t("merchant").toLowerCase()}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {paymentType.test ? t("testEnvironment") : t("productionEnvironment")}
                </DialogContentText>
                <Grid container
                      spacing={1}
                      direction="column">
                    <Grid item>
                        <TextField name="merchantId"
                                   label={t("merchantId")}
                                   type="number"
                                   fullWidth
                                   value={data.merchantId || ""}
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item>
                        <TextField name="token"
                                   label={t("token")}
                                   fullWidth
                                   value={data.token || ""}
                                   onChange={handleChange}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleEmptyAndClose}
                        color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={handleSave}
                        color="primary">
                    {t("save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NetaxeptDialog