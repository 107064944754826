import React, {useEffect, useState} from "react"
import {Card, CardContent, CardHeader, Chip, Grid, Tooltip} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {GridLabel, GridValue} from "./gridComponents"
import {useAxiosBpn} from "../axios"
import useCustomSnackbar from "../components/useCustomSnackbar"
import {ButtonWithProgress} from "../components/ButtonWithProgress"
import {Help, InfoOutlined, RotateRight} from "@mui/icons-material"
import OrderDetailsDialogQueryDetails from "./OrderDetailsDialogQueryDetails"

const OrderDetailsDialogPaymentDetails = ({order, paymentType, loading, orderProcessLoading, processOrder}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()
    const [paymentTypeGridRows, setPaymentTypeGridRows] = useState([])
    const [netaxeptGridRows, setNetaxeptGridRows] = useState([])
    const [queryResponse, setQueryResponse] = useState()

    const [
        {
            data: queryData,
            loading: queryLoading,
            error: queryError,
        }, queryOrder,
    ] = useAxiosBpn({url: `orders/${order?.transactionId}/query`}, {manual: true})

    useEffect(() => {
        if (paymentType && order) {
            setPaymentTypeGridRows([
                {label: t("orderStatus"), value: t(order.status)},
                {label: t("paymentType"), value: t(paymentType.name)},
                {label: t("connectedTo"), value: paymentType.museumName},
            ])
        }
    }, [order, paymentType, t])

    useEffect(() => {
        if (order) {
            const {transactionId, paymentSubtype, walletIssuer, timesProcessed} = order
            setNetaxeptGridRows([
                {label: t("transactionId"), value: transactionId},
                {label: t("paymentCard"), value: t(paymentSubtype)},
                {label: t("3rdPartySolution"), value: t(walletIssuer)},
                {
                    label: t('processedWithNetaxept'),
                    value: timesProcessed
                        ? <div style={{display: "flex", alignItems: "center"}}>
                            {timesProcessed} gang{timesProcessed ? timesProcessed !== 1 ? "er" : "" : ""}
                            <Tooltip title={t('processedWithNetaxeptHelperText')}>
                                <InfoOutlined color="action" fontSize="small" style={{marginLeft: 8}}/>
                            </Tooltip>
                        </div>
                        : null
                },
                {
                    label: t("orderProcessing"),
                    value: (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <ButtonWithProgress label={t("processAFresh")}
                                                startIcon={<RotateRight/>}
                                                disabled={order.lockedByGlobalAdmin}
                                                loading={orderProcessLoading}
                                                onClick={processOrder}
                                                variant="text"
                                                color="primary"
                                                size="small"/>
                        </div>
                    ),
                },
                {
                    label: t("netaxeptQuery"),
                    value: transactionId
                        ? <div style={{display: "flex", alignItems: "center"}}>
                            <ButtonWithProgress label={t("runQuery")}
                                                startIcon={<Help/>}
                                                loading={queryLoading}
                                                onClick={queryOrder}
                                                disabled={order.lockedByGlobalAdmin}
                                                variant="text"
                                                color="primary"
                                                size="small"/>
                        </div>
                        : null,
                },
            ])
        }
    }, [order, orderProcessLoading, processOrder, queryLoading, queryOrder, t])

    useEffect(() => {
        setQueryResponse(queryData?.queryResponse)
    }, [queryData])

    useEffect(() => {
        if (queryData) {
            snackbar.showInfo(t("orderQueried"))
        }
    }, [queryData, snackbar, t])

    useEffect(() => {
        if (queryError) {
            snackbar.showError(queryError.message)
        }
    }, [queryError, snackbar])

    function getTitle() {
        const {paymentTypeName} = order

        return (
            <Grid container
                  spacing={1}>
                <Grid item>
                    {t("payment")}
                </Grid>
                <Grid item>
                    <Chip label={t(paymentTypeName)}
                          color="primary"
                          size="small"/>
                </Grid>
            </Grid>)
    }

    return loading
        ? <ProgressIndicator/>
        : <Card square
                variant="outlined">
            <CardHeader title={getTitle()}
                        titleTypographyProps={{color: "primary"}}
            />
            <CardContent>
                <Grid container
                      direction="column">
                    {paymentTypeGridRows?.map(({label, value}, index) =>
                        <Grid container
                              key={index}
                              alignItems="center">
                            <GridLabel>{label}:</GridLabel>
                            <GridValue>{value}</GridValue>
                        </Grid>,
                    )}
                    {netaxeptGridRows?.map(({label, value}, index) =>
                        value &&
                        <Grid container
                              key={index}
                              alignItems="center">
                            <GridLabel>{label && `${label}:`}</GridLabel>
                            <GridValue>{value}</GridValue>
                        </Grid>,
                    )}
                    {queryResponse && <OrderDetailsDialogQueryDetails queryResponse={queryResponse}
                                                                      handleClose={() => setQueryResponse()}/>}
                </Grid>
            </CardContent>
        </Card>
}

export default OrderDetailsDialogPaymentDetails
