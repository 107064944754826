import React, {useEffect, useState} from "react"
import {Button, FormControlLabel, Grid, Switch} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import _ from "lodash/fp"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {lowerFirst} from "lodash"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import SaveButtonWithProgressAndLabel from "../../../app/components/SaveButtonWithProgressAndLabel"
import makeStyles from '@mui/styles/makeStyles';
import {Delete} from "@mui/icons-material"
import {defaultPortalRegistrationSettings} from "../UserPortalSettings"

const useStyles = makeStyles((theme) => ({
    inputField: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}))

const UserPortalRegistrationSettings = ({registrationSettings, getMuseumSettings}) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const {selectedMuseum, umbrellaName} = useMuseumSelectorState()
    const [portalRegistrationSettings, setPortalRegistrationSettings] = useState(defaultPortalRegistrationSettings)
    const snackbar = useCustomSnackbar()

    const [
        {
            data: portalRegistrationSettingsPutData,
            loading: portalRegistrationSettingsPutLoading,
            error: portalRegistrationSettingsPutError,
        }, putPortalRegistrationSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    const [
        {loading: portalRegistrationSettingsDeleteLoading}, deletePortalRegistrationSettings,
    ] = useAxiosBpn({method: "DELETE"}, {manual: true})

    useEffect(() => {
        if (portalRegistrationSettingsPutData) {
            snackbar.showSuccess(`Kjøpsportal innstillinger for ${selectedMuseum?.name} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
    }, [selectedMuseum, portalRegistrationSettingsPutData, t, umbrellaName, snackbar, getMuseumSettings])

    useEffect(() => {
        if (registrationSettings) {
            setPortalRegistrationSettings(registrationSettings)
        } else {
            setPortalRegistrationSettings(defaultPortalRegistrationSettings)
        }
    }, [registrationSettings])

    useEffect(() => {
        if (portalRegistrationSettingsPutError) {
            snackbar.showError(t(portalRegistrationSettingsPutError.message))
        }
    }, [portalRegistrationSettingsPutError, t, snackbar])

    const handleMuseumUrlSave = () => {
        const id = portalRegistrationSettings?.museumId ? portalRegistrationSettings.museumId : selectedMuseum?.id
        putPortalRegistrationSettings({
            url: `portal_registration_settings/${id}`,
            data: {
                collectZipCodes: portalRegistrationSettings.collectZipCodes,
            },
        })
    }

    const handleDelete = () => {
        const id = portalRegistrationSettings?.museumId ? portalRegistrationSettings.museumId : selectedMuseum?.id
        deletePortalRegistrationSettings({
            url: `portal_registration_settings/${id}/delete`,
        })
        setPortalRegistrationSettings(defaultPortalRegistrationSettings)
    }

    const handleSwitchChange = (e) => {
        setPortalRegistrationSettings(prev => ({...prev, [e.target.name]: e.target.checked}))
    }

    return (
        <>
            <Grid container
                  spacing={3}>
                <Grid container
                      item
                      xs={12}
                      direction="column"
                      spacing={2}>
                    <Grid item xs>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={portalRegistrationSettings?.collectZipCodes || false}
                                    onChange={handleSwitchChange}
                                    name="collectZipCodes"
                                    color="primary"
                                />
                            }
                            label={t("collectZipCodes")}
                            classes={{label: classes.switchLabel}}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button onClick={handleDelete}
                                disabled={portalRegistrationSettingsDeleteLoading}
                                startIcon={<Delete/>}>
                            {t("delete")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <SaveButtonWithProgressAndLabel unsavedChanges={!_.isEqual(portalRegistrationSettings,
                            registrationSettings)}
                                                        handleSave={handleMuseumUrlSave}
                                                        loading={portalRegistrationSettingsPutLoading}/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default UserPortalRegistrationSettings
