import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from "@mui/material"
import Grid from "@mui/material/Grid"
import MuiDatePicker from "../../components/MuiDatePicker"
import RepeatIcon from "@mui/icons-material/Repeat"
import TextField from "@mui/material/TextField"
import {RECURRING_OPTION_MONTH, RECURRING_OPTION_WEEK, RECURRING_OPTIONS} from "../../baseContext"
import Button from "@mui/material/Button"
import React, {useEffect, useState} from "react"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
    extractPattern,
    getDayOfMonth,
    getDayOfWeek,
    getDefaultPattern,
    getRecurringPatternDescription,
    getRecurringType
} from "../../helper-functions/scheduleEventHelper"
import _ from "lodash"
import {dateFormat} from "../../helper-functions/dateFormat"
import {SubdirectoryArrowLeft, SubdirectoryArrowRight} from "@mui/icons-material"

const useStyles = makeStyles(theme => ({
    label: {
        paddingBottom: theme.spacing(1),
    },
    weekdayButton: {
        fontSize: "0.9em",
        width: theme.spacing(5),
        background: "#e9e9e9",
        fontWeight: "bolder",
        margin: "4px",
        "&:hover": {
            backgroundColor: "#dedede",
        },
    },
}))

const MONTHLY_OPTION_BY_DATE = "monthly_option_by_date"
const MONTHLY_OPTION_BY_WEEKDAY = "monthly_option_by_weekday"

const ScheduleEventRecurringPatternDialog = ({
                                                 event,
                                                 recurringPatternDialogOpen,
                                                 recurringPattern,
                                                 recurringOptionSelected,
                                                 startDate,
                                                 endDate,
                                                 handleRecurringPatternChange,
                                             }) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const weekDays = [t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday"), t("sunday")]

    const [dialogOpen, setDialogOpen] = useState(false)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [pattern, setPattern] = useState(getDefaultPattern)
    const [occurrencesLimited, setOccurrencesLimited] = useState(false)
    const [optionSelected, setOptionSelected] = useState(RECURRING_OPTION_WEEK)
    const [monthlyOptionSelected, setMonthlyOptionSelected] = useState(MONTHLY_OPTION_BY_DATE)
    const [showWeekDaySelector, setShowWeekDaySelector] = useState(false)
    const [occurrence, setOccurrence] = useState()
    const [_event, setEvent] = useState()

    useEffect(() => {
        setEvent({...event, scheduleEventRecurringPattern: pattern})
    }, [event, pattern])

    useEffect(() => setOccurrence(pattern?.occurrence), [pattern])

    useEffect(() => {
        setPattern(prev => ({
            ...prev,
            dayOfWeek: monthlyOptionSelected === MONTHLY_OPTION_BY_WEEKDAY ? prev?.dayOfWeek?.length ? prev.dayOfWeek : getDayOfWeek(event) : [],
            occurrence: monthlyOptionSelected === MONTHLY_OPTION_BY_WEEKDAY ? prev?.occurrence || 1 : null,
            dayOfMonth: monthlyOptionSelected === MONTHLY_OPTION_BY_DATE ? prev?.dayOfMonth || getDayOfMonth(event) : null,
        }))
    }, [monthlyOptionSelected, event])

    useEffect(() => {
        setShowWeekDaySelector(optionSelected === RECURRING_OPTION_WEEK || (optionSelected === RECURRING_OPTION_MONTH && monthlyOptionSelected === MONTHLY_OPTION_BY_WEEKDAY))
    }, [optionSelected, monthlyOptionSelected])

    useEffect(() => {
        if (recurringOptionSelected === RECURRING_OPTION_MONTH && recurringPattern?.dayOfWeek) setMonthlyOptionSelected(MONTHLY_OPTION_BY_WEEKDAY)
        setDialogOpen(recurringPatternDialogOpen)
        setOccurrence(recurringPattern?.occurrence)
        setOccurrencesLimited(recurringPattern?.maxNumberOfOccurrences > 0)
        setPattern(recurringPattern)
        setOptionSelected(recurringOptionSelected)
        setStart(startDate)
        setEnd(endDate)
    }, [recurringPatternDialogOpen, recurringPattern, recurringOptionSelected, startDate, endDate])

    const handleOccurrencesLimitedChange = () => {
        setPattern(prev => ({
            ...prev,
            maxNumberOfOccurrences: occurrencesLimited
                ? 0
                : recurringPattern?.maxNumberOfOccurrences
                    ? recurringPattern?.maxNumberOfOccurrences
                    : 10,
        }))
        setOccurrencesLimited(!occurrencesLimited)
    }

    const handleOccurrenceChange = (newValue) => {
        setOccurrence(newValue)
        setPattern(prev => ({...prev, occurrence: newValue}))
    }

    const handlePatternChange = e => {
        e.persist()
        setPattern(prev => ({
            ...prev,
            [e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value,
        }))
    }

    const handleOptionSelectedChange = option => {
        setOptionSelected(option)
        setPattern(prev => ({...prev, type: getRecurringType(option)}))
    }

    const handleDayOfWeekChange = index => {
        let dayOfWeek = pattern.dayOfWeek
        let newDayOfWeek = _.xor(dayOfWeek, [index])
        setPattern(prev => ({...prev, dayOfWeek: newDayOfWeek}))
    }

    const handleDialogClose = () => handleRecurringPatternChange(pattern, !dialogOpen, start, end)

    const handleEndDateClear = (event) => {
        event.stopPropagation()
        setEnd(null)
    }

    return (
        <Dialog open={dialogOpen}
                onClose={handleDialogClose}>
            <DialogTitle>{t("repeatingCalendarEvent")}</DialogTitle>
            <DialogContent>
                <div style={{maxWidth: 450}}>
                    <Typography variant="caption" color="textSecondary">
                        {getRecurringPatternDescription(t, extractPattern(_event), start, end)}
                    </Typography>
                </div>
                <Grid container direction="column" spacing={1}>
                    <Grid container
                          item
                          direction="row"
                          wrap="nowrap"
                          justifyContent="space-between"
                          spacing={1}
                          alignContent="stretch"
                          alignItems="flex-end">
                        <Grid item>
                            <Typography className={classes.label}
                                        variant="body2">
                                {t("validFrom")}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <MuiDatePicker selected={start}
                                           size="small"
                                           inputVariant="standard"
                                           onChange={date => setStart(date)}/>
                        </Grid>
                    </Grid>
                    <Grid container
                          item
                          direction="row"
                          wrap="nowrap"
                          spacing={1}
                          alignContent="stretch"
                          alignItems="flex-end">
                        <Grid item>
                            <RepeatIcon/>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.label}
                                        variant="body2">
                                {t("repeatEvery")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField name="separationCount"
                                       variant="standard"
                                       size="small"
                                       type="number"
                                       margin="dense"
                                       onChange={handlePatternChange}
                                       value={pattern.separationCount || 1}/>
                        </Grid>
                        <Grid item>
                            <TextField name="recurringOption"
                                       variant="standard"
                                       size="small"
                                       margin="dense"
                                       select
                                       value={optionSelected || ""}>
                                {RECURRING_OPTIONS.map((option, index) => (
                                    <MenuItem key={index}
                                              value={option}
                                              onClick={() => handleOptionSelectedChange(option)}>
                                        {t(option)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    {optionSelected === RECURRING_OPTION_MONTH && (
                        <Grid item>
                            <FormControl style={{marginTop: 8, marginBottom: 8}}>
                                <RadioGroup onChange={({target: {value}}) => setMonthlyOptionSelected(value)}
                                            value={monthlyOptionSelected}>
                                    <FormControlLabel value={MONTHLY_OPTION_BY_DATE}
                                                      control={<Radio/>}
                                                      label={`På dato (${dateFormat(new Date(start), "do")})`}/>
                                    <FormControlLabel value={MONTHLY_OPTION_BY_WEEKDAY}
                                                      control={<Radio/>}
                                                      label="På ukedag"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}
                    {optionSelected === RECURRING_OPTION_MONTH && monthlyOptionSelected === MONTHLY_OPTION_BY_WEEKDAY && (
                        <Grid item style={{display: "flex", alignItems: "center"}}>
                            Gjenta den
                            <TextField style={{marginLeft: 4}}
                                       variant="standard"
                                       size="small"
                                       margin="dense"
                                       select
                                       value={occurrence || ""}>
                                <MenuItem value={1} onClick={() => handleOccurrenceChange(1)}>første</MenuItem>
                                <MenuItem value={2} onClick={() => handleOccurrenceChange(2)}>andre</MenuItem>
                                <MenuItem value={3} onClick={() => handleOccurrenceChange(3)}>tredje</MenuItem>
                                <MenuItem value={4} onClick={() => handleOccurrenceChange(4)}>fjerde</MenuItem>
                                <MenuItem value={5} onClick={() => handleOccurrenceChange(5)}>femte</MenuItem>
                                <MenuItem value={-1} onClick={() => handleOccurrenceChange(-1)}>siste</MenuItem>
                            </TextField>
                            <SubdirectoryArrowLeft color="disabled" style={{transform: "rotate(270deg)"}}/>
                        </Grid>
                    )}
                    {showWeekDaySelector && (
                        <Grid item>
                            <div style={{marginTop: 16, marginBottom: 16}}>
                                {optionSelected === RECURRING_OPTION_WEEK && (
                                    <Typography className={classes.label} variant="body2"> {t("repeatOn")} </Typography>
                                )}
                                {weekDays.map((day, index) => (
                                    <IconButton
                                        key={index}
                                        onClick={() => handleDayOfWeekChange(index)}
                                        style={pattern.dayOfWeek && pattern.dayOfWeek.includes(index) ?
                                            {
                                                background: theme.palette.primary.main,
                                                color: "white",
                                            } : {
                                                background: "#e9e9e9",
                                            }
                                        }
                                        className={classes.weekdayButton}
                                        size="large">
                                        {day.substring(0, 2)}
                                    </IconButton>
                                ))}
                            </div>
                        </Grid>
                    )}
                    {optionSelected === RECURRING_OPTION_MONTH && monthlyOptionSelected === MONTHLY_OPTION_BY_WEEKDAY && (
                        <Grid item
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  marginBottom: 8
                              }}>
                            <SubdirectoryArrowRight color="disabled" style={{marginRight: 4}}/> i måneden
                        </Grid>
                    )}
                    <Grid item>
                        <Typography>{t("ends")}</Typography>
                    </Grid>
                    <Grid container
                          item
                          direction="row"
                          wrap="nowrap"
                          spacing={1}
                          alignContent="stretch"
                          alignItems="flex-end">
                        <Grid item>
                            <Switch checked={occurrencesLimited}
                                    className={classes.label}
                                    size="small"
                                    onChange={handleOccurrencesLimitedChange}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2"
                                        style={occurrencesLimited ? {} : {color: "lightgray"}}>
                                {t("after").toLowerCase()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField name="maxNumberOfOccurrences"
                                       variant="standard"
                                       size="small"
                                       type="number"
                                       disabled={!occurrencesLimited}
                                       margin="dense"
                                       onChange={handlePatternChange}
                                       value={pattern.maxNumberOfOccurrences || ""}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2"
                                        style={occurrencesLimited ? {} : {color: "lightgray"}}>
                                {" " + t("occurrences").toLowerCase()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container
                          item
                          direction="row"
                          wrap="nowrap"
                          spacing={1}
                          justifyContent="space-between"
                          alignContent="stretch"
                          alignItems="flex-end">
                        <Grid item>
                            <Typography className={classes.label}
                                        variant="body2">
                                {t("andOrAtTheLatest")}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <MuiDatePicker selected={end}
                                           size="small"
                                           minDate={start}
                                           inputVariant="standard"
                                           isClearable={true}
                                           onClear={handleEndDateClear}
                                           onChange={date => setEnd(date)}/>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary"
                        onClick={handleDialogClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(ScheduleEventRecurringPatternDialog)