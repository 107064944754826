import { CardActions } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import Button from "@mui/material/Button";
import { useBaseTranslation } from "../../../app/baseTranslationContext";

const useStyles = makeStyles(() => ({
    button: {
        marginLeft: "auto",
    },
}))

const DiscountCodes = ({children, handleNewDiscountCode}) => {
    const classes = useStyles()
    const t = useBaseTranslation()

    return (
      <>
          {children}
          <CardActions>
              <Button variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={handleNewDiscountCode}
                      endIcon={<AddIcon/>}>
                  {t("add")}
              </Button>
          </CardActions>
      </>
    )
}

export default DiscountCodes