import React, {useEffect, useState} from "react"
import {Grid} from "@mui/material"
import {ProgressIndicator} from "../ProgressIndicator"
import {useBaseTranslation} from "../baseTranslationContext"
import DashboardCard from "../components/DashboardCard"
import {createVisitorsTableRows, createVisitorStatsExcelDataset} from "../visitor-stats/visitorsHelperFunctions"
import GenericTable from "../generic-table/GenericTable"
import RevenueCardActions from "../revenue/RevenueCardActions"

const ProductVisitorStatsCard = ({productName, data, loading, testOrders}) => {
    const t = useBaseTranslation()

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [excelDataSet, setExcelDataSet] = useState()
    const [excelFileName, setExcelFileName] = useState()

    useEffect(() => {
        if (data) {
            const {pivotTable} = data

            if (pivotTable) {
                const {columns: _columns, data: _data} = pivotTable

                const headers = [
                    {
                        field: _columns[0],
                        headerName: t(_columns[0]),
                        align: "left",
                        flex: 2,
                    },
                    {
                        field: _columns[1],
                        headerName: t(_columns[1]),
                        flex: 1,
                    },
                ]
                setColumns(headers)

                const _rows = createVisitorsTableRows(_data, headers)
                setRows(_rows)
            } else {
                setColumns([])
                setRows([])
            }
        } else {
            setColumns([])
            setRows([])
        }
    }, [data, t])

    useEffect(() => {
        if (columns) {
            const dataSet = createVisitorStatsExcelDataset(columns, rows)
            setExcelDataSet(dataSet)
        }
    }, [rows, columns])

    useEffect(() => {
        setExcelFileName(`${t("visitorNumbers")} ${productName}`)
    }, [productName, t])

    return (
        <DashboardCard testOrders={testOrders}
                       title={t("visitorNumbers")}
                       subheader={t("prPriceCategory")}
                       avatar={"#"}
                       action={
                           <RevenueCardActions excelDataSet={excelDataSet}
                                               excelFileName={excelFileName}/>
                       }>
            {loading
             ? <ProgressIndicator/>
             : <Grid container
                     direction="column"
                     spacing={1}>
                 <Grid container>
                     <Grid item
                           xs={12}
                           style={{width: "100%"}}>
                         <GenericTable columns={columns}
                                       size="small"
                                       rows={rows}
                                       hideEmptyRows={false}
                                       totalsRow/>
                     </Grid>
                 </Grid>
             </Grid>
            }
        </DashboardCard>
    )
}

export default ProductVisitorStatsCard