import React from "react"
import HelpButton from "../../../app/components/HelpButton"
import {Typography} from "@mui/material"

const VismaVoucherFieldsSettingsHelperText = ({highlightColor}) => (
    <HelpButton>
        Feltene satt her blir brukt i eksportfilen til Visma, og kan defineres på fire nivåer:

        <br/>
        <br/>

        <Typography variant="button">
            STIFTELSE/SELSKAP > VAREGRUPPE > ENKELTMUSEUM > AVDELING
        </Typography>

        <br/>
        <br/>

        Verdiene satt på en <i>stiftelse/selskap</i> gjelder for alle bilag på alle museer, så lenge det ikke er satt
        verdier under <i>varegruppe</i>, <i>enkeltmuseum</i> eller <i>avdeling</i>.

        <br/>
        <br/>

        Verdier satt på <i>varegruppe</i> overstyrer verdier satt på <i>stiftelse/selskap</i>,
        verdier satt på <i>enkeltmuseum</i> overstyrer verdier satt på <i>varegruppe</i>, og
        verdier satt på <i>avdeling</i> overstyrer verdier satt på <i>enkeltmuseum</i>.

        <br/>
        <br/>

        <i>VÆR OPPMERKSOM PÅ AT DU MÅ VELGE MUSEUM FØR AVDELINGSVELGEREN SYNES!</i>

        <br/>
        <br/>
        <Typography variant="body2">
            Verdi i &nbsp;
            <span style={{backgroundColor: highlightColor}}>&nbsp;uthevet felt&nbsp;</span>&nbsp;
            er den gjeldende verdien basert på utvalget gjort i velgeren. <br/>
            <i>Vær oppmerksom på at endringer må lagres før fargeuthevingen oppdateres.</i>
        </Typography>

    </HelpButton>
)

export default VismaVoucherFieldsSettingsHelperText