import React, {useEffect, useState} from "react";
import {useMuseumSelectorState} from "../../museum-selector/museumSelectorContext";
import {useAxiosBpn} from "../../axios";
import {Checkbox, Icon, Input, ListItemIcon, ListItemText, MenuItem, Select} from "@mui/material";
import SelectedUniqueSellingPointChip from "./SelectedUniqueSellingPointChip";

const ProductUniqueSellingPoints = ({
                                        product,
                                        updateProductState,
                                        unsavedChanges,
                                        setUnsavedChanges,
                                        refetchUniqueSellingPoints
                                    }) => {
    const {umbrellaId} = useMuseumSelectorState()
    const [data, setData] = useState({})
    const [uniqueSellingPoints, setUniqueSellingPoints] = useState([])
    const [selectedUniqueSellingPoints, setSelectedUniqueSellingPoints] = useState([])
    const [{data: uniqueSellingPointsData}, getUniqueSellingPoints] = useAxiosBpn({
        url: `unique_selling_points/${umbrellaId}`,
    })

    const combineUniqueSellingPointLists = (list1, list2) => {
        return list1.concat(list2.filter((item) => !!!list1.find(i => i.title === item.title)));
    }

    useEffect(() => {
        if (product) {
            setData(product)
        }
    }, [product])

    useEffect(() => {
        if (uniqueSellingPoints && data.uniqueSellingPoints) {
            setSelectedUniqueSellingPoints(uniqueSellingPoints.filter((sellingPoint) =>
                data.uniqueSellingPoints.some((selectedSellingPoint) => sellingPoint.id === selectedSellingPoint.id || sellingPoint.id === selectedSellingPoint.id)
            ))
        }
    }, [uniqueSellingPoints, data])

    useEffect(() => {
        let tempUniqueSellingPoints = []
        if (uniqueSellingPointsData) {
            tempUniqueSellingPoints = uniqueSellingPointsData?.uniqueSellingPoints
        }

        if (data?.uniqueSellingPoints?.length > 0) {
            tempUniqueSellingPoints = combineUniqueSellingPointLists(tempUniqueSellingPoints, data.uniqueSellingPoints)
        }

        setUniqueSellingPoints(tempUniqueSellingPoints)
    }, [uniqueSellingPointsData, data])

    useEffect(() => {
        if (umbrellaId) {
            getUniqueSellingPoints().catch(() => {
            })
        }
    }, [getUniqueSellingPoints, umbrellaId, refetchUniqueSellingPoints])

    const isUniqueSellingPointSelected = (uniqueSellingPoint) => {
        return !!selectedUniqueSellingPoints.find(selectedSellingPoint => selectedSellingPoint.id === uniqueSellingPoint.id)
    }

    const handleUniqueSellingPointChange = ({target: {value}}) => {
        updateProductState({
            ...product,
            uniqueSellingPoints: value.map((uniqueSellingPoint => ({
                id: uniqueSellingPoint.id,
                title: uniqueSellingPoint.title,
                description: uniqueSellingPoint.description,
                icon: uniqueSellingPoint.icon,
                museumId: umbrellaId
            })))
        })

        if (!unsavedChanges) setUnsavedChanges(true)
    }


    return <>
        {uniqueSellingPoints?.length > 0 &&
            <Select
                multiple
                fullWidth
                value={selectedUniqueSellingPoints}
                variant={"outlined"}
                onChange={handleUniqueSellingPointChange}
                input={<Input/>}
                renderValue={(selected) => <div style={{display: "flex", flexWrap: "wrap"}}>
                    {selected.map((selectedSellingPoint) => (
                        <SelectedUniqueSellingPointChip key={selectedSellingPoint.id}
                                                        uniqueSellingPoint={selectedSellingPoint}/>
                    ))}
                </div>}>
                {uniqueSellingPoints.map((uniqueSellingPoint) => (
                    <MenuItem disabled={selectedUniqueSellingPoints.length >= 3 && !isUniqueSellingPointSelected(uniqueSellingPoint)}
                              key={uniqueSellingPoint.id} value={uniqueSellingPoint}>
                        <ListItemIcon>
                            <Checkbox color={"primary"}
                                      checked={selectedUniqueSellingPoints?.filter(sellingPoint => sellingPoint.id === uniqueSellingPoint.id).length > 0}/>
                        </ListItemIcon>
                        <Icon fontSize={"small"}
                              style={{
                                  marginRight: "8px",
                                  color: "#3f3f3f"
                              }}>{uniqueSellingPoint?.icon?.toLowerCase()}</Icon>
                        <ListItemText primary={uniqueSellingPoint.title}/>
                    </MenuItem>
                ))}
            </Select>
            /*<ProductUniqueSellingPoint key={sellingPoint.id}
                                       uniqueSellingPoint={sellingPoint}
                                       isSelected={isUniqueSellingPointSelected(sellingPoint)}
                                       toggleSelected={toggleUniqueSellingPoint}/>*/
        }
    </>
}

export default ProductUniqueSellingPoints
