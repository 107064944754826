import React, {useEffect, useState} from "react";
import {useBaseTranslation} from "../../app/baseTranslationContext";
import _ from "lodash";
import {Button, Grid} from "@mui/material";
import useCustomSnackbar from "../../app/components/useCustomSnackbar";
import {useAxiosBpn} from "../../app/axios";
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext";
import JointProductsDiscountDetailsForm from "./JointProductsDiscountDetailsForm";
import JointProductsDiscountDetailsDragDrop from "./JointProductsDiscountDetailsDragDrop";
import {Close, LocalOffer} from "@mui/icons-material";
import SaveButtonWithProgressAndLabel from "../../app/components/SaveButtonWithProgressAndLabel";
import DashboardCard from "../../app/components/DashboardCard";
import {
    getTranslatableFieldValueFromObject,
    getTranslationsPayloadFromObject
} from "../../app/helper-functions/translationHelpers";

const JointProductsDiscountDetails = ({selectedDiscount, setSelectedDiscount, handleClose, refetch}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()
    const {umbrellaId} = useMuseumSelectorState()

    const [data, setData] = useState(selectedDiscount)
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [{loading: postLoading, error: postError}, postJointProductsDiscount] = useAxiosBpn({
        url: "joint_products_discount",
        method: "POST",
    }, {manual: true})

    const [{loading: putLoading, error: putError}, putJointProductsDiscount] = useAxiosBpn({
        url: `joint_products_discount/${data?.id}`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        setUnsavedChanges(false)
        setData(selectedDiscount)
    }, [selectedDiscount])

    useEffect(() => {
        if (postError) {
            snackbar.showError(postError.message)
        }
    }, [postError, snackbar])

    useEffect(() => {
        if (putError) {
            snackbar.showError(t(putError.message))
        }
    }, [putError, snackbar, t])

    const handleChange = event => {
        event.persist()
        setData(prev => ({...prev, [event.target.name]: event.target.value}))
        setUnsavedChanges(true)
    }

    const handleTranslationChange = (property, value) => {
        setData(prev => ({...prev, [property]: value}))
        setUnsavedChanges(true)
    }

    const handleJointProductsChange = (jointProducts) => {
        setData(prevState => ({...prevState, jointProducts: jointProducts}))
        setUnsavedChanges(true)
    }

    const _handleClose = () => {
        setUnsavedChanges(false)
        refetch()
        handleClose()
    }

    const populateTranslateAbleFieldsFromObject = (objectToPopulate) => {
        if (objectToPopulate) {
            objectToPopulate.name = getTranslatableFieldValueFromObject(objectToPopulate, "name")
            objectToPopulate.description = getTranslatableFieldValueFromObject(objectToPopulate, "description")
        }
        return objectToPopulate
    }

    const handleSave = () => {
        data.translations = getTranslationsPayloadFromObject(data)
        if (data?.new) {
            postJointProductsDiscount({data: {...data, museumId: umbrellaId}})
                .then(({data: {jointProductsDiscount}}) => {
                    snackbar.showSuccess(`${t("jointProductsDiscount")} ${t("added").toLowerCase()}`)
                    setSelectedDiscount(populateTranslateAbleFieldsFromObject(jointProductsDiscount))
                    refetch()
                    setUnsavedChanges(false)
                })
        } else {
            putJointProductsDiscount({data: {...data, museumId: umbrellaId}})
                .then(({data: {jointProductsDiscount}}) => {
                    snackbar.showSuccess(`${t("jointProductsDiscount")} ${t("updated").toLowerCase()}`)
                    setSelectedDiscount(populateTranslateAbleFieldsFromObject(jointProductsDiscount))
                    refetch()
                    setUnsavedChanges(false)
                })
        }
    }

    return !!selectedDiscount?.name?.value || selectedDiscount?.new
        ? <DashboardCard avatar={<LocalOffer/>}
                         height="100%"
                         cardContentStyle={{height: "calc(100% - 72px)", paddingBottom: 8}}
                         action={
                             <Grid container
                                   spacing={1}
                                   alignItems="center"
                                   justifyContent="flex-end">
                                 <Grid item>
                                     <SaveButtonWithProgressAndLabel unsavedChanges={unsavedChanges}
                                                                     handleSave={handleSave}
                                                                     loading={postLoading || putLoading}/>
                                 </Grid>
                                 <Grid item>
                                     <Button variant="contained"
                                             onClick={_handleClose}
                                             startIcon={<Close/>}>
                                         {unsavedChanges ? t("cancel") : t("close")}
                                     </Button>
                                 </Grid>
                             </Grid>
                         }
                         title={!!selectedDiscount?.name?.value || `${t("new")} ${_.lowerFirst(t("jointProductsDiscount"))}`}>
            <JointProductsDiscountDetailsForm data={data}
                                              selectedDiscountId={selectedDiscount?.id}
                                              handleChange={handleChange}
                                              handleTranslationChange={handleTranslationChange}
                                              unsavedChanges={unsavedChanges}/>
            <JointProductsDiscountDetailsDragDrop jointProducts={selectedDiscount?.jointProducts}
                                                  handleJointProductsChange={handleJointProductsChange}
                                                  selectedDiscountId={selectedDiscount?.id}/>
        </DashboardCard>
        : null
}

export default JointProductsDiscountDetails
