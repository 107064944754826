import React, {useEffect, useState} from "react"
import {Grid, Tooltip, Typography} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import {Label} from "@mui/icons-material"


const ScheduleEventExceptionsGridRowRenamed = ({name, originalName}) => {
    const theme = useTheme()

    const [renamed, setRenamed] = useState(false)
    const [color, setColor] = useState("lightgray")

    useEffect(() => setColor(renamed ? "darkred" : "lightgray"), [renamed])

    useEffect(() => {
        if (name !== originalName) setRenamed(true)
        else setRenamed(false)
    }, [name, originalName])

    const TooltipTitle = () => (
        <Typography variant="subtitle2">
            {
                renamed
                    ? `Navn endret fra ${originalName || "ingen"} til ${name}`
                    : "Navn ikke endret"
            }
        </Typography>
    )

    return (
        <Tooltip title={<TooltipTitle/>}>
            <Grid container item direction="column" alignItems="center">
                <Grid item><Label style={{color: color}}/></Grid>
                <Grid item>
                    <Typography style={{color: color, marginTop: -8, fontSize: theme.typography.pxToRem(11)}}>
                        Navn
                    </Typography>
                </Grid>
            </Grid>
        </Tooltip>
    )
}

export default ScheduleEventExceptionsGridRowRenamed