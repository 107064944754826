import React, {createContext, useContext} from "react"
import {useTranslation} from "react-i18next"
import {ProgressIndicator} from "../app/ProgressIndicator"

const OtherTranslationContext = createContext(undefined)

export const OtherTranslationProvider = ({children}) => {
    const {t, ready} = useTranslation("bpn.other", {useSuspense: false})

    if (ready) {
        return (
            <OtherTranslationContext.Provider value={t}>
                {children}
            </OtherTranslationContext.Provider>
        )
    } else {
        return <ProgressIndicator/>
    }
}

export const useOtherTranslation = () => {
    return useContext(OtherTranslationContext)
}