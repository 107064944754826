import React, {useEffect, useState} from "react"
import CardHeader from "@mui/material/CardHeader"
import {Card, CardContent, Typography} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {teal} from "@mui/material/colors"
import {getEventLabel} from "../helper-functions/dateFormat"
import GenericTable from "../generic-table/GenericTable"
import _ from "lodash"
import {nokPrice} from "../helper-functions/numberFormat"

const CreateOrderDialogSummary = ({order, product, jointProducts, discountCode, style}) => {
    const t = useBaseTranslation()

    const [_order, setOrder] = useState()
    const [products, setProducts] = useState()
    const [orderLines, setOrderLines] = useState()
    const [productOrderLines, setProductOrderLines] = useState()
    const [orderTotal, setOrderTotal] = useState()

    useEffect(() => {
        if (order) {
            const {orderLines: _orderLines, ...orderRest} = order
            setOrderLines(_orderLines)
            setOrder(orderRest)
        }
    }, [order])

    useEffect(() => setProducts([product, ...jointProducts]), [jointProducts, product])

    useEffect(() => {
        if (orderLines?.length) {
            let _orderTotal = 0

            const grouped = products?.map((product) => {
                let hasDiscount = false
                const {jointProductsDiscountId} = product
                let appliedDiscountCodeId

                let _orderLines = orderLines
                    .filter(({productId}) => productId === product.id)
                    .map(line => {
                        const {
                            originalPriceIfDiscount,
                            price,
                            quantity,
                            productPriceCategoryName,
                            appliedDiscountCodeId: _appliedDiscountCodeId,
                            jointProductsDiscountId: _jointProductsDiscountId,
                            jointProductsDiscountName,
                            jointProductsDiscountPercent,
                            familyDiscountAmount: familyDiscount,
                        } = line

                        let jointDiscountAmount = null

                        if (originalPriceIfDiscount) hasDiscount = true
                        appliedDiscountCodeId = _appliedDiscountCodeId

                        if (_jointProductsDiscountId === jointProductsDiscountId) {
                            jointDiscountAmount = _.round((originalPriceIfDiscount || price) * jointProductsDiscountPercent / 100,
                                2) * quantity
                        }

                        const _price = familyDiscount || jointDiscountAmount ? price : originalPriceIfDiscount || price
                        let _productPriceCategoryName = productPriceCategoryName

                        if (familyDiscount) _productPriceCategoryName += " (familierabatt)"
                        else if (jointDiscountAmount) _productPriceCategoryName += ` (${jointProductsDiscountName})`

                        return ({
                            ...line,
                            price: _price,
                            productPriceCategoryName: _productPriceCategoryName,
                            sum: _.round(quantity * _price, 2),
                        })
                    })
                const getTotal = () => _orderLines.map(({sum}) => sum).reduce((prev, curr) => prev + curr, 0)

                if (hasDiscount) {
                    let discountedAmount
                    const totalBeforeDiscount = getTotal()

                    if (appliedDiscountCodeId === discountCode?.id) {
                        const {code, discount} = discountCode

                        discountedAmount = totalBeforeDiscount * discount / 100
                        if (discountedAmount) _orderLines.push({
                            productPriceCategoryName: `${t("discountCode")} ${code} (${discount}%)`,
                            sum: _.round(discountedAmount * -1, 2),
                        })
                    }
                }

                const total = getTotal()

                if (total) {
                    _orderTotal += total
                    _orderLines.push({sum: total})
                }

                return ({
                    ...product,
                    orderLines: _orderLines,
                })
            })
            setProductOrderLines(grouped)
            setOrderTotal(_orderTotal)
        }
    }, [discountCode, orderLines, products, t])

    const tableHeaders = [
        {field: "quantity", flex: 1},
        {field: "productPriceCategoryName", flex: 10, align: "left"},
        {field: "price", flex: 1, ...nokPrice},
        {field: "sum", flex: 1, ...nokPrice},
    ]

    return (
        <Card square
              style={{backgroundColor: teal[50], height: "100%", ...style}}>
            <CardHeader title={`${t("orderSummary")} ${_order?.id}`}
                        titleTypographyProps={{style: {color: teal[900]}}}/>
            <CardContent>
                {productOrderLines?.length
                 ? productOrderLines.map(({id, name, museumName, orderLines}) => orderLines?.length ? (
                        <div key={id} style={{marginBottom: 24}}>
                            <Typography><strong>{name}</strong></Typography>
                            <Typography variant="caption" color="textSecondary">
                                <strong>{museumName}</strong> - {getEventLabel(t, orderLines[0])}
                            </Typography>
                            <hr style={{opacity: 0.4}}/>
                            <GenericTable columns={tableHeaders}
                                          size="small"
                                          rows={orderLines}
                                          hideHeader
                                          totalsRow
                                          totalsColumn/>
                            <hr style={{opacity: 0.4}}/>
                        </div>
                    ) : null)
                 : <Typography>{t("noOrderLinesYet")}</Typography>}
                {orderTotal && (
                    <Typography><strong>Totalt: {orderTotal},-</strong></Typography>
                )}
            </CardContent>
        </Card>
    )
}

export default CreateOrderDialogSummary