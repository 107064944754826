import {Grid, Typography} from "@mui/material"
import React from "react"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import Button from "@mui/material/Button"
import GroupTicketCategory from "./GroupTicketCategory"
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add"

const useStyles = makeStyles((theme) => ({
    addButtonContainer: {
        marginTop: theme.spacing(2),
        width: "100%",
        textAlign: "end",
    },
    groupTicketConfigurerWrapper: {
        padding: theme.spacing(4),
        borderWidth: "1px",
        border: "5px #aeaeae",
        borderStyle: "solid none",
    },
}))

const GroupTicketConfigurer = ({priceCategory, onPriceCategoryChanged, availablePriceCategories}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    const handleAddPriceCategory = () => {
        if (!priceCategory.groupTicket) {
            onPriceCategoryChanged("groupTicket", [{id: 1, priceCategory: "", ticketCount: 0}])
        } else {
            onPriceCategoryChanged("groupTicket",
                [
                    ...priceCategory.groupTicket,
                    {id: priceCategory.groupTicket.length + 1, priceCategory: "", ticketCount: 0},
                ])
        }
    }

    const updateTicketCategory = (ticketCategory, index, key, newValue) => {
        const updatedTicketCategory = {...ticketCategory}
        updatedTicketCategory[key] = newValue
        const updatedGroupTicketList = [...priceCategory.groupTicket]
        updatedGroupTicketList[index] = updatedTicketCategory
        onPriceCategoryChanged("groupTicket", updatedGroupTicketList)
    }

    const getAllPriceCategoriesIncludedInVisitorStats = () => {
        return availablePriceCategories.filter(category =>
            category.includedInVisitorStats === true && !category.groupTicket && category.name !== priceCategory.name,
        )
    }

    return (
        <div className={classes.groupTicketConfigurerWrapper}>
            <Grid container
                  spacing={1}
                  alignItems="center">
                <Grid item>
                    <Typography variant={"caption"}>{t("Velg hva gruppebilletten skal telles som i besøksantallet")}</Typography>
                </Grid>
                {priceCategory?.groupTicket?.length > 0 &&
                <>
                    {priceCategory?.groupTicket.map((ticketCategory, index) => (
                        <GroupTicketCategory key={ticketCategory.id}
                                             availablePriceCategories={getAllPriceCategoriesIncludedInVisitorStats()}
                                             ticketCategory={ticketCategory}
                                             priceCategory={priceCategory}
                                             onChangedTicketCategory={(key, newValue) => updateTicketCategory(
                                                 ticketCategory,
                                                 index,
                                                 key,
                                                 newValue)}
                                             onDeleteTicketCategory={(ticketCategory) => onPriceCategoryChanged(
                                                 "groupTicket",
                                                 priceCategory?.groupTicket.filter(ticketCat => ticketCat !== ticketCategory))}/>))}

                </>
                }
                <div className={classes.addButtonContainer}>
                    <Button variant={"contained"}
                            disabled={priceCategory?.groupTicket?.length >= availablePriceCategories.length}
                            color={"primary"}
                            onClick={() => handleAddPriceCategory()}
                            endIcon={<AddIcon/>}>{t("addTicketCategory")}</Button>
                </div>
            </Grid>
        </div>
    )
}

export default GroupTicketConfigurer