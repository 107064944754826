import React, {useEffect, useState} from "react"
import {useAxiosBpn} from "../axios"
import {Button, ClickAwayListener, Grid, IconButton, Link, Paper, Popper, Tooltip, Typography} from "@mui/material"
import {CalendarToday, ConfirmationNumber, FileCopy, Update} from "@mui/icons-material"
import TicketInformation from "./TicketInformation"
import {endOfDay, startOfDay} from "date-fns"
import MuiDatePicker from "../components/MuiDatePicker"
import {useBaseTranslation} from "../baseTranslationContext"
import {dateFormat} from "../helper-functions/dateFormat"
import {ProgressIndicator} from "../ProgressIndicator"
import DashboardCard from "../components/DashboardCard"
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext"
import {useAuthsState} from "../auths/authsContext"

const TicketStatusCard = ({productId, testOrders}) => {
    const t = useBaseTranslation()
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [after, setAfter] = useState()
    const [before, setBefore] = useState()
    const [eventsGroupedByDate, setEventsGroupedByDate] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectDateDialogOpen, setSelectDateDialogOpen] = useState(false)
    const {selectedMuseum, umbrellaAndChildrenIds} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const [museumIds, setMuseumIds] = useState()
    const [lastUpdatedAt, setLastUpdatedAt] = useState(dateFormat(new Date(), "pp"))
    const [ticketStatusPortalLink, setTicketStatusPortalLink] = useState()

    const [{data, loading}, getProductStats] = useAxiosBpn({
        url: `schedule_events/ticket_status`,
        params: {
            productId: productId,
            museumIds: museumIds,
            test: testOrders,
            after: after,
            before: before,
        },
    })

    useEffect(() => {
        if (productId) setMuseumIds(null)
        else if (umbrellaAndChildrenIds?.length) setMuseumIds(umbrellaAndChildrenIds.join())
        else setMuseumIds(Array.from(userAccessMuseumIds.keys()).join())
    }, [productId, umbrellaAndChildrenIds, userAccessMuseumIds])

    useEffect(() => {
        setAfter(startOfDay(selectedDate).getTime())
        setBefore(endOfDay(selectedDate).getTime())
    }, [selectedDate])

    useEffect(() => {
        getProductStats().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getProductStats().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getProductStats])

    useEffect(() => {
        if (data) {
            const {eventsGroupedByDate: _eventsGroupedByDate} = data
            setEventsGroupedByDate(_eventsGroupedByDate)
        } else {
            setEventsGroupedByDate([])
        }
    }, [data])

    useEffect(() => {
        if (productId) {
            setTicketStatusPortalLink(`${window._env_.REACT_APP_BPN_PORTAL}${productId}/stats`)
        } else {
            setTicketStatusPortalLink(`${window._env_.REACT_APP_BPN_PORTAL}status/${selectedMuseum?.id}`)
        }
    }, [productId, selectedMuseum])

    const handleDateChange = (date) => {
        setSelectedDate(date)
        setSelectDateDialogOpen(false)
    }

    const handleRefresh = () => {
        getProductStats()
        setLastUpdatedAt(dateFormat(new Date(), "pp"))
    }

    const handleSelectDateDialogOpen = (event) => {
        setSelectDateDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleCopyToClipboard = async () => await navigator.clipboard.writeText(ticketStatusPortalLink)

    return <>
        <DashboardCard title={t("ticketStatus")}
                       testOrders={testOrders}
                       subheader={dateFormat(selectedDate, "PPPP")}
                       avatar={<ConfirmationNumber/>}
                       action={
                           <Button variant="text"
                                   onClick={handleSelectDateDialogOpen}
                                   endIcon={<CalendarToday/>}>
                               {t("chooseDate")}
                           </Button>
                       }>
            {loading
             ? <ProgressIndicator/>
             : <>
                 <Grid container
                       direction="column">
                     {eventsGroupedByDate?.map(group => (
                         Object.entries(group).map(([date, events]) => (
                             <div key={date}>
                                 {events.map((event,
                                              index) => (
                                     <Grid item
                                           key={index}>
                                         <TicketInformation {...event} showProductName={!productId}/>
                                     </Grid>
                                 ))}
                             </div>
                         ))))}
                     {!eventsGroupedByDate?.length && (
                         <Typography>
                             Ingen billetter på valgt dato
                         </Typography>
                     )}
                 </Grid>
                 <Grid container
                       style={{marginTop: 0}}
                       direction="row"
                       justifyContent="space-between">

                     <Grid item
                           xs={12}
                           sm={6}>
                         <Grid container
                               alignItems="center"
                               spacing={1}>
                             <Grid item>
                                 <Typography variant="button" style={{marginLeft: 8}}>
                                     <strong>
                                         <Link href={ticketStatusPortalLink}
                                               target="urlToTicketStatus">
                                             Billettstatus i kjøpsportal
                                         </Link>
                                     </strong>
                                 </Typography>
                             </Grid>
                             <Grid item>
                                 <Tooltip title="Kopier link">
                                     <IconButton onClick={handleCopyToClipboard} size="large">
                                         <FileCopy fontSize="small"/>
                                     </IconButton>
                                 </Tooltip>
                             </Grid>
                         </Grid>
                     </Grid>

                     <Grid item
                           xs={12}
                           sm={6}>
                         <Grid container
                               justifyContent="flex-end"
                               alignItems="center">
                             <Grid item>
                                 <Typography variant="caption">
                                     {`${t("lastUpdated")}: ${lastUpdatedAt}`}
                                 </Typography>
                             </Grid>
                             <Grid item>
                                 <IconButton onClick={handleRefresh} size="large">
                                     <Update/>
                                 </IconButton>
                             </Grid>
                         </Grid>
                     </Grid>

                 </Grid>
             </>}
        </DashboardCard>
        <Popper open={selectDateDialogOpen}
                style={{zIndex: 5}}
                anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={() => setSelectDateDialogOpen(false)}>
                <Paper elevation={8}>
                    <MuiDatePicker selected={selectedDate}
                                   label={t("date")}
                                   name="selectedDate"
                                   fullWidth={false}
                                   size="small"
                                   variant="static"
                                   inputVariant="standard"
                                   onChange={handleDateChange}/>
                </Paper>
            </ClickAwayListener>
        </Popper>
    </>;
}

export default TicketStatusCard


