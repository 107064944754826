import React from "react";
import { useBaseTranslation } from "../baseTranslationContext";
import { ProgressIndicator } from "../ProgressIndicator";
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";

const OrderDetailsDialogQuestionAnswers = ({loading, order}) => {
  const t = useBaseTranslation()

  const getProductNameByProductId = (productId) => {
    return order.orderLines.find(orderLine => orderLine.productId === productId)?.productName || ""
  }

  return loading
    ? <ProgressIndicator/>
    : <Card square
            variant="outlined">
      <CardHeader title={t("registrationQuestions")}
                  titleTypographyProps={{color: "primary"}}
      />
      <CardContent>
        {order.formQuestionAnswers?.map(answer => {
          return <div key={`${answer.productId}${answer.orderId}`}>
            <Typography variant={"h6"}>{getProductNameByProductId(answer.productId)}:</Typography>
            <Typography variant={"caption"}>{t('customerAnswer')}:</Typography>
            <Typography>{answer.answer}</Typography>
            <Divider style={{marginTop: "1rem", marginBottom: "1rem"}}/>
          </div>})
        }
      </CardContent>
    </Card>

}

export default OrderDetailsDialogQuestionAnswers
