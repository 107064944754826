import React, { useState } from "react";
import { Avatar, CardHeader, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { QRCode } from "react-qr-svg";
import { lowerFirst } from "lodash";
import { useBaseTranslation } from "../baseTranslationContext";
import makeStyles from '@mui/styles/makeStyles';
import ValidationAttempts from "./OrderDetailsDialogValidationAttempts";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

const SubTicket = ({ticket, order}) => {
  const t = useBaseTranslation()
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  function getPriceCategoryByOrderLineId(orderLineId) {
    const orderLine = order.orderLines.find(orderline => orderline.id === orderLineId)
    return orderLine ? orderLine.productPriceCategoryName : ''
  }


  function getTitle() {
    return (
      <Grid container
            spacing={1}
            alignItems="center"
            style={{marginLeft: 8}}>
        <Grid item><Typography variant="overline"><strong>{getPriceCategoryByOrderLineId(ticket.orderLineId)}</strong></Typography></Grid>
        <Grid item><Typography variant="overline">{ticket.code}</Typography></Grid>
        <Grid item>
          <Typography variant="overline">
            {`${t("validated")} (${ticket.timesValidated
              ? ticket.timesValidated
              : lowerFirst(t("never"))})`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="overline">
            {`${t("validationAttempts")} (${ticket.validationAttempts.length})`}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  function getAction() {
    return (
      <IconButton
        className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
        onClick={() => setExpanded(!expanded)}
        size="large">
        <ExpandMoreIcon/>
      </IconButton>
    );
  }

  return (
    <>
    <CardHeader title={getTitle()}
                avatar={<Avatar variant="square"
                                className={classes.smallAvatar}>
                  <QRCode value={ticket.code}/>
                </Avatar>}
                action={getAction()}/>
    <Collapse in={expanded}
              timeout="auto"
              unmountOnExit>
      {ticket.validationAttempts && <ValidationAttempts validationAttempts={ticket.validationAttempts}/>}
    </Collapse>
  </>

  )
}

export default SubTicket