import React, {createContext, useContext, useEffect, useReducer, useState} from "react";
import {getParent, getUmbrellaMuseumChildren, getUmbrellaMuseums} from "../endpoints/museums"
import {useAuthsState} from "../auths/authsContext"
import {ADMIN_APP_ID, getRoleByMuseumId} from "../menu/MuseumSelectorHelpers"
import {ROLES} from "../menu/MuseumSelector"

export const SET_ROOT_MUSEUM = "museumSelector/setRootMuseum"
export const SET_UMBRELLA_MUSEUM = "museumSelector/setUmbrellaMuseum"
export const SET_MUSEUM = "museumSelector/setMuseum"
export const SET_SELECTED_MUSEUM = "museumSelector/setSelectedMuseum"
export const SET_NEW_MUSEUM_SELECTOR_STATE = "museumSelector/setNewMuseumSelectorState"
export const SELECTED_TYPE_ROOT = "root"
export const SELECTED_TYPE_UMBRELLA = "umbrella"
export const SELECTED_TYPE_MUSEUM = "museum"
const LOCAL_STORAGE_KEY = "bpn.museumSelectorContext"

const MuseumSelectorStateContext = createContext(undefined)
const MuseumSelectorDispatchContext = createContext(undefined)

const rootMuseum = {
    id: 0,
    name: "Alle",
    type: "root",
    role: {name: "User", value: 1},
    children: [],
}

const initialState = {
    selectedMuseum: rootMuseum,
    umbrellaAndChildrenIds: [],
    umbrellaAndChildrenIdsInAccessList: [],
    umbrellaId: null,
    umbrellaName: null,
}

const museumSelectorReducer = (state, action) => {
    switch (action.type) {
        case SET_ROOT_MUSEUM:
            return {
                ...state,
                umbrellaAndChildrenIds: [],
                umbrellaAndChildrenIdsInAccessList: [],
                umbrellaId: null,
                umbrellaName: null,
            }
        case SET_UMBRELLA_MUSEUM:
            return {
                ...state,
                umbrellaChildren: action.payload.children,
                umbrellaAndChildrenIds: action.payload.umbrellaAndChildrenIds,
                umbrellaAndChildrenIdsInAccessList: action.payload.umbrellaAndChildrenIds,
                umbrellaId: action.payload.id,
                umbrellaName: action.payload.name,
            }
        case SET_MUSEUM:
            return {
                ...state,
                umbrellaAndChildrenIds: [action.payload.id],
                umbrellaAndChildrenIdsInAccessList: action.payload.umbrellaAndChildrenIds,
            }
        case SET_SELECTED_MUSEUM:
            return {
                ...state,
                selectedMuseum: action.payload,
            }
        case SET_NEW_MUSEUM_SELECTOR_STATE:
            return {
                ...action.payload
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

const getLocalMuseumSelectorContext = (userData, userAccessMuseumIds) => {
    const localState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    const isAppAdmin = userData?.appAccess?.apps?.find(app => app.id === window._env_.REACT_APP_ID && app.role.name === ROLES.ADMIN)
    const isGlobalEkulturAdmin = userData?.appAccess?.apps?.find(app => app.id === ADMIN_APP_ID && app.role.name === ROLES.ADMIN)

    if (!localState?.selectedMuseum?.role?.value && userAccessMuseumIds.size > 0) {
        localState.selectedMuseum.role = getRoleByMuseumId(localState?.selectedMuseum?.id,
            userAccessMuseumIds,
            isAppAdmin,
            isGlobalEkulturAdmin)
    }

    if ((localState && Object.keys(localState).length === 0) || localState === "{}") {
        return null
    }

    return localState
}

export const MuseumSelectorProvider = ({children}) => {
    const {userAccessMuseumIds, userData} = useAuthsState()
    const [isMounted, setIsMounted] = useState(false);
    const [state, dispatch] = useReducer(museumSelectorReducer, getLocalMuseumSelectorContext(userData, userAccessMuseumIds) || initialState)

    useEffect(() => {
        if (isMounted && userData?.appAccess && !!userAccessMuseumIds && !!!state.selectedMuseum.role) {
            dispatch({
                type: SET_NEW_MUSEUM_SELECTOR_STATE,
                payload: getLocalMuseumSelectorContext(userData, userAccessMuseumIds)
            })
        }
    }, [isMounted, userData, userAccessMuseumIds, state.selectedMuseum.role])

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
    }, [state])

    useEffect(() => {
        async function getParentAndChildren() {
            let parent = await getParent(state?.selectedMuseum?.id)

            if (parent) {
                let {children, ids} = await getUmbrellaMuseumChildren(parent?.id, userData?.appAccess?.museums)
                return {parent: parent, children: children, ids: ids}
            } else {
                const _museum = state?.selectedMuseum
                // using museum as 'parent' if museum has no umbrella (to be able to save settings)
                return {parent: _museum, ids: [_museum?.id]}
            }
        }

        if (!isMounted) {
            return
        }

        switch (state.selectedMuseum.type) {
            case SELECTED_TYPE_ROOT:
                getUmbrellaMuseums().then(result => {
                    if (isMounted) {
                        dispatch({type: SET_ROOT_MUSEUM, payload: result})
                    }
                })
                break
            case SELECTED_TYPE_UMBRELLA:
                getUmbrellaMuseumChildren(state.selectedMuseum.id, userData?.appAccess?.museums)
                    .then(({children, ids}) => {
                        if (isMounted) {
                            dispatch({
                                type: SET_UMBRELLA_MUSEUM, payload: {
                                    ...state.selectedMuseum,
                                    children: children,
                                    umbrellaAndChildrenIds: ids,
                                },
                            })
                        }
                    })
                break
            case SELECTED_TYPE_MUSEUM:
                getParentAndChildren().then(({parent, children, ids}) => {
                    if (isMounted) {
                        dispatch({
                            type: SET_UMBRELLA_MUSEUM,
                            payload: {
                                ...parent,
                                children: children,
                                umbrellaAndChildrenIds: ids,
                            },
                        })
                        dispatch({
                            type: SET_MUSEUM,
                            payload: {
                                ...state.selectedMuseum,
                                umbrellaAndChildrenIds: ids,
                            },
                        })
                    }
                })
                break
            default:
                throw new Error(`Unhandled type: ${state.selectedMuseum.type}`)
        }
    }, [isMounted, state.selectedMuseum, state.umbrellaId, userData]);

    useEffect(() => {
        setIsMounted(true)
        return () => (setIsMounted(false))
    }, [])

    return (
        <MuseumSelectorStateContext.Provider value={state}>
            <MuseumSelectorDispatchContext.Provider value={dispatch}>
                {children}
            </MuseumSelectorDispatchContext.Provider>
        </MuseumSelectorStateContext.Provider>
    )
}

export let useMuseumSelectorState = () => useContext(MuseumSelectorStateContext)
export const useMuseumSelectorDispatch = () => useContext(MuseumSelectorDispatchContext)
