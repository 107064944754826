import React, {useEffect, useState} from "react"
import {IconButton, Tooltip, Typography} from "@mui/material"
import {add, endOfMonth, startOfMonth, sub} from "date-fns"
import {dateFormat} from "../helper-functions/dateFormat"
import {toSentenceCase} from "../helper-functions/stringUtility"
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material"
import {useBaseTranslation} from "../baseTranslationContext"

const DateFilterMonthSwitcherButtons = ({handleMonthChange, currentMonth}) => {
    const t = useBaseTranslation()
    const [label, setLabel] = useState()

    useEffect(() => {
        if (currentMonth) {
            setLabel(`${toSentenceCase(dateFormat(currentMonth, "MMMM"))} ${dateFormat(currentMonth, "y")}`)
        }
    }, [currentMonth])

    const handlePreviousMonthClick = () => _handleMonthChange(sub(currentMonth, {months: 1}))
    const handleNextMonthClick = () => _handleMonthChange(add(currentMonth, {months: 1}))

    const _handleMonthChange = (newMonth) => {
        const _after = startOfMonth(newMonth).getTime()
        const _before = endOfMonth(newMonth).getTime()
        const label = `${toSentenceCase(dateFormat(newMonth, "MMMM"))} ${dateFormat(newMonth, "y")}`
        handleMonthChange(_after, _before, label)
    }

    return (
        <>
            <Tooltip title={t("previousMonth")}>
                <IconButton color="primary"
                            size="small"
                            onClick={handlePreviousMonthClick}>
                    <KeyboardArrowLeft/>
                </IconButton>
            </Tooltip>
            <Tooltip title={t("nextMonth")}>
                <IconButton color="primary"
                            size="small"
                            onClick={handleNextMonthClick}>
                    <KeyboardArrowRight/>
                </IconButton>
            </Tooltip>
            <Typography variant="button">
                {label}
            </Typography>

        </>
    )
}

export default DateFilterMonthSwitcherButtons