import React, {useCallback, useEffect, useState} from "react"
import {Button, SvgIcon} from "@mui/material";
import ScrollableSectionView from "../ScrollableSectionView"
import {TreeView} from "@mui/lab"
import {getEventLabel} from "../helper-functions/dateFormat";
import {useBaseTranslation} from "../baseTranslationContext"
import {TreeItems} from "./TreeItems";
import {SelectedChip} from "./SelectedChip";

const MinusSquare = props => (
    <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
    </SvgIcon>
)

const PlusSquare = props => (
    <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
    </SvgIcon>
)


const ScheduleEventTreeView = ({
                                   selectedEvent,
                                   groupedScheduleEvents,
                                   handleClose,
                                   handleSelect,
                                   size = "medium",
                               }) => {
    const t = useBaseTranslation()

    const [expandedNodes, setExpandedNodes] = useState([])
    const [selectedNode, setSelectedNode] = useState([])
    const [selectedLabel, setSelectedLabel] = useState()

    const _getEventLabel = useCallback((event) => getEventLabel(t, event), [t])

    useEffect(() => {
        if (!selectedNode.length) setSelectedLabel(undefined)
    }, [selectedNode])

    useEffect(() => {
        if (selectedEvent) {
            const label = _getEventLabel(selectedEvent)
            setSelectedLabel(label)
            setSelectedNode([`${selectedEvent?.id}-${selectedEvent?.recurNumber}`])
        }
    }, [_getEventLabel, selectedEvent])

    const handleToggle = (event, nodeIds) => {
        setExpandedNodes(nodeIds)
    }

    const _handleSelect = (event) => {
        handleSelect(event, _getEventLabel(event))

        if (handleClose) handleClose()
    }

    const handleUnselect = () => {
        setSelectedNode([])
        handleSelect()
    }

    const handleExpandAll = () => {
        let nodes = []

        Object.entries(groupedScheduleEvents).map(([year, months]) => {
            nodes.push(year)
            return Object.entries(months).map(([month, days]) => {
                nodes.push(`${year}-${month}`)
                return Object.entries(days).map(([day, events]) => nodes.push(`${year}-${month}-${day}`))
            })
        })
        setExpandedNodes(nodes)
    }


    const getButton = () => expandedNodes.length
                            ? <Button variant="text"
                                      onClick={() => setExpandedNodes([])}>
                                {t("collapseAll")}
                            </Button>
                            : <Button variant="text"
                                      onClick={handleExpandAll}>
                                {t("expandAll")}
                            </Button>

    return (
        <>
            {size === "small"
             ? <div style={{height: 48}}>
                    <SelectedChip selectedLabel={selectedLabel} handleUnselect={handleUnselect} />
            </div>
             : <SelectedChip selectedLabel={selectedLabel} handleUnselect={handleUnselect} />}
            <ScrollableSectionView>
                <TreeView defaultCollapseIcon={<MinusSquare/>}
                          defaultExpandIcon={<PlusSquare/>}
                          expanded={expandedNodes}
                          selected={selectedNode}
                          style={{
                              maxHeight: 264,
                              height: size === "small" ? null : 264,
                              flexGrow: 1,
                              maxWidth: 400,
                          }}
                          onNodeToggle={handleToggle}>
                    <TreeItems
                        getEventLabel={_getEventLabel}
                        onSelect={_handleSelect}
                        groupedScheduleEvents={groupedScheduleEvents}
                        t={t}
                    />
                </TreeView>
            </ScrollableSectionView>
            {size !== "small" && getButton()}
        </>
    )
}

export default ScheduleEventTreeView
