import React, {useEffect, useState} from "react"
import {
    Button,
    Chip,
    ClickAwayListener,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {AccountBalance, ArrowDropDown, ArrowForwardIos, Museum} from "@mui/icons-material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import {useAxiosBpn} from "../../../app/axios"
import {
    VOUCHER_SETTINGS_TYPE_DEPARTMENT,
    VOUCHER_SETTINGS_TYPE_MUSEUM,
    VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP,
} from "../../../app/helper-functions/voucherStatuses"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import AddIcon from "@mui/icons-material/Add"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import DeleteIcon from "@mui/icons-material/Delete"

const SelectButton = ({handlePopperOpen, label, type}) => (
    <>
        <Grid item>
            <ArrowForwardIos style={{marginTop: 8}}
                             color="disabled"/>
        </Grid>
        <Grid item>
            <Button variant="text"
                    onClick={(e) => handlePopperOpen(e, type)}
                    endIcon={<ArrowDropDown/>}>
                {label}
            </Button>
        </Grid>
    </>
)

const SelectedChip = ({label, avatar, onDelete}) => (
    <>
        <Grid item>
            <ArrowForwardIos style={{marginTop: 8}}
                             color="disabled"/>
        </Grid>
        <Grid item>
            <Chip label={label}
                  avatar={avatar}
                  onDelete={onDelete}/>
        </Grid>
    </>
)

const VismaVoucherSettingsSelector = ({
                                          selectedMuseum,
                                          setSelectedMuseum,
                                          selectedProductGroup,
                                          setSelectedProductGroup,
                                          selectedDepartment,
                                          setSelectedDepartment,
                                          loading,
                                      }) => {
    const t = useBaseTranslation()
    const {palette} = useTheme()
    const {umbrellaId, umbrellaName, umbrellaChildren} = useMuseumSelectorState()
    const snackbar = useCustomSnackbar()

    const [anchorEl, setAnchorEl] = useState(null)
    const [popperType, setPopperType] = useState(null)
    const [selectMuseumHidden, setSelectMuseumHidden] = useState(false)
    const [selectProductGroupHidden, setSelectProductGroupHidden] = useState(false)
    const [selectDepartmentHidden, setSelectDepartmentHidden] = useState(true)
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [productGroups, setProductGroups] = useState([])
    const [departments, setDepartments] = useState([])
    const [newDepartmentName, setNewDepartmentName] = useState()

    const [{data: productGroupsData}, getProductGroups] = useAxiosBpn(
        {url: "product_groups", params: {museumId: umbrellaId}})

    const [{data: departmentsData}, getDepartments] = useAxiosBpn(
        {url: "voucher_departments", params: {museumId: selectedMuseum?.id}}, {manual: true})

    const [{error: postError}, postDepartment] = useAxiosBpn(
        {url: "voucher_departments", method: "POST"}, {manual: true})

    const [{error: deleteError}, deleteDepartment] = useAxiosBpn(
        {method: "DELETE"}, {manual: true})

    useEffect(() => {
        getProductGroups().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getProductGroups().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getProductGroups, umbrellaId])

    useEffect(() => {
        if (selectedMuseum?.id) {
            getDepartments().catch(() => {
                // Just to avoid annoying console error
            })
        } else setDepartments([])
    }, [getDepartments, selectedMuseum])

    useEffect(() => {
        /*
         Select Museum button should be hidden if:
         - a museum is already selected
         - the umbrella museum has no children (isn't an umbrella museum)
         */
        if (!umbrellaChildren?.length || selectedMuseum) {
            setSelectMuseumHidden(true)
        } else {
            setSelectMuseumHidden(false)
        }
    }, [selectedMuseum, umbrellaChildren])

    useEffect(() => {
        if (!productGroups?.length || selectedProductGroup) setSelectProductGroupHidden(true)
        if (productGroups.length && !selectedProductGroup) setSelectProductGroupHidden(false)
    }, [selectedProductGroup, productGroups])

    useEffect(() => {
        if (!selectedMuseum || selectedDepartment) setSelectDepartmentHidden(true)
        if (selectedMuseum && !selectedDepartment) setSelectDepartmentHidden(false)
    }, [selectedDepartment, selectedMuseum])

    useEffect(() => {
        if (productGroupsData) {
            const {productGroups} = productGroupsData
            setProductGroups(productGroups)
        }
    }, [productGroupsData])

    useEffect(() => {
        if (departmentsData) {
            const {voucherDepartments} = departmentsData
            setDepartments(voucherDepartments)
        }
    }, [departmentsData])

    useEffect(() => {
        if (postError) snackbar.showError(t(postError.message))
        if (deleteError) snackbar.showError(t(deleteError.message))
    }, [deleteError, postError, snackbar, t])

    const onNewDepartmentNameChange = e => {
        e.persist()
        setNewDepartmentName(e.target.value)
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleAddDepartment = () => {
        postDepartment({data: {name: newDepartmentName, museumId: selectedMuseum.id}})
            .then(() => {
                snackbar.showSuccess(`${t("department")} ${t("added").toLowerCase()}`)
                setUnsavedChanges(false)
                setNewDepartmentName("")
                getDepartments().catch(() => {
                    // Just to avoid annoying console error
                })
            })
    }

    const handleDelete = (department) => {
        deleteDepartment({url: `voucher_departments/${department.id}`})
            .then(() => {
                snackbar.showSuccess(`${t("department")} ${t("deleted").toLowerCase()}`)
                getDepartments().catch(() => {
                    // Just to avoid annoying console error
                })
            })
    }

    const handlePopperOpen = (event, type) => {
        setAnchorEl(event.currentTarget)
        setPopperType(type)
    }

    const handlePopperClose = () => {
        setAnchorEl(null)
        setPopperType(null)
    }

    const handleMuseumSelect = (museum) => {
        setSelectedMuseum(museum)
        handlePopperClose()
    }

    const handleProductGroupSelect = (productGroup) => {
        setSelectedProductGroup(productGroup)
        handlePopperClose()
    }

    const handleDepartmentSelect = (department) => {
        setSelectedDepartment(department)
        handlePopperClose()
    }

    const handleMuseumUnSelect = () => setSelectedMuseum(null)
    const handleProductGroupUnSelect = () => setSelectedProductGroup(null)
    const handleDepartmentUnSelect = () => setSelectedDepartment(null)

    return <>
        <Grid container
              spacing={1}
              alignItems="center"
              justifyContent="center"
              item>
            <Grid item>
                <Chip label={umbrellaName}
                      disabled
                      avatar={<AccountBalance/>}/>
            </Grid>

            {selectedProductGroup && <SelectedChip label={selectedProductGroup.name}
                                                   onDelete={handleProductGroupUnSelect}/>}

            {!selectProductGroupHidden && <SelectButton handlePopperOpen={handlePopperOpen}
                                                        label={t("productGroup")}
                                                        type={VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP}/>}

            {selectedMuseum && <SelectedChip label={selectedMuseum.name}
                                             avatar={<Museum/>}
                                             onDelete={handleMuseumUnSelect}/>}

            {!selectMuseumHidden && <SelectButton handlePopperOpen={handlePopperOpen}
                                                  label={t("museum")}
                                                  type={VOUCHER_SETTINGS_TYPE_MUSEUM}/>}

            {selectedDepartment && <SelectedChip label={selectedDepartment.name}
                                                 onDelete={handleDepartmentUnSelect}/>}

            {!selectDepartmentHidden && <SelectButton handlePopperOpen={handlePopperOpen}
                                                      label={t("department")}
                                                      type={VOUCHER_SETTINGS_TYPE_DEPARTMENT}/>}

            <ProgressIndicator linear={false}
                               size={25}
                               hide={!loading}/>
        </Grid>
        <Grid container
              spacing={1}
              style={{marginBottom: 16}}
              alignItems="center"
              justifyContent="center"
              item>
            <Typography variant="caption">
                Verdi i &nbsp;
                <span style={{backgroundColor: palette?.primary?.light}}>&nbsp;uthevet felt&nbsp;</span>&nbsp; er
                det som kommer til å bli brukt for gjeldende utvalg.
            </Typography>
        </Grid>
        <Popper open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{zIndex: 1500}}>
            <ClickAwayListener onClickAway={handlePopperClose}>
                <Paper elevation={8}>
                    <>
                        <MenuList>
                            {popperType === VOUCHER_SETTINGS_TYPE_MUSEUM && (
                                umbrellaChildren?.map((museum, index) => (
                                    <MenuItem key={index}
                                              onClick={() => handleMuseumSelect(museum)}>
                                        {museum.name}
                                    </MenuItem>
                                ))
                            )}
                            {popperType === VOUCHER_SETTINGS_TYPE_PRODUCT_GROUP && (
                                productGroups?.map((productGroup, index) => (
                                    <MenuItem key={index}
                                              onClick={() => handleProductGroupSelect(productGroup)}>
                                        {productGroup.name}
                                    </MenuItem>
                                ))
                            )}
                        </MenuList>
                        {popperType === VOUCHER_SETTINGS_TYPE_DEPARTMENT && (
                            <div style={{padding: 16}}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <TextField label={t("newDepartment")}
                                                   variant="standard"
                                                   size="small"
                                                   fullWidth
                                                   onChange={onNewDepartmentNameChange}
                                                   value={newDepartmentName || ""}/>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={"Legg til"}>
                                            <span>
                                                <IconButton disabled={!unsavedChanges} onClick={handleAddDepartment} size="large">
                                                    <AddIcon color={unsavedChanges ? "primary" : "disabled"}/>
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <List>
                                    {departments?.map((department) => (
                                        <ListItem key={department.id}>
                                            <ListItemText onClick={() => handleDepartmentSelect(department)}
                                                          style={{cursor: "pointer"}}>
                                                {department.name}
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleDelete(department)} size="large">
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}
                    </>
                </Paper>
            </ClickAwayListener>
        </Popper>
    </>;
}

export default VismaVoucherSettingsSelector