import React, {useEffect, useState} from "react"
import {Grid, LinearProgress, Paper} from "@mui/material"
import Typography from "@mui/material/Typography"
import makeStyles from '@mui/styles/makeStyles';
import {useBaseTranslation} from "../baseTranslationContext"
import {isPast, parse} from "date-fns"
import {dateFormat} from "../helper-functions/dateFormat"

const useStyles = makeStyles((theme) => ({
    progressBar: {
        height: 12,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[300],
    },
    bar: {
        backgroundColor: ({ticketsLeft}) => {
            if (ticketsLeft === "few") return "#f9a825"
            if (ticketsLeft === "none") return "#c62828"
            return "#2e7d32"
        },
    },
    bar2: {
        backgroundColor: ({ticketsLeft}) => {
            if (ticketsLeft === "few") return "#ffd95a"
            if (ticketsLeft === "none") return "#ff5f52"
            return "#60ad5e"
        },
    },
    dashed: {
        display: "none",
    },
}))

const normalise = (value, max) => value * 100 / max

const TicketInformation = ({
                               productName,
                               name,
                               saleLimit,
                               ticketsReserved,
                               ticketsSold,
                               startDate,
                               startTime,
                               endDate,
                               endTime,
                               isFullDay,
                               showProductName = false,
                           }) => {
    const [props, setProps] = useState({ticketsLeft: "few"})
    const classes = useStyles(props)
    const t = useBaseTranslation()

    const [timeCaption, setTimeCaption] = useState("")

    const bar1Value = normalise(ticketsSold, saleLimit)
    const bar2Value = normalise(ticketsSold + ticketsReserved, saleLimit)

    useEffect(() => {
        if (isFullDay) {
            setTimeCaption(t("wholeDay"))
        } else {
            let caption = dateFormat(parse(startTime, "HH:mm:ss", new Date()), "HH:mm")

            if (endTime) {
                caption += ` - ${dateFormat(parse(endTime, "HH:mm:ss", new Date()), "HH:mm")}`
            }

            setTimeCaption(caption)
        }
    }, [t, isFullDay, startTime, endTime])

    useEffect(() => {
        function ticketsLeft(_ticketsSold, _ticketsReserved, _saleLimit) {
            if (!_saleLimit) return null
            let _ticketsLeft = _saleLimit - (_ticketsReserved + _ticketsSold)

            if (_ticketsLeft) {
                if (_saleLimit > 10) {
                    if (_ticketsLeft <= (_saleLimit / 10)) return "few"
                } else {
                    if (_ticketsLeft <= (_saleLimit / 4)) return "few"
                }
                return "many"
            } else {
                return "none"
            }
        }

        setProps({ticketsLeft: ticketsLeft(ticketsSold, ticketsReserved, saleLimit)})

    }, [ticketsSold, ticketsReserved, saleLimit])

    function isEventOver(_startDate, _endDate, _endTime) {
        const _date = _endDate ? _endDate : _startDate
        const _dateTime = _endTime ? `${_date} ${_endTime}` : `${_date} 23:59:59`
        return isPast(parse(_dateTime, "yyyy-MM-dd HH:mm:ss", new Date()))
    }

    function getLinearProgress() {
        return saleLimit
               ? <LinearProgress value={bar1Value || 0}
                                 valueBuffer={bar2Value || 0}
                                 classes={{
                                     root: classes.progressBar,
                                     colorPrimary: classes.colorPrimary,
                                     bar: classes.bar,
                                     bar2Buffer: classes.bar2,
                                     dashed: classes.dashed,
                                 }}
                                 variant="buffer"/>
               : null
    }

    return (
        <Paper variant="outlined"
               square
               style={{
                   paddingLeft: 8,
                   paddingRight: 8,
                   margin: 4,
                   opacity: isEventOver(startDate, endDate, endTime) ? 0.6 : 1,
                   border: "1px solid rgba(0, 0, 0, 0.18)",
               }}>
            {showProductName && productName && (
                <Typography variant="button"
                            color="primary"><strong>{productName}</strong></Typography>
            )}
            <Grid container
                  spacing={1}>

                {name && (
                    <Grid item>
                        <Typography variant="caption"><strong>{name}</strong></Typography>
                    </Grid>
                )}
                {timeCaption && (
                    <Grid item>
                        <Typography variant="caption">{timeCaption}</Typography>
                    </Grid>
                )}
            </Grid>

            {isEventOver(startDate, endDate, endTime)
             ? <Typography variant="overline"
                           style={{padding: 4, backgroundColor: "lightgray"}}>
                 Arrangementet er over
             </Typography>
             : getLinearProgress()
            }
            <Grid container
                  spacing={1}>
                <Grid item>
                    <Typography variant="overline"><strong>Solgt: </strong>{ticketsSold || 0}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="overline"><strong>Reservert: </strong>{ticketsReserved || 0}</Typography>
                </Grid>
                {saleLimit
                 ? <Grid item>
                     <Typography variant="overline">
                         <strong>Ledig: </strong>{saleLimit - ticketsReserved - ticketsSold}
                     </Typography>
                 </Grid>
                 : null
                }
            </Grid>
        </Paper>
    )
}

export default TicketInformation