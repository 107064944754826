import React from "react"
import {Typography} from "@mui/material"

const ProductGroupsSettingsHelperText = () => {
    return (
        <>
            <Typography variant="subtitle2">
                Alle priser må være knyttet til en <em>priskategori</em>, som igjen er knyttet til
                en <em>varegruppe</em>.
            </Typography>
            <br/>
            <Typography variant="body2">
                Både priskategorier og varegrupper er <em>felles for alle museene i gjeldende konsolidering</em>.
                <br/><br/>
                For øyeblikket er det kun mulig å sette merverdigavgift på varegruppe, men på sikt vil innstillinger
                relatert til regnskapssystemer være tilgjengelig.
                <br/><br/>
                Merverdigavgift er knyttet til varegruppe, som igjen er knyttet til en eller flere
                priskategorier. I produkteditoren er det kun mulig å velge forhåndsdefinerte
                priskategorier (og dermed varegruppe og MVA indirekte). Endringer på MVA og varegruppe
                må gjøres her.
            </Typography>
        </>
    )
}

export default ProductGroupsSettingsHelperText