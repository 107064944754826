import React, {useEffect, useState} from "react"
import "tippy.js/dist/tippy.css"
import ScrollableSectionView from "../../../app/ScrollableSectionView"
import SectionHeader from "../../../app/components/SectionHeader"
import {ENTRANCE_BASE_URL} from "../../../app/Routes"
import {useAxiosBpn} from "../../../app/axios"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import {useEntranceTranslation} from "../entranceTranslationContext"
import {useAuthsState} from "../../../app/auths/authsContext"
import Calendar from "../../../app/components/Calendar"
import {getUnixTime} from "date-fns"
import {getStartAndEndOfMonth} from "../../../app/helper-functions/dateFormat"

export const EntranceCalendar = () => {
    const t = useEntranceTranslation()
    const {umbrellaAndChildrenIds} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()

    const [timeInterval, setTimeInterval] = useState(getStartAndEndOfMonth())

    const breadcrumbs = [
        {label: t("entrance"), url: ENTRANCE_BASE_URL},
        {label: t("calendar")},
    ]

    const [scheduleEvents, setScheduleEvents] = useState([])
    const [{data, loading}, getScheduleEvents] = useAxiosBpn({
        url: "schedule_events",
        params: {
            museumIds: umbrellaAndChildrenIds?.length ? umbrellaAndChildrenIds.join() : Array.from(userAccessMuseumIds.keys()).join(),
            after: getUnixTime(timeInterval?.start) * 1000,
            before: getUnixTime(timeInterval?.end) * 1000,
            expandRecurring: true,
            sortRecurring: true,
            productType: "entrance_ticket",
        },
    })

    useEffect(() => {
        if (data) {
            setScheduleEvents(data.scheduleEvents)
        }
    }, [data])

    useEffect(() => {
        getScheduleEvents().catch(() => {
            // Just to avoid annoying console error
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}/>
            <ScrollableSectionView>
                {loading ? <ProgressIndicator/> : <Calendar scheduleEvents={scheduleEvents}
                                                            timeInterval={timeInterval}
                                                            setTimeInterval={setTimeInterval}/>}
            </ScrollableSectionView>
        </>
    )
}