import Grid from "@mui/material/Grid"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import {useSeasonalPassesTranslation} from "./membershipsTranslationContext"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {useAuthsState} from "../../app/auths/authsContext"
import {DAILY} from "../../app/helper-functions/dateFormat"
import {GROUP_BY_PRODUCT_NAME, GROUP_BY_PRODUCT_TYPE} from "../../app/components/OrderSumsGroupBySelectMenu"
import {useAxiosBpn} from "../../app/axios"
import {PRODUCT_TYPE_SEASONAL_PASS} from "../../app/baseContext"
import {SEASONAL_PASSES_BASE_URL} from "../../app/Routes"
import {subDays} from "date-fns"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import SmallRevenueCard from "../../app/revenue/SmallRevenueCard"
import TestOrdersSwitch from "../../app/components/TestOrdersSwitch"
import {useTheme} from "@mui/material"
import {ChartCardAvatar} from "../../app/components/avatars"

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    grid: {
        paddingTop: theme.spacing(2),
    },
}))

const LOCAL_STORAGE_KEY = "bpn.testOrders"
const NO_OF_DAYS = 10

export const SeasonalPassOverview = () => {
    const classes = useStyles()
    const t = useSeasonalPassesTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()

    const {umbrellaAndChildrenIds} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const [frequency] = useState(DAILY)
    const [groupBy] = useState(GROUP_BY_PRODUCT_NAME)
    const [createdAfter] = useState(subDays(new Date(), NO_OF_DAYS).getTime())

    const [{data: orderSumsData, loading: loadingOrderSums}] = useAxiosBpn({
        url: "orders/sums",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                       ? umbrellaAndChildrenIds.join()
                       : Array.from(userAccessMuseumIds.keys()).join(),
            groupBy: groupBy,
            groupByDateFrequency: frequency,
            test: testOrders,
            createdAfter: createdAfter,
            productType: PRODUCT_TYPE_SEASONAL_PASS,
        },
    })

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    const breadcrumbs = [
        {label: t("seasonalPasses"), url: SEASONAL_PASSES_BASE_URL},
        {label: t("overview")},
    ]

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <ScrollableSectionView>
                <Grid container
                      className={classes.grid}
                      justifyContent="space-between"
                      alignItems="stretch"
                      spacing={2}>
                    <Grid item
                          xs={12}
                          xl={6}>
                        <SmallRevenueCard data={orderSumsData}
                                          loading={loadingOrderSums}
                                          frequency={frequency}
                                          groupBy={groupBy}
                                          shortcutTo={{
                                              label: "Gå til hovedside for omsetning (data gruppert på produkttype)",
                                              url: `/sales/revenue?groupBy=${GROUP_BY_PRODUCT_TYPE}`
                                          }}
                                          testOrders={testOrders}
                                          title={t("revenue")}
                                          subheader="Pr. sesongkort siste ti dager"
                                          avatar={<ChartCardAvatar backgroundColor={testOrders
                                                                                    ? testOrdersPalette?.dark
                                                                                    : null}/>}/>
                    </Grid>
                </Grid>
            </ScrollableSectionView>
        </>
    )
}