import React from "react"
import {Tooltip} from "@mui/material"
import {Settings} from "@mui/icons-material"
import IconButton from "@mui/material/IconButton"
import {useBaseTranslation} from "../baseTranslationContext"
import {useHistory} from "react-router-dom"

const ButtonWithPushSettingsUrl = ({settingsUrl}) => {
    const t = useBaseTranslation()
    const history = useHistory()

    return (
        <IconButton onClick={() => history.push(settingsUrl)} size="large">
            <Tooltip title={t("goToSettings")}>
                <Settings/>
            </Tooltip>
        </IconButton>
    );
}

export default ButtonWithPushSettingsUrl