import React from "react"
import {Avatar, Card, CardContent, CardHeader, useTheme} from "@mui/material"
import {toLowerCase} from "../helper-functions/stringUtility"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"

const DashboardCard = ({
                           title,
                           subheader,
                           avatar,
                           action,
                           height,
                           maxHeight,
                           cardContentStyle,
                           titleTypographyProps,
                           testOrders,
                           hideTestOrdersLabel,
                           loading,
                           style = {},
                           linearProgress = true,
                           children,
                       }) => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()

    const getTitle = () => {
        const _title = <span>{title}<strong>{testOrders && !hideTestOrdersLabel
                                             ? ` (${toLowerCase(t("testOrders"))})`
                                             : ""}</strong></span>
        if (title) return _title
    }

    const getAvatar = () => (
        <Avatar style={{backgroundColor: testOrders ? testOrdersPalette?.dark : null}}>
            {avatar}
        </Avatar>
    )

    return (
        <Card style={{
            backgroundColor: testOrders ? testOrdersPalette?.light : null,
            height: height,
            maxHeight: maxHeight,
            ...style
        }}>
            {(title || avatar || action) && <CardHeader title={getTitle()}
                                                        subheader={subheader}
                                                        titleTypographyProps={titleTypographyProps}
                                                        avatar={avatar ? getAvatar() : null}
                                                        action={action}/>}
            <CardContent style={cardContentStyle}>
                <div style={{
                    backgroundColor: testOrders ? "white" : null,
                    padding: 8,
                    paddingRight: 16,
                    height: height,
                }}>
                    {loading
                     ? <ProgressIndicator linear={linearProgress}/>
                     : children}
                </div>
            </CardContent>
        </Card>
    )
}

export default DashboardCard
