import React, {useState} from "react";
import {useBaseTranslation} from "../../app/baseTranslationContext";
import SectionHeader from "../../app/components/SectionHeader";
import {JOINT_PRODUCTS_DISCOUNT_BASE_URL} from "../../app/Routes";
import {Grid, useMediaQuery} from "@mui/material";
import JointProductsDiscountsList from "./JointProductsDiscountsList";
import JointProductsDiscountDetails from "./JointProductsDiscountDetails";
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext";
import {useTheme} from "@mui/material/styles";

const newDiscount = {
    new: true,
    museumId: null,
    name: "",
    percent: 0,
    description: "",
}

export const JointProductsDiscounts = () => {
    const t = useBaseTranslation()
    const {umbrellaName} = useMuseumSelectorState()
    const {breakpoints} = useTheme()
    const lgUp = useMediaQuery(breakpoints.up("lg"))

    const [selectedDiscount, setSelectedDiscount] = useState({})
    const [refetch, setRefetch] = useState(false)

    const breadcrumbs = [
        {label: t("jointProductsDiscounts"), url: JOINT_PRODUCTS_DISCOUNT_BASE_URL},
        {label: umbrellaName},
    ]

    const refetchAndDeselect = () => {
        setRefetch(!refetch)
        setSelectedDiscount({})
    }

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}/>
            <Grid container spacing={1} style={{height: `calc(100% - 108px)`}} wrap={lgUp ? "nowrap" : "wrap"}>
                <Grid item xs={12} md={5} lg={4} xl={3}>
                    <JointProductsDiscountsList handleNewDiscountClick={() => setSelectedDiscount(newDiscount)}
                                                handleDiscountSelect={(discount) => setSelectedDiscount(discount)}
                                                selectedDiscountId={selectedDiscount?.id}
                                                refetch={refetch}
                                                refetchAndDeselect={refetchAndDeselect}/>
                </Grid>
                <Grid item xs style={{height: "100%"}}>
                    <JointProductsDiscountDetails selectedDiscount={selectedDiscount}
                                                  setSelectedDiscount={setSelectedDiscount}
                                                  refetch={() => setRefetch(!refetch)}
                                                  handleClose={() => setSelectedDiscount({})}/>
                </Grid>
            </Grid>
        </>
    )
}

