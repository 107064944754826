import React, {useEffect, useState} from "react"
import {Grid} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {createOrderTableHeaders, createOrderTableRows} from "./orderTableHelpers"
import GenericTable from "../generic-table/GenericTable"

export const OrderTableMini = ({orders}) => {
    const t = useBaseTranslation()

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        if (orders) {
            const _columns = createOrderTableHeaders(t, false, false, false, false, true)
            setColumns(_columns)
            const _rows = createOrderTableRows(orders, t, false, true)
            setRows(_rows)
        }
    }, [orders, t])

    return (
        <>
            <Grid container
                  direction="column"
                  spacing={1}>
                <Grid item
                      style={{width: "100%"}}>
                    <GenericTable columns={columns}
                                  rows={rows}
                                  tableHeight={500}
                                  collapsible={true}
                                  size="small"/>
                </Grid>
            </Grid>
        </>

    )
}
