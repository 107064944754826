import React, {useEffect, useState} from "react"
import {Grid, IconButton, TextField, Tooltip} from "@mui/material"
import _ from "lodash/fp"
import SaveIcon from "@mui/icons-material/Save"
import {Cancel} from "@mui/icons-material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const RubicInputFields = ({museumSettings, handleSaveRubicSettings, isUmbrellaFields, readonly}) => {
    const t = useBaseTranslation()
    const [rubicApiUrl, setRubicApiUrl] = useState("")
    const [initRubicApiUrl, setInitRubicApiUrl] = useState("")

    useEffect(() => {
        if (museumSettings) {
            setRubicApiUrl(museumSettings?.rubicApiUrl)
            setInitRubicApiUrl(museumSettings?.rubicApiUrl)
        }
    }, [museumSettings])

    useEffect(() => {
        if (readonly) {
            setRubicApiUrl("")
        }
    }, [readonly])

    return (
        <Grid container
              spacing={3}
              direction="column">
            <Grid container
                  item
                  spacing={1}
                  xs={12}>

                <Grid item
                      xs>
                    <TextField variant="outlined"
                               size="small"
                               fullWidth
                               value={rubicApiUrl || ""}
                               disabled={readonly}
                               onChange={e => setRubicApiUrl(e.target.value.trim())}
                               helperText={isUmbrellaFields ?
                                           t("termsOfSaleCommonUrlHelperText") :
                                           t("mailchimpMuseumHelperText")}
                               label={`Rubic API URL`}/>
                </Grid>

                <Grid item>
                    <Tooltip title={t("cancel")}>
                            <span>
                                <IconButton
                                    disabled={_.isEqual(initRubicApiUrl, rubicApiUrl) || readonly}
                                    onClick={() => {
                                        setRubicApiUrl(initRubicApiUrl)
                                    }}
                                    size="large">
                                    <Cancel/>
                                </IconButton>
                            </span>
                    </Tooltip>
                    <Tooltip title={t("save")}>
                            <span>
                                <IconButton
                                    disabled={_.isEqual(initRubicApiUrl, rubicApiUrl) && readonly}
                                    onClick={() => handleSaveRubicSettings(rubicApiUrl, isUmbrellaFields)}
                                    size="large">
                                    <SaveIcon/>
                                </IconButton>
                            </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RubicInputFields