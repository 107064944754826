import React, {useEffect, useState} from "react"
import {Grid, IconButton, TextField, Tooltip} from "@mui/material"
import _ from "lodash/fp"
import SaveIcon from "@mui/icons-material/Save"
import {Cancel} from "@mui/icons-material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const GoogleTagManagerFields = ({museumSettings, handleSaveGoogleTagManagerSettings, readonly}) => {
    const t = useBaseTranslation()
    const [gtmId, setGtmId] = useState("")

    useEffect(() => {
        if (museumSettings) {
            setGtmId(museumSettings?.gtmId)
        }
    }, [museumSettings])


    return (
        <Grid container
              spacing={3}
              direction="column">
            <Grid container
                  item
                  spacing={1}
                  xs={12}>

                <Grid item
                      xs>
                    <TextField variant="outlined"
                               style={{marginBottom: "1rem"}}
                               size="small"
                               fullWidth
                               value={gtmId || ""}
                               disabled={readonly}
                               onChange={e => setGtmId(e.target.value)}
                               label={`Google tag manager container id`}/>
                </Grid>

                <Grid item>
                    <Tooltip title={t("cancel")}>
                            <span>
                                <IconButton
                                    disabled={_.isEqual(museumSettings?.gtmId, gtmId)}
                                    onClick={() => setGtmId(museumSettings?.gtmId)}
                                    size="large">
                                    <Cancel/>
                                </IconButton>
                            </span>
                    </Tooltip>
                    <Tooltip title={t("save")}>
                            <span>
                                <IconButton
                                    disabled={_.isEqual(museumSettings?.gtmId, gtmId)}
                                    onClick={() => handleSaveGoogleTagManagerSettings(gtmId)}
                                    size="large">
                                    <SaveIcon/>
                                </IconButton>
                            </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default GoogleTagManagerFields
