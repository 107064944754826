import React, {useEffect, useState} from "react"
import {FormControlLabel, Grid, Switch} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {lowerFirst} from "lodash"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import Typography from "@mui/material/Typography"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import TermsOfSaleUrlInputField from "./TermsOfSaleUrlInputField"
import _ from "lodash/fp"
import SaveButtonWithProgressAndLabel from "../../../app/components/SaveButtonWithProgressAndLabel"

const TermsOfSaleUrl = ({umbrellaSettings, museumSettings, getMuseumSettings}) => {
    const t = useBaseTranslation()
    const {selectedMuseum, umbrellaId, umbrellaName} = useMuseumSelectorState()
    const [_umbrellaUrl, setUmbrellaUrl] = useState()
    const [_umbrellaUrlEng, setUmbrellaUrlEng] = useState()
    const [_museumUrl, setMuseumUrl] = useState()
    const [_museumUrlEng, setMuseumUrlEng] = useState()
    const [overrideSetting, setOverrideSetting] = useState(false)
    const snackbar = useCustomSnackbar()

    const [
        {
            data: umbrellaPutData,
            loading: umbrellaPutLoading,
            error: umbrellaPutError,
        }, putUmbrellaMuseumSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    const [
        {
            data: museumPutData,
            loading: museumPutLoading,
            error: museumPutError,
        }, putMuseumSettings,
    ] = useAxiosBpn({method: "PUT"}, {manual: true})

    useEffect(() => {
        setMuseumUrl(museumSettings?.termsOfSaleUrl)
        setMuseumUrlEng(museumSettings?.termsOfSaleUrlEn)
        setUmbrellaUrl(umbrellaSettings?.termsOfSaleUrl)
        setUmbrellaUrlEng(umbrellaSettings?.termsOfSaleUrlEn)
    }, [umbrellaSettings, museumSettings])

    useEffect(() => {
        if (museumSettings) {
            setOverrideSetting(!!museumSettings.termsOfSaleUrl)
        }
    }, [museumSettings])

    useEffect(() => {
        if (umbrellaPutData) {
            snackbar.showSuccess(`${t("commonUrlFor")} ${umbrellaName} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
        if (museumPutData) {
            snackbar.showSuccess(`URL for ${selectedMuseum?.name} ${lowerFirst(t("updated"))}`)
            getMuseumSettings()
        }
    }, [umbrellaPutData, selectedMuseum, museumPutData, t, umbrellaName, snackbar, getMuseumSettings])

    useEffect(() => {
        if (umbrellaPutError) {
            snackbar.showError(t(umbrellaPutError.message))
        }
        if (museumPutError) {
            snackbar.showError(t(museumPutError.message))
        }
    }, [umbrellaPutError, museumPutError, t, snackbar])

    const handleUmbrellaUrlSave = () => {
        const id = umbrellaSettings?.museumId ? umbrellaSettings.museumId : umbrellaId
        putUmbrellaMuseumSettings({
            url: `museum_settings/${id}`,
            data: {
                ...umbrellaSettings,
                museumName: umbrellaName,
                termsOfSaleUrl: _umbrellaUrl,
                termsOfSaleUrlEn: _umbrellaUrlEng,
            },
        })
    }

    const handleMuseumUrlSave = () => {
        const id = museumSettings?.museumId ? museumSettings.museumId : selectedMuseum?.id
        putMuseumSettings({
            url: `museum_settings/${id}`,
            data: {
                ...museumSettings,
                museumName: selectedMuseum?.name,
                termsOfSaleUrl: _museumUrl,
                termsOfSaleUrlEn: _museumUrlEng,
            },
        })
    }

    const handleOverrideSettingChange = () => {
        if (overrideSetting) {
            setMuseumUrl()
            setMuseumUrlEng()
            setOverrideSetting(false)
        } else {
            setOverrideSetting(true)
        }
    }

    function getLabel() {
        return (
            <>
                <Typography>{t("ownUrlForMuseum")}</Typography>
                <Typography variant="subtitle1"
                            color="textSecondary">{t("ownUrlForMuseumHelperText")}</Typography>
            </>
        )
    }

    return (
        <>
            <Grid container
                  spacing={3}
                  direction="column">
                {umbrellaPutLoading
                 ? <ProgressIndicator/>
                 :
                 <>
                     <TermsOfSaleUrlInputField value={_umbrellaUrl}
                                               onChange={e => setUmbrellaUrl(e.target.value)}
                                               helperText={t("termsOfSaleCommonUrlHelperText")}
                                               label={`${t("commonUrlFor")} ${umbrellaName}`}
                                               disabled={false}
                                               initialValue={umbrellaSettings?.termsOfSaleUrl}
                                               onCancel={() => setUmbrellaUrl(umbrellaSettings?.termsOfSaleUrl)}/>
                     <TermsOfSaleUrlInputField value={_umbrellaUrlEng}
                                               onChange={e => setUmbrellaUrlEng(e.target.value)}
                                               helperText={""}
                                               label={t("urlForEnglishTermsAndConditions")}
                                               disabled={false}
                                               initialValue={umbrellaSettings?.termsOfSaleUrlEn}
                                               onCancel={() => setUmbrellaUrlEng(umbrellaSettings?.termsOfSaleUrlEn)}/>
                     <SaveButtonWithProgressAndLabel handleSave={handleUmbrellaUrlSave}
                                                     loading={umbrellaPutLoading}
                                                     unsavedChanges={!_.isEqual(umbrellaSettings?.termsOfSaleUrl,
                                                       _umbrellaUrl) || !_.isEqual(umbrellaSettings?.termsOfSaleUrlEn,
                                                         _umbrellaUrlEng)}/>
                 </>
                }

                {selectedMuseum?.type === "museum" && (
                    museumPutLoading
                    ? <ProgressIndicator/>
                    : <>
                        <Grid item>
                            <FormControlLabel label={getLabel()}
                                              control={
                                                  <Switch checked={overrideSetting}
                                                          onChange={handleOverrideSettingChange}/>
                                              }/>
                        </Grid>
                        <TermsOfSaleUrlInputField value={_museumUrl}
                                                  onChange={e => setMuseumUrl(e.target.value)}
                                                  helperText={t("termsOfSaleMuseumUrlHelperText")}
                                                  label={`URL for ${selectedMuseum?.name}`}
                                                  disabled={!overrideSetting}
                                                  initialValue={museumSettings?.termsOfSaleUrl}
                                                  onCancel={() => setMuseumUrl(museumSettings?.termsOfSaleUrl)}/>
                        <TermsOfSaleUrlInputField value={_museumUrlEng}
                                                  onChange={e => setMuseumUrlEng(e.target.value)}
                                                  helperText={""}
                                                  label={t("urlForEnglishTermsAndConditions")}
                                                  disabled={!overrideSetting}
                                                  initialValue={museumSettings?.termsOfSaleUrlEn}
                                                  onCancel={() => setMuseumUrlEng(museumSettings?.termsOfSaleUrlEn)}/>
                        <SaveButtonWithProgressAndLabel handleSave={handleMuseumUrlSave}
                                                        loading={museumPutLoading}
                                                        unsavedChanges={!_.isEqual(museumSettings?.termsOfSaleUrlEn,
                                                            _museumUrlEng) || !_.isEqual(museumSettings?.termsOfSaleUrl,
                                                            _museumUrl)}/>
                    </>
                )}
            </Grid>
        </>
    )
}

export default TermsOfSaleUrl
