import React, {useEffect, useState} from "react"
import {Card, CardContent, CardHeader, Chip, Grid, Link, Typography} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {dateFormat} from "../helper-functions/dateFormat"
import {GridLabel, GridValue} from "./gridComponents"
import {lowerFirst} from "lodash"
import {useAxiosBpn} from "../axios"
import useCustomSnackbar from "../components/useCustomSnackbar"
import {ButtonWithProgress} from "../components/ButtonWithProgress"
import SendIcon from "@mui/icons-material/Send"
import OrderDetailsDialogTicketDetails from "./OrderDetailsDialogTicketDetails"
import {isConfirmed} from "../helper-functions/orderStatuses"

const OrderDetailsDialogReceiptDetails = ({order, loading, refetchOrder}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()
    const [gridRows, setGridRows] = useState([])

    const [
        {
            data: resendReceiptData,
            loading: resendReceiptLoading,
            error: resendReceiptError,
        }, resendReceipt,
    ] = useAxiosBpn({
        url: `orders/${order.id}/resend_receipt`,
        method: "PUT",
    }, {manual: true})

    const [portalLink, setPortalLink] = useState()

    useEffect(
        () => {
            setPortalLink(`${window._env_.REACT_APP_BPN_PORTAL}kvittering/${order.externalId}`)
        },
        [order.externalId])

    useEffect(() => {
        if (resendReceiptData) {
            const {emailResponse} = resendReceiptData

            if (emailResponse?.status === "OK") {
                snackbar.showSuccess(`${t("orderConfirmation")} ${lowerFirst(t("sent"))} ${lowerFirst(t("afresh"))}`)
            } else {
                snackbar.showError(emailResponse?.text)
            }
            refetchOrder()
        }
    }, [refetchOrder, resendReceiptData, snackbar, t])

    useEffect(() => {
        if (order) {
            const {emailReceiptSentAt, status} = order

            setGridRows([
                {label: t("sent"), value: dateFormat(emailReceiptSentAt, "PPPp") || t("never")},
                {
                    label: `${t("send")} ${lowerFirst(t("email"))}${emailReceiptSentAt
                                                                    ? " " + lowerFirst(t("afresh"))
                                                                    : ""}`,
                    value:
                        <div style={{display: "flex", alignItems: "center"}}>
                            <ButtonWithProgress label={t("send")}
                                                onClick={resendReceipt}
                                                startIcon={<SendIcon/>}
                                                variant="text"
                                                color="primary"
                                                disabled={!isConfirmed(status) || !hasEmail(order)}
                                                loading={resendReceiptLoading}
                                                size="small"/>
                            {!isConfirmed(status) &&
                            <Typography variant="caption"
                                        color="textSecondary"
                                        style={{marginLeft: 8}}>
                                {t("orderIsNotConfirmed")}
                            </Typography>
                            }
                            {!hasEmail(order) && isConfirmed(status) &&
                            <Typography variant="caption"
                                        color="textSecondary"
                                        style={{marginLeft: 8}}>
                                {t("noEmailAddressRegistered")}
                            </Typography>
                            }
                        </div>,
                },
                {
                    label: t("receiptPage"),
                    value: isConfirmed(status) && portalLink
                           ? <Link href={portalLink}
                                   target="portal">
                               {portalLink}
                           </Link>
                           : null,
                },
            ])
        }
    }, [order, resendReceipt, resendReceiptLoading, t, portalLink])

    useEffect(() => {
        if (resendReceiptError) {
            snackbar.showError(resendReceiptError.message)
        }
    }, [resendReceiptError, snackbar])

    const hasEmail = (_order) => _order?.customer?.email

    const getTitle = () => {
        const {emailReceiptSentAt} = order

        return (
            <Grid container
                  spacing={1}>
                <Grid item>
                    {t("orderConfirmation")}
                </Grid>
                <Grid item>
                    <Chip label={t(emailReceiptSentAt ? "sent" : "notSent")}
                          color={emailReceiptSentAt ? "primary" : "default"}
                          size="small"/>
                </Grid>
            </Grid>)
    }

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined">
               <CardHeader title={getTitle()}
                           titleTypographyProps={{color: "primary"}}
               />
               <CardContent>
                   <Grid container
                         direction="column">
                       {gridRows?.map(({label, value}, index) =>
                           value &&
                           <Grid container
                                 key={index}
                                 alignItems="center">
                               <GridLabel>{label && `${label}:`}</GridLabel>
                               <GridValue>{value}</GridValue>
                           </Grid>,
                       )}
                       <OrderDetailsDialogTicketDetails order={order}/>
                   </Grid>
               </CardContent>
           </Card>
}

export default OrderDetailsDialogReceiptDetails
