import React from "react"
import makeStyles from '@mui/styles/makeStyles';
import {useBaseTranslation} from "../../app/baseTranslationContext"
import {Grid, InputAdornment} from "@mui/material"
import TextField from "@mui/material/TextField"
import TranslatableTextField from "../../app/product-editor/TranslatableTextField";

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const JointProductsDiscountDetailsForm = ({data, unsavedChanges, handleChange, handleTranslationChange}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    const isInvalidDiscount = (discount) => {
        const discountAsNumber = parseInt(discount)
        return !(discountAsNumber && discountAsNumber > 0 && discountAsNumber <= 100)
    }

    return (
        <form noValidate
              autoComplete="off">
            <Grid container
                  spacing={2}
                  justifyContent="space-between">
                <Grid item xs={12} sm>
                  <TranslatableTextField label={t("name")}
                                         required={true}
                                         error={!data?.name && unsavedChanges}
                                         name="name"
                                         value={data?.name || ""}
                                         alwaysShowTranslationFields={true}
                                         onChange={(updatedValue) => handleTranslationChange("name", updatedValue)} />
                </Grid>
                <Grid item>
                    <TextField label={t("discount")}
                               className={classes.textfield}
                               required={true}
                               error={unsavedChanges && isInvalidDiscount(data?.percent)}
                               name={"percent"}
                               fullWidth
                               type="number"
                               InputProps={{
                                   inputProps: {
                                       max: 100, min: 1,
                                   },
                                   endAdornment: <InputAdornment position="end">%</InputAdornment>,
                               }}
                               value={data?.percent || ""}
                               onChange={handleChange}/>
                </Grid>
            </Grid>

          <TranslatableTextField label={t("description")}
                                 required={true}
                                 error={!data?.description && unsavedChanges}
                                 name="name"
                                 multiline={true}
                                 alwaysShowTranslationFields={true}
                                 value={data?.description || ""}
                                 onChange={(updatedValue) => handleTranslationChange("description", updatedValue)} />
        </form>
    )
}

export default JointProductsDiscountDetailsForm
