import React, {useEffect, useState} from "react"
import {Card, CardContent, CardHeader, Chip, Divider, Grid} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {dateFormat} from "../helper-functions/dateFormat"
import {GridLabel, GridValue} from "../order-details/gridComponents"
import {parse} from "date-fns"
import {toLowerCase} from "../helper-functions/stringUtility"

const VoucherGeneralDetails = ({
                                   voucher,
                                   loading,
                               }) => {

    const t = useBaseTranslation()

    const [gridRows, setGridRows] = useState([])

    useEffect(() => {
        if (voucher) {
            const {
                id,
                externalId,
                createdAt,
                createdBy,
                status,
                exportedAt,
                exportedBy,
                parentMuseumId,
                parentMuseumName,
                museumId,
                museumName,
                departmentId,
                departmentName,
                type,
                date,
                paymentSubtype,
                paymentType,
                productGroup,
                productGroupId,
            } = voucher

            setGridRows([
                {label: t("created"), value: dateFormat(createdAt, "PPPp")},
                {label: t("createdBy"), value: createdBy},
                {label: t("internalId"), value: id},
                {label: t("externalId"), value: externalId},
                {label: t("status"), value: t(status)},
                {label: t("exportedAt"), value: dateFormat(exportedAt, "PPPp")},
                {label: t("exportedBy"), value: exportedBy},
                {divider: true},
                {
                    label: `${t("mainMuseum")} (${toLowerCase(t("id"))})`,
                    value: `${parentMuseumName} (${parentMuseumId})`,
                },
                {label: `${t("museum")} (${toLowerCase(t("id"))})`, value: `${museumName} (${museumId})`},
                {
                    label: `${t("department")} (${toLowerCase(t("id"))})`,
                    value: departmentName ? `${departmentName} (${departmentId})` : t("none"),
                },
                {divider: true},
                {label: t("type"), value: t(type)},
                {label: t("date"), value: dateFormat(parse(date, "yyyy-MM-dd", new Date()), "P")},
                {label: t("productGroupId"), value: `${productGroup} (${productGroupId})`},
                {label: t("paymentType"), value: t(paymentType)},
                {label: t("cardType"), value: t(paymentSubtype)},

            ])
        }

    }, [t, voucher])

    const getTitle = () => (
        <Grid container
              spacing={1}>
            <Grid item>
                <span>{`${t("voucher")} ${voucher?.id}`}<strong>{voucher?.test
                                                                 ? ` (test)`
                                                                 : ""}</strong></span>
            </Grid>
            <Grid item>
                <Chip label={t(voucher?.status)}
                      color="primary"
                      size="small"/>
            </Grid>
            <Grid item>
                <Chip label={dateFormat(parse(voucher?.date, "yyyy-MM-dd", new Date()), "do LLL")}
                      size="small"/>
            </Grid>
            <Grid item>
                <Chip label={voucher?.productGroup}
                      size="small"/>
            </Grid>
            <Grid item>
                <Chip label={t(voucher?.paymentSubtype)}
                      size="small"/>
            </Grid>
        </Grid>)

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined">
               <CardHeader title={getTitle()}
                           titleTypographyProps={{color: "primary"}}/>
               <CardContent>
                   <Grid container
                         direction="column">
                       {gridRows?.map(({label, value, divider}, index) =>
                           divider
                           ? <Divider key={index}
                                      style={{marginTop: 8, marginBottom: 8}}/>
                           : value &&
                               <Grid container
                                     key={index}
                                     alignItems="center">
                                   <GridLabel>{label}:</GridLabel>
                                   <GridValue>{value}</GridValue>
                               </Grid>,
                       )}
                   </Grid>
               </CardContent>
           </Card>
}

export default VoucherGeneralDetails
