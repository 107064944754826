import React, {useEffect, useState} from "react"
import {useBaseContextState} from "../../app/baseContext"
import SectionHeader from "../../app/components/SectionHeader"
import PriceCategories from "./price-categories/PriceCategories"
import ProductGroups from "./product-groups/ProductGroups"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import EditorCardModule from "../../app/components/EditorCardModule"
import {useAxiosBpn} from "../../app/axios"
import {ProgressIndicator} from "../../app/ProgressIndicator"
import ProductGroup from "./product-groups/ProductGroup"
import PriceCategoryTable from "./price-categories/PriceCategoryTable"
import {useTheme} from "@mui/material/styles"
import ProductGroupsSettingsHelperText from "./product-groups/ProductGroupsSettingsHelperText"
import PriceCategoriesSettingsHelperText from "./price-categories/PriceCategoriesSettingsHelperText"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import EditOrCreatePriceCategoryDialog from "./EditOrCreatePriceCategoryDialog"
import _ from "lodash"
import DiscountCodes from "./discount-codes/DiscountCodes"
import DiscountCode from "./discount-codes/DiscountCode"
import DiscountCodesHelperText from "./discount-codes/DiscountCodesHelperText"
import FamilyDiscounts from "./family-discounts/FamilyDiscounts"
import FamilyDiscountsTable from "./family-discounts/FamilyDiscountsTable"
import EditOrCreateFamilyDiscountDialog from "./EditOrCreateFamilyDiscountDialog"
import FamilyDiscountsHelperText from "./family-discounts/FamilyDiscountsHelperText";
import UniqueSellingPointsHelperText from "./unique-selling-points/UniqueSellingPointsHelperText";
import UniqueSellingPoints from "./unique-selling-points/UniqueSellingPoints";
import EditOrCreateUniqueSellingPointDialog from "./unique-selling-points/EditOrCreateUniqueSellingPointDialog";
import UniqueSellingPointsTable from "./unique-selling-points/UniqueSellingPointTable";

const ProductsSettings = ({
                              showSectionHeader = true,
                              showDiscountCodeModule = true,
                              showProductGroupModule = true,
                              showPriceCategoryModule = true,
                              showUniqueSellingPointsModule = true,
                              showFamilyDiscountModule = true,
                          }) => {
    const {spacing} = useTheme()
    const t = useBaseTranslation()
    const {currentBaseUrl} = useBaseContextState()
    const {umbrellaName, umbrellaId} = useMuseumSelectorState()
    const [activeBreadcrumb, setActiveBreadcrumb] = useState(t("products"))
    const [uniqueSellingPoints, setUniqueSellingPoints] = useState([])
    const [productGroups, setProductGroups] = useState([])
    const [discountCodes, setDiscountCodes] = useState([])
    const [familyDiscounts, setFamilyDiscounts] = useState([])
    const [familyDiscountToEdit, setFamilyDiscountToEdit] = useState(null)
    const [editCreateFamilyDiscountDialogOpen, setEditCreateFamilyDiscountDialogOpen] = useState(false)
    const [priceCategoryToEdit, setPriceCategoryToEdit] = useState(null)
    const [priceCategories, setPriceCategories] = useState([])
    const [editCreatePriceCategoryDialogOpen, setEditCreatePriceCategoryDialogOpen] = useState(false)
    const [editCreateUniqueSellingPointDialogOpen, setEditCreateUniqueSellingPointDialogOpen] = useState(false)
    const [uniqueSellingPointToEdit, setUniqueSellingPointToEdit] = useState(null)


    const [{data: productGroupsData, loading: productGroupsLoading}, getProductGroups] = useAxiosBpn({
        url: "product_groups",
        params: {
            museumId: umbrellaId,
        },
    })

    const [{data: uniqueSellingPointsData, loading: uniqueSellingPointsLoading}, getUniqueSellingPoints] = useAxiosBpn({
        url: `unique_selling_points/${umbrellaId}`,
    })

    const [{data: priceCategoriesData, loading: priceCategoriesLoading}, getPriceCategories] = useAxiosBpn({
        url: "price_categories",
        params: {
            museumId: umbrellaId,
        },
    })

    const [{data: familyDiscountsData, loading: familyDiscountsLoading}, getFamilyDiscounts] = useAxiosBpn({
        url: `family_discounts/museums/${umbrellaId}`,
    })

    const [{data: discountCodesData, loading: discountCodesLoading}, getDiscountCodes] = useAxiosBpn({
        url: "discount_codes",
        params: {
            museumId: umbrellaId,
        },
    })

    useEffect(() => {
        getPriceCategories().catch(() => {
        })
        getProductGroups().catch(() => {
        })
        getDiscountCodes().catch(() => {
        })
        getFamilyDiscounts().catch(() => {
        })
        getUniqueSellingPoints().catch(() => {
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (productGroupsData) setProductGroups(productGroupsData.productGroups)
    }, [productGroupsData])

    useEffect(() => {
        if (familyDiscountsData) setFamilyDiscounts(familyDiscountsData.familyDiscounts)
    }, [familyDiscountsData])

    useEffect(() => {
        if (discountCodesData) setDiscountCodes(discountCodesData.discountCodes)
    }, [discountCodesData])

    useEffect(() => {
        if (uniqueSellingPointsData) setUniqueSellingPoints(uniqueSellingPointsData.uniqueSellingPoints)
    }, [uniqueSellingPointsData])

    useEffect(() => {
        if (priceCategoriesData) {
            const convertedPriceCategories = priceCategoriesData.priceCategories.map(priceCategory => {
                    let tempPriceCategory = {...priceCategory}
                    if (tempPriceCategory.groupTicket?.length > 0) {
                        const parsedGroupTicketConf = JSON.parse(tempPriceCategory.groupTicket)
                        tempPriceCategory.groupTicket = parsedGroupTicketConf.map(categoryAndCount => convertGroupTicketToCamelCase(
                            categoryAndCount))
                    }

                    return tempPriceCategory
                },
            )

            setPriceCategories(convertedPriceCategories)
        }
    }, [priceCategoriesData])

    useEffect(() => {
        setActiveBreadcrumb(`${t("products")} (${umbrellaName})`)
    }, [t, umbrellaName])

    const breadcrumbs = [
        {label: t("settings"), url: currentBaseUrl},
        {label: activeBreadcrumb},
    ]

    const handleNewProductGroup = () => {
        setProductGroups(prevState => [...prevState, {new: true}])
    }

    const handleNewDiscountCode = () => {
        setDiscountCodes(prevState => [...prevState, {new: true}])
    }

    const convertGroupTicketToCamelCase = (obj) => {
        return _.mapKeys(obj, (value, key) => _.camelCase(key))
    }

    const handleProductGroupChange = (index, productGroup) => {
        let newProductGroups = [...productGroups]
        newProductGroups[index] = productGroup
        setProductGroups(newProductGroups)
    }

    const removeFromProductGroups = index => {
        let newProductGroups = productGroups
        newProductGroups.splice(index, 1)
        setProductGroups([...newProductGroups])
    }

    const handleDiscountCodeChange = (index, discountCode) => {
        let newDiscountCodes = [...discountCodes]
        newDiscountCodes[index] = discountCode
        setDiscountCodes([...newDiscountCodes])
    }

    const removeFromSellingPoints = index => {
        let newSellingPoints = uniqueSellingPoints
        newSellingPoints.splice(index, 1)
        setUniqueSellingPoints([...newSellingPoints])
    }

    const removeFromDiscountCodes = index => {
        let newDiscountCodes = discountCodes
        newDiscountCodes.splice(index, 1)
        setDiscountCodes([...newDiscountCodes])
    }

    const handleFamilyDiscountUpdated = () => {
        getFamilyDiscounts().catch(() => {
        })
        setEditCreateFamilyDiscountDialogOpen(false)
        setFamilyDiscountToEdit(null)
    }

    const handlePriceCategoryUpdated = () => {
        getPriceCategories()
        setEditCreatePriceCategoryDialogOpen(false)
    }

    const removeFromPriceCategories = index => {
        let newPriceCategories = priceCategories
        newPriceCategories.splice(index, 1)
        setPriceCategories([...newPriceCategories])
    }

    const handleCreateOrEditDialogClose = () => {
        setEditCreatePriceCategoryDialogOpen(false)
        setPriceCategoryToEdit(null)
    }

    const handleCreateOrEditUniqueSellingPointDialogClose = () => {
        setEditCreateUniqueSellingPointDialogOpen(false)
        setUniqueSellingPointToEdit(null)
    }

    const handleUniqueSellingPointsUpdated = () => {
        getUniqueSellingPoints()
        setEditCreateUniqueSellingPointDialogOpen(false)
    }

    const handleCreateOrEditFamilyDiscountDialogClose = () => {
        setEditCreateFamilyDiscountDialogOpen(false)
        setFamilyDiscountToEdit(null)
    }

    return (
        <>
            {showSectionHeader && <SectionHeader breadcrumbs={breadcrumbs}/>}
            <ScrollableSectionView>
                <div style={{paddingRight: spacing(1)}}>
                    {showProductGroupModule && (
                        <EditorCardModule expanded
                                          title="productGroups"
                                          subheader={t("productGroupsSettingsHelperText")}
                                          helperTextComponent={
                                              <ProductGroupsSettingsHelperText/>}>
                            <ProductGroups handleNewProductGroup={handleNewProductGroup}>
                                {productGroupsLoading
                                    ? <ProgressIndicator/>
                                    : productGroups?.map((productGroup, index) => (
                                        <ProductGroup data={productGroup}
                                                      key={index}
                                                      index={index}
                                                      umbrellaId={umbrellaId}
                                                      submitChange={handleProductGroupChange}
                                                      removeFromProductGroups={removeFromProductGroups}/>
                                    ))}
                            </ProductGroups>
                        </EditorCardModule>
                    )}
                    {showPriceCategoryModule && (
                        <EditorCardModule expanded
                                          title="priceCategories"
                                          subheader={t("pricecategoriesSettingsHelperText")}
                                          helperTextComponent={
                                              <PriceCategoriesSettingsHelperText/>}>
                            <PriceCategories handleNewPriceCategory={() => setEditCreatePriceCategoryDialogOpen(true)}
                                             addDisabled={!productGroups?.length && !priceCategoriesLoading}>
                                {productGroupsLoading || priceCategoriesLoading
                                    ? <ProgressIndicator/>
                                    : <PriceCategoryTable priceCategories={priceCategories}
                                                          onEditPriceCategory={(priceCategory) => {
                                                              setPriceCategoryToEdit(priceCategory)
                                                              setEditCreatePriceCategoryDialogOpen(true)
                                                          }}
                                                          removeFromPriceCategories={removeFromPriceCategories}/>
                                }
                                {editCreatePriceCategoryDialogOpen &&
                                    <EditOrCreatePriceCategoryDialog open={editCreatePriceCategoryDialogOpen}
                                                                     priceCategoryToEdit={priceCategoryToEdit}
                                                                     handleClose={() => handleCreateOrEditDialogClose()}
                                                                     availablePriceCategories={priceCategories}
                                                                     onPriceCategorySaved={handlePriceCategoryUpdated}
                                    />}
                            </PriceCategories>
                        </EditorCardModule>
                    )}
                    {showUniqueSellingPointsModule && (
                        <EditorCardModule expanded
                                          title={"uniqueSellingPoints"}
                                          helperTextComponent={<UniqueSellingPointsHelperText/>}
                                          subheader={t("uniqueSellingPointsHelperText")}>
                            <UniqueSellingPoints addDisabled={false}
                                                 handleAddNewSellingPoint={() => setEditCreateUniqueSellingPointDialogOpen(true)}>
                                {uniqueSellingPointsLoading
                                    ? <ProgressIndicator/>
                                    :
                                    <UniqueSellingPointsTable uniqueSellingPoints={uniqueSellingPoints}
                                                              onEditUniqueSellingPoint={(uniqueSellingPoint) => {
                                                                  setUniqueSellingPointToEdit(uniqueSellingPoint)
                                                                  setEditCreateUniqueSellingPointDialogOpen(true)
                                                              }}
                                                              removeFromSellingPoints={removeFromSellingPoints}/>
                                }
                                {editCreateUniqueSellingPointDialogOpen &&
                                    <EditOrCreateUniqueSellingPointDialog open={editCreateUniqueSellingPointDialogOpen}
                                                                          uniqueSellingPointToEdit={uniqueSellingPointToEdit}
                                                                          handleClose={() => handleCreateOrEditUniqueSellingPointDialogClose()}
                                                                          onUniqueSellingPoingSaved={handleUniqueSellingPointsUpdated}
                                    />}
                            </UniqueSellingPoints>
                        </EditorCardModule>
                    )}
                    {showFamilyDiscountModule && (
                        <EditorCardModule expanded
                                          title={"familyDiscounts"}
                                          helperTextComponent={<FamilyDiscountsHelperText/>}
                                          subheader={t("familyDiscountsHelperText")}>
                            <FamilyDiscounts addDisabled={!priceCategories?.length && !familyDiscountsLoading}
                                             handleNewFamilyDiscount={() => setEditCreateFamilyDiscountDialogOpen(true)}>
                                {productGroupsLoading || priceCategoriesLoading || (familyDiscountsLoading && !familyDiscounts?.length)
                                    ? <ProgressIndicator/>
                                    : <FamilyDiscountsTable familyDiscounts={familyDiscounts}
                                                            setFamilyDiscounts={setFamilyDiscounts}
                                                            getFamilyDiscounts={getFamilyDiscounts}
                                                            onEditfamilyDiscount={(familyDiscount) => {
                                                                setFamilyDiscountToEdit(familyDiscount)
                                                                setEditCreateFamilyDiscountDialogOpen(true)
                                                            }}/>
                                }
                                {editCreateFamilyDiscountDialogOpen && (
                                    <EditOrCreateFamilyDiscountDialog open={editCreateFamilyDiscountDialogOpen}
                                                                      familyDiscountToEdit={familyDiscountToEdit}
                                                                      handleClose={handleCreateOrEditFamilyDiscountDialogClose}
                                                                      availablePriceCategories={priceCategories}
                                                                      onFamilyDiscountSaved={handleFamilyDiscountUpdated}/>
                                )}
                            </FamilyDiscounts>
                        </EditorCardModule>
                    )}
                    {showDiscountCodeModule && (
                        <EditorCardModule expanded
                                          title="discountCodes"
                                          subheader={t("discountCodesHelperText")}
                                          helperTextComponent={<DiscountCodesHelperText/>}>
                            <DiscountCodes handleNewDiscountCode={handleNewDiscountCode}>
                                {discountCodesLoading
                                    ? <ProgressIndicator/>
                                    :
                                    discountCodes?.map((discountCode, index) => (
                                        <DiscountCode data={discountCode}
                                                      key={index}
                                                      index={index}
                                                      umbrellaId={umbrellaId}
                                                      submitChange={handleDiscountCodeChange}
                                                      removeFromProductGroups={removeFromDiscountCodes}/>
                                    ))}
                            </DiscountCodes>
                        </EditorCardModule>
                    )}
                </div>
            </ScrollableSectionView>
        </>
    )
}

export default ProductsSettings
