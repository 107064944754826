import React, {useEffect, useState} from "react"
import SectionHeader from "../../app/components/SectionHeader"
import ScrollableSectionView from "../../app/ScrollableSectionView"
import {SALES_BASE_URL} from "../../app/Routes"
import {useAxiosBpn} from "../../app/axios"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import {useAuthsState} from "../../app/auths/authsContext"
import DateFilterDialog from "../../app/components/DateFilterDialog"
import {dateFormat, MONTHLY} from "../../app/helper-functions/dateFormat"
import {endOfYear, startOfYear} from "date-fns"
import DateFrequencySelectMenu from "../../app/components/DateFrequencySelectMenu"
import OrderSumsGroupBySelectMenu, {
    GROUP_BY_ALTERNATIVES,
    GROUP_BY_PRICE_CATEGORY_NAME
} from "../../app/components/OrderSumsGroupBySelectMenu"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import VisitorStatsCard from "../../app/visitor-stats/VisitorStatsCard"
import TestOrdersSwitch from "../../app/components/TestOrdersSwitch"
import {Tab} from "@mui/material"
import {TabContext, TabList, TabPanel} from "@mui/lab"
import VisitorStatsPivotTable from "../../app/visitor-stats/VisitorStatsPivotTable"
import {useQuery} from "../../app/hooks/useQuery"

const LOCAL_STORAGE_KEY = "bpn.testOrders"
const defaultDateFilterLabel = (t) => `${t("thusFar")} ${t("in").toLowerCase()} ${dateFormat(new Date(), "y")}`


const PIVOT_TAB = "pivot"
export const STATS_TAB = "stats"

const ALLOWED_TABS = [PIVOT_TAB, STATS_TAB]

export const Visitors = () => {
    const t = useBaseTranslation()
    const query = useQuery()
    const {umbrellaAndChildrenIds} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()

    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const [dateFilterLabel, setDateFilterLabel] = useState(defaultDateFilterLabel(t))
    const [before, setBefore] = useState(endOfYear(new Date()).getTime())
    const [after, setAfter] = useState(startOfYear(new Date()).getTime())
    const [groupByMenuOpen, setGroupByMenuOpen] = useState(false)
    const [dateFrequencyMenuOpen, setDateFrequencyMenuOpen] = useState(false)
    const [dateFilterDialogOpen, setDateFilterDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [frequency, setFrequency] = useState(MONTHLY)
    const [groupBy, setGroupBy] = useState(GROUP_BY_PRICE_CATEGORY_NAME)
    const [selectedProduct, setSelectedProduct] = useState()
    const [selectedTab, setSelectedTab] = useState(PIVOT_TAB)

    const [{data: visitorStatsData, loading: visitorStatsLoading}, getVisitorStats] = useAxiosBpn({
        url: "orders/visitors",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                ? umbrellaAndChildrenIds.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
            groupBy: groupBy,
            groupByDateFrequency: frequency,
            productId: selectedProduct && selectedProduct.id !== 0 ? selectedProduct.id : null,
            test: testOrders,
            pivot: selectedTab === 'pivot',
            index: "priceCategoryName,productName",
            columns: "museumName",
            visitorStats: true,
            before: before,
            after: after,
        },
    })

    const [{data: productsData, loading: productsLoading}, getProducts] = useAxiosBpn({
        url: "products",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                ? umbrellaAndChildrenIds.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
            publishedOnly: true,
        },
    })

    useEffect(() => {
        if (query) {
            const _groupBy = query.get('groupBy')
            const tab = query.get('tab')

            if (_groupBy && GROUP_BY_ALTERNATIVES.includes(_groupBy)) setGroupBy(_groupBy)
            if (tab && ALLOWED_TABS.includes(tab)) setSelectedTab(tab)
        }
    }, [query])

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    useEffect(() => {
        getVisitorStats().catch(() => {
            // Just to avoid annoying console error
        })
        getProducts().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getVisitorStats().catch(() => {
            // Just to avoid annoying console error
        })
        getProducts().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getProducts, getVisitorStats, testOrders])

    const breadcrumbs = [
        {label: t("sales"), url: SALES_BASE_URL},
        {label: t("visitorNumbers")},
    ]

    const handleSelectGroupByClick = (event) => {
        setGroupByMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleGroupBySelect = (_groupBy) => {
        setGroupBy(_groupBy)
        setGroupByMenuOpen(false)
    }

    const handleTabSelect = (event, newTab) => {
        setSelectedTab(newTab)
    }

    const handleSelectDateFrequencyClick = (event) => {
        setDateFrequencyMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleDateFrequencySelect = (freq) => {
        setFrequency(freq)
        setDateFrequencyMenuOpen(false)
    }

    const handleDateFilterDialogOpen = (event) => {
        setDateFilterDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleDateFilterDialogClose = (_after = null, _before = null, label = null) => {
        setDateFilterDialogOpen(false)
        setDateFilterLabel(label || t("allDates"))
        setAfter(_after)
        setBefore(_before)
    }

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <ScrollableSectionView>
                <TabContext value={selectedTab}>
                    <TabList onChange={handleTabSelect}
                             textColor="primary"
                             indicatorColor="primary">
                        <Tab value={STATS_TAB}
                             label="Graf"/>
                        <Tab value={PIVOT_TAB}
                             label="Tabell"/>
                    </TabList>

                    <TabPanel value={STATS_TAB}>
                        <VisitorStatsCard data={visitorStatsData}
                                          loading={visitorStatsLoading}
                                          getOrderSums={getVisitorStats}
                                          productsOptions={productsData?.products}
                                          productsLoading={productsLoading}
                                          selectedProduct={selectedProduct}
                                          setSelectedProduct={setSelectedProduct}
                                          testOrders={testOrders}
                                          handleSelectGroupByClick={handleSelectGroupByClick}
                                          handleSelectDateFrequencyClick={handleSelectDateFrequencyClick}
                                          handleDateFilterDialogOpen={handleDateFilterDialogOpen}
                                          dateFilterLabel={dateFilterLabel}
                                          frequency={frequency}
                                          groupBy={groupBy}/>
                    </TabPanel>
                    <TabPanel value={PIVOT_TAB}>
                        <VisitorStatsPivotTable data={visitorStatsData}
                                                loading={visitorStatsLoading}
                                                getOrderSums={getVisitorStats}
                                                testOrders={testOrders}
                                                handleDateFilterDialogOpen={handleDateFilterDialogOpen}
                                                dateFilterLabel={dateFilterLabel}/>
                    </TabPanel>
                </TabContext>
            </ScrollableSectionView>
            <OrderSumsGroupBySelectMenu open={groupByMenuOpen}
                                        setOpen={setGroupByMenuOpen}
                                        anchorEl={anchorEl}
                                        handleSelect={handleGroupBySelect}/>
            <DateFrequencySelectMenu open={dateFrequencyMenuOpen}
                                     setOpen={setDateFrequencyMenuOpen}
                                     anchorEl={anchorEl}
                                     handleSelect={handleDateFrequencySelect}/>
            <DateFilterDialog t={t}
                              open={dateFilterDialogOpen}
                              anchorEl={anchorEl}
                              handleClose={handleDateFilterDialogClose}/>
        </>
    )
}