import React, {useEffect, useState} from "react"
import {FormHelperText, FormLabel, Grid, MenuItem, TextField} from "@mui/material"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import {useAxiosBpn} from "../../../app/axios"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {VOUCHER_FORMAT_NONE, VOUCHER_FORMAT_VISMA} from "../../../app/helper-functions/voucherStatuses"

const VoucherExport = ({setVoucherTypeSelected}) => {
    const t = useBaseTranslation()
    const {umbrellaId, umbrellaName} = useMuseumSelectorState()
    const snackbar = useCustomSnackbar()

    const [selectedFormat, setSelectedFormat] = useState("none")

    const [{data: voucherSettingsData, loading: voucherSettingsLoading}, getVoucherSettings] = useAxiosBpn({
        url: `voucher_settings/museums/${umbrellaId}`,
    })

    const [
        {
            data: voucherSettingsPutData,
            loading: voucherSettingsPutLoading,
            error: voucherSettingsPutError,
        }, putVoucherSettings,
    ] = useAxiosBpn({
        url: `voucher_settings/museums/${umbrellaId}`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        getVoucherSettings().catch(() => {
            // Just to avoid annoying console error
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getVoucherSettings().catch(() => {
            // Just to avoid annoying console error
        })
    }, [getVoucherSettings, umbrellaId])

    useEffect(() => {
        if (voucherSettingsData) {
            const {voucherSettings} = voucherSettingsData
            if (voucherSettings?.format) {
                setSelectedFormat(voucherSettings?.format)
            } else {
                setSelectedFormat("none")
            }
        }
    }, [voucherSettingsData])

    useEffect(() => {
        setVoucherTypeSelected(selectedFormat)
    }, [selectedFormat, setVoucherTypeSelected])

    useEffect(() => {
        if (voucherSettingsPutData) {
            snackbar.showSuccess(t("voucherSettingsUpdated"))
        }
    }, [snackbar, voucherSettingsPutData, t])

    useEffect(() => {
        if (voucherSettingsPutError) snackbar.showError(t(voucherSettingsPutError.message))
    }, [voucherSettingsPutError, snackbar, t])

    const voucherFormats = [
        {value: VOUCHER_FORMAT_NONE, label: t("none")},
        {value: VOUCHER_FORMAT_VISMA, label: t("textFileVisma")},
    ]

    const handleVoucherFormatSelect = (format) => {
        if (selectedFormat !== format) {
            setSelectedFormat(format)
            putVoucherSettings({data: {format: format, museumName: umbrellaName}})
        }
    }

    return voucherSettingsLoading || voucherSettingsPutLoading
           ? <ProgressIndicator/>
           : <Grid container
                   alignItems="flex-end"
                   direction="column">
               <Grid container
                     spacing={1}
                     justifyContent="flex-end"
                     alignItems="center"
                     item>
                   <Grid item>
                       <FormLabel>{t("selectFileFormat")}</FormLabel>
                   </Grid>
                   <Grid item
                         xs={12}
                         sm={6}
                         md={3}
                         lg={2}
                         xl={1}>
                       <TextField name="format"
                                  select
                                  fullWidth
                                  label={t("format")}
                                  value={selectedFormat || ""}>
                           {voucherFormats.map(({value, label}, index) => (
                               <MenuItem key={index}
                                         value={value}
                                         onClick={() => handleVoucherFormatSelect(value)}>
                                   {label}
                               </MenuItem>
                           ))}
                       </TextField>
                   </Grid>
               </Grid>
               <Grid item>
                   <FormHelperText>
                       Gjelder for <strong>{umbrellaName}</strong> og alle tilhørende museer
                   </FormHelperText>
               </Grid>

           </Grid>
}

export default VoucherExport