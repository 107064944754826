import {Dialog, DialogActions, DialogContent, FormControlLabel, Grid, Switch, TextField,} from "@mui/material"
import Button from "@mui/material/Button"
import React, {useEffect, useState} from "react"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import {useAxiosBpn} from "../../axios"
import ScheduleEventTreeView from "../../components/ScheduleEventTreeView"
import EventFilterDialog from "../../components/EventFilterDialog"
import {Block} from "@mui/icons-material"
import ScheduleEventExceptionsDialogTitle from "./ScheduleEventExceptionsDialogTitle"
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl"
import MuiDatePicker from "../../components/MuiDatePicker"
import {getEndTime, getStartDate, getStartTime} from "../../helper-functions/scheduleEventHelper"
import {dateFormat} from "../../helper-functions/dateFormat"
import MuiTimePicker from "../../components/MuiTimePicker"
import SaveIcon from "@mui/icons-material/Save"


const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    switchLabel: {
        fontSize: "0.9em",
    },
}))


const ScheduleEventExceptionsDialog = ({open, event, pattern, exceptions, handleAddException, handleClose}) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()

    const [eventDialogOpen, setEventDialogOpen] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState()
    const [eventLabel, setEventLabel] = useState(t("selectEvent"))
    const [saleLimit, setSaleLimit] = useState(false)
    const [startDate, setStartDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [originalDate, setOriginalDate] = useState()
    const [originalStartTime, setOriginalStartTime] = useState()
    const [originalEndTime, setOriginalEndTime] = useState()
    const [endTimeIsKnown, setEndTimeIsKnown] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [{data, loading}, getRecurringEvents] = useAxiosBpn(
        {
            url: "schedule_events/recurring",
            method: "POST",
            data: {
                pattern: {...pattern, dayOfWeek: pattern?.dayOfWeek?.join(",")},
                event: {...event, id: Number.isInteger(event.id) ? event.id : null, name: event?.name?.value},
                exceptions: exceptions
            }
        },
        {
            manual: true,
        })

    useEffect(() => {
        if (event && event.id && pattern && open) getRecurringEvents().catch(() => {
        })
    }, [event, pattern, open, getRecurringEvents])

    useEffect(() => setSaleLimit(!!event?.saleLimit), [event])

    useEffect(() => {
        if (selectedEvent) {
            setStartDate(getStartDate(selectedEvent))
            setStartTime(getStartTime(selectedEvent))
            setEndTime(getEndTime(selectedEvent))
            setEndTimeIsKnown(!!selectedEvent?.endTime)
        }
    }, [selectedEvent])

    const handleEventSelect = (event, label = null) => {
        setEventLabel(label)
        setSelectedEvent(event)
        setOriginalDate(getStartDate(event))
        setOriginalStartTime(getStartTime(event))
        setOriginalEndTime(getEndTime(event))
    }

    const handleEventDialogOpen = (event) => {
        setEventDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const _handleAddException = (cancel) => {
        const _originalStartDate = dateFormat(originalDate, "yyyy-MM-dd")
        const _originalStartTime = dateFormat(originalStartTime?.setSeconds(0), "HH:mm:ss")
        const _originalEndTime = dateFormat(originalEndTime?.setSeconds(0), "HH:mm:ss")
        const _isSaleLimitReduced = selectedEvent?.saleLimit !== 0 && selectedEvent?.saleLimit < event.saleLimit
        const _isSaleLimitIncreased = selectedEvent?.saleLimit === 0 || selectedEvent?.saleLimit > event.saleLimit
        const _isRescheduled = (selectedEvent?.startDate !== _originalStartDate)
            || (selectedEvent?.startTime !== _originalStartTime)
            || (selectedEvent?.endTime !== _originalEndTime)

        const commonAttributes = {
            originalName: event.name?.value,
            originalSaleLimit: event.saleLimit,
            originalStartDate: _originalStartDate,
            originalStartTime: _originalStartTime,
            originalEndTime: _originalEndTime,
            isCancelled: cancel,
            isRescheduled: _isRescheduled,
            isSaleLimitReduced: _isSaleLimitReduced,
            isSaleLimitIncreased: _isSaleLimitIncreased,
            recurNumber: selectedEvent?.recurNumber,
            scheduleEventId: Number.isInteger(event.id) ? event.id : null
        }

        // If cancelled, all attributes should remain the same
        cancel
            ? handleAddException({
                ...commonAttributes,
                name: event.name?.value,
                startDate: _originalStartDate,
                startTime: _originalStartTime,
                endDate: _originalStartDate,
                endTime: _originalEndTime,
                saleLimit: event.saleLimit,
            })
            : handleAddException({
                ...commonAttributes,
                name: selectedEvent?.name,
                startDate: selectedEvent?.startDate,
                startTime: selectedEvent?.startTime,
                endDate: selectedEvent?.endDate,
                endTime: selectedEvent?.endTime,
                saleLimit: selectedEvent?.saleLimit,
            })
        _handleClose()
    }

    const handleEndTimeIsKnownChange = () => {
        setSelectedEvent({
            ...selectedEvent,
            endTime: endTimeIsKnown ? null : dateFormat(new Date().setMinutes(120), "HH:mm:ss"),
        })
        setEndTimeIsKnown(!endTimeIsKnown)
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleDateChange = (date, name) => {
        setSelectedEvent({...selectedEvent, [name]: dateFormat(date, "yyyy-MM-dd")})
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleTimeChange = (time, name) => {
        setSelectedEvent({...selectedEvent, [name]: dateFormat(time.setSeconds(0), "HH:mm:ss")})
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleSaleLimitChange = () => {
        setSelectedEvent({...selectedEvent, saleLimit: (saleLimit ? 0 : 10)})
        setSaleLimit(!saleLimit)
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleChange = ({target: {name, value}}) => {
        setSelectedEvent({...selectedEvent, [name]: value})
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const _handleClose = () => {
        setSelectedEvent(null)
        setEventLabel(t("selectEvent"))
        setUnsavedChanges(false)
        handleClose()
    }

    const getNameHelperText = () => {
        if (!selectedEvent) return "Velg tidspunkt for å kunne overstyre felt."
        let text = event?.name?.value
            ? `Opprinnelig navn er "${event?.name?.value}".`
            : `Ingen opprinnelig navn.`

        return text + "Kan overstyres for valgt tidspunkt."
    }

    const getSaleLimitHelperText = () => {
        if (!selectedEvent) return "Velg tidspunkt for å kunne overstyre felt."
        let text = event?.saleLimit
            ? `Opprinnelig plassbegrensning er ${event?.saleLimit}.`
            : `Ingen opprinnelig plassbegrensning.`

        return text + "Kan overstyres for valgt tidspunkt."
    }

    const getStartDateHelperText = () => {
        if (!selectedEvent) return "Velg tidspunkt for å kunne overstyre felt."
        let text = originalDate
            ? `Opprinnelig dato er ${dateFormat(originalDate, "dd.MM.yyyy")}.`
            : `Ingen opprinnelig dato.`

        return text + "Kan overstyres for valgt tidspunkt."
    }

    const getStartTimeHelperText = () => {
        if (!selectedEvent) return "Velg tidspunkt for å kunne overstyre felt."
        return originalStartTime
            ? `Opprinnelig starttidspunkt er ${dateFormat(originalStartTime, "HH:mm")}.`
            : `Ingen opprinnelig starttidspunkt.`
    }

    const getEndTimeHelperText = () => {
        if (!selectedEvent) return "Velg tidspunkt for å kunne overstyre felt."
        return originalEndTime
            ? `Opprinnelig sluttidspunkt er ${dateFormat(originalEndTime, "HH:mm")}.`
            : `Ingen opprinnelig sluttidspunkt.`
    }

    return (
        <Dialog open={open}
                fullWidth
                maxWidth="sm"
                onClose={_handleClose}>
            <ScheduleEventExceptionsDialogTitle eventLabel={eventLabel}
                                                groupedScheduleEvents={data?.groupedScheduleEvents}
                                                handleEventDialogOpen={handleEventDialogOpen}
                                                loading={loading}/>
            <DialogContent>
                {selectedEvent && (
                    <Grid container
                          direction="column"
                          spacing={3}>
                        <form noValidate autoComplete="off" style={{width: "100%"}}>
                            <Grid item xs={12}>
                                <TextField label={t("name")}
                                           className={classes.textfield}
                                           disabled={!selectedEvent}
                                           name="name"
                                           fullWidth
                                           variant="outlined"
                                           size="small"
                                           helperText={getNameHelperText()}
                                           onChange={handleChange}
                                           value={selectedEvent?.name || event?.name?.value || ""}/>
                            </Grid>
                            <Grid item container spacing={1}>
                                <Grid item xs={3}>
                                    <FormControl>
                                        <FormControlLabel label={t("limitSale")}
                                                          labelPlacement="top"
                                                          classes={{label: classes.switchLabel}}
                                                          control={
                                                              <Switch checked={saleLimit}
                                                                      size="small"
                                                                      disabled={!selectedEvent}
                                                                      onChange={handleSaleLimitChange}/>
                                                          }/>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={9}>
                                    {saleLimit && (
                                        <TextField label={t("saleLimit")}
                                                   name="saleLimit"
                                                   variant="outlined"
                                                   disabled={!selectedEvent || !saleLimit}
                                                   size="small"
                                                   helperText={getSaleLimitHelperText()}
                                                   fullWidth
                                                   onChange={handleChange}
                                                   value={selectedEvent?.saleLimit || event?.saleLimit || 0}/>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiDatePicker selected={startDate}
                                               label={t("date")}
                                               helperText={getStartDateHelperText()}
                                               disabled={!selectedEvent}
                                               name="startDate"
                                               size="small"
                                               inputVariant="outlined"
                                               onChange={handleDateChange}/>
                            </Grid>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={6} sm={3}>
                                    <MuiTimePicker selected={startTime}
                                                   label={t("startTime")}
                                                   disabled={!selectedEvent}
                                                   helperText={getStartTimeHelperText()}
                                                   name="startTime"
                                                   size="small"
                                                   inputVariant="outlined"
                                                   onChange={handleTimeChange}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {endTimeIsKnown && (
                                        <MuiTimePicker selected={endTime}
                                                       label={t("endTime")}
                                                       disabled={!selectedEvent}
                                                       helperText={getEndTimeHelperText()}
                                                       name="endTime"
                                                       size="small"
                                                       isClearable
                                                       inputVariant="outlined"
                                                       onChange={handleTimeChange}/>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel label={t("endTimeKnown")}
                                                      labelPlacement="start"
                                                      classes={{label: classes.switchLabel}}
                                                      control={
                                                          <Switch checked={endTimeIsKnown}
                                                                  disabled={!selectedEvent}
                                                                  size="small"
                                                                  onChange={handleEndTimeIsKnownChange}/>
                                                      }/>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                )}
                <EventFilterDialog anchorEl={anchorEl}
                                   title={t("selectScheduleEvent")}
                                   open={eventDialogOpen}
                                   handleClose={() => setEventDialogOpen(false)}>
                    <ScheduleEventTreeView groupedScheduleEvents={data?.groupedScheduleEvents}
                                           selectedEvent={selectedEvent}
                                           handleClose={() => setEventDialogOpen(false)}
                                           handleSelect={handleEventSelect}/>
                </EventFilterDialog>
            </DialogContent>
            <DialogActions>
                {selectedEvent && (
                    <>
                        <Button onClick={() => _handleAddException(true)}
                                style={{color: "#d32f2f"}}
                                startIcon={<Block/>}>
                            Avlys valgt tidspunkt
                        </Button>
                        <Button onClick={() => _handleAddException(false)}
                                disabled={!unsavedChanges}
                                startIcon={<SaveIcon/>}>
                            Lagre endringer
                        </Button>
                    </>
                )}
                <Button color="primary"
                        onClick={_handleClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(ScheduleEventExceptionsDialog)