import React, {useEffect, useState} from "react"
import {Card, CardContent, CardHeader, Divider, Grid} from "@mui/material"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {GridLabel, GridValue} from "../order-details/gridComponents"

const VoucherVismaDetails = ({
                                 voucher,
                                 loading,
                             }) => {

    const t = useBaseTranslation()

    const [gridRows, setGridRows] = useState([])

    useEffect(() => {
        if (voucher) {
            const {
                creditAccount,
                creditTaxCode,
                creditTurnoverClass,
                debitAccount,
                debitTaxCode,
                debitTurnoverClass,
                department,
                journalType,
                location,
                project,
                text,
                text2,
            } = voucher

            setGridRows([
                {label: t("creditAccount"), value: creditAccount},
                {label: t("creditTaxCode"), value: creditTaxCode},
                {label: t("creditTurnoverClass"), value: creditTurnoverClass},
                {label: t("debitAccount"), value: debitAccount},
                {label: t("debitTaxCode"), value: debitTaxCode},
                {label: t("debitTurnoverClass"), value: debitTurnoverClass},
                {label: t("department"), value: department},
                {label: t("journalType"), value: journalType},
                {label: t("project"), value: project},
                {label: t("location"), value: location},
                {label: t("text"), value: text},
                {label: t("text2"), value: text2},
            ])
        }

    }, [t, voucher])

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined">
               <CardHeader title={"Visma"}
                           titleTypographyProps={{color: "primary"}}/>
               <CardContent>
                   <Grid container
                         direction="column">
                       {gridRows?.map(({label, value, divider}, index) =>
                           divider
                           ? <Divider key={index}
                                      style={{marginTop: 8, marginBottom: 8}}/>
                           : value &&
                               <Grid container
                                     key={index}
                                     alignItems="center">
                                   <GridLabel>{label}:</GridLabel>
                                   <GridValue>{value}</GridValue>
                               </Grid>,
                       )}
                   </Grid>
               </CardContent>
           </Card>
}

export default VoucherVismaDetails
