import {Card, CardContent, CardHeader, CircularProgress, Tab, TextField, Tooltip, Typography} from "@mui/material"

import React, {useCallback, useEffect, useState} from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import {useAxiosBpn} from "../axios"
import ReactQuill, {Quill} from "react-quill"
import "react-quill/dist/quill.snow.css"
import BlotFormatter from "quill-blot-formatter"
import useCustomSnackbar from "../components/useCustomSnackbar"
import CheckIcon from "@mui/icons-material/Check"
import makeStyles from '@mui/styles/makeStyles';
import GenericTable from "../generic-table/GenericTable"
import {TabContext, TabList, TabPanel} from "@mui/lab"
import {dateFormat, getEventLabel} from "../helper-functions/dateFormat"
import {MailPopupModal} from "./MailPopupModal"
import {useQuery} from "../hooks/useQuery"
import {ArrowDropDown} from "@mui/icons-material"
import ScheduleEventTreeView from "../components/ScheduleEventTreeView"
import EventFilterDialog from "../components/EventFilterDialog"

Quill.register("modules/blotFormatter", BlotFormatter)

const defaultValues = {
    subject: "",
    message: "",
    heading: "",
}

const modules = {
    toolbar: [
        [{header: "1"}, {header: "2"}, {font: []}],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            {list: "ordered"},
            {list: "bullet"},
            {indent: "-1"},
            {indent: "+1"},
        ],
        ["link"],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
]

const useStyles = makeStyles((theme) => ({
    emailCardWrapper: {
        display: "flex",
    },
    templateIllustration: {
        width: "480px",
        height: "500px",
        marginLeft: theme.spacing(2),
    },
    inputForm: {
        width: "100%",
    },
    buttonIcon: {
        marginLeft: theme.spacing(2),
        color: "white",
    },
    sendTestButton: {
        marginLeft: theme.spacing(2),
        height: "100%",
        alignSelf: "center",
    },
    testMailGridItem: {
        display: "flex",
    },
}))

export const EmailCustomersCard = ({productId, eventFilterButtonHidden, groupedScheduleEvents}) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()
    const classes = useStyles()
    const query = useQuery()

    const [formValues, setFormValues] = useState(defaultValues)
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
    const [fullEmailText, setFullEmailText] = useState("")
    const [testEmail, setTestEmail] = useState("")
    const [selectedTab, setSelectedTab] = useState("send")
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [eventLabel, setEventLabel] = useState(t("allEvents"))
    const [rows, setRows] = useState([])
    const [eventFilterDialogOpen, setEventFilterDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const [{data: testEmailResult, loading: testEmailIsSending, error: testEmailError}, sendTestEmail] = useAxiosBpn({
        url: `products/mail/${productId}`,
        method: "POST",
        params: {testEmailAddress: testEmail}
    }, {manual: true})

    const [{data: sendToAllResult, loading: sendToallLoading, error: sendToAllError}, sendEmail] = useAxiosBpn({
        url: `products/mail/${productId}`,
        method: "POST",
        params: {
            scheduleEventId: selectedEvent?.id,
            recurNumber: selectedEvent?.recurNumber,
        }
    }, {manual: true})

    const [{data: emailHistory}, fetchSentEmails] = useAxiosBpn({
        url: `products/mail/${productId}`,
        method: "get",
    }, {manual: true})

    const [{data: scheduleEventData}, getScheduleEvent] = useAxiosBpn({url: ""}, {manual: true})

    const _getEventLabel = useCallback((event) => getEventLabel(t, event), [t])

    useEffect(() => {
        if (query) {
            const scheduleEventId = query.get('scheduleEventId')
            const recurNumber = query.get('recurNumber')

            if (scheduleEventId) {
                getScheduleEvent({
                    url: `schedule_events/${scheduleEventId}`,
                    params: {recurNumber: recurNumber}
                }).catch(() => {
                })
            }
        }
    }, [query, getScheduleEvent])

    useEffect(() => {
        if (scheduleEventData?.scheduleEvent) {
            const {scheduleEvent} = scheduleEventData
            setSelectedEvent(scheduleEvent)
            setEventLabel(_getEventLabel(scheduleEvent))
        }
    }, [scheduleEventData, _getEventLabel])

    useEffect(() => fetchSentEmails(), [fetchSentEmails])

    useEffect(() => {
        if (sendToAllResult) {
            const {send_response} = sendToAllResult
            if (send_response) {
                snackbar.showSuccess(`Din mail er sendt`)
                fetchSentEmails()
            }
        } else if (testEmailResult) {
            const {send_response} = testEmailResult
            if (send_response) snackbar.showSuccess(`Test mail er nå sendt. Merk at det kan ta litt tid før den er i mailboksen din.`)
        }
    }, [sendToAllResult, snackbar, testEmailResult, fetchSentEmails])

    useEffect(() => {
        if (sendToAllError || testEmailError) snackbar.showError(`Mailutsendendelse feilet. Prøv igjen, eller kontakt support.`)
    }, [sendToAllError, snackbar, testEmailError])

    useEffect(() => {
        if (emailHistory) {
            const {productEmails} = emailHistory

            setRows(productEmails?.map(email => {
                return {
                    ...email,
                    sentAt: dateFormat(email.sentAt, "PPPp"),
                    message: email.message.replace(/<[^>]+>/g, "").substring(0, 50) + "....",
                };
            }) || [])
        }
    }, [emailHistory])

    const handleInputChange = (e) => {
        const {name, value} = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const isValidEmail = () => {
        if (testEmail && testEmail !== "") {
            return /(.+)@(.+){2,}\.(.+){2,}/.test(testEmail);
        } else {
            return true
        }
    }

    const handleMessageInputChange = (generatedHTML) => {
        setFormValues({
            ...formValues,
            "message": generatedHTML,
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        sendEmail({data: {product_id: productId, ...formValues}})
    }

    const sendTest = (event) => {
        event.preventDefault()
        sendTestEmail({data: {product_id: productId, ...formValues}})
    }

    const handleTabSelect = (event, newTab) => setSelectedTab(newTab)

    const handleEventFilterDialogOpen = (event) => {
        setEventFilterDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleEventFilterSelect = (event, label = null) => {
        setEventLabel(label || t("allEvents"))
        setSelectedEvent(event)
    }

    const showFullMailModal = (e) => {
        setIsEmailModalOpen(prevState => !prevState)
        const fullEmailContent = emailHistory?.productEmails?.find(email => {
            const emailText = email.message.replace(/<[^>]+>/g, "").substring(0, 50)
            //remove last 4 chars due to "...." at end of the table value
            return emailText.startsWith(e?.target?.textContent.substring(0, 50).slice(0, -4))
        })

        setFullEmailText(fullEmailContent)
    }

    const columns = [
        {field: "subject", headerName: t("subject")},
        {field: "heading", headerName: t("heading")},
        {field: "message", headerName: t("message"), "onClick": showFullMailModal},
        {field: "sentBy", headerName: t("sentBy")},
        {field: "sentAt", headerName: t("sentAt")},
    ]

    return (
        <Card style={{marginRight: 16, height: "1000px"}}>
            <CardHeader title={t(selectedEvent ? "sendMailToSelectedCustomers" : "sendMailToAllCustomers")}
                        action={!eventFilterButtonHidden && (
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Typography variant="caption">{t("onlySendToCustomersFor")}: </Typography>
                                <Tooltip title={t("selectScheduleEvent")}>
                                    <Button onClick={handleEventFilterDialogOpen}
                                            color="primary"
                                            endIcon={<ArrowDropDown/>}>
                                        {eventLabel}
                                    </Button>
                                </Tooltip>
                            </div>
                        )}/>

            <CardContent>
                <TabContext value={selectedTab}>
                    <TabList onChange={handleTabSelect} textColor="primary" indicatorColor="primary">
                        <Tab value="send" label="Ny email"/>
                        <Tab value="history" label="Sendte mailer "/>
                    </TabList>
                    <TabPanel value="send">
                        <form onSubmit={handleSubmit} className={classes.inputForm}>
                            <Grid container spacing={4} direction="column">
                                <Grid item xs={12}>
                                    <Typography variant={"subtitle1"}>
                                        {selectedEvent
                                            ? <>
                                                {t("emailSomeCustomerCardInfoText")}
                                                <strong> {eventLabel}.</strong>
                                            </>
                                            : t("emailCustomerCardInfoText")}
                                    </Typography>
                                    <br/>
                                    <Typography variant={"subtitle1"}>{t("emailCustomerCardHelperText")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="subject-input"
                                               name="subject"
                                               label={t("subject")}
                                               type="text"
                                               fullWidth
                                               color={"primary"}
                                               variant={"outlined"}
                                               value={formValues.subject}
                                               onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="heading-input"
                                               name="heading"
                                               label={t("heading")}
                                               type="text"
                                               fullWidth
                                               color={"primary"}
                                               variant={"outlined"}
                                               value={formValues.heading}
                                               onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} className={"quill-wrapper1"}>
                                    <ReactQuill value={formValues.message}
                                                onChange={handleMessageInputChange}
                                                theme={"snow"}
                                                style={{height: "250px", marginBottom: "40px"}}
                                                modules={modules}
                                                formats={formats}
                                                bounds={".quill-wrapper"}/>
                                </Grid>
                                <Grid item xs={12} className={classes.testMailGridItem}>
                                    <TextField id="test-mail-input"
                                               name="testEmail"
                                               label="Test mailadresse"
                                               type="text"
                                               color={"primary"}
                                               error={!isValidEmail()}
                                               margin="dense"
                                               helperText={t("testEmailHelperText")}
                                               variant={"outlined"}
                                               value={testEmail}
                                               onChange={(e) => setTestEmail(e.target.value)}
                                    />
                                    <Button size={"small"}
                                            className={classes.sendTestButton}
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => sendTest(e)}
                                            disabled={!(formValues.subject && formValues.heading && testEmail) || testEmailIsSending}>
                                        {t("sendTestMail")}
                                        {testEmailIsSending &&
                                            <CircularProgress size={20} className={classes.buttonIcon}/>}
                                        {testEmailResult && <CheckIcon className={classes.buttonIcon}/>}
                                    </Button>
                                </Grid>

                                <Grid item xs={4}>
                                    <Button variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={!(formValues.subject && formValues.heading) || sendToallLoading}>
                                        {t(selectedEvent ? "sendToSelection" : "sendToAll")}
                                        {sendToallLoading &&
                                            <CircularProgress size={20} className={classes.buttonIcon}/>}
                                        {sendToAllResult && <CheckIcon className={classes.buttonIcon}/>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </TabPanel>
                    <TabPanel value={"history"}>
                        <GenericTable rows={rows}
                                      columns={columns}
                                      tableTitle={t("sentEmails")}
                                      selectable={false}
                                      size="small"/>
                        <MailPopupModal open={isEmailModalOpen}
                                        onClose={() => setIsEmailModalOpen(prevState => !prevState)}
                                        content={fullEmailText}/>
                    </TabPanel>
                </TabContext>
            </CardContent>
            <EventFilterDialog anchorEl={anchorEl}
                               open={eventFilterDialogOpen}
                               handleClose={() => setEventFilterDialogOpen(false)}>
                <ScheduleEventTreeView groupedScheduleEvents={groupedScheduleEvents}
                                       selectedEvent={selectedEvent}
                                       handleClose={() => setEventFilterDialogOpen(false)}
                                       handleSelect={handleEventFilterSelect}/>
            </EventFilterDialog>
        </Card>
    )
}
