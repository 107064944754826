import { FormControlLabel, Grid, Link, Switch, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useEffect, useState} from "react";
import {lowerFirst} from "lodash";
import {useProductEditorTranslation} from "../productEditorTranslationContext";
import {toPascalCase} from "../../helper-functions/stringUtility";
import MuiDateTimePicker from "../../components/MuiDateTimePicker";
import {SETTINGS_BASE_URL} from "../../Routes";
import {useHistory, useParams} from "react-router-dom";
import {bpnAPI} from "../../axios";
import TranslatableTextField from "../TranslatableTextField";
import UploadMediaButton from "./UploadMediaButton";
import axios from "axios";
import {useProductTranslationProvider} from "../ProductTranslationContext";
import TranslatableRichTextField from "../TranslatableRichTextField";
import {PRODUCT_TYPE_SEASONAL_PASS} from "../../baseContext";

const useStyles = makeStyles(theme => ({
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const GeneralSettings = ({product, type, unsavedChanges, setUnsavedChanges, updateProductState}) => {
    const t = useProductEditorTranslation()
    const history = useHistory()
    const classes = useStyles()
    const {id} = useParams()
    const [data, setData] = useState({})
    const [orderNotificationEmailAddress, setOrderNotificationEmailAddress] = useState()
    const [productStatsLink, setProductStatsLink] = useState()
    const [isImageReady, setIsImageReady] = useState()
    const [uploadingImage, setUploadingImage] = useState()
    const [uploadProgress, setUploadProgress] = useState(0)
    const {showTranslationFields, setShowTranslationFields} = useProductTranslationProvider();

    const isDoneConverting = useCallback(async () => product && product.imageUrl?.length > 0, [product])

    useEffect(() => {
        if (product) {
            setData(product)
            setOrderNotificationEmailAddress(product.orderNotificationEmailAddress)
            if (product.translations?.length > 0) setShowTranslationFields(true)
            setIsImageReady(isDoneConverting())
        }
    }, [isDoneConverting, product, setShowTranslationFields])

    useEffect(() => {
        if (id !== "new") setProductStatsLink(`${window._env_.REACT_APP_BPN_PORTAL}${id}/stats`)
    }, [id])

    const onTranslatableFieldChanged = (propertyKey, updatedValue) => {
        setUnsavedChanges(true)
        updateProductState({...data, [propertyKey]: updatedValue})
    }

    const handleNotificationEmailUpdated = (propertyKey, updatedValue) => {
      setUnsavedChanges(true)
      updateProductState({...data, [propertyKey]: updatedValue})
    }

    const handleDateChange = (date, name) => {
        setData(prev => ({...prev, [name]: date ? date.getTime() : 0}))
        setUnsavedChanges(true)
        updateProductState({...data, [name]: date ? date.getTime() : 0})
    }

    const onValidationTypeChanged = (value) => {
        setData(prev => ({...prev, "maxValidations": value ? null : 1}))
        setUnsavedChanges(true)
        updateProductState({...data, "maxValidations": value ? null : 1})
    }

    const handleImageChange = async (image) => {
        setUploadingImage(true)
        const imageUrl = await uploadImage(image)
        setUploadingImage(false)
        setData(prev => ({...prev, "image": image ? image : null, "imageUrl": imageUrl}))
        setUnsavedChanges(true)
        updateProductState({...data, "image": image ? image : null, "imageUrl": imageUrl})
    }

    const handleDeleteProductImage = () => {
        setData(prev => ({...prev, "image": null, "imageUrl": null}))
        setUnsavedChanges(true)
        updateProductState({...data, "image": null, "imageUrl": null})
    }

    const handleDateClear = (event, name) => {
        event.stopPropagation()
        setUnsavedChanges(true)
        updateProductState({...data, [name]: null})
    }

    const uploadImage = async (image) => {
        setUploadProgress(0)
        if (!image) {
            throw new Error("File must be provided!")
        }

        const uploadTo = await bpnAPI.get(`media/image/order_upload_url/${encodeURI(image?.name)}?mime_type=${image?.file?.type}`)

        if (!uploadTo?.data?.upload_url) {
            throw uploadTo
        }

        // Upload the media
        const headers = new Headers({
            "Content-Type": image.type,
            "Slug": image.name,
        })

        const config = {
            method: "put",
            data: image.file,
            headers,
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setUploadProgress(percentCompleted * 0.9)
            },
        }

        try {
            await axios.put(uploadTo.data.upload_url, image.file, config)
        } catch (e) {
            console.log(e)
        }

        await new Promise(r => setTimeout(r, 3000))
        setUploadProgress(100)
        return uploadTo.data.url
    }

    const isInvalidEmailAddress = (emailAddress) => {
      if (!emailAddress || emailAddress=== "") {
        return false
      }
      return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress))
    }

    return (
        <form noValidate
              autoComplete="off">
            {data?.status === "published" && productStatsLink && (
                <Grid container
                      style={{marginBottom: 4}}
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}>
                    <Grid item>
                        <Typography><strong>{t("urlToProductStats")}: </strong></Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <Link href={productStatsLink}
                                  target="portalstats">
                                {productStatsLink}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {id !== "new" && (
                <Grid container
                      style={{marginBottom: 4}}
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}>
                    <Grid item>
                        <Typography><strong>{t("termsOfSaleUrl")}: </strong></Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {data?.museumSettings?.termsOfSaleUrl
                                ? <Link href={data?.museumSettings?.termsOfSaleUrl}
                                        target="_blank"
                                        rel="noopener">
                                    {data?.museumSettings?.termsOfSaleUrl}
                                </Link>
                                : <Typography variant="caption">
                                    {`${t("goTo")} `}
                                    <Link component="button"
                                          onClick={() => history.push(`${SETTINGS_BASE_URL}/other`)}>
                                        {t("settings").toLowerCase()}
                                    </Link> {lowerFirst(t("toDefineTermsOfSaleUrl"))}.
                                </Typography>
                            }
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <FormControlLabel
                control={
                    <Switch
                        checked={showTranslationFields}
                        onChange={() => setShowTranslationFields(!showTranslationFields)}
                        name="showTranslationFields"
                        color="primary"
                    />
                }
                label={product?.translations?.length > 0 ? t("showTranslations") : t("enableTranslations")}
                classes={{label: classes.switchLabel}}
            />
            <TranslatableTextField label={t("name")}
                                   required={true}
                                   error={!data?.name && unsavedChanges}
                                   helperText={!data?.name && unsavedChanges ? t("mustHaveName") : t("productNameHelperText")}
                                   name="name"
                                   multiline={true}
                                   value={data?.name || ""}
                                   onChange={(updatedValue) => onTranslatableFieldChanged("name", updatedValue)}
            />
            <Typography className={classes.textfield}><strong>{t("longDescription")}</strong></Typography>
            <FormControlLabel
              control={
                  <Switch
                    checked={data?.showDescriptionInReceipt || false}
                    onChange={(e) => onTranslatableFieldChanged(e.target.name, e.target.checked)}
                    name="showDescriptionInReceipt"
                    color="primary"
                  />
              }
              label={t("showDescriptionInReceipt")}
              classes={{label: classes.switchLabel}}/>
            <TranslatableRichTextField variant="filled"
                                       value={data?.description || ""}
                                       showTranslationFields={showTranslationFields}
                                       onChange={(updatedValue) => onTranslatableFieldChanged("description", updatedValue)}/>
            <Typography className={classes.textfield}><strong>{t("shortDescription")}</strong></Typography>
            <TranslatableRichTextField variant="filled"
                                       value={data?.shortDescription || ""}
                                       showTranslationFields={showTranslationFields}
                                       onChange={(updatedValue) => onTranslatableFieldChanged("shortDescription", updatedValue)}/>
            <Grid container
                  spacing={2}
                  justifyContent="space-between">
                <Grid item
                      xs={12}
                      sm={6}>
                    <MuiDateTimePicker selected={data?.availableFrom || null}
                                       label={t("availableFrom")}
                                       isClearable={true}
                                       maxDate={data?.availableTo || null}
                                       onClear={event => handleDateClear(event, "availableFrom")}
                                       name="availableFrom"
                                       className={classes.textfield}
                                       onChange={handleDateChange}/>
                </Grid>
                <Grid item
                      xs={12}
                      sm={6}>
                    <MuiDateTimePicker selected={data?.availableTo || null}
                                       label={t("availableTo")}
                                       name="availableTo"
                                       minDate={data?.availableFrom || null}
                                       isClearable={true}
                                       onClear={event => handleDateClear(event, "availableTo")}
                                       className={classes.textfield}
                                       onChange={handleDateChange}/>
                </Grid>
            </Grid>
            <Typography variant="caption"
                        color="textSecondary">
                {t("theTimePeriod")} {t(`the${toPascalCase(type)}`).toLowerCase()} {t("isAvailableForSale")
                .toLowerCase()}. {t("blankForNoLimitations")}.
            </Typography>

            <Typography className={classes.textfield}>
                <strong>{t("productImage")}</strong>
            </Typography>
            <Typography variant="caption"
                        color="textSecondary">
                {t("productImageHelperText")}
            </Typography>
            <Grid container
                  spacing={2}
                  justifyContent="space-between">
                <Grid item
                      xs={12}
                      sm={6}>
                    <UploadMediaButton isUploading={uploadingImage}
                                       mediaItem={data?.image}
                                       currentImageUrl={isImageReady ? product?.imageUrl : ""}
                                       onImageSelected={image => handleImageChange(image)}
                                       deleteProductImage={() => handleDeleteProductImage()}
                                       uploadProgress={uploadProgress}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={data?.showImageOnProductPage || false}
                                onChange={(e) => onTranslatableFieldChanged(e.target.name, e.target.checked)}
                                name="showImageOnProductPage"
                                color="primary"
                            />
                        }
                        label={t("showImageOnProductPage")}
                        classes={{label: classes.switchLabel}}
                    />
                </Grid>
            </Grid>
            {type !== PRODUCT_TYPE_SEASONAL_PASS &&
                <Grid container
                      spacing={2}
                      justifyContent="space-between">
                    <Grid item>
                        <Typography className={classes.textfield}>
                            <strong>{t("validation")}</strong>
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data?.maxValidations !== 1}
                                    onChange={(e) => onValidationTypeChanged(e.target.checked)}
                                    name="showImageOnProductPage"
                                    color="primary"
                                />
                            }
                            label={t("canValidateMultipleTimes")}
                            classes={{label: classes.switchLabel}}
                        />

                    </Grid>
                </Grid>
            }
          <Typography className={classes.textfield}>
            <strong>{t("emailNotification")}</strong>
          </Typography>
            <TextField variant={"filled"}
                       label={t("emailNotificationAddress")}
                       value={orderNotificationEmailAddress || ""}
                       onChange={(e) => setOrderNotificationEmailAddress(e.target.value)}
                       error={isInvalidEmailAddress(orderNotificationEmailAddress)}
                       helperText={t("emailNotificationAddressHelperText")}
                       onBlur={(e) => handleNotificationEmailUpdated("orderNotificationEmailAddress",
                         e.target.value)} fullWidth/>
        </form>
    )
}

export default GeneralSettings
