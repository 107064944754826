import React from "react"
import {CalendarToday, Visibility} from "@mui/icons-material"
import makeStyles from '@mui/styles/makeStyles';
import {Fab, Tooltip} from "@mui/material"

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        bottom: 0,
        textAlign: "center",
        right: "100px",
        zIndex: 2,
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            display: "none",
        },
        [theme.breakpoints.only("md")]: {
            width: "30%",
            right: "125px",
        },
        [theme.breakpoints.only("lg")]: {
            width: "40%",
            right: "140px",
        },
        [theme.breakpoints.only("xl")]: {
            width: "50%",
            right: "190px",
        },
    },
}))

const PreviewToolbar = ({selectedPreview, setSelectedPreview}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Tooltip title="Forhåndsvisning">
                <Fab style={{backgroundColor: selectedPreview === "preview" ? "white" : "lightgrey"}}
                     size={selectedPreview === "preview" ? "large" : "small"}
                     onClick={() => setSelectedPreview("preview")}>
                    <Visibility fontSize={selectedPreview === "preview" ? "large" : "small"}
                                color={selectedPreview === "preview" ? "primary" : "disabled"}/>
                </Fab>
            </Tooltip>
            <Tooltip title="Kalender">
                <Fab style={{backgroundColor: selectedPreview === "calendar" ? "white" : "lightgrey"}}
                     size={selectedPreview === "calendar" ? "large" : "small"}
                     onClick={() => setSelectedPreview("calendar")}>
                    <CalendarToday fontSize={selectedPreview === "calendar" ? "large" : "small"}
                                   color={selectedPreview === "calendar" ? "primary" : "disabled"}/>
                </Fab>
            </Tooltip>
        </div>
    )
}

export default PreviewToolbar