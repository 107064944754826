import React from "react"
import HelpButton from "../../../app/components/HelpButton"

const VismaVoucherPaymentSubTypesSettingsHelperText = () => (
    <HelpButton>
        Her settes debetkonto for hver type av betalingskort. <i>Disse innstillingene gjelder for alle museer i en
        stiftelse eller et selskap</i>, og overstyrer eventuell standard debetkonto satt i boksen over. <br/><br/>
        Hvis en ordre blir betalt med et betalingskort som ikke er definert, brukes standard debetkonto.
        <br/><br/>Hvis standard debetkonto heller ikke er satt på noe nivå, <u>kan ikke bilag genereres eller
        eksporteres.</u>
    </HelpButton>
)

export default VismaVoucherPaymentSubTypesSettingsHelperText