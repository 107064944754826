import Fade from "@mui/material/Fade"
import LinearProgress from "@mui/material/LinearProgress"
import React from "react"
import {Box, CircularProgress} from "@mui/material"

export const ProgressIndicator = ({linear = true, hide = false, size = 40}) => {
    return (
        linear
        ? <div>
            <Fade in={true}
                  style={{transitionDelay: "400ms"}}
                  unmountOnExit={true}>
                <LinearProgress style={{opacity: hide ? 0 : 1}}/>
            </Fade>
        </div>
        : <Box sx={{display: "flex"}}
               justifyContent="center">
            <Fade in={true}
                  style={{transitionDelay: "400ms"}}
                  unmountOnExit={true}>
                <CircularProgress size={size}
                                  style={{opacity: hide ? 0 : 1}}/>
            </Fade>
        </Box>
    )
}
