import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import ScrollableSectionView from "../app/ScrollableSectionView"
import SectionHeader from "../app/components/SectionHeader"
import {useBaseTranslation} from "../app/baseTranslationContext"
import TestOrdersSwitch from "../app/components/TestOrdersSwitch"
import {useTheme} from "@mui/material"
import {useMuseumSelectorState} from "../app/museum-selector/museumSelectorContext"
import {useAuthsState} from "../app/auths/authsContext"
import {DAILY} from "../app/helper-functions/dateFormat"
import {GROUP_BY_MUSEUM_NAME} from "../app/components/OrderSumsGroupBySelectMenu"
import {subDays} from "date-fns"
import SmallRevenueCard from "../app/revenue/SmallRevenueCard"
import {ChartCardAvatar} from "../app/components/avatars"
import SmallVisitorStatsCard from "../app/visitor-stats/SmallVisitorStatsCard"
import {useAxiosBpn} from "../app/axios"
import SmallProductListCard from "../app/components/SmallProductListCard"
import {ConfirmationNumber} from "@mui/icons-material"
import TicketStatusCard from "../app/ticket-status/TicketStatusCard"
import {STATS_TAB} from "../sales/visitors/Visitors"
import {ROLES_VALUES} from "../app/menu/MuseumSelector"

const LOCAL_STORAGE_KEY = "bpn.testOrders"
const NO_OF_DAYS = 30

export const Dashboard = () => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}, spacing} = useTheme()

    const {umbrellaAndChildrenIds, selectedMuseum} = useMuseumSelectorState()
    const {userAccessMuseumIds} = useAuthsState()
    const initialTestOrders = localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    const [testOrders, setTestOrders] = useState(initialTestOrders)
    const [frequency] = useState(DAILY)
    const [groupBy] = useState(GROUP_BY_MUSEUM_NAME)
    const [createdAfter] = useState(subDays(new Date(), NO_OF_DAYS).getTime())

    const [{data: orderSumsData, loading: loadingOrderSums}] = useAxiosBpn({
        url: "orders/sums",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                ? umbrellaAndChildrenIds.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
            groupBy: groupBy,
            groupByDateFrequency: frequency,
            test: testOrders,
            createdAfter: createdAfter,
        },
    })

    const [{data: visitorStatsData, loading: visitorStatsLoading}] = useAxiosBpn({
        url: "orders/visitors",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                ? umbrellaAndChildrenIds.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
            groupBy: groupBy,
            groupByDateFrequency: frequency,
            test: testOrders,
            visitorStats: true,
            after: createdAfter,
        },
    })

    const [{data: orderLinesData, loading: orderLinesLoading}] = useAxiosBpn({
        url: "order_lines/newest",
        params: {
            museumIds: umbrellaAndChildrenIds?.length
                ? umbrellaAndChildrenIds.join()
                : Array.from(userAccessMuseumIds.keys()).join(),
            test: testOrders,
        },
    })

    useEffect(() => localStorage.setItem(LOCAL_STORAGE_KEY, testOrders.toString()), [testOrders])

    const breadcrumbs = [
        {label: t("dashboard")},
    ]

    return (
        <>
            <SectionHeader breadcrumbs={breadcrumbs}>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </SectionHeader>
            <ScrollableSectionView>
                <Grid container
                      style={{paddingTop: spacing(2)}}
                      justifyContent="space-between"
                      alignItems="stretch"
                      spacing={2}>
                    {selectedMuseum?.role?.value >= ROLES_VALUES.EDITOR && (
                        <>
                            <Grid item
                                  xs={12}
                                  xl={6}>
                                <SmallRevenueCard data={orderSumsData}
                                                  loading={loadingOrderSums}
                                                  frequency={frequency}
                                                  groupBy={groupBy}
                                                  shortcutTo={{url: "/sales/revenue"}}
                                                  testOrders={testOrders}
                                                  title={t("revenue")}
                                                  subheader="Pr. museum siste tredve dager"
                                                  avatar={<ChartCardAvatar backgroundColor={testOrders
                                                      ? testOrdersPalette?.dark
                                                      : null}/>}/>
                            </Grid>

                            <Grid item
                                  xs={12}
                                  xl={6}>
                                <SmallVisitorStatsCard data={visitorStatsData}
                                                       loading={visitorStatsLoading}
                                                       frequency={frequency}
                                                       groupBy={groupBy}
                                                       testOrders={testOrders}
                                                       shortcutTo={{url: `/sales/visitors?tab=${STATS_TAB}`}}
                                                       title={t("visitorNumbers")}
                                                       subheader="Pr. museum siste tredve dager"
                                                       avatar={<ChartCardAvatar backgroundColor={testOrders
                                                           ? testOrdersPalette?.dark
                                                           : null}/>}/>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container
                      justifyContent="space-between"
                      alignItems="stretch"
                      style={{paddingTop: spacing(1)}}
                      spacing={2}>
                    <Grid item
                          xs={12}
                          xl={6}>
                        <SmallProductListCard data={orderLinesData}
                                              loading={orderLinesLoading}
                                              testOrders={testOrders}
                                              title="Nylig salg"
                                              avatar={<ConfirmationNumber/>}
                                              subheader="Pr. produkt"/>
                    </Grid>
                    <Grid item
                          xs={12}
                          xl={6}>
                        <TicketStatusCard testOrders={testOrders}/>
                    </Grid>
                </Grid>
            </ScrollableSectionView>
        </>
    )
}