import React from "react"
import {Typography} from "@mui/material"

const UserPortalRegistrationSettingsHelperText = () => {
    return (
        <>
            <Typography variant="body2">
                Benytt dette kortet til å tilpasse hva som skal vises til kunder når de når registreringssteget i kjøpsportalen.
            </Typography>
        </>
    )
}

export default UserPortalRegistrationSettingsHelperText
