import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {ProgressIndicator} from "../ProgressIndicator"
import {Avatar, Button, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Tooltip} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {lowerFirst} from "lodash"
import {formatNOK, formatPercent} from "../helper-functions/numberFormat"
import {GridLabel, GridValue} from "./gridComponents"
import {useHistory} from "react-router-dom"
import {List} from "@mui/icons-material"
import {getBaseRouteBasedOnProductType} from "../helper-functions/getBaseUrlBasedOnProductType"
import EditIcon from "@mui/icons-material/Edit"
import {deepOrange} from "@mui/material/colors"
import {getValidityString} from "../helper-functions/dateFormat"
import ProductTypeIcon from "../components/ProductTypeIcon"

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: deepOrange[300],
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}))

const OrderLineDetail = ({orderLine, loading}) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const history = useHistory()

    const [expanded, setExpanded] = useState(false)
    const [gridRows, setGridRows] = useState([])

    useEffect(() => {
        if (orderLine) {
            setGridRows([
                {label: t("id"), value: orderLine?.id},
                {label: `${t("museum")} (id)`, value: `${orderLine?.museumName} (${orderLine?.museumId})`},
                {label: `${t("product")} (id)`, value: `${orderLine?.productName} (${orderLine?.productId})`},
                {label: t("priceCategory"), value: orderLine?.productPriceCategoryName},
                {label: t("unitsSold"), value: orderLine?.quantity},
                {label: t("price"), value: formatNOK(orderLine?.price)},
                {label: t("validity"), value: getValidityString(orderLine)},
                {label: t("vat"), value: formatPercent(orderLine?.vat)},
                {
                    label: t("shortcutTo"),
                    value:
                        <Button variant="text"
                                color="primary"
                                onClick={() => history.push(`/${getBaseRouteBasedOnProductType(orderLine?.productType)}/${orderLine?.productId}/details`)}
                                startIcon={<List fontSize="small"/>}
                                size="small">
                            {t("productDetails")}
                        </Button>,
                },
                {
                    label: null,
                    value:
                        <Button variant="text"
                                color="primary"
                                onClick={() => history.push(`/${getBaseRouteBasedOnProductType(orderLine?.productType)}/${orderLine?.productId}/edit`)}
                                startIcon={<EditIcon fontSize="small"/>}
                                size="small">
                            {t("productEditor")}
                        </Button>,
                },
            ])
        }
    }, [history, orderLine, t])

    function getTitle() {
        return (
            <Grid container
                  spacing={1}>
                <Grid item><strong>{orderLine?.productName}</strong></Grid>
                <Grid item>{`( ${orderLine?.museumName} )`}</Grid>
            </Grid>
        )
    }

    function getSubheader() {
        return (
            <Grid container
                  spacing={1}>
                <Grid item> {orderLine?.productPriceCategoryName} </Grid>
                <Grid item>&bull;</Grid>
                <Grid item>{`${orderLine?.quantity} ${lowerFirst(t("pcs"))}`}</Grid>
                <Grid item>&bull;</Grid>
                <Grid item>{formatNOK(orderLine?.price)}</Grid>
                <Grid item>&bull;</Grid>
                <Grid item>{`${formatPercent(orderLine?.vat)} ${t("vat")}`}</Grid>
            </Grid>
        )
    }

    function getAction() {
        return (
            <IconButton
                className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
                onClick={() => setExpanded(!expanded)}
                size="large">
                <ExpandMoreIcon/>
            </IconButton>
        );
    }

    function getAvatar() {
        return (
            <Avatar style={{backgroundColor: deepOrange[300]}}>
                <Tooltip title={t(orderLine?.productType)}>
                    <div>
                        <ProductTypeIcon type={orderLine?.productType}/>
                    </div>
                </Tooltip>
            </Avatar>
        )
    }

    return loading
           ? <ProgressIndicator/>
           : <Card square
                   variant="outlined"
                   style={{marginTop: 8}}>
               <CardHeader title={getTitle()}
                           subheader={getSubheader()}
                           avatar={getAvatar()}
                           action={getAction()}/>
               <Collapse in={expanded}
                         timeout="auto"
                         unmountOnExit>
                   <CardContent>
                       <Grid container
                             direction="column"
                             style={{marginLeft: 32}}>
                           {gridRows?.map(({label, value}, index) =>
                               value &&
                               <Grid container
                                     key={index}
                                     alignItems="center">
                                   <GridLabel size="small">{label && `${label}:`}</GridLabel>
                                   <GridValue size="small">{value}</GridValue>
                               </Grid>,
                           )}
                       </Grid>
                   </CardContent>
               </Collapse>
           </Card>
}

export default OrderLineDetail