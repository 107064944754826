import React from "react"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import ReactDOM from "react-dom"
import App from "./app/App"
import "./app/i18n"
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(<App/>, document.getElementById("root"))

serviceWorker.unregister()