import {IconButton, TableCell} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import React, {useState} from "react"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {useAxiosBpn} from "../../../app/axios"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import EditIcon from "@mui/icons-material/Edit"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import ConfirmationDialog from "../../../app/components/ConfirmationDialog"

const PriceCategoryTable = ({
                                priceCategories,
                                onEditPriceCategory,
                                removeFromPriceCategories,
                            }) => {
    const t = useBaseTranslation()
    const snackbar = useCustomSnackbar()

    const [hasSaleWarningDialogOpen, setHasSaleWarningDialogOpen] = useState(false)
    const [tryingToDeletePriceCategory, setTryingToDeletePriceCategory] = useState({index: null, id: null})

    const [{loading: deleteLoading}, deletePriceCategory] = useAxiosBpn({
        method: "DELETE",
    }, {manual: true})


    const [{loading: priceCategoryLoading}, getPriceCategory] = useAxiosBpn({
        params: {checkIfHasSale: true}
    }, {manual: true})

    const handleDelete = (index, priceCategoryId, warningAccepted = false) => {
        setTryingToDeletePriceCategory({index: index, id: priceCategoryId})

        if (!warningAccepted) {
            getPriceCategory({url: `price_categories/${priceCategoryId}`})
                .then(({data: {priceCategory: {hasSale}}}) => {
                    if (hasSale) setHasSaleWarningDialogOpen(true)
                    else handleDelete(index, priceCategoryId, true)
                }).catch(() => {
            })
        } else {
            deletePriceCategory({url: `price_categories/${priceCategoryId}`}).then(() => {
                snackbar.showSuccess(`${t("priceCategory")} ${t("deleted").toLowerCase()}`)
                removeFromPriceCategories(index)
                setHasSaleWarningDialogOpen(false)
            }).catch(() => {
            })
        }
    }

    const getCountAsFromPriceCategory = (priceCategory) => {
        let countAsString = ""
        if (priceCategory.groupTicket) {
            priceCategory.groupTicket.forEach(category => {
                countAsString = countAsString + `${category.ticketCount} ${category.priceCategory}, `
            })

            countAsString = countAsString.substring(0, countAsString.length - 2)
        }

        return countAsString
    }

    return priceCategories?.length
        ? <>
            {deleteLoading || priceCategoryLoading
                ? <ProgressIndicator/>
                : <TableContainer>
                    <Table sx={{minWidth: 650}}
                           aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t("id")}</TableCell>
                                <TableCell align="left">{t("name")}</TableCell>
                                <TableCell align="center">{t("productGroup")}</TableCell>
                                <TableCell align="center">{t("includeInVisitorStatistics")}</TableCell>
                                <TableCell align="center">{t("excludeFromCapacityCalculation")}</TableCell>
                                <TableCell align="left">{t("countsAs")}</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {priceCategories.map((priceCategory, index) => (
                                <TableRow key={priceCategory.id}
                                          sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell align="center"
                                               component="th"
                                               scope="row">
                                        {priceCategory.id}
                                    </TableCell>
                                    <TableCell align="left">{priceCategory.name}</TableCell>
                                    <TableCell align="center">{priceCategory.productGroupId}</TableCell>
                                    <TableCell align="center">
                                        {priceCategory.includedInVisitorStats ? "✓" : ""}
                                    </TableCell>
                                    <TableCell align="center">
                                        {priceCategory?.excludedFromCapacityCalculation ? "✓" : ""}
                                    </TableCell>
                                    <TableCell align="left">{getCountAsFromPriceCategory(priceCategory)} </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onEditPriceCategory(priceCategory)}
                                            starticon={<EditIcon/>}
                                            size="large">
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(index, priceCategory.id)} size="large">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            <ConfirmationDialog open={hasSaleWarningDialogOpen}
                                handleAccept={() => handleDelete(tryingToDeletePriceCategory.index, tryingToDeletePriceCategory.id, true)}
                                handleClose={() => setHasSaleWarningDialogOpen(false)}>
                Det er salg knyttet til denne priskategorien! <br/><br/>
                <strong>Er du sikker på at du vil slette?</strong>
            </ConfirmationDialog>
        </>
        : null;
}

export default PriceCategoryTable