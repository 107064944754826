import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import React from "react"
import {useBaseTranslation} from "../../../app/baseTranslationContext"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"

const GroupTicketCategory = ({
                                 ticketCategory,
                                 priceCategory,
                                 onChangedTicketCategory,
                                 availablePriceCategories,
                                 onDeleteTicketCategory,
                             }) => {
    const t = useBaseTranslation()

    const isPriceCategoryUsedInGroupTicket = (priceCategoryName) => {
        return priceCategory.groupTicket.filter(category => category.priceCategory === priceCategoryName).length > 0
    }

    return <>
        <Grid item
              xs={7}
              md={9}>
            <TextField label={t("priceCategory")}
                       required
                       name="priceCategory"
                       variant="outlined"
                       select
                       error={!ticketCategory?.priceCategory || ticketCategory.priceCategory === ""}
                       fullWidth
                       size="small"
                       value={ticketCategory?.priceCategory}>
                {availablePriceCategories.map(({id, name: priceCategory}, _index) => (
                    <MenuItem key={`${id}-${priceCategory}`}
                              onClick={() => onChangedTicketCategory("priceCategory", priceCategory)}
                              value={priceCategory}
                              disabled={isPriceCategoryUsedInGroupTicket(priceCategory)}>
                        {priceCategory}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        <Grid item
              xs={4}
              md={2}>
            <TextField
                id="ticketCount"
                variant="outlined"
                label={t("quantity")}
                error={!ticketCategory?.ticketCount || ticketCategory.ticketCount <= 0}
                size="small"
                type="number"
                InputProps={{inputProps: {min: 0}}}
                value={ticketCategory?.ticketCount}
                onChange={(e) => onChangedTicketCategory("ticketCount", e.target.value)}
            />
        </Grid>
        <Grid item
              xs={1}
              md={1}>
            <IconButton onClick={() => onDeleteTicketCategory(ticketCategory)} size="large">
                <DeleteIcon/>
            </IconButton>
        </Grid>

    </>;
}

export default GroupTicketCategory