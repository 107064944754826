import React, {useEffect, useState} from "react"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"
import useCustomSnackbar from "../../app/components/useCustomSnackbar"
import {useAxiosBpn} from "../../app/axios"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import MenuItem from "@mui/material/MenuItem"
import FamilyDiscountAlerts from "./family-discounts/FamilyDiscountAlerts";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert } from '@mui/material';
import {getFamilyDiscountLimitsString} from "./family-discounts/FamilyDiscountsTable";

const defaultLimit = {familyDiscountId: null, priceCategoryId: null, min: 0, max: 0}

const EditOrCreateFamilyDiscountDialog = ({
                                              open,
                                              handleClose,
                                              familyDiscountToEdit,
                                              availablePriceCategories,
                                              onFamilyDiscountSaved,
                                          }) => {
    const t = useBaseTranslation()
    const {umbrellaId} = useMuseumSelectorState()
    const snackbar = useCustomSnackbar()

    const [limits, setLimits] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [_availablePriceCategories, setAvailablePriceCategories] = useState([])
    const [errors, setErrors] = useState({test: false})
    const [familyDiscount, setFamilyDiscount] = useState(familyDiscountToEdit || {
        new: true,
        active: false,
        name: "",
        museumId: umbrellaId,
        limits: [],
    })

    const [{error: postError}, postFamilyDiscount] = useAxiosBpn({
        url: "family_discounts",
        method: "POST",
    }, {manual: true})

    const [{error: putError}, putFamilyDiscount] = useAxiosBpn({
        url: `family_discounts/${familyDiscount?.id}`,
        method: "PUT",
    }, {manual: true})

    useEffect(() => {
        if (familyDiscount?.limits) setLimits(familyDiscount.limits)
    }, [familyDiscount])

    useEffect(() => {
        setAvailablePriceCategories(availablePriceCategories.filter(o => !o.groupTicket)
        )
    }, [availablePriceCategories, familyDiscount])

    useEffect(() => {
        const error = postError || putError || null
        if (error) {
            snackbar.showError(t(error.message))
        }
    }, [postError, putError, snackbar, t])

    const handleSave = () => {
        if (familyDiscount?.new) {
            postFamilyDiscount({data: familyDiscount})
                .then(() => {
                    snackbar.showSuccess(`${t("familyDiscount")} ${t("added").toLowerCase()}`)
                    onFamilyDiscountSaved()
                    setUnsavedChanges(false)
                })
        } else {
            putFamilyDiscount({data: familyDiscount})
                .then(() => {
                    snackbar.showSuccess(`${t("familyDiscount")} ${t("updated").toLowerCase()}`)
                    onFamilyDiscountSaved()
                    setUnsavedChanges(false)
                })
        }
    }

    const handleChange = event => {
        event.persist()
        setFamilyDiscount(prev => ({...prev, [event.target.name]: event.target.value}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleAddLimitClick = () => {
        setLimits(prevState => ([...prevState, defaultLimit]))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    function handleLimitsChange(_limits) {
        setLimits(_limits)
        setFamilyDiscount(prevState => ({...prevState, limits: _limits}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handlePriceCategorySelect = (index, id, name) => {
        let _limits = limits
        _limits[index] = {..._limits[index], priceCategoryId: id, priceCategoryName: name}
        handleLimitsChange(_limits)
    }

    const handleActiveChange = () => {
        setFamilyDiscount(prevState => ({
            ...prevState,
            active: !prevState.active,
        }))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleLimitMinMaxChange = (event, index) => {
        let newMinMax
        let _limits = limits

        if (event.target.name === "min") {
            const newMin = event.target.value < 1 ? 1 : event.target.value
            newMinMax = {min: newMin, max: _limits[index].max < newMin ? newMin : _limits[index].max}
        } else {
            const newMax = event.target.value < 1 ? 1 : event.target.value
            newMinMax = {min: _limits[index].min > newMax ? newMax : _limits[index].min, max: newMax}
        }

        _limits[index] = {..._limits[index], ...newMinMax}
        handleLimitsChange(_limits)
    }

    const handleDelete = (limit) => {
        const _limits = limits?.filter(l => l !== limit)
        setLimits(_limits)
        handleLimitsChange(_limits)
    }

    const updateErrorState = (title, error) => {
        if (error) {
            if (!errors[title]) setErrors(prev => ({...prev, [title]: true}))
        } else {
            if (errors[title]) setErrors(prev => ({...prev, [title]: false}))
        }
    }

    const isFamilyDiscountValid = () => {
        let isValid = true
        let nameMissing = false
        let categoryMissing = false
        let noFreeTicketsPossible = false

        if (!familyDiscount?.name) {
            isValid = false
            nameMissing = true
        }

        let possibleFreeTickets = 0

        for (const {priceCategoryId, min, max} of familyDiscount?.limits) {
            if (!priceCategoryId) {
                isValid = false
                categoryMissing = true
            }
            if (min === 0 && max === 0) isValid = false
            if (familyDiscount.limits.filter(({priceCategoryId: id}) => id === priceCategoryId).length > 1) {
                isValid = false
            }

            possibleFreeTickets += (max - min)
        }

        if (!possibleFreeTickets) {
            isValid = false
            noFreeTicketsPossible = true
        }

        updateErrorState("noFreeTicketsPossible", noFreeTicketsPossible)
        updateErrorState("nameMissing", nameMissing)
        updateErrorState("categoryMissing", categoryMissing)

        return isValid
    }

    const LimitsDescription = () => {
        let possibleFreeTickets = []
        let otherTickets = []

        for (const {priceCategoryName, min, max} of familyDiscount?.limits) {
            const possibleFree = max - min

            if (possibleFree) {
                possibleFreeTickets.push({priceCategory: priceCategoryName, free: possibleFree, fullPrice: min})
            } else {
                otherTickets.push({priceCategory: priceCategoryName, fullPrice: max})
            }
        }

        let otherTicketsString = ""
        for (const [index, {priceCategory, fullPrice}] of otherTickets.entries()) {
            const string = `${fullPrice} ${priceCategory}`
            if (otherTickets.length === 1) {
                otherTicketsString += string
            } else {
                if ((index + 1) < (otherTickets.length)) {
                    otherTicketsString += `${string}, `
                } else {
                    otherTicketsString += `${string} og `
                }
            }
        }

        let fullPriceTicketsString = ""
        for (const [index, {priceCategory, fullPrice}] of possibleFreeTickets.entries()) {
            const string = `${fullPrice} ${priceCategory}`
            if (possibleFreeTickets.length === 1) {
                fullPriceTicketsString += string
            } else {
                if ((index + 1) < (possibleFreeTickets.length - 1)) {
                    fullPriceTicketsString += `${string}, `
                } else if ((index + 1) < possibleFreeTickets.length) {
                    fullPriceTicketsString += `${string} og `
                } else {
                    fullPriceTicketsString += string
                }
            }
        }

        let possibleFreeTicketsString = ""
        for (const [index, {priceCategory, free}] of possibleFreeTickets.entries()) {
            const string = `${free} ${priceCategory}`
            if (possibleFreeTickets.length === 1) {
                possibleFreeTicketsString += string
            } else {
                if ((index + 1) < (possibleFreeTickets.length - 1)) {
                    possibleFreeTicketsString += `${string}, `
                } else if ((index + 1) < possibleFreeTickets.length) {
                    possibleFreeTicketsString += `${string} og `
                } else {
                    possibleFreeTicketsString += string
                }
            }
        }

        return (
            <>
                <strong>{getFamilyDiscountLimitsString(familyDiscount?.limits)}</strong><br/><br/>
                Ved kjøp av følgende
                billetter; <i>{otherTicketsString.length ? `${otherTicketsString} ` : ""}{fullPriceTicketsString}</i>,
                er det mulig å få inntil <strong>{possibleFreeTicketsString}</strong> gratis.
            </>
        )
    }

    const handleEmptyAndClose = () => {
        handleClose()
    }

    return (
        <Dialog open={open}
                onClose={handleEmptyAndClose}
                fullWidth
                maxWidth="md">
            <DialogTitle>
                {`${familyDiscount && familyDiscount?.id
                    ? t("edit")
                    : t("add")} ${t("familyDiscount").toLowerCase()}`}
            </DialogTitle>
            <DialogContent>
                {unsavedChanges && <FamilyDiscountAlerts {...errors} />}
                <Grid container
                      spacing={1}
                      direction="column">
                    <Grid item>
                        <FormControlLabel label={familyDiscount?.active ? t("active") : t("notActive")}
                                          control={<Switch checked={familyDiscount?.active}
                                                           onChange={() => handleActiveChange()}/>}/>
                    </Grid>
                    <Grid item>
                        <TextField name="name"
                                   label={t("name")}
                                   fullWidth
                                   variant={"outlined"}
                                   helperText={"Vises i handlekurv og på kvittering"}
                                   error={unsavedChanges && !familyDiscount?.name}
                                   type="text"
                                   required
                                   value={familyDiscount?.name || ""}
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item style={{display: "flex", justifyContent: "flex-end", marginTop: 16}}>
                        <Tooltip title={t("addNewRule")}>
                            <Button variant="outlined"
                                    color="primary"
                                    onClick={handleAddLimitClick}
                                    endIcon={<AddIcon/>}>
                                {t("rule")}
                            </Button>
                        </Tooltip>
                    </Grid>
                    {limits?.map((limit, index) => (
                        <Grid item key={index}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField label={t("priceCategory")}
                                               required
                                               name="priceCategoryId"
                                               variant="outlined"
                                               error={unsavedChanges && errors?.categoryMissing}
                                               select
                                               fullWidth
                                               size="small"
                                               value={limit?.priceCategoryId || ""}>
                                        {_availablePriceCategories.map(({id, name: priceCategoryName}, _index) => (
                                            <MenuItem key={id}
                                                      disabled={familyDiscount?.limits?.some(({priceCategoryId}) => priceCategoryId === id)}
                                                      onClick={() => handlePriceCategorySelect(index, id, priceCategoryName)}
                                                      value={id}>
                                                {priceCategoryName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs>
                                    <TextField label={t("minimum")}
                                               variant="outlined"
                                               name="min"
                                               fullWidth
                                               error={limit?.min === 0 || limit?.min === '0'}
                                               type="number"
                                               size="small"
                                               onChange={e => handleLimitMinMaxChange(e, index)}
                                               value={limit?.min || ""}/>
                                </Grid>
                                <Grid item xs>
                                    <TextField label={t("maximum")}
                                               variant="outlined"
                                               name="max"
                                               type="number"
                                               error={limit?.max === 0 || limit?.max === '0'}
                                               fullWidth
                                               size="small"
                                               onChange={e => handleLimitMinMaxChange(e, index)}
                                               value={limit?.max || ""}/>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleDelete(limit)} size="large"><DeleteIcon/></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                {isFamilyDiscountValid() && <Alert style={{marginTop: 8}} severity="info"><LimitsDescription/></Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleEmptyAndClose}
                        color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={handleSave}
                        color="primary"
                        disabled={!isFamilyDiscountValid() || familyDiscountToEdit === familyDiscount}>
                    {t("save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditOrCreateFamilyDiscountDialog