import React, {useState} from "react"
import {Button, Checkbox, Collapse, IconButton, TableCell, TableRow, Typography} from "@mui/material"
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material"
import GenericTable, {getGenericTableCellAlign, getGenericTableCellVariant} from "./GenericTable"
import makeStyles from '@mui/styles/makeStyles';

const useRowStyles = makeStyles({
    collapsible: {
        "& > *": {
            borderBottom: "unset",
        },
    },
})

const GenericTableRow = ({
                             row,
                             columns,
                             hideEmptyRows,
                             size,
                             handleRowClick,
                             isSelected,
                             collapsible,
                             emptyFirstCell,
                             selectable,
                             totalsRow = false,
                             totalsColumn = false,
                         }) => {
    const classes = useRowStyles()
    const [open, setOpen] = useState(false)

    const getFirstCell = () => {
        if (collapsible) {
            return (
                <TableCell align="right"
                           style={{borderBottom: "none"}}>
                    {row?.collapsibles && (
                        <IconButton size="small"
                                    onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                        </IconButton>
                    )}
                </TableCell>
            )
        } else if (selectable) {
            return (
                <TableCell padding="checkbox"
                           style={{borderBottom: "none"}}>
                    <Checkbox checked={isSelected}/>
                </TableCell>
            )
        } else if (emptyFirstCell) {
            return (
                <TableCell style={{borderBottom: "none"}}/>
            )
        }
    }

    const skipRow = (_row, hideEmptyRows) => {
        const rowEmpty = () => {
            let sum = 0
            Object.entries(_row).forEach(([key, value]) => {
                if (key !== "id" && !isNaN(value)) {
                    sum += value
                }
            })
            return sum === 0
        }

        if (hideEmptyRows) {
            return rowEmpty()
        } else {
            return false
        }
    }

    const negativeTotals = () => {
        if (totalsColumn) {
            const lastColumnName = columns[columns.length - 1].field
            if (row[lastColumnName] < 0) return true
        }
    }

    const rowClassName = collapsible ? classes.collapsible : null
    const rowRole = selectable ? "checkbox" : "row"

    return (
        <>
            <TableRow className={rowClassName}
                      role={rowRole}
                      tabIndex={-1}
                      selected={isSelected}
                      onClick={(event) => handleRowClick(event, row?.id)}
                      hover={selectable}>
                {!skipRow(row, hideEmptyRows) && (
                    <>
                        {getFirstCell()}
                        {columns?.map(({
                                           align,
                                           field,
                                           valueFormatter,
                                           hide = false,
                                           type,
                                           onClick,
                                       },
                                       _index) => {
                            const value = row[field]
                            const format = () => valueFormatter ? valueFormatter({value: value}) : value
                            const isLastColumn = totalsColumn && _index === columns.length - 1

                            const getTableCell = () => <>
                                {onClick
                                 ? <Button variant="text"
                                           color="primary"
                                           size="small"
                                           onClick={onClick}>
                                     {format()}
                                 </Button>
                                 : <Typography variant={getGenericTableCellVariant(size, totalsRow, isLastColumn)}
                                               color={negativeTotals() ? "textSecondary" : "textPrimary"}>
                                     {format()}
                                 </Typography>
                                }
                            </>

                            return hide ? null : (
                                <TableCell key={`${field}-${_index}`}
                                           style={{
                                               borderBottom: "none",
                                               borderTop: totalsRow && !collapsible
                                                          ? "1px solid rgba(224, 224, 224, 1)"
                                                          : null,
                                               borderLeft: isLastColumn ? "1px solid rgba(224, 224, 224, 1)" : null,
                                               paddingTop: size === "small" && !selectable ? 0 : null,
                                               paddingBottom: size === "small" && !selectable ? 0 : null,
                                           }}
                                           align={getGenericTableCellAlign(align, type)}>
                                    {getTableCell()}
                                </TableCell>
                            )
                        })}
                    </>)
                }
            </TableRow>
            {collapsible && (
                <TableRow style={{backgroundColor: "#eeeeee"}}>
                    <TableCell style={{padding: 0}}
                               colSpan={columns?.length + 1}>
                        <Collapse in={open}
                                  timeout="auto"
                                  unmountOnExit>
                            {row?.collapsibles?.map(({title, columns: _columns, rows}, _index) => (
                                <div key={_index}
                                     style={{marginTop: 8, marginBottom: 16}}>
                                    {title && (
                                        <Typography variant="button"
                                                    color="primary">{title}</Typography>
                                    )}
                                    <GenericTable columns={_columns}
                                                  rows={rows}
                                                  emptyFirstCell
                                                  size="small"
                                                  totalsColumn={totalsColumn}/>
                                </div>
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default GenericTableRow