import {Grid, Hidden, IconButton, Typography} from "@mui/material"
import TextField from "@mui/material/TextField"
import SaveIcon from "@mui/icons-material/Save"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {useAxiosBpn} from "../../../app/axios"
import {ProgressIndicator} from "../../../app/ProgressIndicator"
import _ from "lodash"
import {getErrorMessage} from "../../../app/helper-functions/getErrorMessage"
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    warning: {
        textAlign: "right",
        color: "lightgray",
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(-1),
    },
}))

const KdrMenuIdSetting = ({data}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    const [setting, setSetting] = useState(data)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const snackbar = useCustomSnackbar()

    const [{data: postData, loading: postLoading, error: postError}, postKdrLocalSettings] = useAxiosBpn({
        url: "point_of_sale_systems/kdr/local_settings",
        method: "POST",
    }, {manual: true})

    useEffect(() => {
        if (postData) {
            snackbar.showSuccess(`${t("menuId")} ${t("updated").toLowerCase()}`)
            setSetting(postData.kdrLocalSettings)
            setUnsavedChanges(false)
        }
    }, [postData, snackbar, t])

    useEffect(() => {
        if (postError) {
            snackbar.showError(getErrorMessage(postError.response, postError.message, t))
        }
    }, [postError, snackbar, t])

    const handleChange = e => {
        e.persist()
        setSetting(prev => ({...prev, [e.target.name]: e.target.value}))

        if (!unsavedChanges) {
            setUnsavedChanges(true)
        }
    }

    const handleSave = () => {
        postKdrLocalSettings({data: setting})
    }

    const handleSubmit = () => {
        if (!_.isEqual(setting, data)) {
            setUnsavedChanges(true)
        }
    }

    return <>
        {postLoading ? <ProgressIndicator/> : (
            <form noValidate
                  className={classes.root}
                  autoComplete="off"
                  onBlur={handleSubmit}>
                <Grid container
                      wrap="nowrap"
                      spacing={1}
                      alignItems="center">
                    <Grid item
                          xs={6}
                          md={7}
                          lg={8}>
                        <TextField label={t("museum")}
                                   name="museumName"
                                   variant="standard"
                                   size="small"
                                   fullWidth
                                   disabled
                                   value={setting.museumName || ""}/>
                    </Grid>
                    <Grid item
                          xs={4}
                          md={3}
                          lg={2}>
                        <TextField label={t("menuId")}
                                   name="menuId"
                                   variant="outlined"
                                   size="small"
                                   type="number"
                                   fullWidth
                                   onChange={handleChange}
                                   value={setting.menuId || ""}/>
                    </Grid>
                    <Grid item
                          xs={1}>
                        <IconButton disabled={!unsavedChanges} onClick={handleSave} size="large">
                            <SaveIcon/>
                        </IconButton>
                    </Grid>
                    {unsavedChanges &&
                     <Hidden mdDown>
                         <Grid item
                               md={1}>
                             <Typography variant="subtitle2"
                                         className={classes.warning}>
                                 {t("unsavedChanges") + "!"}
                             </Typography>
                         </Grid>
                     </Hidden>
                    }
                </Grid>
                {unsavedChanges &&
                 <Hidden mdUp>
                     <Typography variant="subtitle2"
                                 className={classes.warning}>
                         {t("unsavedChanges") + "!"}
                     </Typography>
                 </Hidden>
                }
            </form>
        )}
    </>;
}

export default KdrMenuIdSetting