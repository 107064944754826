import React, {useEffect, useState} from "react"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
} from "@mui/material"
import {useMuseumSelectorState} from "../../../app/museum-selector/museumSelectorContext"
import Button from "@mui/material/Button"
import useCustomSnackbar from "../../../app/components/useCustomSnackbar"
import {useAxiosBpn} from "../../../app/axios"
import makeStyles from '@mui/styles/makeStyles';
import {useBaseTranslation} from "../../../app/baseTranslationContext"

const useStyles = makeStyles(theme => ({
    switch: {
        marginLeft: theme.spacing(1),
        verticalAlign: "middle",
    },
    switchLabel: {
        fontSize: "0.9em",
    },
}))

const initialData = {
    merchantId: "",
    museumId: 0,
    museumName: "",
    departmentId: 0,
    domainId: "",
    partnerId: "",
}

const KdrDialog = ({pointOfSaleSystem, open, handleClose}) => {
    const t = useBaseTranslation()
    const classes = useStyles()

    const {umbrellaId, umbrellaName} = useMuseumSelectorState()
    const [data, setData] = useState({...initialData})
    const snackbar = useCustomSnackbar()

    const [{error: postError}, postKdr] = useAxiosBpn({
        url: "point_of_sale_systems/kdr",
        method: "POST",
    }, {manual: true})

    useEffect(() => {
        if (pointOfSaleSystem) {
            setData(prev => ({
                    ...prev,
                    merchantId: pointOfSaleSystem.merchantId,
                    departmentId: pointOfSaleSystem.departmentId,
                    domainId: pointOfSaleSystem.domainId,
                    partnerId: pointOfSaleSystem.partnerId,
                    enabled: pointOfSaleSystem.enabled,
                    museumId: umbrellaId,
                    museumName: umbrellaName,
                }),
            )
        }
    }, [pointOfSaleSystem, umbrellaId, umbrellaName])

    useEffect(() => {
        if (postError) {
            snackbar.showError(t(postError.message))
        }
    }, [postError, snackbar, t])

    const handleChange = event => {
        event.persist()
        setData(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    const handleEnabledChange = () => {
        setData(prev => ({...prev, enabled: !data.enabled}))
    }

    const handleEmptyAndClose = () => {
        setData({...initialData})
        handleClose()
    }

    const handleSave = () => {
        postKdr({data: data}).then(() => {
            snackbar.showSuccess(`${t("pointOfSaleSystem")} KDR ${pointOfSaleSystem.id
                                                                  ? t("edited")
                                                                  : t("added").toLowerCase()}`)
            handleEmptyAndClose()
        })
    }

    return (
        <Dialog open={open}
                onClose={handleEmptyAndClose}
                fullWidth
                maxWidth="sm">
            <DialogTitle>
                {`${pointOfSaleSystem && pointOfSaleSystem.id
                    ? t("edit")
                    : t("add")} ${t("kdr")}-${t("merchant").toLowerCase()}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("productionEnvironment")}
                </DialogContentText>
                <Grid container
                      spacing={1}
                      direction="column">
                    <Grid item>
                        <FormControlLabel label={t("activateKdrIntegration")}
                                          classes={{root: classes.switch}}
                                          labelPlacement="start"
                                          control={
                                              <Switch checked={data.enabled || false}
                                                      onChange={handleEnabledChange}/>
                                          }/>
                    </Grid>
                    <Grid item>
                        <TextField name="departmentId"
                                   label={t("departmentId")}
                                   fullWidth
                                   type="number"
                                   required
                                   value={data.departmentId || ""}
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item>
                        <TextField name="merchantId"
                                   label={t("merchantId")}
                                   fullWidth
                                   required
                                   value={data.merchantId || ""}
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item>
                        <TextField name="domainId"
                                   label={t("domainId")}
                                   fullWidth
                                   required
                                   value={data.domainId || ""}
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item>
                        <TextField name="partnerId"
                                   label={t("partnerId")}
                                   fullWidth
                                   required
                                   value={data.partnerId || ""}
                                   onChange={handleChange}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleEmptyAndClose}
                        color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={handleSave}
                        color="primary">
                    {t("save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default KdrDialog