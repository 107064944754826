import { PRODUCT_TYPE_EVENT, PRODUCT_TYPE_ENTRANCE_TICKET, PRODUCT_TYPE_SEASONAL_PASS } from "../baseContext";

export const getBaseUrlBasedOnProductType = (type) => {
    switch (type) {
        case PRODUCT_TYPE_EVENT:
            return "events"
        case PRODUCT_TYPE_ENTRANCE_TICKET:
            return "entrance_tickets"
        case PRODUCT_TYPE_SEASONAL_PASS:
            return "seasonal_passes"
        default:
            return "products"
    }
}

export const getBaseRouteBasedOnProductType = (type) => {
    switch (type) {
        case PRODUCT_TYPE_EVENT:
            return "events"
        case PRODUCT_TYPE_ENTRANCE_TICKET:
            return "entrance"
        case PRODUCT_TYPE_SEASONAL_PASS:
            return "seasonalpass"
        default:
            return ""
    }
}