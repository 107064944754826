import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

import React from "react";
import "react-quill/dist/quill.snow.css";
import Button from "@mui/material/Button";
import { useBaseTranslation } from "../baseTranslationContext";

export const MailPopupModal = ({open, onClose, content}) => {
  const t = useBaseTranslation()

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md">
      <DialogContent style={{minHeight: 600}}>
        <Typography variant={"h4"}>{content?.heading}</Typography>
        <div dangerouslySetInnerHTML={{__html: content?.message}} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}
                color="primary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
