import {alpha, Collapse, Grid, Tooltip, Typography} from "@mui/material";
import {Event} from "@mui/icons-material";
import React from "react";
import withStyles from "@mui/styles/withStyles";
import {TreeItem} from "@mui/lab";
import {simpleMonthNumberToStringConverter} from "../helper-functions/dateFormat";
import {animated, useSpring} from "@react-spring/web";

const TransitionComponent = props => {
    const style = useSpring({
        from: {opacity: 0, transform: "translate3d(20px,0,0)"},
        to: {opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)`},
    })

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    )
}

const StyledTreeItem = withStyles({
    iconContainer: {
        "& .close": {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha("#000000", 0.4)}`,
    },
})((props) => <TreeItem {...props} TransitionComponent={TransitionComponent}/>)

export const TreeItems = ({groupedScheduleEvents, getEventLabel, onSelect, t}) => {
    const getEventNodes = events => events?.map((event) => {
        const cancelled = event?.status === "cancelled"
        return (
            <StyledTreeItem key={`${event?.id}-${event?.recurNumber}`}
                            nodeId={`${event?.id}-${event?.recurNumber}`}
                            label={(
                                <Tooltip title={cancelled ? t("eventIsCancelled") : ""}>
                                    <Grid container spacing={1} wrap="nowrap">
                                        <Grid item>
                                            <Event fontSize="small" color={cancelled ? "disabled" : "action"}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2"
                                                        color={cancelled ? "textSecondary" : "textPrimary"}>
                                                {getEventLabel(event)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Tooltip>
                            )}
                            onClick={() => onSelect(event)}>
            </StyledTreeItem>
        )
    })

    const getDayNodes = (days, year, month, singleMonth) => {
        const singleDay = Object.entries(days).length === 1

        if (singleDay) {
            return Object.entries(days).map(([day, events]) => getEventNodes(events))
        } else {
            return Object.entries(days).map(([day, events]) => {
                const singleEvent = events.length === 1

                if (singleEvent) return getEventNodes(events)

                return (
                    <StyledTreeItem key={`${year}-${month}-${day}`}
                                    nodeId={`${year}-${month}-${day}`}
                                    style={singleMonth ? {
                                        marginLeft: 7,
                                        paddingLeft: 18,
                                        borderLeft: `1px dashed ${alpha("#000000", 0.4)}`,
                                    } : {}}
                                    label={`${day}.`}>
                        {getEventNodes(events)}
                    </StyledTreeItem>
                )
            })
        }
    }

    const getMonthNodes = (months, year) => {
        const singleMonth = Object.entries(months).length === 1

        if (singleMonth) {
            const month = Object.keys(months)[0]
            return (
                <div key={`${year}-${month}`}>
                    <Typography color="textSecondary">{simpleMonthNumberToStringConverter(month)}</Typography>
                    {Object.entries(months).map(([month, days]) => getDayNodes(days, year, month, singleMonth))}
                </div>
            )
        } else {
            return Object.entries(months).map(([month, days]) => (
                <StyledTreeItem key={`${year}-${month}`}
                                nodeId={`${year}-${month}`}
                                label={simpleMonthNumberToStringConverter(month)}>
                    {getDayNodes(days, year, month)}
                </StyledTreeItem>
            ))
        }
    }

    const getYearNodes = () => Object.entries(groupedScheduleEvents).map(([year, months]) => (
        <StyledTreeItem key={year}
                        nodeId={year}
                        label={year}>
            {getMonthNodes(months, year)}
        </StyledTreeItem>
    ))

    if (groupedScheduleEvents) {
        const singleYear = Object.entries(groupedScheduleEvents).length === 1

        if (singleYear) {
            return Object.entries(groupedScheduleEvents).map(([year, months]) => getMonthNodes(months, year))
        } else {
            return getYearNodes()
        }
    }


}