import {Button, FormControlLabel, FormHelperText, MenuItem, Switch, Typography} from "@mui/material"
import AccordionDetails from "@mui/material/AccordionDetails"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useEffect, useState} from "react"
import MuiDatePicker from "../../components/MuiDatePicker"
import MuiTimePicker from "../../components/MuiTimePicker"
import {dateFormat} from "../../helper-functions/dateFormat"
import {useProductEditorTranslation} from "../productEditorTranslationContext"
import _ from "lodash"
import {RECURRING_EVENT_TYPES, RECURRING_TYPE_RECURS_NOT} from "../../baseContext"
import {
    eventHasTicketsSold,
    extractPattern,
    getDefaultPattern,
    getEndDate,
    getEndTime,
    getRecurringOption,
    getRecurringPattern,
    getStartDate,
    getStartTime,
    patternIsRecurring,
} from "../../helper-functions/scheduleEventHelper"
import ScheduleEventRecurringPatternDialog from "./ScheduleEventRecurringPatternDialog"
import FormControl from "@mui/material/FormControl"
import { Alert, AlertTitle } from '@mui/material';
import {LockOpen} from "@mui/icons-material"
import ScheduleEventExceptions from "./ScheduleEventExceptions"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import TranslatableTextField from "../TranslatableTextField"
import {getTranslatableFieldValueFromObject} from "../../helper-functions/translationHelpers"
import {useMuseumSelectorState} from "../../museum-selector/museumSelectorContext";
import {ROLES_VALUES} from "../../menu/MuseumSelector";
import ScheduleEventExceptionsDialog from "./ScheduleEventExceptionsDialog"
import AddIcon from "@mui/icons-material/Add"
import ScheduleEventExistingOrdersDialog from "./ScheduleEventDetailsExistingOrdersDialog"

const useStyles = makeStyles(theme => ({
    scheduleEventForm: {
        width: "100%",
    },
    switchLabel: {
        fontSize: "0.9em",
    },
    deleteButton: {
        background: "#d32f2f",
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: "white",
        "&:hover": {
            background: "#9a0007",
        },
    },
}))

const ScheduleEventDetails = ({event, product, existingOrders, fetchOrders, handleEventsChange, unsavedChanges}) => {
    const t = useProductEditorTranslation()
    const classes = useStyles()
    const {selectedMuseum} = useMuseumSelectorState()

    const [_event, setEvent] = useState({saleLimit: undefined})
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [saleLimit, setSaleLimit] = useState(false)
    const [maxTicketPerCustomer] = useState(1)
    const [enableMaxTicketPerCustomer, setEnableMaxTicketPerCustomer] = useState(false)
    const [isFullDay, setIsFullDay] = useState(false)
    const [recurringPattern, setRecurringPattern] = useState(getDefaultPattern)
    const [recurringOptionSelected, setRecurringOptionSelected] = useState()
    const [recurringPatternDialogOpen, setRecurringPatternDialogOpen] = useState(false)
    const [locked, setLocked] = useState(false)
    const [_hasExceptions, setHasExceptions] = useState(false)
    const [_exceptions, setExceptions] = useState([])
    const [exceptionsWarningDialogOpen, setExceptionsWarningDialogOpen] = useState(false)
    const [addExceptionsDialogOpen, setAddExceptionsDialogOpen] = useState(false)
    const [needExceptionsWarning, setNeedExceptionsWarning] = useState(false)
    const [exceptionsWarningAccepted, setExceptionsWarningAccepted] = useState(false)
    const [tempPatternType, setTempPatternType] = useState()
    const [exceptionIdLoading, setExceptionIdLoading] = useState()
    const [existingOrdersDialogOpen, setExistingOrdersDialogOpen] = useState(false)
    const [exception, setException] = useState()
    const [tryingToRemoveException, setTryingToRemoveException] = useState(false)

    useEffect(() => {
        if (!exceptionsWarningAccepted && _hasExceptions && patternIsRecurring(recurringPattern)) {
            setNeedExceptionsWarning(true)
        } else {
            setNeedExceptionsWarning(false)
        }
    }, [exceptionsWarningAccepted, _hasExceptions, recurringPattern])

    const populateTranslateAbleFieldsFromObject = useCallback((objectToPopulate) => {
        if (!objectToPopulate?.name?.translations) {
            objectToPopulate.name = getTranslatableFieldValueFromObject(objectToPopulate, "name")
        }

        return objectToPopulate
    }, [])

    useEffect(() => {
        const {hasExceptions, exceptions} = event
        setEvent(populateTranslateAbleFieldsFromObject(event))
        if (!unsavedChanges && event?.isRecurring) setLocked(eventHasTicketsSold(event))
        setExceptions(exceptions)
        setSaleLimit(!!event.saleLimit)
        setEnableMaxTicketPerCustomer(!!event.maxTicketPerCustomer)
        setIsFullDay(!!event.isFullDay)
        setRecurringPattern(extractPattern(event))
        setStartDate(getStartDate(event))
        setEndDate(getEndDate(event))
        setStartTime(getStartTime(event))
        setEndTime(getEndTime(event))
        setHasExceptions(hasExceptions)
    }, [event, populateTranslateAbleFieldsFromObject, unsavedChanges])

    const handleTranslatableFieldChange = (updatedValue, propertyName) => {
        const updatedEvent = {..._event, [propertyName]: updatedValue}
        setEvent(updatedEvent)
        if (!_.isEqual(updatedEvent, event)) handleEventsChange(updatedEvent)
    }

    const handleEventChange = (updatedValue, propertyName) => {
        const updatedEvent = {..._event, [propertyName]: updatedValue}
        setEvent(updatedEvent)
    }

    const handleSaleLimitChange = () => {
        handleEventsChange({..._event, saleLimit: (saleLimit ? 0 : 10)})
        setSaleLimit(!saleLimit)
    }

    const handleMaxTicketPerCustomerChange = () => {
        handleEventsChange({..._event, maxTicketPerCustomer: (enableMaxTicketPerCustomer ? 0 : maxTicketPerCustomer)})
        setEnableMaxTicketPerCustomer(!enableMaxTicketPerCustomer)
    }

    const handleIsFullDayChange = () => {
        handleEventsChange({
            ..._event,
            isFullDay: (!isFullDay),
            startTime: isFullDay ? dateFormat(new Date().setMinutes(60), "HH:mm:ss") : null,
            endTime: null,
        })
        setIsFullDay(!isFullDay)
    }

    const handleRecurringPatternTypeSelect = (type) => {
        let newPattern = type !== recurringPattern.type
            ? {...recurringPattern, ...getRecurringPattern(_event, type)}
            : recurringPattern
        setRecurringOptionSelected(getRecurringOption(type))

        handleEventsChange({
            ..._event,
            hasExceptions: false,
            exceptions: [],
            isRecurring: (type !== RECURRING_TYPE_RECURS_NOT),
            scheduleEventRecurringPattern: newPattern
        })

        setRecurringPattern(newPattern)

        if (!recurringPatternDialogOpen && type !== RECURRING_TYPE_RECURS_NOT) setRecurringPatternDialogOpen(true)
    }

    const handleRecurringPatternChange = (pattern, dialogOpen, start, end) => {
        setRecurringPatternDialogOpen(dialogOpen)
        handleEventsChange({
            ..._event,
            startDate: dateFormat(start, "yyyy-MM-dd"),
            endDate: dateFormat(end, "yyyy-MM-dd"),
            scheduleEventRecurringPattern: {...pattern, dayOfWeek: pattern?.dayOfWeek?.join(",")},
            hasExceptions: false,
            exceptions: [],
        })
    }

    const handleExceptionsChange = (newExceptions) => {
        handleEventsChange({
            ..._event,
            hasExceptions: !!newExceptions?.length,
            exceptions: newExceptions,
        })
        setTryingToRemoveException(false)
    }

    const handleAddException = (_exception, warningAccepted = false) => {
        if (warningAccepted || (!_exception?.isCancelled && !_exception?.isRescheduled && !_exception?.isSaleLimitReduced)) {
            handleExceptionsChange([...event?.exceptions, _exception])
        } else {
            fetchOrders(_exception.scheduleEventId, _exception.recurNumber).then(({data: {orders}}) => {
                setException(_exception)
                setExistingOrdersDialogOpen(orders.length > 0)
                if (!orders.length) handleExceptionsChange([...event?.exceptions, _exception])
            })
        }
    }

    const handleExceptionRemove = (_exception, warningAccepted = false) => {
        if (!_exception?.id || warningAccepted) {
            handleExceptionsChange(_exceptions.filter(x => x.recurNumber !== _exception?.recurNumber))
        } else {
            setExceptionIdLoading(_exception?.id)
            setTryingToRemoveException(true)

            if (_exception?.isCancelled || _exception?.isRescheduled || !_exception?.isSaleLimitIncreased) {
                fetchOrders(_exception.scheduleEventId, _exception.recurNumber).then(({data: {orders}}) => {
                    setException(_exception)
                    setExistingOrdersDialogOpen(orders.length > 0)
                    if (!orders.length) handleExceptionsChange(_exceptions.filter(x => x.recurNumber !== _exception?.recurNumber))
                }).finally(() => setExceptionIdLoading(null))
            } else handleExceptionsChange(_exceptions.filter(x => x.recurNumber !== _exception?.recurNumber))
        }
    }

    const handleDateChange = (date, name) => {
        let newEvent = {..._event, [name]: dateFormat(date, "yyyy-MM-dd"), hasExceptions: false, exceptions: [], dateChanged: true}
        const {isRecurring} = _event

        if (name === "startDate") {
            if (isFullDay) {
                // If event is fullday, end date should never be earlier than start date
                if (date > endDate) newEvent = {...newEvent, endDate: dateFormat(date, "yyyy-MM-dd")}
                // Only fullday events should have endDate. Recurring CAN have endDate.
            } else if (!isRecurring) newEvent = {...newEvent, endDate: null}
        } else if (isFullDay && name === "endDate") {
            // If event is fullday, end date should never be earlier than start date
            if (date < startDate) newEvent = {...newEvent, startDate: dateFormat(date, "yyyy-MM-dd")}
        }

        handleEventsChange(newEvent)
    }

    const handleTimeChange = (time, name) => {
        let newEvent
        if (!!!time) {
            newEvent = {..._event, [name]: null}
        } else {
            newEvent = {..._event, [name]: dateFormat(time.setSeconds(0), "HH:mm:ss")}
        }
        handleEventsChange(newEvent)
    }

    const handleEventSubmit = () => {
        if (!_.isEqual(_event, event)) handleEventsChange(_event)
    }

    const handleExceptionsWarningDialogOpen = (type) => {
        setTempPatternType(type)
        setExceptionsWarningDialogOpen(true)
    }

    const handleExceptionsWarningAccept = () => {
        setExceptionsWarningAccepted(true)
        handleRecurringPatternTypeSelect(tempPatternType)
        setExceptionsWarningDialogOpen(false)
    }

    const handleExistingOrdersWarningAccept = (exception) => {
        if (tryingToRemoveException) handleExceptionRemove(exception, true)
        else handleAddException(exception, true)
        setExistingOrdersDialogOpen(false)
    }

    const getStartDateHelperText = () => {
        if (locked) {
            return t("recurringScheduleEventLockedHelperText")
        } else if (needExceptionsWarning) {
            return "Unntak vil fjernes hvis dato endres!"
        } else {
            return ""
        }
    }

    return (
        <AccordionDetails>
            <Grid container direction="column" spacing={1}>
                <form noValidate autoComplete="off" className={classes.scheduleEventForm}>
                    <Grid item xs={12}>
                        <TranslatableTextField label={t("name")}
                                               name="name"
                                               variant="outlined"
                                               size="small"
                                               helperText={t("scheduleEventNameHelperText")}
                                               onChange={(updatedValue) => handleTranslatableFieldChange(
                                                   updatedValue,
                                                   "name")}
                                               value={_event.name || ""}/>
                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormControlLabel label={t("limitSale")}
                                                  labelPlacement="top"
                                                  classes={{label: classes.switchLabel}}
                                                  control={
                                                      <Switch checked={saleLimit}
                                                              size="small"
                                                              disabled={locked}
                                                              onChange={handleSaleLimitChange}/>
                                                  }/>
                                <FormHelperText>{t("scheduleEventSaleLimitHelperText")}</FormHelperText>
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormControlLabel label={t("maxPerCustomer")}
                                                  labelPlacement="top"
                                                  classes={{label: classes.switchLabel}}
                                                  control={
                                                      <Switch checked={enableMaxTicketPerCustomer}
                                                              size="small"
                                                              onChange={handleMaxTicketPerCustomerChange}/>
                                                  }/>
                                <FormHelperText>{t("scheduleEventMaxTicketPerCustomerHelperText")}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            {_event?.saleLimit !== 0 && (
                                <TextField label={t("saleLimit")}
                                           name="saleLimit"
                                           variant="outlined"
                                           disabled={!saleLimit || locked}
                                           helperText={locked ? t("recurringScheduleEventLockedHelperText") : ""}
                                           size="small"
                                           fullWidth
                                           onBlur={handleEventSubmit}
                                           onChange={(e) => handleEventChange(e.target.value, e.target.name)}
                                           value={_event.saleLimit || 0}/>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {_event?.maxTicketPerCustomer !== 0 && (
                                <TextField label={t("maxPerCustomer")}
                                           name="maxTicketPerCustomer"
                                           variant="outlined"
                                           disabled={!enableMaxTicketPerCustomer}
                                           size="small"
                                           fullWidth
                                           onBlur={handleEventSubmit}
                                           onChange={(e) => handleEventChange(e.target.value, e.target.name)}
                                           value={_event.maxTicketPerCustomer || 0}/>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <MuiDatePicker selected={startDate}
                                           label={t(isFullDay ? "startDate" : "date")}
                                           name="startDate"
                                           disabled={locked}
                                           error={needExceptionsWarning && !locked}
                                           helperText={getStartDateHelperText()}
                                           size="small"
                                           inputVariant="outlined"
                                           onChange={handleDateChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel label={t("isFullDay")}
                                              labelPlacement="start"
                                              classes={{label: classes.switchLabel}}
                                              control={
                                                  <Switch checked={isFullDay}
                                                          size="small"
                                                          disabled={locked}
                                                          onChange={handleIsFullDayChange}/>
                                              }/>
                        </Grid>
                    </Grid>
                    {!isFullDay&&
                    <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={6} sm={3}>
                                    <MuiTimePicker selected={startTime}
                                                   label={t("startTime")}
                                                   name="startTime"
                                                   size="small"
                                                   disabled={locked}
                                                   inputVariant="outlined"
                                                   onChange={handleTimeChange}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                        <MuiTimePicker selected={endTime}
                                                       label={t("endTime")}
                                                       name="endTime"
                                                       size="small"
                                                       disabled={locked}
                                                       isClearable
                                                       inputVariant="outlined"
                                                       onChange={handleTimeChange}/>
                                </Grid>
                    </Grid>}
                    <Grid container item alignItems="flex-start" spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField label={t("recurring")}
                                       name="recurringPatternType"
                                       variant="outlined"
                                       disabled={locked}
                                       helperText={locked ? t("recurringScheduleEventLockedHelperText") : ""}
                                       size="small"
                                       fullWidth
                                       select
                                       value={recurringPattern.type || ""}>
                                {RECURRING_EVENT_TYPES.map((type, idx) => (
                                    <MenuItem key={idx}
                                              value={type}
                                              onClick={() => needExceptionsWarning
                                                  ? handleExceptionsWarningDialogOpen(type)
                                                  : handleRecurringPatternTypeSelect(type)}>
                                        {t(type)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {patternIsRecurring(recurringPattern) && (
                                <Button onClick={() => setAddExceptionsDialogOpen(true)}
                                        variant="text"
                                        color="primary"
                                        startIcon={<AddIcon/>}>
                                    {t('addException')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </form>

                {locked && (
                    <Alert severity="error"
                           style={{marginTop: 8}}
                           action={selectedMuseum?.role?.value >= ROLES_VALUES.APP_ADMIN && (
                               <Button color="inherit"
                                       onClick={() => setLocked(false)}
                                       startIcon={<LockOpen/>}>
                                   <Typography variant="button"
                                               noWrap>
                                       Lås opp
                                   </Typography>
                               </Button>
                           )}>
                        <AlertTitle>{t("recurringScheduleEventLockedHelperText")}</AlertTitle>
                        Legg inn unntak hvis det er avvik fra opprinnelig oppsett.<br/><br/>
                        Hvis det er behov for andre endringer; ta kontakt med en administrator!
                    </Alert>
                )}

                {_hasExceptions && <ScheduleEventExceptions exceptions={_exceptions}
                                                            exceptionIdLoading={exceptionIdLoading}
                                                            handleRemove={handleExceptionRemove}/>}

            </Grid>
            <ConfirmationDialog open={exceptionsWarningDialogOpen}
                                handleAccept={handleExceptionsWarningAccept}
                                handleClose={() => setExceptionsWarningDialogOpen(false)}>
                Det er lagt inn unntak på denne gjentagende kalenderhendelsen. <br/>
                Hvis regler og oppsett for repetisjon blir endret, <u>vil unntakene bli fjernet!</u><br/><br/><br/>
                <strong>Er du sikker på at du vil fortsette?</strong>
            </ConfirmationDialog>
            <ScheduleEventRecurringPatternDialog recurringPatternDialogOpen={recurringPatternDialogOpen}
                                                 recurringPattern={recurringPattern}
                                                 recurringOptionSelected={recurringOptionSelected}
                                                 event={_event}
                                                 startDate={startDate}
                                                 endDate={endDate}
                                                 handleRecurringPatternChange={handleRecurringPatternChange}/>
            <ScheduleEventExceptionsDialog open={addExceptionsDialogOpen}
                                           event={_event}
                                           pattern={recurringPattern}
                                           exceptions={_exceptions}
                                           handleAddException={handleAddException}
                                           handleClose={() => setAddExceptionsDialogOpen(false)}/>
            <ScheduleEventExistingOrdersDialog open={existingOrdersDialogOpen}
                                               orders={existingOrders}
                                               product={product}
                                               exception={exception}
                                               refetch={fetchOrders}
                                               handleClose={() => setExistingOrdersDialogOpen(false)}
                                               handleAccept={handleExistingOrdersWarningAccept}/>
        </AccordionDetails>
    )
}

export default ScheduleEventDetails
