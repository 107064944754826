import { Alert, AlertTitle } from '@mui/material';
import React from "react"

const AlertBox = ({title, text, severity = "info"}) => (
    <>
        {text && (
            <Alert severity={severity}
                   style={{marginBottom: 8}}>
                <AlertTitle>{title}</AlertTitle>
                {text}
            </Alert>
        )}
    </>
)

export default AlertBox