import React, {useEffect, useState} from "react"
import { InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";

const MuiDateTimePicker = ({
                               selected,
                               label,
                               name,
                               onChange,
                               fullWidth = true,
                               size = "medium",
                               inputVariant = "filled",
                               margin = 'normal',
                               minDate = null,
                               maxDate = null,
                               helperText = "",
                               isClearable = false,
                               onClear = null,
                               error = false,
                               required = false
                           }) => {
    const [minMaxDateProps, setMinMaxDateProps] = useState({})

    useEffect(() => {
        if (minDate) setMinMaxDateProps({minDate: minDate})
        if (maxDate) setMinMaxDateProps({maxDate: maxDate})
    }, [minDate, maxDate])

    return (
        <DateTimePicker value={selected}
                        autoOk
                        renderInput={(props) => <TextField {...props} variant={inputVariant} fullWidth={fullWidth} margin={margin} />}
                        ampm={false}
                        format="dd.MM.yyyy HH:mm"
                        minutesStep={5}
                        label={label}
                        size={size}
                        {...minMaxDateProps}
                        helperText={helperText}
                        fullWidth={fullWidth}
                        required={required}
                        margin={margin}
                        error={error}
                        InputProps={{
                            endAdornment: selected && isClearable && onClear ? (
                                <InputAdornment position="end">
                                    <IconButton size="small"
                                                onClick={(event) => onClear(event)}>
                                        <Clear/>
                                    </IconButton>
                                </InputAdornment>
                            ) : null
                        }}
                        onChange={date => onChange(date, name)}/>
    )
}

export default MuiDateTimePicker

