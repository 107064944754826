import {Button, Card, CardContent, CardHeader, Grid, Tooltip, useTheme} from "@mui/material"

import React, {useEffect, useMemo, useState} from "react"
import {useBaseTranslation} from "../baseTranslationContext"
import {useAxiosBpn} from "../axios"
import "react-quill/dist/quill.snow.css"
import GenericTable from "../generic-table/GenericTable"
import {ProgressIndicator} from "../ProgressIndicator"
import {createExcelDatasetFromOrderData} from "../order-table/orderTableHelpers"
import ExcelFile from "react-data-export/dist/ExcelPlugin/components/ExcelFile"
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet"
import {ButtonWithProgress} from "../components/ButtonWithProgress"
import {toLowerCase} from "../helper-functions/stringUtility"
import {ArrowDropDown} from "@mui/icons-material"
import ScheduleEventTreeView from "../components/ScheduleEventTreeView"
import EventFilterDialog from "../components/EventFilterDialog"

export const AllProductComments = ({productId, testOrders, freeTextFieldLabel, productName = ""}) => {
    const t = useBaseTranslation()
    const {palette: {testOrders: testOrdersPalette}} = useTheme()
    const [eventFilterButtonHidden, setEventFilterButtonHidden] = useState(true)
    const [eventFilterDialogOpen, setEventFilterDialogOpen] = useState(false)
    const [eventFilterLabel, setEventFilterLabel] = useState(t("allEvents"))
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [downloadExelFile, setDownloadExelFile] = useState(false)

    const tableColumns = useMemo(() => {
        return [
            {field: "orderId", headerName: t("orderId")},
            {field: "firstName", headerName: t("firstName")},
            {field: "lastName", headerName: t("lastName")},
            {field: "phone", headerName: t("phone")},
            {field: "comment", headerName: t("comment")},
        ]
    },[t])

    const [{data: orderCommentsData, loading: commentsLoading}, getOrderComments] = useAxiosBpn({
        url: `order_comment/${productId}`,
        params: {
            scheduleEventId: selectedEvent?.id,
            recurNumber: selectedEvent?.recurNumber,
            test: testOrders,
        },
        method: "GET",
    }, {manual: true})

    const [{data: productScheduleEventsData}, getProductScheduleEventsData] = useAxiosBpn({
        url: "schedule_events/grouped",
        params: {productId: productId},
    }, {manual: true})

    useEffect(() => getOrderComments(), [getOrderComments])

    useEffect(() => {
        if (productId) getProductScheduleEventsData().catch(() => {})
    }, [getProductScheduleEventsData, productId])

    useEffect(() => {
        if (productScheduleEventsData) {
            const {groupedScheduleEvents} = productScheduleEventsData
            if (Object.entries(groupedScheduleEvents).length > 1) {
                setEventFilterButtonHidden(false)
            } else {
                // eslint-disable-next-line array-callback-return
                Object.entries(groupedScheduleEvents).map(([year, months]) => {
                    if (Object.entries(months).length > 1) {
                        setEventFilterButtonHidden(false)
                    } else {
                        // eslint-disable-next-line array-callback-return
                        Object.entries(months).map(([month, days]) => {
                            if (Object.entries(days).length > 1) {
                                setEventFilterButtonHidden(false)
                            } else {
                                // eslint-disable-next-line array-callback-return
                                Object.entries(days).map(([day, events]) => {
                                    if (Object.entries(events).length > 1) {
                                        setEventFilterButtonHidden(false)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
    }, [productScheduleEventsData])

    const generatedTableRows = useMemo(() => {
        const tableRows = []
        if (orderCommentsData) {
            orderCommentsData.comments.forEach(comment => {
                tableRows.push({
                    orderId: comment.orderId,
                    firstName: comment.customerFirstName,
                    lastName: comment.customerLastName,
                    phone: comment.customerPhone,
                    comment: comment.comment,
                })
            })
        }

        return tableRows
    }, [orderCommentsData])

    const excelDataSet = useMemo(() => {
        if (downloadExelFile) {
            return createExcelDatasetFromOrderData(tableColumns, generatedTableRows)
        }
        return null
    }, [tableColumns, generatedTableRows, downloadExelFile])

    const handleEventFilterDialogOpen = (event) => {
        setEventFilterDialogOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleEventFilterSelect = (event, label = null) => {
        setEventFilterLabel(label || t("allEvents"))
        setSelectedEvent(event)
    }

    return (
        commentsLoading
        ? <ProgressIndicator/>
        : <Card style={{
            marginRight: 16,
            height: "1000px",
            backgroundColor: testOrders ? testOrdersPalette?.light : null,
        }}>
            <CardHeader title={`${t("comments")}${testOrders ? ` (${toLowerCase(t("testOrders"))})` : ""}`}
                        titleTypographyProps={{color: "primary"}}/>
            <CardContent>
                <Grid container
                      spacing={3}
                      alignItems="center"
                      justifyContent="flex-end">
                    <Grid item>
                        <>
                            <ButtonWithProgress label={t("downloadXLSX")}
                                                variant="text"
                                                color="primary"
                                                onClick={() => setDownloadExelFile(true)}
                                                loading={false}/>
                            {excelDataSet && (
                                <ExcelFile filename={`${productName} - ${t("comments")} - ${eventFilterLabel}`}
                                           hideElement={true}>
                                    <ExcelSheet dataSet={excelDataSet}
                                                name={t("comments")}/>
                                </ExcelFile>
                            )}
                        </>
                    </Grid>
                    {!eventFilterButtonHidden && (
                        <Grid item>
                            <Tooltip title={t("filterOnScheduleEvent")}>
                                <Button onClick={handleEventFilterDialogOpen}
                                        color="primary"
                                        endIcon={<ArrowDropDown/>}>
                                    {eventFilterLabel}
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <div style={{backgroundColor: testOrders ? "white" : null, width: "100%"}}>
                    <GenericTable columns={tableColumns}
                                  rows={generatedTableRows || []}
                                  tableTitle={freeTextFieldLabel}
                                  selectable={false}
                                  size="small"/>
                </div>
                <EventFilterDialog anchorEl={anchorEl}
                                   open={eventFilterDialogOpen}
                                   handleClose={() => setEventFilterDialogOpen(false)}>
                    <ScheduleEventTreeView groupedScheduleEvents={productScheduleEventsData?.groupedScheduleEvents}
                                           selectedEvent={selectedEvent}
                                           handleClose={() => setEventFilterDialogOpen(false)}
                                           handleSelect={handleEventFilterSelect}/>
                </EventFilterDialog>
            </CardContent>
        </Card>
    )
}
