import React from "react"
import {Grid, Typography} from "@mui/material"
import TestOrdersSwitch from "../components/TestOrdersSwitch"

export const ProductDetailsHeader = ({product, testOrders, setTestOrders}) => {

    return (
        <Grid container
              alignItems="center"
              justifyContent="space-between">
            <Grid item>
                <div style={{marginTop: "16px"}}>
                    <Typography variant={"h5"}>{product.name}</Typography>
                </div>
            </Grid>
            <Grid item>
                <TestOrdersSwitch testOrders={testOrders}
                                  setTestOrders={setTestOrders}/>
            </Grid>
        </Grid>
    )
}
