import {Button, Grid, Hidden, IconButton, Typography} from "@mui/material"
import TextField from "@mui/material/TextField"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from "react"
import {useBaseTranslation} from "../../app/baseTranslationContext"
import useCustomSnackbar from "../../app/components/useCustomSnackbar"
import {useAxiosBpn} from "../../app/axios"
import {ProgressIndicator} from "../../app/ProgressIndicator"
import _ from "lodash"
import {useMuseumSelectorState} from "../../app/museum-selector/museumSelectorContext"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    switch: {
        marginLeft: theme.spacing(1),
        verticalAlign: "middle",
    },
    switchLabel: {
        fontSize: "0.9em",
    },
    warning: {
        textAlign: "right",
        color: "lightgray",
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(-1),
    },
}))

const ApiClient = ({index, data, removeFromApiClients, submitChange}) => {
    const t = useBaseTranslation()
    const classes = useStyles()
    const {umbrellaAndChildrenIds, umbrellaId} = useMuseumSelectorState()

    const [apiClient, setApiClient] = useState()
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const snackbar = useCustomSnackbar()

    const [{loading: postLoading, error: postError}, postApiClient] = useAxiosBpn({
        url: "api_clients",
        method: "POST",
    }, {manual: true})

    const [{loading: putLoading, error: putError}, putApiClient] = useAxiosBpn({
        url: `api_clients/${apiClient?.id}`,
        method: "PUT",
    }, {manual: true})

    const [{loading: deleteLoading, error: deleteError}, deleteApiClient] = useAxiosBpn({
        url: `api_clients/${apiClient?.id}`,
        method: "DELETE",
    }, {manual: true})

    useEffect(() => {
        setApiClient(data)
    }, [data])

    useEffect(() => {
        if (apiClient?.new) setUnsavedChanges(true)
    }, [apiClient])

    useEffect(() => {
        if (postError) snackbar.showError(t(postError.message))
        if (putError) snackbar.showError(t(putError.message))
        if (deleteError) snackbar.showError(t(deleteError.message))
    }, [deleteError, postError, putError, snackbar, t])

    const handleChange = e => {
        e.persist()
        setApiClient(prev => ({...prev, [e.target.name]: e.target.value}))
        if (!unsavedChanges) setUnsavedChanges(true)
    }

    const handleSave = () => {
        if (apiClient?.new) {
            postApiClient({
                data: {
                    ...apiClient,
                    museumId: umbrellaId,
                    accessToMuseumIds: umbrellaAndChildrenIds.toString(),
                },
            })
                .then(({data: {apiClient: _apiClient}}) => {
                    snackbar.showSuccess(`${t("apiClient")} ${t("added").toLowerCase()}`)
                    submitChange(index, _apiClient)
                    setUnsavedChanges(false)
                })
        } else {
            putApiClient({data: apiClient})
                .then(({data: {apiClient: _apiClient}}) => {
                    snackbar.showSuccess(`${t("apiClient")} ${t("updated").toLowerCase()}`)
                    submitChange(index, _apiClient)
                    setUnsavedChanges(false)
                })
        }
    }

    const handleDelete = () => {
        if (apiClient?.new) {
            removeFromApiClients(index)
        } else {
            deleteApiClient()
                .then(() => {
                    snackbar.showSuccess(`${t("apiClient")} ${t("deleted").toLowerCase()}`)
                    removeFromApiClients(index)
                })
        }
    }

    const handleSubmit = () => {
        if (!_.isEqual(apiClient, data)) {
            setUnsavedChanges(true)
            submitChange(index, apiClient)
        }
    }

    const isMissingName = () => {
        return putError || postError ? !apiClient?.name : false
    }

    return <>
        {postLoading || putLoading || deleteLoading ? <ProgressIndicator/> : (
            <form noValidate
                  className={classes.root}
                  autoComplete="off"
                  onBlur={handleSubmit}>
                <Grid container
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="center">
                    <Grid item
                          xs={12}
                          md={1}>
                        <TextField label={t("id")}
                                   name="id"
                                   variant="outlined"
                                   size="small"
                                   disabled
                                   fullWidth
                                   value={apiClient?.id || ""}/>
                    </Grid>
                    <Grid item
                          xs={12}
                          md={3}>
                        <TextField label={t("name")}
                                   name="name"
                                   variant="outlined"
                                   size="small"
                                   error={isMissingName()}
                                   helperText={isMissingName() && t("mustHaveName")}
                                   fullWidth
                                   required
                                   onChange={handleChange}
                                   value={apiClient?.name || ""}/>
                    </Grid>
                    <Grid item
                          xs={12}
                          md={4}>
                        <TextField label={t("key")}
                                   name="key"
                                   disabled
                                   variant="outlined"
                                   fullWidth
                                   size="small"
                                   value={apiClient?.key || ""}/>
                    </Grid>
                    <Grid item
                          xs={12}
                          md={2}>
                        <TextField label={t("access")}
                                   name="accessToMuseumIds"
                                   disabled
                                   variant="outlined"
                                   fullWidth
                                   size="small"
                                   value={apiClient?.accessToMuseumIds || ""}/>
                    </Grid>
                    <Hidden mdDown
                            xlUp>
                        <Grid item>
                            <IconButton onClick={handleDelete} size="large">
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton disabled={!unsavedChanges} onClick={handleSave} size="large">
                                <SaveIcon/>
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Hidden only={["md", "lg"]}>
                        <Grid item
                              container
                              justifyContent="flex-end"
                              xs={12}
                              sm>
                                <span>
                                    <Button variant="contained"
                                            onClick={handleDelete}
                                            className={classes.button}
                                            startIcon={<DeleteIcon/>}>
                                        {t("delete")}
                                    </Button>
                                </span>
                            <Button variant="contained"
                                    className={classes.button}
                                    disabled={!unsavedChanges}
                                    onClick={handleSave}
                                    startIcon={<SaveIcon/>}>
                                {t("save")}
                            </Button>
                        </Grid>
                    </Hidden>
                </Grid>
                {unsavedChanges &&
                <Typography variant="subtitle2"
                            className={classes.warning}>
                    {t("unsavedChanges") + "!"}
                </Typography>}
            </form>
        )}
    </>;
}

export default ApiClient